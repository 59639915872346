<template>
    <div class="rule-editor">
        <pendo-button
            v-if="removable && index === 0 && !readOnly"
            icon="x"
            type="tertiary"
            size="mini"
            class="rule-editor--remove"
            :class="{ 'rule-editor--first-rule': index === 0 }"
            @click="$emit('remove')" />
        <div
            v-if="removable && index !== 0"
            class="rule-editor--or-separator">
            <span class="rule-editor--or-separator--text">
                OR
            </span>
            <pendo-button
                v-if="!readOnly"
                icon="x"
                type="tertiary"
                size="mini"
                class="rule-editor--remove"
                @click="$emit('remove')" />
        </div>

        <div class="rule-editor--items">
            <!-- source picker -->
            <pendo-multiselect
                :value="rule.schema"
                :allow-empty="false"
                :options="groupedSchemaList"
                :disabled="readOnly"
                full-width
                label-key="name"
                value-key="id"
                class="rule-editor--schema"
                @select="onSchemaSelect">
                <template #optionGroup="{ option }">
                    <div
                        v-if="option.id === 'product-usage-header'"
                        class="group-header-label">
                        {{ option.label }}
                    </div>
                    <div
                        v-else
                        class="group-header-label">
                        {{ option.label }} ({{ option.options.length }})
                    </div>
                </template>
                <template #option="{ option }">
                    <pendo-icon-option
                        v-pendo-tooltip="{
                            content: option.tooltipContent,
                            placement: 'top',
                            arrow: true
                        }"
                        :option="option" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
            <!-- application usage picker -->
            <pendo-multiselect
                v-if="showAppChooser"
                :value="rule.app"
                :allow-empty="false"
                :options="appOptionsList"
                :searchable="true"
                :disabled="readOnly"
                :preselect-first="preselectFirstApp"
                label-key="displayName"
                value-key="id"
                full-width
                placeholder="Select Application"
                class="rule-editor--app-select"
                @select="onAppSelect">
                <template #option="{ option }">
                    <pendo-app-display
                        :show-app-icons="!!option.id && config.showAppIcons"
                        :apps="option" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-app-display
                        :show-app-icons="!!option.id && config.showAppIcons"
                        :apps="option" />
                </template>
            </pendo-multiselect>
            <!--  resource center application usage picker -->
            <pendo-multiselect
                v-if="showRCAppChooser"
                :value="rule.app"
                :allow-empty="false"
                :options="resourceCenterAppOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="displayName"
                value-key="id"
                full-width
                placeholder="Select Application"
                class="rule-editor--app-select"
                @select="onAppSelect">
                <template #option="{ option }">
                    <pendo-app-display
                        :apps="option"
                        :show-app-icons="config.showAppIcons" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-app-display
                        :apps="option"
                        :show-app-icons="config.showAppIcons" />
                </template>
            </pendo-multiselect>

            <component
                :is="ruleComponent"
                :computed-data="computedData"
                :config="config"
                :operators="operators"
                :rule="rule"
                :segment="segment"
                :filters="filters"
                @input="onRuleUpdate" />
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoButton, PendoIconOption, PendoMultiselect, PendoAppDisplay, PendoTooltip } from '@pendo/components';
import ApplicationRule from './rule-types/ApplicationRule';
import FeatureRule from './rule-types/FeatureRule';
import GuideRule from './rule-types/GuideRule';
import GuideElementRule from './rule-types/GuideElementRule';
import MetadataRule from './rule-types/MetadataRule';
import PageRule from './rule-types/PageRule';
import PollRule from './rule-types/PollRule';
import ResourceCenterRule from './rule-types/ResourceCenterRule';
import SegmentRule from './rule-types/SegmentRule';
import WorkflowRule from './rule-types/WorkflowRule';
import TrackTypeRule from './rule-types/TrackTypeRule';
import {
    getDefaultValueForSchema,
    generateRule,
    isCsvUploadOperator,
    isMobileDataSchema,
    isMobilePlatform
} from '@/components/segment-builder/utils/utils';
import ReadOnlyMixin from './rule-mixins/ReadOnly.mixin';
import { SEGMENT_RULE_LIMIT } from '@/components/segment-builder/constants/segments';
import * as filters from '@/components/segment-builder/constants/filters';

// Combines all selections under single value key for now
const SINGLE_VALUE_SCHEMAS = ['time', 'feature', 'page', 'guideElement', 'application', 'trackType'];

export default {
    name: 'RuleEditor',
    components: {
        ApplicationRule,
        FeatureRule,
        GuideRule,
        GuideElementRule,
        MetadataRule,
        PageRule,
        PendoButton,
        PendoIconOption,
        PendoMultiselect,
        PollRule,
        SegmentRule,
        ResourceCenterRule,
        WorkflowRule,
        PendoAppDisplay,
        TrackTypeRule
    },
    directives: {
        PendoTooltip
    },
    mixins: [ReadOnlyMixin],
    props: {
        config: {
            type: Object,
            required: true
        },
        computedData: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        operators: {
            type: Object,
            required: true
        },
        removable: {
            type: Boolean,
            default: false
        },
        rule: {
            type: Object,
            required: true
        },
        segment: {
            type: Object,
            required: false,
            default: () => null
        },
        hasParentSegments: {
            type: Boolean,
            required: true
        },
        nestedSegmentCount: {
            type: Number,
            required: true
        },
        hasMobileApps: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        appOptionsForAppUsage () {
            const isMobileDataSchemaSelected = isMobileDataSchema(this.rule.schema);

            return this.computedData.appOptions.filter((app) => {
                if (isMobileDataSchemaSelected) {
                    return !!app.id && isMobilePlatform(app.platform);
                }

                return !!app.id;
            });
        },
        appOptionsList () {
            if (this.ruleComponent === 'application-rule') {
                return this.appOptionsForAppUsage;
            }

            const defaultOption = [
                {
                    displayName: 'All Applications',
                    id: null,
                    resourceCenterId: null
                }
            ];

            const groupedOptions = [
                {
                    label: 'default',
                    options: defaultOption
                },
                {
                    label: 'divider',
                    options: this.appOptionsForAppUsage
                }
            ];

            return groupedOptions;
        },
        filters () {
            return {
                timeSeries: this.config.filters?.timeSeries || filters.DEFAULT_TIMESERIES,
                includeAnonymous: this.config.filters?.includeAnonymous || filters.DEFAULT_INCLUDE_ANONYMOUS,
                blacklist: this.config.filters?.blacklist || filters.DEFAULT_EXCLUDELIST,
                languageCode: this.config.filters?.languageCode || filters.DEFAULT_LANGUAGE_CODE
            };
        },
        groupedSchemaList () {
            const productUsage = [
                'guide',
                'guideElement',
                'resourceCenter',
                'page',
                'feature',
                'poll',
                'segment',
                'trackType'
            ];

            if (this.config.usesMultiApp) {
                productUsage.push('application');
            }

            if (this.config.hasWorkflow) {
                productUsage.push('workflow');
            }

            if (Array.isArray(this.computedData.filteredSchemaList)) {
                let productUsageFields = this.computedData.filteredSchemaList.filter((schema) => {
                    return productUsage.includes(schema.id);
                });
                productUsageFields = this.addSegmentLimitTooltip(productUsageFields);

                const metaFields = this.computedData.filteredSchemaList.filter((schema) => {
                    return !productUsage.includes(schema.id) && !isMobileDataSchema(schema);
                });

                const mobileFields = this.computedData.filteredSchemaList.filter((schema) => {
                    return isMobileDataSchema(schema);
                });

                const groupedSchemaList = [
                    {
                        id: 'product-usage-header',
                        label: 'Product Usage',
                        options: productUsageFields
                    },
                    {
                        id: 'visitor-data-header',
                        label: 'Visitor Data',
                        options: metaFields
                    }
                ];

                if (this.config.useTrackTypes && this.hasMobileApps) {
                    groupedSchemaList.push({
                        id: 'mobile-usage-header',
                        label: 'Mobile Data',
                        options: mobileFields
                    });
                }

                return groupedSchemaList;
            }

            return [];
        },
        preselectFirstApp () {
            return this.ruleComponent !== 'application-rule';
        },
        resourceCenterAppOptions () {
            return this.computedData.appOptions.filter((app) => !!app.resourceCenterId);
        },
        ruleComponent () {
            const { rule } = this;

            // enable these types as we separate them out
            const { schema, id } = rule.schema;
            switch (schema) {
                case 'guide':
                    if (id === 'resourceCenter') {
                        return 'resource-center-rule';
                    }

                    return 'guide-rule';
                case 'poll':
                    return 'poll-rule';
                case 'guideElement':
                    return 'guide-element-rule';
                case 'page':
                    return 'page-rule';
                case 'feature':
                    return 'feature-rule';
                case 'segment':
                    return 'segment-rule';
                case 'application':
                    return 'application-rule';
                case 'workflow':
                    return 'workflow-rule';
                case 'aggregation':
                    return null;
                case 'trackType':
                    return 'track-type-rule';
                default:
                    return 'metadata-rule';
            }
        },
        showAppChooser () {
            const { ruleComponent, config, rule } = this;
            if (!config.usesMultiApp) {
                return false;
            }

            if (isMobileDataSchema(rule.schema)) {
                return true;
            }

            const ruleComponentsWithAppSelections = [
                'guide-rule',
                'guide-element-rule',
                'page-rule',
                'feature-rule',
                'poll-rule',
                'application-rule',
                'track-type-rule'
            ];

            return ruleComponentsWithAppSelections.includes(ruleComponent);
        },
        showRCAppChooser () {
            if (!this.config.usesMultiApp) {
                return false;
            }

            return this.ruleComponent === 'resource-center-rule';
        }
    },
    methods: {
        onAppSelect (selectedApp) {
            const { rule } = this;
            const existingAppId = get(rule, 'app.id');

            if (selectedApp.id === existingAppId) {
                return;
            }

            const updates = {};

            const baseProperties = ['app', 'id', 'appDeleted', 'schema'];

            Object.keys(rule).forEach((prop) => {
                if (baseProperties.includes(prop)) {
                    return;
                }
                updates[prop] = undefined;
            });

            const validOperators = this.operators[rule.schema.schema] || [];
            updates.operator = validOperators[0];

            if (this.ruleComponent === 'application-rule') {
                updates.time = null;
            }

            if (isMobileDataSchema(rule.schema)) {
                updates.value = '';
            }

            updates.app = selectedApp;

            // Technically this probably isn't needed, but we'll do it for uniformity
            updates.appDeleted = false;

            this.$emit('input', updates);
        },
        onSchemaSelect (schema) {
            // if schema isn't the same, build a new rule with correct defaults
            if (schema.id !== this.rule.schema.id) {
                this.$emit('input', generateRule(this.operators, schema));
            } else {
                this.$emit('input', { schema });
            }
        },
        onRuleUpdate ($event) {
            const { rule } = this;
            const { schema } = rule.schema;
            const updates = {};
            const changes = Array.isArray($event) ? $event : [$event];

            changes.forEach(({ field, value }) => {
                switch (field) {
                    case 'operator':
                        Object.assign(updates, this.onOperatorChange(value));
                        break;
                    case 'count':
                    case 'granularity':
                    case 'first':
                    case 'last':
                        if (SINGLE_VALUE_SCHEMAS.includes(schema)) {
                            updates.value = { ...rule.value, [field]: value };
                        } else {
                            updates[field] = value;
                        }
                        break;
                    case 'tag':
                        updates.tagId = value.tagId;
                        updates.value = value.filename;

                        this.$emit('update:showCsvUploadWarning', !!value.tagId);

                        break;
                    case 'time':
                        updates.time = value;
                        break;
                    default:
                        updates[field] = value;
                        break;
                }
            });

            this.$emit('input', updates);
        },
        onOperatorChange (operator) {
            const { rule } = this;
            const { value, operator: previousOperator, schema } = rule;

            const updates = {};
            const wasCsvOperator = isCsvUploadOperator(previousOperator.value);
            const isCsvOperator = isCsvUploadOperator(operator.value);
            const shouldRestoreOriginalSchemaType = wasCsvOperator && !isCsvOperator;
            updates.operator = operator;

            if (operator.multivalue) {
                const value =
                    operator.value === 'between' ? { first: null, last: null } : { count: 30, granularity: 'days' };
                updates.value = value;
            } else if (!operator.multivalue && previousOperator.multivalue) {
                updates.value = null;
            } else if (schema.schema === 'boolean') {
                updates.value = true;
            } else {
                updates.value = value || getDefaultValueForSchema(schema);
            }

            if (shouldRestoreOriginalSchemaType) {
                const { type } = this.getSchemaById(schema.id);
                if (type) {
                    updates.schema = { ...schema, type };
                }
            }

            return updates;
        },
        addSegmentLimitTooltip (schemaOptions) {
            return schemaOptions.map((schema) => {
                if (schema.id === 'segment' && this.nestedSegmentCount >= SEGMENT_RULE_LIMIT) {
                    return {
                        ...schema,
                        disabled: true,
                        tooltipContent: "You've reached the limit of segments you can use"
                    };
                }

                if (schema.id === 'segment' && this.hasParentSegments) {
                    return {
                        ...schema,
                        disabled: true,
                        tooltipContent: 'Segments that are already nested cannot contain nested segment rules'
                    };
                }

                return schema;
            });
        },
        getSchemaById (id) {
            if (Array.isArray(this.computedData.filteredSchemaList)) {
                return this.computedData.filteredSchemaList.find((schema) => schema.id === id);
            }

            return undefined;
        }
    }
};
</script>

<style lang="scss">
.rule-editor {
    &--item {
        &.complex-rule {
            display: grid;
            grid-template-columns: auto auto;
            grid-gap: 16px;
        }
    }

    &--remove {
        & svg {
            stroke: $gray-lighter-2;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
</style>

<style lang="scss" scoped>
.rule-editor {
    &--items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        grid-gap: 16px;
        margin-bottom: 1.5em;
    }

    &--or-separator {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5em;
        border-top: 1px solid $gray-lighter-5;
        padding-top: 1.5em;

        &--text {
            color: $gray-lighter-2;
            font-weight: bold;
        }
    }

    &--first-rule {
        float: right;
    }

    &--remove {
        height: 14px;
        line-height: 14px;
    }
}
</style>
