<template>
    <div :class="wrapperClass">
        <div class="settings-panel--divider" />
        <div class="settings-panel__section">
            <slot name="beforeMetric" />
            <div class="section-label">
                {{ title }}
                <pendo-icon
                    v-if="tooltip"
                    v-pendo-tooltip="tooltip"
                    type="info"
                    size="14" />
            </div>
            <pendo-metric
                :value="value"
                :loading="loading" />
            <slot name="afterMetric" />
        </div>
    </div>
</template>

<script>
import { PendoIcon, PendoMetric, PendoTooltip } from '@pendo/components';

export default {
    name: 'SegmentMetricPanel',
    components: {
        PendoIcon,
        PendoMetric
    },
    directives: {
        PendoTooltip
    },
    props: {
        title: {
            type: String,
            default: undefined
        },
        value: {
            type: String,
            default: undefined
        },
        loading: {
            type: Boolean,
            default: false
        },
        wrapperClass: {
            type: String,
            default: undefined
        },
        tooltip: {
            type: Object,
            default: () => null
        }
    }
};
</script>
