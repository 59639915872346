<template>
    <div class="poll-rule">
        <!-- poll guide list picker - same as guide list picker except filtered to guides with polls -->
        <div :class="{ 'guide-undefined': rule.guideDeleted }">
            <pendo-multiselect
                :value="rule.guide"
                :allow-empty="false"
                :options="pollGuideListPickerOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                placeholder="Select Poll"
                class="poll-rule--guide-select"
                @select="onGuideSelect">
                <template #option="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
            </pendo-multiselect>
            <rule-warning v-if="rule.guideDeleted">
                The original guide for this rule has been deleted
            </rule-warning>
        </div>
        <!-- poll question picker - choose from all available polls on a selected guide -->
        <pendo-multiselect
            v-if="rule.guide"
            :value="rule.poll"
            :allow-empty="false"
            :options="pollQuestionPickerOptions"
            :searchable="true"
            :preselect-first="true"
            :disabled="readOnly"
            placeholder="Select Question"
            label-key="question"
            value-key="id"
            full-width
            class="poll-rule--poll-select"
            @select="$emit('input', { field: 'poll', value: $event })" />
        <pendo-multiselect
            v-if="showOperatorField"
            :value="rule.operator"
            :allow-empty="false"
            :preselect-first="true"
            :options="pollOperatorPickerOptions"
            :disabled="!operators[rule.schema.schema] || readOnly"
            min-trigger-width="100%"
            value-key="value"
            class="rule-editor--operator"
            @select="$emit('input', { field: 'operator', value: $event })" />
        <simple-value-inputs
            v-if="showValueField"
            :config="config"
            :operators="operators"
            :rule="rule"
            v-on="$listeners" />

        <pendo-multiselect
            v-if="showTimeSelector"
            :value="pollTimeLabelObject"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="pollTimeRangeOptions"
            :disabled="readOnly"
            class="poll-rule--page-time-operator"
            @select="handlePollTimeSelection" />
        <component
            :is="timeComponent.component"
            v-if="showTimeComponent"
            v-bind="timeComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoGuideStatusOption, PendoMultiselect } from '@pendo/components';
import SimpleValueInputs from '../rule-components/SimpleValueInputs';
import RuleWarning from '../rule-components/RuleWarning';
import NumberInput from '../rule-components/NumberInput.vue';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import {
    getAbsoluteDateRangeConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import { POLL_TIME_RANGE_OPTIONS, INPUT_OPTIONS_MAP } from '@/components/segment-builder/constants/segments';
import { getEntitiesByAppSelection, getPollsForGuide } from '@/components/segment-builder/utils/utils';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'PollRule',
    components: {
        PendoGuideStatusOption,
        PendoMultiselect,
        RuleWarning,
        SimpleValueInputs,
        RelativeDateRange,
        AbsoluteDateRange,
        SingleDate,
        NumberInput
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        operators: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            pollTimeRangeOptions: POLL_TIME_RANGE_OPTIONS
        };
    },
    computed: {
        pollGuideListPickerOptions () {
            return getEntitiesByAppSelection(this.rule.app, this.computedData.guidesWithPolls, this.config);
        },
        pollOperatorPickerOptions () {
            const options = this.operators[this.rule.schema.schema] || [];
            if (this.rule?.poll?.attributes?.type !== INPUT_OPTIONS_MAP.FREE_FORM) {
                return options.filter((option) => option.value !== 'contains' && option.value !== '!contains');
            }

            return options;
        },
        pollQuestionPickerOptions () {
            return getPollsForGuide(this.rule.guide);
        },
        pollTimeLabelObject () {
            return this.pollTimeRangeOptions.find((opt) => opt.value === this.rule.time);
        },
        showOperatorField () {
            const { rule } = this;

            if (!rule.guide || !rule.poll) {
                return false;
            }

            return true;
        },
        showTimeComponent () {
            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(rule.poll && operatorValue && rule.time && rule.time !== 'ever');
        },
        timeComponent () {
            if (['withinlast', '!withinlast', 'withinnext', '!withinnext'].includes(this.rule.time)) {
                return getRelativeDateRangeConfig(this.rule);
            }

            if (['between'].includes(this.rule.time)) {
                return getAbsoluteDateRangeConfig(this.rule);
            }

            if (['>=', '<='].includes(this.rule.time)) {
                return getSingleDateConfig(this.rule, 'first');
            }

            return;
        },
        showTimeSelector () {
            const { rule } = this;

            return !!rule.poll;
        },
        showValueField () {
            const { rule } = this;

            return !!rule.poll && !/^(not)?responded$/.test(rule.operator && rule.operator.value);
        }
    },
    methods: {
        handlePollTimeSelection ($event) {
            const { value } = $event;
            // Clean up fields
            if (value !== 'between') {
                this.$emit('input', { field: 'last', value: undefined });
                if (value !== '>=' && value !== '<=') {
                    this.$emit('input', { field: 'first', value: undefined });
                }
            }

            if (value !== 'withinlast' && value !== '!withinlast') {
                const fields = [
                    { field: 'count', value: undefined },
                    { field: 'granularity', value: undefined }
                ];
                this.$emit('input', fields);
            }

            this.$emit('input', { field: 'time', value });
        },
        onGuideSelect (guide) {
            this.$emit('input', [
                { field: 'guide', value: guide },
                { field: 'guideDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.poll-rule {
    display: contents;

    .guide-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
