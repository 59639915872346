<template>
    <div
        v-if="canChangeGuideStatus"
        class="guide-status-dropdown">
        <pendo-multiselect
            :value="currentStatus"
            :allow-empty="false"
            :min-menu-width="140"
            :options="options"
            :popper-options="{ placement: 'bottom-start', class: 'guide-status-changer-panel' }"
            value-key="status"
            @select="confirmStatusChange">
            <template #trigger>
                <pendo-guide-status-trigger
                    :size="size"
                    :type="size === 'medium' ? 'fill' : undefined" />
            </template>
            <template #option="{ option }">
                <pendo-guide-status-option :option="option" />
            </template>
        </pendo-multiselect>
    </div>
    <div
        v-else
        class="guide-status-label">
        <pendo-icon
            type="circle"
            size="8"
            :fill="statusColor"
            :stroke="statusColor" />
        <span>
            {{ currentStatus.label }}
        </span>
    </div>
</template>

<script>
import { PendoMultiselect, PendoGuideStatusOption, PendoGuideStatusTrigger, PendoIcon } from '@pendo/components';
import { canEditGuide, canPublishGuide } from '@/utils/guide-permissions';
import { isScheduled, getStatus } from '@/stateless-components/guides/audit/guide-audit-utils.js';
import get from 'lodash/get';
import { GUIDE_STATUS_COLORS, GUIDE_STATUS_LABEL, GUIDE_STATUS_KEYS } from '@/constants/guide-statuses';

export default {
    name: 'GuideStatusChanger',
    components: {
        PendoMultiselect,
        PendoGuideStatusOption,
        PendoGuideStatusTrigger,
        PendoIcon
    },
    props: {
        size: {
            type: String,
            default: 'medium'
        },
        hideStagedOption: {
            type: Boolean,
            default: false
        },
        guide: {
            type: Object,
            required: true
        },
        disableStatusChange: {
            type: Boolean,
            default: false
        },
        shouldShowWasScheduled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select'],
    data () {
        return {
            pendingStatus: null,
            modalConfig: {}
        };
    },
    computed: {
        options () {
            return GUIDE_STATUS_KEYS.reduce((options, key) => {
                if (key === 'staged' && this.hideStagedOption) return options;
                if (key === 'scheduled' && !this.isScheduled) return options;
                if (key === 'public' && this.isScheduled) return options;

                options.push({
                    label: GUIDE_STATUS_LABEL[key],
                    status: key,
                    disabled: !this.isStatusOptionEnabled(key)
                });

                return options;
            }, []);
        },
        currentStatus () {
            return getStatus(this.guide, this.shouldShowWasScheduled);
        },
        isScheduled () {
            return isScheduled(this.guide, this.shouldShowWasScheduled);
        },
        statusColor () {
            return GUIDE_STATUS_COLORS[this.currentStatus.status];
        },
        canChangeGuideStatus () {
            return (
                !this.disableStatusChange &&
                this.options
                    .filter((option) => option.status !== this.currentStatus.status)
                    .some((option) => !option.disabled)
            );
        },
        segmentId () {
            return get(this.guide, 'audienceUiHint.filters[0].segmentId', null);
        }
    },
    methods: {
        confirmStatusChange ({ status }) {
            if (status === this.currentStatus.status) return;
            const { guide } = this;
            this.$emit('select', { guide, status });
        },
        isStatusOptionEnabled (targetState) {
            if (targetState === 'public' || targetState === 'scheduled') return canPublishGuide(this.guide);

            return canEditGuide({ guide: this.guide, field: 'status' }, { target: targetState });
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-status-label {
    display: grid;
    grid-template-columns: 8px auto;
    grid-gap: 8px;
    align-items: center;
    min-width: 70px;
    text-transform: capitalize;
}

.pendo-table {
    .pendo-multiselect__trigger--status {
        transition: none;
    }
}
</style>
