<template>
    <pendo-page-content>
        <div class="workflows-list">
            <div class="workflows-list__filter-bar">
                <global-filters
                    :show-segment-picker="false"
                    :show-date-picker="false"
                    class="workflows-dashboard__filter-bar">
                    <template #additional-filters>
                        <pendo-multiselect
                            class="workflows-list__created-by-filter"
                            :value="localCreatedByFilter"
                            :class="{ 'left-spacing': usesMultiApp }"
                            :allow-empty="false"
                            :options="createdByFilterOptions"
                            @select="onSelectCreatedByFilter" />
                    </template>
                    <template #right>
                        <search
                            v-model="searchValue"
                            class="workflows-dashboard__search-input"
                            placeholder="Search" />
                    </template>
                </global-filters>
            </div>
            <pendo-table
                class="workflows-list__table"
                title="Workflows"
                empty-text="No Workflows found."
                :loading="isFetchingWorkflows"
                :data="workflowsForAppFilter"
                :columns="columns"
                :filters="filters"
                :default-sort="sort"
                manage-columns
                resizable
                csv-download
                auto-height
                @column-change="handleColumnChange"
                @column-resize="handleColumnResize"
                @sort-change="handleSortChange">
                <template #actions="{ row }">
                    <pendo-actions-cell
                        :row="row"
                        :actions="tableRowActions"
                        @delete="openDeleteWorkflowModal" />
                </template>
                <template #startingStep="{ row }">
                    <pendo-app-display :apps="row.startingStep.app" />
                </template>
                <template #endingStep="{ row }">
                    <pendo-app-display :apps="row.endingStep.app" />
                </template>

                <template #empty>
                    <div class="workflows-list__empty-state">
                        <img
                            class="workflows-list__empty-state-image"
                            src="@/img/empty-states/workflows-list-empty-state.svg">

                        <h3
                            v-if="workflows.length === 0"
                            class="workflows-list__empty-state-title">
                            No workflows have been created yet
                        </h3>

                        <h3
                            v-if="workflows.length !== 0"
                            class="workflows-list__empty-state-title">
                            No workflows found
                        </h3>

                        <p
                            v-if="workflows.length === 0 && isReadOnly"
                            class="workflows-list__empty-state-body">
                            Start analyzing process completion and
                            <router-link to="/workflows/new/home">
                                run a workflow.
                            </router-link>
                        </p>

                        <p
                            v-if="workflows.length === 0 && !isReadOnly"
                            class="workflows-list__empty-state-body">
                            Start analyzing process completion and
                            <router-link to="/workflows/new/home">
                                create a new workflow.
                            </router-link>
                        </p>

                        <p
                            v-if="workflows.length !== 0"
                            class="workflows-list__empty-state-body">
                            We can’t find any workflows that match the current filters. Consider adjusting any applied
                            filters.
                        </p>
                    </div>
                </template>
            </pendo-table>

            <workflows-delete-modal
                :visible="deleteModalVisible"
                :name="deleteModalWorkflowName"
                @deleteWorkflow="onDeleteWorkflow"
                @modalClose="onDeleteModalClose" />
        </div>
    </pendo-page-content>
</template>

<script>
import WorkflowsDeleteModal from '../WorkflowsDeleteModal.vue';
import GlobalFilters from '@/components/filters/GlobalFilters';
import Search from '@/components/Search';
import moment, { DATE_FORMAT } from '@/utils/moment';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import {
    PendoMultiselect,
    PendoTable,
    PendoLoading,
    PendoActionsCell,
    PendoPageContent,
    PendoNotification,
    PendoAppDisplay
} from '@pendo/components';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    onColumnResize,
    onColumnChange,
    onSortChange
} from '@/stateless-components/utils/pendo-table-user-settings-helper.js';

export default {
    name: 'WorkflowsListContent',
    components: {
        GlobalFilters,
        PendoActionsCell,
        PendoMultiselect,
        PendoPageContent,
        PendoTable,
        Search,
        WorkflowsDeleteModal,
        PendoAppDisplay
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            workflowsListUserSettingsName: 'workflows-list',
            createdByFilterUserSettingsName: 'workflows-list-created-by-filter',
            createdByFilterOptions: [
                {
                    id: 'anyone',
                    label: 'Created by anyone'
                },
                {
                    id: 'me',
                    label: 'Created by me'
                }
            ],
            defaultSort: {
                prop: 'name',
                order: 'descending'
            },
            deleteModalVisible: false,
            deleteModalWorkflowId: '',
            deleteModalWorkflowName: '',
            localCreatedByFilter: null,
            searchValue: ''
        };
    },
    computed: {
        ...mapGetters({
            pages: 'pages/list',
            features: 'features/list',
            getAppById: 'apps/appById',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            isReadOnly: 'auth/isReadOnly',
            usesMultiApp: 'subscriptions/usesMultiApp',
            workflows: 'workflows/list',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName'
        }),
        ...mapState({
            appIdsFilter: (state) => state.filters.appIdsFilter,
            features: (state) => state.features.map,
            isFetchingWorkflows: (state) => state.workflows.isFetching,
            pages: (state) => state.pages.map,
            user: (state) => state.auth.user,
            workflowsListCreatedByFilter: (state) => state.workflows.workflowsListCreatedByFilter
        }),
        workflowsListUserSettings () {
            return this.getTableUserSettingValueByName(this.workflowsListUserSettingsName);
        },
        columns () {
            const defaultColumns = [
                {
                    fixed: true,
                    label: 'Workflow Name',
                    prop: 'name',
                    sortable: true,
                    to: (row) => ({ name: 'workflowsDetailsHome', params: { id: row.id } }),
                    type: 'link',
                    visible: true
                },
                {
                    label: 'Starting App',
                    prop: 'startingStep',
                    sortable: true,
                    sortBy: (row) => row.startingStep.app.name,
                    visible: true
                },
                {
                    label: 'Ending App',
                    prop: 'endingStep',
                    sortable: true,
                    sortBy: (row) => row.endingStep.app.name,
                    visible: true
                },
                {
                    label: 'Type',
                    prop: 'classification',
                    sortable: true,
                    visible: true,
                    formatter: (row) => (row.classification === 'nonrecurring' ? 'Non-Recurring' : 'Recurring')
                },
                {
                    formatter: (row) => (row.subscriptionSharing.permission !== 'restricted' ? 'Everyone' : 'Only Me'),
                    label: 'Visibility',
                    prop: 'subscriptionSharing.permission',
                    sortable: true,
                    visible: true
                },
                {
                    label: 'Created By',
                    prop: 'createdByUser.username',
                    sortable: true,
                    visible: true
                },
                {
                    formatter: (row) => moment(row.createdAt).format(DATE_FORMAT.full),
                    label: 'Created Date',
                    prop: 'createdAt',
                    sortable: true,
                    visible: true
                },
                {
                    label: 'Last Updated By',
                    prop: 'lastUpdatedByUser.username',
                    sortable: true,
                    visible: true
                },
                {
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full),
                    label: 'Last Updated Date',
                    prop: 'lastUpdatedAt',
                    sortable: true,
                    visible: true
                },
                {
                    type: 'actions',
                    fixed: true,
                    visible: !this.isReadOnly,
                    width: 60
                },
                {
                    formatter: (row) => this.findStepDisplayName(row.startingStep),
                    label: 'Start Step',
                    prop: 'startingStep.countableKind',
                    sortable: true,
                    sortBy: (row) => this.findStepDisplayName(row.startingStep),
                    visible: false
                },
                {
                    formatter: (row) => this.findStepDisplayName(row.endingStep),
                    label: 'End Step',
                    prop: 'endingStep.countableKind',
                    sortable: true,
                    sortBy: (row) => this.findStepDisplayName(row.endingStep),
                    visible: false
                }
            ];

            return mergeDefaultColumnsWithUserSettings(defaultColumns, this.workflowsListUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.workflowsListUserSettings);
        },
        filters () {
            return [
                {
                    prop: ['createdByUser.username'],
                    type: 'exact',
                    value: get(this.localCreatedByFilter, 'id') === 'me' ? this.user.email : ''
                },
                {
                    prop: [
                        'name',
                        'createdByUser.username',
                        'lastUpdatedByUser.username',
                        'startingStep.app.displayName',
                        'endingStep.app.displayName'
                    ],
                    value: this.searchValue
                }
            ];
        },
        tableRowActions () {
            return [
                {
                    type: 'delete',
                    icon: 'trash-2',
                    tooltip: 'Delete'
                }
            ];
        },
        workflowsForAppFilter () {
            return cloneDeep(this.workflows).reduce((workflows, workflow) => {
                let appsIncludedInFilter = 0;

                workflow = workflow.workflowSteps.reduce(
                    (workflow, step) => {
                        const countable = this[`${step.countableKind.toLowerCase()}s`][step.countableId];

                        const app = countable
                            ? this.getAppById(countable.appId)
                            : { id: '', name: '--', displayName: '' };

                        // Increment if we are not using the app filter, or using the filter and the app is included.
                        if (
                            !this.isAppIdsFilterInUse ||
                            (this.isAppIdsFilterInUse && this.appIdsFilter.includes(app.id))
                        ) {
                            appsIncludedInFilter++;
                        }

                        if (step.type === 'start') {
                            workflow.startingStep = {
                                ...step,
                                app
                            };
                        } else if (step.type === 'end') {
                            workflow.endingStep = {
                                ...step,
                                app
                            };
                        }

                        return workflow;
                    },
                    {
                        ...workflow,
                        startingStep: null,
                        endingStep: null
                    }
                );

                // No apps in the workflow is included in the current filter
                if (appsIncludedInFilter === 0) {
                    return workflows;
                }

                workflows.push(workflow);

                return workflows;
            }, []);
        }
    },
    async created () {
        this.setLocalCreatedByFilter();
        await this.fetchWorkflows();
    },
    methods: {
        ...mapActions({
            fetchWorkflows: 'workflows/fetch',
            removeWorkflow: 'workflows/remove',
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        handleColumnResize ($event) {
            const newUserSettings = onColumnResize($event, this.workflowsListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleColumnChange ($event) {
            const newUserSettings = onColumnChange($event, this.workflowsListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleSortChange ($event) {
            const newUserSettings = onSortChange($event, this.workflowsListUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        async saveUserSettings (newSettings) {
            await this.updateUserSettingByName({
                settingsName: this.workflowsListUserSettingsName,
                value: newSettings
            });
        },
        onDeleteModalClose () {
            this.deleteModalVisible = false;
            this.deleteModalWorkflowId = '';
            this.deleteModalWorkflowName = '';
        },
        async onDeleteWorkflow () {
            try {
                await this.removeWorkflow({ id: this.deleteModalWorkflowId });
                PendoNotification({
                    type: 'success',
                    message: `${this.deleteModalWorkflowName} deleted.`
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    message: `There was an error deleting ${this.deleteModalWorkflowName}. Please try again.`
                });
            }

            this.deleteModalVisible = false;
            this.deleteModalWorkflowId = '';
            this.deleteModalWorkflowName = '';
        },
        setLocalCreatedByFilter () {
            const optionId = this.getTableUserSettingValueByName(this.createdByFilterUserSettingsName);
            const matchingOption = this.createdByFilterOptions.find((opt) => opt.id === optionId);

            this.localCreatedByFilter = matchingOption || this.createdByFilterOptions[0];
        },
        async onSelectCreatedByFilter (option) {
            this.localCreatedByFilter = option;

            await this.updateUserSettingByName({
                settingsName: this.createdByFilterUserSettingsName,
                value: option.id
            });
        },
        openDeleteWorkflowModal (row) {
            this.deleteModalWorkflowId = row.id;
            this.deleteModalWorkflowName = row.name;
            this.deleteModalVisible = true;
        },
        findStepDisplayName (step) {
            const stepKind = step.countableKind;
            const stepId = step.countableId;

            if (stepKind === 'Page') {
                const page = this.pages[stepId];

                return page ? page.displayName : '';
            }
            const feature = this.features[stepId];

            return feature ? feature.displayName : '';
        }
    }
};
</script>

<style lang="scss" scoped>
.workflows-dashboard__filter-bar {
    .workflows-dashboard__search-input {
        margin-left: auto;
    }
}

.workflows-list {
    &__empty-state {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 56px auto;
        text-align: left;
        width: 300px;

        &-image {
            width: 250px;
        }

        &-title {
            font-size: 22px;
            font-weight: 600;
            line-height: 120%;
            margin: 24px 0 12px;
        }

        &-body {
            font-size: 18px;
            line-height: 25px;
            margin: 0;
        }
    }
}
</style>
