<template>
    <pendo-page-content>
        <pendo-table
            v-pendo-loading:feather="!segmentsLoaded"
            :data="segments"
            :columns="columns"
            :filters="filters"
            :default-sort="{
                prop: 'name',
                order: 'ascending'
            }"
            :max-height="600"
            title="Segments"
            row-key="id"
            class="segment-list">
            <template #headerActions>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
            <template #actions="{ row }">
                <pendo-actions-cell
                    :row="row"
                    :actions="[
                        {
                            type: 'edit',
                            icon: 'edit-2',
                            disabled: !!row.createdByApi,
                            tooltip: row.createdByApi
                                ? 'You must use the API to edit API-created segments'
                                : 'Edit segment'
                        },
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            tooltip: 'Delete segment'
                        }
                    ]"
                    @delete="confirmDeleteSegment"
                    @edit="openSegmentBuilder" />
            </template>
            <template #empty>
                <div class="guide-list-table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        {{ emptyText }}
                    </span>
                </div>
            </template>
        </pendo-table>
        <app-segment-builder
            v-if="showSegmentBuilder"
            :editing-segment="editingSegment"
            @close="closeSegmentBuilder" />
        <delete-segment-modal
            :visible="showDeleteSegment"
            :segment-id="segmentIdToDelete"
            @close="hideDeleteSegmentModal" />
    </pendo-page-content>
</template>

<script>
import get from 'lodash/get';
import { mapState, mapGetters } from 'vuex';
import { PendoActionsCell, PendoTable, PendoPageContent, PendoLoading, PendoIcon } from '@pendo/components';
import moment, { DATE_FORMAT } from '@/utils/moment';
import Search from '@/components/Search';
import AppSegmentBuilder from '@/components/segments/builder/AppSegmentBuilder.vue';
import DeleteSegmentModal from '@/components/segments/DeleteSegmentModal.vue';

export default {
    name: 'SegmentListContent',
    components: {
        AppSegmentBuilder,
        PendoPageContent,
        PendoTable,
        Search,
        PendoIcon,
        PendoActionsCell,
        DeleteSegmentModal
    },
    directives: {
        PendoLoading
    },
    data () {
        return {
            searchInput: '',
            showSegmentBuilder: false,
            editingSegment: null,
            segmentIdToDelete: '',
            showDeleteSegment: false
        };
    },
    computed: {
        ...mapState({
            segmentsLoaded: (state) => state.filters.segmentsLoaded
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup'
        }),
        emptyText () {
            if (!this.searchInput) return 'You have not created any custom segments.';

            return 'There are no segments matching that search criteria.';
        },
        segments () {
            return get(this.segmentsListByGroup, 'customList', []);
        },
        filters () {
            return [
                {
                    prop: ['name', 'createdByUser.username', 'lastUpdatedByUser.username'],
                    type: 'includes',
                    value: this.searchInput
                }
            ];
        },
        columns () {
            return [
                {
                    sortable: true,
                    prop: 'name',
                    label: 'Segment Name',
                    minWidth: 300
                },
                {
                    sortable: true,
                    prop: 'createdByUser.username',
                    label: 'Created By'
                },
                {
                    sortable: true,
                    prop: 'lastUpdatedByUser.username',
                    label: 'Last Updated By'
                },
                {
                    sortable: true,
                    prop: 'lastUpdatedAt',
                    label: 'Last Updated At',
                    formatter: (row) => moment(row.lastUpdatedAt).format(DATE_FORMAT.full),
                    minWidth: 100
                },
                {
                    type: 'actions',
                    width: 60
                }
            ];
        }
    },
    methods: {
        openSegmentBuilder (segment) {
            this.editingSegment = segment;
            this.showSegmentBuilder = true;
        },
        closeSegmentBuilder () {
            this.editingSegment = null;
            this.showSegmentBuilder = false;
        },
        confirmDeleteSegment ({ id }) {
            this.segmentIdToDelete = id;
            this.showDeleteSegment = true;
        },
        hideDeleteSegmentModal () {
            this.segmentIdToDelete = '';
            this.showDeleteSegment = false;
        }
    }
};
</script>
<style lang="scss" scoped>
:deep(.pendo-table__title-actions) {
    width: 300px;
}
</style>
