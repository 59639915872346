import moment from 'moment-timezone';
import isPlainObject from 'lodash/isPlainObject';

export default moment;

export const convertToSubscriptionTimezone = (activeTimezone, ...args) => moment(...args).tz(activeTimezone);

export const DATE_FORMAT = {
    iso: 'YYYY-MM-DD',
    short: 'MMM D, YYYY',
    time: 'h:mma',
    full: 'MMM D, YYYY h:mma z',
    isoDateTime: 'YYYY-MM-DD hh:mm:ss'
};

// * -1 to undo POSIX compat https://momentjs.com/timezone/docs/#/zone-object/offset/
export function getSubscriptionUtcOffset (activeTimezone) {
    return (
        moment()
            .tz(activeTimezone)
            .utcOffset() * -1
    );
}

export function formatTimeValue (value) {
    let formattedValue;

    if (isPlainObject(value)) {
        formattedValue = Object.entries(value).reduce((acc, [key, val]) => {
            acc[key] = formatTimeValue(val);

            return acc;
        }, {});
    } else {
        formattedValue = moment.utc(value).format(DATE_FORMAT.iso);
    }

    return formattedValue;
}

export function formatTimeFilterSummary (valueFromTimeSchema) {
    let value;

    if (isPlainObject(valueFromTimeSchema)) {
        if (valueFromTimeSchema.first && valueFromTimeSchema.last) {
            value = [
                moment(valueFromTimeSchema.first).format(DATE_FORMAT.short),
                moment(valueFromTimeSchema.last).format(DATE_FORMAT.short)
            ].join(' and ');
        } else {
            value = Object.keys(valueFromTimeSchema)
                .map((key) => valueFromTimeSchema[key])
                .join(' ');
        }
    } else {
        value = moment(valueFromTimeSchema).format(DATE_FORMAT.short);
    }

    return value;
}

export function formatTimeRangeForTable (timeInMs) {
    const momentTime = moment.duration(timeInMs);

    const days = momentTime.days();
    const hours = momentTime.hours();
    const minutes = momentTime.minutes();
    const seconds = momentTime.seconds();

    let formattedString = '';

    if (seconds) {
        formattedString = `${seconds}s ${formattedString}`;
    }
    if (minutes) {
        formattedString = `${minutes}m ${formattedString}`;
    }
    if (hours) {
        formattedString = `${hours}h ${formattedString}`;
    }
    if (days) {
        formattedString = `${days}d ${formattedString}`;
    }

    return formattedString.trim();
}

export const splitTimeParts = (time) => {
    if (!time || time === '-') {
        return { hours: 0, minutes: 0, amPm: 'am' };
    }

    const reAmPm = /am|pm/;
    const [amPm] = reAmPm.exec(time);
    let [hours, minutes] = time.replace(reAmPm, '').split(':');

    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (hours === 12) {
        hours = 0;
    }

    if (amPm === 'pm') {
        hours += 12;
    }

    return {
        hours,
        minutes,
        amPm
    };
};
