<template>
    <pendo-poll-metrics-table
        :guide="guide"
        :polls="polls"
        :views="views"
        :views-agg-status="viewsAggStatus"
        :response-count="responseCount"
        :response-count-agg-status="responseCountAggStatus"
        @poll-link-click="onPollLinkClick" />
</template>

<script>
import { PendoPollMetricsTable } from '@pendo/components';
import { connectAggregationToState, withStore, withComponent } from '@pendo/agg-connect';
import { iterativeToAggregativePeriod } from '@/utils/time-series';
import PollResponseCountAgg from '@/aggregations/poll-response-count';
import ViewsByStepAgg from '@/aggregations/views-by-step';
import { mapGetters } from 'vuex';

export default {
    name: 'PollMetricsTable',
    components: {
        PendoPollMetricsTable
    },
    data () {
        return {
            views: null,
            viewsAggStatus: 'loading',
            responseCount: null,
            responseCountAggStatus: 'loading'
        };
    },
    computed: {
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            guide: 'guides/active',
            polls: 'guides/activePolls'
        }),
        timeSeries () {
            let { period } = this.activeTimeSeries;
            period = iterativeToAggregativePeriod(period) || period;

            return {
                ...this.activeTimeSeries,
                period
            };
        }
    },
    created () {
        this.createViewsAgg$();
        this.createResponseCountAgg$();
    },
    methods: {
        onPollLinkClick (row) {
            this.$router.push({ name: 'guidePolls', query: { pollId: row.id } }).catch(() => {});
        },
        createResponseCountAgg$ () {
            const responseCountAgg$ = connectAggregationToState(
                PollResponseCountAgg,
                withComponent({
                    timeSeries: 'timeSeries'
                }),
                withStore({
                    segmentId: (state) => state.filters.activeSegmentId,
                    guideId: (state) => state.guides.activeId
                })
            )({ component: this, store: this.$store });

            responseCountAgg$.subscribe(({ status, value }) => {
                this.responseCountAggStatus = status;
                switch (status) {
                    case 'loading':
                        this.responseCount = null;
                        break;
                    case 'resolved': {
                        this.responseCount = value;
                        break;
                    }
                }
            });
        },
        createViewsAgg$ () {
            const viewsAgg$ = connectAggregationToState(
                ViewsByStepAgg,
                withComponent({
                    timeSeries: 'timeSeries'
                }),
                withStore({
                    segmentId: (state) => state.filters.activeSegmentId,
                    guideId: (state) => state.guides.activeId
                })
            )({ component: this, store: this.$store });

            viewsAgg$.subscribe(({ status, value }) => {
                this.viewsAggStatus = status;
                switch (status) {
                    case 'loading':
                        this.views = null;
                        break;
                    case 'resolved': {
                        this.views = value;
                        break;
                    }
                }
            });
        }
    }
};
</script>
