<template>
    <main class="pages-list">
        <pendo-countable-empty-state
            v-if="showNoPagesCTA"
            :is-read-only="!canCreatePage({ filtered: true })"
            countable-type="page"
            theme="app"
            @tag-countable="showPageModal" />
        <pendo-table
            v-else
            v-pendo-loading:feather="isFetchingPageList"
            :data="tableData"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :resizable="true"
            :max-height="600"
            :auto-height-offset="96"
            csv-download
            manage-columns
            resizeable
            title="Pages"
            row-key="id"
            :row-class-name="getRowClassName"
            class="pages-list--table"
            @column-change="handleColumnChange"
            @column-resize="handleColumnResize"
            @sort-change="handleSortChange">
            <template #headerActions>
                <div
                    v-if="activeIsDigitalAdoption"
                    class="no-data-toggle">
                    <pendo-multiselect
                        v-if="!showNoPagesCTA"
                        v-model="pageDataFilter"
                        :options="pageDataFilterOptions"
                        :allow-empty="false"
                        preselect-first />
                </div>
                <div
                    v-else
                    class="pages-list--table-header">
                    <pendo-button
                        v-if="canCreatePage({ filtered: false })"
                        type="link"
                        theme="app"
                        prefix-icon="plus"
                        label="Add Custom Pages"
                        @click="openAddCustomPagesModal" />
                </div>
            </template>
            <template #displayName="{ row }">
                <div
                    data-cy="pages-list--table-display-name"
                    class="pages-list--table-display-name"
                    @click="openDetailsPanel(row)">
                    <span class="pages-list--table-name-link">{{ rowDisplayName(row) }}</span>
                    <span
                        v-if="row.dirty && !isInstalled(row)"
                        class="pages-list--table-name-tag">
                        Awaiting Install
                    </span>
                    <span
                        v-else-if="row.dirty"
                        class="pages-list--table-name-tag">
                        Processing
                    </span>
                </div>
            </template>
            <template #appDisplayName="{ row }">
                <pendo-app-display :apps="row.app" />
            </template>
            <template #empty>
                <div class="pages-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
            <template #allRules="{ row }">
                <countable-table-rules
                    :rules="row.rules"
                    :is-expanded="!!expandedRules[row.id]"
                    @expanded="onRulesExpanded(row, $event)" />
            </template>
            <template #allExcludeRules="{ row }">
                <countable-table-rules
                    :rules="!!row.excludeRules ? row.excludeRules : [{ rule: '---' }]"
                    :is-expanded="!!expandedExcludeRules[row.id]"
                    @expanded="onExcludeRulesExpanded(row, $event)" />
            </template>
        </pendo-table>
        <add-custom-pages-modal
            :visible="isAddCustomPagesModalVisible"
            @close="closeAddCustomPagesModal" />
        <delete-custom-entity-modal
            type="page"
            :visible="isDeleteCustomPageModalVisible"
            :entity="currentlyDeletingRow"
            @close="closeDeleteCustomPageModal($event)" />
        <page-details-sidebar
            v-if="sidebarVisible"
            :visible="sidebarVisible"
            :page-id="currentlyViewingRow.id"
            @delete-page="openDeleteCustomPageModal($event)"
            @close-sidebar="closeDetailsPanel" />
    </main>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import { formatTimeRangeForTable } from '@/utils/moment';
import { filterEntitiesByAppIds } from '@/utils/apps';
import AddCustomPagesModal from './AddCustomPagesModal';
import {
    PendoButton,
    PendoIcon,
    PendoTable,
    PendoLoading,
    PendoCountableEmptyState,
    PendoTooltip,
    PendoMultiselect,
    PendoAppDisplay,
    PendoNotification
} from '@pendo/components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import DeleteCustomEntityModal from '@/components/common/DeleteCustomEntityModal.vue';
import { filterFnPageByUrlMatch } from '@/utils/pages';
import { isValidUrl } from '@pendo/services/Formatters';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import { CountableTableRules, countableType, isCustomCountable, isInactiveCountable } from '@pendo/tagging';
import PageDetailsSidebar from '@/components/analytics/pages/PageDetailsSidebar.vue';
import {
    onColumnResize,
    onColumnChange,
    onSortChange
} from '@/stateless-components/utils/pendo-table-user-settings-helper.js';

export default {
    name: 'PagesList',
    components: {
        PendoButton,
        PendoIcon,
        PendoTable,
        AddCustomPagesModal,
        PendoCountableEmptyState,
        DeleteCustomEntityModal,
        PendoMultiselect,
        PendoAppDisplay,
        CountableTableRules,
        PageDetailsSidebar
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        searchString: {
            type: String,
            default: null
        },
        pageFilterSelection: {
            type: String,
            default: 'All Pages'
        }
    },
    data () {
        return {
            isAddCustomPagesModalVisible: false,
            isEditCustomPageModalVisible: false,
            isDeleteCustomPageModalVisible: false,
            currentlyDeletingRow: {},
            currentlyViewingRow: {},
            pagesListUserSettingsName: 'pagesList',
            defaultSort: {
                prop: 'numVisitors',
                order: 'descending'
            },
            pageDataFilterOptions: ['All', 'With Data', 'Without Data'],
            pageDataFilter: null,
            expandedRules: {},
            expandedExcludeRules: {}
        };
    },

    computed: {
        ...mapGetters({
            pages: 'pages/list',
            allPages: 'pages/listAll',
            allPagesWithAnalytics: 'pages/listAllWithAnalytics',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            filteredApps: 'filters/appsForAppIdFilter',
            isAppIdsFilterInUse: 'filters/isAppIdsFilterInUse',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName',
            canCreatePage: 'pages/canCreatePage',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription'
        }),
        ...mapState({
            activeSubHasAnyPages: (state) => state.pages.activeSubHasAnyPages,
            isFetchingPageList: (state) => state.pages.isFetchingWithAnalytics,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            suggestedPageRules: (state) => state.pages.suggestedPageRules
        }),
        barePageList () {
            return filterEntitiesByAppIds(this.allPages, this.appIdsFilter);
        },
        pagesWithAnalyticsClone () {
            return [...this.allPagesWithAnalytics];
        },
        filters () {
            const filters = [
                {
                    filterFn: this.filterByPageUrl.bind(this)
                },
                {
                    prop: ['displayName'],
                    value: !isValidUrl(this.searchString) ? this.searchString : ''
                }
            ];

            if (this.pageFilterSelection === 'Custom Pages Only') {
                filters.push({
                    filterFn: (row) => this.isCustomPage(row)
                });
            }

            return filters;
        },
        savedTableUserSettings () {
            return this.getTableUserSettingValueByName(this.pagesListUserSettingsName);
        },
        defaultColumns () {
            const defaultColumns = [
                {
                    sortable: true,
                    prop: 'displayName',
                    label: 'Page Name',
                    visible: true,
                    width: 288
                },
                {
                    sortable: true,
                    prop: 'type',
                    label: 'Type',
                    visible: true,
                    width: 180
                }
            ];

            defaultColumns.push(
                {
                    sortable: true,
                    prop: 'appDisplayName',
                    label: 'Application',
                    visible: true
                },
                {
                    sortable: true,
                    prop: 'allRules',
                    label: 'Include Rules',
                    visible: true
                },
                {
                    sortable: true,
                    prop: 'allExcludeRules',
                    label: 'Exclude Rules',
                    visible: true
                },

                {
                    sortable: true,
                    prop: 'numVisitors',
                    label: 'Number of Visitors',
                    visible: true,
                    async: true
                },
                {
                    sortable: true,
                    prop: 'pageLoads',
                    label: 'Page Views',
                    visible: true,
                    async: true
                },
                {
                    sortable: true,
                    prop: 'avgTime',
                    label: 'Average Time on Page',
                    formatter: this.avgTimeOnPageFormatter,
                    visible: true,
                    async: true
                }
            );

            return defaultColumns;
        },
        columns () {
            return mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.savedTableUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.savedTableUserSettings);
        },
        appsWithoutPages () {
            let apps = [];
            if (this.isAppIdsFilterInUse) {
                const appsWithTaggedPages = this.pages.map((pages) => {
                    return pages.app.id;
                });
                apps = this.filteredApps.filter(
                    (app) => !appsWithTaggedPages.find((pageAppId) => pageAppId === app.id)
                );
            }

            return apps;
        },
        appsWithoutSuggestedPages () {
            let apps = [];
            if (this.isAppIdsFilterInUse) {
                const appsWithSuggestedPages = this.suggestedPageRules.map((pages) => {
                    return pages.appId;
                });
                apps = this.filteredApps.filter(
                    (app) => !appsWithSuggestedPages.find((pageAppId) => pageAppId === app.id)
                );
            }

            return apps;
        },
        showNoPagesCTA () {
            const filteredAppsHaveNoPages = this.appsWithoutPages.length === this.filteredApps.length;
            const filteredAppsHaveNoSuggestedPages =
                this.appsWithoutPages.length === this.appsWithoutSuggestedPages.length;
            const showAppLevelCta = filteredAppsHaveNoPages && filteredAppsHaveNoSuggestedPages;

            return (
                ((!this.suggestedPageRules.length && !this.activeSubHasAnyPages) || showAppLevelCta) &&
                this.activeIsDigitalAdoption
            );
        },
        getPages () {
            const pagesAndAnalytics = this.barePageList.reduce((acc, barePage) => {
                const isChildPage = get(barePage, 'isChildPage', false);
                if (isChildPage) return acc;

                const pageHasAnalytics = acc.find(
                    (existingPage) =>
                        existingPage.id === barePage.id &&
                        existingPage.numVisitors &&
                        existingPage.pageLoads &&
                        existingPage.avgTime
                );
                if (pageHasAnalytics) {
                    return acc;
                }

                const pageToAdd = {
                    ...barePage,
                    numVisitors: 0,
                    pageLoads: 0,
                    avgTime: 0
                };

                acc.push(pageToAdd);

                return acc;
            }, this.pagesWithAnalyticsClone);

            if (pagesAndAnalytics.length) {
                return pagesAndAnalytics
                    .map((page) => {
                        page.type = countableType(page);
                        page.appDisplayName = get(page, 'app.displayName', '');

                        return page;
                    })
                    .filter((page) => {
                        if (!this.activeIsDigitalAdoption) return true;
                        if (this.pageDataFilter === 'Without Data') return page.numVisitors === 0;
                        if (this.pageDataFilter === 'With Data') return page.numVisitors > 0;

                        return true;
                    });
            }

            return pagesAndAnalytics || [];
        },
        tableData () {
            return this.getPages.map((row) => {
                return {
                    ...row,
                    allRules: JSON.stringify(row.rules.map((ruleObj) => ruleObj.rule)),
                    allExcludeRules: JSON.stringify(get(row, 'excludeRules', []).map((ruleObj) => ruleObj.rule))
                };
            });
        },
        sidebarVisible () {
            return !isEmpty(this.currentlyViewingRow);
        }
    },
    watch: {
        allPages: {
            handler (newValue) {
                const { id } = this.$route.query;
                if (!id || newValue.length === 0) return;

                const openPage = this.allPages.find((page) => page.id === id);

                if (openPage) {
                    this.currentlyViewingRow = openPage;
                    this.setPageDetailsSidebarOpen({ isPageDetailsSidebarOpen: true });
                } else {
                    this.$router.push({ path: 'pages', query: {} });
                    PendoNotification({
                        type: 'error',
                        title: 'Unable to view page',
                        message: 'The page was not found.',
                        duration: 5000
                    });
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            setPageDetailsSidebarOpen: 'pages/setPageDetailsSidebarOpen'
        }),
        ...mapActions({
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        handleColumnResize ($event) {
            const newUserSettings = onColumnResize($event, this.savedTableUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleColumnChange ($event) {
            const newUserSettings = onColumnChange($event, this.savedTableUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        handleSortChange ($event) {
            const newUserSettings = onSortChange($event, this.savedTableUserSettings);
            this.saveUserSettings(newUserSettings);
        },
        async saveUserSettings (newSettings) {
            await this.updateUserSettingByName({
                settingsName: this.pagesListUserSettingsName,
                value: newSettings
            });
        },
        isCustomPageNameEditDisabled (row) {
            if (get(this.currentlyEditingRow, 'id')) {
                return this.currentlyEditingRow.id !== row.id;
            }

            return false;
        },
        getRowClassName ({ row }) {
            return this.currentlyViewingRow.id === row.id ? 'selected' : '';
        },
        isCustomPage (row) {
            return isCustomCountable(row);
        },
        avgTimeOnPageFormatter (row) {
            if (row.avgTime <= 0) return '---';

            return formatTimeRangeForTable(row.avgTime);
        },
        openAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = true;
        },
        closeAddCustomPagesModal () {
            this.isAddCustomPagesModalVisible = false;
        },
        openDeleteCustomPageModal (row) {
            this.currentlyDeletingRow = row;
            this.isDeleteCustomPageModalVisible = true;
        },
        popDeleteNotification ({ name = '' }) {
            PendoNotification({
                type: 'success',
                title: 'Deleted Page Successfully',
                message: `The Page ${name} was successfully deleted.`,
                duration: 2000,
                showClose: false
            });
        },
        closeDeleteCustomPageModal ({ didDelete = false }) {
            if (didDelete) {
                this.closeDetailsPanel(); // close the sidebar after deletion
                this.popDeleteNotification(this.currentlyDeletingRow);
            }
            this.currentlyDeletingRow = {};
            this.isDeleteCustomPageModalVisible = false;
        },
        openDetailsPanel (row) {
            this.setPageDetailsSidebarOpen({ isPageDetailsSidebarOpen: true });
            this.currentlyViewingRow = row;
            this.$router.push({ path: 'pages', query: { id: row.id } });
        },
        closeDetailsPanel () {
            this.setPageDetailsSidebarOpen({ isPageDetailsSidebarOpen: false });
            this.currentlyViewingRow = {};
            this.$router.push({ query: {} });
        },
        isInstalled (row) {
            return get(row, 'app.integrated');
        },
        filterByPageUrl (row) {
            if (!isValidUrl(this.searchString)) return true;

            return filterFnPageByUrlMatch(row, this.searchString);
        },
        deleteTooltip (row) {
            switch (countableType(row)) {
                case 'Inherited':
                    return 'Inherited Pages cannot be deleted';
                case 'Inactive':
                    return this.activeIsTrainingSubscription ? 'Inactive Pages cannot be deleted' : null;
                default:
                    return null;
            }
        },
        showPageModal () {
            this.$emit('openLaunchDesignerModal', {
                title: 'Launch to edit',
                type: 'page',
                translationWarning: false
            });
        },
        onRulesExpanded (row, expanded) {
            this.$set(this.expandedRules, row.id, expanded);
        },
        onExcludeRulesExpanded (row, expanded) {
            this.$set(this.expandedExcludeRules, row.id, expanded);
        },
        rowDisplayName (row) {
            const nameKey = isInactiveCountable(row) ? 'name' : 'displayName';

            return row[nameKey];
        }
    }
};
</script>

<style lang="scss">
.pages-list--table-display-name:hover .pages-list--table-name-link,
.pages-list--table-display-name.hover .pages-list--table-name-link {
    text-decoration: underline;
    color: $teal-darker-1;
}

.pages-list {
    :deep(.pendo-table__link:hover) {
        text-decoration: none;

        .page-list-table__name__link {
            text-decoration: underline;
        }
    }

    &--table {
        .selected {
            background-color: $color-blue-5;
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
        }

        &-display-name {
            display: flex;
            align-items: baseline;
            cursor: pointer;
        }

        &-name-link {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $teal-primary;
            font-weight: 600;
        }

        &-name-tag {
            font-style: italic;
            font-size: 12px;
            font-weight: 400;
            margin-left: 4px;
            color: $gray-lighter-2;
        }

        .no-data-toggle {
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: right;
            flex-grow: 1;
        }

        &--is-custom-page {
            font-size: 10px;
            letter-spacing: 1.5px;
            margin-top: 0;
            line-height: 120%;
            color: $gray-lighter-2;
            text-transform: uppercase;
        }

        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }

            &__cta {
                height: 400px;

                .designer-launcher-cta {
                    margin: 18px 0;
                }
            }
        }

        .pendo-table__title-actions {
            grid-template-columns: auto 36px;
            flex-grow: 1;
            justify-content: flex-end;
        }
    }

    .visibility-note {
        font-size: 0.875rem;
        padding-left: 10px;
    }
}
</style>
