import { formatTimeValue } from '../moment';

export function parsePollFilterPipeline ({ operator, time, ...params }) {
    let filterString;

    switch (operator) {
        case 'responded':
            filterString = buildPollRespondedFilterString(params);
            break;
        case 'notresponded':
            filterString = buildPollNotRespondedFilterString(params);
            break;
        case 'contains':
            filterString = buildPollContainsFilterString(params);
            break;
        case '!contains':
            filterString = buildPollNotContainsFilterString(params);
            break;
        case '!=':
            filterString = buildPollResponseNotEqualsFilterString({ operator, ...params });
            break;
        default:
            filterString = buildPollResponseEqualsFilterString({ operator, ...params });
    }

    switch (time) {
        case '<=':
            filterString += `&&${buildPollTimeBeforeFilterString(params)}`;
            break;
        case '>=':
            filterString += `&&${buildPollTimeSinceFilterString(params)}`;
            break;
        case 'withinlast':
            filterString += `&&${buildPollTimeWithinLastFilterString(params)}`;
            break;
        case '!withinlast':
            filterString += `&&${buildPollTimeNotWithinLastFilterString(params)}`;
            break;
        case 'between':
            filterString += `&&${buildPollTimeBetweenFilterString(params)}`;
            break;
        // default 'ever'; nothing appended
    }

    return { filter: filterString };
}

export function buildPollRespondedFilterString ({ filterIndex }) {
    return `!isNull(pollResponse${filterIndex})`;
}

export function buildPollNotRespondedFilterString ({ filterIndex }) {
    return `isNull(pollResponse${filterIndex})`;
}

export function buildPollContainsFilterString ({ filterIndex, value }) {
    return `contains(pollResponse${filterIndex},${value})`;
}

export function buildPollNotContainsFilterString ({ filterIndex, value }) {
    return `!isNull(pollResponse${filterIndex})&&!contains(pollResponse${filterIndex},${value})`;
}

export function buildPollResponseEqualsFilterString ({ filterIndex, operator, value }) {
    return `pollResponse${filterIndex}${operator}${value}`;
}

export function buildPollResponseNotEqualsFilterString ({ filterIndex, operator, value }) {
    return `!isNull(pollResponse${filterIndex})&&pollResponse${filterIndex}${operator}${value}`;
}

export function buildPollTimeBeforeFilterString ({ filterIndex, first }) {
    return `pollTime${filterIndex}<=date("${formatTimeValue(first)}")`;
}

export function buildPollTimeSinceFilterString ({ filterIndex, first }) {
    return `pollTime${filterIndex}>=date("${formatTimeValue(first)}")`;
}

export function buildPollTimeWithinLastFilterString ({ filterIndex, count, granularity }) {
    return `pollTime${filterIndex}>=dateAdd(now(),-${count},"${granularity}")`;
}

export function buildPollTimeNotWithinLastFilterString ({ filterIndex, count, granularity }) {
    return `(pollTime${filterIndex}>=now()||pollTime${filterIndex}<=dateAdd(now(),-${count},"${granularity}"))`;
}

export function buildPollTimeBetweenFilterString ({ filterIndex, first, last }) {
    return `(pollTime${filterIndex}>=date("${formatTimeValue(first)}")&&pollTime${filterIndex}<=date("${formatTimeValue(
        last
    )}"))`;
}
