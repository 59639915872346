/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { buildGroupValueGrouping } from '@/aggregations/utils';
import {
    request,
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { identifiedState } from '@/utils/aggregations';
import { iterativeToAggregativePeriod } from '@/utils/time-series';
import isEmpty from 'lodash/isEmpty';

// Add this function to src/utils/aggregations.js in order to pass SonarCloud analysis
export function getProductAreaSubProductArea () {
    return {
        productArea: PRODUCT_AREAS.ANALYTICS,
        subProductArea: SUB_PRODUCT_AREAS.PORTFOLIO_OVERVIEW
    };
}

// chart
export function getAppUsage ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries, signal }) {
    let spec;
    if (isEmpty(metadataField)) {
        spec = buildAppUsageByTotalSpec({ appId, defaultMetadataField, segmentId, timeSeries });
    } else {
        spec = buildAppUsageByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
    }

    return request(spec, { signal });
}

// This is temporary to unify the UIs, as aggs run differently in each UI
export function getAppUsageAggSpec ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries }) {
    if (isEmpty(metadataField)) {
        return buildAppUsageByTotalSpec({ appId, defaultMetadataField, segmentId, timeSeries });
    }

    return buildAppUsageByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
}

export function buildAppUsageByTotalSpec ({ appId, defaultMetadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'portfolio-app-usage-by-total-adopt',
            ...getProductAreaSubProductArea()
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                ['appId'],
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)',
                groupValue: `"${defaultMetadataField}"`
            }),
            o.select({
                id: 'appId',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors',
                groupValue: 'groupValue'
            })
        )
    );
}

export function buildAppUsageByMetadataSpec ({ appId, metadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;
    const { group, field } = metadataField;
    const visitorMetadataField = `visitor.${group}.${field}`;

    return o.aggregation(
        {
            name: 'portfolio-app-usage-by-metadata-adopt',
            ...getProductAreaSubProductArea()
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.bulkExpand('visitor'),
            ...computeGroupValue(visitorMetadataField),
            ...limitToTopGroupValues(20, {
                appId,
                timeSeries,
                segmentId,
                visitorMetadataField
            }),
            ...buildGroupValueGrouping({
                columns: ['appId', 'groupValue'],
                fields: [
                    {
                        summedMinutes: {
                            sum: 'numMinutes'
                        }
                    },
                    {
                        visitors: {
                            count: 'visitorId'
                        }
                    },
                    {
                        visitorsByDay: {
                            count: ['visitorId', 'day']
                        }
                    }
                ]
            }),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)'
            }),
            o.select({
                id: 'appId',
                groupValue: 'groupValue',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors'
            })
        )
    );
}

// This is temporary to unify the UIs, as aggs run differently in each UI
export function getAppUsageBreakdownAggSpec ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries }) {
    if (isEmpty(metadataField)) {
        return buildAppUsageBreakdownByTotalSpec({
            appId,
            defaultMetadataField,
            segmentId,
            timeSeries
        });
    }

    return buildAppUsageBreakdownByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
}

// table
export function getAppUsageBreakdown ({ appId, metadataField, defaultMetadataField, segmentId, timeSeries, signal }) {
    let spec;
    if (isEmpty(metadataField)) {
        spec = buildAppUsageBreakdownByTotalSpec({
            appId,
            defaultMetadataField,
            segmentId,
            timeSeries
        });
    } else {
        spec = buildAppUsageBreakdownByMetadataSpec({ appId, metadataField, segmentId, timeSeries });
    }

    return request(spec, { rowsOnly: false, signal });
}

export function buildAppUsageBreakdownByTotalSpec ({ appId, defaultMetadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'portfolio-app-usage-breakdown-by-total-adopt',
            ...getProductAreaSubProductArea()
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                'appId',
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('visitors', o.count('visitorId')),
                o.groupField('visitorsByDay', o.count(['visitorId', 'day']))
            ),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)',
                groupValue: `"${defaultMetadataField}"`
            }),
            o.select({
                id: 'appId',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors',
                groupValue: 'groupValue'
            })
        )
    );
}

export function buildAppUsageBreakdownByMetadataSpec ({ appId, metadataField, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    const { group, field } = metadataField;
    const visitorMetadataField = `visitor.${group}.${field}`;

    return o.aggregation(
        {
            name: 'portfolio-app-usage-breakdown-by-metadata-adopt',
            ...getProductAreaSubProductArea()
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.bulkExpand('visitor'),
            ...computeGroupValue(visitorMetadataField),
            ...limitToTopGroupValues(501, {
                appId,
                timeSeries,
                segmentId,
                visitorMetadataField
            }),
            ...buildGroupValueGrouping({
                columns: ['appId', 'groupValue'],
                fields: [
                    {
                        summedMinutes: {
                            sum: 'numMinutes'
                        }
                    },
                    {
                        visitors: {
                            count: 'visitorId'
                        }
                    },
                    {
                        visitorsByDay: {
                            count: ['visitorId', 'day']
                        }
                    }
                ]
            }),
            o.evalExpression({
                averageTime: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitors)',
                visitors: 'if(isNull(visitors), 0, visitors)',
                averageDailyTimeOnApp: 'if(isNull(visitors) || visitors == 0, 0, summedMinutes / visitorsByDay)'
            }),
            o.select({
                id: 'appId',
                groupValue: 'groupValue',
                averageTime: 'averageTime',
                averageDailyTimeOnApp: 'averageDailyTimeOnApp',
                summedMinutes: 'summedMinutes',
                visitors: 'visitors'
            })
        )
    );
}

function computeGroupValue (visitorMetadataField) {
    const { operators: o } = Agg2;

    return [
        o.evalExpression({
            groupValue: visitorMetadataField
        }),
        o.evalExpression({
            groupValue: 'if(isNull(groupValue), "(Not Specified)", groupValue)'
        }),
        o.evalExpression({
            groupValue: 'if(isNumber(groupValue), toString(groupValue), groupValue)'
        }),
        o.evalExpression({
            groupValue: 'if(groupValue == true, "true", if(groupValue == false, "false", groupValue))'
        })
    ];
}

function limitToTopGroupValues (numValues, { appId, timeSeries, segmentId, visitorMetadataField }) {
    const { operators: o } = Agg2;
    const adjustTimeSeries = {
        ...timeSeries,
        period: iterativeToAggregativePeriod(timeSeries.period)
    };

    return [
        o.merge(
            ['groupValue'],
            o.mappings({
                useGroupValue: 'useGroupValue'
            }),
            o.pipeline(
                o.sources.events({
                    appId,
                    timeSeries: adjustTimeSeries
                }),
                o.identified(identifiedState(segmentId)),
                o.segment(parseSegmentIdForAggregation(segmentId)),
                o.bulkExpand('visitor'),
                ...computeGroupValue(visitorMetadataField),
                o.group(['groupValue'], o.groupField('num', { count: null })),
                o.evalExpression({ useGroupValue: 'true' }),
                o.sort('-num'),
                o.limit(numValues)
            )
        ),
        o.filter('useGroupValue == true')
    ];
}
