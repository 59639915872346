import { ALL_APPS_AGG_ID, ALL_EXTENSION_APPS_AGG_ID } from '../constants/apps';

export function pluralize (value, count) {
    if (!value) {
        return '';
    }
    if (count && count === 1) {
        return value;
    }

    return `${value}s`;
}

export function filterIsAllApps (appIdsFilter = []) {
    return [ALL_APPS_AGG_ID, ALL_EXTENSION_APPS_AGG_ID].includes(appIdsFilter[0]);
}
