<template>
    <div class="metadata-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            @select="$emit('input', { field: 'operator', value: $event })" />
        <component
            :is="valueComponent.component"
            v-if="showValueComponent"
            v-bind="valueComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import SegmentCsvUpload from '../rule-components/SegmentCsvUpload.vue';
import NumberInput from '../rule-components/NumberInput.vue';
import StringInput from '../rule-components/StringInput.vue';
import MetadataValueSelect from '../rule-components/MetadataValueSelect.vue';
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import { SEGMENT_OPERATORS } from '@/components/segment-builder/constants/segments';
import { PendoDatePicker, PendoMultiselect, PendoInputNumber, PendoInput } from '@pendo/components';
import {
    getAbsoluteDateRangeConfig,
    getSegmentCsvConfig,
    getNumberConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig,
    getStringConfig
} from '@/components/segment-builder/utils/segments';
import { isCsvUploadOperator, isMobileDataSchema } from '@/components/segment-builder/utils/utils';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

export default {
    name: 'MetadataRule',
    components: {
        PendoMultiselect,
        PendoInputNumber,
        PendoInput,
        PendoDatePicker,
        AbsoluteDateRange,
        SegmentCsvUpload,
        NumberInput,
        RelativeDateRange,
        SingleDate,
        StringInput,
        MetadataValueSelect
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        metadata () {
            return this.computedData.filteredSchemaList.find(({ id }) => this.rule.schema.id === id);
        },
        operatorOptions () {
            let options = [...SEGMENT_OPERATORS[this.rule.schema.schema]];

            const mobileDataOnlyOperators = ['startsWith', '!startsWith'];

            if (!isMobileDataSchema(this.rule.schema)) {
                options = options.filter((option) => !mobileDataOnlyOperators.includes(option.value));
            }

            if (this.rule.schema.id === 'visitor_auto_id') {
                return options;
            }

            return options.filter((option) => !isCsvUploadOperator(option.value));
        },
        ruleWithLatestMetadataOptions () {
            const rule = cloneDeep(this.rule);
            rule.schema.neverIndex = this.metadata?.neverIndex;

            return rule;
        },
        showValueComponent () {
            return this.rule.operator && !this.rule.operator.unary;
        },
        valueComponent () {
            const rule = this.ruleWithLatestMetadataOptions;
            const schema = rule.schema.schema === 'list' ? rule.schema.elementType : rule.schema.schema;

            switch (schema) {
                case 'integer':
                case 'float':
                case 'number':
                case 'aggregation':
                    return getNumberConfig(rule, 'value');
                case 'time':
                case 'date':
                    if (['withinLast', '!withinLast', 'withinNext', '!withinNext'].includes(rule.operator.value)) {
                        return getRelativeDateRangeConfig(rule, 'value');
                    }

                    if (['between'].includes(rule.operator.value)) {
                        return getAbsoluteDateRangeConfig(rule);
                    }

                    if (['>=', '<='].includes(rule.operator.value)) {
                        return getSingleDateConfig(rule, 'value');
                    }

                    return;
                default:
                    if (isCsvUploadOperator(rule.operator.value)) {
                        return getSegmentCsvConfig(rule);
                    }

                    return getStringConfig(rule, 'value');
            }
        }
    },
    created () {
        const isBoolean = get(this.rule, 'schema.schema') === 'boolean';
        const { value, operator } = this.rule;
        if (isNil(value) && isBoolean) {
            this.$emit('input', { field: 'operator', value: operator });
        }
    }
};
</script>

<style lang="scss" scoped>
.metadata-rule {
    display: contents;
}
</style>
