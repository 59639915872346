<template>
    <div class="anon-visitor-options">
        <div class="settings-panel--divider" />
        <div class="settings-panel__section">
            <pendo-radio-group
                :labels="{ top: 'Select Visitor Data' }"
                :value="value"
                :disabled="readOnly"
                @change="onChange">
                <div v-pendo-tooltip="disableAnonymousOptionTooltip">
                    <div class="identified-visitors-only">
                        <pendo-radio
                            :disabled="disableAnonymousOption"
                            value="visitorId"
                            label="Identified Visitors Only" />
                    </div>
                    <div class="anonymous-visitors-only">
                        <pendo-radio
                            :disabled="disableAnonymousOption"
                            value="!visitorId"
                            label="Anonymous Visitors Only" />
                    </div>
                </div>
                <div class="all-visitors">
                    <pendo-radio
                        :disabled="disableAnonymousOption"
                        :value="null"
                        label="All Visitors" />
                </div>
            </pendo-radio-group>
        </div>
    </div>
</template>
<script>
import { PendoTooltip, PendoRadio, PendoRadioGroup } from '@pendo/components';

export default {
    name: 'VisitorTypeOptions',
    components: {
        PendoRadioGroup,
        PendoRadio
    },
    directives: {
        PendoTooltip
    },
    props: {
        value: {
            type: String,
            default: null
        },
        disableAnonymousOption: {
            type: Boolean,
            required: true
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        disableAnonymousOptionTooltip () {
            return {
                multiline: true,
                content: this.disableAnonymousOption ? 'Changing visitor data type for this segment is disabled' : null
            };
        }
    },
    methods: {
        onChange (value) {
            this.$emit('input', value);
        }
    }
};
</script>
