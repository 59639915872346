import Vue from 'vue';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import head from 'lodash/head';
import keys from 'lodash/keys';
import isUndefined from 'lodash/isUndefined';
import moment from '@/utils/moment';
import { flattenTrainingAttrs, hydrateSubscriptionAccounts, createAccountMap } from '@/utils/utils';
import { updateUserState, updateAdoptV2UserState, currentJsonWebTokenIds } from '@/utils/user-settings';
import { LOCALSTORAGE_KEYS } from '@/constants/localstorage-keys';
import {
    recordRaw,
    update,
    updateSubscriptionFlag,
    updateSubscriptionExtensionAttributes,
    _getUnauthenticatedTrainingAttributes
} from '@/utils/subscriptions';

const { localStorage } = window;

export function getInitialState () {
    return {
        activeId: null,
        map: {},
        accountMap: {},
        activeAccountId: null,
        updateError: null,
        unauthenticatedTrainingAttributes: {},
        error: {}
    };
}

export const state = getInitialState();

export const mutations = {
    setActiveById (state, { id }) {
        state.activeId = id;
        localStorage.setItem(LOCALSTORAGE_KEYS.subscriptionId, id);
    },
    setMap (state, { map }) {
        state.map = map;
    },
    setActiveAccountId (state, { activeAccountId }) {
        state.activeAccountId = activeAccountId;
        localStorage.setItem(LOCALSTORAGE_KEYS.accountId, activeAccountId);
    },
    setAccountMap (state, { accountMap }) {
        state.accountMap = accountMap;
    },
    setUpdateError (state, { message }) {
        state.updateError = message;
    },
    setUnauthenticatedTrainingAttributes (state, { unauthenticatedTrainingAttributes }) {
        state.unauthenticatedTrainingAttributes = unauthenticatedTrainingAttributes;
    },
    setProperty (state, { property, value }) {
        Vue.set(state.map[state.activeId], property, value);
    },
    setFeatureFlag (state, { flagName, isEnabled }) {
        Vue.set(state.map[state.activeId].featureFlags, flagName, isEnabled);
    },
    reset (state) {
        const { unauthenticatedTrainingAttributes } = state;
        // preserve unauthenticatedTrainingAttributes to persist on logout
        Object.assign(state, getInitialState(), { unauthenticatedTrainingAttributes });
    },
    setError (state, { error }) {
        state.error = error;
    }
};

export const actions = {
    hydrate ({ commit }, { subscriptions }) {
        const flatSubs = flattenTrainingAttrs(subscriptions);
        const hydratedSubs = hydrateSubscriptionAccounts(flatSubs);

        commit('setMap', { map: keyBy(hydratedSubs, 'id') });
    },
    async unImpersonateSub ({ state, dispatch }) {
        const firstSubId = Object.keys(state.map).find((sub) => sub.id !== state.activeId);
        await dispatch('updateActive', { subscriptionId: firstSubId });
        window.location.reload();
    },
    async updateActive ({ commit, dispatch, getters, state }, { subscriptionId, accountId }) {
        const { subId: currentSubId, accountId: currentAccountId } = currentJsonWebTokenIds();
        const accountMap = state.map[subscriptionId].accounts || createAccountMap(state.map, subscriptionId);
        const accountIdToSetActive = !accountId || !accountMap[accountId] ? head(keys(accountMap)) : accountId;

        if (!accountIdToSetActive) throw new Error('Could not determine account restriction');

        const subscription = get(state, `map[${subscriptionId}]`, {});
        const subscriptionOrAccountHasChanged =
            subscriptionId !== currentSubId || accountIdToSetActive !== currentAccountId;
        const updatingFromLogin = !currentSubId && !currentAccountId;
        const isAdoptV2Sub = subscription.isTrainingSubscription || subscription.isDigitalAdoption;
        if (subscriptionOrAccountHasChanged) {
            try {
                if (isAdoptV2Sub) await updateAdoptV2UserState(subscriptionId);
                else await updateUserState(subscriptionId, accountIdToSetActive);
            } catch (ex) {
                commit('setUpdateError', { message: ex.message });

                return;
            }
        }

        commit('setActiveById', { id: subscriptionId });
        commit('setActiveAccountId', { activeAccountId: accountIdToSetActive });
        moment.tz.setDefault(getters.getTimezone);

        const { isEmptyStateDigitalAdoption, usesMultiApp } = getters;
        const shouldReloadForDigitalAdoption = usesMultiApp || !isEmptyStateDigitalAdoption;

        if (subscriptionOrAccountHasChanged && !updatingFromLogin && shouldReloadForDigitalAdoption) {
            if (/guides\/\w+/is.test(window.location.href)) {
                window.location.pathname = '/guides';

                return;
            }

            if (/resource-center\/\w+/is.test(window.location.href)) {
                window.location.pathname = '/resource-center';

                return;
            }

            if (/paths\/\w+/is.test(window.location.href)) {
                window.location.pathname = '/paths';

                return;
            }

            if (/workflows\/\w+/is.test(window.location.href)) {
                window.location.pathname = '/workflows';

                return;
            }

            return window.location.reload();
        }

        commit('setAccountMap', { accountMap: getters.hasAccounts ? getters.active.accounts : accountMap });
        dispatch('filters/reset', null, { root: true });
    },
    async fetchUnauthenticatedTrainingAttributes ({ commit }) {
        const unauthenticatedTrainingAttributes = await _getUnauthenticatedTrainingAttributes();

        commit('setUnauthenticatedTrainingAttributes', { unauthenticatedTrainingAttributes });
    },
    async updateProperty ({ state, commit }, { property, value }) {
        await update({ ...state.active, [property]: value });
        commit('setProperty', { property, value });
    },
    async updateSubscriptionFlag ({ commit }, { flag, enabled }) {
        await updateSubscriptionFlag(flag, enabled);
        commit('setFeatureFlag', { flagName: flag, isEnabled: enabled });
    },
    async updateExtensionAttributes ({ getters, commit }, { extensionAttributes }) {
        const currentExtensionAttributes = getters.active.extensionAttributes;
        const newExtensionAttributes = Object.assign({}, currentExtensionAttributes, extensionAttributes);

        const response = await updateSubscriptionExtensionAttributes(newExtensionAttributes);
        commit('setProperty', { property: 'extensionAttributes', value: response });
    },
    async startRecordingRawEvents ({ commit, dispatch }) {
        try {
            const { recordUntil } = await recordRaw();
            const value = recordUntil;
            dispatch('updateProperty', { property: 'recordUntil', value });
        } catch (error) {
            commit('setError', { error });
            throw error;
        }
    }
};

export const getters = {
    active (state) {
        return state.map[state.activeId] || {};
    },
    aeuAttributes (state, getters) {
        return get(getters, 'active.aeuAttributes', {});
    },
    isEmptyStateDigitalAdoption (state, getters) {
        const doSubsExist = getters.listAll.length > 0;
        const { active, usesMultiApp } = getters;

        const activeHasNoApps = get(active, 'applications', []).length === 0;

        if (usesMultiApp) return !!active.isDigitalAdoption && activeHasNoApps;

        const areDigitalAdoptionSubsWithZeroExtApps = getters.listAll.every(
            ({ applications, isDigitalAdoption }) => isDigitalAdoption && applications.length === 0
        );

        return doSubsExist && areDigitalAdoptionSubsWithZeroExtApps;
    },
    activeIsDigitalAdoption (state, getters) {
        return get(getters, 'active.isDigitalAdoption', false);
    },
    isActiveDASubAndHasMoreDASubs (state, getters) {
        return (
            getters.activeIsDigitalAdoption &&
            getters.listAll.filter((sub) => get(sub, 'isDigitalAdoption', false)).length > 1
        );
    },
    isActiveAEUSubAndHasMoreAEUSubs (state, getters) {
        return getters.activeIsTrainingSubscription && getters.hasMultipleTrainingSubs;
    },
    canCopyGuidesAcrossSubs (state, getters, rootState, rootGetters) {
        return (
            (getters.isActiveDASubAndHasMoreDASubs || getters.isActiveAEUSubAndHasMoreAEUSubs) &&
            rootGetters['guides/canCopyGuides']
        );
    },
    // temporary getter that guards access to DA Designer launching
    activeUsesDADesigner (state, getters) {
        return getters.activeIsDigitalAdoption;
    },
    activeIsTrainingSubscription (state, getters) {
        return get(getters, 'active.isTrainingSubscription', false);
    },
    activeUsesV2Adopt (state, getters) {
        return getters.activeIsDigitalAdoption || getters.activeIsTrainingSubscription;
    },
    subscriptionByIdUsesV2Adopt (state) {
        return (subId) => {
            const sub = get(state.map, subId, {});

            return sub.isDigitalAdoption || sub.isTrainingSubscription;
        };
    },
    activeHasAnalytics (state, getters) {
        const { activeIsTrainingSubscription, activeIsDigitalAdoption, aeuAttributes } = getters;
        if (activeIsTrainingSubscription) {
            return get(aeuAttributes, 'analyticsAccess', false);
        }

        return activeIsDigitalAdoption;
    },
    activeSubHasFlag: (state, getters) => (flag) => {
        return get(getters.active, `featureFlags.${flag}`, false);
    },
    listAll (state) {
        return Object.values(state.map);
    },
    getAccountList (state) {
        return (app = {}) => {
            const { subscriptionId: subId, applicationFlags } = app;
            if (!get(state.map[subId], 'accounts', false)) {
                const id = get(state.map[subId], 'displayName');
                if (!id) return [];
                const accounts = [{ ...state.map[subId], id, applicationFlags, uid: `${subId}:${id}` }];

                return accounts;
            }

            return Object.values(get(state.map[subId], 'accounts', [])).sort((a, b) => (a.id > b.id ? 1 : -1));
        };
    },
    getAccountListForActiveApp (state, getters, rootState, rootGetters) {
        const activeApp = rootGetters['apps/active'];

        return getters.getAccountList(activeApp);
    },
    hasMultipleAccounts (state, getters, rootState, rootGetters) {
        if (!getters.activeUsesV2Adopt) {
            return getters.getAccountListForActiveApp.length > 1;
        }

        const apps = rootGetters['apps/listAllWithAccounts'];
        const activeApp = get(rootGetters['apps/active'], 'displayName');
        const accounts = apps[activeApp];

        return accounts.length > 1;
    },
    hasMultipleTrainingSubs (state, getters) {
        return getters.trainingSubscriptions.length > 1;
    },
    trainingSubscriptions (state, getters) {
        return getters.listAll.filter((sub) => sub.isTrainingSubscription);
    },
    hasAccounts (state, getters) {
        return !!get(getters.active, 'accounts', false);
    },
    activeAccount (state, getters, rootState) {
        if (
            getters.activeUsesV2Adopt &&
            !getters.hasAccounts &&
            !isUndefined(state.accountMap[state.activeAccountId])
        ) {
            const { applications, ...account } = state.accountMap[state.activeAccountId];

            const application = applications.find((app) => {
                const appId = rootState.apps.activeAppUid.split(':')[1];

                // eslint-disable-next-line eqeqeq
                return app.id == appId;
            });
            const applicationFlags = get(application, 'applicationFlags', {});

            return { ...account, applicationFlags, applications };
        }

        return state.accountMap[state.activeAccountId] || {};
    },
    isGuideValidationEnabled (state, getters) {
        return get(getters, 'active.guideValidation', false);
    },
    productName (state, getters) {
        return get(getters.active, 'trainingAttributes.productName');
    },
    activeHasResourceCenter (state, getters) {
        const { activeIsTrainingSubscription, activeIsDigitalAdoption } = getters;
        if (activeIsTrainingSubscription) {
            return get(getters, 'aeuAttributes.resourceCenterAccess', false);
        }

        return activeIsDigitalAdoption;
    },
    getTimezone (state, getters) {
        return getters.active.timezone;
    },
    activeSubscriptionUtcOffset (state, getters) {
        return (
            moment()
                .tz(getters.active.timezone)
                .utcOffset() * -1 // * -1 to undo POSIX compat https://momentjs.com/timezone/docs/#/zone-object/offset/
        );
    },
    usesMultiApp () {
        return true;
    },
    activeProductLines (state, getters) {
        return keyBy(getters.active.productLines, 'type');
    },
    hasProductLineMetadataEnabled: (state, getters) => (plName, metadataName) => {
        const { [plName]: productLine } = getters.activeProductLines;
        if (!productLine) return false;
        if (!productLine.purchased) return false;

        return get(productLine, `metadata.${metadataName}`, false);
    },
    activeHasSnippetWebApps (state, getters) {
        const sub = getters.active;

        const allowSubsSnippetWebApps = [
            'chess_extension_testing',
            'Saint_Gobain_Trial',
            'Red_Hat_Partner_Enableme',
            'RedHat_Adopt_Trial',
            'IQVIA7'
        ];

        if (allowSubsSnippetWebApps.includes(sub.name)) return true;

        return get(getters, 'activeProductLines.adopt.metadata.enableSnippetWebApps', false);
    },
    useAdoptParityCustomRolesEnabled: (state, getters) => {
        const { adopt, adoptEndUser } = getters.activeProductLines;

        return [adoptEndUser, adopt].some((pl) => get(pl, 'metadata.useAdoptParityCustomRoles', false));
    },
    requireSSO: (state, getters) =>
        getters.activeSubHasFlag('requireGoogleSSO') || getters.activeSubHasFlag('requireSAML')
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
