<template>
    <pendo-multiselect
        ref="multiselect"
        multiple
        :show-selected-values="false"
        class="app-filter"
        label-key="displayName"
        value-key="id"
        stateless
        placeholder="label"
        :value="flatSelectedOptions"
        :close-on-select="false"
        :full-width="true"
        :options="groupedAppOptions"
        group-options-key="options"
        group-label-key="label"
        :max-menu-height="300"
        :max-menu-width="400"
        :min-menu-width="200"
        :searchable="false"
        @select="addAppToFilter"
        @remove="removeAppFromFilter">
        <template #selectedLabel="{ option }">
            <pendo-app-display
                v-if="flatSelectedOptions.length === 1"
                :apps="option" />

            <span v-else>
                {{ label }}
            </span>
        </template>
        <template #option="{ option }">
            <pendo-checkbox-option
                v-if="showCheckbox(option)"
                :option="option">
                <pendo-app-display :apps="option" />
            </pendo-checkbox-option>
            <div
                v-else
                class="all-apps-option">
                {{ option.displayName }}
            </div>
        </template>
        <template #optionGroup="{ option }">
            <div
                v-if="option.id === 'app-groups'"
                class="group-header-label">
                {{ option.label }}
            </div>
            <div
                v-else
                class="group-header-label">
                {{ option.label }} ({{ option.options.length }})
            </div>
        </template>
        <template #beforeList>
            <div
                v-if="individualSelectedApps.length"
                class="user-selected-apps">
                <div class="user-selected-apps-header">
                    <div class="group-header-label pendo-multiselect__group">
                        Selected ({{ individualSelectedApps.length }})
                    </div>
                    <pendo-button
                        type="link"
                        label="Clear"
                        class="clear-selected-apps-button"
                        @click="resetToAllApplications" />
                </div>
                <div class="pendo-multiselect__option">
                    <pendo-checkbox-option
                        v-for="(selectedOption, index) of individualSelectedApps"
                        :key="index"
                        :option="selectedOption"
                        @change="removeAppFromFilter(selectedOption)">
                        <pendo-app-display :apps="selectedOption" />
                    </pendo-checkbox-option>
                </div>
                <pendo-divider
                    stroke="#dadce5"
                    height="16px" />
            </div>
        </template>
        <template #header>
            <div class="application-search-container">
                <pendo-input
                    v-model="searchTerm"
                    placeholder="Search Applications..."
                    class="application-search">
                    <template #prefix>
                        <pendo-icon
                            type="search"
                            stroke="#babcc5"
                            size="16" />
                    </template>
                </pendo-input>
            </div>
        </template>
        <template #noData>
            <div class="no-data-message">
                No applications found for search text "<strong>{{ searchTerm }}</strong>"
            </div>
        </template>
    </pendo-multiselect>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
    PendoAppDisplay,
    PendoMultiselect,
    PendoCheckboxOption,
    PendoInput,
    PendoIcon,
    PendoButton,
    PendoDivider
} from '@pendo/components';

import { ALL_MOBILE_APPS_AGG_ID, ALL_WEB_APPS_AGG_ID } from '@/constants/apps';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import isEqual from 'lodash/isEqual';

export default {
    name: 'AppFilter',
    components: {
        PendoAppDisplay,
        PendoMultiselect,
        PendoInput,
        PendoIcon,
        PendoCheckboxOption,
        PendoButton,
        PendoDivider
    },
    props: {
        showMobileApps: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            searchTerm: '',
            adoptMobileFlag: false,
            allWebAppsOption: {
                id: ALL_WEB_APPS_AGG_ID,
                displayName: 'Web Applications'
            },
            allMobileAppsOption: {
                id: ALL_MOBILE_APPS_AGG_ID,
                displayName: 'Mobile Applications'
            },
            isGroupSelected: true
        };
    },
    computed: {
        ...mapState({
            appIdsFilter: (state) => state.filters.appIdsFilter
        }),
        ...mapGetters({
            appOptions: 'apps/listAllForActiveSubscription',
            allAdoptAppsAggId: 'filters/allAdoptAppsAggId',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption'
        }),
        label () {
            const len = this.flatSelectedOptions?.length;

            if (!len) {
                return 'All Apps';
            }

            return `Apps (${len})`;
        },
        baseOptions () {
            let baseOptions = [
                {
                    id: this.allAdoptAppsAggId,
                    displayName: 'All Applications'
                }
            ];

            if (
                this.showMobileApps &&
                this.adoptMobileFlag &&
                this.allMobileAppsIds.length > 0 &&
                this.allWebAppsIds.length > 0 &&
                !this.activeIsDigitalAdoption
            ) {
                baseOptions = [...baseOptions, this.allWebAppsOption, this.allMobileAppsOption];
            }

            return baseOptions;
        },
        groupedAppOptions () {
            const applicationGroups = {
                id: 'app-groups',
                label: 'Application Groups',
                options: this.filterAppListBySearchTerm(this.baseOptions)
            };

            const allAppOptions = {
                id: 'individual-apps',
                label: 'All Applications',
                options: this.filterAppListBySearchTerm(this.appOptions)
            };

            const options = [];

            if (applicationGroups.options.length) {
                options.push(applicationGroups);
            }

            if (allAppOptions.options.length) {
                options.push(allAppOptions);
            }

            return options;
        },
        individualSelectedApps () {
            return this.appOptions.filter((app) => this.flatSelectedIdsOptions.includes(app.id));
        },
        flatSelectedOptions () {
            const flatSelectedOptions = [...this.baseOptions, ...this.appOptions];

            if (!this.showMobileApps && this.adoptMobileFlag && this.isGroupSelected && this.isAllMobileAppsSelected) {
                return [this.allMobileAppsOption];
            }

            if (!this.showMobileApps && this.adoptMobileFlag && this.isGroupSelected && this.isAllWebAppsSelected) {
                return [this.allWebAppsOption];
            }

            return flatSelectedOptions.filter((app) => this.appIdsFilter.includes(app.id));
        },
        flatSelectedIdsOptions () {
            return this.flatSelectedOptions.map((app) => app.id);
        },
        isAllAppsSelected () {
            return this.allAdoptAppsAggId === this.flatSelectedOptions[0].id;
        },
        isAllMobileAppsSelected () {
            const appIdsFilter = this.appIdsFilter.toSorted();
            const allMobileAppsIds = this.allMobileAppsIds.toSorted();

            return isEqual(appIdsFilter, allMobileAppsIds);
        },
        isAllWebAppsSelected () {
            const appIdsFilter = this.appIdsFilter.toSorted();
            const allWebAppsIds = this.allWebAppsIds.toSorted();

            return isEqual(appIdsFilter, allWebAppsIds);
        },
        allMobileAppsIds () {
            return this.appIdsByPlatform(this.appOptions, MOBILE_PLATFORMS);
        },
        allWebAppsIds () {
            return this.appIdsByPlatform(this.appOptions, ['web']);
        },
        showGroupLabel () {
            if (!this.adoptMobileFlag && this.showMobileApps) return this.isAllAppsSelected;

            return (
                this.isGroupSelected &&
                (this.isAllAppsSelected || this.isAllMobileAppsSelected || this.isAllWebAppsSelected)
            );
        }
    },
    created () {
        this.adoptMobileFlag = this.hasSegmentFlag('adoptMobile');
    },
    methods: {
        ...mapActions({
            updateAppFilter: 'filters/updateAppFilter'
        }),
        updateSearchTerm (newVal) {
            this.searchTerm = newVal;
        },
        filterAppListBySearchTerm (appList) {
            if (!this.searchTerm) return appList;

            return appList.filter((opt) => {
                return opt.displayName.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
        addAppToFilter (app) {
            if (this.flatSelectedIdsOptions.includes(app.id)) return;

            const handleGroupSelection = () => {
                this.isGroupSelected = true;
                this.$refs.multiselect.toggleMenu();
            };

            if (app.id === this.allAdoptAppsAggId) {
                this.resetToAllApplications();
                handleGroupSelection();

                return;
            }

            if (app.id === ALL_WEB_APPS_AGG_ID) {
                this.updateAppFilter({ appIds: this.allWebAppsIds });
                handleGroupSelection();

                return;
            }

            if (app.id === ALL_MOBILE_APPS_AGG_ID) {
                this.updateAppFilter({ appIds: this.allMobileAppsIds });
                handleGroupSelection();

                return;
            }

            let appFilter = this.appIdsFilter.slice();
            const isAllMobileOrWebSelected = this.flatSelectedIdsOptions.some((appId) => {
                return appId === ALL_WEB_APPS_AGG_ID || appId === ALL_MOBILE_APPS_AGG_ID;
            });

            if (appFilter.includes(this.allAdoptAppsAggId) || isAllMobileOrWebSelected) {
                appFilter = [];
            }

            this.isGroupSelected = false;
            appFilter.push(app.id);

            this.updateAppFilter({ appIds: appFilter });
        },
        removeAppFromFilter (app) {
            const appFilter = this.appIdsFilter.slice();
            const indexOfApp = appFilter.indexOf(app.id);

            if (indexOfApp === -1) return;

            appFilter.splice(indexOfApp, 1);

            if (!appFilter.length) {
                this.resetToAllApplications();
            } else {
                this.updateAppFilter({ appIds: appFilter });
            }
        },
        resetToAllApplications () {
            this.updateAppFilter({ appIds: [this.allAdoptAppsAggId] });
        },
        showCheckbox (option) {
            return (
                option.id !== this.allAdoptAppsAggId &&
                option.id !== ALL_WEB_APPS_AGG_ID &&
                option.id !== ALL_MOBILE_APPS_AGG_ID
            );
        },
        appIdsByPlatform (apps, platforms) {
            return apps.reduce((acc, app) => {
                if (platforms.includes(app.platform)) {
                    acc.push(app.id);
                }

                return acc;
            }, []);
        }
    }
};
</script>
<style lang="scss" scoped>
.app-filter {
    position: relative;
}

.selected-apps-trigger-text {
    max-width: 380px;
    display: block;
    line-height: 20px;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.all-apps-option {
    display: flex;
    align-items: center;
}

.user-selected-apps-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    box-sizing: border-box;
    height: 26px;

    .clear-selected-apps-button {
        height: 26px;
        font-size: 12px;
    }
}

.application-search-container {
    border-bottom: 1px solid $gray-lighter-6;

    :deep(.pendo-input__field) {
        border-style: hidden;
    }
}

.group-header-label {
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}
</style>
