<template>
    <pendo-date-picker
        class="single-date"
        full-width
        is-required
        :value="date"
        :min-date="appFirstVisit"
        :max-date="yesterday"
        :disabled="readOnly"
        @select-date="updateDate" />
</template>

<script>
import { PendoDatePicker } from '@pendo/components';
import moment, { formatTimeValue } from '@/components/segment-builder/utils/moment';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'SingleDate',
    components: {
        PendoDatePicker
    },
    mixins: [ReadOnlyMixin],
    inject: ['appFirstVisit'],
    props: {
        value: {
            type: null,
            default: null
        },
        field: {
            type: String,
            default: 'value'
        }
    },
    computed: {
        date () {
            return moment(this.value).toDate();
        },
        valid () {
            return moment(this.value).isValid() && !moment(this.value).isBefore(this.appFirstVisit);
        },
        yesterday () {
            return moment(Date.now())
                .subtract(1, 'day')
                .toDate();
        }
    },
    created () {
        const value = this.valid ? this.date : this.yesterday;
        this.updateDate({ value });
    },
    methods: {
        updateDate (date) {
            const value = formatTimeValue(date.value);
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>
