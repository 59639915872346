<template>
    <div>
        <page-header
            only-title
            :title="workingWorkflow.name"
            :editable="canEditWorkflowName"
            :before-exit="onNameChanged"
            class="workflows-details-header"
            data-cy="workflows-details-header"
            @confirm="onNameChanged">
            <template #top>
                <div class="workflows-details-header__back-to-list">
                    <router-link to="/workflows">
                        <pendo-button
                            theme="app"
                            type="link"
                            size="mini"
                            prefix-icon="chevron-left"
                            label="Back to Workflows List" />
                    </router-link>
                </div>
            </template>

            <template #actions>
                <template v-if="route.name === 'workflowsDetailsHome'">
                    <pendo-button
                        v-if="canDeleteWorkflow"
                        data-cy="workflows-details-delete-button"
                        :disabled="isLoading"
                        icon="trash-2"
                        theme="app"
                        type="tertiary"
                        @click="onDeleteClicked" />

                    <pendo-button
                        v-if="showCreateSegmentButton"
                        data-cy="workflows-details-create-segment-button"
                        :disabled="isLoading"
                        label="Create Segment"
                        icon="plus"
                        theme="app"
                        type="secondary"
                        @click="onCreateSegmentClicked" />
                </template>
                <template v-if="showSelectedPagesFeaturesButton">
                    <pendo-button
                        :loading="isFetchingOrSaving"
                        label="Select Pages &amp; Features"
                        theme="app"
                        @click="$emit('showBlade')" />
                </template>
            </template>
        </page-header>

        <app-segment-builder
            v-if="showSegmentBuilder"
            :editing-segment="prebuiltSegment"
            @close="onSegmentBuilderClosed"
            @save="onSegmentSaved" />

        <selected-pages-features-side-panel
            v-if="showBlade"
            :saved-workflow="savedWorkflow"
            :show-blade="showBlade"
            @workflowUpdated="updateWorkingWorkflow"
            @hideBlade="$emit('hideBlade')" />
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import AppSegmentBuilder from '@/components/segments/builder/AppSegmentBuilder';
import { PendoButton, PendoNotification } from '@pendo/components';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import SelectedPagesFeaturesSidePanel from '@/components/workflows/journeys/SidePanel/SelectedPagesFeaturesSidePanel';
import { hasInvalidWorkflowSteps } from '@/utils/workflows';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

export default {
    name: 'WorkflowsDetailsHeader',
    components: {
        AppSegmentBuilder,
        PageHeader,
        PendoButton,
        SelectedPagesFeaturesSidePanel
    },
    props: {
        showBlade: {
            type: Boolean,
            default: false
        }
    },
    emits: ['showBlade', 'hideBlade', 'deleteClicked'],
    data () {
        return {
            showSegmentBuilder: false
        };
    },
    computed: {
        ...mapGetters({
            pages: 'pages/list',
            features: 'features/list',
            isReadOnly: 'auth/isReadOnly',
            savedWorkflow: 'workflows/savedWorkflow',
            isLoading: 'workflows/isLoading'
        }),
        ...mapState({
            workingWorkflow: (state) => state.workflows.workingWorkflow,
            isFetching: (state) => state.workflows.isFetching,
            isSaving: (state) => state.workflows.isSaving,
            route: (state) => state.route
        }),
        hasInvalidWorkflowSteps () {
            const pagesMap = keyBy(this.pages, 'id');
            const featuresMap = keyBy(this.features, 'id');

            return hasInvalidWorkflowSteps(this.savedWorkflow.workflowSteps, { pagesMap, featuresMap });
        },
        showCreateSegmentButton () {
            return this.savedWorkflow && !this.hasInvalidWorkflowSteps;
        },
        showSelectedPagesFeaturesButton () {
            return this.route.name === 'journeys' && this.savedWorkflow && !this.hasInvalidWorkflowSteps;
        },
        isFetchingOrSaving () {
            return this.isFetching || this.isSaving;
        },
        canDeleteWorkflow () {
            return !this.isReadOnly && !!this.savedWorkflow;
        },
        canEditWorkflowName () {
            return !this.isReadOnly && !this.isLoading;
        },
        prebuiltSegment () {
            return {
                definition: {
                    filters: [
                        {
                            kind: 'visitor',
                            schema: 'workflow',
                            name: 'Workflow Progress',
                            id: 'workflow',
                            type: 'workflow',
                            icon: {
                                type: 'adopt-workflows'
                            },
                            workflowId: this.savedWorkflow.id,
                            operator: 'completed',
                            filterIndex: 0
                        }
                    ]
                }
            };
        }
    },
    methods: {
        ...mapActions({
            updateSavedWorkflow: 'workflows/update',
            removeWorkflow: 'workflows/remove'
        }),
        ...mapMutations({
            setWorkingWorkflowField: 'workflows/setWorkingWorkflowField',
            setWorkingWorkflow: 'workflows/setWorkingWorkflow'
        }),
        async updateWorkingWorkflow (workflow) {
            this.setWorkingWorkflow({ workflow });
        },
        onCreateSegmentClicked () {
            this.showSegmentBuilder = true;
        },
        onDeleteClicked () {
            this.$emit('deleteClicked');
        },
        async onNameChanged (name) {
            if (this.savedWorkflow && this.savedWorkflow.name.trim() !== name.trim()) {
                const workflow = cloneDeep(this.savedWorkflow);
                workflow.name = name;

                try {
                    await this.updateSavedWorkflow({ workflow });
                    this.setWorkingWorkflowField({ field: 'name', value: name });
                } catch (error) {
                    this.handleSavingError(error);
                }
            } else if (!this.savedWorkflow) {
                this.setWorkingWorkflowField({ field: 'name', value: name });
            }
        },
        handleSavingError (error) {
            if (this.savedWorkflow && get(error, 'response.status', 500) === 404) {
                this.removeWorkflow({
                    id: this.savedWorkflow.id
                });

                this.$router.replace({
                    name: 'workflowsList'
                });

                PendoNotification({
                    type: 'error',
                    message: `${this.workingWorkflow.name} has been deleted.`
                });
            } else {
                PendoNotification({
                    type: 'error',
                    message: `There was an error saving ${this.workingWorkflow.name}. Please try again.`
                });
            }
        },
        onSegmentBuilderClosed () {
            this.showSegmentBuilder = false;
        },
        onSegmentSaved ({ name }) {
            PendoNotification({
                type: 'success',
                message: `Segment "${name}" created!`
            });
        }
    }
};
</script>
