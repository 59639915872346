<template>
    <div class="workflow-rule">
        <div :class="{ 'workflow-undefined': rule.workflowDeleted }">
            <pendo-multiselect
                :value="rule.workflow"
                :allow-empty="false"
                :options="workflowOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                placeholder="Select Workflow"
                class="workflow-rule--workflow-select"
                @select="onWorkflowSelect" />
            <rule-warning v-if="rule.workflowDeleted">
                The original workflow for this rule has been deleted
            </rule-warning>
        </div>
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            placeholder="Select Usage"
            @select="handleProgressOperatorSelection">
            <template #suffix="{ open }">
                <pendo-icon
                    type="chevron-down"
                    size="14"
                    class="pendo-multiselect__caret"
                    :class="{
                        'is-active': open
                    }" />

                <pendo-popover
                    arrow
                    offset="14">
                    <div class="info-tooltip">
                        <pendo-icon
                            size="14"
                            type="info" />
                    </div>

                    <template #popover>
                        <div class="info-tooltip-body">
                            <p><strong>Completed:</strong> Visitors who have completed this workflow.</p>

                            <p v-if="config.showWorkflowRicherSegmentation">
                                <strong>Incomplete:</strong> Visitors who did not complete the workflow.
                            </p>
                            <p v-if="config.showWorkflowRicherSegmentation">
                                <strong>Not Started:</strong> Visitors that did not start the workflow.
                            </p>
                            <p v-if="!config.showWorkflowRicherSegmentation">
                                <strong>Incomplete or Not Started:</strong> Visitors who did not complete the workflow
                                (Incomplete) or visitors that did not start the workflow (Not Started).
                            </p>
                        </div>
                    </template>
                </pendo-popover>
            </template>
        </pendo-multiselect>
        <pendo-multiselect
            v-if="config.showWorkflowRicherSegmentation"
            :value="workflowTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :options="workflowTimeRangeOptions"
            :disabled="readOnly"
            class="worflow-rule--workflow-time-operator"
            @select="handleWorkflowTimeSelection" />

        <component
            :is="valueComponent.component"
            v-if="showValueComponent"
            class="rule-editor--item complex-rule"
            v-bind="valueComponent.props"
            @input="handleTimeDataChange" />
    </div>
</template>

<script>
import {
    SEGMENT_OPERATORS,
    WORKFLOW_TIME_RANGE_COMPLETED_INCOMPLETE_OPTIONS,
    WORKFLOW_TIME_RANGE_NOT_STARTED_OPTIONS
} from '@/components/segment-builder/constants/segments';
import { PendoIcon, PendoMultiselect, PendoPopover } from '@pendo/components';
import {
    getNumberConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import RuleWarning from '../rule-components/RuleWarning';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import NumberInput from '../rule-components/NumberInput.vue';

export default {
    name: 'WorkflowRule',
    components: {
        PendoIcon,
        PendoMultiselect,
        PendoPopover,
        RuleWarning,
        RelativeDateRange,
        SingleDate,
        NumberInput
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        }
    },
    computed: {
        operatorOptions () {
            return [
                ...SEGMENT_OPERATORS[
                    this.config.showWorkflowRicherSegmentation ? this.rule.schema.schema : 'workflowNonRS'
                ]
            ];
        },
        workflowOptions () {
            return this.computedData.workflows
                .filter((workflow) => {
                    if (workflow.workflowSteps.length !== 2) {
                        return false;
                    }

                    return (
                        !!this.computedData[`${workflow.workflowSteps[0].countableKind.toLowerCase()}sMap`][
                            workflow.workflowSteps[0].countableId
                        ] &&
                        !!this.computedData[`${workflow.workflowSteps[1].countableKind.toLowerCase()}sMap`][
                            workflow.workflowSteps[1].countableId
                        ]
                    );
                })
                .sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    return nameA > nameB ? 1 : 0;
                });
        },
        workflowTimeRangeOptions () {
            return this.rule.operator.value === 'completed' || this.rule.operator.value === 'incomplete'
                ? WORKFLOW_TIME_RANGE_COMPLETED_INCOMPLETE_OPTIONS
                : WORKFLOW_TIME_RANGE_NOT_STARTED_OPTIONS;
        },
        showValueComponent () {
            return this.config.showWorkflowRicherSegmentation && this.rule.time !== 'ever' && this.rule.time;
        },
        valueComponent () {
            switch (this.rule.time) {
                case 'atleast':
                case 'atmost':
                    return getNumberConfig(this.rule, 'value');
                case 'since':
                    return getSingleDateConfig(this.rule, 'value');
                case 'withinlast':
                    return getRelativeDateRangeConfig(this.rule, 'value');
                default:
                    return;
            }
        }
    },
    created () {
        if (this.config.showWorkflowRicherSegmentation && !this.rule.value) {
            if (this.config.workflowWithinLastDateRange) {
                this.handleWorkflowTimeSelection({
                    label: 'within last',
                    value: 'withinlast'
                });
            }
            const incompleteOption = this.operatorOptions.find((opt) => opt.value === 'incomplete');
            this.handleProgressOperatorSelection(incompleteOption);
        }
    },
    methods: {
        handleProgressOperatorSelection ($event) {
            const updates = [{ field: 'operator', value: $event }];
            // handle switching to `not started` where `at least / at most` not supported
            if (
                this.config.showWorkflowRicherSegmentation &&
                $event.value === 'notStarted' &&
                (this.rule.time === 'atleast' || this.rule.time === 'atmost')
            ) {
                updates.push({ field: 'time', value: 'ever' });
            }

            this.$emit('input', updates);
        },
        onWorkflowSelect (workflow) {
            this.$emit('input', [
                { field: 'workflow', value: workflow },
                { field: 'workflowDeleted', value: false }
            ]);
        },
        workflowTimeLabelObject (timeString) {
            return this.workflowTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleWorkflowTimeSelection ($event) {
            const { value } = $event;

            // only 'time' type to require value as an array
            if (value === 'withinlast') {
                const globalDateRange = this.config.workflowWithinLastDateRange;
                this.$emit('input', { field: 'value', value: { count: globalDateRange || 30, granularity: 'days' } });
            } else {
                this.$emit('input', { field: 'value', value: 1 });
            }
            this.$emit('input', { field: 'time', value });
        },
        handleTimeDataChange ($event) {
            const { field, value } = $event;
            if (this.rule.time === 'withinlast') {
                const unmodifiedField = field === 'count' ? 'granularity' : 'count';
                this.$emit('input', {
                    field: 'value',
                    value: { [field]: value, [unmodifiedField]: this.rule.value[unmodifiedField] }
                });
            } else {
                this.$emit('input', $event);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.workflow-rule {
    display: contents;

    .workflow-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}

.info-tooltip {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0 8px;

    &-body {
        padding: 16px;
        width: 286px;

        & * {
            font-size: 14px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
</style>
