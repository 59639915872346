import Vue from 'vue';
import get from 'lodash/get';
import routerInstance from '@/router';
import { refreshSegmentFlags } from '@/state/plugins/pendo';

export function getInitialState () {
    return {
        pageTitle: '',
        showFullscreenLoading: false,
        globalRouteKey: ''
    };
}

export const state = getInitialState();

export const mutations = {
    setPageTitle (state, { pageTitle }) {
        state.pageTitle = pageTitle;
    },
    setShowFullscreenLoading (state, { shouldShow }) {
        state.showFullscreenLoading = shouldShow;
    },
    setGlobalRouteKey (state, { subscriptionId, appId, accountId }) {
        state.globalRouteKey = `${subscriptionId}:${appId}:${accountId}`;
    }
};

export const actions = {
    formatDocumentTitleByRoute ({ rootGetters, rootState, commit }) {
        const usesMultiApp = rootGetters['subscriptions/usesMultiApp'];
        const currentRoute = routerInstance.currentRoute.value;
        const publicProductName = get(rootState, 'subscriptions.unauthenticatedTrainingAttributes.productName');
        const productName = rootGetters['subscriptions/productName'] || publicProductName || 'Pendo Adopt';
        const matchedRouteNames = currentRoute.matched.map((route) => route.name);
        const titleHierarchy = currentRoute.matched.reduce((acc, route) => {
            if (!get(route, 'meta.title')) return acc;
            acc.push(route.meta.title);

            return acc;
        }, []);
        const routes = {
            appDetails: matchedRouteNames.includes('appDetails'),
            guideDetails: matchedRouteNames.includes('guideDetails'),
            resourceCenter: matchedRouteNames.includes('resourceCenter'),
            settings: matchedRouteNames.includes('settings')
        };
        const shouldDisplayVersboseTitle =
            !usesMultiApp || routes.guideDetails || routes.resourceCenter || routes.appDetails;
        let appName = !usesMultiApp ? get(rootGetters['apps/active'], 'displayName') : '';
        let title = titleHierarchy;

        if (routes.guideDetails) {
            const activeGuide = rootGetters['guides/getGuideById'](currentRoute.params.id);
            if (activeGuide) {
                // "Guide Metrics: Some Guide Name", for instance
                title = `${titleHierarchy.join(' ')}: ${activeGuide.name}`;

                if (usesMultiApp) {
                    appName = rootGetters['apps/appFromGuide'](activeGuide).displayName;
                }
            }
        } else if (routes.resourceCenter) {
            if (usesMultiApp && currentRoute.params.activeState) {
                const activeResourceCenterAppName = rootGetters['apps/appNameFromId'](
                    rootState.resourceCenter.activeEditingAppId
                );
                if (activeResourceCenterAppName) {
                    appName = activeResourceCenterAppName;
                }
            }
        } else if (routes.settings) {
            // "Team Settings", for instance
            title = `${titleHierarchy.reverse().join(' ')}`;
        } else if (routes.appDetails) {
            title = `${titleHierarchy.reverse().join(' ')}`;
            const activeAppName = rootGetters['apps/appNameFromId'](currentRoute.params.id);
            if (activeAppName) {
                appName = activeAppName;
            }
        } else if (titleHierarchy.length) {
            title = `${titleHierarchy.join(' - ')}`;
        }

        if (title) {
            title += ' | ';
        }

        title += productName;

        if (appName && shouldDisplayVersboseTitle) {
            title += ` for ${appName}`;
        }

        commit('setPageTitle', { pageTitle: title });

        // Vue.nextTick is required here in order to keep the browser history from reporting the wrong item
        // https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
        Vue.nextTick(() => {
            document.title = title;
        });
    },
    changeAppContext ({ commit, dispatch }, { subscriptionId, appId, accountId, validateRoute }) {
        if (validateRoute) {
            dispatch('ensureValidLocation');
        }

        commit('setGlobalRouteKey', { subscriptionId, appId, accountId });
    },
    ensureValidLocation ({ rootGetters }) {
        const currentRoute = routerInstance.currentRoute.value;
        const isOnGuideDetails = currentRoute.matched.some((route) => route.name === 'guideDetails');

        if (isOnGuideDetails) return routerInstance.push({ name: 'guideList', query: currentRoute.query });

        const isOnAnalytics = currentRoute.matched.some((route) => route.name === 'analytics');
        const newSubHasAnalytics = rootGetters['subscriptions/activeHasAnalytics'];
        if (isOnAnalytics && !newSubHasAnalytics) {
            return routerInstance.push({ name: 'root', query: currentRoute.query });
        }

        const isOnWorkflows = currentRoute.matched.some(
            (route) => route.name === 'workflowsDetails' || route.name === 'workflowsList'
        );
        if (isOnWorkflows) {
            if (!newSubHasAnalytics) return routerInstance.push({ name: 'root', query: currentRoute.query });

            return routerInstance.push({ name: 'workflowsList', query: currentRoute.query });
        }

        const isOnResourceCenter = currentRoute.matched.some((route) => route.name === 'resourceCenter');
        const newSubHasRC = rootGetters['subscriptions/activeHasResourceCenter'];
        if (isOnResourceCenter && !newSubHasRC) return routerInstance.push({ name: 'root', query: currentRoute.query });

        const isOnEditingModule = currentRoute.matched.some((route) => route.name === 'EditModule');
        if (isOnEditingModule && newSubHasRC) {
            return routerInstance.push({ name: 'resourceCenter', query: currentRoute.query });
        }

        refreshSegmentFlags();
    }
};

export const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
