<template>
    <div class="simple-value-inputs">
        <div
            v-if="!isUnitCountValue"
            class="rule-editor--item">
            <component
                :is="valueComponent"
                :value="rule.value"
                v-bind="valueComponentProps"
                @input="$emit('input', { field: 'value', value: $event })" />
        </div>
        <div
            v-if="isUnitCountValue"
            class="rule-editor--item complex-rule">
            <pendo-input-number
                :value="rule.value.count"
                :min="1"
                :disabled="readOnly"
                @change="$emit('input', { field: 'count', value: $event })" />
            <pendo-multiselect
                :value="rule.value.granularity"
                :allow-empty="false"
                :preselect-first="true"
                :min-trigger-width="90"
                :min-menu-width="90"
                :disabled="readOnly"
                :options="['days', 'weeks', 'months']"
                @select="$emit('input', { field: 'granularity', value: $event })" />
        </div>
    </div>
</template>

<script>
import { PendoDatePicker, PendoInput, PendoInputNumber, PendoMultiselect } from '@pendo/components';
import map from 'lodash/map';
import moment from '@/components/segment-builder/utils/moment';
import { SEGMENT_OPERATORS, INPUT_OPTIONS_MAP } from '@/components/segment-builder/constants/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'SimpleValueInputs',
    components: {
        PendoDatePicker,
        PendoInput,
        PendoInputNumber,
        PendoMultiselect
    },
    mixins: [ReadOnlyMixin],
    props: {
        config: {
            type: Object,
            required: true
        },
        operators: {
            type: Object,
            default () {
                return SEGMENT_OPERATORS;
            }
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        isDateRangeValue () {
            const { operator, value } = this.rule;

            return value && operator && (operator.value === 'between' || operator.value === '!between');
        },
        isUnitCountValue () {
            const { operator, value } = this.rule;

            return value && operator && (operator.value === 'withinLast' || operator.value === '!withinLast');
        },
        maxDate () {
            const { operator } = this.rule;

            if (!operator || !operator.label) {
                return null;
            }

            if (operator.label === 'since') {
                return this.yesterday;
            }

            return null;
        },
        valueComponent () {
            const { rule } = this;

            if (rule.schema.schema === 'poll') {
                return {
                    [INPUT_OPTIONS_MAP.PICK_LIST]: 'PendoMultiselect',
                    [INPUT_OPTIONS_MAP.FREE_FORM]: 'PendoInput',
                    [INPUT_OPTIONS_MAP.NUMBER_SCALE]: 'PendoMultiselect',
                    [INPUT_OPTIONS_MAP.POSITIVE_NEGATIVE]: 'PendoMultiselect'
                }[rule.poll.attributes.type];
            }

            return {
                string: 'PendoInput',
                list: 'PendoInput',
                integer: 'PendoInputNumber',
                float: 'PendoInputNumber',
                time: 'PendoDatePicker'
            }[rule.schema.schema];
        },
        valueComponentProps () {
            const { rule } = this;
            const {
                schema: { schema }
            } = rule;

            const types = ['PickList', 'PositiveNegative'];

            if (schema === 'poll' && types.includes(rule.poll.attributes.type)) {
                return {
                    class: 'simple-value-inputs--value',
                    allowEmpty: false,
                    fullWidth: true,
                    options: map(rule.poll.idResponses, (label, id) => id),
                    formatOptionLabel (id) {
                        return rule.poll.idResponses[id];
                    }
                };
            }

            if (schema === 'poll' && rule.poll.attributes.type === 'NumberScale') {
                return {
                    class: 'simple-value-inputs--value',
                    allowEmpty: false,
                    fullWidth: true,
                    options: map(rule.poll.numericResponses, (value) => parseInt(value))
                };
            }

            const props = {
                time: {
                    shortcuts: false,
                    class: 'simple-value-inputs--time',
                    type: this.isDateRangeValue ? 'range' : 'single',
                    minDate: this.config?.data?.appFirstVisit,
                    maxDate: this.maxDate
                },
                float: {
                    precision: 2
                }
            }[schema];

            return {
                class: 'simple-value-inputs--value',
                disabled: !this.operators[schema] || this.readOnly,
                ...props
            };
        },
        yesterday () {
            return moment(Date.now())
                .subtract(1, 'day')
                .toDate();
        }
    }
};
</script>

<style lang="scss" scoped>
.simple-value-inputs {
    display: contents;

    &--time {
        width: 100%;

        > div > span {
            // display: block !important;
        }
    }
}
</style>
