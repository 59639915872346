import { pluralize } from './filters';
import { migrateRule } from './migration';
import { findSchemaOperator, parseValueFromDefinition } from './segments';
import { formatTimeFilterSummary } from './moment';
import { SEGMENT_OPERATORS } from '../constants/segments';
import { isCsvUploadOperator } from './utils';
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import get from 'lodash/get';

export function parseSegmentSummary ({
    segment,
    appsMap = {},
    guidesMap = {},
    pagesMap = {},
    featuresMap = {},
    workflowsMap = {},
    segmentsMap = {}
} = {}) {
    try {
        if (!segment || segment.id === 'everyone' || segment.id == null) {
            return [
                [
                    {
                        text: 'Everyone in your app will see this'
                    }
                ]
            ];
        }

        if (segment.createdByApi) {
            return [
                [
                    {
                        text: 'Visitors contained within an API-managed list'
                    }
                ]
            ];
        }

        const { filters } = segment.definition;

        return filters.reduce((summary, filter) => {
            let rule;
            if (filter.or) {
                rule = filter.or.map(
                    getFilterSummary({ appsMap, guidesMap, pagesMap, featuresMap, workflowsMap, segmentsMap })
                );
            } else {
                rule = [
                    getFilterSummary({ appsMap, guidesMap, pagesMap, featuresMap, workflowsMap, segmentsMap })(filter)
                ];
            }

            return [...summary, rule];
        }, []);
    } catch {
        return [[{ schema: 'Unknown', text: 'Unknown segment' }]];
    }
}

// Careful: this is getting used elsewhere outside of this repo; order matters
export function getInlineSegmentSummary (segment, guidesMap, pagesMap, featuresMap, workflowsMap, segmentsMap, appsMap) {
    if (!segment || segment.id === 'everyone' || segment.id == null) {
        return 'Everyone';
    }

    const summary = parseSegmentSummary({
        segment,
        appsMap,
        guidesMap,
        pagesMap,
        featuresMap,
        workflowsMap,
        segmentsMap
    });

    return summary
        .map((rule) => {
            if (rule.length > 1) {
                return rule.map((orRule) => orRule.text).join(' OR ');
            }

            return rule[0].text;
        })
        .join(' AND ');
}

export function getFilterSummary ({
    guidesMap = {},
    pagesMap = {},
    featuresMap = {},
    workflowsMap = {},
    segmentsMap = {},
    appsMap = {}
} = {}) {
    return (filter) => {
        const { name, schema, operator, type } = migrateRule(filter);

        if (!operator || !SEGMENT_OPERATORS[schema]) {
            return { schema, text: 'Unsupported Segment' };
        }

        const operatorSchema = schema === 'workflow' && operator === 'notcompleted' ? 'workflowNonRS' : schema;

        const { label } = findSchemaOperator(operator, operatorSchema);
        const guide = guidesMap[filter.guideId];
        const page = pagesMap[filter.pageId];
        const feature = featuresMap[filter.featureId];
        const workflow = workflowsMap[filter.workflowId];
        const segment = segmentsMap[filter.segmentId];
        const app = appsMap[filter.selectedApp?.id];
        const guideNotFound = /^guide$|^poll$/.test(schema) && !guide;
        const pageNotFound = schema === 'page' && !page;
        const featureNotFound = schema === 'feature' && !feature;
        const workflowNotFound = schema === 'workflow' && !workflow;
        const segmentNotFound = schema === 'segment' && !segment;
        const appNotFound = schema === 'application' && !app;

        if (guideNotFound || pageNotFound || featureNotFound || workflowNotFound || segmentNotFound || appNotFound) {
            const entityId = filter[`${schema}Id`];

            return {
                schema,
                text: getMissingEntityText(schema, entityId)
            };
        }

        if (schema === 'application') {
            const appSentenceInfo = `${capitalize(type)} "${app.displayName}"`;
            const usedNotUsed = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            return {
                schema,
                text: `${appSentenceInfo} ${usedNotUsed} ${operatorDescription}`
            };
        }

        if (schema === 'segment') {
            return {
                schema,
                id: filter[`${schema}Id`],
                text: `${capitalize(type)} ${label} "${segment.name}"`.trim()
            };
        }

        if (schema === 'guide') {
            const guideSentenceInfo = `${capitalize(type)} "${guide.name}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.count} ${filter.granularity}`;
            }

            if (filter.time === '!withinlast') {
                operatorDescription = `not within last ${filter.count} ${filter.granularity}`;
            }

            if (filter.time === '<=') {
                operatorDescription = `before ${formatTimeFilterSummary(filter.first)}`;
            }

            if (filter.time === '>=') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.first)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'between') {
                operatorDescription = `between ${formatTimeFilterSummary(filter.first)} and ${formatTimeFilterSummary(
                    filter.last
                )}`;
            }

            return {
                schema,
                text: `${guideSentenceInfo} ${seenNotSeen} ${operatorDescription}`.trim()
            };
        }

        if (schema === 'page') {
            const pageSentenceInfo = `${capitalize(type)} "${page.displayName}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === '!withinlast') {
                operatorDescription = `not within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'before') {
                operatorDescription = `before ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'between') {
                operatorDescription = `between ${formatTimeFilterSummary(
                    filter.value.first
                )} and ${formatTimeFilterSummary(filter.value.last)}`;
            }

            return {
                schema,
                text: `${pageSentenceInfo} ${seenNotSeen} ${operatorDescription}`
            };
        }

        if (schema === 'feature') {
            const featureSentenceInfo = `${capitalize(type)} "${feature.displayName}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === '!withinlast') {
                operatorDescription = `not within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'before') {
                operatorDescription = `before ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'between') {
                operatorDescription = `between ${formatTimeFilterSummary(
                    filter.value.first
                )} and ${formatTimeFilterSummary(filter.value.last)}`;
            }

            return {
                schema,
                text: `${featureSentenceInfo} ${seenNotSeen} ${operatorDescription}`
            };
        }

        if (schema === 'poll') {
            const poll = find(guide.polls, { id: filter.pollId });

            if (!poll) {
                return {
                    schema,
                    text: getMissingEntityText(schema, filter.pollId)
                };
            }

            const question = get(poll, 'question', 'the poll');
            let responseVal = '';
            if (filter.value != null && !['responded', 'not responded'].includes(label)) {
                responseVal = parseValueFromDefinition(filter.value, 'string');
                responseVal = ` ${poll.idResponses ? poll.idResponses[responseVal] : responseVal}`;
            }

            let timeVal = '';
            switch (filter.time) {
                case '>=': {
                    timeVal = `since ${formatTimeFilterSummary(filter.first)}`;
                    break;
                }
                case '<=': {
                    timeVal = `before ${formatTimeFilterSummary(filter.first)}`;
                    break;
                }
                case 'withinlast': {
                    timeVal = `within last ${filter.count} ${filter.granularity}`;
                    break;
                }
                case '!withinlast': {
                    timeVal = `not within last ${filter.count} ${filter.granularity}`;
                    break;
                }
                case 'between': {
                    timeVal = `between ${formatTimeFilterSummary({ first: filter.first, last: filter.last })}`;
                    break;
                }
                default: {
                    timeVal = 'ever';
                }
            }

            return {
                schema,
                text: `Response to "${question}" ${label}${responseVal} ${timeVal}`.trim()
            };
        }

        if (schema === 'workflow') {
            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            return {
                schema,
                text: `${capitalize(type)} "${workflow.name}" ${label} ${operatorDescription}`.trim()
            };
        }

        if (schema === 'guideElement') {
            const sentenceInfo = `Guide Element "${filter.guideElementName ?? filter.guideElementId}"`;
            const seenNotSeen = label;

            let operatorDescription = filter.time.trim();

            if (filter.time === 'withinlast') {
                operatorDescription = `within last ${filter.value.count} ${filter.value.granularity}`;
            }

            if (filter.time === 'since') {
                operatorDescription = `since ${formatTimeFilterSummary(filter.value)}`;
            }

            if (filter.time === 'atleast') {
                operatorDescription = `at least ${filter.value} ${pluralize('time', filter.value)}`;
            }

            if (filter.time === 'atmost') {
                operatorDescription = `at most ${filter.value} ${pluralize('time', filter.value)}`;
            }

            return {
                schema,
                text: `${sentenceInfo} ${seenNotSeen} ${operatorDescription}`
            };
        }

        if (isCsvUploadOperator(operator)) {
            const filename = parseValueFromDefinition(filter.value, schema, operator);
            const operatorDescription = {
                'listContains': 'contained within list',
                '!listContains': 'not contained within list'
            }[operator];

            return {
                schema,
                text: `${name} ${operatorDescription} "${filename}"`.trim()
            };
        }

        const value = getFilterValue(filter);
        let val = parseValueFromDefinition(value, schema, operator);

        if (schema === 'time') {
            val = formatTimeFilterSummary(val);
        }

        if (schema === 'boolean') {
            val = '';
        }

        return {
            schema,
            text: `${name} ${label} ${val}`.trim()
        };
    };
}

export function getMissingEntityText (type, id) {
    return `Error: ${type} ${id} not found`;
}

function getFilterValue ({ value, granularity, count }) {
    // default segments use a different format?
    if (value == null && granularity != null && count != null) {
        return { count: -count, granularity };
    }

    return value;
}
