<template>
    <main class="visitor-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingVisitorList"
            title="Visitors"
            row-key="visitorId"
            class="visitor-list--table"
            :data="visitorList"
            :filters="filters"
            :columns="columns"
            :default-sort="sort"
            :max-height="600"
            :csv-config="csvDownloadConfig"
            manage-columns
            csv-download
            resizable
            @sort-change="handleSortChange"
            @column-change="handleColumnChange"
            @column-resize="handleColumnResize">
            <template
                v-if="canUpload"
                #headerActions>
                <metadata-csv-upload @uploaded="$emit('fetchVisitorList')" />
            </template>
            <template #visitorId="{ row }">
                <div
                    class="visitor-list--table-display-name"
                    @click="$emit('routeToVisitorDetails', row)">
                    <span class="visitor-list--table-name-link">{{ row.visitorId }}</span>
                </div>
            </template>
            <template #empty>
                <div class="visitor-list--table--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        No data found.
                    </span>
                </div>
            </template>
            <template #lastVisitAppDisplayName="{ row }">
                <pendo-app-display :apps="row.lastVisitAppId ? appMap[row.lastVisitAppId] : []" />
            </template>
        </pendo-table>
    </main>
</template>

<script>
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { PendoIcon, PendoTable, PendoLoading, PendoAppDisplay } from '@pendo/components';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';
import MetadataCsvUpload from '@/stateless-components/analytics/MetadataCsvUpload.vue';
import { mergeDefaultSortWithUserSettings } from '@/stateless-components/utils/table-user-settings';
import {
    onColumnResize,
    onColumnChange,
    onSortChange
} from '@/stateless-components/utils/pendo-table-user-settings-helper.js';

export default {
    name: 'AnalyticsVisitorList',
    components: {
        PendoIcon,
        PendoTable,
        MetadataCsvUpload,
        PendoAppDisplay
    },
    directives: {
        PendoLoading
    },
    props: {
        searchString: {
            type: String,
            default: ''
        },
        canUpload: {
            type: Boolean,
            default: false
        },
        isFetchingVisitorList: {
            type: Boolean,
            default: false
        },
        visitorListUserSettings: {
            type: Object,
            default: () => {}
        },
        activeTimezone: {
            type: String,
            default: ''
        },
        appMap: {
            type: Object,
            default: () => {}
        },
        visitorList: {
            type: Array,
            default: () => []
        },
        columns: {
            type: Array,
            required: true
        }
    },
    emits: ['fetchVisitorList', 'routeToVisitorDetails', 'tableSettingsUpdate'],
    data () {
        return {
            visitorListUserSettingsName: 'visitorList',
            defaultSort: {
                prop: 'visitorId',
                order: 'ascending'
            },
            isColumnChooserVisible: false,
            dropdownOptions: [
                {
                    label: 'Manage Columns',
                    action: 'edit'
                }
            ]
        };
    },
    computed: {
        searchableColumns () {
            const columns =
                get(this.visitorListUserSettings, 'visibleColumns', null) || this.columns.map((column) => column.prop);

            return columns.filter((columnName) => {
                return columnName !== 'daysActive' && columnName !== 'eventCount';
            });
        },
        filters () {
            return [
                {
                    prop: this.searchableColumns,
                    value: this.searchString
                }
            ];
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.visitorListUserSettings);
        },
        csvDownloadConfig () {
            const visibleColumns = this.columns.filter((col) => col.visible);
            const formattedColumns = visibleColumns.map((column) => {
                const singleColumn = {
                    ...column,
                    label: column.label,
                    formatter: (row) => this.formatDateForDownload(row, column)
                };

                return singleColumn;
            });

            return { columns: formattedColumns };
        }
    },
    methods: {
        openColumnChooser () {
            this.isColumnChooserVisible = true;
        },
        handleSortChange ($event) {
            const newSettings = onSortChange($event, this.visitorListUserSettings);
            this.handleTableSettingsUpdate(newSettings);
        },
        handleColumnResize ($event) {
            const newSettings = onColumnResize($event, this.visitorListUserSettings);
            this.handleTableSettingsUpdate(newSettings);
        },
        handleTableSettingsUpdate (newSettings) {
            this.$emit('tableSettingsUpdate', this.visitorListUserSettingsName, newSettings);
        },
        handleColumnChange ($event) {
            const newSettings = onColumnChange($event, this.visitorListUserSettings);
            this.handleTableSettingsUpdate(newSettings);

            const { columns } = $event;
            const currentlyVisibleColumnNames = this.columns.map((col) => col.prop).sort();
            const newlyVisiblyColumnNames = columns.slice().sort();
            const newColumnsAdded = !isEqual(currentlyVisibleColumnNames, newlyVisiblyColumnNames);

            if (!newColumnsAdded) return;

            this.$emit('fetchVisitorList');
        },
        formatDateForDownload (row, column) {
            const columnVal = get(row, column.prop);
            const schema = get(column, 'schema');
            if (schema === 'time') {
                const dateValue =
                    columnVal &&
                    convertToSubscriptionTimezone(this.activeTimezone, columnVal).format(DATE_FORMAT.isoDateTime);

                return dateValue;
            }

            return columnVal;
        }
    }
};
</script>

<style lang="scss">
.visitor-list {
    &--table {
        &--empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .pendo-icon {
                margin-right: 0.5em;
                display: flex;
            }

            .empty-text {
                color: $gray-primary;
            }
        }

        &-name-link {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            color: $teal-primary;
            font-weight: 600;
        }

        &-display-name {
            display: flex;
            align-items: baseline;
            cursor: pointer;
        }

        &-display-name:hover &-name-link {
            text-decoration: underline;
            color: $teal-darker-1;
        }
    }

    .pendo-table__header {
        .pendo-table__column {
            text-transform: capitalize;
        }
    }
}
</style>
