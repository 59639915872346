import { formatTimeValue } from '../moment';

export function parseGuideFilterPipeline ({ operator, time, ...params }) {
    let filterString;

    switch (operator) {
        case 'viewed':
            filterString = buildGuideViewedFilterString(params);
            switch (time) {
                case '<=':
                    filterString += `&&${buildGuideTimeBeforeFilterString(params)}`;
                    break;
                case '>=':
                    filterString += `&&${buildGuideTimeSinceFilterString(params)}`;
                    break;
                case 'withinlast':
                    filterString += `&&${buildGuideTimeWithinLastFilterString(params)}`;
                    break;
                case '!withinlast':
                    filterString += `&&${buildGuideTimeNotWithinLastFilterString(params)}`;
                    break;
                case 'atleast':
                    filterString += `&&${buildGuideSeenAtLeastOrMostFilterString({
                        comparisonSymbol: '>=',
                        ...params
                    })}`;
                    break;
                case 'atmost':
                    filterString += `&&${buildGuideSeenAtLeastOrMostFilterString({
                        comparisonSymbol: '<=',
                        ...params
                    })}`;
                    break;
                // default 'ever'; nothing appended
            }
            break;
        case 'lastviewed':
            filterString = buildGuideViewedFilterString(params);
            switch (time) {
                case '<=':
                    filterString += `&&${buildGuideLastTimeBeforeFilterString(params)}`;
                    break;
                case '>=':
                    filterString += `&&${buildGuideTimeSinceFilterString(params)}`;
                    break;
                case 'withinlast':
                    filterString += `&&${buildGuideTimeWithinLastFilterString(params)}`;
                    break;
                case '!withinlast':
                    filterString += `&&${buildGuideTimeNotWithinLastFilterString(params)}`;
                    break;
                case 'atleast':
                    filterString += `&&${buildGuideSeenAtLeastOrMostFilterString({
                        comparisonSymbol: '>=',
                        ...params
                    })}`;
                    break;
                case 'atmost':
                    filterString += `&&${buildGuideSeenAtLeastOrMostFilterString({
                        comparisonSymbol: '<=',
                        ...params
                    })}`;
                    break;
                case 'between':
                    filterString += `&&${buildGuideTimeBetweenFilterString(params)}`;
                    break;
            }
            break;
        case 'notviewed':
            filterString = buildGuideNotViewedFilterString(params);
            switch (time) {
                case '<=':
                    filterString += `||!(${buildGuideTimeBeforeFilterString(params)})`;
                    break;
                case '>=':
                    filterString += `||!(${buildGuideTimeSinceFilterString(params)})`;
                    break;
                case 'withinlast':
                    filterString += `||!(${buildGuideTimeWithinLastFilterString(params)})`;
                    break;
                case '!withinlast':
                    filterString += `||!(${buildGuideTimeNotWithinLastFilterString(params)})`;
                    break;
                // default 'ever'; nothing appended
            }
            break;
    }

    return { filter: filterString };
}

export function buildGuideViewedFilterString ({ filterIndex }) {
    return `!isNull(lastState${filterIndex})`;
}

export function buildGuideNotViewedFilterString ({ filterIndex }) {
    return `isNull(lastState${filterIndex})`;
}

export function buildGuideTimeBeforeFilterString ({ filterIndex, first }) {
    return `guideFirstTime${filterIndex}<=date("${formatTimeValue(first)}")`; // double-check this
}

export function buildGuideLastTimeBeforeFilterString ({ filterIndex, first }) {
    return `guideLastTime${filterIndex}<=date("${formatTimeValue(first)}")`; // double-check this
}

export function buildGuideTimeSinceFilterString ({ filterIndex, first }) {
    return `guideLastTime${filterIndex}>=date("${formatTimeValue(first)}")`;
}

export function buildGuideTimeBetweenFilterString ({ filterIndex, first, last }) {
    return `(guideLastTime${filterIndex}>=date("${formatTimeValue(
        first
    )}")&&guideLastTime${filterIndex}<=date("${formatTimeValue(last)}"))`;
}

export function buildGuideTimeWithinLastFilterString ({ filterIndex, count, granularity }) {
    return `guideLastTime${filterIndex}>=dateAdd(now(),-${count},"${granularity}")`;
}

export function buildGuideTimeNotWithinLastFilterString ({ filterIndex, count, granularity }) {
    return `(guideLastTime${filterIndex}>=now()||guideLastTime${filterIndex}<=dateAdd(now(),-${count},"${granularity}"))`;
}

export function buildGuideSeenAtLeastOrMostFilterString ({ filterIndex, value, comparisonSymbol }) {
    return `guideSeenCount${filterIndex}${comparisonSymbol}${value}`;
}
