<template>
    <pendo-form
        class="generate-ai-guide-prompt-form"
        :model="model">
        <pendo-form-item
            class="generate-ai-guide-prompt-form--user-prompt"
            prop="description"
            :rules="descriptionRules">
            <pendo-input
                v-model="model.description"
                data-cy="generate-ai-guide-prompt-form--user-prompt"
                label="Prompt"
                placeholder="Example: Create a new account"
                @change="$emit('updatePrompt', Object.assign(model, { description: $event }))" />
        </pendo-form-item>
        <pendo-form-item
            prop="maxNumberOfSteps"
            :rules="maxNumberOfStepsRules">
            <pendo-multiselect
                v-model="model.maxNumberOfSteps"
                :options="stepsArray"
                :allow-empty="false"
                data-cy="generate-ai-guide-prompt-form--max-step-count-input"
                :value="model.maxNumberOfSteps"
                :labels="{ top: 'Steps' }"
                @change="$emit('updatePrompt', Object.assign(model, { maxNumberOfSteps: $event }))" />
        </pendo-form-item>
        <pendo-multiselect
            v-model="model.tone"
            :options="toneOptions"
            :allow-empty="false"
            :value="model.tone"
            :labels="{ top: 'Tone' }"
            @change="$emit('updatePrompt', Object.assign(model, { tone: $event }))" />
        <pendo-button
            v-pendo-tooltip="dynamicDisabledSubmitionMessage"
            :disabled="disabled"
            :loading="isLoading"
            data-cy="generate-ai-guide-prompt-form--submit-button"
            label="Generate Guide"
            type="primary"
            prefix-icon="wand"
            @click="generateSteps" />
    </pendo-form>
</template>

<script>
import { PendoButton, PendoInput, PendoMultiselect, PendoForm, PendoFormItem, PendoTooltip } from '@pendo/components';
import { AI } from '@pendo/services/Constants';

const assistantTypesToOmit = ['SPELLCHECK', 'SHORTER', 'LONGER'];
const assistantTypesByKey = Object.keys(AI.ASSISTANT_TYPES);
const descriptionValidationMessage = 'Please enter the purpose of this guide';
const maxNumberOfStepsValidationMessage = 'Please set a maximum number of steps';

export default {
    name: 'PromptForm',
    components: {
        PendoButton,
        PendoMultiselect,
        PendoInput,
        PendoForm,
        PendoFormItem
    },
    directives: {
        PendoTooltip
    },
    props: {
        maximumStepCount: {
            type: Number,
            default: 10,
            validate (value) {
                return value > 0 && value <= 15;
            }
        },
        application: {
            type: Object,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        prompt: {
            type: Object,
            default: () => ({})
        },
        theme: {
            type: String,
            default: null
        }
    },
    emits: ['updatePrompt', 'generateSteps'],
    data () {
        return {
            model: Object.assign({ description: '', maxNumberOfSteps: 5, tone: null }, this.prompt),
            toneOptions: assistantTypesByKey.reduce((options, id) => {
                if (!assistantTypesToOmit.includes(id)) {
                    options.push({
                        id,
                        label: id.toLowerCase()
                    });
                }

                return options;
            }, []),
            descriptionValidationMessage,
            maxNumberOfStepsValidationMessage,
            descriptionRules: {
                required: true,
                trigger: ['blur', 'change'],
                message: descriptionValidationMessage
            },
            maxNumberOfStepsRules: {
                required: true,
                trigger: ['blur', 'change'],
                message: maxNumberOfStepsValidationMessage
            }
        };
    },
    computed: {
        stepsArray () {
            return Array.from({ length: this.maximumStepCount }, (__, index) => index + 1);
        },
        isValidMaxNumberOfSteps () {
            return Number.isInteger(this.model.maxNumberOfSteps) && this.model.maxNumberOfSteps > 0;
        },
        isValidApplication () {
            return !!this.application?.id;
        },
        hasValidDescriptionContent () {
            return this.model.description?.trim().length > 0;
        },
        isValidTone () {
            return assistantTypesByKey.includes(this.model.tone?.id);
        },
        disabled () {
            return (
                this.isLoading ||
                !this.isValidMaxNumberOfSteps ||
                !this.isValidTone ||
                !this.isValidApplication ||
                !this.hasValidDescriptionContent
            );
        },
        dynamicDisabledSubmitionMessage () {
            if (this.isLoading) return 'Loading';
            if (!this.isValidMaxNumberOfSteps) return maxNumberOfStepsValidationMessage;
            if (!this.isValidApplication) return 'Please select an app';
            if (!this.isValidTone) return 'Please select a valid tone from the dropdown';
            if (!this.hasValidDescriptionContent) return descriptionValidationMessage;

            return '';
        }
    },
    created () {
        this.model.tone = this.model.tone || this.toneOptions.find(({ id }) => id === 'PROFESSIONAL');
    },
    methods: {
        async generateSteps () {
            const { maxNumberOfSteps, description, tone } = this.model;
            this.$emit('generateSteps', {
                theme: this.theme,
                appName: this.application.name,
                appId: this.application.id,
                maxNumberOfSteps,
                description,
                assistantType: AI.ASSISTANT_TYPES[tone.id]
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.generate-ai-guide-prompt-form {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 32px;
    width: 100%;

    > * {
        margin: 0.25rem;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }

        &:first-child {
            margin-left: 0;
        }
    }

    .generate-ai-guide-prompt-form--user-prompt {
        flex: 1 0 150px;

        :deep(.pendo-form-item__error) {
            position: absolute;
        }
    }
}
</style>
