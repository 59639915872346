<template>
    <div class="engage-segment-overlay">
        <pendo-empty-state
            background-color="#F4F4F7"
            title="Edit segment in Engage"
            description="This segment was created in Engage. To edit this segment, open Engage and edit the segment there.">
            <template #icon>
                <svg
                    width="73"
                    height="72"
                    viewBox="0 0 73 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                        x="5.5"
                        y="17"
                        width="50"
                        height="50"
                        rx="4"
                        fill="#C7C8F6" />
                    <path
                        d="M51.0296 7.82345C54.7981 4.05496 60.908 4.05496 64.6765 7.82344C68.445 11.5919 68.445 17.7018 64.6765 21.4703L30.5593 55.5875L13.4577 60.2708C12.7106 60.4754 12.0245 59.7893 12.2291 59.0422L16.9124 41.9407L51.0296 7.82345Z"
                        fill="#4546E0" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M62.793 26.7072L45.793 9.70718L47.2072 8.29297L64.2072 25.293L62.793 26.7072Z"
                        fill="#060119" />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M58.793 30.7072L41.793 13.7072L43.2072 12.293L60.2072 29.293L58.793 30.7072Z"
                        fill="#060119" />
                </svg>
            </template>
            <template #actions>
                <pendo-button
                    type="primary"
                    label="Open in Engage"
                    @click="openSegmentInEngage" />
            </template>
        </pendo-empty-state>
    </div>
</template>

<script>
import { PendoEmptyState, PendoButton } from '@pendo/components';
import { env } from '@pendo/services/Environments';
import { generateEngageUrlByEnv } from '@/components/segment-builder/utils/utils';

export default {
    name: 'EngageSegmentOverlay',
    components: {
        PendoButton,
        PendoEmptyState
    },
    props: {
        segment: {
            type: Object,
            required: true
        }
    },
    methods: {
        openSegmentInEngage () {
            const baseUrl = generateEngageUrlByEnv(env());
            window.open(`${baseUrl}/segments?segment=${this.segment.id}`, '_blank');
        }
    }
};
</script>
