<template>
    <pendo-modal
        title="Clear & Reset Guide Data?"
        height="auto"
        :visible="visible"
        @close="closeModal">
        <div class="clear-data-modal--message">
            This will clear all data associated with the guide. In addition, visitors who close the guide may see it
            again if it is automatically activated.
        </div>
        <div class="clear-data-modal--message-confirm">
            Confirm by typing in <span class="clear-data-modal--message-red">clear-guide-data</span> below.
        </div>
        <pendo-input v-model="textInput" />
        <template #footer>
            <pendo-button
                type="secondary"
                theme="app"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :disabled="!validateInput"
                label="Clear Data"
                theme="app"
                type="danger"
                @click="clearGuideData" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoButton, PendoInput, PendoModal, PendoNotification } from '@pendo/components';
import { mapActions } from 'vuex';

export default {
    name: 'ClearGuideDataModal',
    components: {
        PendoButton,
        PendoInput,
        PendoModal
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            textInput: ''
        };
    },
    computed: {
        validateInput () {
            return this.textInput === 'clear-guide-data';
        }
    },
    methods: {
        ...mapActions({
            clearGuide: 'guides/clearViewData'
        }),
        closeModal () {
            this.$emit('close');
        },
        async clearGuideData () {
            try {
                await this.clearGuide({ guide: this.guide });
                PendoNotification({
                    type: 'success',
                    title: 'Guide Data Successfully Reset',
                    duration: 5000
                });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Please try again or contact support if the issue persists.',
                    duration: 5000
                });
            }

            this.closeModal();
        }
    }
};
</script>

<style lang="scss">
.clear-data-modal--message {
    margin-bottom: 12px;
}

.clear-data-modal--message-confirm {
    font-weight: 600;
    margin-bottom: 8px;
}

.clear-data-modal--message-red {
    color: $red-error;
}
</style>
