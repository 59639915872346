<template>
    <page-header
        title="Analytics"
        class="analytics-header"
        :show-banner="true">
        <template #actions>
            <pendo-button
                v-if="showCreatePathAction"
                theme="app"
                type="primary"
                prefix-icon="plus"
                label="Create Path"
                @click="onCreatePathClick" />
            <pendo-button
                v-if="showLaunchPages && !showLaunchPageEventProperties"
                class="designer-launcher"
                theme="app"
                type="primary"
                prefix-icon="external-link"
                label="Tag Pages"
                @click="showPageModal('page')" />
            <pendo-multiselect
                v-if="showLaunchPageEventProperties"
                class="designer-combo-launcher"
                stateless
                :options="designerComboOptions"
                @select="showPageModal('pageEventPropertiesView')">
                <template #trigger>
                    <pendo-split-button-trigger
                        theme="app"
                        type="primary"
                        prefix-icon="external-link"
                        label="Tag Pages"
                        @click="showPageModal('page')" />
                </template>
                <template #option="{ option }">
                    <div
                        class="designer-dropdown-option"
                        :data-cy="option.dataCy">
                        {{ option.name }}
                    </div>
                </template>
            </pendo-multiselect>
            <pendo-button
                v-if="showLaunchFeatures"
                class="designer-launcher"
                theme="app"
                type="primary"
                prefix-icon="external-link"
                label="Tag Features"
                @click="showFeatureModal" />
        </template>
    </page-header>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '@/components/PageHeader';
import { PendoButton, PendoMultiselect, PendoSplitButtonTrigger } from '@pendo/components';

export default {
    name: 'AnalyticsHeader',
    components: {
        PendoButton,
        PageHeader,
        PendoMultiselect,
        PendoSplitButtonTrigger
    },
    emits: ['openLaunchDesignerModal'],
    data () {
        return {
            pageBasedEventPropertiesLabel: 'Manage Page Event Properties'
        };
    },
    computed: {
        ...mapGetters({
            app: 'apps/active',
            getListActiveApps: 'apps/getListActiveApps',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            canCreatePage: 'pages/canCreatePage',
            allPages: 'pages/listAll',
            allFeatures: 'features/list',
            canCreateFeature: 'features/canCreateFeature',
            appsWithPageBasedEventProperties: 'apps/appsWithPageBasedEventProperties'
        }),
        designerComboOptions () {
            const options = [];
            if (this.showLaunchPageEventProperties) {
                options.push({
                    dataCy: 'countable-actions-page-based-event-properties-option',
                    name: this.pageBasedEventPropertiesLabel
                });
            }

            return options;
        },
        showCreatePathAction () {
            return this.$route.name === 'pathsDashboard';
        },
        hasIntegratedApps () {
            return this.getListActiveApps.some(({ integrated }) => integrated);
        },
        showLaunchPages () {
            return (
                this.canCreatePage({ filtered: false }) &&
                this.$route.name === 'pagesDashboard' &&
                (this.app.integrated || this.hasIntegratedApps || this.allPages.length > 0) &&
                this.activeIsDigitalAdoption
            );
        },
        showLaunchPageEventProperties () {
            return this.showLaunchPages && this.appsWithPageBasedEventProperties.length > 0;
        },
        showLaunchFeatures () {
            return (
                this.canCreateFeature({ filtered: false }) &&
                this.$route.name === 'featuresDashboard' &&
                (this.app.integrated || this.hasIntegratedApps || this.allFeatures.length > 0) &&
                this.activeIsDigitalAdoption
            );
        }
    },
    watch: {
        $route (route) {
            if (route.hash === '#autoLaunchPagesModal') {
                this.showPageModal('page');
            }
            if (route.hash === '#autoLaunchFeaturesModal') {
                this.showFeatureModal();
            }
        }
    },
    methods: {
        onCreatePathClick () {
            this.$router.push({ name: 'path', params: { id: 'new' } }).catch(() => {});
        },
        showPageModal (type) {
            this.launchDesignerModal({
                title: 'Launch to edit',
                type,
                translationWarning: false
            });
        },
        showFeatureModal () {
            this.launchDesignerModal({
                title: "Enter your app's URL",
                type: 'feature'
            });
        },
        launchDesignerModal (modalProps) {
            this.$emit('openLaunchDesignerModal', modalProps);
        }
    }
};
</script>
<style lang="scss">
.designer-dropdown-action {
    margin-left: 8px;
}

.designer-dropdown-option {
    line-height: 26px;
}
</style>
