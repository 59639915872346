import { operators as o } from '@pendo/aggregations';
import {
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { identifiedState } from '@/utils/aggregations';

export default {
    name: 'GuideViewsByStep',
    productArea: PRODUCT_AREAS?.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS?.GUIDE_METRICS,
    responseFormat: 'rows',
    build: ({ guideId, timeSeries, segmentId }) => {
        return o.pipeline(
            o.sources.guideEvents({
                guideId,
                timeSeries
            }),
            o.filter('eventSubType(type) == "guideSeen"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(['guideStepId'], o.groupField('count', o.count(null)))
        );
    }
};
