<template>
    <pendo-multiselect
        :value="rule.operator"
        :allow-empty="false"
        :preselect-first="true"
        :options="operators[schema] || []"
        :disabled="!operators[schema] || readOnly"
        min-trigger-width="100%"
        value-key="value"
        class="rule-editor--operator"
        @select="$emit('input', { field: 'operator', value: $event })" />
</template>

<script>
import { PendoMultiselect } from '@pendo/components';
import { SEGMENT_OPERATORS } from '@/components/segment-builder/constants/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'OperatorPicker',
    components: { PendoMultiselect },
    mixins: [ReadOnlyMixin],
    props: {
        operators: {
            type: Object,
            default () {
                return SEGMENT_OPERATORS;
            }
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        schema () {
            return this.rule.schema.schema;
        }
    }
};
</script>
