<template>
    <pendo-modal
        class="pendo-add-update-extension-app-modal"
        title="Select an App to Edit Theme"
        height="auto"
        :visible="visible"
        size="medium"
        @close="emitClose">
        <template #body>
            <pendo-multiselect
                :value="app"
                :allow-empty="false"
                :options="showMobileAppsOptions"
                :searchable="true"
                label-key="displayName"
                value-key="id"
                preselect-first
                full-width
                placeholder="Select Application"
                class="guide-them-app-chooser--app-select"
                @select="onAppSelect($event)">
                <template #selectedLabel>
                    <pendo-app-display :apps="app" />
                </template>
                <template #option="{ option }">
                    <pendo-app-display :apps="option" />
                </template>
            </pendo-multiselect>
        </template>
        <template #footer>
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="emitClose" />
            <pendo-button
                theme="app"
                label="Edit"
                @click="onSubmit" />
        </template>
    </pendo-modal>
</template>
<script>
import { PendoAppDisplay, PendoButton, PendoModal, PendoMultiselect } from '@pendo/components';
import { mapGetters } from 'vuex';

export default {
    name: 'GuideThemeAppChooser',
    components: {
        PendoAppDisplay,
        PendoButton,
        PendoModal,
        PendoMultiselect
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close', 'launchThemeEditor'],
    data () {
        return {
            app: null
        };
    },
    computed: {
        ...mapGetters({
            excludeMobileForActiveSubscription: 'apps/excludeMobileForActiveSubscription',
            listAllForActiveSubscription: 'apps/listAllForActiveSubscription',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            activeIsTrainingSubscription: 'subscriptions/activeIsTrainingSubscription'
        }),
        showMobileAppsOptions () {
            return this.activeIsTrainingSubscription
                ? this.listAllForActiveSubscription
                : this.excludeMobileForActiveSubscription;
        }
    },
    methods: {
        emitClose () {
            this.$emit('close');
        },
        onAppSelect (app) {
            this.app = app;
        },
        onSubmit () {
            this.$emit('launchThemeEditor', this.app);
        }
    }
};
</script>
