import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { requestVisitorOrAccountIdCsv } from './utils';

function fetchClicksForTaskListChildGuideAggOps ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const { operators: o } = Agg2;

    return [
        o.sources.guideEvents({ appId: appIds, guideId, guideStepId: stepId, timeSeries, blacklist }),
        o.filter('eventSubType(type) == "guideActivity"'),
        o.languageFilter(languageCode),
        o.identified(includeAnonymous),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        { unmarshal: { action: 'uiElementActions' } },
        o.unwind('action'),
        o.filter('action.action == "showGuide"'),
        o.filter(`action.guideId == "${childGuideId}"`)
    ];
}

function visitorAccountAggOps ({ kind }) {
    const { operators: o } = Agg2;
    const idKey = `${kind}.id`;
    const clicksKey = `${kind}.clicks`;

    return o.pipeline(
        o.group(`${kind}Id`, o.groupField('numClicks', o.count(null))),
        o.sort(['-numClicks', `${kind}Id`]),
        o.filter(`!isNull(${kind}Id) && ${kind}Id!=""`),
        o.evalExpression({
            [idKey]: `${kind}Id`,
            [clicksKey]: 'numClicks'
        }),
        o.reduce({
            [`${kind}s`]: o.list(kind)
        })
    );
}

export function getTaskListItemClicks ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const spec = buildTaskListItemClicksSpec({
        appIds,
        guideId,
        timeSeries,
        segmentId,
        stepId,
        childGuideId,
        includeAnonymous,
        blacklist,
        languageCode
    });

    return request(spec);
}

function commonTaskListItemClicksPipeline ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const { operators: o } = Agg2;

    return o.pipeline(
        ...fetchClicksForTaskListChildGuideAggOps({
            appIds,
            guideId,
            stepId,
            timeSeries,
            segmentId,
            childGuideId,
            includeAnonymous,
            blacklist,
            languageCode
        }),
        o.fork(visitorAccountAggOps({ kind: 'visitor' }), visitorAccountAggOps({ kind: 'account' })),
        o.join()
    );
}

export function buildTaskListItemClicksSpec ({
    appIds,
    guideId,
    timeSeries,
    segmentId,
    stepId,
    childGuideId,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'task-list-item-clicks',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        commonTaskListItemClicksPipeline({
            appIds,
            guideId,
            timeSeries,
            segmentId,
            stepId,
            childGuideId,
            includeAnonymous,
            blacklist,
            languageCode
        })
    );
}

export const TaskListItemClicksAgg = {
    name: 'task-list-item-clicks',
    productArea: PRODUCT_AREAS.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS,
    responseFormat: 'rows',
    build ({ appIds, guideId, timeSeries, segmentId, stepId, childGuideId, includeAnonymous, blacklist, languageCode }) {
        return commonTaskListItemClicksPipeline({
            appIds,
            guideId,
            timeSeries,
            segmentId,
            stepId,
            childGuideId,
            includeAnonymous,
            blacklist,
            languageCode
        });
    }
};

export function getTaskListItemClicksCsv ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    kind,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const spec = buildTaskListItemClicksCsvSpec({
        appIds,
        guideId,
        timeSeries,
        segmentId,
        stepId,
        childGuideId,
        kind,
        includeAnonymous,
        blacklist,
        languageCode
    });

    return requestVisitorOrAccountIdCsv(spec, kind, { shouldIncludeClicks: true });
}

function commonTaskListItemClicksCsvPipeline ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    kind,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const { operators: o } = Agg2;
    const visitor = {
        id: 'visitorId',
        clicks: 'numClicks'
    };
    const account = {
        id: 'if(isNull(accountId)||accountId=="", "(none)", accountId)',
        clicks: 'numClicks'
    };

    const select = kind === 'visitor' ? visitor : account;

    return o.pipeline(
        ...fetchClicksForTaskListChildGuideAggOps({
            appIds,
            guideId,
            stepId,
            timeSeries,
            segmentId,
            childGuideId,
            includeAnonymous,
            blacklist,
            languageCode
        }),
        o.group(`${kind}Id`, o.groupField('numClicks', o.count(null))),
        o.sort(['-numClicks', `${kind}Id`]),
        o.select(select)
    );
}

export function buildTaskListItemClicksCsvSpec ({
    appIds,
    guideId,
    stepId,
    timeSeries,
    segmentId,
    childGuideId,
    kind,
    includeAnonymous,
    blacklist,
    languageCode
}) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'task-list-item-clicks-csv',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        commonTaskListItemClicksCsvPipeline({
            appIds,
            guideId,
            stepId,
            timeSeries,
            segmentId,
            childGuideId,
            kind,
            includeAnonymous,
            blacklist,
            languageCode
        })
    );
}

export const TaskListItemClicksCSVAgg = {
    name: 'task-list-item-clicks-csv',
    productArea: PRODUCT_AREAS.GUIDES,
    subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS,
    response: ({ columns }) => {
        const fields = columns.map((column) => ({
            field: column.prop,
            label: column.label
        }));

        return {
            location: 'gcs',
            mimeType: 'text/csv',
            fields
        };
    },
    build ({
        appIds,
        guideId,
        timeSeries,
        segmentId,
        stepId,
        childGuideId,
        includeAnonymous,
        blacklist,
        languageCode,
        kind
    }) {
        return commonTaskListItemClicksCsvPipeline({
            appIds,
            guideId,
            timeSeries,
            segmentId,
            stepId,
            childGuideId,
            includeAnonymous,
            blacklist,
            languageCode,
            kind
        });
    }
};
