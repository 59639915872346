import { operators as o } from '@pendo/aggregations';
import { PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/stateless-components/utils/aggregations';

const determineElementType = () => {
    return [
        'if(isButton, "Button", elementType)',
        'if(isDismiss, "Close Button", elementType)',
        'if(isLink, "Link", elementType)',
        'if(isSwipeLeft, "Swiped Left", elementType)',
        'if(isSwipeRight, "Swiped Right", elementType)'
    ].reduce((acc, item) => {
        return acc.replace('elementType', item);
    });
};

export default {
    name: 'GuideElementsActivity',
    productArea: PRODUCT_AREAS.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS,
    responseFormat: 'rows',
    build: ({ guideId, currentIds, filters }) => {
        const { blacklist, includeAnonymous, languageCode, timeSeries } = filters;

        return o.pipeline(
            o.sources.guideEvents({ guideId, timeSeries, blacklist }),
            o.filter('eventSubType(type) == "guideActivity" && uiElementType != ""'),
            o.languageFilter(languageCode),
            o.identified(includeAnonymous),
            o.evaluate({
                elementType: 'eventSubType(type)',
                isButton: 'if(uiElementType == "BUTTON", true, false)',
                isDismiss: 'if(contains(uiElementId, "pendo-close-guide-"), true, false)',
                isLink: 'if(uiElementType == "A", true, false)',
                isDeleted: `!contains(${JSON.stringify(currentIds)}, uiElementId)`,
                isSwipeLeft: 'if(uiElementType == "LEFT-SWIPER", true, false)',
                isSwipeRight: 'if(uiElementType == "RIGHT-SWIPER", true, false)'
            }),
            o.evaluate({
                elementType: determineElementType()
            }),
            o.fork(
                o.pipeline(
                    o.group(
                        ['guideId', 'guideStepId', 'uiElementId', 'elementType', 'isDeleted'],
                        o.groupField('numClicks', o.count(null))
                    )
                ),
                o.pipeline(
                    o.group(['uiElementId', 'uiElementText'], o.groupField('mostRecent', { max: 'browserTime' })),
                    o.sort(['mostRecent'])
                ),
                o.pipeline(
                    o.group(['uiElementId', 'uiElementActions'], o.groupField('mostRecent', { max: 'browserTime' })),
                    o.sort(['mostRecent'])
                )
            ),
            o.join('uiElementId'),
            o.select({
                guideId: 'guideId',
                guideStepId: 'guideStepId',
                uiElementId: 'uiElementId',
                uiElementType: 'elementType',
                uiElementActions: 'uiElementActions',
                uiElementText: 'uiElementText',
                numClicks: 'numClicks',
                isDeleted: 'isDeleted'
            })
        );
    }
};
