<template>
    <segment-metric-panel
        title="Eligible Visitors"
        wrapper-class="editor-visitor-count"
        :value="eligibleVisitorCount"
        :loading="loading">
        <template #beforeMetric>
            <pendo-alert
                v-if="showCsvUploadWarning"
                type="warning">
                Warning: Processing CSV analytics can take up to 15 minutes to correctly show visitor counts.
            </pendo-alert>
        </template>
        <template #afterMetric>
            <div
                class="hidden-pipeline"
                data-cy="segment-pipeline">
                {{ pipeline }}
            </div>
        </template>
    </segment-metric-panel>
</template>

<script>
import { PendoAlert } from '@pendo/components';
import SegmentMetricPanel from './SegmentMetricPanel.vue';
import eligibleVisitorCountAgg from '@/components/segment-builder/aggregations/eligible-visitor-count';
import { addCommasToNumber } from '@/components/segment-builder/utils/utils';
import { connectAggregationToState, withComponent } from '@pendo/agg-connect';
import { LOADING_STATES } from '@pendo/agg-connect';
import debounce from 'lodash/debounce';

const COUNT_PLACEHOLDER = '--';

export default {
    name: 'SegmentVisitorCount',
    components: {
        PendoAlert,
        SegmentMetricPanel
    },
    props: {
        pipeline: {
            type: [Array, Object],
            default: undefined
        },
        showCsvUploadWarning: {
            type: Boolean,
            default: false
        },
        isValid: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            visitorCount: COUNT_PLACEHOLDER,
            loading: false,
            debouncedPipeline: undefined
        };
    },
    computed: {
        eligibleVisitorCount () {
            return addCommasToNumber(this.visitorCount);
        }
    },
    watch: {
        pipeline: {
            handler (newPipeline) {
                if (!this.isValid || newPipeline === null) {
                    this.visitorCount = COUNT_PLACEHOLDER;
                } else {
                    this.setDebouncedPipeline(newPipeline);
                }
            },
            deep: true
        }
    },
    created () {
        this.setupVisitorCountAgg();
        this.setDebouncedPipeline = debounce(this.setPipeline, 1500);
    },
    methods: {
        setupVisitorCountAgg () {
            const agg$ = connectAggregationToState(
                eligibleVisitorCountAgg,
                withComponent({ pipeline: 'debouncedPipeline' })
            )({ component: this });

            agg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case LOADING_STATES.LOADING:
                        this.visitorCount = COUNT_PLACEHOLDER;
                        this.loading = true;
                        break;
                    case LOADING_STATES.RESOLVED: {
                        this.visitorCount = value;
                        this.loading = false;
                        break;
                    }
                    case LOADING_STATES.REJECTED: {
                        this.visitorCount = COUNT_PLACEHOLDER;
                        this.loading = false;

                        // eslint-disable-next-line no-console
                        console.warn(response.error);
                        break;
                    }
                }
            });
        },
        setPipeline (pipeline) {
            this.loading = false;
            this.debouncedPipeline = pipeline;
        }
    }
};
</script>

<style lang="scss" scoped>
.editor-visitor-count {
    display: flex;
    flex-flow: column nowrap;

    .pendo-alert {
        margin-bottom: 20px;
    }

    .hidden-pipeline {
        display: none;
    }
}
</style>
