<template>
    <pendo-modal
        :visible="visible"
        :title="title"
        height="auto"
        @close="closeModal"
        @closed="resetModal">
        <pendo-form
            :call-validate="callValidate"
            :model="model"
            @formValidated="handleValidForm"
            @invalidForm="handleInvalidForm">
            <div class="launch-designer-modal">
                <pendo-alert
                    v-if="translationWarning"
                    type="warning">
                    Editing the content of this guide may cause a mismatch between languages.
                </pendo-alert>
                <div
                    v-if="!isCrossAppSelectApp || launched"
                    class="launch-designer-modal--message">
                    {{ bodyText }}
                </div>
                <pendo-multiselect
                    v-if="!launched && isCrossAppSelectApp"
                    full-width
                    label-key="stepId"
                    value-key="stepId"
                    :value="selectedStep"
                    :labels="{ top: 'Select a Guide step to edit' }"
                    :options="stepOptions"
                    @select="setSelectedStep">
                    <template #placeholder>
                        <step-display
                            :app="stepOptions[0].app"
                            :index="0" />
                    </template>
                    <template #selectedLabel>
                        <step-display
                            :app="selectedStep.app"
                            :index="selectedStep.index" />
                    </template>
                    <template #option="{ option }">
                        <step-display
                            :app="option.app"
                            :index="option.index" />
                    </template>
                </pendo-multiselect>
                <pendo-form-item
                    v-if="!launched"
                    :rules="urlValidationRule"
                    prop="url">
                    <pendo-input
                        v-model="model.url"
                        :class="{ 'launch-modal-input': isCrossAppSelectApp }"
                        placeholder="https://acme.io"
                        @keyup.enter="launchDesigner" />
                </pendo-form-item>
                <pendo-form-item v-if="showForceNativeDesigner">
                    <pendo-checkbox
                        label="Force designer to load from the native agent"
                        :value="model.forceNativeDesigner"
                        @change="model.forceNativeDesigner = !model.forceNativeDesigner" />
                    <pendo-icon
                        v-pendo-tooltip="{
                            content: 'Select this option to launch tagging within a web app in a double agent scenario',
                            arrow: true,
                            classes: 'is-multi-line'
                        }"
                        display="inline"
                        class="force-native-designer-icon"
                        type="info"
                        size="14" />
                </pendo-form-item>
                <pendo-form-item
                    v-if="!launched && isPendoUser && isLookasideInputVisible"
                    :rules="lookasideHostValidationRules"
                    prop="lookasideHost">
                    <label class="lookaside-host-label pendo-form-item__label">Lookaside Host</label>
                    <pendo-icon
                        v-pendo-tooltip="{
                            content: 'Lookaside host option is only visible to users with @pendo.io in their emails',
                            arrow: true,
                            classes: 'is-multi-line'
                        }"
                        display="inline"
                        class="lookaside-host-icon"
                        type="info"
                        size="14" />
                    <pendo-input
                        v-model="model.lookasideHost"
                        :autofocus="true"
                        placeholder="adopt.pendo-[env].pendo-dev.com/in-app-designer/[name]/designer.html (no trailing slash)">
                        <template #prepend>
                            https://
                        </template>
                    </pendo-input>
                </pendo-form-item>
                <pendo-form-item v-if="shouldShowLocalAdoptStudioToggle">
                    <pendo-toggle
                        v-model="useLocalAdoptStudio"
                        label="Launch using local Adopt Studio"
                        label-position="right"
                        @change="toggleUseLocalAdoptStudio" />
                </pendo-form-item>
                <a
                    v-if="launched && !supportsSecondaryLaunch && !isLaunchingInSidebar"
                    :href="helpLinkURL"
                    target="_blank">
                    <br>
                    {{ `Don't see ${guideEditorName}?` }}
                </a>
                <pendo-button
                    v-if="launched && (supportsSecondaryLaunch || isLaunchingInSidebar)"
                    :suffix-icon="showSecondarySteps ? 'chevron-up' : 'chevron-down'"
                    class="secondary-instructions"
                    theme="app"
                    type="link"
                    @click="showSecondarySteps = !showSecondarySteps">
                    {{ `Don't see ${guideEditorName}?` }}
                </pendo-button>
                <div v-if="supportsSecondaryLaunch && showSecondarySteps && !isLaunchingInSidebar">
                    <p>
                        Sometimes things don't work perfectly the first time. Let's try a different way to launch the
                        designer on your application.
                    </p>
                    <p>
                        Proceed to any browser tab with your application open and logged in. Then enter the following
                        keyboard shortcut:
                    </p>
                    <div class="keyboard-shortcut">
                        <div class="keyboard-shortcut--keys">
                            <span class="keyboard-shortcut--key">Alt</span>
                            <span class="keyboard-shortcut--key">Shift</span>
                            <span class="keyboard-shortcut--key">7</span>
                        </div>
                        <span class="keyboard-shortcut--subtext">
                            Alt (Option on Mac) + Shift + 7
                        </span>
                    </div>
                    <p>
                        {{ `Tried the shortcut and still not seeing ${guideEditorName}?` }}
                        <a
                            href="https://support.pendo.io/hc/en-us/articles/360031864732-Help-launching-Visual-Design-Studio"
                            target="_blank">
                            Learn more
                        </a>
                    </p>
                </div>
                <div v-else-if="showSecondarySteps && isLaunchingInSidebar">
                    <p>
                        Sometimes things don't work perfectly the first time.
                    </p>
                    <p>
                        If the Visual Design Studio window does not appear, your browser may have blocked the pop-up.
                        Please enable pop-ups for the site you're tagging on.
                    </p>
                    <p>
                        {{ `Still not seeing ${guideEditorName}?` }}
                        <a
                            href="https://adoptsupport.pendo.io/hc/en-us/articles/12185779107355"
                            target="_blank"
                            rel="noopener noreferrer">
                            Learn more ›
                        </a>
                    </p>
                </div>
            </div>
        </pendo-form>
        <template #footer>
            <div class="launch-designer-modal--footer">
                <pendo-button
                    v-if="!launched && isPendoUser"
                    icon="git-merge"
                    type="tertiary"
                    class="toggle-lookaside-button"
                    @click="toggleLookasideInputVisibility" />
                <div class="launch-designer-modal--footer-right">
                    <pendo-button
                        v-if="launched"
                        type="primary"
                        theme="app"
                        label="Close"
                        @click="closeModal" />
                    <pendo-button
                        v-if="!launched"
                        type="tertiary"
                        theme="app"
                        label="Cancel"
                        @click="cancelLaunch" />
                    <pendo-button
                        v-if="!launched"
                        class="open-external-link"
                        :disabled="!isValidForm"
                        :data-gid="guideId"
                        :data-sid="stepId"
                        :label="`Launch ${guideEditorName}`"
                        theme="app"
                        type="primary"
                        icon="external-link"
                        @click="launchDesigner" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
    PendoAlert,
    PendoButton,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoToggle,
    PendoIcon,
    PendoTooltip,
    PendoMultiselect,
    PendoCheckbox,
    PendoModal
} from '@pendo/components';
import StepDisplay from '@/components/common/StepDisplay';
import { lookasideHostValidationRules, isValidLaunchDesignerForm } from '@/utils/utils';
import {
    updateVisitorMetadata,
    writeTokenToLocalStorage,
    launchViaWindowOpener,
    generateAdoptStudioLaunchParams,
    generateVDSLaunchParams,
    DESIGNER_LAUNCH_TOKEN_LS_KEY,
    WHITELABEL_SETTINGS_TOKEN_LS_KEY,
    getBestDesignerUrl,
    getFormattedDesignerUrl
} from '@/stateless-components/utils/designer';
import { meetsMinimumAgentVersion, getDesignerAppName } from '@/utils/apps';
import { addAppToEntityList } from '@/stateless-components/utils/apps';
import get from 'lodash/get';
import { EDITOR_TYPES, PENDO_HELP_LINKS } from '@pendo/services/Constants';
import { getAppGroups } from '@pendo/services/CrossAppGuides';
import { urlValidationRule } from '@pendo/services/Formatters';

export default {
    name: 'LaunchDesignerModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoForm,
        PendoFormItem,
        PendoToggle,
        PendoInput,
        PendoIcon,
        PendoMultiselect,
        StepDisplay,
        PendoCheckbox,
        PendoModal
    },
    directives: {
        PendoTooltip
    },
    props: {
        stepId: {
            type: String,
            default: null
        },
        guideId: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'feature'
        },
        translationWarning: {
            type: Boolean,
            default: false
        },
        isCrossApp: {
            type: Boolean,
            default: false
        },
        stepAppId: {
            type: Number,
            default: null
        },
        suggestedDomains: {
            type: Array,
            default: null
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Launch Designer'
        }
    },
    emits: ['close', 'closed'],
    data () {
        return {
            pageFeatureTypes: ['page', 'feature', 'pageEventPropertiesView'],
            isLookasideInputVisible: false,
            supportsSecondaryLaunch: false,
            model: {
                url: '',
                lookasideHost: null,
                forceNativeDesigner: false
            },
            launched: false,
            callValidate: false,
            urlValidationRule,
            lookasideHostValidationRules,
            showSecondarySteps: false,
            useLocalAdoptStudio: false,
            selectedStep: null,
            crossAppStepId: null
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            designerUrl: (state) => state.designer.url
        }),
        ...mapGetters({
            app: 'apps/active',
            appId: 'apps/activeId',
            apps: 'apps/appMapForActiveSubscription',
            appWhiteLabelSettings: 'apps/whiteLabelSettings',
            getAppFromGuide: 'apps/appFromGuide',
            productName: 'subscriptions/productName',
            activeUsesV2Adopt: 'subscriptions/activeUsesV2Adopt',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            usesMultiApp: 'subscriptions/usesMultiApp',
            getGuideById: 'guides/getGuideById',
            isPendoUser: 'auth/isPendoUser',
            lastUrlByAppId: 'designer/lastUrlByAppId',
            activeIsAEU: 'subscriptions/activeIsTrainingSubscription',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            activeHasSnippetWebApps: 'subscriptions/activeHasSnippetWebApps',
            userPreferences: 'userSettings/getUserPreferences'
        }),
        showForceNativeDesigner () {
            return this.activeHasSnippetWebApps && this.pageFeatureTypes.includes(this.type);
        },
        domainPatterns () {
            if (this.suggestedDomains && this.suggestedDomains.length > 0) return this.suggestedDomains;

            return this.app.extensionDomainPatterns && this.app.extensionDomainPatterns.length > 0
                ? [...this.app.extensionDomainPatterns]
                : [this.app.extensionDomainPatterns];
        },
        guideEditorName () {
            return getDesignerAppName(this.useDADesigner, this.type);
        },
        bodyText () {
            if (this.type === 'feature') {
                return `This will open a new tab and launch ${this.guideEditorName}${
                    this.isLaunchingInSidebar ? ' in a separate window on the right' : ''
                }. In ${this.guideEditorName}, you can tag new features or edit existing ones.`;
            }
            if (this.type === 'page') {
                return `Enter your app's URL. This will open a new tab and launch ${this.guideEditorName}${
                    this.isLaunchingInSidebar ? ' in a separate window on the right' : ''
                } so you can edit your tagged page.`;
            }
            const notLaunched = `Enter the URL where you'd like to see your guide. This will open a new tab and launch ${this.guideEditorName}.`;
            const launched = `You should see a new tab with ${this.guideEditorName} overlaying the URL you specified.`;

            return this.launched ? launched : notLaunched;
        },
        isValidForm () {
            const {
                model: { url, lookasideHost }
            } = this;

            return isValidLaunchDesignerForm({ url, lookasideHost });
        },
        useDADesigner () {
            const editorType = get(this, 'guide.editorType', EDITOR_TYPES.ADOPT_UI);
            if (this.activeIsAEU && !this.activeIsDigitalAdoption) return false;
            if (this.pageFeatureTypes.includes(this.type)) return false;
            if (editorType === EDITOR_TYPES.ADOPT_STUDIO) return true;
            if (editorType === EDITOR_TYPES.VDS) return false;
            if (editorType === EDITOR_TYPES.ENGAGE_UI) return false;

            return this.activeUsesDADesigner;
        },
        isRunningOnLocalhost () {
            return window.location.origin.includes('local');
        },
        shouldShowLocalAdoptStudioToggle () {
            return (
                !this.launched &&
                this.isPendoUser &&
                this.isLookasideInputVisible &&
                this.useDADesigner &&
                this.isRunningOnLocalhost
            );
        },
        guideAppId () {
            return get(this, 'guide.appId');
        },
        guide () {
            return this.getGuideById(this.guideId);
        },
        helpLinkURL () {
            return this.guideEditorName === 'Adopt Studio' ? PENDO_HELP_LINKS.asHelpDocs : PENDO_HELP_LINKS.vdsHelpDocs;
        },
        idForApp () {
            if (this.isCrossApp && this.stepAppId) return this.stepAppId;

            if (this.isCrossAppSelectApp) return get(this, 'selectedStep.app.id', this.stepOptions[0].app.id);

            return this.guideAppId;
        },
        lastUrlByApp () {
            return this.lastUrlByAppId(this.idForApp);
        },
        isCrossAppSelectApp () {
            return this.isCrossApp && !this.stepAppId;
        },
        stepOptions () {
            let prev = 0;
            const steps = getAppGroups(this.guide).map((group) => {
                const step = group[0];
                step.index = prev;
                prev += group.length;

                return step;
            });

            return addAppToEntityList(steps, this.apps).reduce((formattedSteps, step) => {
                const {
                    app: { extensionDomainPatterns },
                    index
                } = step;
                const option = {
                    index,
                    app: step.app,
                    url: extensionDomainPatterns ? getFormattedDesignerUrl(extensionDomainPatterns[0]) : '',
                    stepId: step.id
                };

                formattedSteps.push(option);

                return formattedSteps;
            }, []);
        },
        isTaggingType () {
            return this.type === 'page' || this.type === 'feature';
        },
        isLaunchingInSidebar () {
            return this.hasUserPreference('tagInASeparateBrowserWindow') && this.isTaggingType;
        }
    },
    created () {
        // pre-fill modal url if it exists
        this.model.url = this.isCrossAppSelectApp ? this.stepOptions[0].url : this.getModalUrl();
    },
    methods: {
        ...mapActions({
            updateDesignerUrl: 'designer/updateDesignerUrl'
        }),
        toggleLookasideInputVisibility () {
            this.isLookasideInputVisible = !this.isLookasideInputVisible;
        },
        closeModal () {
            this.$emit('close');
        },
        resetModal () {
            this.launched = false;
            this.isLookasideInputVisible = false;
            this.selectedStep = null;
            this.crossAppStepId = null;
            this.model.url = this.isCrossAppSelectApp ? this.stepOptions[0].url : this.getModalUrl();
            this.$emit('closed');
        },
        toggleUseLocalAdoptStudio (toggleValue) {
            this.useLocalAdoptStudio = toggleValue;
        },
        launchDesigner () {
            if (!this.isValidForm) {
                return;
            }

            const {
                guideId,
                guide,
                type,
                model: { url, lookasideHost }
            } = this;

            const stepId = this.isCrossAppSelectApp ? this.crossAppStepId : this.stepId;

            const host = lookasideHost ? `https://${lookasideHost}` : window.location.origin;

            const token = {
                guideId,
                stepId,
                type,
                target: 'via',
                idForApp: this.idForApp,
                DADesigner: this.useDADesigner,
                adoptv2: this.activeUsesV2Adopt,
                host
            };

            if (this.useDADesigner || lookasideHost) {
                token.target = 'latest';
                const launchParams = this.useDADesigner
                    ? generateAdoptStudioLaunchParams(lookasideHost)
                    : generateVDSLaunchParams(lookasideHost);
                Object.assign(token, launchParams);
            }

            if (this.useLocalAdoptStudio) {
                token.target = '999.999.999-local';
            }

            if (this.isLaunchingInSidebar) {
                token.sidebarDesigner = true;
            }

            const appFromGuide = guide ? this.getAppFromGuide(guide) : {};

            token.forceNativeDesigner = this.pageFeatureTypes.includes(type)
                ? this.model.forceNativeDesigner
                : get(appFromGuide, 'platform', '') === 'web';

            const multiAppWhiteLabelSettings = get(appFromGuide, 'trainingAttributes.whiteLabelSettings', {});
            const whiteLabelToken = this.usesMultiApp
                ? {
                      ...multiAppWhiteLabelSettings,
                      productName: this.productName
                  }
                : {
                      ...this.appWhiteLabelSettings,
                      productName: this.productName
                  };
            this.updateDesignerUrl({ url, appId: this.idForApp });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(token));
            writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelToken));
            launchViaWindowOpener(url, token);
            this.launched = true;
        },
        cancelLaunch () {
            this.closeModal();
            if (this.supportsSecondaryLaunch) {
                // clear metadata to prevent in-advertent designer launch and override launch token
                const values = { designerenabled: false };
                updateVisitorMetadata({ user: this.user, values });
                writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, '');
            }
        },
        handleValidForm () {
            this.callValidate = false;
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        validateForm () {
            this.callValidate = true;
        },
        async initSecondaryLaunch () {
            this.supportsSecondaryLaunch = await meetsMinimumAgentVersion('2.15.12', this.appId);

            if (!this.supportsSecondaryLaunch) {
                return;
            }

            const values = { designerenabled: true };
            const { guideId, stepId, user, type } = this;
            const localStorageToken = {
                guideId,
                stepId,
                type,
                target: this.useDADesigner ? 'latest' : 'via',
                idForApp: this.idForApp,
                host: window.location.origin,
                DADesigner: this.useDADesigner,
                adoptv2: this.activeUsesV2Adopt
            };

            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            // set metadata and write token to local storage when the modal is opened
            updateVisitorMetadata({ user, values });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(localStorageToken));
            writeTokenToLocalStorage(WHITELABEL_SETTINGS_TOKEN_LS_KEY, JSON.stringify(whiteLabelToken));
        },
        getModalUrl () {
            const unformattedModalUrl = this.isCrossApp
                ? getBestDesignerUrl(this.domainPatterns)
                : getBestDesignerUrl(this.domainPatterns, this.designerUrl);

            if (this.lastUrlByApp && !this.isCrossApp) {
                return this.lastUrlByApp;
            }

            return getFormattedDesignerUrl(unformattedModalUrl);
        },
        setSelectedStep (selectedStep) {
            this.selectedStep = selectedStep;
            this.crossAppStepId = selectedStep.stepId;
            this.model.url = selectedStep.url;
        },
        hasUserPreference (preference) {
            const preferenceEnabled = get(this.userPreferences, `${preference}.value`, false);

            return preferenceEnabled;
        }
    }
};
</script>
<style lang="scss">
.launch-designer-modal {
    &--message {
        margin-bottom: 16px;
        font-weight: 600;
        padding: 0;
    }

    :deep(.pendo-alert) {
        margin-bottom: 16px;
    }
}

.keyboard-shortcut {
    background-color: $gray-lighter-7;
    margin: 1em 26%;
    padding: 1em;
    border-radius: 3px;

    &--keys {
        display: flex;
        justify-content: center;
    }

    &--key {
        align-items: center;
        background-color: $white;
        border-radius: 5px;
        border: 1px solid $gray-lighter-5;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
        display: inline-flex;
        justify-content: center;
        margin: 5px;
        height: 32px;
        min-width: 32px;
        padding: 8px;
    }

    &--subtext {
        color: $gray-primary;
        display: flex;
        font-size: 0.875em;
        justify-content: center;
        margin-top: 0.5em;
    }
}

.lookaside-host-label {
    margin-right: 8px;
    height: 22px;
    padding: 0;
}

.secondary-instructions.via.pendo-button--medium {
    padding: 0;
}

.launch-designer-modal--footer {
    display: grid;
    grid-auto-flow: column;

    .toggle-lookaside-button {
        justify-self: start;
        width: 36px;
    }

    .launch-designer-modal--footer-right {
        justify-self: end;
    }
}

.launch-modal-input {
    margin-top: 30px;
}

.force-native-designer-icon {
    margin-left: 2px;
}
</style>
