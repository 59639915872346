<template>
    <div class="features-dashboard">
        <global-filters>
            <template #right>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
        </global-filters>
        <div class="features-dashboard--grid">
            <div class="features-dashboard--row">
                <features-list
                    :search-string="searchInput"
                    class="features-dashboard--features-list"
                    @openLaunchDesignerModal="$emit('openLaunchDesignerModal', $event)" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import FeaturesList from '@/components/analytics/features/FeaturesList';
import Search from '@/components/Search';
import GlobalFilters from '@/components/filters/GlobalFilters';

export default {
    name: 'FeaturesDashboard',
    components: {
        FeaturesList,
        Search,
        GlobalFilters
    },
    emits: ['openLaunchDesignerModal'],
    data () {
        return {
            searchInput: ''
        };
    },
    created () {
        this.fetchFeatures();
        this.fetchWorkflows();
    },
    methods: {
        ...mapActions({
            fetchFeatures: 'features/fetchWithAnalytics',
            fetchWorkflows: 'workflows/fetch'
        })
    }
};
</script>

<style lang="scss">
.features-dashboard {
    .features-dashboard--grid {
        display: grid;
        grid-gap: 32px;
    }

    .features-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}
</style>
