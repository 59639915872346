<template>
    <pendo-modal
        title="Guide Content Validation"
        height="auto"
        :visible="visible"
        @close="closeModal">
        <div class="content-validation">
            <div class="content-validation--guide-id">
                <pendo-input
                    :value="guide.id"
                    :disabled="true"
                    label="Guide ID" />
            </div>
            <div class="content-validation--guide-hashes">
                <pendo-input
                    v-model="contentHashJson"
                    :disabled="true"
                    label="Validation Content"
                    rows="8"
                    type="textarea" />
            </div>
        </div>
        <template #footer>
            <pendo-button
                type="secondary"
                theme="app"
                label="Close"
                @click="closeModal" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoButton, PendoInput, PendoModal } from '@pendo/components';
import { getBuildingBlockGuideSignature } from '@/utils/guides';

export default {
    name: 'GuideContentValidation',
    components: {
        PendoButton,
        PendoInput,
        PendoModal
    },
    props: {
        guide: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        contentHashJson () {
            return JSON.stringify(getBuildingBlockGuideSignature(this.guide));
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        }
    }
};
</script>

<style lang="scss">
.content-validation {
    display: flex;
    flex-flow: column nowrap;

    &--guide-id {
        margin-bottom: 1em;
    }
}
</style>
