import { formatTimeValue } from '../moment';

export function parseGuideElementFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'clicked':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildGuideElementClickedWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildGuideElementClickedSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atleast') {
                filterString = buildGuideElementClickedAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = `${buildGuideElementNullFilterString(
                    filterIndex
                )}||${buildGuideElementClickedAtLeastOrMostFilterString(filterIndex, value, '<=')}`;

                break;
            }

            filterString = buildGuideElementClickedEverFilterString(filterIndex, '>');

            break;
        case 'notclicked':
            filterString = buildGuideElementNullFilterString(filterIndex);
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString += `||${buildGuideElementClickedWithinLastFilterString(
                    filterIndex,
                    count,
                    granularity,
                    '<'
                )}`;

                break;
            }

            if (time === 'since') {
                filterString += `||${buildGuideElementClickedSinceFilterString(filterIndex, value, '<')}`;

                break;
            }

            filterString += `||${buildGuideElementClickedEverFilterString(filterIndex, '<=')}`;

            break;
    }

    return { filter: filterString };
}

export function buildGuideElementNullFilterString (filterIndex) {
    return `isNull(guideElement${filterIndex}_itemSeen.count)`;
}

export function buildGuideElementClickedEverFilterString (filterIndex, comparisonSymbol) {
    return `guideElement${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildGuideElementClickedWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `guideElement${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 *
        countValue},"${timeUnit}")`;
}

export function buildGuideElementClickedSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `guideElement${filterIndex}_itemSeen.lastUsed${comparisonSymbol}date("${formatTimeValue(timeValue)}")`;
}

export function buildGuideElementClickedAtLeastOrMostFilterString (filterIndex, countValue, comparisonSymbol) {
    return `guideElement${filterIndex}_itemSeen.count${comparisonSymbol}${countValue}`;
}
