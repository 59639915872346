<template>
    <div class="segment-rule">
        <operator-picker
            :rule="rule"
            v-on="$listeners" />
        <!-- segment list picker -->
        <div :class="{ 'segment-undefined': rule.segmentDeleted }">
            <pendo-multiselect
                :value="rule.segment"
                :allow-empty="false"
                :options="segmentPickerOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                placeholder="Select Segment"
                class="segment-rule--segment-select"
                @select="onSegmentSelect">
                <template #option="{ option, index }">
                    <pendo-multiselect-option
                        v-pendo-tooltip="{
                            content: option.tooltipContent,
                            placement: 'top',
                            arrow: true,
                            multiline: true
                        }"
                        :option="option"
                        :index="index" />
                </template>
            </pendo-multiselect>
            <rule-warning v-if="rule.segmentDeleted">
                The original segment for this rule has been deleted.
            </rule-warning>
            <rule-warning v-else-if="isSegmentOrphaned">
                The segment used by this rule is no longer being shared.
            </rule-warning>
            <rule-warning v-else-if="rule.segmentInvalid">
                The segment used by this rule is invalid.
            </rule-warning>
        </div>
        <div
            v-if="rule.segment && !rule.segmentInvalid"
            class="segment-summary">
            <pendo-collapse
                v-model="activePanel"
                :panels="panels">
                <template #rulesTitle>
                    <pendo-icon
                        class="segment-link-icon"
                        type="link"
                        size="12" />
                    <span class="segment-link-text">{{ rule.segment.name }}</span>
                    <span class="rule-count">{{ ruleCountText }}</span>
                </template>
                <template #rules>
                    <segment-summary
                        data-cy="segment-card-segment-summary"
                        :show-name="false"
                        :show-label="false"
                        :segment="rule.segment"
                        :guides-map="computedData.guidesMap"
                        :pages-map="computedData.pagesMap"
                        :features-map="computedData.featuresMap"
                        :workflows-map="computedData.workflowsMap"
                        :segments-map="computedData.segmentsMap"
                        :apps-map="computedData.appsMap" />
                </template>
            </pendo-collapse>
        </div>
    </div>
</template>

<script>
import { PendoMultiselect, PendoMultiselectOption, PendoTooltip, PendoCollapse, PendoIcon } from '@pendo/components';
import { getRulesFromSegment } from '@/components/segment-builder/utils/segments';
import { pluralize } from '@/components/segment-builder/utils/filters';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import OperatorPicker from '../rule-components/OperatorPicker';
import RuleWarning from '../rule-components/RuleWarning';
import SegmentSummary from '../SegmentSummary';

export default {
    name: 'SegmentRule',
    components: {
        OperatorPicker,
        PendoCollapse,
        PendoIcon,
        PendoMultiselect,
        PendoMultiselectOption,
        RuleWarning,
        SegmentSummary
    },
    directives: {
        PendoTooltip
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        },
        segment: {
            type: Object,
            required: false,
            default: () => null
        }
    },
    data () {
        return {
            activePanel: null,
            panels: [
                {
                    name: 'rules',
                    title: 'rules',
                    titleSlotName: 'rulesTitle'
                }
            ]
        };
    },
    computed: {
        segmentPickerOptions () {
            const appendSegmentOptionMetadata = (option) => {
                let tooltipContent = '';
                if (!option.shared) {
                    tooltipContent = 'Segment cannot be selected: Visibility set to Only Me';
                } else if (option.compound) {
                    tooltipContent = 'Segment cannot be selected: Segment is already referenced by another segment';
                } else if (!option.definition?.filters?.length) {
                    tooltipContent = 'Segment cannot be selected: A segment with no rules cannot be nested';
                }

                return { disabled: !!tooltipContent, tooltipContent };
            };

            return this.computedData.segments
                .filter((segment) => this.segment?.id !== segment.id)
                .map((segment) => {
                    return { ...segment, ...appendSegmentOptionMetadata(segment) };
                });
        },
        isSegmentOrphaned () {
            const { rule } = this;

            return rule.segment && !rule.isSegmentShared;
        },
        ruleCount () {
            const { filters } = this.rule?.segment?.definition || {};
            if (!filters) {
                return 0;
            }

            return filters.reduce((count, filter) => count + (filter.or?.length || 1), 0);
        },
        ruleCountText () {
            return `(Includes ${this.ruleCount} ${pluralize('rule', this.ruleCount)})`;
        }
    },
    created () {
        if (this.isSegmentInvalid(this.rule.segment)) {
            this.onSegmentSelect(this.rule.segment);
        }
    },
    methods: {
        isSegmentInvalid (segment) {
            if (!segment) {
                return false;
            }

            return getRulesFromSegment(segment).some((orRules) => {
                return orRules.some((rule) => {
                    return ['guide', 'workflow', 'feature', 'page', 'rcModule'].some((type) => {
                        const entityType = type === 'rcModule' ? 'guide' : type;
                        const idType = type === 'rcModule' ? 'resourceCenter' : type;
                        if (rule[`${entityType}Id`] && rule.schema.id === idType) {
                            const entity = this.computedData[`${type}sMap`][rule[`${entityType}Id`]];

                            return !entity;
                        }

                        return false;
                    });
                });
            });
        },
        onSegmentSelect (segment) {
            this.$emit('input', [
                { field: 'segment', value: segment },
                { field: 'isSegmentShared', value: true },
                { field: 'segmentDeleted', value: false },
                { field: 'segmentInvalid', value: this.isSegmentInvalid(segment) }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.segment-rule {
    display: contents;

    .segment-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}

.segment-summary {
    grid-column: 1 / span 3;

    .segment-link {
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .segment-link-icon {
        margin-right: 5px;
    }

    .segment-link-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .rule-count {
        color: $gray-lighter-2;
        white-space: nowrap;
    }
}

:deep(.pendo-collapse-item__header) {
    padding: 0;
    border: 0;
    height: 32px;
    line-height: 32px;
    grid-gap: 4px;
    justify-content: start;
}

:deep(.pendo-collapse-item__content) {
    padding-bottom: 0px;
    padding-top: 12px;
}
</style>
