import { formatTimeValue } from '../moment';

export function parsePageFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'viewed':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildPageSeenWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atleast') {
                filterString = buildPageSeenAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = buildPageSeenAtLeastOrMostFilterString(filterIndex, value, '<=');

                break;
            }

            filterString = buildPageSeenEverFilterString(filterIndex, '>');

            break;
        case 'lastviewed':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildPageSeenWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === '!withinlast') {
                const { count, granularity } = value;
                filterString = buildPageSeenNotWithinLastFilterString(filterIndex, count, granularity);

                break;
            }

            if (time === 'since') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'before') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '<=');

                break;
            }

            if (time === 'atleast') {
                filterString = buildPageSeenAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = buildPageSeenAtLeastOrMostFilterString(filterIndex, value, '<=');

                break;
            }

            if (time === 'between') {
                filterString = buildPageSeenBetweenFilterString(filterIndex, value.first, value.last);

                break;
            }

            filterString = buildPageSeenEverFilterString(filterIndex, '>');

            break;
        case 'notviewed':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildPageSeenWithinLastFilterString(filterIndex, count, granularity, '<');

                break;
            }

            if (time === 'since') {
                filterString = buildPageSeenSinceFilterString(filterIndex, value, '<');

                break;
            }

            filterString = buildPageSeenEverFilterString(filterIndex, '<=');

            break;
    }

    return { filter: filterString };
}

export function buildPageSeenEverFilterString (filterIndex, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildPageSeenWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 * countValue},"${timeUnit}")`;
}

export function buildPageSeenNotWithinLastFilterString (filterIndex, countValue, timeUnit) {
    return `(page${filterIndex}_itemSeen.lastUsed>=now()||page${filterIndex}_itemSeen.lastUsed<=dateAdd(now(),-${countValue},"${timeUnit}"))`;
}

export function buildPageSeenSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.lastUsed${comparisonSymbol}date("${formatTimeValue(timeValue)}")`;
}

export function buildPageSeenBetweenFilterString (filterIndex, first, last) {
    return `(page${filterIndex}_itemSeen.lastUsed>=date("${formatTimeValue(
        first
    )}")&&page${filterIndex}_itemSeen.lastUsed<=date("${formatTimeValue(last)}"))`;
}

export function buildPageSeenAtLeastOrMostFilterString (filterIndex, countValue, comparisonSymbol) {
    return `page${filterIndex}_itemSeen.count${comparisonSymbol}${countValue}`;
}
