<template>
    <pendo-card
        ref="card"
        class="journey-card-container">
        <div
            v-if="showPagination"
            class="pagination-controls">
            <a
                v-if="canScrollLeft"
                href="#"
                class="prev"
                @click.prevent="scrollLeft">
                <span class="symbol">&lt;</span> Previous
            </a>
            <span
                v-else
                class="prev disabled"> <span class="symbol">&lt;</span> Previous </span>

            <span class="spacer">|</span>
            <a
                v-if="canScrollRight"
                href="#"
                class="next"
                @click.prevent="scrollRight">
                Next <span class="symbol">&gt;</span>
            </a>
            <span
                v-else
                class="next disabled"> Next <span class="symbol">&gt;</span> </span>
        </div>
        <div
            ref="canvas"
            class="journey-canvas"
            @scroll="setScrollPosition">
            <transition-group
                name="journey"
                tag="div"
                class="journey-list-container">
                <div
                    v-for="(row, journeyNum) in sortedJourneyArray"
                    :key="row.id"
                    class="journey-nodes">
                    <journey-list
                        :num-of-attempts="row.numAttempts"
                        :avg-time-taken="row.avgTimeTaken"
                        :steps="row.journey"
                        :step-count="row.numSteps"
                        :header-type="sortBy"
                        :total-num-of-attempts="totalAttempts"
                        :journey-num="journeyNum + 1"
                        :included-steps="includedSteps"
                        :show-default-icon="showDefaultIcon"
                        :is-engage="isEngage"
                        @toggleIncludedStep="toggleIncludedStep" />
                </div>
            </transition-group>
        </div>
    </pendo-card>
</template>
<script>
import { PendoCard } from '@pendo/components';
import JourneyList from '@/stateless-components/workflows/journeys/JourneyList';
import debounce from 'lodash/debounce';

export default {
    name: 'AllJourneys',
    components: {
        PendoCard,
        JourneyList
    },
    props: {
        journeyData: {
            type: Array,
            default: () => []
        },
        includedSteps: {
            type: Object,
            default: () => ({})
        },
        updating: {
            type: Boolean,
            default: false
        },
        sortBy: {
            type: String,
            default: 'dispFrequency'
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        isEngage: {
            type: Boolean,
            default: false
        }
    },
    emits: ['toggleIncludedStep'],
    data () {
        return {
            headerType: 'dispFrequency',
            scrollPosition: 0,
            journeyWidth: 317,
            canvasWidth: 0,
            cardWidth: 0
        };
    },
    computed: {
        sortedJourneyArray () {
            const tempJourneys = [...this.journeyData];
            switch (this.sortBy) {
                case 'dispFrequency': {
                    return tempJourneys.sort((journeyA, journeyB) => {
                        return journeyB.numAttempts - journeyA.numAttempts;
                    });
                }
                case 'dispNumOfSteps': {
                    return tempJourneys.sort((journeyA, journeyB) => {
                        return journeyA.numSteps - journeyB.numSteps;
                    });
                }
                default: {
                    return tempJourneys.sort((journeyA, journeyB) => {
                        return journeyA.avgTimeTaken - journeyB.avgTimeTaken;
                    });
                }
            }
        },
        totalAttempts () {
            return this.journeyData.reduce((totalAttempts, curRow) => totalAttempts + curRow.numAttempts, 0);
        },
        journeysPerScroll () {
            return Math.floor(this.cardWidth / this.journeyWidth);
        },
        canScrollLeft () {
            return this.scrollPosition !== 0;
        },
        canScrollRight () {
            return this.canvasWidth - this.cardWidth > this.scrollPosition;
        },
        showPagination () {
            return this.journeysPerScroll < this.journeyData.length;
        }
    },
    watch: {
        updating (loadingIndicator) {
            // `updating` is a prop that corelates to the temporary loading state passed from MainJourneys
            if (!loadingIndicator) {
                // the loading indicator is no longer hiding the canvas/card and ref widths can be recalculated
                this.setWidths();
            }
        }
    },
    mounted () {
        this.setWidths();
        window.addEventListener('resize', this.setWidths);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.setWidths);
    },
    methods: {
        setWidths () {
            this.canvasWidth = this.$refs.canvas.scrollWidth;
            this.cardWidth = this.$refs.card.$el.clientWidth;
        },
        setScrollPosition: debounce(function () {
            this.scrollPosition = this.$refs.canvas.scrollLeft;
        }, 100),
        scrollRight () {
            const remainingCanvas = this.canvasWidth - this.scrollPosition;
            let scrollTo = this.scrollPosition + this.journeyWidth * this.journeysPerScroll;
            if (remainingCanvas < scrollTo) {
                scrollTo = this.canvasWidth - this.journeyWidth * this.journeysPerScroll;
            }
            this.$refs.canvas.scroll({
                top: 0,
                left: scrollTo,
                behavior: 'smooth'
            });
        },
        scrollLeft () {
            if (this.scrollPosition === 0) return;
            let scrollTo = this.scrollPosition - this.journeyWidth * this.journeysPerScroll;
            if (scrollTo < 0) {
                scrollTo = 0;
            }
            this.$refs.canvas.scroll({
                top: 0,
                left: scrollTo,
                behavior: 'smooth'
            });
        },
        toggleIncludedStep (step) {
            this.$emit('toggleIncludedStep', step);
        }
    }
};
</script>
<style lang="scss" scoped>
.journey-move {
    transition: transform 0.5s;
}

.pagination-controls {
    width: 210px;
    margin: 20px auto;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;

    .prev {
        &.disabled {
            color: $gray-lighter-3;

            &:hover {
                cursor: not-allowed;
            }
        }

        .symbol {
            margin-right: 10px;
        }
    }

    .spacer {
        margin: 0 20px;
        font-weight: 400;
    }

    .next {
        &.disabled {
            color: $gray-lighter-3;

            &:hover {
                cursor: not-allowed;
            }
        }

        .symbol {
            margin-left: 10px;
        }
    }
}

.journey-canvas {
    overflow: auto;
    width: inherit;
    padding-bottom: 25px;

    .journey-list-container {
        display: flex;
        justify-content: flex-start;
        margin: 0 auto;
        width: fit-content;
    }

    .journey-list-container-overflow {
        display: flex;
        justify-content: flex-start;
    }

    .journey-nodes {
        margin-left: 20px;
        margin-right: 20px;
    }
}
</style>
