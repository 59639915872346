<template>
    <pendo-drawer
        :visible="isVisible"
        :title="title"
        size="fullscreen"
        class-name="segment-builder"
        @open="initSegmentData"
        @closed="resetLocalState"
        @close="close">
        <template #body>
            <engage-segment-overlay
                v-if="isEngageSegment"
                :segment="editingSegment" />
            <template v-if="!isEngageSegment">
                <div class="settings-panel">
                    <div class="settings-panel__inner">
                        <pendo-card body-min-height="0">
                            <div class="settings-panel__section">
                                <div class="settings-panel--segment-name">
                                    <pendo-input
                                        v-model="name"
                                        :invalid="!name"
                                        maxlength="48"
                                        :disabled="readOnly"
                                        placeholder="Segment Name"
                                        label="Segment Name" />
                                </div>
                            </div>
                            <segment-stats-panel
                                :pipeline="pipeline"
                                :nested-segment-count="nestedSegmentCount"
                                :show-csv-upload-warning="showCsvUploadWarning"
                                :is-valid="isPipelineValid" />
                            <segment-super-panel
                                v-if="showSuperPanel"
                                v-model="segmentFlagValue"
                                :segment-flag-input-disabled="editingSegment === null ? false : createdBySomeoneElse"
                                :pipeline-json="pipelineJson"
                                @invalid-segment-flag="invalidSegmentFlag = $event" />
                            <visitor-type-options
                                v-if="config.canShowAnonymous"
                                :disable-anonymous-option="disableAnonymousOption"
                                :value="selectedVisitorType"
                                @input="updateVisitorType" />
                        </pendo-card>
                    </div>
                </div>
                <div class="rules-panel">
                    <segment-editor
                        :is-visible="isVisible"
                        :read-only="readOnly"
                        :segment="segment"
                        :config="config"
                        @update-nested-segment-count="($event) => (nestedSegmentCount = $event)"
                        @update-rules="onUpdateRules"
                        @showCsvUploadWarning="showCsvUploadWarning = $event" />
                </div>
            </template>
        </template>
        <template #footer>
            <footer class="segment-builder--footer">
                <pendo-button
                    theme="app"
                    type="secondary"
                    :label="closeButtonLabel"
                    class="segment-builder--close"
                    @click="close" />
                <pendo-button
                    v-if="showSaveButton"
                    :disabled="invalidSegment"
                    :loading="isSaving"
                    theme="app"
                    type="primary"
                    :label="saveButtonLabel"
                    class="segment-builder--save"
                    @click="save" />
            </footer>
        </template>
    </pendo-drawer>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';

import { PendoButton, PendoCard, PendoDrawer, PendoInput } from '@pendo/components';
import { validateAndBuildSegment, getSegmentVisitorType } from '@/components/segment-builder/utils/segments';
import { SEGMENT_OPERATORS } from '@/components/segment-builder/constants/segments';
import SegmentStatsPanel from './SegmentStatsPanel.vue';
import SegmentSuperPanel from './super-panel/SegmentSuperPanel.vue';
import SegmentEditor from './SegmentEditor.vue';
import EngageSegmentOverlay from './EngageSegmentOverlay.vue';
import VisitorTypeOptions from './VisitorTypeOptions.vue';

import { initializeHttpWrapper, http } from '@pendo/http';
import { initializeAggConnect } from '@pendo/agg-connect';
import { isEngageSegment } from '@/components/segment-builder/utils/utils';

export default {
    name: 'SegmentBuilder',
    components: {
        EngageSegmentOverlay,
        PendoButton,
        PendoCard,
        PendoDrawer,
        PendoInput,
        SegmentStatsPanel,
        SegmentSuperPanel,
        SegmentEditor,
        VisitorTypeOptions
    },
    provide () {
        return {
            appFirstVisit: this.config?.data?.appFirstVisit
        };
    },
    props: {
        editingSegment: {
            type: Object,
            default: null
        },
        config: {
            type: Object,
            required: true
        },
        isVisible: {
            type: Boolean,
            default: false
        },
        isSaving: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        showSuperPanel: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            operators: SEGMENT_OPERATORS,
            name: '',
            rules: [],
            segmentFlagValue: '',
            nestedSegmentCount: 0,
            invalidSegmentFlag: false,
            attemptedSubmit: false,
            showCsvUploadWarning: false,
            selectedVisitorType: this.config.canShowAnonymous ? null : 'visitorId'
        };
    },
    computed: {
        isEngageSegment () {
            return this.editingSegment?.id && isEngageSegment(this.editingSegment);
        },
        pipelineJson () {
            return JSON.stringify(this.pipeline, null, 2);
        },
        isEditing () {
            return this.editingSegment !== null;
        },
        title () {
            return this.editingSegment ? 'Edit Segment' : 'Create Segment';
        },
        showSaveButton () {
            return !this.readOnly && !this.isEngageSegment;
        },
        saveButtonLabel () {
            return this.editingSegment ? 'Save Segment' : 'Create Segment';
        },
        closeButtonLabel () {
            return this.readOnly || this.isEngageSegment ? 'Close' : 'Cancel';
        },
        builtSegment () {
            const segmentInfo = {
                rules: this.rules,
                name: this.name,
                flagName: this.segmentFlagValue,
                selectedVisitorType: this.selectedVisitorType
            };

            if (!this.config.usesMultiApp) {
                segmentInfo.appId = this.config.appId;
            }

            const segment = validateAndBuildSegment(segmentInfo);

            return segment;
        },
        pipeline () {
            if (!this.builtSegment) {
                return null;
            }

            const { compound, pipelines, pipeline } = this.builtSegment;

            if (pipelines) {
                return { compound, pipelines };
            }

            return pipeline;
        },
        invalidSegment () {
            return (
                !this.isPipelineValid ||
                (this.config.guideTargeting && this.usesLastVisitSchema) ||
                this.invalidSegmentFlag ||
                !this.name
            );
        },
        isPipelineValid () {
            return !!this.builtSegment && !!this.pipeline;
        },
        usesLastVisitSchema () {
            return this.rules.some((orRules) =>
                orRules.some((orRule) => {
                    return orRule.schema.type === 'metadata.auto.lastvisit';
                })
            );
        },
        createdBySomeoneElse () {
            const { createdByUser } = this.editingSegment;

            return createdByUser && this.config?.userId !== createdByUser.id;
        },
        segment () {
            if (this.editingSegment) {
                // Work with segment that's not tied to vuex store
                return cloneDeep(this.editingSegment);
            }

            return null;
        },
        disableAnonymousOption () {
            return this.rules.some((orRules) => orRules.some((rule) => get(rule, 'schema.type', '') === 'segment'));
        }
    },
    created () {
        initializeHttpWrapper(this.config.pendoHttpWrapperConfig);
        initializeAggConnect({
            http,
            env: window.appEnvId === 'prod' ? 'production' : window.appEnvId
        });
    },
    methods: {
        onUpdateRules (rules) {
            this.rules = rules;
            if (this.disableAnonymousOption && this.config.canShowAnonymous) {
                this.selectedVisitorType = null;
            }
        },
        initSegmentData () {
            if (this.editingSegment) {
                this.name = this.editingSegment.name;
                this.segmentFlagValue = this.editingSegment?.flagName;
                this.selectedVisitorType = getSegmentVisitorType(this.editingSegment);
            }
        },
        save () {
            this.attemptedSubmit = true;

            const segment = Object.assign({}, this.builtSegment);
            if (this.editingSegment) {
                segment.id = this.editingSegment.id;
                // when we build a segment we only create certain properties
                const initialSegment = cloneDeep(
                    pick(this.editingSegment, ['name', 'id', 'definition', 'pipeline', 'shared'])
                );
                const noChanges = isEqual(initialSegment, segment);

                if (noChanges) {
                    this.close();

                    return;
                }
            }

            this.$emit('save', { segment });
        },
        resetLocalState () {
            this.name = '';
            this.rules = [];
            this.segmentFlagValue = '';
            this.showCsvUploadWarning = false;
        },
        close () {
            this.$emit('close');
        },
        updateVisitorType (newType) {
            this.selectedVisitorType = newType;
        }
    }
};
</script>

<style lang="scss">
.segment-builder {
    .pendo-multiselect__placeholder {
        color: $gray-lighter-2;
    }

    .pendo-drawer__body {
        padding: 0px;
        grid-template-columns: minmax(275px, 350px) minmax(610px, 1fr);
        display: grid;
        grid-column-gap: 24px;
    }

    .settings-panel,
    .rules-panel {
        padding: 48px 0;
    }

    .settings-panel {
        padding-left: 32px;
        overflow-y: auto;

        .pendo-card__body {
            padding: 16px 0;
        }

        &__section {
            padding: 0 16px;

            .section-label {
                display: grid;
                grid-auto-flow: column;
                grid-gap: 8px;
                justify-content: start;
                font-weight: 600;
                min-height: 24px;
                line-height: 1rem;
            }
        }

        &--segment-name {
            margin-bottom: 1em;
        }

        &--divider {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            border-top: 1px solid $gray-lighter-5;
        }
    }

    .rules-panel {
        overflow-y: auto;
        padding-right: 32px;
    }

    &--footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &--close svg {
        stroke: $gray-lighter-2;
    }

    @media (max-width: 915px) {
        .pendo-drawer__body {
            padding: 0;
            grid-row-gap: 24px;
            grid-template-columns: 1fr;
            grid-template-rows: max-content;
            overflow-y: auto;
        }

        .settings-panel {
            padding-top: 48px;
            padding-bottom: 0px;
        }

        .rules-panel {
            padding-top: 0px;
            padding-bottom: 48px;
        }

        .rules-panel,
        .settings-panel {
            padding-left: 32px;
            padding-right: 32px;
            overflow-y: initial;
        }
    }
}
</style>
