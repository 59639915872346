import { BuildingBlock, BuildingBlockLayouts, StepCopy, Polls, GuideService } from '@pendo/services/BuildingBlocks';
import { updateCrossAppSteps } from '@pendo/services/CrossAppGuides';
import { EDITOR_TYPES } from '@pendo/services/Constants';
import { http } from '@pendo/http';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import moment from '@/utils/moment';

const pageAuto = 'page-auto';

export const LAUNCH_METHODS = Object.freeze({
    apiDA: {
        label: 'No Activation',
        text: "This guide has no activation method selected. It won't be displayed to your users"
    },
    apiReseller: {
        label: 'Launched via API',
        text:
            'This activation setting is the result of not selecting any other type. This guide can still be activated programmatically',
        mobileText:
            'This activation setting is the result of not selecting any other type. This guide can still be launched from another guide'
    },
    auto: {
        label: 'Automatic',
        text: 'once automatically, until dismissed or advanced'
    },
    appLaunch: {
        label: 'App Launch',
        text: 'when the app launches'
    },
    badge: {
        label: 'Badge',
        text: 'after a visitor clicks the badge icon'
    },
    confirmation: {
        label: 'Confirmation',
        text: 'when confirmation element is clicked'
    },
    embed: {
        label: 'Embedded On Page',
        text: 'automatically, embedded on the page.'
    },
    extensionIcon: {
        label: 'Pendo Launcher',
        text: 'when the Pendo Launcher icon is clicked'
    },
    dom: {
        label: 'Element Click',
        text: 'when target element is clicked'
    },
    feature: {
        label: 'Element Click',
        text: 'when target element is clicked'
    },
    launcher: {
        label: 'Resource Center',
        text: 'in the Resource Center'
    },
    page: {
        label: 'Page',
        text: 'when the page loads'
    },
    [pageAuto]: {
        label: 'Automatic',
        text: 'when a visitor views a targeted page'
    },
    track: {
        label: 'Track Event',
        text: 'when the track event occurs'
    }
});

export const MATCH_TYPES_TO_TEXT_MAP = {
    contains: 'contains',
    notContains: 'does not contain',
    equal: 'exactly matches',
    notEqual: 'does not match',
    greaterThan: 'is greater than',
    lessThan: 'is less than'
};

export function isBadgeGuide (guide) {
    return guide.launchMethod.includes('badge');
}

export function isWebAppGuide (guide) {
    return guide.app.platform === 'web';
}

export function isMobile (guide) {
    return guide.app.platform === 'mobile';
}

export function isMatchingPlatform (guide, platform) {
    return guide.app.platform === platform;
}

export function isConfirmationGuide (guide) {
    return isBadgeGuide(guide) && !!get(guide, 'attributes.badge.isConfirmation');
}

export function isEmbeddedGuide (guide) {
    return guide.launchMethod === 'embed';
}

export function isMobileTooltipGuide (buildingBlocks) {
    return (
        buildingBlocks &&
        BuildingBlock.findBlockByWidgetId(JSON.parse(buildingBlocks), BuildingBlockLayouts.widgetIds.tooltip)
    );
}

export function constructLaunchMethodString (launchMethod, automaticActivation) {
    let launchMethods = launchMethod.split('-');
    if (automaticActivation) {
        launchMethods.unshift('auto');
        launchMethods = launchMethods.filter((method) => method !== 'api');
    } else {
        launchMethods = launchMethods.filter((method) => method !== 'auto');
    }
    if (launchMethods.length === 0) launchMethods.push('api');

    return launchMethods.join('-');
}

export function getBuildingBlocks (step) {
    return Promise.all([
        http.get(step.buildingBlocksUrl, { withCredentials: false }).then((res) => res.data),
        http.get(step.domUrl, { withCredentials: false }).then((res) => res.data)
    ]).then(([buildingBlocks, dom]) => {
        return {
            buildingBlocks: JSON.stringify(buildingBlocks),
            dom: JSON.stringify(dom)
        };
    });
}

export async function getStepsWithBuildingBlocks ({ steps }) {
    const stepsContent = await Promise.all(steps.map((step) => getBuildingBlocks(step)));

    stepsContent.forEach((buildingBlocksAndDom, index) => {
        steps[index] = { ...steps[index], ...buildingBlocksAndDom };
    });

    return steps;
}

export function entityIdToNameMap (entities) {
    return entities.reduce((total, curr) => {
        total[curr.id] = curr.displayName || curr.name;

        return total;
    }, {});
}

export function addPropertyNamesToSteps (steps, featureNamesById, pagesNamesById) {
    return steps.map((step) => {
        const featureId = step?.featureId || step?.locationFeatureId;
        const featureName = featureNamesById[featureId];
        if (featureName) {
            step.featureName = featureName;
        }

        const pageName = pagesNamesById[step?.pageId];
        if (pageName) {
            step.pageName = pageName;
        }

        return step;
    });
}

export function getAllPollIds (guide) {
    return guide.steps
        .reduce((list, step) => {
            if (step.pollIds) list.push(step.pollIds);

            return list;
        }, [])
        .flat();
}

export function updateGuideAndStepPolls (guide) {
    const polls = guide.steps.reduce((acc, step) => {
        const stepBuildingBlocks = JSON.parse(step.buildingBlocks);
        if (!stepBuildingBlocks) return step;

        const pollObjects = Polls.generatePollsForBlocks(stepBuildingBlocks);
        if (pollObjects.length) {
            const pollIds = pollObjects.map((pollObject) => pollObject.id);
            step.pollIds = pollIds;
        }

        return acc.concat(pollObjects);
    }, []);

    if (polls.length) {
        guide.polls = polls;
    }
}

export function hasGuideWithZeroSteps ({ guides = [] }) {
    return !!guides.filter((guide) => !get(guide, 'steps.length', 0)).length;
}

export function isEditedByAdopt (guide) {
    return (
        guide.editorType === EDITOR_TYPES.ADOPT_UI ||
        guide.editorType === EDITOR_TYPES.ADOPT_STUDIO ||
        guide.editorType === ''
    );
}

export function formatGuideState (guide) {
    const isScheduledDateInFuture = moment(guide.showsAfter).isAfter(moment());
    if (guide.state === 'public' && isScheduledDateInFuture) return 'scheduled';

    return guide.state;
}

export function getGuideLink (guide, useQueryParam) {
    const suffixMap = {
        queryParam: {
            metrics: '?view=guide-metrics',
            settings: '?view=settings'
        },
        route: {
            metrics: '/metrics',
            settings: '/settings'
        }
    };

    const formattedGuideState = formatGuideState(guide);
    const view = formattedGuideState === 'public' ? 'metrics' : 'settings';
    const routeType = useQueryParam ? 'queryParam' : 'route';

    return `/guides/${guide.id}${suffixMap[routeType][view]}`;
}

/* Cross-App Guides */
export function isCrossAppGuideEligible (guide) {
    return isEditedByAdopt(guide) && !isBadgeGuide(guide) && !hasGuideWithZeroSteps({ guides: [guide] });
}

export async function updateGuideStatusesForCrossApp (rows, setGuide) {
    let updateError;
    const guidesToPatch = rows.filter(({ kind }) => kind === 'Guide');
    const guideStatusPromises = guidesToPatch.reduce((promises, remoteGuide) => {
        const { state } = remoteGuide;
        if (state === 'draft') return promises;
        const guide = {
            ...remoteGuide,
            state: 'draft'
        };
        const guideFieldsToPatch = ['state'];
        promises.push(GuideService.patchGuideFields(http, { guide, remoteGuide, guideFieldsToPatch }));

        return promises;
    }, []);
    const settledPromises = await Promise.allSettled(guideStatusPromises);

    settledPromises.forEach((result) => {
        if (result.status === 'rejected') {
            updateError = result.reason.message;

            return;
        }

        if (!setGuide) return;

        setGuide({ guide: result.value.data });
    });

    if (updateError) throw new Error(updateError);
}

export function prepareCrossAppGuideForSave (guide, rows, updatePolls) {
    guide.steps.forEach((step) => {
        step.buildingBlocks = JSON.stringify(step.buildingBlocks);
        step.dom = JSON.stringify(step.dom);
    });

    if (updatePolls) updateGuideAndStepPolls(guide);
    updateGuideAndStepAutomations(guide, rows);

    return guide;
}

export async function createCrossAppSteps (newGuideId, selectedGuides, urls, prevUrls) {
    const stepsPromise = selectedGuides.map(async (guide) => {
        const steps = await Promise.all(copyGuideSteps(guide, newGuideId));

        return steps;
    });

    const preppedSteps = await Promise.all(stepsPromise);

    return updateCrossAppSteps({ groups: preppedSteps, urls, prevUrls });
}

export function copyGuideSteps (guide, newGuideId) {
    return guide.steps.map((step) => copyStepForCrossApp(step, newGuideId, guide.appId));
}

export async function copyStepForCrossApp (step, newGuideId, appId) {
    if (!step.buildingBlocks) {
        const requestedBuildingBlocks = await http
            .get(step.buildingBlocksUrl, { withCredentials: false })
            .then((res) => res.data);

        step.buildingBlocks = requestedBuildingBlocks;
    }

    if (isString(step.buildingBlocks)) {
        const parsedBlocks = JSON.parse(step.buildingBlocks);
        step.buildingBlocks = parsedBlocks;
    }

    const makeId = async () => http.get('/api/s/_SID_/object/makeid').then((res) => res.data.id);
    const newStepId = await makeId();
    const containerId = get(BuildingBlock.findBlockByDomId(step.buildingBlocks, 'pendo-g-'), 'web.domId');
    const copied = StepCopy.copyStep(step, newStepId, containerId, {}, true);
    const { buildingBlocks, dom, automationIdMapping, otherProps } = copied;

    return {
        ...otherProps,
        automationIdMapping,
        id: newStepId,
        buildingBlocks,
        dom,
        guideId: newGuideId,
        theme: cloneDeep(step.theme),
        appIds: [appId]
    };
}

export async function createCrossAppGuide ({ guide, rows, urls }) {
    guide.isMultiApp = true;
    guide.steps = await createCrossAppSteps(guide.id, rows, urls, []);
    const updatePolls = rows.some((row) => row.polls);

    return prepareCrossAppGuideForSave(guide, rows, updatePolls);
}

export async function updateCrossAppGuide ({ guide, rows, urls, prevUrls }) {
    const initialPollIds = getAllPollIds(guide);
    const groups = await getNewStepGroups(rows, guide.id);
    guide.steps = updateCrossAppSteps({ groups, urls, prevUrls });
    const newPollIds = getAllPollIds(guide);
    const updatePolls = !isEqual(initialPollIds, newPollIds);

    return prepareCrossAppGuideForSave(guide, rows, updatePolls);
}

export async function getNewStepGroups (rows, crossAppGuideId) {
    const newSteps = [];
    for await (const row of rows) {
        if (row.kind !== 'Guide') {
            row.steps.forEach((step) => {
                step.buildingBlocks = JSON.parse(step.buildingBlocks);
            });
            newSteps.push(row.steps);
        } else {
            const guideStepsPromises = await Promise.all(copyGuideSteps(row, crossAppGuideId));
            const guideSteps = await Promise.all(guideStepsPromises);
            newSteps.push(guideSteps);
        }
    }

    return newSteps;
}

export function updateGuideAndStepAutomations (guide, rows) {
    const combinedAutomationIdMapping = guide.steps.reduce((mapping, step) => {
        const { automationIds, automationIdMapping } = step;
        if (automationIds && automationIdMapping) {
            step.automationIds = automationIds.map((oldAutomationId) => automationIdMapping[oldAutomationId]);
            Object.assign(mapping, automationIdMapping);
        }

        return mapping;
    }, {});
    if (!isEmpty(combinedAutomationIdMapping)) {
        const combinedAutomations = cloneDeep(guide.automations || []);
        rows.forEach(({ kind, automations }) => {
            if (kind === 'Guide' && automations) combinedAutomations.push(...automations);
        });
        combinedAutomations.forEach((automation) => {
            const mappedId = combinedAutomationIdMapping[automation.id];
            if (mappedId) automation.id = mappedId;
        });
        guide.automations = combinedAutomations;
    }
}

export async function getRulsetFullObjects (trackEventPropertyRule) {
    // take the data from the activation rule and return an object with the full info.
    const eventList = await fetchEventMetadata();

    const propertyObj = eventList?.data?.event[trackEventPropertyRule.Operand] || {};

    const operatorOptions = getOperatorOptions({ schema: propertyObj.Type }) || [];

    const operatorObj = operatorOptions.find((option) => {
        return option.operator === trackEventPropertyRule.Operator;
    });

    const value =
        propertyObj?.Type === 'string' && !operatorObj?.unary
            ? JSON.stringify(trackEventPropertyRule.Value)
            : trackEventPropertyRule.Value;

    return {
        property: propertyObj,
        operator: operatorObj,
        value
    };
}

export async function fetchEventMetadata () {
    return http.get('/api/s/_SID_/metadata/event/schema').then((res) => {
        if (get(res, 'data.event', null)) {
            res.data.event = parseEventMetadataUniqueValues(res.data.event);
        }

        return res;
    });
}

export function parseEventMetadataUniqueValues (eventMetadata) {
    return Object.entries(eventMetadata).reduce((acc, [key, value]) => {
        // each value in uniqueValues is JSON Stringified due to how it is stored in the BE
        acc[key] = {
            ...value,
            uniqueValues: get(value, 'uniqueValues', []).map((uniqueValue) => {
                try {
                    return JSON.parse(uniqueValue);
                } catch (error) {
                    return uniqueValue;
                }
            })
        };

        return acc;
    }, {});
}

export function getOperatorOptions ({ schema } = {}) {
    switch (schema) {
        case 'boolean':
            return [
                { operator: 'isTrue', name: 'is true', value: true, unary: true },
                { operator: 'isFalse', name: 'is false', value: false, unary: true }
            ];
        case 'string': {
            const options = [
                { operator: '==', name: 'is equal to' },
                { operator: '!=', name: 'is not equal to' },
                { operator: 'contains', name: 'contains' },
                { operator: '!contains', name: 'does not contain' },
                { operator: 'empty', name: 'is empty', unary: true },
                { operator: '!empty', name: 'is not empty', unary: true }
            ];

            return options;
        }
        case 'integer':
        case 'float':
        case 'number':
            return [
                { operator: '==', name: 'is equal to' },
                { operator: '!=', name: 'is not equal to' },
                { operator: '<=', name: 'less or equal to' },
                { operator: '>=', name: 'greater or equal to' },
                { operator: 'empty', name: 'is empty', unary: true },
                { operator: '!empty', name: 'is not empty', unary: true }
            ];
        case 'time':
            return [
                { operator: '>=', name: 'since' },
                { operator: '<=', name: 'before' },
                { operator: 'withinLast', name: 'within last' },
                { operator: '!withinLast', name: 'not within last' },
                { operator: 'withinNext', name: 'within next' },
                { operator: '!withinNext', name: 'not within next' },
                { operator: 'between', name: 'between' },
                { operator: 'empty', name: 'is empty', unary: true },
                { operator: '!empty', name: 'is not empty', unary: true }
            ];
        case 'list':
            return [
                { operator: 'contains', name: 'contains' },
                { operator: '!contains', name: 'does not contain' },
                { operator: 'empty', name: 'is empty', unary: true },
                { operator: '!empty', name: 'is not empty', unary: true }
            ];
    }

    return [];
}

export function getTrackEventPropertyRule (guide) {
    const activations = get(guide, 'steps[0].activations', []);
    const trackEventActivation = activations.find((activation) => {
        return activation.event === 'track';
    });

    return get(trackEventActivation, 'ruleset.Rules[0]', {});
}

export function getTrackEventPropertyMessage (rulsetFullObjects) {
    return `${rulsetFullObjects?.property?.DisplayName} event property ${rulsetFullObjects?.operator?.name} ${rulsetFullObjects.value}`;
}

/* End Cross-App Guides */
