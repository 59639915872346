<template>
    <div class="view--analytics">
        <analytics-header @open-launch-designer-modal="openLaunchDesignerModal" />
        <analytics-content @open-launch-designer-modal="openLaunchDesignerModal" />
        <launch-designer-modal
            :visible="showLaunchDesignerModal"
            v-bind="modalProps"
            @close="closeLaunchDesignerModal"
            @closed="resetProps" />
    </div>
</template>

<script>
import AnalyticsContent from '@/components/analytics/AnalyticsContent';
import AnalyticsHeader from '@/components/analytics/AnalyticsHeader';
import LaunchDesignerModal from '@/components/LaunchDesignerModal';

export default {
    name: 'AnalyticsView',
    components: {
        AnalyticsContent,
        AnalyticsHeader,
        LaunchDesignerModal
    },
    data () {
        return {
            showLaunchDesignerModal: false,
            modalProps: {}
        };
    },
    methods: {
        openLaunchDesignerModal (modalProps) {
            this.modalProps = modalProps;
            this.showLaunchDesignerModal = true;
        },
        closeLaunchDesignerModal () {
            this.showLaunchDesignerModal = false;
        },
        resetProps () {
            this.modalProps = {};
        }
    }
};
</script>
