<template>
    <page-header
        title="Guides"
        :show-banner="true">
        <template #actions>
            <pendo-button
                v-if="canManageTheme"
                theme="app"
                type="secondary"
                class="manage-theme"
                prefix-icon="droplet"
                label="Manage Theme"
                @click="launchThemeAppChooser" />
            <guide-create-split-button
                v-if="showGuideCreationSplitButton"
                data-cy="guide-list--create-guide-split"
                button-option-id="create_guide"
                :dropdown-options="guideCreationOptions"
                @select="selectGuideCreationOption" />
            <pendo-button
                v-if="showOptInVdsButton && !showGuideCreationSplitButton"
                theme="app"
                type="secondary"
                class="create-guide"
                data-cy="guide-list--create-vds-guide"
                prefix-icon="plus"
                label="Create VDS Guide"
                @click="internalOnlyCreateVdsGuide" />
            <pendo-button
                v-if="canCreateGuide && !showGuideCreationSplitButton"
                theme="app"
                type="primary"
                class="create-guide"
                data-cy="guide-list--create-guide"
                prefix-icon="plus"
                label="Create Guide"
                @click="handleCreateGuide" />
            <layout-chooser
                v-if="showChooser"
                @onChooseLayout="chooseLayout"
                @onClose="closeSelectionModal" />
            <guide-create
                :visible="showGuideCreate"
                :last-app-url-map="lastAppUrlMap"
                :is-pendo-user="isPendoUser"
                :app-options="appOptions"
                :launching="isSavingGuide"
                :guide-editor-name="guideEditorName"
                :show-a-i-option="showAIOption && !showGuideCreationSplitButton"
                :generated-steps="generatedSteps"
                :is-generating-steps="isGeneratingSteps"
                :is-regenerating-step="isRegeneratingStep"
                :theme="themeForGuideCreate"
                @generateSteps="generateSteps"
                @regenerateStep="regenerateStep"
                @updateSteps="setGeneratedSteps"
                @appSelected="setThemeForGuideCreate"
                @close="closeSelectionModal"
                @createGuide="handleSaveGuide" />
            <guide-theme-app-chooser
                :visible="showThemeAppChooser"
                @close="closeSelectionModal"
                @launchThemeEditor="launchThemeEditor({ app: $event })" />
            <guide-creation-drawer-with-a-i
                :apps="apps"
                :generated-steps="generatedSteps"
                :themes="appThemes"
                :is-regenerating-step="isRegeneratingStep"
                :is-generating-steps="isGeneratingSteps"
                :is-saving-guide="isSavingGuide"
                :visible="showGuideCreationWithAIDrawer"
                :app-alias="appAlias"
                :selected-app="appSelectedForGuideCreation || activeApp"
                :selected-theme="themeForGuideCreate"
                :url="customerAppUrl"
                :is-pendo-user="isPendoUser"
                :launch-guide-in-vds="showOptInVdsButton"
                @urlUpdated="updateCustomerAppUrl"
                @updateSteps="setGeneratedSteps"
                @generateSteps="generateSteps"
                @regenerateStep="regenerateStep"
                @appAliasUpdated="updateAppAlias"
                @appSelected="updateAppSelectedForGuideCreation"
                @themeSelected="updateThemeSelection"
                @close="closeSelectionModal"
                @createGuide="handleSaveGuide" />
        </template>
    </page-header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoButton, PendoNotification } from '@pendo/components';
import { http } from '@pendo/http';
import PageHeader from '@/components/PageHeader';
import LayoutChooser from '@/components/guides/layouts/LayoutChooser';
import GuideCreate from '@/stateless-components/guides/list/GuideCreate';
import GuideCreateSplitButton from '@/stateless-components/guides/list/GuideCreateSplit';
import GuideCreationDrawerWithAI from '@/stateless-components/guides/ai/GuideCreationDrawerWithAI';
import GuideThemeAppChooser from '@/components/guides/list/GuideThemeAppChooser';
import {
    openDesignerHere,
    writeTokenToLocalStorage,
    launchViaWindowOpener,
    DESIGNER_LAUNCH_TOKEN_LS_KEY
} from '@/stateless-components/utils/designer';
import { EDITOR_TYPES, AI } from '@pendo/services/Constants';
import { canCreateGuide, canCreateFromScratch } from '@/utils/guide-permissions';
import { sendPendoTrackEvent } from '@/utils/utils';
import { getDesignerAppName } from '@/utils/apps';
import { generateSteps, regenerateStepTextContent, prepStepsForPreview } from '@/stateless-components/utils/ai';
import get from 'lodash/get';

export default {
    name: 'GuideListHeader',
    components: {
        LayoutChooser,
        GuideCreate,
        PageHeader,
        PendoButton,
        GuideThemeAppChooser,
        GuideCreateSplitButton,
        GuideCreationDrawerWithAI
    },
    props: {
        showGuideCreate: {
            type: Boolean,
            default: false
        }
    },
    emits: ['showGuideCreateChange'],
    data () {
        return {
            customerAppUrl: '',
            showChooser: false,
            showThemeAppChooser: false,
            forceVds: false,
            themeForGuideCreate: null,
            isSavingGuide: false,
            isGeneratingSteps: false,
            isRegeneratingStep: false,
            showGuideCreationWithAIDrawer: false,
            generatedSteps: [],
            appAlias: '',
            appSelectedForGuideCreation: null
        };
    },
    computed: {
        ...mapState({
            guideId: (state) => state.guides.activeId,
            lastAppUrlMap: (state) => state.designer.lastAppUrlMap
        }),
        ...mapGetters({
            activeApp: 'apps/active',
            guide: 'guides/active',
            getThemeByAppId: 'themes/getThemeByAppId',
            themes: 'themes/list',
            hasSegmentFlag: 'auth/hasSegmentFlag',
            productName: 'subscriptions/productName',
            hasProductLineMetadataEnabled: 'subscriptions/hasProductLineMetadataEnabled',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag',
            activeUsesDADesigner: 'subscriptions/activeUsesDADesigner',
            activeIsAEU: 'subscriptions/activeIsTrainingSubscription',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            appWhiteLabelSettings: 'apps/whiteLabelSettings',
            apps: 'apps/listAllForActiveSubscription',
            isAdmin: 'auth/isAdmin',
            isPendoUser: 'auth/isPendoUser'
        }),
        guideCreationOptions () {
            const options = [];
            if (this.showOptInVdsButton) options.push({ id: 'create_vds_guide', label: 'Create VDS guide' });
            if (this.canCreateGuide) options.push({ id: 'create_guide', label: 'Create guide' });
            options.push({ id: 'create_guide_with_ai', label: 'Create guide with AI' });

            return options;
        },
        showOptInVdsButton () {
            return this.activeIsDigitalAdoption && this.hasSegmentFlag('adoptInternalOnlyVds') && this.canCreateGuide;
        },
        canManageTheme () {
            let canManageTheme = true;
            if (this.activeIsDigitalAdoption) {
                canManageTheme = this.isAdmin;
            }

            return canManageTheme;
        },
        canCreateGuide () {
            return canCreateGuide(this.apps);
        },
        appOptions () {
            return this.apps.filter(({ id }) => canCreateFromScratch(id));
        },
        showAIOption () {
            return this.activeSubHasFlag('enableGenerateAIGuideContent');
        },
        guideEditorName () {
            return getDesignerAppName(this.activeUsesDADesigner, 'guide');
        },
        showGuideCreationSplitButton () {
            if (!this.showAIOption) return false;
            if (this.activeIsAEU) return false;

            return this.hasProductLineMetadataEnabled('guides', 'aIGuideContentEnabled');
        },
        appThemes () {
            const selectedApp = this.appSelectedForGuideCreation || this.activeApp;

            return this.themes.filter(({ appId, archive }) => appId === selectedApp.id && !archive);
        }
    },
    async beforeMount () {
        await this.fetchAllThemes({ noCache: true });
        if (this.showGuideCreationSplitButton && this.appThemes.length > 0) {
            this.themeForGuideCreate = this.appThemes[0];
        }
    },
    methods: {
        ...mapActions({
            fetchThemes: 'themes/fetch',
            fetchAllThemes: 'themes/fetchAll',
            createGuide: 'guides/create',
            updateGuide: 'guides/update',
            refreshGuide: 'guides/refreshGuide',
            updateDesignerUrl: 'designer/updateDesignerUrl'
        }),
        updateAppAlias (alias) {
            this.appAlias = alias;
        },
        updateAppSelectedForGuideCreation (app) {
            this.appSelectedForGuideCreation = app;
            this.customerAppUrl = get(this, `lastAppUrlMap[${this.appSelectedForGuideCreation?.id}]`, '');
            this.generatedSteps = [];
        },
        updateCustomerAppUrl (url) {
            this.customerAppUrl = url;
        },
        selectGuideCreationOption (option) {
            switch (option.id) {
                case 'create_vds_guide':
                    this.internalOnlyCreateVdsGuide();
                    break;
                case 'create_guide':
                    this.handleCreateGuide();
                    break;
                case 'create_guide_with_ai':
                    this.showGuideCreationWithAIDrawer = true;
                    this.updateAppSelectedForGuideCreation(this.activeApp);
                    break;
            }
        },
        async chooseLayout (layout, appId, isAppFlutterCodeless) {
            const themeId = this.getThemeByAppId(appId)?.id;
            const editorType = this.forceVds ? EDITOR_TYPES.VDS : EDITOR_TYPES.ADOPT_UI;

            await this.createGuide({ layout, appId, editorType, isAppFlutterCodeless, themeId });
            // createGuide runs migrations for steps created from layouts. refreshGuide
            // is called to make sure the guide is updated and has the latest buldingBlocks and dom content
            await this.refreshGuide({ id: this.guideId });
            this.$router.push(`/guides/${this.guideId}/settings`).catch(() => {});
        },
        launchThemeAppChooser () {
            this.showThemeAppChooser = true;
        },
        async setThemeForGuideCreate (app) {
            this.themeForGuideCreate = this.getThemeByAppId(app.id);
        },
        closeSelectionModal () {
            this.forceVds = false;
            this.showChooser = false;
            this.$emit('showGuideCreateChange', false);
            this.showThemeAppChooser = false;
            this.showGuideCreationWithAIDrawer = false;
        },
        launchThemeEditor ({ app } = {}) {
            const appToEdit = app || this.activeApp;

            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            openDesignerHere(
                {
                    adoptv2: true,
                    type: 'watermark',
                    idForApp: get(appToEdit, 'id'),
                    disableFetchAgentAPIKey: true
                },
                window.location,
                undefined,
                whiteLabelToken
            );

            this.showThemeAppChooser = false;
        },
        internalOnlyCreateVdsGuide () {
            this.forceVds = true;
            this.handleCreateGuide();
        },
        handleCreateGuide () {
            if (this.activeUsesDADesigner && !this.forceVds) {
                this.$emit('showGuideCreateChange', true);
            } else {
                this.showChooser = true;
            }
        },
        async generateSteps (request) {
            request.http = http;
            request.appName = this.appAlias || request.appName;
            this.isGeneratingSteps = true;
            try {
                const generatedSteps = await generateSteps(request);
                this.setGeneratedSteps(generatedSteps);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                PendoNotification({
                    type: 'error',
                    title: 'Failed to generate guide steps. Please try again.'
                });
            }
            this.isGeneratingSteps = false;
        },
        async regenerateStep ({ index, tone, appId }) {
            this.isRegeneratingStep = true;
            try {
                const regeneratedStepTextContent = await regenerateStepTextContent({
                    http,
                    appId,
                    stepTextContent: this.generatedSteps[index].stepTextContent,
                    assistantType: AI.ASSISTANT_TYPES[tone.id]
                });
                this.generatedSteps[index].stepTextContent = regeneratedStepTextContent;
                this.setGeneratedSteps(this.generatedSteps);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                PendoNotification({
                    type: 'error',
                    title: 'Failed to regenerate guide step. Please try again.'
                });
            }
            this.isRegeneratingStep = false;
        },
        setGeneratedSteps (steps = []) {
            this.generatedSteps = prepStepsForPreview(steps, this.themeForGuideCreate);
        },
        async handleSaveGuide ({ layout, appId, token, url, editorType = EDITOR_TYPES.ADOPT_STUDIO, trackEventProps }) {
            const shouldLaunchBuilder = token && url;
            this.isSavingGuide = true;

            try {
                await this.createGuide({
                    layout,
                    appId,
                    themeId: this.themeForGuideCreate?.id,
                    editorType
                });

                if (trackEventProps) {
                    trackEventProps.time = Date.now();
                    trackEventProps.guideId = this.guideId;
                    sendPendoTrackEvent({
                        trackEventKey: 'aiGuideCreated',
                        properties: trackEventProps,
                        adoptSpecific: false
                    });
                }

                if (shouldLaunchBuilder) {
                    token.guideId = this.guideId;
                    token.stepId = get(this.guide, 'steps[0].id');
                    this.launchGuideBuilder(token, url, appId);
                }

                this.$router.push(`/guides/${this.guideId}/settings`).catch(() => {});
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                PendoNotification({
                    type: 'error',
                    title: `Failed to create guide${
                        shouldLaunchBuilder ? ` and launch ${this.guideEditorName}` : ''
                    }. Please try again.`
                });
            }
            this.isSavingGuide = false;
        },
        launchGuideBuilder (token, url, appId) {
            this.updateDesignerUrl({ url, appId });
            writeTokenToLocalStorage(DESIGNER_LAUNCH_TOKEN_LS_KEY, JSON.stringify(token));
            launchViaWindowOpener(url, token);
        },
        updateThemeSelection (theme) {
            this.themeForGuideCreate = theme;
            this.generatedSteps = [];
        }
    }
};
</script>
