<template>
    <pendo-tag
        :class="{ 'visitors-no-events-filter': true, 'is-active': value }"
        type="filter"
        @click="toggleFilter">
        Hide Visitors with No Events
    </pendo-tag>
</template>

<script>
import { PendoTag } from '@pendo/components';

export default {
    name: 'VisitorsNoEventsFilter',
    components: {
        PendoTag
    },
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    emits: ['change'],
    methods: {
        toggleFilter () {
            this.$emit('change', !this.value);
        }
    }
};
</script>

<style scoped lang="scss">
.visitors-no-events-filter {
    &:hover {
        border: solid 1px $gray-lighter-1;
        cursor: pointer;
    }

    &.is-active {
        background-color: $teal-primary;
        border-color: $teal-primary;
        color: $white;
    }
}
</style>
