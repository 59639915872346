<template>
    <div>
        <span>
            {{
                isAppDisabled
                    ? 'The application is disabled.  This guide will become visible to all segmented users once the application is enabled.'
                    : 'This guide will become visible to all segmented users upon changing status to Public.'
            }}
        </span>
        <p v-if="activationType">
            <b>Activation Type:</b> {{ activationType }}
        </p>
        <p v-if="showSegmentInfo">
            <b>Segment:</b> {{ segmentName }}
            <b>|</b>
            {{ visitorCount }} Eligible Visitors
        </p>
    </div>
</template>
<script>
export default {
    name: 'PublicStatusModalBody',
    props: {
        isAppDisabled: {
            type: Boolean,
            default: false
        },
        activationType: {
            type: String,
            default: ''
        },
        showSegmentInfo: {
            type: Boolean,
            default: false
        },
        segmentName: {
            type: String,
            default: ''
        },
        visitorCount: {
            type: Number,
            default: 0
        }
    }
};
</script>
