<template>
    <div>
        <multiselect-blade
            :visible="showBlade"
            :workflow-steps="[startNode, endNode]"
            :options="filteredApps"
            :apply-disabled="applyDisabled"
            size="large"
            title="Selected Pages and Features"
            :value="filteredSelectedOptions"
            @input="handleInput"
            @apply="handleApply"
            @changeApplyDisabled="handleApplyDisabled"
            @close="onCancel">
            <template #filters>
                <journeys-app-filter
                    :app-ids="appIds"
                    :app-options="appOptions"
                    @change="setAppIds" />
                <event-type-filter @select="handleEventFilterSelection" />
            </template>
            <template #notification>
                <pendo-alert
                    v-if="showJourneyGuidance"
                    class="alert"
                    title="Select the Pages & Features you expect visitors to take to complete the Workflow."
                    :center="false"
                    closable
                    inline
                    @close="updateUserSettings" />
            </template>
        </multiselect-blade>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import JourneysAppFilter from '@/stateless-components/workflows/journeys/SidePanel/JourneysAppFilter';
import MultiselectBlade from './multiselect-blade/multiselect-blade';
import { PendoAlert } from '@pendo/components';
import EventTypeFilter from '@/stateless-components/workflows/journeys/SidePanel/EventTypeFilter';
import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

export default {
    name: 'SelectedPagesFeaturesSidePanel',
    components: {
        JourneysAppFilter,
        MultiselectBlade,
        EventTypeFilter,
        PendoAlert
    },
    props: {
        savedWorkflow: {
            type: Object,
            default: () => ({})
        },
        showBlade: {
            type: Boolean,
            default: false
        }
    },
    emits: ['workflowUpdated', 'hideBlade'],
    data () {
        return {
            searchTerm: '',
            appIds: [],
            eventTypesFilter: ['Page', 'Feature'],
            initialWorflowState: {},
            applyDisabled: true,
            tempWorkflow: {}
        };
    },
    computed: {
        ...mapGetters({
            pages: 'pages/list',
            features: 'features/list',
            getAppById: 'apps/appById',
            getUserSetting: 'userSettings/getUserSetting',
            allAppsForCurrentSub: 'apps/listAllForActiveSubscription'
        }),
        // Pass down pages/features are not versioned and do not have a 'kind' field
        pagesWithKindField () {
            return this.pages.map((page) => ({ ...page, kind: 'Page' }));
        },
        // Pass down pages/features are not versioned and do not have a 'kind' field
        featuresWithKindField () {
            return this.features.map((feature) => ({ ...feature, kind: 'Feature' }));
        },
        selectedOptions () {
            const selectedOptions = {};
            this.validatedPageIds.forEach((pageId) => {
                selectedOptions[pageId] = this.pagesWithKindField.find((page) => page.id === pageId);
            });

            this.validatedFeatureIds.forEach((featureId) => {
                selectedOptions[featureId] = this.featuresWithKindField.find((feature) => feature.id === featureId);
            });

            return selectedOptions;
        },
        filteredSelectedOptions () {
            const filtered = {};
            const options = this.selectedOptions;

            for (const eventId in options) {
                if (eventId) {
                    const { appId } = options[eventId];
                    if (this.appIds.includes(appId)) {
                        filtered[eventId] = options[eventId];
                    }
                }
            }

            return filtered;
        },
        filteredApps () {
            return this.appIds.map((appId) => {
                const pages = this.eventTypesFilter.includes('Page')
                    ? this.pagesWithKindField.filter((page) => page.appId === appId)
                    : [];

                const features = this.eventTypesFilter.includes('Feature')
                    ? this.featuresWithKindField.filter((feature) => feature.appId === appId)
                    : [];

                const workflowStepIds = [this.firstStep.countableId, this.lastStep.countableId];
                const nonWorklowPages = pages.filter((page) => !workflowStepIds.includes(page.id));
                const nonWorkflowFeatures = features.filter((feature) => !workflowStepIds.includes(feature.id));

                const app = this.getAppById(appId);

                return {
                    ...app,
                    id: appId,
                    section: app.displayName,
                    targets: [...nonWorklowPages, ...nonWorkflowFeatures],
                    type: 'group'
                };
            });
        },
        hasSteps () {
            return !!this.firstStep && !!this.lastStep;
        },
        firstStep () {
            return this.savedWorkflow.workflowSteps.find((step) => step.type === 'start');
        },
        lastStep () {
            return this.savedWorkflow.workflowSteps.find((step) => step.type === 'end');
        },
        startNode () {
            if (this.firstStep.countableKind === 'Page') {
                return this.pagesWithKindField.find((page) => page.id === this.firstStep.countableId);
            }

            return this.featuresWithKindField.find((feature) => feature.id === this.firstStep.countableId);
        },

        endNode () {
            if (this.lastStep.countableKind === 'Page') {
                return this.pagesWithKindField.find((page) => page.id === this.lastStep.countableId);
            }

            return this.featuresWithKindField.find((feature) => feature.id === this.lastStep.countableId);
        },
        savedWorkflowApps () {
            if (!this.hasSteps) {
                return [];
            }

            return uniq([this.startNode.appId, this.endNode.appId]);
        },
        savedJourneyApps () {
            const { journey } = this.tempWorkflow;

            return this.getAppIdsFromJourneyPagesFeatures(journey);
        },
        showJourneyGuidance () {
            return JSON.parse(get(this.getUserSetting('showJourneyGuidance'), 'value', true));
        },
        validatedPageIds () {
            const workflow = cloneDeep(this.tempWorkflow);
            const { pageIds = [] } = workflow.journey;

            return pageIds.filter((pageId) => this.pages.find((page) => page.id === pageId));
        },
        validatedFeatureIds () {
            const workflow = cloneDeep(this.tempWorkflow);
            const { featureIds = [] } = workflow.journey;

            return featureIds.filter((featureId) => this.features.find((feature) => feature.id === featureId));
        },
        appOptions () {
            return this.allAppsForCurrentSub.map((app) => {
                return {
                    ...app,
                    id: app.id,
                    displayName: app.displayName
                };
            });
        }
    },
    watch: {
        savedWorkflow: {
            handler () {
                this.tempWorkflow = cloneDeep(this.savedWorkflow);
            },
            immediate: true
        }
    },
    created () {
        if (this.savedJourneyApps.length && this.savedJourneyApps.length >= this.savedWorkflowApps.length) {
            this.appIds = this.savedJourneyApps;
        } else {
            this.appIds = this.savedWorkflowApps;
        }
    },
    methods: {
        ...mapActions({
            updateWorkflow: 'workflows/update',
            updateUserSetting: 'userSettings/updateUserSetting'
        }),
        getAppIdsFromJourneyPagesFeatures () {
            const journeyApps = [];

            this.validatedPageIds.forEach((pageId) => {
                const { appId = '' } = this.pagesWithKindField.find((page) => page.id === pageId);
                journeyApps.push(appId);
            });
            this.validatedFeatureIds.forEach((featureId) => {
                const { appId = '' } = this.featuresWithKindField.find((feature) => feature.id === featureId);
                journeyApps.push(appId);
            });

            return uniq(journeyApps);
        },
        async handleApply () {
            const workflow = cloneDeep(this.tempWorkflow);
            const options = Object.values(this.filteredSelectedOptions);
            const pageIds = options.filter((opt) => opt.kind === 'Page').map((opt) => opt.id);
            const featureIds = options.filter((opt) => opt.kind === 'Feature').map((opt) => opt.id);

            workflow.journey = { pageIds, featureIds };
            this.$emit('workflowUpdated', workflow);

            this.$emit('hideBlade');

            await this.updateWorkflow({ workflow, applyToAggregation: false });
        },
        setAppIds (appIds) {
            this.appIds = appIds;
            this.applyDisabled = false;
        },
        handleInput (items) {
            const selection = Object.values(items);
            this.tempWorkflow.journey = {
                pageIds: selection.filter((opt) => opt.kind === 'Page').map((opt) => opt.id),
                featureIds: selection.filter((opt) => opt.kind === 'Feature').map((opt) => opt.id)
            };
        },
        onCancel () {
            this.setAppIds(this.getAppIdsFromJourneyPagesFeatures(this.savedWorkflow.journey));
            this.tempWorkflow = this.savedWorkflow;
            this.$emit('hideBlade');
        },
        handleEventFilterSelection (eventTypes) {
            this.eventTypesFilter = eventTypes.map((ev) => ev.kind);
        },
        handleApplyDisabled (bool) {
            this.applyDisabled = bool;
        },
        updateUserSettings () {
            this.updateUserSetting({
                namespaceKey: 'showJourneyGuidance',
                value: false
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.alert {
    margin-top: 15px;
}
</style>
