<template>
    <div class="journey-container">
        <component
            :is="hasHeaderInsight(currentInsight) ? 'insight-popover' : 'div'"
            :insight="currentInsight"
            :avg-times="avgTimes"
            :meta="insightMeta"
            :journey-list="steps"
            :insights-showing="insightsShowing"
            placement="top"
            @prev="prevInsight"
            @next="nextInsight"
            @hideInsights="hideInsights">
            <journey-header
                :class="{
                    highlighted: hasHeaderInsight(currentInsight)
                }"
                :num-of-attempts="numOfAttempts"
                :abbreviated-time-taken="abbreviatedTime(avgTimeTaken)"
                :step-count="stepCount"
                :heading="heading"
                :total-num-of-attempts="totalNumOfAttempts"
                :journey-num="journeyNum" />
        </component>
        <div
            v-for="(step, idx) in steps"
            :key="idx"
            :class="{
                'journey-step': true,
                'highlighted': shouldBeHighlighted(step),
                'sequence-start': sequenceStartingIds.includes(step.nodeId),
                'sequence-end-duplicates': hasDuplicateTags(step),
                'fade': shouldFade(step) && insightsShowing,
                'terminal-node': idx === 0 || idx === stepCount - 1
            }">
            <div
                v-if="shouldBeHighlighted(step) && showGrouping"
                :style="{ borderColor: highlightGroupingColor(step) }"
                class="highlight-grouping" />
            <div v-if="(!displayLoadMore(idx) && !displayLoadMore(idx - 1)) || showExpandedView">
                <div
                    v-if="step.avgTimeSinceLastStep !== null"
                    class="time-between">
                    <pendo-divider
                        class="connecting-line"
                        :stroke-width="1"
                        height="36px"
                        :stroke="connectingLineColor(step)"
                        direction="vertical" />
                    <p class="timestamp">
                        {{ abbreviatedTime(step.avgTimeSinceLastStep) }}
                    </p>
                </div>
            </div>
            <div v-if="displayLoadMore(idx) && !showExpandedView && idx === 11">
                <pendo-divider
                    class="show-more-line"
                    stroke-dasharray="4"
                    :stroke-width="1"
                    height="36px"
                    stroke="#DADCE5"
                    direction="vertical" />
                <pendo-button
                    type="secondary"
                    :label="loadMoreMessage"
                    @click="showMore = true" />
                <pendo-divider
                    class="show-more-line"
                    stroke-dasharray="4"
                    :stroke-width="1"
                    height="36px"
                    stroke="#DADCE5"
                    direction="vertical" />
            </div>
            <component
                :is="hasInsight(step) ? 'insight-popover' : 'div'"
                :insight="currentInsight"
                :steps="insightSteps"
                :journey-list="steps"
                :meta="insightMeta"
                :insights-showing="insightsShowing"
                @prev="prevInsight"
                @next="nextInsight"
                @hideInsights="hideInsights">
                <journey-node
                    v-if="!displayLoadMore(idx) || showExpandedView"
                    :highlight="shouldBeHighlighted(step)"
                    :fade="shouldFade(step) && insightsShowing"
                    :node-id="step.nodeId"
                    :app-name="step.appName"
                    :node-name="step.name"
                    :favicon="step.favicon"
                    :color="step.color"
                    :platform="step.platform"
                    :step-count="idx"
                    :is-final-node="idx === stepCount - 1"
                    :is-starred="isStarred(step)"
                    :is-page="step.pageId !== undefined"
                    :show-default-icon="showDefaultIcon"
                    :duplicate-tags="step.duplicateTags ? step.duplicateTags : null"
                    :is-engage="isEngage"
                    @includeStep="toggleStep(step)" />
            </component>
        </div>
    </div>
</template>
<script>
import JourneyNode from '@/stateless-components/workflows/journeys/JourneyNode';
import JourneyHeader from '@/stateless-components/workflows/journeys/JourneyHeader';
import { PendoDivider, PendoButton } from '@pendo/components';
import InsightPopover from '@/stateless-components/workflows/journeys/insights/InsightPopover';
import { accessibleColorMap } from '@/utils/colors';
import get from 'lodash/get';
import { INSIGHT_TYPES, JOURNEY_TYPES } from '@/stateless-components/workflows/journeys/utils/constants';
import { pluralize } from '@/utils/formatters';

export default {
    name: 'JourneyList',
    components: {
        JourneyNode,
        PendoButton,
        PendoDivider,
        JourneyHeader,
        InsightPopover
    },
    props: {
        heading: {
            type: String,
            default: ''
        },
        steps: {
            type: Array,
            default: () => []
        },
        numOfAttempts: {
            type: Number,
            default: 0
        },
        avgTimeTaken: {
            type: Number,
            default: 0
        },
        stepCount: {
            type: Number,
            default: 0
        },
        totalNumOfAttempts: {
            type: Number,
            default: 0
        },
        journeyNum: {
            type: Number,
            default: 0
        },
        currentInsight: {
            type: Object,
            default: () => ({})
        },
        avgTimes: {
            type: Object,
            default: () => ({})
        },
        insightMeta: {
            type: Object,
            default: () => ({})
        },
        includedSteps: {
            type: Object,
            default: () => ({})
        },
        insightsShowing: {
            type: Boolean,
            default: false
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        isEngage: {
            type: Boolean,
            default: false
        }
    },
    emits: ['resetInsights', 'prevInsight', 'nextInsight', 'toggleIncludedStep', 'hideInsights'],
    data () {
        return {
            showMore: false
        };
    },
    computed: {
        insightSteps () {
            if (!this.currentInsight.nodes) return [];
            const { nodes } = this.currentInsight;
            const { REPEATED_SEQUENCES, REPEATED_STEPS } = INSIGHT_TYPES;

            if (nodes && [REPEATED_SEQUENCES, REPEATED_STEPS].includes(this.currentInsight.key)) {
                return nodes.flat();
            }

            return nodes;
        },
        loadMoreMessage () {
            const additionalStepCount = this.stepCount - 12;
            if (additionalStepCount === 1) return 'Load Next Step';

            return `Load Next ${additionalStepCount} Steps`;
        },
        formattedAvgTimeCompleted () {
            const timeArr = this.convertMsToTimeArr(this.avgTimeTaken).filter(Boolean);

            return [timeArr[0], timeArr[1] || null];
        },
        sequenceStartingIds () {
            if (!this.currentInsight.nodes) return [];

            const { nodes } = this.currentInsight;
            const { REPEATED_SEQUENCES, REPEATED_STEPS } = INSIGHT_TYPES;

            if (nodes && [REPEATED_SEQUENCES, REPEATED_STEPS].includes(this.currentInsight.key)) {
                return nodes.map((nodeSet) => nodeSet[0].nodeId);
            }

            return [nodes[0].nodeId];
        },
        sequenceEndingIds () {
            if (!this.currentInsight.nodes) return [];

            const { nodes } = this.currentInsight;
            const { REPEATED_SEQUENCES } = INSIGHT_TYPES;

            if (this.currentInsight.key === REPEATED_SEQUENCES) {
                const sequenceLength = nodes[0].length - 1;

                return nodes.map((nodeSet) => nodeSet[sequenceLength].nodeId);
            }

            return [nodes[nodes.length - 1].nodeId];
        },
        applyInsight () {
            const columnHeading = get(this, 'heading');
            const insightHeading = get(this, 'currentInsight.heading');

            return columnHeading.includes(insightHeading);
        },
        showGrouping () {
            const insightHeading = get(this, 'currentInsight.key');
            const groupedInsights = [INSIGHT_TYPES.REPEATED_SEQUENCES];

            return groupedInsights.includes(insightHeading);
        },
        showExpandedView () {
            return this.insightsShowing || this.showMore;
        }
    },
    watch: {
        insightsShowing (showing) {
            if (showing) {
                this.scrollHighlightedNodeOrHeaderIntoView();
            } else {
                this.$emit('resetInsights');
            }
        }
    },
    methods: {
        prevInsight () {
            this.$emit('prevInsight');
            this.scrollHighlightedNodeOrHeaderIntoView();
        },
        nextInsight () {
            this.$emit('nextInsight');
            this.scrollHighlightedNodeOrHeaderIntoView();
        },
        async scrollHighlightedNodeOrHeaderIntoView () {
            await this.$nextTick();
            const highlightedNode = document.querySelector('.journey-node.highlighted, .journey-header.highlighted');
            const nodeHeight = highlightedNode.getBoundingClientRect().height;

            const viewableRegion = window.innerHeight;

            const pageCenter = viewableRegion / 2;

            const scrollLimits = {
                top: pageCenter - nodeHeight / 2,
                bottom: pageCenter + nodeHeight / 2
            };

            highlightedNode.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            let nodeTop = highlightedNode.getBoundingClientRect().top;

            const page = document.querySelector('.pendo-page-container') || document.body;

            if (nodeTop < 0) {
                page.scrollBy({ top: -nodeTop, behavior: 'smooth' });
                nodeTop = highlightedNode.getBoundingClientRect().top;
            }

            if (nodeTop < scrollLimits.top) {
                page.scrollBy({ top: -(scrollLimits.top - nodeTop), behavior: 'smooth' });
            }

            const nodeBottom = highlightedNode.getBoundingClientRect().bottom;

            if (nodeBottom > scrollLimits.bottom) {
                page.scrollBy({ top: nodeBottom - scrollLimits.top, behavior: 'smooth' });
            }
        },
        highlightGroupingColor (step) {
            return accessibleColorMap(step.color).dark;
        },
        connectingLineColor (step) {
            const isStartNode = this.sequenceStartingIds.includes(step.nodeId);
            if (!this.applyInsight || isStartNode) return '#DADCE5';

            const applyColor = this.insightSteps.find((node) => {
                return node.nodeId === step.nodeId;
            });

            return applyColor && this.insightsShowing ? accessibleColorMap(step.color).dark : '#DADCE5';
        },
        shouldBeHighlighted (step) {
            if (!this.applyInsight) return false;

            return this.insightsShowing && this.insightSteps.findIndex((node) => node.nodeId === step.nodeId) > -1;
        },
        hasInsight (step) {
            if (!this.applyInsight || !this.insightSteps.length) return false;

            return this.insightSteps[0].nodeId === step.nodeId;
        },
        hasHeaderInsight (insight) {
            return insight.applyToHeader && this.heading.includes(insight.heading);
        },
        shouldFade (step) {
            if (this.currentInsight.heading === JOURNEY_TYPES.QUICKEST) return false;
            if (!this.applyInsight) return true;

            return this.insightSteps.findIndex((node) => node.nodeId === step.nodeId) === -1;
        },
        displayLoadMore (idx) {
            return idx > 10 && idx !== this.stepCount - 1;
        },
        abbreviatedTime (timeBetweenMs) {
            const timeArr = this.convertMsToTimeArr(timeBetweenMs).filter(Boolean);
            if (!timeArr.length) return '<1s';

            return [timeArr[0], timeArr[1] || null]
                .reduce((prevVal, curVal) => {
                    const lowVal = curVal ? curVal.toLowerCase() : '';

                    if (curVal === '< 1 Second') return '<1s';

                    return `${prevVal}${lowVal.substring(0, lowVal.search(/[dhms]/) + 1).replace(' ', '')} `;
                }, '')
                .trim();
        },
        convertMsToTimeArr (timeMs) {
            const days = Math.floor(timeMs / (24 * 60 * 60 * 1000));
            const daysms = timeMs % (24 * 60 * 60 * 1000);
            const dayUnits = pluralize('Day', days);

            const hours = Math.floor(daysms / (60 * 60 * 1000));
            const hoursms = timeMs % (60 * 60 * 1000);
            const hourUnits = pluralize('Hour', hours);

            const minutes = Math.floor(hoursms / (60 * 1000));
            const minutesms = timeMs % (60 * 1000);
            const minuteUnits = pluralize('Minute', minutes);

            const seconds = Math.floor(minutesms / 1000);
            const displaySeconds = seconds < 1 ? '< 1' : seconds;
            const secondUnits = seconds < 1 ? 'Second' : pluralize('Second', seconds);

            return [
                days ? `${days} ${dayUnits}` : null,
                hours ? `${hours} ${hourUnits}` : null,
                minutes ? `${minutes} ${minuteUnits}` : null,
                displaySeconds ? `${displaySeconds} ${secondUnits}` : null
            ];
        },
        toggleStep (step) {
            this.$emit('toggleIncludedStep', step);
        },
        isStarred (step) {
            if (this.includedSteps[step.stepId]) return true;

            if (step.duplicateTags) {
                return step.duplicateTags.some((tag) => this.includedSteps[tag.stepId]);
            }

            return false;
        },
        hasDuplicateTags (step) {
            return step.duplicateTags && this.sequenceEndingIds.includes(step.nodeId);
        },
        hideInsights () {
            this.$emit('hideInsights');
        }
    }
};
</script>
<style lang="scss" scoped>
.journey-container {
    max-width: 280px;
}

.journey-step {
    position: relative;

    &.fade {
        filter: opacity(0.5);
        pointer-events: none;
    }
}

.highlight-grouping {
    position: absolute;
    left: -10px;
    border-style: solid;
    border-width: 2px;
}

.highlighted {
    // First `.highlighted` element
    .time-between {
        filter: opacity(0.5);
    }

    .highlight-grouping {
        top: 35px;
        bottom: 0;
        border-radius: 10px 10px 0 0;
    }

    &.terminal-node {
        .highlight-grouping {
            top: 0;
        }
    }
}

.highlighted ~ .highlighted {
    // All `.highlighted` siblings following the first `.highlighted` element
    &.sequence-start {
        .highlight-grouping {
            top: 35px;
            bottom: 0;
            border-radius: 10px 10px 0 0;
        }

        .time-between {
            filter: opacity(0.5);
        }
    }

    .time-between {
        filter: none;
    }

    .highlight-grouping {
        top: 0;
        bottom: 0;
        border-radius: 0 0 10px 10px;
    }

    &.sequence-end-duplicates {
        .highlight-grouping {
            bottom: 10px;
        }
    }
}

.time-between {
    display: flex;

    .connecting-line {
        margin-left: 13px;
        margin-right: 16px;
        max-width: 10px;
    }

    .timestamp {
        margin-top: 10px;
        font-size: 12px;
        color: $color-gray-100;
    }
}

.show-more-line {
    max-width: 36px;
}
</style>
