<!--
    Note: This component was directly copied from Designer.
    We can deprecate this in both repos (Designer/Via) when we have a Pendo component to replace it.
-->
<template>
    <el-upload
        ref="uploadWrapper"
        v-pendo-loading="loadingProps"
        :class="{
            'is-showing-preview': internalFileList.length,
            'is-showing-error': showError,
            'is-replace': removeType === 'replace',
            'is-remove': removeType === 'remove'
        }"
        :action="action"
        :headers="headers"
        :multiple="multiple"
        :data="data"
        :name="name"
        :with-credentials="withCredentials"
        :show-file-list="showFileList"
        :drag="drag"
        :accept="accept"
        :on-exceed="handleExceed"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-progress="handleProgress"
        :on-change="handleChange"
        :before-upload="handleBeforeUpload"
        :before-remove="handleBeforeRemove"
        :file-list="internalFileList"
        :list-type="listType"
        :auto-upload="autoUpload"
        :http-request="httpRequest"
        :disabled="disabled"
        :limit="maxFiles"
        class="upload-wrapper">
        <!-- @slot alternative using props to define trigger experience -->
        <template
            v-if="!isUploading && internalFileList.length < limit"
            #trigger>
            <slot name="trigger">
                <pendo-icon
                    v-if="showError"
                    v-bind="errorIcon" />
                <pendo-icon
                    v-if="!showError"
                    v-bind="uploadIcon" />
                <div class="el-upload__helper-text">
                    <template v-if="!showError">
                        {{ uploadText }}
                    </template>
                    <template v-if="showError">
                        {{ errorMessage }}
                    </template>
                </div>
            </slot>
        </template>
        <template
            v-if="$slots.tip"
            #tip>
            <slot name="tip" />
        </template>
    </el-upload>
</template>

<script>
import { Upload } from 'element-ui';
import { PendoLoading, PendoIcon } from '@pendo/components';

export default {
    name: 'UploadWrapper',
    components: {
        ElUpload: Upload,
        PendoIcon
    },
    directives: {
        PendoLoading
    },
    props: {
        /**
         * request URL
         */
        action: {
            type: String,
            default: ''
        },
        /**
         * request headers
         */
        headers: {
            type: Object,
            default: () => ({})
        },
        /**
         * whether uploading multiple files is permitted
         */
        multiple: {
            type: Boolean,
            default: false
        },
        /**
         * additional request options
         */
        data: {
            type: Object,
            default: () => ({})
        },
        /**
         * key name for uplaoded file
         */
        name: {
            type: String,
            default: 'file'
        },
        /**
         * whether cookies are sent
         */
        withCredentials: {
            type: Boolean,
            default: false
        },
        /**
         * whether to show the uploaded file list
         */
        showFileList: {
            type: Boolean,
            default: true
        },
        /**
         * whether to activate drag and drop mode
         */
        drag: {
            type: Boolean,
            default: false
        },
        /**
         * accepted [file types](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-accept)
         */
        accept: {
            type: String,
            default: 'image/*'
        },
        /**
         * default uploaded files, e.g. `[{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]`
         */
        fileList: {
            type: Array,
            default: () => []
        },
        /**
         * type of fileList
         * @values text, picture, picture-card
         */
        listType: {
            type: String,
            default: 'picture-card',
            validator: (listType) => ['text', 'picture', 'picture-card'].includes(listType)
        },
        /**
         * whether to auto upload file
         */
        autoUpload: {
            type: Boolean,
            default: true
        },
        /**
         * override default xhr behavior, allowing you to implement your own upload-file's request
         */
        httpRequest: {
            type: Function,
            default: undefined
        },
        /**
         * whether to disable upload
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * maximum number of uploads allowed
         */
        limit: {
            type: Number,
            default: 1
        },
        /**
         * either a valid `pendo-icon` type (string) or config (object)
         */
        uploadIcon: {
            type: [String, Object],
            default: () => ({
                type: 'image',
                stroke: '#9A9CA5',
                size: 36,
                strokeWidth: 1.5
            })
        },
        /**
         * a valid `pendo-loading` config (object)
         */
        loadingConfig: {
            type: Object,
            default: () => ({})
        },
        /**
         * text that appears below upload icon
         */
        uploadText: {
            type: String,
            default: 'Click to upload image, or enter an image URL below.'
        },
        /**
         * either a valid `pendo-icon` type (string) or config (object)
         */
        errorIcon: {
            type: [String, Object],
            default: () => ({
                type: 'minus-circle',
                stroke: '#cc3340',
                size: 36,
                strokeWidth: 1.5
            })
        },
        /**
         * text that appears below error icon
         */
        errorText: {
            type: String,
            default: 'There was an error uploading the image. Please try again.'
        },
        /**
         * @ignore
         */
        validate: {
            type: [Boolean, Object],
            default: false
        },
        /**
         * determines available end user actions after uploading file
         * where `remove` allows user to delete the uplaoded media and `replace` allows user to only replace with alternatve file, but not delete.
         * @values remove, replace
         */
        removeType: {
            type: String,
            default: 'remove',
            validator: (removeType) => ['remove', 'replace'].includes(removeType)
        }
    },
    emits: [
        'exceed',
        'preview',
        'remove',
        'success',
        'error',
        'progress',
        'change',
        'invalid',
        'beforeUpload',
        'beforeRemove'
    ],
    data () {
        return {
            internalFileList: [],
            isUploading: false,
            isUploadError: false,
            errorType: null,
            errorMessages: {
                upload: this.errorText,
                validation: ''
            },
            uploadStartTime: null,
            uploadEndTime: null
        };
    },
    computed: {
        maxFiles () {
            if (this.removeType === 'replace') {
                return 2;
            }

            return this.limit;
        },
        showError () {
            return this.isInvalid || !!this.errorType;
        },
        errorMessage () {
            return this.errorMessages[this.errorType];
        },
        loadingProps () {
            return {
                loading: this.isUploading,
                type: 'material',
                ...this.loadingConfig
            };
        }
    },
    watch: {
        fileList (newValue) {
            this.internalFileList = newValue;
        }
    },
    methods: {
        handleExceed (files, fileList) {
            this.internalFileList = fileList;
            /**
             * emitted when `limit` is exceeded
             * @event exceed
             */
            this.$emit('exceed', { files, fileList });
        },
        handlePreview (file) {
            /**
             * emitted when uploaded files are clicked on
             * @event preview
             */
            this.$emit('preview', { file });
            if (this.removeType === 'replace') {
                const { input } = this.$refs.uploadWrapper.$refs['upload-inner'].$refs;
                input.value = null;
                input.click();
            }
        },
        handleRemove (file, fileList) {
            this.internalFileList = fileList;
            /**
             * emitted when file is removed from list
             * @event remove
             */
            this.$emit('remove', { file, fileList });
        },
        handleSuccess (response, file, fileList) {
            this.internalFileList = fileList;
            this.handleLoaderShutdown();
            /**
             * emitted upon successful upload of file
             * @event success
             */
            this.$emit('success', { response, file, fileList });
        },
        handleError (err, file, fileList) {
            this.internalFileList = fileList;
            this.errorType = 'upload';
            this.isUploadError = true;
            this.handleLoaderShutdown();

            /**
             * emitted when upload error occurs
             * @event error
             */
            this.$emit('error', { err, file, fileList });
        },
        handleProgress (event, file, fileList) {
            this.internalFileList = fileList;
            /**
             * emitted when upload progress occurs
             * @event progress
             */
            this.$emit('progress', { event, file, fileList });
        },
        handleLoaderShutdown () {
            const elapsedTime = Date.now() - this.uploadStartTime;
            // reset upload start time
            this.uploadStartTime = null;

            if (elapsedTime > 300) {
                setTimeout(() => {
                    this.isUploading = false;
                }, 300 - elapsedTime);
            } else {
                this.isUploading = false;
            }
        },
        handleChange (file, fileList) {
            if (this.removeType === 'replace') {
                this.internalFileList = [file];
            } else {
                this.internalFileList = fileList;
            }

            if (file.percentage === 0) {
                this.errorType = null;
                this.isUploading = true;
                this.uploadStartTime = Date.now();
            }

            /**
             * emitted when select file or upload file success or upload file fail
             * @event change
             */
            this.$emit('change', { file, fileList });
        },
        validateSize ({ min, max, file }) {
            const size = file.size / 1000000;
            let status;

            if (size < min) {
                status = 'under';
            } else if (size > max) {
                status = 'over';
            }

            if (!status) {
                return {
                    valid: true
                };
            }

            return {
                valid: false,
                error: {
                    under: `Upload an image that is larger than ${min}MB.`,
                    over: `Upload an image that is smaller than ${max}MB.`
                }[status]
            };
        },
        handleBeforeUpload (file) {
            return new Promise((resolve, reject) => {
                if (!this.validate) {
                    this.$emit('beforeUpload', { file });

                    return resolve();
                }

                const { type, min, max } = this.validate;
                if (type === 'size') {
                    // simple mb validation for now...
                    const { valid, error } = this.validateSize({ min, max, file });

                    if (valid) {
                        this.$emit('beforeUpload', { file });

                        return resolve();
                    }

                    this.handleLoaderShutdown();
                    this.errorType = 'validation';
                    this.errorMessages.validation = error;

                    /**
                     * emitted when an image fails a pre-upload validation check
                     * @event invalid
                     */
                    this.$emit('invalid', { type, file });

                    return reject();
                }
            });
        },
        handleBeforeRemove (file, fileList) {
            return this.$emit('beforeRemove', { file, fileList });
        }
    }
};
</script>

<style lang="scss">
.upload-wrapper {
    position: relative;
    width: 100%;
    height: 156px;
    padding: 0 16px;
    display: grid;
    background-color: $gray-lighter-7;
    border-radius: 3px;
    border: 1px solid $gray-lighter-5;
    overflow: hidden;
}

.upload-wrapper.is-showing-preview .el-upload-list--picture-card {
    display: unset;
}

.upload-wrapper.is-showing-preview .el-upload {
    display: none;
}

.upload-wrapper.is-showing-preview.is-remove .el-upload-list__item-actions:hover .el-upload-list__item-delete {
    display: inline-block;
}

.upload-wrapper.is-showing-preview.is-replace .el-upload-list__item-actions:hover .el-upload-list__item-preview {
    display: inline-block;
}

.upload-wrapper.is-showing-preview.is-replace
    .el-upload-list__item-actions:hover
    .el-upload-list__item-preview
    .el-icon-zoom-in {
    display: grid;
}

.upload-wrapper.is-showing-preview.is-replace
    .el-upload-list__item-actions:hover
    .el-upload-list__item-preview
    .el-icon-zoom-in:before {
    font-size: 24px;
    content: '\E9F5';
}

.upload-wrapper.is-showing-preview.is-replace
    .el-upload-list__item-actions:hover
    .el-upload-list__item-preview
    .el-icon-zoom-in:after {
    // font-family: Inter;
    font-size: 14px;
    line-height: 1em;
    content: 'Change Image';
}

.upload-wrapper.is-showing-error .el-upload__helper-text {
    color: $red-error;
}

.upload-wrapper .el-upload {
    display: grid;
    grid-gap: 16px;
    cursor: pointer;
    outline: 0;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
}

.upload-wrapper .el-upload--text:hover {
    background-color: $gray-lighter-6;
}

.upload-wrapper .el-upload .pendo-icon {
    display: grid;
    justify-content: center;
    align-items: end;
}

.upload-wrapper .el-upload .el-upload__helper-text,
.upload-wrapper .el-upload .el-upload__note-text {
    display: grid;
    align-items: start;
    font-size: 14px;
    line-height: 17px;
    color: $gray-lighter-2;
    text-align: center;
    font-weight: 600;
}

.upload-wrapper .el-upload .el-upload__note-text {
    font-weight: 400;
    font-size: 12px;
    margin-top: 6px;
}

.el-upload__input {
    display: none;
}

.el-upload__tip {
    font-size: 12px;
    color: $gray-lighter-1;
    margin-top: 7px;
}

.upload-wrapper .el-upload iframe {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
}

.upload-wrapper .el-upload--picture-card:hover {
    border-color: $gray-primary;
    color: $gray-primary;
}

.upload-wrapper .el-upload:focus {
    border-color: $gray-primary;
    color: $gray-primary;
}

.upload-wrapper .el-upload:focus .el-upload-dragger {
    border-color: $gray-primary;
}

.upload-wrapper .el-upload-dragger {
    background-color: $white;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    width: 360px;
    height: 180px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.upload-wrapper .el-upload-dragger .el-icon-upload {
    font-size: 67px;
    color: $gray-lighter-3;
    margin: 40px 0 16px;
    line-height: 50px;
}

.upload-wrapper .el-upload-dragger + .el-upload__tip {
    text-align: center;
}

.upload-wrapper .el-upload-dragger ~ .el-upload__files {
    border-top: 1px solid $gray-lighter-4;
    margin-top: 7px;
    padding-top: 5px;
}

.upload-wrapper .el-upload-dragger .el-upload__text {
    color: $gray-lighter-1;
    font-size: 14px;
    text-align: center;
}

.upload-wrapper .el-upload-dragger .el-upload__text em {
    color: $gray-primary;
    font-style: normal;
}

.upload-wrapper .el-upload-dragger:hover {
    border-color: $gray-primary;
}

.upload-wrapper .el-upload-dragger.is-dragover {
    background-color: rgba(32, 159, 255, 0.06);
    border: 2px dashed $gray-primary;
}

.upload-wrapper .el-upload-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.el-upload-list__item {
    transition: 250ms cubic-bezier(0.55, 0, 0.1, 1);
    font-size: 14px;
    color: $gray-lighter-1;
    line-height: 1.8;
    margin-top: 5px;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
}

.el-upload-list__item .el-progress {
    display: none;
    position: absolute;
    top: 20px;
    width: 100%;
}

.el-upload-list__item .el-progress__text {
    display: none;
    position: absolute;
    right: 0;
    top: -13px;
}

.el-upload-list__item .el-progress-bar {
    display: none;
    margin-right: 0;
    padding-right: 0;
}

.el-upload-list__item .el-icon-upload-success {
    color: $green-success;
}

.el-upload-list__item .el-icon-close {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    opacity: 0.75;
    color: $gray-lighter-1;
}

.el-upload-list__item .el-icon-close:hover {
    opacity: 1;
}

.el-upload-list__item .el-icon-close-tip {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 12px;
    cursor: pointer;
    opacity: 1;
    color: $gray-primary;
}

.el-upload-list__item:hover .el-icon-close {
    display: inline-block;
}

.el-upload-list__item:hover .el-progress__text {
    display: none;
}

.el-upload-list__item.is-success .el-upload-list__item-status-label {
    display: block;
}

.el-upload-list__item.is-success .el-upload-list__item-name:focus,
.el-upload-list__item.is-success .el-upload-list__item-name:hover {
    color: $link-dark-hover;
    cursor: pointer;
}

.el-upload-list__item.is-success:focus:not(:hover) .el-icon-close-tip {
    display: none;
}

.el-upload-list__item.is-success:active,
.el-upload-list__item.is-success:not(.focusing):focus {
    outline-width: 0;
}

.el-upload-list__item.is-success:active .el-icon-close-tip,
.el-upload-list__item.is-success:not(.focusing):focus .el-icon-close-tip {
    display: none;
}

.el-upload-list__item.is-success:focus .el-upload-list__item-status-label,
.el-upload-list__item.is-success:hover .el-upload-list__item-status-label {
    display: none;
}

.upload-wrapper .el-upload-list.is-disabled .el-upload-list__item:hover .el-upload-list__item-status-label {
    display: block;
}

.el-upload-list__item-name {
    color: $gray-lighter-1;
    display: block;
    margin-right: 40px;
    overflow: hidden;
    padding-left: 4px;
    text-overflow: ellipsis;
    transition: color 0.3s;
    white-space: nowrap;
}

.el-upload-list__item-name [class^='el-icon'] {
    height: 100%;
    margin-right: 7px;
    color: $gray-lighter-2;
    line-height: inherit;
}

.el-upload-list__item-status-label {
    position: absolute;
    right: 5px;
    top: 0;
    line-height: inherit;
    display: none;
}

.el-upload-list__item-delete {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
    color: $gray-lighter-1;
    display: none;
}

.el-upload-list__item-delete:hover {
    color: $gray-primary;
}

.upload-wrapper .el-upload-list--picture-card {
    margin: 0;
    display: none;
    vertical-align: top;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    margin: auto;
    height: max-content;
    max-width: 90%;
    max-height: 156px;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item {
    overflow: hidden;
    margin-top: 0;
    height: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item .el-icon-check,
.upload-wrapper .el-upload-list--picture-card .el-upload-list__item .el-icon-circle-check {
    display: none;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item .el-icon-close {
    display: none;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item:hover .el-upload-list__item-status-label {
    display: none;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item:hover .el-progress__text {
    display: block;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-name {
    display: none;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-status-label {
    display: none;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: default;
    text-align: center;
    color: $gray-lighter-2;
    opacity: 0;
    font-size: 20px;
    transition: opacity 0.2s;
    background-color: rgba(244, 244, 247, 0.8);
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions::after {
    display: inline-block;
    content: '';
    height: 100%;
    vertical-align: middle;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete,
.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-preview {
    display: none;
    cursor: pointer;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete {
    height: 24px;
    width: 24px;
    top: 8px;
    right: 8px;
    color: inherit;
    font-size: 16px;
}

.upload-wrapper .el-upload-list--picture-card .el-upload-list__item-actions:hover {
    opacity: 1;
}

.upload-wrapper .el-upload-list--picture-card .el-progress {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: auto;
    width: 126px;
}

.upload-wrapper .el-upload-list--picture-card .el-progress .el-progress__text {
    top: 50%;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item {
    overflow: hidden;
    z-index: 0;
    background-color: $white;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 10px 10px 10px 90px;
    height: 92px;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item .el-icon-check,
.upload-wrapper .el-upload-list--picture .el-upload-list__item .el-icon-circle-check {
    color: $white;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item:hover .el-upload-list__item-status-label {
    background: 0 0;
    box-shadow: none;
    top: -2px;
    right: -12px;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item:hover .el-progress__text {
    display: block;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name {
    line-height: 70px;
    margin-top: 0;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item.is-success .el-upload-list__item-name i {
    display: none;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item-thumbnail {
    vertical-align: middle;
    display: inline-block;
    width: 70px;
    height: 70px;
    // float: left;
    position: relative;
    z-index: 1;
    margin-left: -80px;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item-name {
    display: block;
    margin-top: 20px;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item-name i {
    font-size: 70px;
    line-height: 1;
    position: absolute;
    left: 9px;
    top: 10px;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item-status-label {
    position: absolute;
    right: -17px;
    top: -7px;
    width: 46px;
    height: 26px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 1px 1px #ccc;
}

.upload-wrapper .el-upload-list--picture .el-upload-list__item-status-label i {
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg);
}

.upload-wrapper .el-upload-list--picture .el-progress {
    position: relative;
    top: -7px;
}

.upload-wrapper .el-upload-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 10;
    cursor: default;
}

.upload-wrapper .el-upload-cover::after {
    display: inline-block;
    content: '';
    height: 100%;
    vertical-align: middle;
}

.upload-wrapper .el-upload-cover img {
    display: block;
    width: 100%;
    height: 100%;
}

.el-upload-cover__label {
    position: absolute;
    right: -15px;
    top: -6px;
    width: 40px;
    height: 24px;
    background: #13ce66;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 0 0 1pc 1px rgba(0, 0, 0, 0.2);
}

.el-upload-cover__label i {
    font-size: 12px;
    margin-top: 11px;
    transform: rotate(-45deg);
    color: $white;
}

.el-upload-cover__progress {
    display: inline-block;
    vertical-align: middle;
    position: static;
    width: 243px;
}

.el-upload-cover__progress + .el-upload__inner {
    opacity: 0;
}

.el-upload-cover__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.el-upload-cover__interact {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.72);
    text-align: center;
}

.el-upload-cover__interact .btn {
    display: inline-block;
    color: $white;
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    margin-top: 60px;
}

.el-upload-cover__interact .btn span {
    opacity: 0;
    transition: opacity 0.15s linear;
}

.el-upload-cover__interact .btn:not(:first-child) {
    margin-left: 35px;
}

.el-upload-cover__interact .btn:hover {
    transform: translateY(-13px);
}

.el-upload-cover__interact .btn:hover span {
    opacity: 1;
}

.el-upload-cover__interact .btn i {
    color: $white;
    display: block;
    font-size: 24px;
    line-height: inherit;
    margin: 0 auto 5px;
}

.el-upload-cover__title {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;
    height: 36px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    text-align: left;
    padding: 0 10px;
    margin: 0;
    line-height: 36px;
    font-size: 14px;
    color: $gray-primary;
}

.upload-wrapper .el-upload-cover + .el-upload__inner {
    opacity: 0;
    position: relative;
    z-index: 1;
}
</style>
