<template>
    <no-active-subs
        product-type="adopt"
        @logout="logout"
        @navigateToProduct="navigateToEngage">
        <template #logo>
            <slot name="logo" />
        </template>
    </no-active-subs>
</template>

<script>
import { NoActiveSubs } from '@pendo/login';
import { mapActions } from 'vuex';
import { engageURL } from '@/stateless-components/utils/environment';

export default {
    name: 'NoActiveSubscriptions',
    components: {
        NoActiveSubs
    },
    methods: {
        ...mapActions({
            _logout: 'auth/logout'
        }),
        async logout () {
            try {
                await this._logout();
                this.$router.push({ name: 'login', query: this.route.query }).catch(() => {});
            } catch (err) {
                window.location.reload();
            }
        },
        navigateToEngage () {
            window.location.href = engageURL();
        }
    }
};
</script>
