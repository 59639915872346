<template>
    <div class="workflows-dashboard">
        <workflows-filter-bar @runWorkflowAggs="runWorkflowAggs" />
        <template v-if="hideWorkflowsAnalytics">
            <workflow-empty-state
                v-if="!hasInvalidSavedWorkflowSteps"
                :no-data="noDataToSurface" />
        </template>
        <template v-else>
            <workflows-highlights-card
                :is-recurring="isRecurring"
                :loading-highlights="highlightsLoading"
                :loading-baseline="highlightsBaselineLoading"
                :highlights-agg-data="highlightsAggData"
                :baseline-agg-data="highlightsBaselineAggData"
                :saved-workflow-settings="savedWorkflowSettings"
                :earliest-app-first-visit="firstVisit"
                @updateBaselineDateRange="updateBaselineDateRange" />
            <h2 class="section-title">
                Completion rates
            </h2>
            <workflow-totals-chart
                :saved-workflow-settings="savedWorkflowSettings"
                :date-range-label="dateRangeLabel"
                :totals-agg-data="totalMetricsAggData"
                :loading="totalMetricsAggLoading"
                :is-recurring="isRecurring"
                @updateTotalsDataSource="updateTotalsDataSource" />
            <workflow-over-time-card
                :saved-workflow-settings="savedWorkflowSettings"
                :date-range="dateRangeOverTimeCard"
                :time-period-options="overTimePeriodOptions"
                :selected-time-period-option="overTimeSelectedTimePeriod"
                :guide-dropdown-options="publicGuidesForComparison"
                :loading="overTimeLoading"
                :over-time-agg-data="overTimeAggData"
                :subscription-utc-offset="subscriptionUtcOffset"
                :is-recurring="isRecurring"
                @guideSelected="updateOverTimeGuideSelection"
                @updateDataSource="updateOverTimeDataSource"
                @updateTimePeriod="updateOverTimePeriod" />
            <h2
                v-if="hasCompletions"
                class="section-title">
                Time to complete
            </h2>
            <workflow-time-to-complete-chart
                v-if="hasCompletions"
                :saved-workflow-settings="savedWorkflowSettings"
                :time-to-complete-agg-data="timeToCompleteAggData"
                :time-to-complete-summary-agg-data="timeToCompleteSummaryAggData"
                :loading="timeToCompleteLoading"
                :summary-loading="timeToCompleteSummaryLoading"
                :is-recurring="isRecurring"
                @updateDataType="updateTimeToCompleteDataType"
                @updateTimePeriod="updateTimeToCompleteTimePeriod" />
            <h2
                v-if="hasVisitors"
                class="section-title">
                Visitors
            </h2>
            <workflow-visitors-table
                v-if="hasVisitors"
                :timezone="getActiveTimezone"
                :visitor-metadata-options="visitorMetadataOptions"
                :visitor-metadata-selected-option="visitorMetadataSelectedOption"
                :table-user-settings="visitorsTableUserSettings"
                :visitor-table-agg-data="visitorTableAggData"
                :loading="visitorTableLoading"
                :is-recurring="isRecurring"
                :workflow-name="savedWorkflow.name"
                @visitorMetadataSelected="updateViewByMetadata"
                @tableSettingsUpdate="updateVisitorsTableUserSettings" />
            <workflow-empty-state :no-metadata="!hasMetadata" />
        </template>
    </div>
</template>

<script>
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapState, mapActions } from 'vuex';

import { PendoNotification } from '@pendo/components';

import { connectAggregationToState, withComponent } from '@pendo/agg-connect';
import workflowHighlightsAgg from '@/aggregations/workflows-highlights-metrics';
import workflowTotalMetricsAgg from '@/aggregations/workflows-total-metrics';
import workflowOverTimeAgg from '@/aggregations/workflows-over-time';
import { TimeToCompleteChartAgg, TimeToCompleteSummaryAgg } from '@/aggregations/workflow-time-to-complete';
import workflowVisitorTableAgg from '@/aggregations/workflow-visitors-table';

import WorkflowsFilterBar from './WorkflowsFilterBar';

import WorkflowEmptyState from '@/stateless-components/workflows/details/WorkflowEmptyState';
import WorkflowsHighlightsCard from '@/stateless-components/workflows/details/WorkflowHighlightsCard';
import WorkflowTotalsChart from '@/stateless-components/workflows/details/WorkflowTotalsChart';
import WorkflowOverTimeCard from '@/stateless-components/workflows/details/WorkflowOverTimeCard';
import WorkflowVisitorsTable from '@/stateless-components/workflows/details/WorkflowVisitorsTable';
import WorkflowTimeToCompleteChart from '@/stateless-components/workflows/details/WorkflowTimeToCompleteChart';

import { hasInvalidWorkflowSteps, cleanAndFormatWorkflowSteps } from '@/utils/workflows';
import { validPeriodsForCount } from '@/utils/time-series';

// Abstract out later
import moment from '@/utils/moment';

export default {
    name: 'WorkflowsDetailsHome',
    components: {
        WorkflowEmptyState,
        WorkflowsFilterBar,
        WorkflowsHighlightsCard,
        WorkflowTotalsChart,
        WorkflowOverTimeCard,
        WorkflowVisitorsTable,
        WorkflowTimeToCompleteChart
    },
    data () {
        return {
            visitorTableUserSettingsName: 'workflowDetailsVisitorTable',
            workflowHighlightsAgg$: null,
            highlightsAggData: null,
            highlightsLoading: false,

            workflowHighlightsBaselineAgg$: null,
            highlightsBaselineAggData: null,
            highlightsBaselineLoading: false,

            totalMetricsAgg$: null,
            totalMetricsAggData: null,
            totalMetricsAggLoading: false,

            timeToCompleteChartAgg$: null,
            timeToCompleteAggData: null,
            timeToCompleteLoading: false,
            timeToCompleteSummaryAgg$: null,
            timeToCompleteSummaryAggData: null,
            timeToCompleteSummaryLoading: false,

            workflowOverTimeAgg$: null,
            overTimeAggData: null,
            overTimeLoading: false,

            visitorTableAgg$: null,
            visitorTableAggData: null,
            visitorTableLoading: false
        };
    },
    computed: {
        ...mapState({
            // eslint-disable-next-line vue/no-unused-properties
            segmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            // eslint-disable-next-line vue/no-unused-properties
            pagesMap: (state) => state.pages.map,
            // eslint-disable-next-line vue/no-unused-properties
            featuresMap: (state) => state.features.map
        }),
        ...mapGetters({
            guideList: 'guides/list',
            pageList: 'pages/list',
            featureList: 'features/list',
            segmentList: 'filters/segmentsList',
            savedWorkflow: 'workflows/savedWorkflow',
            savedWorkflowSettings: 'workflows/savedWorkflowSettings',
            firstVisit: 'apps/firstVisit',
            subscriptionUtcOffset: 'subscriptions/activeSubscriptionUtcOffset',
            getActiveTimezone: 'subscriptions/getTimezone',
            visitorMetadataOptions: 'metadata/visitorMetadataOptions',
            getTableUserSettingValueByName: 'userSettings/getTableUserSettingValueByName'
        }),
        dateRangeLabel () {
            return this.dateRange.id === 'custom'
                ? this.dateRange.label
                : get(this, 'dateRange.label', '').toLowerCase();
        },
        dateRangeOverTimeCard () {
            const { start, end } = this.dateRange.value;

            return {
                start,
                end
            };
        },
        hasMetadata () {
            return this.visitorMetadataOptions.length;
        },
        visitorsTableUserSettings () {
            return this.getTableUserSettingValueByName(this.visitorTableUserSettingsName);
        },
        formattedWorkflow () {
            if (!this.savedWorkflow) return;

            const formattedWorkflow = cloneDeep(this.savedWorkflow);

            formattedWorkflow.workflowSteps = cleanAndFormatWorkflowSteps(formattedWorkflow.workflowSteps, {
                featuresMap: this.sharedFeaturesMap,
                pagesMap: this.sharedPagesMap
            });

            return formattedWorkflow;
        },
        publicGuidesForComparison () {
            const publicGuides = this.guideList.filter((guide) => guide.state === 'public');

            const guidesWithSegmentNames = publicGuides.map((guide) => {
                const segmentId = get(guide, 'audienceUiHint.filters[0].segmentId', null);
                const option = {
                    name: guide.name,
                    id: guide.id,
                    publishedAt: guide.publishedAt,
                    segmentName: 'Everyone'
                };

                if (!segmentId) return option;

                const matchingSegment = this.segmentList.find((segment) => segment.id === segmentId);
                const segmentName = get(matchingSegment, 'name', 'Unknown Segment');

                option.segmentName = segmentName;

                return option;
            });

            return guidesWithSegmentNames;
        },
        baselineDateRange () {
            if (!this.savedWorkflowSettings) return null;

            return this.savedWorkflowSettings.baselineDateRange;
        },
        baselineDateRangeFormatted () {
            if (!this.baselineDateRange) return undefined;

            return {
                value: {
                    start: moment(this.baselineDateRange.start).format('YYYY-MM-DD'),
                    end: moment(this.baselineDateRange.end).format('YYYY-MM-DD')
                }
            };
        },
        sharedPagesMap () {
            return keyBy(this.pageList, 'id');
        },
        sharedFeaturesMap () {
            return keyBy(this.featureList, 'id');
        },
        hasInvalidSavedWorkflowSteps () {
            if (!this.savedWorkflow) return true;
            const workflowSteps = get(this.savedWorkflow, 'workflowSteps', []);

            return hasInvalidWorkflowSteps(workflowSteps, {
                featuresMap: this.sharedFeaturesMap,
                pagesMap: this.sharedPagesMap
            });
        },
        hideWorkflowsAnalytics () {
            if (!this.savedWorkflow) return true;
            if (this.hasInvalidSavedWorkflowSteps) return true;
            if (this.noDataToSurface) return true;

            return false;
        },
        isRecurring () {
            if (!this.savedWorkflow) return false;

            return this.savedWorkflow.classification === 'recurring';
        },
        visitorMetadataSelectedOption () {
            const viewBy = get(this.savedWorkflowSettings, 'viewBy', {});
            const selectedOption = this.visitorMetadataOptions.find((metadata) => {
                if (metadata.kind !== viewBy.kind) return false;
                if (metadata.group !== viewBy.group) return false;
                if (metadata.field !== viewBy.field) return false;

                return true;
            });

            if (selectedOption) return selectedOption;

            return this.visitorMetadataOptions[0];
        },
        overTimePeriodOptions () {
            const labels = {
                hourly: 'hours',
                daily: 'days',
                weekly: 'weeks',
                monthly: 'months'
            };

            const validPeriodsForDateRange = validPeriodsForCount(this.dateRange.count);

            return validPeriodsForDateRange.map((period) => ({
                id: period,
                label: labels[period]
            }));
        },
        overTimeSelectedTimePeriod () {
            const setting = get(this.savedWorkflowSettings, 'timePeriod', null);

            const matchingOption = this.overTimePeriodOptions.find((option) => option.id === setting);

            if (matchingOption) return matchingOption;

            return this.overTimePeriodOptions[this.overTimePeriodOptions.length - 1];
        },
        hasCompletions () {
            if (this.highlightsLoading) return true;

            const completionsKey = this.isRecurring ? 'completedAttempts' : 'completedCount';

            return !!get(this.highlightsAggData, completionsKey, 0);
        },
        hasVisitors () {
            if (this.highlightsLoading) return true;

            const visitorsKey = this.isRecurring ? 'totalAttempts' : 'totalVisitors';

            return !!get(this.highlightsAggData, visitorsKey, 0);
        },
        noDataToSurface () {
            return !this.hasCompletions && !this.hasVisitors;
        }
    },
    created () {
        if (!this.savedWorkflow) return;

        if (this.hasInvalidSavedWorkflowSteps) {
            return PendoNotification({
                type: 'error',
                message: `Please select start and end steps for ${this.savedWorkflow.name}`,
                duration: 0
            });
        }

        this.runWorkflowAggs();
    },
    methods: {
        ...mapActions({
            updateWorkflowSetting: 'workflows/updateWorkflowSetting',
            updateUserSettingByName: 'userSettings/updateUserSettingByName'
        }),
        runWorkflowAggs () {
            if (!this.workflowHighlightsAgg$) this.createHighlightsAgg$();
            if (!this.workflowHighlightsBaselineAgg$) this.createHighlightsBaselineAgg$();
            if (!this.totalMetricsAgg$) this.createTotalMetricsAgg$();
            if (!this.workflowOverTimeAgg$) this.createOverTimeAgg$();
            if (!this.timeToCompleteChartAgg$) this.createTimeToCompleteChartAgg$();
            if (!this.timeToCompleteSummaryAgg$) this.createTimeToCompleteSummaryAgg$();
            if (!this.visitorTableAgg$) this.createVisitorTableAgg$();
        },
        createHighlightsAgg$ () {
            this.workflowHighlightsAgg$ = connectAggregationToState(
                workflowHighlightsAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    segmentId: 'segmentId',
                    dateRange: 'dateRange'
                })
            )({ component: this });

            this.workflowHighlightsAgg$.subscribe((response) => {
                const { status, value } = response;

                switch (status) {
                    case 'loading':
                        this.highlightsLoading = true;
                        this.highlightsAggData = null;
                        break;
                    case 'resolved': {
                        this.highlightsLoading = false;
                        this.highlightsAggData = value[0];
                        break;
                    }
                    case 'rejected': {
                        this.highlightsLoading = false;
                        this.highlightsAggData = null;

                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflow Highlights data'
                        });
                        break;
                    }
                }
            });
        },
        createHighlightsBaselineAgg$ () {
            const baselineAgg = cloneDeep(workflowHighlightsAgg);
            baselineAgg.name = 'WorkflowHighlights-Baseline';

            this.workflowHighlightsBaselineAgg$ = connectAggregationToState(
                baselineAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    segmentId: 'segmentId',
                    dateRange: 'baselineDateRangeFormatted'
                })
            )({ component: this });

            this.workflowHighlightsBaselineAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.highlightsBaselineLoading = true;
                        this.highlightsBaselineAggData = null;
                        break;
                    case 'resolved':
                        this.highlightsBaselineLoading = false;
                        this.highlightsBaselineAggData = value[0];
                        break;
                    case 'rejected':
                        this.highlightsBaselineLoading = false;
                        this.highlightsBaselineAggData = null;
                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        break;
                }
            });
        },
        createTotalMetricsAgg$ () {
            this.totalMetricsAgg$ = connectAggregationToState(
                workflowTotalMetricsAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    segmentId: 'segmentId',
                    dateRange: 'dateRange'
                })
            )({ component: this });

            this.totalMetricsAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.totalMetricsAggData = null;
                        this.totalMetricsAggLoading = true;
                        break;
                    case 'resolved':
                        this.totalMetricsAggData = get(value, '0', null);
                        this.totalMetricsAggLoading = false;
                        break;
                    case 'rejected':
                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        this.totalMetricsAggData = null;
                        this.totalMetricsAggLoading = false;
                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflow Total data'
                        });
                        break;
                }
            });
        },
        createOverTimeAgg$ () {
            this.workflowOverTimeAgg$ = connectAggregationToState(
                workflowOverTimeAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    segmentId: 'segmentId',
                    dateRange: 'dateRange',
                    period: 'overTimeSelectedTimePeriod.id'
                })
            )({ component: this });

            this.workflowOverTimeAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.overTimeLoading = true;
                        this.overTimeAggData = null;
                        break;
                    case 'resolved':
                        this.overTimeLoading = false;
                        this.overTimeAggData = value;
                        break;
                    case 'rejected':
                        this.overTimeLoading = false;
                        this.overTimeAggData = null;

                        // eslint-disable-next-line no-console
                        console.error(response.error);

                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflows Over Time data'
                        });
                        break;
                }
            });
        },
        createTimeToCompleteChartAgg$ () {
            this.timeToCompleteChartAgg$ = connectAggregationToState(
                TimeToCompleteChartAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    activeSegmentId: 'segmentId',
                    dateRange: 'dateRange'
                })
            )({ component: this });

            this.timeToCompleteChartAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.timeToCompleteAggData = null;
                        this.timeToCompleteLoading = true;
                        break;
                    case 'resolved':
                        this.timeToCompleteAggData = value;
                        this.timeToCompleteLoading = false;
                        break;
                    case 'rejected':
                        this.timeToCompleteAggData = null;
                        this.timeToCompleteLoading = false;

                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflow Time To Complete data'
                        });
                        break;
                }
            });
        },
        createTimeToCompleteSummaryAgg$ () {
            this.timeToCompleteSummaryAgg$ = connectAggregationToState(
                TimeToCompleteSummaryAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    activeSegmentId: 'segmentId',
                    dateRange: 'dateRange'
                })
            )({ component: this });

            this.timeToCompleteSummaryAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.timeToCompleteSummaryAggData = null;
                        this.timeToCompleteSummaryLoading = true;
                        break;
                    case 'resolved':
                        this.timeToCompleteSummaryAggData = value[0];
                        this.timeToCompleteSummaryLoading = false;
                        break;
                    case 'rejected':
                        this.timeToCompleteSummaryAggData = null;
                        this.timeToCompleteSummaryLoading = false;

                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflow Time To Complete data'
                        });
                        break;
                }
            });
        },
        createVisitorTableAgg$ () {
            this.visitorTableAgg$ = connectAggregationToState(
                workflowVisitorTableAgg,
                withComponent({
                    workflow: 'formattedWorkflow',
                    segmentId: 'segmentId',
                    dateRange: 'dateRange',
                    metadataOption: 'visitorMetadataSelectedOption'
                })
            )({ component: this });

            this.visitorTableAgg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case 'loading':
                        this.visitorTableLoading = true;
                        this.visitorTableAggData = null;
                        break;
                    case 'resolved':
                        this.visitorTableLoading = false;
                        this.visitorTableAggData = value;
                        break;
                    case 'rejected':
                        this.visitorTableLoading = false;
                        this.visitorTableAggData = null;

                        // eslint-disable-next-line no-console
                        console.error(response.error);
                        PendoNotification({
                            type: 'error',
                            message: 'Error retrieving Workflows Visitor data'
                        });
                        break;
                }
            });
        },
        updateBaselineDateRange (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'baselineDateRange',
                value: newSettingValue
            });
        },
        updateTotalsDataSource (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'totalChartDataSource',
                value: newSettingValue
            });
        },
        updateOverTimeGuideSelection (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'guideMonitorOverTimeChart',
                value: newSettingValue
            });
        },
        updateOverTimeDataSource (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'dataSource',
                value: newSettingValue
            });
        },
        updateOverTimePeriod (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'timePeriod',
                value: newSettingValue
            });
        },
        updateViewByMetadata (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'viewBy',
                value: newSettingValue
            });
        },
        updateTimeToCompleteDataType (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'timeToCompleteChartDataType',
                value: newSettingValue
            });
        },
        updateTimeToCompleteTimePeriod (newSettingValue) {
            this.updateWorkflowSetting({
                workflowId: this.savedWorkflow.id,
                name: 'timeToCompleteChartTimePeriod',
                value: newSettingValue
            });
        },
        updateVisitorsTableUserSettings (newUserSettings) {
            this.updateUserSettingByName({
                settingsName: this.visitorTableUserSettingsName,
                value: newUserSettings
            });
        }
    }
};
</script>

<style lang="scss">
.workflows-dashboard {
    display: grid;
    grid-template-columns: minmax(372px, 1fr);
    grid-gap: 12px;

    .section-title {
        margin-left: 15px;
        font-size: 23px;
        font-weight: 600;
        line-height: 30px;
    }
}
</style>
