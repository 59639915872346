import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import capitalize from 'lodash/capitalize';
import isArray from 'lodash/isArray';
import moment, { DATE_FORMAT, convertToSubscriptionTimezone } from '@/utils/moment';
import { http } from '@pendo/http';
import { Observable } from 'rxjs';
import { BuildingBlock, BuildingBlockProperties } from '@pendo/services/BuildingBlocks';
import { addPropertyNamesToSteps } from '@/stateless-components/utils/guides';

export const TOP_LEVEL_GUIDE_CONTAINER_ID = 'insert_visual_main_layout';

export const GUIDE_VISUAL_CONTAINER_ID = 'insert_visual_container_layout';

export const PENDO_BACKDROP_ID = 'pendo-backdrop';

export const GUIDE_AUDIT_PROPS_TO_CHECK = Object.freeze({
    NAME: 'NAME',
    LAUNCH_METHOD: 'LAUNCH_METHOD',
    AUDIENCE: 'AUDIENCE',
    SHOWS_AFTER: 'SHOWS_AFTER',
    EXPIRES_AFTER: 'EXPIRES_AFTER',
    STATE: 'STATE',
    DESCRIPTION: 'DESCRIPTION',
    DEVICE_TYPE: 'DEVICE_TYPE',
    OVERRIDE_AUTO_THROTTLING: 'OVERRIDE_AUTO_THROTTLING',
    LOCALIZATION: 'LOCALIZATION',
    REDISPLAY: 'REDISPLAY',
    DELIVERY_FREQUENCY: 'DELIVERY_FREQUENCY',
    EMAIL_CONFIG: 'EMAIL_CONFIG',
    MOBILE_DISPLAY: 'MOBILE_DISPLAY',
    SELECTOR: 'SELECTOR',
    POSITION_IN_ELEMENT: 'POSITION_IN_ELEMENT'
});

export const DIMENSION_TYPES = {
    FIXED_WIDTH: 'Fixed width',
    FLUID_WIDTH: 'Fluid width',
    FULL_SCREEN: 'Full screen'
};

export function isValidStatusValue (value) {
    if (!Object.hasOwn(value, 'showsAfter')) return false;
    if (!Object.hasOwn(value, 'lastUpdatedAt')) return false;

    return Object.hasOwn(value, 'state');
}

export function isValidLocalizationValue (value) {
    return Object.hasOwn(value, 'all');
}

export function isValidEmailConfigValue (value) {
    if (!Object.hasOwn(value, 'senderName')) return false;
    if (!Object.hasOwn(value, 'subject')) return false;

    return Object.hasOwn(value, 'emailMetadataPropertyName');
}

function isStatusAndInvalid (difference) {
    if (difference.key !== GUIDE_AUDIT_PROPS_TO_CHECK.STATE) return false;

    return !isValidStatusValue(difference.selected) || !isValidStatusValue(difference.previous);
}

function isEmailConfigAndInvalid (difference) {
    if (difference.key !== GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG) return false;

    return !isValidEmailConfigValue(difference.selected) || !isValidEmailConfigValue(difference.previous);
}

export function isValidDifferencesArray (differences = []) {
    if (differences.length === 0) return true;

    for (const difference of differences) {
        if (!Object.hasOwn(difference, 'previous')) return false;
        if (!Object.hasOwn(difference, 'selected')) return false;
        if (!Object.hasOwn(difference, 'label')) return false;
        if (!Object.hasOwn(difference, 'key')) return false;
        if (isStatusAndInvalid(difference)) return false;
        if (isEmailConfigAndInvalid(difference)) return false;
    }

    return true;
}

export function getGuideContainer (blocks, id = TOP_LEVEL_GUIDE_CONTAINER_ID) {
    const { findBlock } = BuildingBlock;

    return findBlock(blocks, (block) => get(block, 'id', '') === id);
}

function getPropertyValue (properties, propertyName) {
    if (!isArray(properties)) return;

    const { getPropertyByName } = BuildingBlockProperties;
    const property = getPropertyByName(properties, propertyName);

    return get(property, 'value');
}

export function getZIndex (blocks) {
    const topLevelGuideContainer = getGuideContainer(blocks);
    const zIndex = getPropertyValue(topLevelGuideContainer?.properties, 'z-index');

    return zIndex || '--';
}

const mobileBackgroundProps = {
    color: 'mobileBackdropBackground',
    isEnabled: 'hasMobileBackdrop'
};

const webBackgroundProps = {
    color: 'background',
    isEnabled: 'enabled'
};

export function getBackground (blocks, isMobileApp) {
    const { findBlockByDomId } = BuildingBlock;
    const props = isMobileApp ? mobileBackgroundProps : webBackgroundProps;
    const block = isMobileApp
        ? getGuideContainer(blocks, TOP_LEVEL_GUIDE_CONTAINER_ID)
        : findBlockByDomId(blocks, PENDO_BACKDROP_ID);
    const color = getPropertyValue(block?.properties, props.color);
    const isEnabled = getPropertyValue(block?.properties, props.isEnabled);

    return {
        color,
        isEnabled
    };
}

export function getVerticalAlignment (blocks) {
    const topLevelGuideContainer = getGuideContainer(blocks);

    return get(topLevelGuideContainer, 'web.verticalAlignment');
}

export function getDimensionMobile (blocks) {
    const topLevelGuideContainer = getGuideContainer(blocks, GUIDE_VISUAL_CONTAINER_ID);
    const height = getPropertyValue(topLevelGuideContainer?.properties, 'layout_height');
    const maxWidth = getPropertyValue(topLevelGuideContainer?.properties, 'maxWidth');

    if (height === 'match_parent') return { type: DIMENSION_TYPES.FULL_SCREEN };

    if (maxWidth === '0dp') return { type: DIMENSION_TYPES.FLUID_WIDTH };

    return { type: DIMENSION_TYPES.FIXED_WIDTH, width: maxWidth };
}

export function getDimensionWeb (blocks) {
    const topLevelGuideContainer = getGuideContainer(blocks);
    const width = getPropertyValue(topLevelGuideContainer?.properties, 'layout_width');
    const type = !width || width.includes('%') ? DIMENSION_TYPES.FLUID_WIDTH : DIMENSION_TYPES.FIXED_WIDTH;
    const min = getPropertyValue(topLevelGuideContainer?.properties, 'layout_minWidth');
    const max = getPropertyValue(topLevelGuideContainer?.properties, 'layout_maxWidth');
    const top = getPropertyValue(topLevelGuideContainer?.properties, 'layout_top');
    const bottom = getPropertyValue(topLevelGuideContainer?.properties, 'layout_bottom');
    const left = getPropertyValue(topLevelGuideContainer?.properties, 'layout_left');
    const right = getPropertyValue(topLevelGuideContainer?.properties, 'layout_right');

    return {
        width,
        min,
        max,
        type,
        top,
        bottom,
        left,
        right
    };
}

export function getDimension (blocks, isMobileApp) {
    if (isMobileApp) return getDimensionMobile(blocks);

    return getDimensionWeb(blocks);
}

export function getHistory (guideId, params) {
    return http.get(`/api/s/_SID_/guide/${guideId}/history`, { params }).then((res) => res.data);
}

export async function guideHistoryObservable (guide) {
    return new Observable(generateGuideHistoryObservableHandler(guide));
}

export function generateGuideHistoryObservableHandler (guide) {
    return async function (subscriber) {
        const start = getDefaultStartDate(guide);
        const end = guide.lastUpdatedAt + 1;
        const history = await getHistory(guide.id, { start, end });
        subscriber.next(history);

        if (start > guide.createdAt) {
            const restOfHistory = await getHistory(guide.id, { start: guide.createdAt, end: start });
            subscriber.next(history.concat(restOfHistory));
        }

        subscriber.complete();
    };
}

export function convertGuideHistoryToSessions (history, timezone, featureNamesById, pagesNamesById) {
    let lastUser;
    let lastDate;

    return history.reduce((acc, curr) => {
        curr.revisionDateFormatted = convertToSubscriptionTimezone(timezone, curr.lastUpdatedAt).format(
            DATE_FORMAT.short
        );

        if (curr.revisionDateFormatted === lastDate && curr.lastUpdatedByUser.username === lastUser) {
            return acc;
        }

        lastUser = curr.lastUpdatedByUser.username;
        lastDate = curr.revisionDateFormatted;
        curr.steps = addPropertyNamesToSteps(curr.steps, featureNamesById, pagesNamesById);
        acc.push(curr);

        return acc;
    }, []);
}

export function formatRevisionDateTimestamp (timestamp, activeTimezone) {
    const timeInSubscriptionTimezone = convertToSubscriptionTimezone(activeTimezone, timestamp);

    return `${timeInSubscriptionTimezone.format(DATE_FORMAT.short)} - ${timeInSubscriptionTimezone.format(
        DATE_FORMAT.fullTime
    )}`;
}

export function stepHasVisualChanges (selectedStep, previousStep) {
    if (!selectedStep || !previousStep) {
        return true;
    }

    return selectedStep.buildingBlocksUrl !== previousStep.buildingBlocksUrl;
}

function getNonEmptyKey (step = {}, key = '', defaultValue = '') {
    const value = get(step, key, '').trim();

    return value || defaultValue;
}

export function getNonVisualChanges (step, prev, { isEmbedded = false }) {
    const changes = [];
    const isPreviousEmpty = !prev && step;
    const selectedStep = step || {};
    const previousStep = prev || {};

    let selected = get(selectedStep, 'name', '');
    let previous = get(previousStep, 'name', '');
    if (isPreviousEmpty || selected !== previous) {
        changes.push({
            selected,
            previous,
            key: 'name',
            label: 'Step name'
        });
    }

    const selectedAnchor = [];
    const previousAnchor = [];
    const elementSelected = `Element: ${getNonEmptyKey(selectedStep, 'elementPathRule', '--')}`;
    const elementPrevious = `Element: ${getNonEmptyKey(previousStep, 'elementPathRule', '--')}`;
    let selectedId = getNonEmptyKey(selectedStep, 'featureId', get(selectedStep, 'locationFeatureId', '--'));
    let previousId = getNonEmptyKey(previousStep, 'featureId', get(previousStep, 'locationFeatureId', '--'));
    const featureSelected = `Feature: ${getNonEmptyKey(selectedStep, 'featureName', selectedId)}`;
    const featurePrevious = `Feature: ${getNonEmptyKey(previousStep, 'featureName', previousId)}`;
    const hasFeatureDifference =
        selectedStep.featureId !== previousStep?.featureId ||
        selectedStep.locationFeatureId !== previousStep?.locationFeatureId;
    const hasElementDifference = selectedStep.elementPathRule !== previousStep?.elementPathRule;

    if (isPreviousEmpty || hasFeatureDifference || hasElementDifference) {
        selectedAnchor.push(selectedId.includes('--') ? elementSelected : featureSelected);
        previousAnchor.push(previousId.includes('--') ? elementPrevious : featurePrevious);
    }

    if (selectedAnchor.length > 0 && !isEmbedded) {
        changes.push({
            selected: selectedAnchor.join('\n'),
            previous: previousAnchor.join('\n') || '',
            key: 'anchor',
            label: 'Anchor to element / feature'
        });
    }

    selectedId = getNonEmptyKey(selectedStep, 'pageId', 'Sitewide');
    previousId = getNonEmptyKey(previousStep, 'pageId', 'Sitewide');
    selected = get(selectedStep, 'pageName', selectedId);
    previous = get(previousStep, 'pageName', previousId);
    if (isPreviousEmpty || selected !== previous) {
        changes.push({
            selected,
            previous,
            key: 'page',
            label: 'Page location'
        });
    }

    const isSelectedStepBlockIdEnabled = get(selectedStep, 'attributes.blockOutUI.enabled', false)
        ? 'Show'
        : "Don't show";

    const isPreviousStepBlockIdEnabled = get(previousStep, 'attributes.blockOutUI.enabled', false)
        ? 'Show'
        : "Don't show";

    if (isPreviousEmpty || isPreviousStepBlockIdEnabled !== isSelectedStepBlockIdEnabled) {
        changes.push({
            selected: `${isSelectedStepBlockIdEnabled} target element above backdrop`,
            previous: `${isPreviousStepBlockIdEnabled} target element above backdrop`,
            key: 'backdrop',
            label: 'Backdrop'
        });
    }

    const isSelectedStepAutoFocus = get(selectedStep, 'attributes.isAutoFocus', false) ? 'Yes' : 'No';
    const isPreviousStepAutoFocus = get(previousStep, 'attributes.isAutoFocus', false) ? 'Yes' : 'No';
    if (isPreviousEmpty || isSelectedStepAutoFocus !== isPreviousStepAutoFocus) {
        changes.push({
            selected: isSelectedStepAutoFocus,
            previous: isPreviousStepAutoFocus,
            key: 'autofocusEnabled',
            label: 'Autofocus this step'
        });
    }

    return changes;
}

export function getFormattedDimensonChanges (blocksDimension, isMobile) {
    const type = get(blocksDimension, 'type', DIMENSION_TYPES.FIXED_WIDTH);
    const dimensionChanges = [type];

    if (type === DIMENSION_TYPES.FIXED_WIDTH) {
        dimensionChanges.push(`Width: ${get(blocksDimension, 'width', 'auto')}`);
    } else if (type === DIMENSION_TYPES.FLUID_WIDTH && !isMobile) {
        dimensionChanges.push(`Min width: ${get(blocksDimension, 'min', '--')}`);
        dimensionChanges.push(`Max width: ${get(blocksDimension, 'max', '--')}`);
    }

    return dimensionChanges;
}

export function getDimensionChanges (
    selectedBlocksDimension,
    previousBlocksDimension,
    { isPreviousEmpty, isMobileApp }
) {
    let selectedDimension = [];
    let previousDimension = [];
    const selectedType = get(selectedBlocksDimension, 'type', 'Fixed width');
    const previousType = get(previousBlocksDimension, 'type', 'Fixed width');
    const isSelectedFixedType = selectedType === DIMENSION_TYPES.FIXED_WIDTH;
    const isSelectedFluid = selectedType === DIMENSION_TYPES.FLUID_WIDTH;
    const hasTypeChange = selectedType !== previousType;
    const hasFixedWidthChange =
        isSelectedFixedType && get(previousBlocksDimension, 'width') !== selectedBlocksDimension.width;
    const hasFluidWidthChange =
        isSelectedFluid &&
        (get(previousBlocksDimension, 'min') !== selectedBlocksDimension.min ||
            get(previousBlocksDimension, 'max') !== selectedBlocksDimension.max);
    const shouldShowDimensionChange = isPreviousEmpty || hasTypeChange || hasFixedWidthChange || hasFluidWidthChange;

    if (shouldShowDimensionChange) {
        selectedDimension = getFormattedDimensonChanges(selectedBlocksDimension, isMobileApp);

        if (!isPreviousEmpty) {
            previousDimension = getFormattedDimensonChanges(previousBlocksDimension, isMobileApp);
        }
    }

    return { selectedDimension, previousDimension };
}

export function getBuildingBlocksChanges (blocks, prev, { isEmbedded = false, isMobileApp = false }) {
    const changes = [];
    const isPreviousEmpty = !prev && blocks;
    const selectedBlocks = blocks || {};
    const previousBlocks = prev || {};
    const selectedBlocksDimension = getDimension(selectedBlocks, isMobileApp);
    const previousBlocksDimension = getDimension(previousBlocks, isMobileApp);
    const selectedOffset = [];
    const previousOffset = [];

    ['top', 'right', 'left', 'bottom'].forEach((key) => {
        const selectedValueByKey = get(selectedBlocksDimension, key);
        const previousValueByKey = get(previousBlocksDimension, key);
        if (selectedValueByKey === previousValueByKey) return;

        if (selectedValueByKey) selectedOffset.push(`${capitalize(key)} ${selectedValueByKey}`);
        if (previousValueByKey) previousOffset.push(`${capitalize(key)} ${previousValueByKey}`);
    });

    let previous;
    let selected;
    if (isEmbedded) {
        previous = !isPreviousEmpty ? 'Inside Element' : null;
        selected = 'Inside Element';
    } else {
        previous = getVerticalAlignment(previousBlocks);
        selected = getVerticalAlignment(selectedBlocks);
    }
    if ((isPreviousEmpty && !!selected) || previous !== selected) {
        if (selectedOffset.length > 0) {
            selected = `${selected}\nOffset: ${selectedOffset.join(', ')}`;
        }

        if (previousOffset.length > 0) {
            previous = `${previous}\nOffset: ${selectedOffset.join(', ')}`;
        }

        changes.push({
            selected,
            previous,
            key: 'position',
            label: 'Position on page'
        });
    }

    const { selectedDimension, previousDimension } = getDimensionChanges(
        selectedBlocksDimension,
        previousBlocksDimension,
        { isPreviousEmpty, isMobileApp }
    );

    if (selectedDimension.length > 0) {
        changes.push({
            selected: selectedDimension.join('\n'),
            previous: previousDimension.join('\n'),
            key: 'dimension',
            label: 'Dimensions'
        });
    }

    selected = getZIndex(selectedBlocks);
    previous = getZIndex(previousBlocks);
    if (!isMobileApp && (isPreviousEmpty || selected !== previous)) {
        changes.push({
            selected,
            previous,
            key: 'zIndex',
            label: 'Guide container z-index'
        });
    }

    const selectedBlocksBackground = getBackground(selectedBlocks, isMobileApp);
    const previousBlocksBackground = getBackground(previousBlocks, isMobileApp);
    const selectedBackground = [];
    const previousBackground = [];

    const isEnabledForSelected = get(selectedBlocksBackground, 'isEnabled', false) ? 'Is enabled' : 'Is not enabled';
    const isEnabledForPrevious = get(previousBlocksBackground, 'isEnabled', false) ? 'Is enabled' : 'Is not enabled';
    if (isPreviousEmpty || isEnabledForSelected !== isEnabledForPrevious) {
        selectedBackground.push(isEnabledForSelected);
        previousBackground.push(isEnabledForPrevious);
    }

    selected = `Color: ${get(selectedBlocksBackground, 'color', '--')}`;
    previous = `Color: ${get(previousBlocksBackground, 'color', '--')}`;
    if (isPreviousEmpty || selected !== previous) {
        if (isEnabledForSelected === 'Is enabled') selectedBackground.push(selected);
        if (isEnabledForPrevious === 'Is enabled') previousBackground.push(previous);
    }

    if (selectedBackground.length > 0) {
        changes.push({
            selected: selectedBackground.join('\n'),
            previous: previousBackground.join('\n'),
            key: 'backdrop',
            label: 'Backdrop'
        });
    }

    return changes;
}

export function filterRevisionsByUser (revisions = [], users = []) {
    if (!users.length) return revisions;

    const userMap = users.reduce((map, user) => {
        const userId = user.id || user;
        map[userId] = true;

        return map;
    }, {});

    return revisions.filter((revision) => {
        const { username } = revision.lastUpdatedByUser;

        return userMap[username];
    });
}

export const oneDay = 24 * 60 * 60 * 1000;

export function filterRevisionsByDate (revisions, start, end) {
    if (!start || !end) return revisions;
    const startValue = moment(start).valueOf();
    const endValue = moment(end).valueOf() + oneDay;

    return revisions.filter((revision) => {
        return revision.lastUpdatedAt >= startValue && revision.lastUpdatedAt < endValue;
    });
}

export function isDateBeforeDefaultStartDate (date, guide) {
    return date < getDefaultStartDate(guide);
}

export function getDefaultStartDate (guide) {
    const ninetyDays = oneDay * 90;
    const startDate = moment(guide.lastUpdatedAt - ninetyDays).format(DATE_FORMAT.iso);

    return moment(startDate).valueOf();
}

export function areRevisionsEqual (revision, otherRevision) {
    return (
        otherRevision.lastUpdatedAt === revision.lastUpdatedAt &&
        otherRevision.lastUpdatedByUser.username === revision.lastUpdatedByUser.username
    );
}

const GUIDE_AUDIT_PROP_PATH = Object.freeze({
    [GUIDE_AUDIT_PROPS_TO_CHECK.NAME]: 'name',
    [GUIDE_AUDIT_PROPS_TO_CHECK.LAUNCH_METHOD]: 'launchMethod',
    [GUIDE_AUDIT_PROPS_TO_CHECK.AUDIENCE]: 'audienceUiHint.filters[0].segmentId',
    [GUIDE_AUDIT_PROPS_TO_CHECK.REDISPLAY]: 'redisplay',
    [GUIDE_AUDIT_PROPS_TO_CHECK.DELIVERY_FREQUENCY]: 'recurrence',
    [GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION]: 'translationStates',
    [GUIDE_AUDIT_PROPS_TO_CHECK.SHOWS_AFTER]: 'showsAfter',
    [GUIDE_AUDIT_PROPS_TO_CHECK.EXPIRES_AFTER]: 'expiresAfter',
    [GUIDE_AUDIT_PROPS_TO_CHECK.STATE]: 'state',
    [GUIDE_AUDIT_PROPS_TO_CHECK.DESCRIPTION]: 'description',
    [GUIDE_AUDIT_PROPS_TO_CHECK.DEVICE_TYPE]: 'attributes.device.type',
    [GUIDE_AUDIT_PROPS_TO_CHECK.OVERRIDE_AUTO_THROTTLING]: 'attributes.overrideAutoThrottling',
    [GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG]: 'emailConfiguration',
    [GUIDE_AUDIT_PROPS_TO_CHECK.MOBILE_DISPLAY]: 'attributes.mobileDisplaySettings',
    [GUIDE_AUDIT_PROPS_TO_CHECK.SELECTOR]: 'attributes.embedConfig.selector',
    [GUIDE_AUDIT_PROPS_TO_CHECK.POSITION_IN_ELEMENT]: 'attributes.embedConfig.method'
});

export function getStatus (guide, checkOnLastUpdated = false) {
    const isPublicToo = isScheduled(guide, checkOnLastUpdated) && guide.state === 'public';
    const state = isPublicToo ? 'scheduled' : guide.state;

    return {
        label: capitalize(state),
        status: state
    };
}

export function isScheduled (guide, checkOnLastUpdated = false) {
    if (!guide?.showsAfter) return false;

    if (checkOnLastUpdated) {
        return guide.showsAfter > guide.lastUpdatedAt;
    }

    return guide.showsAfter > Date.now();
}

const SHOWS_AFTER_PREFIX = 'Start Date / Time:\n';
const EXPIRES_AFTER_PREFIX = 'Expiration Date / Time:\n';

export function getPropDefaultValue (prop) {
    switch (prop) {
        case GUIDE_AUDIT_PROPS_TO_CHECK.AUDIENCE:
            return 'Everyone';
        case GUIDE_AUDIT_PROPS_TO_CHECK.OVERRIDE_AUTO_THROTTLING:
            return false;
        case GUIDE_AUDIT_PROPS_TO_CHECK.DESCRIPTION:
        case GUIDE_AUDIT_PROPS_TO_CHECK.REDISPLAY:
        case GUIDE_AUDIT_PROPS_TO_CHECK.MOBILE_DISPLAY:
            return '--';
        case GUIDE_AUDIT_PROPS_TO_CHECK.EXPIRES_AFTER:
            return `${EXPIRES_AFTER_PREFIX}---`;
        case GUIDE_AUDIT_PROPS_TO_CHECK.SHOWS_AFTER:
            return `${SHOWS_AFTER_PREFIX}---`;
        case GUIDE_AUDIT_PROPS_TO_CHECK.DELIVERY_FREQUENCY:
            return 'One time';
        default:
            return '';
    }
}

export function launchMethodToStringVDS (guide, options = {}) {
    const launchMethod = get(guide, 'launchMethod', '');
    const isMobileApp = get(options, 'isMobileApp', false);
    const isConfirmation = get(guide, 'attributes.badge.isConfirmation', false);

    if (!launchMethod) return '';

    const launchMethodNames = launchMethod.split('-').map((method) => {
        switch (method) {
            case 'auto':
                if (isMobileApp) return 'App launch';

                return 'Automatic';
            case 'badge':
                if (isConfirmation) return 'Confirmation';

                return 'Badge';
            case 'launcher':
                return 'Resource Center';
            case 'page':
                return 'Page view';
            case 'feature':
                return 'Feature click';
            case 'track':
                return 'Track event';
            case 'dom':
                return 'Target element';
            default:
                return '';
        }
    });

    return launchMethodNames.join(', ');
}

export function getDeviceLabel (value, { isMobileApp }) {
    const mobileOnly = 'Mobile only';
    if (value && isMobileApp) return mobileOnly;

    switch (value) {
        case 'desktop':
        case 'Desktop':
            return 'Desktop & tablets only';
        case 'Mobile':
        case 'mobile':
            return mobileOnly;
        default:
            return 'All devices';
    }
}

export function getPropValue (revision, prop, options = {}) {
    if (!Object.values(GUIDE_AUDIT_PROPS_TO_CHECK).includes(prop)) return null;

    const isMobileApp = get(options, 'isMobileApp', false);
    if (GUIDE_AUDIT_PROPS_TO_CHECK.LAUNCH_METHOD === prop) return launchMethodToStringVDS(revision, { isMobileApp });
    if (GUIDE_AUDIT_PROPS_TO_CHECK.STATE === prop) {
        const { lastUpdatedAt, showsAfter } = revision;

        return {
            state: getStatus(revision, true)?.status,
            lastUpdatedAt,
            showsAfter
        };
    }

    const getThrottling = (isActive) => (isActive ? 'Has throttling enabled' : 'Ignores throttling');
    const activeTimezone = get(options, 'activeTimezone', 'America/New_York');
    const datePrefix = prop === GUIDE_AUDIT_PROPS_TO_CHECK.EXPIRES_AFTER ? EXPIRES_AFTER_PREFIX : SHOWS_AFTER_PREFIX;
    let value = get(revision, GUIDE_AUDIT_PROP_PATH[prop]);

    switch (prop) {
        case GUIDE_AUDIT_PROPS_TO_CHECK.DEVICE_TYPE:
            return getDeviceLabel(value, { isMobileApp });
        case GUIDE_AUDIT_PROPS_TO_CHECK.OVERRIDE_AUTO_THROTTLING:
            return getThrottling(value);
        case GUIDE_AUDIT_PROPS_TO_CHECK.EMAIL_CONFIG:
            return {
                senderName: get(value, 'senderName', '--'),
                subject: get(value, 'subject', '--'),
                emailMetadataPropertyName: get(value, 'emailMetadataPropertyName', '--')
            };
        case GUIDE_AUDIT_PROPS_TO_CHECK.LOCALIZATION:
            return getTranslationNames(value, options.languageMapping);
        case GUIDE_AUDIT_PROPS_TO_CHECK.EXPIRES_AFTER:
        case GUIDE_AUDIT_PROPS_TO_CHECK.SHOWS_AFTER:
            if (!isNumber(value)) {
                value = false;
                break;
            }

            return `${datePrefix}${convertToSubscriptionTimezone(activeTimezone, value).format(DATE_FORMAT.full)}`;
    }

    if (!value) return getPropDefaultValue(prop);

    if (isString(value)) {
        value = value.trim();
    }

    if (!value) return getPropDefaultValue(prop);

    return value;
}

export function getTranslationNames (guideTranslations, languageMapping) {
    const translationsWithNames = [];
    if (!guideTranslations) return translationsWithNames;

    for (const code of Object.keys(guideTranslations)) {
        translationsWithNames.push({
            code,
            name: languageMapping[code].name
        });
    }

    return translationsWithNames;
}

export function formatSelectedLocalizations (prev, selected) {
    const previousMap = keyBy(cloneDeep(prev), 'code');
    const added = [];
    for (const translation of selected) {
        if (previousMap[translation.code]) {
            delete previousMap[translation.code];
        } else {
            added.push(translation);
        }
    }

    const removed = Object.values(previousMap);

    const formattedSelected = {
        all: selected,
        ...(added.length > 0 ? { added } : {}),
        ...(removed.length > 0 ? { removed } : {})
    };

    return formattedSelected;
}

export const ONE_HOUR = 60 * 60 * 1000;

export const ONE_DAY = ONE_HOUR * 24;

export function formatRedisplay (prev, curr) {
    return [prev, curr].map((revision) => {
        if (revision === getPropDefaultValue(GUIDE_AUDIT_PROPS_TO_CHECK.REDISPLAY)) return revision;

        const rawInterval = revision.interval;
        const unit = revision.timeUnit;
        const formattedIntervalTime = rawInterval / (unit === 'Day' ? ONE_DAY : ONE_HOUR);
        const formattedUnit = formattedIntervalTime > 1 ? `${unit}s` : unit;
        const formattedIntervalString =
            formattedIntervalTime > 1
                ? `every ${formattedIntervalTime} ${formattedUnit.toLowerCase()}`
                : `every ${formattedUnit.toLowerCase()}`;
        const dismissalString =
            revision.maxDismissals > 0 ? `Expires after ${revision.maxDismissals} dismissals` : 'Never expires';

        return `Repeat ${formattedIntervalString}. ${dismissalString}.`;
    });
}

export function formatFrequency (prev, curr) {
    return [prev, curr].map((revision) => {
        if (revision === getPropDefaultValue(GUIDE_AUDIT_PROPS_TO_CHECK.DELIVERY_FREQUENCY)) return revision;

        const formattedInterval = revision / ONE_DAY;

        return formattedInterval > 1 ? `Every ${formattedInterval} days` : 'Every day';
    });
}

export function formatMobileDisplay (prev, curr) {
    return [prev, curr].map((revision) => {
        if (revision === getPropDefaultValue(GUIDE_AUDIT_PROPS_TO_CHECK.MOBILE_DISPLAY)) return revision;

        return `${revision.deviceType}, ${revision.orientation}`;
    });
}

export function mergeChanges (existingChange, additionalChange) {
    existingChange.selected = `${additionalChange.selected}\n${existingChange.selected}`;
    if (existingChange.previous) {
        existingChange.previous = `${additionalChange.previous}\n${existingChange.previous}`;
    }

    return existingChange;
}

export function mergeStepAndBuildingBlockChanges (stepChanges, bbChanges) {
    const changes = {};

    stepChanges.forEach((change) => {
        changes[change.key] = change;
    });

    bbChanges.forEach((change) => {
        const { key } = change;
        const existingChange = changes[key];

        if (!existingChange) {
            changes[change.key] = change;

            return;
        }

        changes[key] = mergeChanges(existingChange, change);
    });

    return Object.values(changes);
}
