<template>
    <div class="segment-summary">
        <div
            v-if="showName"
            class="segment-summary--header">
            <span class="segment-summary--title">
                Segment
            </span>
            <span class="segment-summary--header--name">
                {{ segmentIsEmpty ? 'Unknown' : segment.name }}
            </span>
        </div>
        <div class="segment-summary--rule-set">
            <span
                v-if="showLabel"
                class="segment-summary--title">
                Rules Summary
            </span>
            <div v-if="segmentIsEmpty">
                <pendo-alert type="warning">
                    This Segment used by this Guide was deleted. This Guide will not be displayed to any Visitors until
                    the Segment is changed.
                </pendo-alert>
            </div>
            <div v-if="!segmentIsEmpty">
                <div
                    v-for="(rule, index) in summary"
                    :key="`segment-rule-${index}`"
                    class="segment-summary--rule">
                    <div
                        v-for="(orRule, orIndex) in rule"
                        :key="`segment-rule-${index}-${orIndex}`"
                        class="segment-summary--or-rule">
                        <div class="segment-summary--or-rule--summary">
                            <pendo-icon
                                v-if="orRule.schema"
                                :type="getIconFromSchema(orRule.schema)"
                                class="segment-summary--or-rule--summary--icon"
                                size="12" />
                            <span>
                                {{ orRule.text }}
                            </span>
                        </div>
                        <div
                            v-if="orRule.schema === 'segment'"
                            class="nested-summary">
                            <segment-summary
                                v-bind="nestedProps"
                                :segment="getNestedSegmentById(orRule.id)" />
                        </div>
                        <div
                            v-if="orIndex < rule.length - 1"
                            class="segment-summary--or-rule--divider">
                            OR
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import { PendoIcon, PendoAlert } from '@pendo/components';
import { parseSegmentSummary } from '@/components/segment-builder/utils/summary';

export default {
    name: 'SegmentSummary',
    components: {
        PendoIcon,
        PendoAlert
    },
    props: {
        segment: {
            type: Object,
            required: false,
            default: null
        },
        showName: {
            type: Boolean,
            default: true
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        guidesMap: {
            type: Object,
            default: () => ({})
        },
        pagesMap: {
            type: Object,
            default: () => ({})
        },
        featuresMap: {
            type: Object,
            default: () => ({})
        },
        workflowsMap: {
            type: Object,
            default: () => ({})
        },
        segmentsMap: {
            type: Object,
            default: () => ({})
        },
        appsMap: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        nestedProps () {
            return {
                ...this.$props,
                segment: null,
                showName: false,
                showLabel: false
            };
        },
        segmentIsEmpty () {
            return isEmpty(this.segment);
        },
        summary () {
            return parseSegmentSummary({
                segment: cloneDeep(this.segment), // remove vuex ties
                guidesMap: this.guidesMap,
                pagesMap: this.pagesMap,
                featuresMap: this.featuresMap,
                workflowsMap: this.workflowsMap,
                segmentsMap: this.segmentsMap,
                appsMap: this.appsMap
            });
        }
    },
    methods: {
        getIconFromSchema (schema) {
            let icon = '';
            switch (schema) {
                case 'application':
                    icon = 'activity';
                    break;
                case 'poll':
                    icon = 'list';
                    break;
                case 'time':
                    icon = 'calendar';
                    break;
                case 'page':
                    icon = 'page';
                    break;
                case 'feature':
                    icon = 'feature';
                    break;
                case 'workflow':
                    icon = 'adopt-workflows';
                    break;
                case 'segment':
                    icon = 'pie-chart';
                    break;
                case 'guideElement':
                    icon = 'guideElement';
                    break;
                case 'string':
                default:
                    icon = 'user';
                    break;
            }

            return icon;
        },
        getNestedSegmentById (id) {
            return find(this.segmentsMap, { id }) || null;
        }
    }
};
</script>

<style lang="scss">
.segment-summary {
    &--header {
        margin-bottom: 1em;
    }

    &--title {
        font-weight: bold;
        display: block;
        margin-bottom: 0.5em;
    }

    &--rule {
        display: flex;
        flex-flow: column nowrap;

        &::after {
            content: 'AND';
            display: block;
            margin-top: 1em;
            margin-bottom: 1.2em;
            font-size: 0.875em;
        }

        &:last-of-type::after {
            content: '';
            display: none;
        }
    }

    &--or-rule {
        margin-bottom: 0.5em;

        &:last-of-type {
            margin-bottom: 0;
        }

        &--summary {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 0.5em;

            &--icon {
                margin-right: 0.5em;
            }
        }

        &--divider {
            font-size: 0.875em;
            line-height: normal;
        }
    }

    .nested-summary {
        margin-left: 20px;
    }
}
</style>
