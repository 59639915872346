<template>
    <div class="VueCarousel-navigation">
        <button
            type="button"
            aria-label="Previous page"
            :tabindex="canAdvanceBackward ? 0 : -1"
            class="VueCarousel-navigation-button VueCarousel-navigation-prev"
            :class="{ 'VueCarousel-navigation--disabled': !canAdvanceBackward }"
            @click.prevent="triggerPageAdvance('backward')">
            <pendo-icon type="chevron-left" />
        </button>
        <button
            type="button"
            aria-label="Next page"
            :tabindex="canAdvanceForward ? 0 : -1"
            class="VueCarousel-navigation-button VueCarousel-navigation-next"
            :class="{ 'VueCarousel-navigation--disabled': !canAdvanceForward }"
            @click.prevent="triggerPageAdvance('forward')">
            <pendo-icon type="chevron-right" />
        </button>
    </div>
</template>
<script>
import { PendoIcon } from '@pendo/components';

export default {
    name: 'Navigation',
    components: {
        PendoIcon
    },
    inject: ['carousel'],
    emits: ['navigationclick'],
    computed: {
        /**
         * @return {Boolean} Can the slider move forward?
         */
        canAdvanceForward () {
            return this.carousel.canAdvanceForward || false;
        },
        /**
         * @return {Boolean} Can the slider move backward?
         */
        canAdvanceBackward () {
            return this.carousel.canAdvanceBackward || false;
        }
    },
    methods: {
        /**
         * Trigger page change on +/- 1 depending on the direction
         * @param {"backward"} [direction]
         * @return {void}
         */
        triggerPageAdvance (direction) {
            /**
             * @event paginationclick
             * @type {string}
             */
            this.$emit('navigationclick', direction);
        }
    }
};
</script>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.VueCarousel-navigation-button {
    position: absolute;
    top: 50%;
    box-sizing: border-box;
    text-decoration: none;
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    outline: none;
    color: $gray-lighter-2;

    .pendo-icon__chevron-right,
    .pendo-icon__chevron-left {
        height: 32px;
    }
}

.VueCarousel-navigation-next {
    right: 0;
}

.VueCarousel-navigation-prev {
    left: 0;
}

.VueCarousel-navigation-button:focus {
    outline: 1px solid lightblue;
}

.VueCarousel-navigation--disabled {
    opacity: 0;
    cursor: default;
}
</style>
