<template>
    <div class="feature-rule">
        <!-- feature list picker -->
        <div
            :class="{ 'feature-undefined': rule.featureDeleted }"
            class="rule-editor--item">
            <pendo-multiselect
                :value="rule.feature"
                :allow-empty="false"
                :options="featureListOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="displayName"
                value-key="id"
                full-width
                placeholder="Select Feature"
                class="feature-rule--feature-select"
                @select="onFeatureSelect" />
            <rule-warning v-if="rule.featureDeleted">
                The original feature for this rule has been deleted
            </rule-warning>
        </div>
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            @select="handleFeatureOperatorSelection" />
        <pendo-multiselect
            :value="featureTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="featureTimeRangeOptions"
            :disabled="readOnly"
            class="feature-rule--feature-time-operator"
            @select="handleFeatureTimeSelection" />
        <number-input
            v-if="showNumberInputComponent"
            class="rule-editor--item complex-rule number-input--max-height"
            :labels="{ suffix: 'times' }"
            :value="rule.value"
            :min="1"
            @input="$emit('input', $event)" />
        <component
            :is="timeComponent.component"
            v-if="showTimeComponent"
            v-bind="timeComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import RuleWarning from '../rule-components/RuleWarning.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import NumberInput from '../rule-components/NumberInput.vue';
import {
    SEGMENT_OPERATORS,
    FEATURE_TIME_RANGE_CLICKED_OPTIONS,
    FEATURE_TIME_RANGE_LAST_CLICKED_OPTIONS,
    FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS
} from '@/components/segment-builder/constants/segments';
import { PendoMultiselect } from '@pendo/components';
import { getEntitiesByAppSelection } from '@/components/segment-builder/utils/utils';
import {
    getAbsoluteDateRangeConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import get from 'lodash/get';

export default {
    name: 'FeatureRule',
    components: {
        PendoMultiselect,
        AbsoluteDateRange,
        RelativeDateRange,
        RuleWarning,
        SingleDate,
        NumberInput
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        featureListOptions () {
            return getEntitiesByAppSelection(this.rule.app, this.computedData.features, this.config);
        },
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        },
        featureTimeRangeOptions () {
            switch (this.rule.operator.value) {
                case 'lastclicked':
                    return FEATURE_TIME_RANGE_LAST_CLICKED_OPTIONS;
                case 'notclicked':
                    return FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS;
                case 'clicked':
                default:
                    return FEATURE_TIME_RANGE_CLICKED_OPTIONS;
            }
        },
        showNumberInputComponent () {
            return (
                this.rule.operator.value === 'clicked' && (this.rule.time === 'atmost' || this.rule.time === 'atleast')
            );
        },
        showTimeComponent () {
            if (this.showNumberInputComponent) {
                return false;
            }

            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(rule.feature && operatorValue && rule.time && rule.time !== 'ever');
        },
        timeComponent () {
            if (['withinlast', '!withinlast'].includes(this.rule.time)) {
                return getRelativeDateRangeConfig(this.rule, 'value');
            }

            if (['between'].includes(this.rule.time)) {
                return getAbsoluteDateRangeConfig(this.rule);
            }

            if (['before', 'since'].includes(this.rule.time)) {
                return getSingleDateConfig(this.rule, 'value');
            }

            return;
        },
        showTimeSelector () {
            const { rule } = this;

            return !!rule.feature;
        }
    },
    methods: {
        featureTimeLabelObject (timeString) {
            return this.featureTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleFeatureOperatorSelection ($event) {
            const updates = [{ field: 'operator', value: $event }];

            // handle switching to operator where `at least / at most` not supported
            if (this.rule.time === 'atleast' || this.rule.time === 'atmost') {
                if ($event.value === 'notclicked') {
                    updates.push({ field: 'time', value: 'ever' });
                }

                if ($event.value === 'lastclicked') {
                    updates.push({ field: 'time', value: 'before' });
                }
            }

            this.$emit('input', updates);
        },
        handleFeatureTimeSelection ($event) {
            const { value } = $event;

            if (value === 'withinlast' || value === '!withinlast') {
                this.$emit('input', { field: 'value', value: { count: 30, granularity: 'days' } });
            } else if (value !== 'before' && value !== 'since') {
                this.$emit('input', { field: 'value', value: 1 });
            }

            this.$emit('input', { field: 'time', value });
        },
        onFeatureSelect (feature) {
            this.$emit('input', [
                { field: 'feature', value: feature },
                { field: 'featureDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.feature-rule {
    display: contents;

    .feature-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}

.number-input {
    &--max-height {
        height: 36px;
    }
}
</style>
