<template>
    <pendo-drawer
        class-name="feature-details-sidebar--drawer"
        :visible="visible"
        size="360px"
        push=".pendo-page"
        @close="closeSidebar">
        <template #header>
            <div class="feature-details-sidebar--header">
                <div class="feature-details-sidebar--header-top">
                    <editable-text-field
                        v-if="canEditFeatureType && mode === 'view'"
                        v-model="localFeature.name"
                        class="feature-details-sidebar--header-top-editable-content"
                        multiline
                        fit-content
                        @blur="updateDisplayName(localFeature.name)" />
                    <span
                        v-if="!canEditFeatureType || mode === 'edit'"
                        class="feature-details-sidebar--header-top-display-name">
                        {{ localFeature.name }}
                    </span>
                    <pendo-button
                        class="feature-details-sidebar--header-top-close-button"
                        theme="app"
                        type="tertiary"
                        icon="x"
                        icon-size="16"
                        @click="closeSidebar" />
                </div>
                <div class="feature-details-sidebar--header-bottom">
                    <pendo-tag
                        :class="[isInactiveFeature ? 'pendo-tag--inactive' : '']"
                        :label="`${featureType} Feature`"
                        type="filter"
                        round />
                    <div class="feature-details-sidebar--header-bottom-actions">
                        <div
                            v-if="canEditFeature && mode === 'view'"
                            v-pendo-tooltip="{
                                content: !canEditFeatureType && editTooltip,
                                arrow: true,
                                placement: 'bottom',
                                multiline: true
                            }">
                            <pendo-button
                                icon="edit"
                                type="tertiary"
                                :disabled="!canEditFeatureType"
                                @click="mode = 'edit'" />
                        </div>
                        <div
                            v-if="canDeleteFeature"
                            v-pendo-tooltip="{
                                content: !canDeleteFeatureType && deleteTooltip,
                                arrow: true,
                                placement: 'bottom',
                                multiline: true
                            }"
                            class="feature-details-sidebar--header-delete">
                            <pendo-button
                                icon="trash-2"
                                type="tertiary"
                                icon-size="16"
                                :disabled="!canDeleteFeatureType"
                                @click="$emit('delete-feature', localFeature)" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #body>
            <div class="feature-details-sidebar--details">
                <div class="feature-details-sidebar--detail">
                    <div class="feature-details-sidebar--detail-name">
                        Application
                    </div>
                    <pendo-app-display :apps="app" />
                </div>
                <!-- ######################    VIEW INCLUDE RULES -->
                <div v-if="mode === 'view'">
                    <div
                        v-if="!isMobileApp"
                        class="feature-details-sidebar--detail">
                        <div class="feature-details-sidebar--detail-name">
                            Rules
                        </div>
                        <div class="feature-details-sidebar--rules">
                            <div
                                v-for="(rule, key) in localFeature.rules"
                                :key="key"
                                class="feature-details-sidebar--rule-item">
                                <div class="feature-details-sidebar--rule">
                                    {{ rule.rule }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="mode === 'edit'">
                    <pendo-form
                        v-model="isFormValid"
                        :model="editForm">
                        <!-- ######################    EDIT INCLUDE RULES -->
                        <div class="feature-details-sidebar--detail">
                            <repeating-component
                                :repeating-data="editForm.rules"
                                unit="feature rule"
                                @addItem="addRule"
                                @removeItem="removeRule">
                                <template #header>
                                    <div class="feature-details-sidebar--detail-name">
                                        Rules
                                    </div>
                                </template>
                                <template #default="{ item }">
                                    <pendo-form-item
                                        class="feature-details-sidebar--edit-form-input"
                                        prop="rules"
                                        :rules="[
                                            {
                                                required: true,
                                                trigger: ['blur', 'change'],
                                                validator: validateRule(item.rule)
                                            }
                                        ]">
                                        <pendo-input
                                            :value="item.rule"
                                            :autofocus="true"
                                            type="textarea"
                                            :rows="1"
                                            :debounce="500"
                                            class="feature-edit-form-rule-input"
                                            @input="updateRule(item, $event)" />
                                    </pendo-form-item>
                                </template>
                            </repeating-component>
                        </div>
                    </pendo-form>
                </div>
                <div class="feature-details-sidebar--detail">
                    <div class="feature-details-sidebar--detail-name">
                        Page Name
                    </div>
                    <div>
                        {{ pageName }}
                    </div>
                </div>
                <div class="feature-details-sidebar--detail">
                    <div class="feature-details-sidebar--detail-name">
                        Created By
                    </div>
                    <div>
                        <div>{{ localFeature.createdByUser.first }} {{ localFeature.createdByUser.last }}</div>
                        <div v-if="isCustomFeature">
                            ({{ localFeature.createdByUser.username }})
                        </div>
                        <div class="feature-details-sidebar--detail-time">
                            {{ formattedTime(localFeature.createdAt) }}
                        </div>
                    </div>
                </div>
                <div class="feature-details-sidebar--detail">
                    <div class="feature-details-sidebar--detail-name">
                        Last Updated By
                    </div>
                    <div>
                        <div>{{ localFeature.lastUpdatedByUser.first }} {{ localFeature.lastUpdatedByUser.last }}</div>
                        <div v-if="isCustomFeature">
                            ({{ localFeature.lastUpdatedByUser.username }})
                        </div>
                        <div class="feature-details-sidebar--detail-time">
                            {{ formattedTime(localFeature.lastUpdatedAt) }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div v-if="mode === 'edit'">
                <pendo-grid
                    :columns="['max-content', 'max-content']"
                    justify-content="end"
                    gap="8"
                    class="feature-details-sidebar--footer">
                    <pendo-button
                        label="Cancel"
                        theme="app"
                        type="tertiary"
                        class="cancel-button"
                        @click="resetFeatureEditAndView" />
                    <pendo-button
                        label="Save"
                        :disabled="isSaveDisabled"
                        :loading="isSaving"
                        theme="app"
                        type="primary"
                        class="save-button"
                        @click="onSaveClick" />
                </pendo-grid>
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import {
    PendoAppDisplay,
    PendoButton,
    PendoDrawer,
    PendoTag,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoGrid,
    PendoTooltip
} from '@pendo/components';
import { http } from '@pendo/http';
import { canAppEditCountable, canAppRemoveCountable } from '@/utils/countable-permissions';
import {
    removeRepeatingKeys,
    RepeatingComponent,
    countableType,
    isCustomCountable,
    isInactiveCountable,
    canEditCountable,
    canDeleteCountable
} from '@pendo/tagging';
import { convertToSubscriptionTimezone } from '@/utils/moment.js';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import EditableTextField from '@/components/common/EditableTextField.vue';
import { v4 as uuid } from 'uuid';
import { MOBILE_PLATFORMS } from '@/stateless-components/utils/apps';
import get from 'lodash/get';

export default {
    name: 'FeatureDetailsSidebar',
    components: {
        PendoDrawer,
        PendoButton,
        PendoTag,
        PendoAppDisplay,
        EditableTextField,
        RepeatingComponent,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoGrid
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        featureId: {
            type: String,
            required: true
        }
    },
    emits: ['delete-feature', 'close-sidebar'],
    data () {
        return {
            localFeature: {},
            hasChanges: false,
            isSaving: false,
            isFormValid: false,
            mode: 'view',
            editForm: {
                rules: []
            }
        };
    },
    computed: {
        ...mapGetters({
            isAdoptEndUser: 'subscriptions/activeIsTrainingSubscription',
            getFeatureById: 'features/featureById',
            getAppById: 'apps/appById',
            getPageById: 'pages/pageById',
            getActiveTimezone: 'subscriptions/getTimezone'
        }),
        app () {
            return this.getAppById(this.localFeature.appId);
        },
        isMobileApp () {
            const platform = get(this.app, 'platform', '');

            return MOBILE_PLATFORMS.includes(platform);
        },
        feature () {
            const feature = this.getFeatureById(this.featureId);
            const rules = feature.elementPathRules?.map((rule) => ({ rule }));

            return { ...feature, rules };
        },
        pageName () {
            return this.localFeature.pageId ? this.getPageById(this.localFeature.pageId)?.name : 'All Pages';
        },
        canEditFeature () {
            return canAppEditCountable(this.localFeature.appId, 'feature');
        },
        canEditFeatureType () {
            return this.canEditFeature && canEditCountable(this.localFeature, this.isAdoptEndUser);
        },
        canDeleteFeature () {
            return canAppRemoveCountable(this.localFeature.appId, 'feature');
        },
        canDeleteFeatureType () {
            return this.canDeleteFeature && canDeleteCountable(this.localFeature, this.isAdoptEndUser);
        },
        featureType () {
            return countableType(this.localFeature);
        },
        isCustomFeature () {
            return isCustomCountable(this.localFeature);
        },
        isInactiveFeature () {
            return isInactiveCountable(this.localFeature);
        },
        isSaveDisabled () {
            return !this.isFormValid || this.editForm.rules.some((rule) => !rule.rule);
        },
        deleteTooltip () {
            switch (this.featureType) {
                case 'Inherited':
                    return 'Inherited Features cannot be deleted';
                case 'Inactive':
                    return this.isAdoptEndUser ? 'Inactive Features cannot be deleted' : null;
                default:
                    return null;
            }
        },
        editTooltip () {
            switch (this.featureType) {
                case 'Inherited':
                    return 'Inherited Features cannot be edited';
                case 'Inactive':
                    return 'Inactive Features cannot be edited';
                default:
                    return null;
            }
        }
    },
    watch: {
        feature: {
            handler () {
                this.resetFeatureEditAndView();
            },
            immediate: true
        }
    },
    beforeUnmount () {
        this.$emit('close-sidebar');
    },
    methods: {
        ...mapActions({
            updateCustomFeature: 'features/updateCustomFeature'
        }),
        closeSidebar () {
            this.$emit('close-sidebar');
        },
        formattedTime (unixTime) {
            return convertToSubscriptionTimezone(this.getActiveTimezone, unixTime).format('MMM D, YYYY h:mm A z');
        },
        async updateDisplayName (newDisplayName) {
            if (this.feature.name === newDisplayName) {
                return;
            }
            if (!newDisplayName) {
                this.localFeature.name = this.feature.name;

                return;
            }

            const customFeature = {
                ...this.localFeature,
                name: newDisplayName
            };
            this.isSaving = true;
            await this.updateCustomFeature({ customFeature });
            this.isSaving = false;
        },
        validateRule (includeRule) {
            return async (rule, value, callback) => {
                includeRule = includeRule || '';
                if (includeRule === '') {
                    callback(new Error('Feature rules cannot be empty'));

                    return;
                }
                try {
                    await http.post('/api/s/_SID_/helper/cssruleparse', { rule: includeRule });

                    callback();
                } catch (error) {
                    callback(new Error('Feature rule is invalid'));

                    return;
                }
                callback();
            };
        },
        updateRule (item, $event) {
            item.rule = $event.trim();
        },
        addRule () {
            this.editForm.rules.push({ rule: '', parsedRule: '', repeatingKey: uuid() });
        },
        removeRule (index) {
            this.editForm.rules.splice(index, 1);
        },
        resetFeatureEditAndView () {
            this.mode = 'view';
            this.localFeature = cloneDeep(this.feature);
            const rules = this.localFeature.rules?.map((rule) => ({ ...rule, repeatingKey: uuid() }));
            this.editForm = { rules };
        },
        async onSaveClick () {
            if (!this.isFormValid) {
                return;
            }
            this.isSaving = true;
            const { rules } = this.editForm;
            const transformedRules = removeRepeatingKeys(rules);
            // save changes to local feature to prevent UI flashing on update
            this.localFeature = { ...this.localFeature, rules: transformedRules };

            const customFeature = {
                ...this.localFeature,
                elementPathRules: transformedRules.map((rule) => rule.rule)
            };
            await this.updateCustomFeature({ customFeature });
            this.isSaving = false;
            this.mode = 'view';
        }
    }
};
</script>

<style lang="scss">
.feature-details-sidebar {
    &--drawer {
        .pendo-drawer__header {
            padding-left: 22px;
            padding-right: 22px;
        }

        .pendo-drawer__content {
            grid-template-rows: [header] minmax(68px, max-content) [body] auto [footer] minmax(68px, max-content) !important; /* stylelint-disable-line */
        }

        .pendo-drawer__footer {
            padding: 16px;
            align-items: center;
            border-top: 1px solid $gray-lighter-5;
            width: 100%;
            background-color: $white;
            border-radius: 0px 0px 2px 2px;
        }

        .pendo-drawer__footer:empty {
            border-top: 0px solid;
        }
    }

    &--header {
        max-width: 100%;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 5px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        &-top {
            display: flex;
            margin-top: 14px;
            overflow: inherit;
            justify-content: space-between;

            .feature-details-sidebar--header-top-editable-content {
                display: flex;
                overflow: inherit;
                margin-top: 5px;
                width: 275px;
            }

            &-display-name {
                overflow: inherit;
                font-size: 22px;
                font-weight: 600;
                margin-top: 5px;
            }

            &-editable-content {
                font-size: 22px;
                font-weight: 600;
            }
        }

        &-bottom {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            &-actions {
                display: flex;
                gap: 8px;
            }

            .pendo-tag--inactive {
                border-color: $color-red-70;
            }
        }
    }

    &--details {
        padding: 20px;
        border-top: 1px solid $gray-lighter-6;
    }

    &--detail {
        margin-bottom: 35px;
        font-size: 14px;
    }

    &--detail-name {
        font-weight: 600;
        margin-bottom: 5px;
    }

    &--rules {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    &--rule {
        word-break: break-all;
        overflow-wrap: break-word;
    }

    &--rule-item {
        padding: 11px;
        background-color: $color-gray-30;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    &--detail-time {
        margin-top: 5px;
        color: $gray-lighter-2;
    }

    &--edit-form-input {
        width: 100%;
    }
}
</style>
