<template>
    <pendo-input-number
        class="number-input"
        :class="`${field}-number-input`"
        :value="value"
        :labels="labels"
        :disabled="readOnly"
        :min="min"
        :max="max"
        @change="onNumberChange" />
</template>

<script>
import { PendoInputNumber } from '@pendo/components';
import isNumber from 'lodash/isNumber';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'NumberInput',
    components: {
        PendoInputNumber
    },
    mixins: [ReadOnlyMixin],
    props: {
        // eslint-disable-next-line vue/require-default-prop
        value: {
            type: null
        },
        field: {
            type: String,
            default: 'value'
        },
        labels: {
            type: Object,
            default: () => ({})
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: Infinity
        }
    },
    created () {
        const value = isNumber(this.value) ? this.value : 1;
        this.onNumberChange(value);
    },
    methods: {
        onNumberChange (value) {
            if (value == null) {
                value = 0;
            }
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>
