<template>
    <div class="relative-date-range">
        <pendo-input-number
            class="count-input"
            :value="value.count"
            :min="0"
            :disabled="readOnly"
            width="65px"
            @change="onCountChange" />
        <pendo-multiselect
            class="granularity-select"
            :value="value.granularity"
            :options="granularityOptions"
            preselect-first
            :min-trigger-width="90"
            :max-trigger-width="90"
            :min-menu-width="90"
            :max-menu-width="90"
            :disabled="readOnly"
            @select="onGranularitySelect" />
    </div>
</template>

<script>
import { PendoInputNumber, PendoMultiselect } from '@pendo/components';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'RelativeDateRange',
    components: {
        PendoInputNumber,
        PendoMultiselect
    },
    mixins: [ReadOnlyMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data () {
        return {
            granularityOptions: ['days', 'weeks', 'months']
        };
    },
    computed: {
        count () {
            return Number(this.value.count);
        }
    },
    created () {
        const count = Number.isNaN(this.count) ? 30 : this.count;
        this.onCountChange(count);
    },
    methods: {
        onCountChange (count) {
            if (count == null) {
                count = 0;
            }
            this.$emit('input', { field: 'count', value: count });
        },
        onGranularitySelect (option) {
            this.$emit('input', { field: 'granularity', value: option });
        }
    }
};
</script>

<style lang="scss">
.relative-date-range {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: auto 90px;
}
</style>
