<template>
    <div>
        <div
            v-if="languages.length"
            class="import-export-lang-sub-text">
            Note: XLIFF 1.2 format only
        </div>
        <div
            v-if="languages.length"
            class="import-container">
            <language-import
                v-for="language in languages"
                :key="language.code"
                :language="language"
                :guide-id="guideId"
                :guide-name="guideName"
                :is-resource-center="isResourceCenter"
                @openClearStringsModal="toggleClearStringsModal"
                @editTranslationInApp="editTranslationInApp" />
        </div>
        <div
            v-if="languages.length < 1"
            class="translated-empty-state">
            <h5>{{ emptyStateHeading }}</h5>
            <p v-if="isShowingTranslatedLanguages && emptyStateSubtext">
                {{ emptyStateSubtext }}
            </p>
        </div>
        <clear-strings-modal
            v-if="clearStringsData"
            :show-modal="isClearStringsModalVisible"
            :lang-data="clearStringsData"
            :guide-id="guideId"
            :is-resource-center="isResourceCenter"
            @closeClearStringsModal="toggleClearStringsModal" />
    </div>
</template>
<script>
import LanguageImport from '@/components/guides/settings/localization/LanguageImport.vue';
import ClearStringsModal from '@/components/guides/settings/localization/ClearStringsModal.vue';

export default {
    name: 'LanguageList',
    components: {
        LanguageImport,
        ClearStringsModal
    },
    props: {
        guideId: {
            type: String,
            required: true
        },
        guideName: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            default: () => []
        },
        emptyStateHeading: {
            type: String,
            required: true
        },
        emptyStateSubtext: {
            type: String,
            default: () => null
        },
        isShowingTranslatedLanguages: {
            type: Boolean,
            default: () => false
        },
        isResourceCenter: {
            type: Boolean,
            required: true
        }
    },
    emits: ['editTranslationInApp'],
    data () {
        return {
            isClearStringsModalVisible: false,
            clearStringsData: null
        };
    },
    methods: {
        toggleClearStringsModal (isModalVisible, langData = null) {
            if (!this.isShowingTranslatedLanguages) return;
            this.clearStringsData = langData;
            this.isClearStringsModalVisible = isModalVisible;
        },
        editTranslationInApp (config) {
            this.$emit('editTranslationInApp', config);
        }
    }
};
</script>

<style lang="scss">
.import-container {
    padding: 0 16px;
}

.translated-empty-state,
.all-translated-state {
    text-align: center;

    h5,
    p {
        font-weight: 300;
        color: $gray-lighter-2;
    }

    &--row {
        &--header {
            margin-bottom: 16px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        &--body {
            display: grid;
            grid-gap: 16px;
        }
    }

    &--row:not(:last-child) {
        border-bottom: 1px solid $gray-lighter-5;
    }
}

.import-export {
    &-lang-sub-text,
    &-lang-code {
        font-size: 12px;
        color: $gray-lighter-2;
        font-weight: 300;
        display: inline-flex;
        margin-right: 8px;
    }

    &-lang-text {
        display: flex;
        flex-direction: column;
    }

    &-lang-name {
        font-size: 14px;
        font-weight: 700;
    }

    &-button {
        min-width: 80px;
    }
}
</style>
