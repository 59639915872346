import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import {
    getTimeSeriesForCustomRange,
    getTimeSeriesForFixedRange,
    fixedDateRanges,
    convertValueToDateRangeConfig
} from '@/utils/time-series';
import { isDefaultSegment } from '@/utils/segments';
import { sendPendoTrackEvent } from '@/utils/utils';
import { buildPathSpec } from '@/aggregations/paths';
import { identifiedState } from './aggregations';
import store from '@/state/store';

export class PathError extends Error {
    constructor ({ message = 'We were unable to run your path.', retriable = true } = {}) {
        super(message);
        this.message = message;
        this.retriable = retriable;
    }
}

export function getDefaultUnsavedPath (segmentFlags = {}) {
    const defaultTimeSeries = fixedDateRanges().find((dateRange) => dateRange.id === 'last30days');

    return {
        name: 'New Path',
        shared: true,
        type: 'Path',
        definition: {
            config: {
                maxInterval: 3600,
                predecessors: false,
                omitPages: false,
                features: true,
                collapseDups: true,
                uniqueVisitors: false,
                maxLength: 10,
                segmentId: 'everyone',
                acrossApps: false,
                guideEvents: segmentFlags.hasGuidesInPathSegmentFlag,
                trackEvents: segmentFlags.hasAdoptTrackEventSegmentFlag
            },
            minimum: 0,
            timeSeries: defaultTimeSeries.value,
            history: []
        }
    };
}

export function generatePathTimeSeries (dateRange) {
    const type = startCase(dateRange.id);

    return {
        ...dateRange.value,
        type
    };
}

export function convertEngageTimeSeriesTypeToId (dateRange) {
    const id = camelCase(dateRange.type).toLowerCase();

    return {
        ...dateRange,
        id
    };
}

export function buildPathAggregationTimeSeries (timeSeries) {
    const fullDateRange = convertValueToDateRangeConfig({ value: { ...timeSeries } }, store.getters['apps/firstVisit']);
    if (fullDateRange.id === 'custom') {
        return {
            ...getTimeSeriesForCustomRange(fullDateRange),
            period: 'dayRange'
        };
    }

    return {
        ...getTimeSeriesForFixedRange(fullDateRange),
        period: 'dayRange'
    };
}

export function buildPathAggregationParams (path) {
    const { definition } = path;
    const { config } = definition;
    const timeSeries = buildPathAggregationTimeSeries(definition.timeSeries);

    return {
        config: {
            blacklist: 'apply',
            includeAnonymous: identifiedState(config.segmentId),
            ...config,
            segmentId: isDefaultSegment(config.segmentId) ? null : config.segmentId
        },
        timeSeries
    };
}

export function compilePathAggregation (path) {
    const params = buildPathAggregationParams(path);

    return {
        pipeline: buildPathSpec(params)
    };
}

export const progressCircleColors = {
    center: { fill: '#ffffff', stroke: '#ffffff' },
    default: { fill: '#ffffff', stroke: '#ffffff' },
    progress: { fill: '#128297', stroke: '#128297' },
    complete: { fill: '#128297', stroke: '#128297' }
};

export function trackPathReport ({ pathResource, location = 'report' }) {
    const { definition, id } = pathResource;
    const { config, timeSeries } = definition;
    const {
        acrossApps,
        collapseDups,
        features,
        maxLength,
        omitPages,
        pageId,
        featureId,
        trackTypeId,
        guideId,
        predecessors,
        segmentId,
        trackEvents,
        uniqueVisitors,
        guideEvents
    } = config;

    let anchorEventType;
    if (pageId) anchorEventType = 'page';
    if (featureId) anchorEventType = 'feature';
    if (trackTypeId) anchorEventType = 'track event';
    if (guideId) anchorEventType = 'guide';

    const includedEventTypes = [];
    if (!omitPages) includedEventTypes.push('pages');
    if (features) includedEventTypes.push('features');
    if (trackEvents) includedEventTypes.push('track events');
    if (guideEvents) includedEventTypes.push('guides');

    const pathTrackEventData = {
        reportId: id,
        location,
        dateRange: timeSeries.type,
        segment: segmentId === 'everyone' ? 'default' : 'custom',
        showPaths: predecessors ? 'leadingTo' : 'startingFrom',
        anchorEventType,
        across_apps: acrossApps,
        collapse_repeating_steps: collapseDups,
        remove_duplicate_visitor_paths: uniqueVisitors,
        maximum_path_length: maxLength,
        pages: !omitPages,
        features,
        trackEvents,
        guides: guideEvents,
        included_eventTypes: includedEventTypes
    };

    sendPendoTrackEvent({
        trackEventKey: 'runPathReport',
        properties: pathTrackEventData,
        adoptSpecific: false
    });
}
