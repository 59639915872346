<template>
    <div
        class="workflow-visitors-table"
        data-cy="workflow-visitors-table">
        <pendo-table
            row-key="metadata"
            manage-columns
            loading-text="Data for this report is running. This could take a few minutes..."
            resizable
            csv-download
            :columns="columns"
            :data="visitorTableAggData"
            :loading="loading"
            :filters="filters"
            :max-height="500"
            :default-sort="sort"
            :title="csvTitle"
            @sort-change="handleSortChange"
            @column-change="handleColumnChange"
            @column-resize="handleColumnResize">
            <template #headerLeft>
                <div class="workflow-visitors-table--table-header-left">
                    <div class="workflow-visitors-table-list--table-title">
                        {{ tableHeaderText }}
                    </div>
                    <pendo-multiselect
                        v-if="hasVisitorMetadata"
                        :placeholder="visitorMetadataSelectedOption.displayName"
                        :options="visitorMetadataOptions"
                        label-key="displayName"
                        class="metadata-dropdown"
                        @select="onVisitorMetadataSelected">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
                <pendo-input
                    v-model="searchValue"
                    placeholder="Search"
                    class="workflow-visitors-table--search-input">
                    <template #prefix>
                        <pendo-icon
                            type="search"
                            stroke="#2a2c35"
                            size="16" />
                    </template>
                    <template #suffix>
                        <slot name="suffix" />
                    </template>
                </pendo-input>
            </template>
            <template #headerActions>
                <slot name="addToDashboard" />
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { PendoDataSourceTrigger, PendoMultiselect, PendoTable, PendoInput, PendoIcon } from '@pendo/components';

import { rowFormatter } from '@/utils/table-formatters';
import {
    mergeDefaultSortWithUserSettings,
    mergeDefaultColumnsWithUserSettings
} from '@/stateless-components/utils/table-user-settings';
import {
    onColumnResize,
    onColumnChange,
    onSortChange
} from '@/stateless-components/utils/pendo-table-user-settings-helper.js';

import get from 'lodash/get';

export default {
    name: 'WorkflowVisitorsTable',
    components: {
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoTable,
        PendoIcon,
        PendoInput
    },
    props: {
        timezone: {
            type: String,
            default: ''
        },
        visitorTableAggData: {
            type: Array,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        visitorMetadataOptions: {
            type: Array,
            required: true
        },
        visitorMetadataSelectedOption: {
            type: Object,
            default: null
        },
        tableUserSettings: {
            type: Object,
            default: null
        },
        isRecurring: {
            type: Boolean,
            default: false
        },
        workflowName: {
            type: String,
            default: ''
        }
    },
    emits: ['tableSettingsUpdate', 'visitorMetadataSelected'],
    data () {
        return {
            defaultSort: { prop: 'totalVisitors', order: 'descending' },
            runSubscription: null,
            searchValue: '',
            sharedColumns: [
                {
                    prop: 'metadata',
                    label: 'Group',
                    fixed: true,
                    schema: 'string'
                },
                {
                    prop: 'totalVisitors',
                    label: 'Total active visitors',
                    schema: 'string'
                },
                {
                    prop: 'completeVisitors',
                    label: 'Completed visitors',
                    schema: 'string'
                },
                {
                    prop: 'incompleteVisitors',
                    label: 'Incomplete visitors',
                    schema: 'string'
                },
                {
                    prop: 'notStartedVisitors',
                    label: 'Not started visitors',
                    schema: 'string'
                },
                {
                    prop: 'avgTimeToCompletion',
                    label: 'Average time to complete per visitor',
                    schema: 'prettyTime'
                }
            ],
            recurringOnlyColumns: [
                {
                    prop: 'avgNumberCompleteAttempts',
                    label: 'Average number of times completed per visitor',
                    schema: 'integer'
                },
                {
                    prop: 'avgNumberIncompleteAttempts',
                    label: 'Average number of times not completed per visitor',
                    schema: 'integer'
                }
            ]
        };
    },
    computed: {
        nonRecurringWorkflowAvailableColumns () {
            return this.sharedColumns;
        },
        recurringWorkflowAvailableColumns () {
            return this.sharedColumns.concat(this.recurringOnlyColumns);
        },
        defaultColumns () {
            let columnConfig = this.nonRecurringWorkflowAvailableColumns;

            if (this.isRecurring) {
                columnConfig = this.recurringWorkflowAvailableColumns;
            }

            return columnConfig.map((column) => {
                const prop = get(column, 'prop');
                if (prop === 'avgTimeToCompletion') {
                    return {
                        ...column,
                        formatter: (row) => rowFormatter(row, column, this.timezone),
                        sortable: true,
                        sortBy: 'avgTimeToCompletion',
                        visible: true
                    };
                }

                return {
                    ...column,
                    formatter: (row) => rowFormatter(row, column, this.timezone),
                    sortable: true,
                    visible: true
                };
            });
        },
        columns () {
            return mergeDefaultColumnsWithUserSettings(this.defaultColumns, this.tableUserSettings);
        },
        sort () {
            return mergeDefaultSortWithUserSettings(this.defaultSort, this.tableUserSettings);
        },
        filters () {
            return [
                {
                    prop: ['metadata'],
                    value: this.searchValue
                }
            ];
        },
        hasVisitorMetadata () {
            return this.visitorMetadataOptions.length > 0;
        },
        tableHeaderText () {
            if (this.loading) return 'Visitor completion by';

            return `Visitor completion (${this.totalVisitors} active visitor${this.totalVisitors !== 1 ? 's' : ''}) by`;
        },
        csvTitle () {
            return `${this.workflowName} workflow visitors by ${get(
                this.visitorMetadataSelectedOption,
                'displayName',
                'group'
            )}`;
        },
        totalVisitors () {
            if (!this.visitorTableAggData) return 0;

            return this.visitorTableAggData.reduce((total, row) => {
                return total + row.totalVisitors;
            }, 0);
        }
    },
    methods: {
        handleSortChange ($event) {
            const newSettings = onSortChange($event, this.tableUserSettings);
            this.handleTableSettingsUpdate(newSettings);
        },
        handleColumnChange ($event) {
            const newSettings = onColumnChange($event, this.tableUserSettings);
            this.handleTableSettingsUpdate(newSettings);
        },
        handleColumnResize ($event) {
            const newSettings = onColumnResize($event, this.tableUserSettings);
            this.handleTableSettingsUpdate(newSettings);
        },
        handleTableSettingsUpdate (newSettings) {
            this.$emit('tableSettingsUpdate', newSettings);
        },
        onVisitorMetadataSelected (metadata) {
            this.$emit('visitorMetadataSelected', metadata);
        }
    }
};
</script>

<style lang="scss">
.workflow-visitors-table {
    .pendo-table__cell {
        display: flex;
    }

    &--search-input {
        margin-left: auto;
        max-width: 300px;
        min-width: 300px;
    }

    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }
}
</style>
