<template>
    <pendo-modal
        :visible="visible"
        height="auto"
        :title="title"
        @close="closeModal"
        @closed="resetModal">
        <pendo-form
            ref="form"
            :model="model">
            <launch-preview-modal-body
                :launched="launched"
                :error="error"
                :url="model.url"
                :is-resource-center="isResourceCenter"
                :show-advanced-settings="showAdvancedSettings"
                @updateUrl="updateUrl"
                @updatePreviewOptions="updatePreviewOptions" />
        </pendo-form>
        <template #footer>
            <launch-preview-modal-footer
                :launched="launched"
                :url="model.url"
                :snippet-installed="true"
                @close="closeModal"
                @launchPreview="validateForm" />
        </template>
    </pendo-modal>
</template>

<script>
import LaunchPreviewModalBody from './LaunchPreviewModalBody';
import LaunchPreviewModalFooter from './LaunchPreviewModalFooter';
import { PendoForm, PendoModal } from '@pendo/components';
import { launchPreview } from '@/stateless-components/utils/preview';
import get from 'lodash/get';

export default {
    name: 'LaunchPreviewModal',
    components: {
        LaunchPreviewModalBody,
        LaunchPreviewModalFooter,
        PendoForm,
        PendoModal
    },
    props: {
        launchUrl: {
            type: String,
            default: ''
        },
        guide: {
            type: Object,
            default: () => ({})
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        },
        showAdvancedSettings: {
            type: Boolean,
            default: false
        },
        previewConfig: {
            type: Object,
            default: () => ({})
        },
        visible: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Preview Guide'
        }
    },
    emits: ['close', 'updateDesignerUrl'],
    data () {
        return {
            model: {
                url: this.launchUrl
            },
            selectedPreviewOptions: {
                screenType: 'newTab',
                width: 350,
                height: 800
            },
            launched: false,
            error: null
        };
    },
    computed: {
        appId () {
            return get(this.guide, 'appId');
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        resetModal () {
            this.launched = false;
            this.error = false;
        },
        updateUrl (url) {
            this.model.url = url;
        },
        updatePreviewOptions (event) {
            const { selectedWindowOption, height, width } = event;
            this.selectedPreviewOptions = {
                screenType: selectedWindowOption,
                height,
                width
            };
        },
        async launchPreview () {
            this.$emit('updateDesignerUrl', { url: this.model.url, appId: this.appId });

            try {
                this.error = false;
                this.launched = true;
                await launchPreview(this.model.url, this.previewConfig, this.selectedPreviewOptions);
            } catch (error) {
                this.error = true;
            }
        },
        validateForm () {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.launchPreview();
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.launch-preview-modal--message {
    margin-bottom: 16px;
}
</style>
