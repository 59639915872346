<template>
    <div data-cy="cross-app-alert">
        <pendo-alert
            v-if="isInfoType"
            :type="alertType"
            closable>
            <slot v-if="!guideToEdit">
                All selected guides will be set to Draft status. If needed, you can clone a guide before selecting it to
                retain its current status.
            </slot>
            <slot v-if="guideToEdit">
                Any newly added guides will be set to Draft status.
            </slot>
        </pendo-alert>
        <pendo-alert
            v-if="!isInfoType"
            :type="alertType"
            :title="errorConfig.title">
            {{ errorConfig.description }}
            <ul>
                <li
                    v-for="app in warningApps"
                    :key="app.id">
                    <a
                        :href="`${engageURL()}/s/${activeSubId}/admin/app/${app.id}?tab=details`"
                        target="_blank">
                        {{ `${app.name} settings` }}
                    </a>
                </li>
            </ul>
        </pendo-alert>
    </div>
</template>

<script>
import Vue from 'vue';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { PendoAlert } from '@pendo/components';
import { engageURL } from '@/stateless-components/utils/environment';

const STORAGE_DISABLED_APP_IDS = 'storageDisabledApps';
const EMPTY_COOKIE_DOMAIN_APP_IDS = 'emptyCookieDomainApps';

export default {
    name: 'CrossAppAlert',
    components: {
        PendoAlert
    },
    props: {
        guideToEdit: {
            type: Object,
            default: null
        },
        guideAppsList: {
            type: Array,
            default: () => []
        },
        activeSubId: {
            type: Number,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            warningsMap: {},
            allCookieDomains: [],
            engageURL
        };
    },
    computed: {
        alertType () {
            if (this.isAnyAppNotWeb) return 'info';

            return !isEmpty(this.warningTypes) || this.hasMismatchedCookieDomains ? 'error' : 'info';
        },
        isInfoType () {
            return this.alertType === 'info';
        },
        isAnyAppNotWeb () {
            return this.guideAppsList.some((app) => app.platform !== 'web');
        },
        warningTypes () {
            return Object.keys(this.warningsMap);
        },
        isAnyCookieDomainEmpty () {
            return (
                Object.hasOwn(this.warningsMap, EMPTY_COOKIE_DOMAIN_APP_IDS) &&
                this.warningsMap[EMPTY_COOKIE_DOMAIN_APP_IDS].length > 0
            );
        },
        isStorageDisabledForAnyApp () {
            return (
                Object.hasOwn(this.warningsMap, STORAGE_DISABLED_APP_IDS) &&
                this.warningsMap[STORAGE_DISABLED_APP_IDS].length > 0
            );
        },
        warningApps () {
            if (!this.isAdmin) return [];

            if (this.hasMismatchedCookieDomains) {
                return this.guideAppsList.map((app) => {
                    const { id, displayName, name } = app;

                    return {
                        id,
                        name: displayName || name
                    };
                });
            }

            const allWarningApps = Object.values(this.warningsMap).flat();

            return uniqBy(allWarningApps, 'id');
        },
        errorConfig () {
            if (this.isInfoType) return;

            if (!this.isAdmin) {
                return {
                    description:
                        'An admin will need to enable cross-app agent cookies and local storage and set the same cookie domain for each application in this cross-app guide.'
                };
            }

            const storageDisabledAndEmptyCookieDomain = this.isStorageDisabledForAnyApp && this.isAnyCookieDomainEmpty;
            const storageDisabledAndCookieMistach = this.isStorageDisabledForAnyApp && this.hasMismatchedCookieDomains;

            if (storageDisabledAndEmptyCookieDomain || storageDisabledAndCookieMistach) {
                const warning = "You'll need to update cookies settings for ";

                return {
                    title: `${warning}${this.warningSuffix}.`,
                    description:
                        'Each app in a cross-app guide must have cross-app agent cookies and local storage enabled and share the same cookie domain.'
                };
            }

            // we do not want to show this message if one of the cookie domains is empty even though *there is technically* a mismatch due to '' !== 'someDomain.net'
            if (this.hasMismatchedCookieDomains && !this.isAnyCookieDomainEmpty) {
                const warning = 'Cookie domain names do not match.';

                return {
                    title: warning,
                    description:
                        'Each app in a cross-app guide must have the same cookie domain. Please update cookie domain names in the data and privacy settings of each app.'
                };
            }

            return this.getErrorConfigForSingleWarning(this.warningTypes[0]);
        },
        hasMismatchedCookieDomains () {
            if (isEmpty(this.allCookieDomains)) return false;

            const firstDomain = this.allCookieDomains[0];

            return this.allCookieDomains.some((domain) => domain !== firstDomain);
        },
        warningSuffix () {
            return this.warningApps.length > 1 ? 'the listed apps' : this.warningApps[0].name;
        }
    },
    watch: {
        guideAppsList: {
            immediate: true,
            handler () {
                this.resetData();

                for (const app of this.guideAppsList) {
                    const { id, identityStorageSuffix, cookieDomain, displayName, name } = app;
                    const formattedApp = {
                        id,
                        name: displayName || name
                    };

                    if (identityStorageSuffix === '') this.addToWarningsMap(STORAGE_DISABLED_APP_IDS, formattedApp);
                    if (cookieDomain === '') this.addToWarningsMap(EMPTY_COOKIE_DOMAIN_APP_IDS, formattedApp);
                    if (cookieDomain !== '') this.allCookieDomains.push(cookieDomain);
                }
            }
        }
    },
    methods: {
        resetData () {
            this.warningsMap = {};
            this.allCookieDomains = [];
        },
        getErrorConfigForSingleWarning (warningType) {
            let warning = '';
            let description = '';

            switch (warningType) {
                case STORAGE_DISABLED_APP_IDS:
                    warning = "You'll need to enable cookies and storage for ";
                    description =
                        'Each app in a cross-app guide must have cross-app agent cookies and local storage enabled.';
                    break;
                case EMPTY_COOKIE_DOMAIN_APP_IDS:
                    warning = "You'll need to set the cookie domain for ";
                    description = 'Each app in a cross-app guide must have the same cookie domain.';
                    break;
            }

            return {
                title: `${warning}${this.warningSuffix}.`,
                description
            };
        },
        addToWarningsMap (key, value) {
            if (this.warningsMap[key]) {
                this.warningsMap[key].push(value);

                return;
            }

            Vue.set(this.warningsMap, key, [value]);
        }
    }
};
</script>

<style scoped lang="scss">
ul {
    padding: 0;
    margin: 0;
}

li {
    padding: 5px 0 5px 0;
    list-style-type: none;
    text-decoration: underline;
}

a {
    color: $gray-lighter-1;
}
</style>
