import { BuildingBlock, BuildingBlockProperties, BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { TaskList } from '@pendo/services/TaskList';

function parsedBuildingBlockString (buildingBlocks) {
    return typeof buildingBlocks === 'string' ? JSON.parse(buildingBlocks) : buildingBlocks;
}

export function getModuleIframeBlock (buildingBlocks) {
    const bb = parsedBuildingBlockString(buildingBlocks);

    return BuildingBlock.findBlockByWidgetId(bb, BuildingBlockLayouts.widgetIds.iframeBlock);
}

export function updateModuleIframeSrc (guide, newSrc) {
    const clonedGuide = cloneDeep(guide);
    const buildingBlocks = get(clonedGuide, 'steps[0].buildingBlocks', {});
    const modifiedIframeBlock = getModuleIframeBlock(buildingBlocks);
    BuildingBlockProperties.updateOrCreateProperty(modifiedIframeBlock, 'src', 'string', newSrc);
    const parsedBuildingBlocks = JSON.parse(clonedGuide.steps[0].buildingBlocks);
    const modifiedBuildingBlocks = updateUIBuildingBlockByWidgetId(
        parsedBuildingBlocks,
        modifiedIframeBlock,
        BuildingBlockLayouts.widgetIds.iframeBlock
    );
    const modifiedBuildingBlocksString = cloneDeep(modifiedBuildingBlocks);
    clonedGuide.steps[0].buildingBlocks = modifiedBuildingBlocksString;

    return clonedGuide;
}

export function updateUIBuildingBlockByWidgetId (
    allBlocks,
    updateBlock,
    uiRootId = BuildingBlockLayouts.widgetIds.h2Block
) {
    const uiBlocks = BuildingBlock.findBlockByWidgetId(allBlocks, uiRootId);
    BuildingBlock.replaceBlock(allBlocks, updateBlock, (block) => block.web.domId === uiBlocks.web.domId);

    return allBlocks;
}

export function getValidGuidesForRC (guidesList, appId, productLines) {
    return guidesList.reduce(
        (validGuidesMap, guide) => {
            const isAnnouncementGuide = TaskList.isAnnouncementGuide(guide);
            const isInModuleApp = get(guide, 'appId') === appId;
            const isFormGuide = guide.launchMethod === 'form';

            if (isAnnouncementGuide && isInModuleApp && !isFormGuide) {
                validGuidesMap.announcements.push(guide);
            }

            if (TaskList.canGuideBeAddedToTaskList(guide, appId, productLines) && !isFormGuide) {
                validGuidesMap.guidesList.push(guide);
            }

            return validGuidesMap;
        },
        { announcements: [], guidesList: [] }
    );
}
