<template>
    <div class="csv-upload">
        <pendo-icon-button
            v-if="isIcon"
            tooltip="Upload CSV"
            class="csv-upload__icon"
            icon="upload"
            icon-size="16"
            @click="openModal" />
        <pendo-button
            v-else-if="!value.tagId"
            theme="app"
            type="secondary"
            label="Upload CSV"
            @click="openModal" />
        <pendo-tag
            v-if="value.tagId"
            class="csv-upload__tag"
            type="filter"
            :closable="true"
            :label="filename"
            @close="removeFile" />
        <pendo-modal
            :visible="showUploadModal"
            :title="title"
            width="480px"
            height="auto"
            max-height="80%"
            class="csv-upload-modal"
            @close="closeModal"
            @closed="reset">
            <template v-if="!isUploading && isReady">
                <slot
                    v-if="!!$slots.description"
                    name="description" />
                <div
                    v-else
                    class="csv-upload-modal__description">
                    {{ description }}
                </div>
                <div>
                    <input
                        ref="fileInput"
                        type="file"
                        hidden="true"
                        accept=".csv"
                        @change="onFileChange">
                    <div
                        v-if="!hideFileFormatNote"
                        class="csv-upload-modal__file-format-note">
                        <div>
                            <div>
                                {{ fileFormatHelp.copy }}
                            </div>
                            <div>
                                For guidance on the upload process, see
                                <a
                                    :href="fileFormatHelp.docsUrl"
                                    target="_blank">{{ fileFormatHelp.urlText }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="csv-upload-modal__file-upload">
                        <pendo-button
                            theme="app"
                            type="secondary"
                            label="Choose file"
                            @click="chooseFile" />
                        <pendo-tag
                            v-if="file"
                            class="csv-upload__tag"
                            type="filter"
                            :closable="true"
                            :label="file.name"
                            @close="removeFile" />
                    </div>
                    <pendo-checkbox
                        v-if="hasExcludeHeaderCheck"
                        v-model="excludeHeader"
                        class="csv-upload-modal__exclude-header"
                        label="Exclude row 1 (column header)" />
                </div>
            </template>
            <div v-if="isUploading">
                <div class="csv-upload-modal__progress">
                    <pendo-progress-bar
                        ref="uploadProgressBar"
                        :width="360"
                        :value="percentComplete"
                        :colors="progressBarColors"
                        trickle />
                    <div class="csv-upload-modal__progress-status">
                        Uploading {{ file.name }}...
                    </div>
                </div>
            </div>
            <template v-if="!isUploading">
                <div>
                    <pendo-alert
                        v-if="isSuccess"
                        class="csv-upload-modal__success-alert"
                        title="CSV uploaded successfully."
                        type="success">
                        <slot
                            v-if="isSuccess"
                            name="success-message" />
                    </pendo-alert>
                    <pendo-alert
                        v-if="isFailed"
                        class="csv-upload-modal__failed-alert"
                        type="error">
                        <div>
                            <strong>{{ file.name }}</strong> failed to upload.
                        </div>
                        <slot name="error-message" />
                    </pendo-alert>
                </div>
                <pendo-alert
                    v-if="hasWarning"
                    type="warning">
                    <slot name="warning-message" />
                </pendo-alert>
            </template>
            <template #footer>
                <div class="csv-upload-modal__footer">
                    <template v-if="!isUploading">
                        <pendo-button
                            v-if="!(isSuccess && hideCancelOnSuccess && hideCancelOnWarning)"
                            theme="app"
                            type="secondary"
                            :label="cancelText"
                            @click="refreshAndCloseModal" />
                        <pendo-button
                            v-if="isSuccess"
                            theme="app"
                            type="primary"
                            :label="finishText"
                            @click="setEntityTag" />
                        <pendo-button
                            v-if="isFailed && !uploadAlreadyInProgress"
                            theme="app"
                            type="primary"
                            label="Try again"
                            @click="reset" />
                        <pendo-button
                            v-if="isPaused"
                            theme="app"
                            type="primary"
                            label="Continue with this CSV"
                            @click="continueWithCsv" />
                    </template>
                    <pendo-button
                        v-if="isReady || isUploading"
                        theme="app"
                        :disabled="isUploading || !file"
                        :loading="isUploading"
                        :label="isUploading ? 'Uploading...' : 'Upload file'"
                        type="primary"
                        @click="uploadFile" />
                </div>
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import {
    PendoTag,
    PendoAlert,
    PendoButton,
    PendoCheckbox,
    PendoModal,
    PendoProgressBar,
    PendoIconButton
} from '@pendo/components';
import { coordinateMetadataCsvUpload, coordinateEntitytagCsvUpload, coordinateLicenseCsvUpload } from '@/utils/csv';
import { PROGRESS_COLORS } from '@/constants/progress';

export default {
    name: 'CsvUpload',
    components: {
        PendoButton,
        PendoTag,
        PendoAlert,
        PendoCheckbox,
        PendoModal,
        PendoProgressBar,
        PendoIconButton
    },
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
        isIcon: {
            type: Boolean,
            default: false
        },
        hasExcludeHeaderCheck: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            default: ''
        },
        hideFileFormatNote: {
            type: Boolean,
            default: false
        },
        uploadType: {
            type: String,
            default: 'entitytags'
        },
        entityKind: {
            type: String,
            default: 'visitor'
        },
        hasWarning: {
            type: Boolean,
            default: false
        },
        hasError: {
            type: Boolean,
            default: false
        },
        hideCancelOnSuccess: {
            type: Boolean,
            default: false
        },
        hideCancelOnWarning: {
            type: Boolean,
            default: false
        },
        finishText: {
            type: String,
            default: 'Finish'
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        fileFormatHelp: {
            type: Object,
            default: () => {
                return {
                    copy: 'File must be newline delimited.',
                    urlText: 'More file format details.',
                    docsUrl: 'https://adoptsupport.pendo.io/hc/en-us/articles/4406529611035-Metadata-Overview'
                };
            }
        }
    },
    emits: ['input', 'refresh', 'reset', 'progress', 'warning', 'success', 'error', 'continueWithCsv'],
    data () {
        return {
            showUploadModal: false,
            someVisitorIdsNotMatched: false,
            visitorIdMismatchList: [],
            localIsFailed: false,
            isUploading: false,
            isSuccess: false,
            isDefaultState: true,
            file: null,
            percentComplete: 0,
            excludeHeader: false,
            tagId: this.value.tagId,
            uploadStatusInterval: null,
            isPaused: false,
            uploadAlreadyInProgress: false
        };
    },
    computed: {
        isFailed () {
            return this.hasError || this.localIsFailed;
        },
        filename () {
            return this.value.filename;
        },
        isReady () {
            return this.percentComplete === 0;
        },
        progressBarColors () {
            // default -> success
            let complete = PROGRESS_COLORS.SUCCESS;

            // warning -> some requests passed, some failed
            if (this.hasWarning) {
                complete = PROGRESS_COLORS.WARNING;
            }

            // error -> some backend error
            if (this.isFailed) {
                complete = PROGRESS_COLORS.ERROR;
            }

            return {
                default: PROGRESS_COLORS.DEFAULT,
                progress: PROGRESS_COLORS.PROGRESS,
                complete
            };
        }
    },
    methods: {
        removeFile () {
            this.tagId = null;
            this.file = null;
            this.$emit('input', {
                field: 'tag',
                value: {
                    tagId: null,
                    filename: null
                }
            });
        },
        openModal () {
            this.showUploadModal = true;
        },
        closeModal () {
            this.showUploadModal = false;
        },
        refreshAndCloseModal () {
            if (!this.isFailed) this.$emit('refresh');
            this.closeModal();
        },
        setEntityTag () {
            this.$emit('input', {
                field: 'tag',
                value: {
                    tagId: this.tagId,
                    filename: this.file.name
                }
            });
            this.$emit('refresh');
            this.closeModal();
        },
        // modal methods
        chooseFile () {
            // clear input before uploading to ensure
            // change event is triggered for same file uploads
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.click();
        },
        onFileChange (event) {
            this.file = event.target.files[0];
        },
        reset () {
            window.clearInterval(this.uploadStatusInterval);
            this.$emit('reset');

            this.isUploading = false;
            this.percentComplete = 0;
            this.excludeHeader = false;
            this.isSuccess = false;
            this.localIsFailed = false;
            this.file = null;
            this.errorObject = {};
            this.isPaused = false;
            this.uploadAlreadyInProgress = false;
        },
        onProgress ({ progress }) {
            this.$emit('progress', progress);

            if (!isNaN(progress)) {
                this.percentComplete = progress;

                return;
            }

            const { done } = this.$refs.uploadProgressBar;
            const { warnings, command } = progress;

            if (command) {
                setTimeout(() => {
                    this.isUploading = false;
                }, 1000);
            }

            if (command === 'pause') {
                this.isPaused = true;
            } else if (command === 'finish' || command === 'finish with error') {
                if (warnings) {
                    this.$emit('warning', warnings);
                }

                if (command === 'finish with error') {
                    this.$emit('warning', progress);
                }

                this.isSuccess = true;
                this.$emit('success', progress);
                done();
            } else if (command === 'error' || command === 'bad request') {
                this.$emit('error', progress);
                done();
            } else if (command === 'finish with warning') {
                this.$emit('warning', progress);
                done();
            }

            return;
        },
        async uploadFile () {
            if (!this.file) {
                return;
            }

            this.localIsFailed = false;
            this.isSuccess = false;
            this.isUploading = true;

            // wait for progress bar to be rendered to DOM
            setTimeout(() => {
                this.$refs.uploadProgressBar.start();
            }, 0);

            let coordinationPromise;

            switch (this.uploadType) {
                case 'entitytags':
                    coordinationPromise = coordinateEntitytagCsvUpload(this.file, this.onProgress, {
                        excludeHeader: this.excludeHeader
                    });
                    break;
                case 'metadata':
                    coordinationPromise = coordinateMetadataCsvUpload(
                        this.file,
                        this.entityKind,
                        'custom',
                        this.onProgress
                    );
                    break;
                case 'licenses':
                    coordinationPromise = coordinateLicenseCsvUpload(this.file, this.onProgress);
                    break;
            }

            try {
                const { entityTagId, pollingPromise, pollingInterval } = await coordinationPromise;
                this.uploadStatusInterval = pollingInterval;
                // Reset state and clear interval if cancelled once the intial upload request resolves
                if (!this.isUploading) {
                    this.reset();

                    return;
                }
                await pollingPromise;

                // entityTagId will be undefined for metadata uploads
                this.tagId = entityTagId;
            } catch (error) {
                if (error && error.response && error.response.status === 409) {
                    this.uploadInProgressError(error);
                } else {
                    this.$emit('error', error);
                }
            }
        },
        continueWithCsv () {
            this.isPaused = false;
            this.isUploading = true;
            this.$emit('continueWithCsv', this.onProgress);
        },
        uploadInProgressError (error) {
            const { status, data } = error.response;
            const uploadInProgressError = {
                command: 'error',
                error: data,
                status
            };
            this.isUploading = false;
            this.uploadAlreadyInProgress = true;
            this.$emit('error', uploadInProgressError);
        }
    }
};
</script>

<style lang="scss">
input[type='file'][hidden] {
    display: none;
}

.csv-upload {
    display: flex;
    align-items: center;

    .csv-upload__icon {
        margin-right: 8px;
        padding-right: 8px;
    }
}

.csv-upload .csv-upload__tag,
.csv-upload-modal .csv-upload__tag {
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    text-transform: none;
    overflow: hidden;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.csv-upload-modal__limit-exceeded {
    margin-top: 8px;
}

.csv-upload-modal__description {
    margin-bottom: 16px;
}

.csv-upload-modal__exclude-header {
    margin-top: 8px;
}

.csv-upload-modal__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.csv-upload-modal__file-upload {
    display: flex;

    .csv-upload__tag {
        margin-left: 8px;
    }
}

.csv-upload-modal__file-format-note,
.csv-upload-modal__mismatch-alert {
    display: flex;
    align-items: flex-start;

    .pendo-icon {
        margin-top: 2px;
    }

    span {
        margin-left: 6px;
    }
}

.csv-upload-modal__file-format-note {
    margin-bottom: 16px;
}

.csv-upload-modal__progress {
    display: grid;
    grid-gap: 16px;
}

.csv-upload-modal__progress-status {
    color: $gray-lighter-2;
    font-weight: 600;
    text-align: center;
}

.pendo-alert.csv-upload-modal__success-alert,
.pendo-alert.csv-upload-modal__failure-alert,
.pendo-alert.csv-upload-modal__status-warning {
    font-weight: 400;
    margin-bottom: 16px;
}
</style>
