import { formatTimeValue } from '../moment';

export function parseWorkflowUsageFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;
    const startedAndCompletedCheck = `(!isNull(startedAt${filterIndex}) && !isNull(lastCompletedAt${filterIndex})) && (lastCompletedAt${filterIndex} != 0 && startedAt${filterIndex} != 0)`;
    const startedNotCompletedCheck = `startedAt${filterIndex} > 0 && startedAt${filterIndex} > lastCompletedAt${filterIndex}`;
    const notStartedNotCompletedCheck = `(isNull(startedAt${filterIndex}) && isNull(lastCompletedAt${filterIndex})) || (startedAt${filterIndex} == 0 && lastCompletedAt${filterIndex} == 0)`;
    switch (operator) {
        case 'completed':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = `(${startedAndCompletedCheck}) && lastCompletedAt${filterIndex} >= dateAdd(now(),${-1 *
                    count},"${granularity}")`;
                break;
            }

            if (time === 'since') {
                filterString = `(${startedAndCompletedCheck}) && lastCompletedAt${filterIndex} >= date("${formatTimeValue(
                    value
                )}")`;

                break;
            }

            if (time === 'atleast') {
                filterString = `completeCount >= ${value}`;

                break;
            }

            if (time === 'atmost') {
                filterString = `completeCount <= ${value}`;
                break;
            }

            // ever case
            filterString = `!isNull(lastCompletedAt${filterIndex}) && lastCompletedAt${filterIndex} != 0`;

            break;
        case 'incomplete':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = `${startedNotCompletedCheck} && startedAt${filterIndex} >= dateAdd(now(),${-1 *
                    count},"${granularity}")`;
                break;
            }

            if (time === 'since') {
                filterString = `${startedNotCompletedCheck} && startedAt${filterIndex} >= date("${formatTimeValue(
                    value
                )}")`;

                break;
            }

            if (time === 'atleast') {
                filterString = `incompleteCount >= ${value}`;

                break;
            }

            if (time === 'atmost') {
                filterString = `incompleteCount <= ${value}`;
                break;
            }
            // ever case
            filterString = `startedAt${filterIndex} > 0 && (lastCompletedAt${filterIndex} == 0  || isNull(lastCompletedAt${filterIndex}))`;

            break;
        case 'notStarted':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = `${notStartedNotCompletedCheck} || startedAt${filterIndex} < dateAdd(now(),${-1 *
                    count},"${granularity}")`;

                break;
            }

            if (time === 'since') {
                filterString = `${notStartedNotCompletedCheck} || startedAt${filterIndex} < date("${formatTimeValue(
                    value
                )}")`;

                break;
            }

            filterString = `${notStartedNotCompletedCheck}`;
            break;
        case 'notcompleted':
            filterString = `isNull(lastCompletedAt${filterIndex}) || lastCompletedAt${filterIndex} == 0`;
    }

    return { filter: filterString };
}
