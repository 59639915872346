<template>
    <pendo-drawer
        title="Create Guide"
        :visible="visible"
        size="fullscreen"
        @close="handleClose">
        <div class="create-guide-drawer-body">
            <transition name="fade">
                <pendo-form
                    ref="createGuideForm"
                    :model="model"
                    class="create-guide-form"
                    @formValidated="handleFormValidation(true)"
                    @invalidForm="handleFormValidation(false)">
                    <pendo-card
                        title="Guide Name"
                        body-min-height="inherit"
                        class="create-guide-card create-guide-card--name">
                        <template #body>
                            <pendo-form-item
                                class="create-guide-card-content"
                                prop="name"
                                :rules="guideNameRules"
                                label="Name Your Guide">
                                <pendo-input
                                    v-model="model.name"
                                    class="create-guide-form-input"
                                    data-cy="guide-create--name-input"
                                    placeholder="Unnamed"
                                    autofocus />
                            </pendo-form-item>
                        </template>
                    </pendo-card>
                    <pendo-card
                        data-cy="guide-create--url"
                        body-min-height="inherit"
                        title="App Information"
                        class="create-guide-card create-guide-card--app">
                        <template #body>
                            <div class="create-guide-card-content">
                                <pendo-form-item
                                    :rules="appSelectRules"
                                    class="app-select"
                                    prop="appSelection">
                                    <pendo-multiselect
                                        v-model="model.appSelection"
                                        min-trigger-width="100%"
                                        :options="appOptions"
                                        label-key="displayName"
                                        data-cy="guide-create--app-dropdown"
                                        :labels="{ top: 'Select an app' }"
                                        placeholder="- None Selected -"
                                        @select="onAppSelect">
                                        <template #selectedLabel>
                                            <pendo-app-display :apps="model.appSelection" />
                                        </template>
                                        <template #option="{ option }">
                                            <pendo-app-display :apps="option" />
                                        </template>
                                    </pendo-multiselect>
                                </pendo-form-item>
                                <pendo-form-item
                                    :rules="urlValidationRule"
                                    class="launch-url"
                                    prop="url">
                                    <div class="create-guide-app-url-field">
                                        <pendo-input
                                            v-model="model.url"
                                            data-cy="guide-create--url-input"
                                            class="create-guide-form-input"
                                            :labels="{ top: 'Guide Start URL' }"
                                            placeholder="https://yourapp.com"
                                            @change="onLaunchUrlChange">
                                            <template #prefix>
                                                <pendo-icon
                                                    :class="{ 'create-guide-app-url-icon--empty': !model.url }"
                                                    type="link"
                                                    size="16" />
                                            </template>
                                        </pendo-input>
                                    </div>
                                </pendo-form-item>
                            </div>
                            <div
                                v-if="isPendoUser"
                                class="super-only-lookaside">
                                <div>
                                    <pendo-button
                                        icon="git-merge"
                                        type="tertiary"
                                        class="toggle-lookaside-button"
                                        @click="isLookasideInputVisible = !isLookasideInputVisible">
                                        (Super Only) Provide Lookaside Host
                                    </pendo-button>
                                    <pendo-icon
                                        v-pendo-tooltip="{
                                            content:
                                                'Lookaside host option is only visible to users with @pendo.io in their emails',
                                            arrow: true,
                                            classes: 'is-multi-line'
                                        }"
                                        display="inline"
                                        class="lookaside-host-icon"
                                        type="info"
                                        size="14" />
                                </div>
                                <pendo-form-item
                                    v-if="isLookasideInputVisible"
                                    :rules="lookasideHostValidationRules"
                                    prop="lookasideHost">
                                    <label class="lookaside-host-label pendo-form-item__label">Lookaside Host</label>
                                    <pendo-input
                                        v-model="model.lookasideHost"
                                        data-cy="guide-create--lookaside-input"
                                        autofocus
                                        placeholder="[name].lookaside.pendo-[env].pendo-dev.com (no trailing slash)">
                                        <template #prepend>
                                            https://
                                        </template>
                                    </pendo-input>
                                </pendo-form-item>
                                <pendo-form-item v-if="isLookasideInputVisible && isRunningOnLocalhost">
                                    <pendo-toggle
                                        v-model="useLocalAdoptStudio"
                                        label="Launch using local Adopt Studio"
                                        label-position="right"
                                        @change="toggleUseLocalAdoptStudio" />
                                </pendo-form-item>
                            </div>
                        </template>
                    </pendo-card>
                    <div
                        v-if="showAIOption"
                        class="create-guide-card--workflow-toggle">
                        <build-with-a-i-toggle
                            :disable-a-i-option="disableAIOption"
                            @toggle="useAI = $event" />
                    </div>
                    <div
                        v-if="useAI"
                        class="create-guide-card--ai-panel">
                        <guide-creation-with-a-i-panel
                            :is-loading="launching"
                            :is-generating-steps="isGeneratingSteps"
                            :is-regenerating-step="isRegeneratingStep"
                            :generated-steps="generatedSteps"
                            :application="model.appSelection"
                            :prompt="stepGenerationPrompt"
                            @updatePrompt="stepGenerationPrompt = $event"
                            @generateSteps="$emit('generateSteps', $event)"
                            @regenerateStep="regenerateStep"
                            @updateSteps="$emit('updateSteps', $event)" />
                    </div>
                    <pendo-card
                        v-if="!showAIOption || !useAI"
                        data-cy="guide-create--type"
                        body-min-height="inherit"
                        title="Type"
                        class="create-guide-card--type">
                        <pendo-form-item>
                            <pendo-chooser-grid
                                class="create-guide-card__type-select"
                                :items="gridItems"
                                :value="selectedType"
                                :multiple="true"
                                @select="handleSelectType"
                                @remove="handleRemoveType">
                                <template #media="{ item }">
                                    <badge-empty-state v-if="isBadge(item)" />
                                    <guide-empty-state v-if="isGuide(item)" />
                                    <confirmation-empty-state v-if="isConfirmation(item)" />
                                    <automation-empty-state v-if="isAutomation(item)" />
                                </template>
                                <template #overlay="{ item, isItemSelected, select, removeSelection }">
                                    <pendo-button
                                        theme="app"
                                        :data-cy="`create-guide-card__type-select-${item.id}`"
                                        type="secondary"
                                        :label="
                                            isItemSelected(item) ? `Deselect ${item.title}` : `Select ${item.title}`
                                        "
                                        @click="isItemSelected(item) ? removeSelection(item) : select(item)" />
                                </template>
                            </pendo-chooser-grid>
                        </pendo-form-item>
                    </pendo-card>
                </pendo-form>
            </transition>
        </div>
        <template #footer>
            <div class="create-guide-footer">
                <pendo-button
                    data-cy="guide-create--cancel-guide-builder-button"
                    class="cancel-guide-create"
                    label="Cancel"
                    type="secondary"
                    theme="app"
                    @click="handleClose" />
                <pendo-button
                    :disabled="!isValidForm"
                    :loading="launching"
                    data-cy="guide-create--launch-guide-builder-button"
                    class="create-guide-button"
                    :label="`Launch ${guideEditorName}`"
                    type="primary"
                    theme="app"
                    @click="createGuideAndLaunchBuilder" />
            </div>
        </template>
    </pendo-drawer>
</template>

<script>
import {
    PendoAppDisplay,
    PendoDrawer,
    PendoCard,
    PendoChooserGrid,
    PendoForm,
    PendoFormItem,
    PendoIcon,
    PendoInput,
    PendoMultiselect,
    PendoButton,
    PendoToggle,
    PendoTooltip
} from '@pendo/components';
import { generateAdoptStudioLaunchParams } from '@/stateless-components/utils/designer';
import { lookasideHostValidationRules, isValidLaunchDesignerForm } from '@/utils/utils';
import { urlValidationRule } from '@pendo/services/Formatters';
import GuideEmptyState from '@/components/svgs/GuideEmptyState.vue';
import BadgeEmptyState from '@/components/svgs/BadgeEmptyState.vue';
import ConfirmationEmptyState from '@/components/svgs/ConfirmationEmptyState.vue';
import AutomationEmptyState from '@/components/svgs/AutomationEmptyState.vue';
import GuideCreationWithAIPanel from '@/stateless-components/guides/ai/GuideCreationWithAIPanel.vue';
import BuildWithAIToggle from '@/stateless-components/guides/ai/BuildWithAIToggle';

export const emptyModel = {
    name: '',
    url: '',
    lookasideHost: '',
    appSelection: null
};

export default {
    name: 'GuideCreate',
    components: {
        AutomationEmptyState,
        BadgeEmptyState,
        GuideEmptyState,
        ConfirmationEmptyState,
        PendoAppDisplay,
        PendoDrawer,
        PendoCard,
        PendoChooserGrid,
        PendoForm,
        PendoFormItem,
        PendoIcon,
        PendoInput,
        PendoToggle,
        PendoMultiselect,
        PendoButton,
        GuideCreationWithAIPanel,
        BuildWithAIToggle
    },
    directives: {
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        lastAppUrlMap: {
            type: Object,
            default: () => {}
        },
        isPendoUser: {
            type: Boolean,
            default: false
        },
        appOptions: {
            type: Array,
            default: () => []
        },
        launching: {
            type: Boolean,
            default: false
        },
        isGeneratingSteps: {
            type: Boolean,
            default: false
        },
        isRegeneratingStep: {
            type: Boolean,
            default: false
        },
        generatedSteps: {
            type: Array,
            default: () => []
        },
        guideEditorName: {
            type: String,
            default: ''
        },
        showAIOption: {
            type: Boolean,
            default: false
        },
        disableAIOption: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close', 'generateSteps', 'updateSteps', 'appSelected', 'createGuide', 'regenerateStep'],
    data () {
        return {
            model: Object.assign({}, emptyModel),
            lookasideHostValidationRules,
            isLookasideInputVisible: false,
            userManuallyEnteringUrl: false,
            useLocalAdoptStudio: false,
            useAI: false,
            appSelectRules: [
                {
                    required: true,
                    message: 'A target app is required to create a guide',
                    trigger: 'blur'
                }
            ],
            guideNameRules: [
                {
                    required: true,
                    message: 'A name is required for this guide',
                    trigger: 'blur'
                }
            ],
            selectedType: { id: null },
            launchBadgeGuideBuilder: false,
            stepGenerationPrompt: null,
            urlValidationRule
        };
    },
    computed: {
        isValidForm () {
            const { url, lookasideHost, name, appSelection } = this.model;
            if (!name) return false;
            if (!appSelection) return false;

            if (!this.showAIOption && !this.selectedType.id) return false;
            if (this.showAIOption && this.useAI && !this.generatedSteps.length) return false;

            return isValidLaunchDesignerForm({ url, lookasideHost });
        },
        isRunningOnLocalhost () {
            return window.location.origin.includes('local');
        },
        gridItems () {
            const gridItems = [
                {
                    title: 'Guide',
                    subtitle: 'PRODUCT WALKTHROUGH',
                    icon: { type: '' },
                    id: 'guide'
                },
                {
                    title: 'Badge',
                    subtitle: 'In-line Support',
                    icon: { type: '' },
                    id: 'badge'
                },
                {
                    title: 'Confirmation',
                    subtitle: 'User Error Prevention',
                    icon: { type: '' },
                    id: 'confirmation'
                },
                {
                    title: 'Automation',
                    subtitle: 'Improve Data Quality',
                    icon: { type: '' },
                    id: 'automation'
                }
            ];

            return gridItems;
        }
    },
    methods: {
        handleFormValidation (valid) {
            this.disabled = !valid;
        },
        handleClose () {
            this.$emit('close');
            this.$emit('updateSteps', []);
            this.model = Object.assign({}, emptyModel);
            this.stepGenerationPrompt = null;
            this.useAI = false;
        },
        handleSelectType (typeObj) {
            this.selectedType = typeObj;
        },
        handleRemoveType () {
            this.selectedType = { id: null };
        },
        toggleUseLocalAdoptStudio (toggleValue) {
            this.useLocalAdoptStudio = toggleValue;
        },
        validateForm () {
            const valid = this.$refs.createGuideForm.validate().catch(() => false);
            this.handleFormValidation(valid);
        },
        onAppSelect (app) {
            if (!app) return;

            this.validateForm();

            // Don't replace the url if the user has already pasted one in
            if (this.userManuallyEnteringUrl && this.model.url) return;

            const newLaunchUrl = this.lastAppUrlMap[app.id] || '';
            this.model.url = newLaunchUrl;
            this.$emit('appSelected', app);
        },
        onLaunchUrlChange () {
            this.validateForm();
            this.userManuallyEnteringUrl = true;
        },
        async createGuideAndLaunchBuilder () {
            const { url, lookasideHost, appSelection, name } = this.model;

            const layout = {
                name,
                empty: !this.useAI,
                badge: this.isBadge(this.selectedType) || this.isConfirmation(this.selectedType),
                confirmation: this.isConfirmation(this.selectedType),
                buildingBlocks: this.useAI
                    ? this.generatedSteps?.map(({ buildingBlocks }) => buildingBlocks)
                    : undefined
            };
            const launchParams = generateAdoptStudioLaunchParams(lookasideHost);
            const token = {
                forceNativeDesigner: appSelection.platform === 'web',
                guideAttributes: { addAutomation: this.isAutomation(this.selectedType) },
                DADesigner: true,
                idForApp: appSelection.id,
                type: 'guide',
                target: this.useLocalAdoptStudio ? '999.999.999-local' : 'latest',
                host: lookasideHost ? `https://${lookasideHost}` : window.location.origin,
                ...launchParams
            };

            const config = {
                layout,
                appId: appSelection.id,
                url,
                token
            };

            this.$emit('createGuide', config);
        },
        regenerateStep ({ index }) {
            const { appSelection } = this.model;

            this.$emit('regenerateStep', { index, tone: this.stepGenerationPrompt.tone, appId: appSelection.id });
        },
        isGuide (item) {
            return item.id === 'guide';
        },
        isBadge (item) {
            return item.id === 'badge';
        },
        isConfirmation (item) {
            return item.id === 'confirmation';
        },
        isAutomation (item) {
            return item.id === 'automation';
        }
    }
};
</script>

<style scoped lang="scss">
.create-guide-drawer-body {
    padding: 32px;
    width: 100vw;
    overflow-y: auto;
}

.create-guide-form {
    display: flex;
    gap: $default-spacing;
    flex-flow: wrap;

    .create-guide-card--name,
    .create-guide-card--app {
        flex: 1 1 40%;
    }

    .create-guide-card--workflow-toggle,
    .create-guide-card--type,
    .create-guide-card--ai-panel {
        max-width: 100%;
        flex: 2 0 100%;
    }

    .create-guide-card--app {
        .create-guide-card-content {
            max-width: inherit;
            display: flex;

            .app-select {
                width: 35%;
                box-sizing: border-box;
                padding-right: 20px;

                &.is-error {
                    :deep(.pendo-multiselect__trigger) {
                        border-color: $red-error;
                    }
                }
            }

            .launch-url {
                flex-grow: 1;
            }
        }
    }

    .create-guide-form-input {
        max-width: inherit;
    }

    .create-guide-card__type-select {
        width: 80vw;
        flex-grow: 1;

        :deep(.pendo-chooser-grid__items) {
            justify-content: left;
        }

        :deep(.pendo-media-card__content) {
            text-align: center;
        }
    }

    .create-guide-app-url {
        &-field {
            display: flex;
        }

        &-icon--empty {
            color: $gray-lighter-5;
        }
    }
}

.lookaside-host-icon {
    margin-left: 5px;
}

.create-guide-footer {
    display: flex;
    justify-content: flex-end;

    .create-guide-button {
        flex-grow: 0;
    }
}

.create-guide {
    &-form {
        &-input {
            max-width: 390px;
        }
    }

    &-card {
        &-content {
            max-width: 555px;
        }
    }

    &-app-url {
        &-description {
            display: block;
            margin-bottom: $default-spacing;
        }

        &-field {
            display: flex;
        }

        &-button {
            flex-grow: 1;
            margin-left: 8px;
        }

        &-icon--empty {
            color: $gray-lighter-5;
        }
    }
}

@media only screen and (min-width: 1281px) {
    .create-guide-drawer-body {
        padding: 37px 150px;
    }

    .create-guide-card-content {
        max-width: 100%;
    }
}
@media only screen and (min-width: 1921px) {
    .create-guide-drawer-body {
        padding: 37px 250px;
    }
}
</style>
