<template>
    <div class="absolute-date-range">
        <pendo-date-picker
            class="absolute-date-range__start"
            is-required
            :value="first"
            :max-date="last"
            :disabled="readOnly"
            full-width
            @select-date="updateFirst" />
        <div class="absolute-date-range__and-label">
            and
        </div>
        <pendo-date-picker
            class="absolute-date-range__end"
            is-required
            :value="last"
            :min-date="first"
            :disabled="readOnly"
            full-width
            @select-date="updateLast" />
    </div>
</template>

<script>
import { PendoDatePicker } from '@pendo/components';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import moment, { formatTimeValue } from '@/components/segment-builder/utils/moment';

export default {
    name: 'AbsoluteDateRange',
    components: {
        PendoDatePicker
    },
    mixins: [ReadOnlyMixin],
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['input'],
    computed: {
        first () {
            return moment(this.value.first).toDate();
        },
        last () {
            return moment(this.value.last).toDate();
        },
        isFirstValid () {
            return moment(this.value.first).isValid();
        },
        isLastValid () {
            return moment(this.value.last).isValid();
        }
    },
    created () {
        let first = moment(this.value.first);
        let last = moment(this.value.last);

        if (!this.isFirstValid) {
            first = moment(Date.now()).subtract(1, 'days');
        }

        if (!this.isLastValid || last.isBefore(first)) {
            last = moment(first).add(1, 'days');
        }

        this.updateFirst({ value: first });
        this.updateLast({ value: last });
    },
    methods: {
        updateFirst (date) {
            const value = formatTimeValue(date.value);
            this.$emit('input', { field: 'first', value });
        },
        updateLast (date) {
            const value = formatTimeValue(date.value);
            this.$emit('input', { field: 'last', value });
        }
    }
};
</script>

<style lang="scss">
.absolute-date-range {
    align-items: center;
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 36px 1fr;
}

.absolute-date-range__and-label {
    color: $gray-lighter-2;
    margin: auto;
}
</style>
