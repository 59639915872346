<template>
    <div class="resource-center-rule">
        <!-- resource center module picker -->
        <div :class="{ 'guide-undefined': rule.guideDeleted }">
            <pendo-multiselect
                :value="rule.guide"
                :allow-empty="false"
                :options="modulesForResourceCenter"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                :extra-label-width="60"
                placeholder="Select Module"
                class="resource-center-rule--rc-module-select"
                @select="onGuideSelect" />
            <rule-warning v-if="rule.guideDeleted">
                The original module for this rule has been deleted
            </rule-warning>
        </div>
        <operator-picker
            :rule="rule"
            v-on="$listeners" />
        <disabled-single-option-select
            v-if="showCosmeticEver"
            option="ever" />
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoMultiselect } from '@pendo/components';
import OperatorPicker from '../rule-components/OperatorPicker';
import RuleWarning from '../rule-components/RuleWarning';
import DisabledSingleOptionSelect from '../rule-components/DisabledSingleOptionSelect';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'ResourceCenterRule',
    components: {
        DisabledSingleOptionSelect,
        OperatorPicker,
        PendoMultiselect,
        RuleWarning
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        modulesForResourceCenter () {
            const { rule } = this;
            const { app } = rule;
            const appId = this.config.usesMultiApp ? get(app, 'id') : this.config.appId;
            const homeViewId = get(this.computedData.draftResourceCentersMap, `${appId}.homeView.id`);

            return this.computedData.rcModules
                .filter((guide) => {
                    const resourceCenterId =
                        get(guide, 'attributes.resourceCenter.homeViewId') ||
                        get(guide, 'attributes.resourceCenter.originId');

                    return resourceCenterId === homeViewId;
                })
                .map((guide) => {
                    const isHomeView = get(guide, 'attributes.resourceCenter.isTopLevel');

                    return Object.assign(guide, {
                        guideId: guide.id, // can't be named moduleId because guidesSeenEver source only accepts 'guideId'
                        name: isHomeView ? 'Resource Center Home' : guide.name
                    });
                });
        },
        showCosmeticEver () {
            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return ['viewed', 'notviewed'].includes(operatorValue);
        }
    },
    methods: {
        onGuideSelect (guide) {
            this.$emit('input', [
                { field: 'guide', value: guide },
                { field: 'guideDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.resource-center-rule {
    display: contents;

    .guide-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
