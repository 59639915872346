<template>
    <pendo-modal
        type="confirmation"
        :click-to-close="true"
        :visible="showModal"
        :title="getModalTitle"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="hasError"
                closable
                title="There was an error clearing the strings."
                type="error" />
            <p>
                This will remove the translated strings from the guide. Re-import the strings to translate the guide
                again.
            </p>
        </template>
        <template #footer>
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                type="danger"
                theme="app"
                label="Yes, Clear Strings"
                :loading="isLoading"
                @click="clearStringsAsync" />
        </template>
    </pendo-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import get from 'lodash/get';
import { PendoAlert, PendoButton, PendoModal } from '@pendo/components';

export default {
    name: 'ClearStringsModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoModal
    },
    props: {
        guideId: {
            type: String,
            required: true
        },
        langData: {
            type: Object,
            required: true
        },
        showModal: {
            type: Boolean,
            default: false
        },
        isResourceCenter: {
            type: Boolean,
            required: true
        }
    },
    emits: ['closeClearStringsModal'],
    data () {
        return {
            isLoading: false,
            hasError: false
        };
    },
    computed: {
        ...mapState({
            errorOnResourceCenterUpdate: (state) => state.resourceCenter.error
        }),
        getModalTitle () {
            const name = get(this.langData, 'name');

            return `Clear ${name} Strings?`;
        }
    },
    methods: {
        ...mapActions({
            clearGuideTranslations: 'guides/clearTranslationStrings',
            clearRCTranslations: 'resourceCenter/clearResourceCenterTranslationStrings'
        }),
        closeModal () {
            this.$emit('closeClearStringsModal', false);
        },
        async clearStringsAsync () {
            this.isLoading = true;
            const { langCode } = this.langData;
            if (!langCode) {
                this.hasError = true;

                return;
            }

            if (this.isResourceCenter) {
                await this.clearRCTranslations({ langCode });
                if (this.errorOnResourceCenterUpdate) this.hasError = true;
            } else {
                await this.clearGuideTranslations({ guideId: this.guideId, langCode }).catch(() => {
                    this.hasError = true;
                });
            }

            this.isLoading = false;
            this.closeModal();
        }
    }
};
</script>
