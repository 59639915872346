import uniq from 'lodash/uniq';
import { operators as o } from '@pendo/aggregations';
import {
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { identifiedState } from '@/utils/aggregations';
import { formatStartAndEndStepsForAgg } from './utils';

export default {
    name: 'WorkflowHighlights',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS,
    responseFormat: 'rows',
    build: ({ workflow, segmentId, dateRange }) => {
        const aggTimeSeries = {
            period: 'dayRange',
            first: `date("${dateRange.value.start}")`,
            last: `date("${dateRange.value.end}")`
        };

        const { workflowSteps, maxDuration } = workflow;
        const steps = formatStartAndEndStepsForAgg(workflowSteps);
        const appIds = uniq([steps.start.appId, steps.end.appId]);

        const isRecurring = workflow.classification === 'recurring';

        if (isRecurring) {
            return buildRecurringWorkflowAgg(appIds, steps, segmentId, aggTimeSeries, maxDuration);
        }

        return buildNonRecurringWorkflowAgg(appIds, steps, segmentId, aggTimeSeries, maxDuration);
    }
};

function buildRecurringWorkflowAgg (appIds, steps, segmentId, aggTimeSeries, maxDuration) {
    return o.pipeline(
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.fork(
            o.pipeline(
                o.unwind('funnel', { keepEmpty: true }),
                o.group('visitorId', o.groupField('stepCount', { max: 'funnel.steps' }))
            ),
            o.pipeline(o.cat())
        ),
        o.join('visitorId'),
        o.unwind('funnel', { keepEmpty: true }),
        o.fork(
            o.pipeline(o.reduce({ totalAttempts: o.countIf(null, 'funnel.steps != null') })),
            o.pipeline(
                o.filter('funnel.steps == 2'),
                o.evaluate({ completionTime: 'funnel.times[1] - funnel.times[0]' }),
                o.reduce({
                    medianTimeToComplete: o.median('completionTime'),
                    completedAttempts: o.count(null)
                })
            )
        ),
        o.join(null)
    );
}

function buildNonRecurringWorkflowAgg (appIds, steps, segmentId, aggTimeSeries, maxDuration) {
    return o.pipeline(
        o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
        o.fork(
            o.pipeline(
                o.unwind('funnel', { keepEmpty: true }),
                o.filter('funnel.steps == 2'),
                o.group('visitorId', o.groupField('latestCompletionStartTime', { max: 'funnel.start' }))
            ),
            o.pipeline(o.cat())
        ),
        o.join('visitorId'),
        o.unwind('funnel', { keepEmpty: true }),
        o.fork(
            o.pipeline(
                o.group('visitorId', o.groupField('stepCount', { max: 'funnel.steps' })),
                o.reduce({ totalVisitors: o.count('visitorId') })
            ),
            o.pipeline(
                o.filter('funnel.steps == 2'),
                o.filter('funnel.start == latestCompletionStartTime'),
                o.evaluate({ completionTime: 'funnel.times[1] - funnel.times[0]' }),
                o.reduce({
                    medianTimeToComplete: o.median('completionTime'),
                    completedCount: o.count('visitorId')
                })
            )
        ),
        o.join(null)
    );
}
