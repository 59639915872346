<template>
    <div class="license-usage-table">
        <pendo-table
            v-pendo-loading:feather="loading"
            :data="licenseEngagementWithLicenseData"
            row-key="appId"
            title="LicenseUtilization"
            :csv-download="exportToCSV"
            :max-height="750"
            resizable
            :filters="filters"
            :default-sort="{ prop: 'timeSpent', order: 'descending' }"
            :tree-config="{ children: 'children', sortChildren: true }"
            :columns="columns"
            @column-resize="onColumnResized">
            <template #headerLeft>
                <div class="header-left">
                    <div>Summary by</div>
                    <pendo-multiselect
                        v-if="hasVisitorMetadata"
                        ref="tableMetadataChooser"
                        :placeholder="tableMetadataPlaceholder"
                        :options="visitorMetadataOptions"
                        label-key="displayName"
                        @select="onVisitorMetadataSelected">
                        <template #header>
                            <div class="metadata-chooser">
                                <div
                                    class="pendo-multiselect__option"
                                    @click="onVisitorMetadataSelected('')">
                                    <span class="pendo-multiselect__element">
                                        Total
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
            </template>
            <template #headerActions>
                <div class="license-utilization--table-search">
                    <search v-model="searchInput" />
                </div>
            </template>
            <template #name="{ row }">
                <pendo-app-display
                    :show-app-icons="!!row.platform"
                    :apps="row" />
            </template>
            <template #categories="{ row }">
                {{ row.categories.length ? row.categories.join(',') : '--' }}
            </template>
            <template #engagement="{ row }">
                <pendo-popover
                    v-if="row.visitors !== 0"
                    placement="top"
                    arrow>
                    <template #default>
                        <div class="engagement-bar">
                            <div
                                v-if="row.highEngagementPercent !== '0%'"
                                class="high"
                                :style="`width: ${row.highEngagementPercent}`" />
                            <div
                                v-if="row.medEngagementPercent !== '0%'"
                                class="medium"
                                :style="`width: ${row.medEngagementPercent}`" />
                            <div
                                v-if="row.lowEngagementPercent !== '0%'"
                                class="low"
                                :style="`width: ${row.lowEngagementPercent}`" />
                        </div>
                    </template>
                    <template #popover>
                        <div class="engagement-tooltip">
                            <p class="name">
                                {{ row.name === ' ' ? row.name_csv : row.name }}
                            </p>
                            <p class="subtitle">
                                Engagement / {{ row.group }}
                            </p>
                            <div class="rows">
                                <div class="row">
                                    <p class="label">
                                        <span class="high icon" />High
                                    </p>
                                    <p>
                                        <span class="percentage">{{ row.highEngagementPercent }}</span>
                                        <span class="visitors">({{ row.highEngagementVisitors }} Visitors)</span>
                                    </p>
                                </div>
                                <div class="row">
                                    <p class="label">
                                        <span class="med icon" />Medium
                                    </p>
                                    <p>
                                        <span class="percentage">{{ row.medEngagementPercent }}</span>
                                        <span class="visitors">({{ row.medEngagementVisitors }} Visitors)</span>
                                    </p>
                                </div>
                                <div class="row">
                                    <p class="label">
                                        <span class="low icon" />Low
                                    </p>
                                    <p>
                                        <span class="percentage">{{ row.lowEngagementPercent }}</span>
                                        <span class="visitors">({{ row.lowEngagementVisitors }} Visitors)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </pendo-popover>
            </template>
            <template #visitors="{ row }">
                <a
                    href="#"
                    @click.prevent="showVisitorSidePanel(row)">{{ row.visitors }}</a>
            </template>
            <template #utilization="{ row }">
                <utilization-percent-bar
                    v-if="row.quantity >= 0 && !isNil(row.utilization)"
                    :decimal-percent-value="row.utilization" />
                <span v-else />
            </template>
        </pendo-table>
        <pendo-drawer
            class-name="visitor-sidebar"
            :visible="visitorSidePanelState.visible"
            size="800px"
            @close="hideVisitorSidePanel">
            <template #header>
                <div class="visitor-sidebar--header">
                    <div class="visitor-sidebar--header-left">
                        <div class="visitor-sidebar--header-title">
                            {{ `${visitorSidePanelState.appName} Visitors` }}
                        </div>
                        <div class="visitor-sidebar--header-subtitle">
                            {{ `${dateRange.label} ${dateRange.aside || ''}` }}
                        </div>
                    </div>
                    <div class="visitor-sidebar--header-right">
                        <pendo-drawer-close />
                    </div>
                </div>
            </template>
            <template #body>
                <visitor-side-panel
                    v-bind="visitorSidePanelState"
                    @visitor-link-click="onVisitorDetailsRouteClick"
                    @refresh-visitors="refreshVisitors"
                    @download-csv="downloadCsv"
                    @show-hide-csv-modal="showHideCsvModal" />
            </template>
        </pendo-drawer>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Search from '@/components/Search';
import {
    PendoPopover,
    PendoTable,
    PendoLoading,
    PendoMultiselect,
    PendoDataSourceTrigger,
    PendoNotification,
    PendoDrawer,
    PendoAppDisplay,
    PendoDrawerClose
} from '@pendo/components';
import VisitorSidePanel from '@/stateless-components/analytics/licenses/VisitorSidePanel';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import UtilizationPercentBar from '@/stateless-components/analytics/licenses/UtilizationPercentBar';
import {
    getAppEngagement,
    getAppEngagementVisitors,
    getAppEngagementVisitorsCSV
} from '@/aggregations/license-utilization';
import { formatQuantity, formatTime, formatUtilization, downloadCSV } from '@/utils/license-utilization';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import endsWith from 'lodash/endsWith';
import isNil from 'lodash/isNil';
import { isCancel } from 'axios';
import { encodeIdForUri } from '@/utils/utils';

export default {
    name: 'LicenseUsageTable',
    components: {
        Search,
        PendoTable,
        PendoPopover,
        PendoMultiselect,
        PendoDataSourceTrigger,
        PendoDrawer,
        VisitorSidePanel,
        UtilizationPercentBar,
        PendoAppDisplay,
        PendoDrawerClose
    },
    directives: {
        PendoLoading,
        PendoNotification
    },
    data () {
        return {
            searchInput: '',
            loading: true,
            visitorSidePanelState: {
                visible: false
            },
            licenseEngagement: [],
            columnWidths: {}
        };
    },

    computed: {
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            appMapForActiveSubscription: 'apps/appMapForActiveSubscription',
            appsForAppIdFilter: 'filters/appsForAppIdFilter',
            visitorMetadataOptions: 'metadata/visitorMetadataOptions',
            metadataListByKind: 'metadata/metadataListByKind'
        }),
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            licenseDataMap: (state) => state.licenses.licenseDataMap,
            licenseUtilizationUserSettings: (state) => state.licenses.licenseUtilizationUserSettings
        }),
        dataHasChildren () {
            return this.licenseEngagementWithLicenseData.some((item) => {
                return item.children && item.children.length > 0;
            });
        },
        columns () {
            const columns = [
                {
                    prop: 'name',
                    label: 'Application Name',
                    sortable: true,
                    visible: true,
                    fixed: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'name')
                },
                {
                    prop: 'categories',
                    label: 'Category',
                    visible: true,
                    sortable: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'categories')
                },
                {
                    prop: 'group',
                    label: 'Group',
                    visible: true,
                    sortable: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'group')
                },
                {
                    prop: 'engagement',
                    label: 'Engagement',
                    width: 250,
                    visible: true,
                    sortable: false,
                    downloadable: false,
                    allowResize: false
                },
                {
                    prop: 'timeSpent',
                    label: 'Time Spent',
                    formatter: (row) => formatTime(row.timeSpent),
                    sortable: true,
                    visible: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'timeSpent')
                },
                {
                    prop: 'visitors',
                    label: 'Visitors',
                    sortable: true,
                    visible: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'visitors')
                },
                {
                    prop: 'quantity',
                    label: 'Licenses',
                    formatter: (row) => formatQuantity(row.quantity),
                    visible: true,
                    sortable: true,
                    downloadable: true,
                    width: get(this.columnWidths, 'quantity')
                },
                {
                    prop: 'utilization',
                    label: 'License Utilization',
                    formatter: (row) => formatUtilization(row.utilization),
                    sortable: true,
                    downloadable: true,
                    minWidth: 260,
                    visible: true,
                    allowResize: false
                }
            ];

            if (this.visitorMetadataSelected && this.dataHasChildren) {
                columns.unshift({
                    type: 'tree',
                    fixed: true,
                    allowReorder: false,
                    visible: true,
                    downloadable: false
                });
            }

            return columns;
        },
        filters () {
            return [
                {
                    prop: ['name', 'categories'],
                    value: this.searchInput
                }
            ];
        },
        licenseEngagementWithLicenseData () {
            return this.licenseEngagement.map((row) => {
                const license = get(this.licenseDataMap, row.appId, {});
                const categories = license.appCategories || [];
                const color = get(this.appMapForActiveSubscription, `${row.appId}.color`, null);
                const faviconB64 = get(this.appMapForActiveSubscription, `${row.appId}.faviconB64`, null);
                const platform = get(this.appMapForActiveSubscription, `${row.appId}.platform`, 'extension');
                const displayName = row.name;
                if (row.children) {
                    for (const metadataRow of row.children) {
                        metadataRow.categories_csv = categories;
                    }
                }

                let utilization = -1;
                if (!isNil(license.quantity)) {
                    if (row.visitors === 0) utilization = 0;
                    else utilization = !license.quantity ? NaN : row.visitors / license.quantity;
                }

                return {
                    ...row,
                    categories,
                    displayName,
                    color,
                    faviconB64,
                    platform,
                    quantity: isNil(license.quantity) ? -1 : license.quantity,
                    utilization,
                    utilization_csv: utilization === -1 ? null : utilization
                };
            });
        },
        visitorMetadataField () {
            return get(this.licenseUtilizationUserSettings, 'visitorMetadataField', '');
        },
        hasVisitorMetadata () {
            return this.visitorMetadataOptions.length > 0;
        },
        tableMetadataPlaceholder () {
            if (!this.visitorMetadataField) {
                return 'Total';
            }

            const { displayName } = this.visitorMetadataField;

            return displayName;
        },
        visitorMetadataSelected () {
            return !!this.visitorMetadataField;
        }
    },
    async created () {
        await this.refreshTable();

        // Set initial column widths here so that it doesn't impact the `column` computed property reactivity
        this.columnWidths = {
            name: get(this.licenseUtilizationUserSettings, 'usageTableSizes.name'),
            categories: get(this.licenseUtilizationUserSettings, 'usageTableSizes.categories'),
            group: get(this.licenseUtilizationUserSettings, 'usageTableSizes.group'),
            timeSpent: get(this.licenseUtilizationUserSettings, 'usageTableSizes.timeSpent'),
            visitors: get(this.licenseUtilizationUserSettings, 'usageTableSizes.visitors'),
            quantity: get(this.licenseUtilizationUserSettings, 'usageTableSizes.quantity')
        };

        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => this.refreshTable());
    },
    unmounted () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        isNil,
        ...mapActions({
            updateLicenseUtilizationUserSettings: 'licenses/updateLicenseUtilizationUserSettings'
        }),
        async refreshTable () {
            this.loading = true;
            if (this.aggCancel) {
                this.aggCancel.abort();
            }
            this.aggCancel = new AbortController();

            const { activeSegmentId: segmentId, appIdsFilter: appId } = this;
            const { count } = this.dateRange;
            const lowEngagementCap = Math.floor(count * 0.25);
            const medEngagementCap = Math.ceil(count * 0.5);
            let licenseEngagement;

            try {
                licenseEngagement = await getAppEngagement({
                    appId,
                    timeSeries: {
                        ...this.activeTimeSeries,
                        period: 'dayRange'
                    },
                    segmentId,
                    lowEngagementCap,
                    medEngagementCap,
                    signal: this.aggCancel.signal
                });

                const licenseEngagementMap = keyBy(licenseEngagement, 'id');

                if (this.visitorMetadataField) {
                    const licenseEngagementByMetadata = await getAppEngagement({
                        appId,
                        timeSeries: {
                            ...this.activeTimeSeries,
                            period: 'dayRange'
                        },
                        segmentId,
                        lowEngagementCap,
                        medEngagementCap,
                        metadataField: this.visitorMetadataField,
                        signal: this.aggCancel.signal
                    });

                    for (const metadataRow of licenseEngagementByMetadata) {
                        const parentRow = licenseEngagementMap[metadataRow.appId];
                        parentRow.children = parentRow.children || [];

                        const formattedRow = {
                            appId: metadataRow.appId,
                            categories: [' '],
                            name: ' ',
                            name_csv: parentRow.displayName,
                            quantity: ' ',
                            group: metadataRow.groupValue,
                            timeSpent: metadataRow.summedMinutes,
                            visitors: metadataRow.visitors,
                            lowEngagementVisitors: metadataRow.lowEngagementVisitors,
                            lowEngagementPercent: `${Math.round(metadataRow.lowEngagementPercent * 100)}%`,
                            medEngagementVisitors: metadataRow.medEngagementVisitors,
                            medEngagementPercent: `${Math.round(metadataRow.medEngagementPercent * 100)}%`,
                            highEngagementVisitors: metadataRow.highEngagementVisitors,
                            highEngagementPercent: `${Math.round(metadataRow.highEngagementPercent * 100)}%`,
                            utilization: null
                        };

                        parentRow.children.push(formattedRow);
                    }
                }
            } catch (err) {
                // If we've cancelled our aggs because a globalfilter value has changed, then loading should stay true
                if (!isCancel(err)) {
                    this.loading = false;
                    // eslint-disable-next-line no-console
                    console.error(err);
                    this.showErrorModal();
                }

                return;
            }

            const appFilterMap = keyBy(this.appsForAppIdFilter, 'id');

            // The apps source is used to populate empty rows for apps that do not have data
            // But it does not respect the apps filter, so rows that are not in the filter
            // have to be excluded back out
            licenseEngagement = licenseEngagement.filter((row) => {
                return !!appFilterMap[row.appId];
            });

            // Combine data from agg response and apps for Sub to get displayName
            // and format percentage values
            this.licenseEngagement = licenseEngagement.map((row) => {
                return {
                    appId: row.appId,
                    name: row.displayName,
                    group: 'All Groups',
                    timeSpent: row.summedMinutes,
                    visitors: row.visitors,
                    lowEngagementVisitors: row.lowEngagementVisitors,
                    lowEngagementPercent: `${Math.round(row.lowEngagementPercent * 100)}%`,
                    medEngagementVisitors: row.medEngagementVisitors,
                    medEngagementPercent: `${Math.round(row.medEngagementPercent * 100)}%`,
                    highEngagementVisitors: row.highEngagementVisitors,
                    highEngagementPercent: `${Math.round(row.highEngagementPercent * 100)}%`,
                    children: row.children
                };
            });

            this.loading = false;
        },
        exportToCSV (visibleColumns, data) {
            const columns = this.columns.filter((column) => column.downloadable);
            columns.splice(
                2,
                0,
                {
                    prop: 'highEngagementPercent',
                    label: 'High Engagement'
                },
                {
                    prop: 'medEngagementPercent',
                    label: 'Medium Engagement'
                },
                {
                    prop: 'lowEngagementPercent',
                    label: 'Low Engagement'
                }
            );

            const header = columns.map((column) => column.label).join(',');
            const filename = `license-utilization-${new Date().getTime()}.csv`;
            const csvData = `\ufeff${header}\n${this.tableDataToCSV(columns, data)}`;

            return downloadCSV({ csvData, filename });
        },
        tableDataToCSV (tableColumns, tableData) {
            const csvNewLine = '\r\n';
            const csvDelimiter = ',';
            let data = '';
            // eslint-disable-next-line id-length
            for (let r = 0; r < tableData.length; r++) {
                const row = cloneDeep(tableData[r]);

                // metadata child rows have blank columns that should be populated from their `_csv` equivalent on download
                for (const key of Object.keys(row)) {
                    if (endsWith(key, '_csv')) {
                        const targetKey = key.slice(0, -4);
                        row[targetKey] = row[key];
                    }
                }

                for (let c = 0; c < tableColumns.length; c++) {
                    let rowValue;
                    const col = tableColumns[c];
                    if (col.formatter) {
                        rowValue = col.formatter(row, col);
                    } else {
                        rowValue = get(row, col.prop);
                    }
                    if ([undefined, null, '--', 'NaN%'].includes(rowValue)) {
                        rowValue = '';
                    }
                    data = `${data + (c ? csvDelimiter : '')}"${String(rowValue).trim()}"`;
                }
                data += csvNewLine;
            }

            return data;
        },
        onVisitorMetadataSelected (metadata) {
            const { group: currentGroup, field: currentField, kind } = this.visitorMetadataField || {};
            const { group: selectedGroup, field: selectedField } = metadata;
            if (currentGroup === selectedGroup && currentField === selectedField && kind === 'visitor') {
                this.$refs.tableMetadataChooser.toggleMenu();

                return;
            }

            const licenseUtilizationUserSettings = cloneDeep(this.licenseUtilizationUserSettings) || {};
            licenseUtilizationUserSettings.visitorMetadataField = metadata;
            this.updateLicenseUtilizationUserSettings({ licenseUtilizationUserSettings });
            this.$refs.tableMetadataChooser.toggleMenu();
            this.refreshTable();
        },
        showErrorModal () {
            PendoNotification({
                type: 'error',
                title: 'Something went wrong. Please try again later.'
            });
        },
        onColumnResized ({ column, width }) {
            const licenseUtilizationUserSettings = cloneDeep(this.licenseUtilizationUserSettings) || {};
            if (!licenseUtilizationUserSettings.usageTableSizes) {
                licenseUtilizationUserSettings.usageTableSizes = {};
            }

            licenseUtilizationUserSettings.usageTableSizes[column] = width;
            this.updateLicenseUtilizationUserSettings({
                licenseUtilizationUserSettings
            });
        },
        showVisitorSidePanel (row) {
            this.visitorSidePanelState = {
                appId: row.appId,
                appName: row.name === ' ' ? row.name_csv : row.name,
                showCsvModal: false,
                csvModalStatus: 'resolved',
                csvUrl: '',
                filter: row.name_csv ? row.group : null,
                loading: true,
                metadataField: this.visitorMetadataField,
                metadataFields: this.metadataListByKind('visitor', { groupsToExclude: ['auto', 'pendo'] }),
                segmentId: this.activeSegmentId,
                visitors: null,
                visible: true
            };
        },
        hideVisitorSidePanel () {
            this.visitorSidePanelState = {
                ...this.visitorSidePanelState,
                visible: false
            };
        },
        async refreshVisitors () {
            this.visitorSidePanelState.loading = true;
            if (this.aggCancel) {
                this.aggCancel.abort();
            }
            this.aggCancel = new AbortController();

            const { appId, segmentId, metadataFields, metadataField } = this.visitorSidePanelState;
            try {
                let filter = null;
                if (this.visitorSidePanelState.filter) {
                    filter = {
                        group: metadataField.group,
                        field: metadataField.field,
                        value: this.visitorSidePanelState.filter
                    };
                }
                const visitors = await getAppEngagementVisitors({
                    appId,
                    timeSeries: {
                        ...this.activeTimeSeries,
                        period: 'dayRange'
                    },
                    segmentId,
                    filter,
                    metadataFields,
                    limit: 5000,
                    signal: this.aggCancel.signal
                });

                this.visitorSidePanelState.visitors = visitors;
            } catch (err) {
                // If we've cancelled our aggs because a globalfilter value has changed, then loading should stay true
                if (!isCancel(err)) {
                    this.visitorSidePanelState.loading = false;
                    // eslint-disable-next-line no-console
                    console.error(err);
                    this.showErrorModal();
                }

                return;
            }
            this.visitorSidePanelState.loading = false;
        },
        async downloadCsv (csvColumns) {
            const { appId, segmentId, metadataFields, metadataField } = this.visitorSidePanelState;
            this.visitorSidePanelState.csvModalStatus = 'loading';
            this.visitorSidePanelState.showCsvModal = true;

            let filter = null;
            try {
                if (this.visitorSidePanelState.filter) {
                    filter = {
                        group: metadataField.group,
                        field: metadataField.field,
                        value: this.visitorSidePanelState.filter
                    };
                }

                const {
                    data: { url }
                } = await getAppEngagementVisitorsCSV({
                    appId,
                    timeSeries: {
                        ...this.activeTimeSeries,
                        period: 'dayRange'
                    },
                    segmentId,
                    metadataFields,
                    filter,
                    columns: csvColumns
                });

                this.visitorSidePanelState.csvUrl = url;

                this.visitorSidePanelState.csvModalStatus = 'resolved';
            } catch (error) {
                this.visitorSidePanelState.csvModalStatus = 'rejected';
                // eslint-disable-next-line no-console
                console.error(error);
                this.showErrorModal();
            }
        },
        showHideCsvModal (modalVisibility) {
            this.visitorSidePanelState.showCsvModal = modalVisibility;
        },
        async onVisitorDetailsRouteClick (visitorId) {
            const { href } = this.$router.resolve({
                name: 'visitorDetails',
                params: { visitorId: encodeIdForUri(visitorId) }
            });
            window.open(href, '_blank');
        }
    }
};
</script>

<style lang="scss">
.license-usage-table {
    .header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    .visitor-count {
        font-weight: 500;
    }
}

.metadata-chooser {
    padding: 8px 0;
    border-bottom: 1px solid $gray-lighter-6;
    overscroll-behavior: none;

    .pendo-multiselect__element,
    .pendo-multiselect__option {
        height: 26px;

        &:hover {
            background-color: $gray-lighter-7;
        }
    }
}

.license-utilization--table-search {
    margin-right: 10px;
}

.engagement-bar {
    display: flex;
    height: 10px;
    width: 200px;

    .high {
        background: #1e43a6;
        border: 1px solid #ffffff;
    }

    .medium {
        background: #a2b7f0;
        border: 1px solid #ffffff;
    }

    .low {
        background: #dbd8e9;
        border: 1px solid #ffffff;
    }
}

.engagement-tooltip {
    padding: 15px;
    height: 156px;
    width: 244px;

    .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
        font-weight: 600;
        line-height: 1.2;
    }

    .subtitle {
        font-size: 12px;
        font-weight: 400;
        margin: 5px 0 15px;
    }

    .rows {
        position: absolute;
        padding: 5px 20px;
        left: 0;
        width: 100%;
        border-top: 1px solid #c4c4c4;
    }

    .row {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        margin: 10px 0;
        height: 12px;

        .label {
            display: flex;
            align-items: center;
        }

        .percentage {
            font-weight: 600;
        }

        .visitors {
            margin-left: 3px;
        }

        .icon {
            border-radius: 50%;
            height: 12px;
            width: 12px;
            margin-right: 5px;

            &.high {
                background: #1e43a6;
            }

            &.med {
                background: #a2b7f0;
            }

            &.low {
                background: #dbd8e9;
            }
        }
    }
}

:deep(.pendo-table__column:not(.pendo-table__column--actions)) {
    .pendo-table__cell:empty::after {
        content: '';
    }
}

.visitor-sidebar {
    .pendo-drawer__header {
        padding-left: 18px;
        padding-right: 18px;
    }

    &--header {
        margin: 24px 0;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-left {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &-right {
            display: flex;
            align-items: center;
        }

        &-title {
            font-size: 22px;
            font-weight: 600;
        }

        &-subtitle {
            font-size: 16px;
        }
    }
}
</style>
