<template>
    <div class="segment-super-panel">
        <div class="settings-panel--divider" />
        <div class="settings-panel__section">
            <div class="section-label">
                Segment Flag <span class="super-only">(Super Only)</span>
                <pendo-icon
                    v-pendo-tooltip="{
                        arrow: true,
                        multiline: true,
                        content: 'Add a segment flag name to use this segment to hide or show features in code.'
                    }"
                    type="info"
                    size="14" />
            </div>
            <segment-flag-input
                :disabled="segmentFlagInputDisabled"
                :value="value"
                v-on="$listeners" />
        </div>
        <div class="settings-panel--divider" />
        <div class="settings-panel__section">
            <div class="section-label">
                Conditions <span class="super-only">(Super Only)</span>
            </div>
            <segment-debugger-pane :pipeline-json="pipelineJson" />
        </div>
    </div>
</template>

<script>
import SegmentFlagInput from './SegmentFlagInput';
import SegmentDebuggerPane from './SegmentDebuggerPane';
import { PendoIcon, PendoTooltip } from '@pendo/components';

export default {
    name: 'SegmentSuperPanel',
    components: {
        SegmentFlagInput,
        SegmentDebuggerPane,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    props: {
        value: {
            type: String,
            default: null
        },
        pipelineJson: {
            type: String,
            default: null
        },
        segmentFlagInputDisabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.segment-super-panel {
    .super-only {
        color: $gray-lighter-3;
        font-weight: normal;
    }
}
</style>
