<template>
    <carousel
        v-if="stepsWithDoms"
        :class="{ 'navigation-enabled': navigationEnabled }"
        :mouse-drag="false"
        :navigate-to="focusedPageIndex"
        :navigation-enabled="navigationEnabled"
        :pagination-enabled="navigationEnabled"
        :per-page="perPage"
        :per-page-custom="perPageCustom"
        :navigation-prev-label="navigationPrevArrow"
        :navigation-next-label="navigationNextArrow">
        <slide
            v-for="(step, index) in stepsWithDoms"
            :key="`step-preview-${step.id}`"
            class="step-wrapper">
            <slot
                name="overlay"
                :step="step" />
            <guide-step-preview
                v-if="!!step.dom"
                :key="step.key || `${step.id}-${previewLanguage}`"
                ref="guideStepPreview"
                :dom="step.dom"
                :guides="!taskList ? [guide] : guides"
                :step="step"
                :watermark-dom="watermarkDom"
                :step-count="steps.length"
                :resource-centers="resourceCenters"
                :is-mobile="isMobile"
                :has-guide-localization="hasGuideLocalization"
                class="slide-content" />
            <step-display
                class="step-preview--label"
                :index="steps.length > 1 ? index : null"
                :app="showApp ? step.app : null" />
        </slide>
    </carousel>
</template>

<script>
import Carousel from '@/components/guides/carousel/carousel';
import Slide from '@/components/guides/carousel/slide';
import get from 'lodash/get';
import StepDisplay from '@/components/common/StepDisplay';
import GuideStepPreview from '@/components/guides/settings/GuideStepPreview';
import { navigationPrevArrow, navigationNextArrow } from '@/constants/svgs';
import { TaskList } from '@pendo/services/TaskList';

export default {
    name: 'StepPreviewCarousel',
    components: {
        Carousel,
        GuideStepPreview,
        Slide,
        StepDisplay
    },
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        guides: {
            type: Array,
            default: () => []
        },
        guide: {
            type: Object,
            required: true
        },
        watermark: {
            type: Object,
            default: null
        },
        perPageCustom: {
            type: Array,
            default: () => [[1100, 3]]
        },
        focusedPageIndex: {
            type: Number,
            default: 0
        },
        resourceCenters: {
            type: Array,
            default: () => []
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        hasGuideLocalization: {
            type: Boolean,
            default: false
        },
        showApp: {
            type: Boolean,
            default: false
        },
        previewLanguage: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            windowWidth: window.innerWidth,
            navigationPrevArrow,
            navigationNextArrow
        };
    },
    computed: {
        stepsWithDoms () {
            return this.steps.filter((step) => !!step.dom);
        },
        navigationEnabled () {
            return this.steps.length > this.perPage;
        },
        perPage () {
            const matchingPerPageTuple = this.perPageCustom.find((tuple) => this.windowWidth >= tuple[0]) || [
                -Infinity,
                1
            ];

            return matchingPerPageTuple[1];
        },
        watermarkDom () {
            if (get(this.watermark, 'state') !== 'public') return '';

            return get(this.watermark, 'steps[0].dom');
        },
        taskList () {
            return TaskList.getTaskListUsingDomJSON(this.guide);
        }
    },
    methods: {
        getGuidePreview () {
            // Used by parent to generate pdf
            return this.$refs.guideStepPreview;
        }
    }
};
</script>

<style scoped lang="scss">
.step {
    &-wrapper {
        padding: 15px 20px;
        height: 310px;
        width: 33.3%;
    }

    &-overlay {
        height: 275px;
    }

    &-preview--label {
        display: flex;
        align-items: baseline;
        font-size: 14px;
        color: $gray-primary;
        align-self: center;
        max-width: 100%;
    }
}
</style>
