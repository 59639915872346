<template>
    <pendo-modal
        :visible="visible"
        class="delete-entity-modal"
        height="auto"
        max-height="80%"
        :title="modalTitle"
        @close="closeModal">
        <template #body>
            <pendo-alert
                v-if="deleteCustomEntityError"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
            <span v-if="!isEntityReferenced">
                {{ modalDeleteConfirmationText }}
            </span>
            <span v-else-if="isEntityReferenced && !featuresUsingCustomEntity.length">
                This {{ type }} is referenced in one or more places and will break the following:
            </span>
            <div v-if="guidesUsingCustomEntity.length">
                <strong
                    class="delete-entity-modal--warning-spacing delete-entity-modal--error-text">The following guides are targeted to this {{ type }} and must be retargeted before you can
                    delete</strong>
                <div
                    v-for="guide in guidesUsingCustomEntity"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
                <pendo-divider stroke="#dadce5" />
            </div>
            <div v-if="segmentsUsingCustomEntity.length">
                <strong class="delete-entity-modal--warning-spacing">Segments that reference this {{ type }}</strong>
                <div
                    v-for="segment in segmentsUsingCustomEntity"
                    :key="segment.id">
                    {{ segment.name }}
                </div>
            </div>
            <div v-if="guidesUsingSegmentsUsingCustomEntity.length">
                <strong
                    class="delete-entity-modal--warning-spacing">Guides using segments that reference this {{ type }}</strong>
                <div
                    v-for="guide in guidesUsingSegmentsUsingCustomEntity"
                    :key="guide.id">
                    {{ guide.name }}
                </div>
            </div>
            <div v-if="featuresUsingCustomEntity.length">
                <span class="delete-entity-modal--warning-spacing">
                    Pages that have tagged Features cannot be deleted from Pendo. Proceed by either removing the tagged
                    Feature(s) shown below or editing the page rule.
                </span>
                <div
                    v-for="feature in featuresUsingCustomEntity"
                    :key="feature.id">
                    {{ feature.name }}
                </div>
            </div>
        </template>
        <template #footer>
            <div class="delete-entity-modal--footer">
                <pendo-button
                    theme="app"
                    type="secondary"
                    label="Cancel"
                    @click="closeModal" />
                <div
                    v-pendo-tooltip="
                        guidesUsingCustomEntity.length && {
                            arrow: true,
                            multiline: true,
                            content: 'Resolve guide references to enable deletion of this page.'
                        }
                    ">
                    <pendo-button
                        :disabled="isEntityReferenced"
                        :loading="isDeletingCustomEntity"
                        theme="app"
                        type="danger"
                        label="Delete"
                        @click="handleDeleteCustomEntity" />
                </div>
            </div>
        </template>
    </pendo-modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { PendoAlert, PendoButton, PendoDivider, PendoModal, PendoLoading, PendoTooltip } from '@pendo/components';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default {
    name: 'DeleteCustomEntityModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoDivider,
        PendoModal
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'page'
        },
        entity: {
            type: Object,
            required: true
        }
    },
    emits: ['close'],
    data () {
        return {
            isDeletingCustomEntity: false,
            deleteCustomEntityError: false,
            localEntity: {},
            setTimeoutId: null
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.pages.error || state.features.error
        }),
        ...mapGetters({
            featuresList: 'features/list',
            guidesList: 'guides/list',
            segmentsList: 'filters/segmentsList',
            usesMultiApp: 'subscriptions/usesMultiApp'
        }),
        modalTitle () {
            return this.isEntityReferenced
                ? `Unable to Delete ${startCase(this.type)}`
                : `Delete ${startCase(this.type)}`;
        },
        modalDeleteConfirmationText () {
            return `Are you sure you want to delete the '${get(this.localEntity, 'displayName') || ''}' ${this.type}?`;
        },
        isEntityReferenced () {
            return (
                !!this.guidesUsingCustomEntity.length ||
                !!this.segmentsUsingCustomEntity.length ||
                !!this.guidesUsingSegmentsUsingCustomEntity.length ||
                !!this.featuresUsingCustomEntity.length
            );
        },
        guidesUsingCustomEntity () {
            if (isEmpty(this.localEntity)) {
                return [];
            }
            const entityId = this.type === 'page' ? 'pageId' : 'featureId';
            const guidesUsingCustomEntity = this.guidesList.filter((guide) => {
                return guide.steps.some((guideStep) => {
                    return this.localEntity.id && guideStep[entityId] === this.localEntity.id;
                });
            });

            return sortBy(guidesUsingCustomEntity, 'name');
        },
        segmentsUsingCustomEntity () {
            if (isEmpty(this.localEntity)) {
                return [];
            }
            const segmentsUsingCustomEntity = this.segmentsList.filter((segment) => {
                if (!segment.pipeline) return;
                const segmentMatch = JSON.stringify(segment.pipeline).includes(this.localEntity.id);

                return segmentMatch;
            });

            return sortBy(segmentsUsingCustomEntity, 'name');
        },
        guidesUsingSegmentsUsingCustomEntity () {
            const guidesUsingSegmentsUsingCustomEntity = this.guidesList.filter((guide) => {
                if (!guide.audience) return;
                const stringifiedGuideSegment = JSON.stringify(guide.audience);
                const segmentMatch = this.segmentsUsingCustomEntity.some((segment) =>
                    stringifiedGuideSegment.includes(segment.id)
                );

                return segmentMatch;
            });

            return sortBy(guidesUsingSegmentsUsingCustomEntity, 'name');
        },
        featuresUsingCustomEntity () {
            if (isEmpty(this.localEntity)) {
                return [];
            }
            let featuresUsingCustomEntity = [];
            if (this.type === 'page') {
                const pageId = this.usesMultiApp ? this.localEntity.id : this.localEntity.pageId;
                featuresUsingCustomEntity = this.featuresList.filter(
                    (feature) =>
                        feature.appId === this.localEntity.appId &&
                        feature.appWide === false &&
                        feature.pageId === pageId
                );
            }

            return sortBy(featuresUsingCustomEntity, 'name');
        }
    },
    watch: {
        entity (newEntity) {
            if (isEmpty(newEntity)) {
                // the modal flashes when reset before it becomes invisible
                this.setTimeoutId = setTimeout(() => {
                    this.localEntity = newEntity;
                    this.setTimeoutId = null;
                }, 2000);
            } else {
                if (this.setTimeoutId) {
                    clearTimeout(this.setTimeoutId);
                    this.setTimeoutId = null;
                }
                this.localEntity = newEntity;
            }
        }
    },
    methods: {
        ...mapActions({
            _deleteCustomPage: 'pages/deleteCustomPage',
            _deleteCustomFeature: 'features/deleteCustomFeature'
        }),
        closeModal () {
            this.deleteCustomEntityError = false;
            this.$emit('close', { didDelete: false });
        },
        async handleDeleteCustomEntity () {
            this.deleteCustomEntityError = false;
            this.isDeletingCustomEntity = true;

            switch (this.type) {
                case 'page':
                    await this.handleDeleteCustomPage();
                    break;
                case 'feature':
                    await this.handleDeleteCustomFeature();
                    break;
            }

            this.isDeletingCustomEntity = false;

            if (this.error) {
                this.deleteCustomEntityError = true;

                return;
            }

            this.$emit('close', { didDelete: true });
        },
        async handleDeleteCustomFeature () {
            if (isEmpty(this.localEntity)) {
                return;
            }
            let { featureId, appId } = this.localEntity;

            if (this.usesMultiApp) {
                featureId = this.localEntity.id;
            }

            const customFeature = {
                featureId,
                appId
            };

            await this._deleteCustomFeature({ customFeature });
        },
        async handleDeleteCustomPage () {
            if (isEmpty(this.localEntity)) {
                return;
            }
            let { pageId, appId } = this.localEntity;

            if (this.usesMultiApp) {
                pageId = this.localEntity.id;
            }

            const customPage = {
                pageId,
                appId
            };

            await this._deleteCustomPage({ customPage });
        }
    }
};
</script>

<style lang="scss">
.delete-entity-modal {
    &--warning-spacing {
        display: block;
        margin: 8px 0;
    }

    &--error-text {
        color: $red-error;
    }

    &--footer {
        display: flex;
        justify-content: flex-end;
    }
}

.delete-entity-modal {
    .pendo-button {
        margin-left: 8px;
    }

    .pendo-alert {
        margin-bottom: 18px;
    }
}
</style>
