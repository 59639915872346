import { operators as o } from '@pendo/aggregations';
import { pathAggregation, processResponse } from '@pendo/services/Paths';
import {
    request,
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';

export async function getPath ({ path, aggregation }) {
    const spec = o.aggregation(
        {
            name: 'path',
            ...getProductAreaAndSubArea()
        },
        aggregation
    );
    const { messages } = await request(spec, { rowsOnly: false });

    return processResponse(path, messages[0].rows);
}

export function buildPathSpec ({ timeSeries, config }) {
    config.segmentId = parseSegmentIdForAggregation(config.segmentId);

    return pathAggregation.build({ config, timeSeries });
}

export function getProductAreaAndSubArea () {
    return {
        productArea: PRODUCT_AREAS.ANALYTICS,
        subProductArea: SUB_PRODUCT_AREAS.PATHS
    };
}
