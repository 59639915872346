<template>
    <div class="path-delete-action">
        <pendo-icon-button
            tooltip="Delete"
            icon="trash-2"
            @click="onClick" />
        <path-delete-modal
            :report="report"
            :is-visible="isConfirmationVisible"
            @close="isConfirmationVisible = false" />
    </div>
</template>

<script>
import { PendoIconButton } from '@pendo/components';
import PathDeleteModal from '@/components/paths/PathDeleteModal';

export default {
    name: 'PathDeleteAction',
    components: {
        PendoIconButton,
        PathDeleteModal
    },
    props: {
        report: {
            type: Object,
            required: true
        },
        // eslint-disable-next-line vue/no-unused-properties
        modalOverride: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isConfirmationVisible: false
        };
    },
    methods: {
        onClick () {
            this.isConfirmationVisible = true;
        }
    }
};
</script>
