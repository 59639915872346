import * as Agg2 from '@pendo/aggregations';
import { identifiedState } from '@/utils/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { requestVisitorOrAccountIdCsv } from './utils';

export function getGuideElementsActivity ({ appIds, guideId, currentIds, segmentId, timeSeries }) {
    const spec = buildGuideElementsActivitySpec({ appIds, guideId, currentIds, segmentId, timeSeries });

    return request(spec);
}

export function buildGuideElementsActivitySpec ({ appIds, guideId, currentIds, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'guide-elements-activity',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, guideId, timeSeries }),
            o.filter('eventSubType(type) == "guideActivity"'),
            o.filter('uiElementType != ""'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.evaluate({
                elementType: 'eventSubType(type)',
                isButton: 'if(uiElementType == "BUTTON", true, false)',
                isDismiss: 'if(contains(uiElementId, "pendo-close-guide-"), true, false)',
                isLink: 'if(uiElementType == "A", true, false)',
                isDeleted: `!contains(${JSON.stringify(currentIds)}, uiElementId)`,
                isSwipeLeft: 'if(uiElementType == "LEFT-SWIPER", true, false)',
                isSwipeRight: 'if(uiElementType == "RIGHT-SWIPER", true, false)'
            }),
            o.evaluate({
                elementType: 'if(isButton, "Button", elementType)'
            }),
            o.evaluate({
                elementType: 'if(isDismiss, "Close Button", elementType)'
            }),
            o.evaluate({
                elementType: 'if(isLink, "Link", elementType)'
            }),
            o.evaluate({
                elementType: 'if(isSwipeLeft, "Swiped Left", elementType)'
            }),
            o.evaluate({
                elementType: 'if(isSwipeRight, "Swiped Right", elementType)'
            }),
            o.fork(
                o.pipeline(
                    o.group(
                        ['guideId', 'guideStepId', 'uiElementId', 'elementType', 'isDeleted'],
                        o.groupField('numClicks', o.count(null))
                    )
                ),
                o.pipeline(
                    o.group(['uiElementId', 'uiElementText'], o.groupField('mostRecent', { max: 'browserTime' })),
                    o.sort(['mostRecent'])
                ),
                o.pipeline(
                    o.group(['uiElementId', 'uiElementActions'], o.groupField('mostRecent', { max: 'browserTime' })),
                    o.sort(['mostRecent'])
                )
            ),
            o.join('uiElementId'),
            o.select({
                guideId: 'guideId',
                guideStepId: 'guideStepId',
                uiElementId: 'uiElementId',
                uiElementType: 'elementType',
                uiElementActions: 'uiElementActions',
                uiElementText: 'uiElementText',
                numClicks: 'numClicks',
                isDeleted: 'isDeleted'
            })
        )
    );
}

export function getGuideElementsActivityClicks ({ appIds, guideId, timeSeries, segmentId, uiElementId }) {
    const spec = buildGuideElementsActivityClicksSpec({ appIds, guideId, timeSeries, segmentId, uiElementId });

    return request(spec);
}

export function buildGuideElementsActivityClicksSpec ({ appIds, guideId, timeSeries, segmentId, uiElementId }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'guide-elements-clicks',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, guideId, timeSeries }),
            o.filter('eventSubType(type) == "guideActivity"'),
            o.filter('uiElementType != ""'),
            o.filter(`uiElementId == "${uiElementId}"`),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.fork(
                o.pipeline(
                    o.group('visitorId', o.groupField('numClicks', o.count(null))),
                    o.sort(['-numClicks', 'visitorId']),
                    o.filter('!isNull(visitorId) && visitorId!=""'),
                    o.evalExpression({
                        'visitor.id': 'visitorId',
                        'visitor.clicks': 'numClicks'
                    }),
                    o.reduce({
                        visitors: o.list('visitor')
                    })
                ),
                o.pipeline(
                    o.group('accountId', o.groupField('numClicks', o.count(null))),
                    o.sort(['-numClicks', 'accountId']),
                    o.filter('!isNull(accountId) && accountId!=""'),
                    o.evalExpression({
                        'account.id': 'accountId',
                        'account.clicks': 'numClicks'
                    }),
                    o.reduce({
                        accounts: o.list('account')
                    })
                )
            ),
            o.join()
        )
    );
}

export function getGuideElementsClicksCsv ({ appIds, guideId, timeSeries, segmentId, uiElementId, kind }) {
    const spec = buildGuideElementsClicksSpecCsv({ appIds, guideId, timeSeries, segmentId, uiElementId, kind });

    return requestVisitorOrAccountIdCsv(spec, kind, { shouldIncludeClicks: true });
}

export function buildGuideElementsClicksSpecCsv ({ appIds, guideId, timeSeries, segmentId, uiElementId, kind }) {
    const { operators: o } = Agg2;

    const select = {
        visitor: {
            id: 'visitorId',
            clicks: 'numClicks'
        },
        account: {
            id: 'if(isNull(accountId)||accountId=="", "(none)", accountId)',
            clicks: 'numClicks'
        }
    }[kind];

    return o.aggregation(
        {
            name: 'guide-elements-click-csv',
            productArea: PRODUCT_AREAS.GUIDES,
            subProductArea: SUB_PRODUCT_AREAS.GUIDE_METRICS
        },
        o.pipeline(
            o.sources.guideEvents({ appId: appIds, guideId, timeSeries }),
            o.filter('eventSubType(type) == "guideActivity"'),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.filter('uiElementType != ""'),
            o.filter(`uiElementId == "${uiElementId}"`),
            o.group(`${kind}Id`, o.groupField('numClicks', o.count(null))),
            o.sort(['-numClicks', `${kind}Id`]),
            o.select(select)
        )
    );
}
