<template>
    <div class="segment-debugger-pane">
        <div class="conditions-pipeline">
            <pendo-card>
                <pendo-button
                    v-pendo-clipboard="pipelineJson"
                    theme="app"
                    type="tertiary"
                    size="mini"
                    icon="clipboard"
                    icon-size="14" />
                <pre><code>{{ pipelineJson }}</code></pre>
            </pendo-card>
        </div>
    </div>
</template>

<script>
import { PendoCard, PendoButton, PendoClipboard } from '@pendo/components';

export default {
    name: 'SegmentDebuggerPane',
    components: {
        PendoCard,
        PendoButton
    },
    directives: {
        PendoClipboard
    },
    props: {
        pipelineJson: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
.segment-debugger-pane {
    .conditions-pipeline {
        position: relative;

        pre {
            padding: 9.5px;
            margin: 0 0 10px;
            line-height: 21px;
            white-space: pre-wrap;
            word-break: break-word;
        }

        .pendo-button {
            position: absolute;
            top: 2px;
            right: 8px;
        }

        .pendo-card {
            background-color: $gray-lighter-8;
        }
    }
}
</style>
