<template>
    <div class="resource-guide-event-selector">
        <span class="resource-guide-event-selector__text"> is </span>
        <pendo-multiselect
            :class="'resource-guide-event-selector__guide-event'"
            :value="resourceGuideEvent"
            :searchable="false"
            :disabled="disabled"
            preselect-first
            :allow-empty="false"
            :options="resourceGuideEventOptions"
            @select="onGuideEventSelect">
            <template #trigger>
                <div
                    class="resource-guide-event-selector__trigger"
                    data-cy="resource-guide-event-selector">
                    <pendo-data-source-trigger />
                </div>
            </template>
        </pendo-multiselect>
        <template v-if="canSelectStep">
            <span class="resource-guide-step-selector__text"> at </span>
            <pendo-multiselect
                :class="'resource-guide-event-selector__guide-step'"
                :value="resourceGuideStep"
                value-key="stepId"
                :searchable="false"
                :disabled="disabled"
                preselect-first
                :allow-empty="false"
                :options="resourceGuideStepOptions"
                @select="onGuideStepSelect">
                <template #option="{ option }">
                    <div class="option-wrapper">
                        {{ option.selectedLabel }}
                    </div>
                </template>
                <template #trigger>
                    <div
                        v-pendo-tooltip="{ content: resourceGuideStep ? resourceGuideStep.selectedLabel : undefined }"
                        class="resource-guide-step-selector__trigger"
                        data-cy="resource-guide-step-selector">
                        <pendo-data-source-trigger />
                    </div>
                </template>
            </pendo-multiselect>
        </template>
    </div>
</template>
<script>
import { PendoMultiselect, PendoDataSourceTrigger, PendoTooltip } from '@pendo/components';
import get from 'lodash/get';

export default {
    name: 'SingleResourceGuideEventSelector',
    components: {
        PendoMultiselect,
        PendoDataSourceTrigger
    },
    directives: {
        PendoTooltip
    },
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        resource: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select'],
    data () {
        return {
            dismissed: { id: 'dismissed', eventType: 'guideDismissed', label: 'Dismissed' },
            seen: { id: 'seen', eventType: 'guideSeen', label: 'Seen' },
            notSeen: { id: 'guideNotSeen', eventType: 'guideNotSeen', label: 'Not seen (control group)' },
            resourceGuideEvent: null,
            resourceGuideStep: null
        };
    },
    computed: {
        resourceGuideEventOptions () {
            return [this.seen, this.dismissed];
        },
        stepOptions () {
            const guideStepId = get(this, 'item.guideStepId');
            const steps = get(this, 'resource.steps', []);
            const stepOptions = steps.map((step, i) => {
                const label = `Step ${i + 1}`;

                return {
                    stepId: step.id,
                    label,
                    selectedLabel: step.name ? `${step.name} (${label})` : label
                };
            });
            const guideStepIdNotFound = !stepOptions.find((guideStep) => guideStep.stepId === guideStepId);
            if (guideStepId && guideStepIdNotFound) {
                stepOptions.push({
                    stepId: guideStepId,
                    label: 'Deleted step',
                    selectedLabel: `Deleted step (${guideStepId})`
                });
            }

            return stepOptions;
        },
        // test for commit
        canSelectStep () {
            if (!this.resourceGuideEvent) {
                return false;
            }
            if (this.stepOptions.length < 2) {
                return false;
            }

            return this.resourceGuideEvent !== this.notSeen;
        },
        resourceGuideStepOptions () {
            if (!this.canSelectStep) {
                return [];
            }

            return this.stepOptions;
        }
    },
    watch: {
        resource () {
            this.resourceGuideStep = this.resourceGuideStepOptions[0];
            this.onGuideEventSelect(this.resourceGuideEvent);
        }
    },
    created () {
        this.resourceGuideEvent = this.resourceGuideEventOptions.find(
            ({ eventType }) => eventType === this.item.guideEventTypes
        );
        this.resourceGuideStep = this.resourceGuideStepOptions.find(({ stepId }) => stepId === this.item.guideStepId);
    },
    methods: {
        onGuideEventSelect (option) {
            this.resourceGuideEvent = option;
            const steps = get(this, 'resource.steps', []);
            const payload = {
                guideEventTypes: option.eventType
            };
            const shouldPersistStepSelection = this.canSelectStep && this.resourceGuideStep;
            if (shouldPersistStepSelection) {
                payload.guideStepId = this.resourceGuideStep.stepId;
            } else if (!this.canSelectStep && steps.length === 1) {
                payload.guideStepId = steps[0].id;
            }
            this.$emit('select', payload);
        },
        onGuideStepSelect (option) {
            this.resourceGuideStep = option;
            const payload = {
                guideEventTypes: this.resourceGuideEvent.eventType
            };
            payload.guideStepId = option.stepId;
            this.$emit('select', payload);
        }
    }
};
</script>
<style scoped lang="scss">
.resource-guide-event-selector {
    padding-left: 10px;
}

:deep(.pendo-loading-spinner svg) {
    height: 14px;
    width: 14px;
}

.option-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pendo-multiselect {
    padding-left: 10px;
}
</style>
