import { operators as o } from '@pendo/aggregations';
import { identifiedState } from '@/utils/aggregations';
import {
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';

export const MAX_LIMIT = 5000;

export const ERROR_FIELDS = ['error'];

export function eventPropertySelectMap (eventPropertyNames, key = 'properties') {
    return eventPropertyNames.reduce((acc, eventPropertyName) => {
        if (key === '__sg__') {
            acc[`${eventPropertyName}`] = `${eventPropertyName}`;
        } else {
            acc[`${key}.${eventPropertyName}`] = `${key}.${eventPropertyName}`;
        }

        return acc;
    }, {});
}

export function eventPropertyGroupArray (eventPropertyNames, key = 'properties') {
    return eventPropertyNames.map((eventPropertyName) => {
        if (key === '__sg__') {
            return `${eventPropertyName}`;
        }

        return `${key}.${eventPropertyName}`;
    }, []);
}

export const trackEvents = {
    name: 'TrackEventBreakdownAggregation',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.TRACK_EVENT_LIST_DETAILS,
    response: () => {
        return {
            location: 'request',
            mimeType: 'application/json'
        };
    },
    build: ({
        id,
        timeSeries,
        segmentId,
        customEventPropertyNames = [],
        devicePropertyNames = [],
        historicalMetadataProperties = []
    }) =>
        o.pipeline(
            o.sources.trackEvents({
                blacklist: 'apply',
                trackTypeId: id,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.userAgent('parsedUserAgent'),
            o.filter('numEvents > 0'),
            o.select({
                day: 'day',
                visitorId: 'visitorId',
                numEvents: 'numEvents',
                ...eventPropertySelectMap(customEventPropertyNames),
                ...eventPropertySelectMap(devicePropertyNames, 'parsedUserAgent'),
                ...eventPropertySelectMap(historicalMetadataProperties, '__sg__')
            }),
            o.group(
                [
                    'day',
                    'visitorId',
                    ...eventPropertyGroupArray(customEventPropertyNames),
                    ...eventPropertyGroupArray(devicePropertyNames, 'parsedUserAgent'),
                    ...eventPropertyGroupArray(historicalMetadataProperties, '__sg__')
                ],
                o.groupField('numEvents', o.sum('numEvents'))
            ),
            o.limit(MAX_LIMIT)
        )
};
