<template>
    <pendo-card title="Activation">
        <template #headerRight>
            <pendo-button
                v-if="!isEditing && isDraft && canEditRCActivation"
                type="link"
                theme="app"
                class="manage-in-app--button"
                label="Edit"
                prefix-icon="edit-2"
                :disabled="isUpdating"
                @click="handleEditing" />
            <pendo-button
                v-if="isEditing"
                theme="app"
                type="tertiary"
                label="Cancel"
                :disabled="isUpdating"
                @click="resetActiveItems" />
            <pendo-button
                v-if="isEditing"
                theme="app"
                type="primary"
                size="mini"
                label="Save"
                :disabled="isUpdating || isDisabled || awaitingCustomImage"
                @click="updateActivation" />
        </template>
        <template #body>
            <div
                v-if="!isEditing"
                class="launch-method">
                <div
                    :class="{
                        'launch-method--item': true,
                        'with-alert': hasMultipleLaunchMethods || hasCorruptedBadge || hasInvalidMethod
                    }">
                    <pendo-alert
                        v-if="hasMultipleLaunchMethods"
                        type="info"
                        title="It looks like you've selected multiple Launch Methods, which means you can't select another. Only one Activation Setting can be selected." />
                    <pendo-alert
                        v-if="!hasMultipleLaunchMethods && hasInvalidMethod"
                        type="info"
                        title="It looks like you've selected an unavailable Launch Method" />
                    <img
                        v-if="!hasCorruptedBadge && isLauncherType"
                        class="chrome-extension-preview-img"
                        src="@/img/chrome-extension-preview.svg">
                    <img
                        v-if="!hasCorruptedBadge && isBadgeType"
                        :class="['badge-icon-preview-img', `badge-${badgeName}`]"
                        :src="badgeImage">
                    <pendo-alert
                        v-if="hasCorruptedBadge"
                        type="error"
                        :title="corruptedBadgeAlert" />
                </div>
            </div>
            <div v-if="isEditing">
                <div
                    v-if="isDigitalAdoption"
                    class="activation-text">
                    <div class="activation-text--label">
                        Launch Method
                    </div>
                    <pendo-multiselect
                        class="launcher-selection-dropdown"
                        :value="launcherLabel"
                        label-key="label"
                        :allow-empty="false"
                        :options="availableLauncherOptions"
                        @select="selectLauncher" />
                </div>
                <div
                    v-if="!isLauncherType"
                    class="badge-settings">
                    <div class="badge-explanation">
                        Resource Center appears {{ LAUNCH_METHODS.badge.text }}.
                    </div>
                    <div class="badge-icon">
                        <div class="badge-icon--label">
                            Badge Icon
                        </div>
                        <pendo-multiselect
                            :allow-empty="false"
                            :options="icons"
                            @select="(icon) => handleChange(BADGE_OPTIONS.ICON, icon)">
                            <template #placeholder>
                                <div class="multiselect-option">
                                    <img
                                        v-if="!isCustomBadge"
                                        :src="badgeImage">
                                    <span>
                                        {{ optionName(badgeName) }}
                                    </span>
                                </div>
                            </template>
                            <template #option="{ option }">
                                <div class="multiselect-option">
                                    <img
                                        v-if="option.name !== 'custom'"
                                        :src="option.src"
                                        alt="Badge icon option">
                                    <span>{{ optionName(option.name) }}</span>
                                </div>
                            </template>
                        </pendo-multiselect>
                    </div>
                    <div class="badge-position">
                        <div class="badge-position--label">
                            Badge Position
                        </div>
                        <pendo-multiselect
                            :value="badgePosition"
                            :allow-empty="false"
                            :options="positions"
                            @select="(position) => handleChange(BADGE_OPTIONS.POSITION, position.id)" />
                    </div>
                    <div
                        v-if="!isCustomBadge"
                        class="badge-color">
                        <div class="badge-color--label">
                            Color
                        </div>
                        <pendo-color-picker
                            :initial-color="badgeColor"
                            :class="{ 'label-color-picker': true, 'updating': isColorPickerHidden || isUpdating }"
                            value-key="hex"
                            disable-presets
                            disable-alpha
                            disable-fields
                            @change="(color) => handleChange(BADGE_OPTIONS.COLOR, color)"
                            @active="(color) => handleChange(BADGE_OPTIONS.COLOR, color)"
                            @input="(color) => handleChange(BADGE_OPTIONS.COLOR, color)" />
                    </div>
                    <div
                        v-if="isCustomBadge"
                        class="custom-badge-image">
                        <div class="badge-image--label">
                            Image Preview
                        </div>
                        <image-upload
                            ref="badgeImageUploader"
                            :class="{
                                'is-previewing': badgeImageFiles.length,
                                'is-loading': imageLoading
                            }"
                            :guide-id="activeGuide.id"
                            :app-id="`${activeGuide.appId}`"
                            :files="badgeImageFiles"
                            @uploadError="handleUploadError"
                            @loading="handleUploadLoading"
                            @filesChanged="handleFilesChanged" />
                        <div v-if="badgeImageFiles.length">
                            <img
                                :src="badgeImageFiles[0].url"
                                class="image-preview"
                                alt="Uploaded badge image"
                                @load="onImageLoad"
                                @error="onImageError">
                            <pendo-button
                                class="edit-image-button"
                                theme="app"
                                type="link"
                                size="mini"
                                label="Edit"
                                @click="replaceFile" />
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template
            #footer
            v-if="!isEditing">
            <div class="footer-badge-settings">
                <div class="activation-text">
                    <div class="activation-text--label">
                        Launch Method
                    </div>
                    {{ launchText }}.
                </div>
                <div
                    v-if="!isLauncherType && !hasInvalidMethod"
                    class="badge-icon">
                    <div class="badge-icon--label">
                        Badge Icon
                    </div>
                    {{ optionName(badgeName) }}
                </div>
                <div
                    v-if="!isLauncherType && !hasInvalidMethod"
                    class="badge-position">
                    <div class="badge-position--label">
                        Badge Position
                    </div>
                    {{ badgePosition }}
                </div>
                <div
                    v-if="!isLauncherType && !hasInvalidMethod && !isCustomBadge"
                    class="badge-color">
                    <div class="badge-color--label">
                        Color
                    </div>
                    <pendo-color-picker
                        v-model="badgeColor"
                        :class="{ 'label-color-picker': true, 'updating': isColorPickerHidden || isUpdating }"
                        value-key="hex"
                        disable-presets
                        disable-alpha
                        disabled />
                </div>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import {
    PendoCard,
    PendoButton,
    PendoMultiselect,
    PendoNotification,
    PendoColorPicker,
    PendoAlert
} from '@pendo/components';
import {
    STATES,
    BADGE_POSITIONS,
    getDefaultBadgesSVGs,
    updateBadgePosition,
    updateBadgeImage,
    updateBadgeColor,
    updateBadgeIconName,
    getBadgePosition,
    getBadgeIconName,
    getBadgeImage,
    getBadgeColor,
    changeSVGColor,
    createDefaultBadgeObject,
    updateFrameColor
} from '@/utils/resource-center';
import { mapGetters, mapActions, mapState } from 'vuex';
import { LAUNCH_METHODS } from '@/stateless-components/utils/guides';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import { canEditResourceCenter } from '@/utils/rc-permissions';
import ImageUpload from '@/components/common/ImageUpload.vue';

export default {
    name: 'ResourceCenterActivationInfo',
    components: {
        PendoCard,
        PendoButton,
        PendoMultiselect,
        PendoColorPicker,
        PendoAlert,
        ImageUpload
    },
    props: {
        guide: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            isEditing: false,
            isColorPickerHidden: false,
            isDisabled: false,
            activeGuide: {},
            activeLaunchMethod: {},
            LAUNCH_METHODS,
            BADGE_OPTIONS: Object.freeze({
                ICON: 'icon',
                COLOR: 'color',
                POSITION: 'position'
            }),
            badgeName: null,
            badgePosition: null,
            badgeColor: null,
            badgeImage: null,
            badgeImageFiles: [],
            imageLoading: false,
            isCustomBadge: false,
            savedBadgeImage: null
        };
    },
    computed: {
        ...mapState({
            error: (state) => state.resourceCenter.error,
            isUpdating: (state) => state.resourceCenter.isUpdating
        }),
        ...mapGetters({
            isDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            appFromGuide: 'apps/appFromGuide'
        }),
        activeHomeView () {
            return this.guide.homeView;
        },
        isExtensionApp () {
            return this.guidePlatform === 'extension';
        },
        app () {
            return this.appFromGuide(this.activeHomeView);
        },
        guidePlatform () {
            return this.app.platform;
        },
        lastUpdatedAt () {
            return this.activeHomeView.lastUpdatedAt;
        },
        isDraft () {
            return this.activeHomeView.state === STATES.DRAFT;
        },
        corruptedBadgeAlert () {
            let title = 'Your activation widget is corrupt, but your Resource Center is safe.';

            if (this.canEditRCActivation) {
                title += 'To fix this, edit and resave the activation widget to get it working again.';
            }

            return title;
        },
        hasInvalidMethod () {
            return !this.availableLauncherOptions.find((option) => option.id === this.activeGuide.launchMethod);
        },
        hasMultipleLaunchMethods () {
            return Array.isArray(this.activeHomeView.launchMethod) && this.activeHomeView.launchMethod.length > 1;
        },
        hasCorruptedBadge () {
            const badge = get(this, 'activeGuide.attributes.badge', null);

            return (this.isBadgeType || this.isLauncherType) && !badge;
        },
        availableLauncherOptions () {
            const options = [{ ...LAUNCH_METHODS.badge, id: 'badge' }];

            if (this.isExtensionApp) {
                options.push({ ...LAUNCH_METHODS.extensionIcon, id: 'extensionIcon' });
            }

            return options;
        },
        launchMethod () {
            let { launchMethod } = {
                launchMethod: 'badge',
                ...this.activeGuide
            };
            launchMethod = launchMethod.split('-')[0];

            return launchMethod;
        },
        launchText () {
            return `This Resource Center appears ${
                (LAUNCH_METHODS[this.launchMethod] || { text: this.launchMethod }).text
            }`;
        },
        launcherLabel () {
            return (LAUNCH_METHODS[this.launchMethod] || { label: '--' }).label;
        },
        isLauncherType () {
            return this.launchMethod === 'extensionIcon';
        },
        isBadgeType () {
            return this.launchMethod === 'badge';
        },
        positions () {
            return Object.keys(BADGE_POSITIONS).map((id) => ({ id, label: BADGE_POSITIONS[id] }));
        },
        icons () {
            const svgs = getDefaultBadgesSVGs();
            const res = svgs.map((badge) => {
                let { name, src: svg } = badge;
                svg = changeSVGColor(svg, this.badgeColor);

                return {
                    name,
                    src: svg
                };
            });

            return res;
        },
        canEditRCActivation () {
            return canEditResourceCenter({ appId: this.app.id, field: 'activation' });
        },
        awaitingCustomImage () {
            return this.isCustomBadge && !this.badgeImageFiles.length;
        }
    },
    watch: {
        lastUpdatedAt () {
            this.resetActiveItems();
        },
        badgeImageFiles (newFiles) {
            if (newFiles.length && newFiles[0].url !== this.badgeImage) {
                const newGuide = updateBadgeImage(this.activeGuide, newFiles[0].url);
                this.activeGuide = newGuide;
                this.setActiveValues();
            }
        }
    },
    created () {
        this.resetActiveItems();
    },
    methods: {
        ...mapActions({
            updateModules: 'resourceCenter/updateModules'
        }),
        handleEditing () {
            if (this.hasInvalidMethod) {
                this.setDefaultBadgeOptions();
                this.setActiveValues();
                if (this.isExtensionApp) {
                    this.selectLauncher({ id: 'extensionIcon' });
                }
            }

            if (this.isCustomBadge) {
                this.savedBadgeImage = this.badgeImage;
                this.badgeImageFiles = [{ url: this.savedBadgeImage }];
            }

            this.isEditing = true;
        },
        setDefaultBadgeOptions () {
            this.activeGuide.attributes.badge = createDefaultBadgeObject(this.activeGuide.id);
            this.activeGuide.attributes.activation = { selector: 'body' };
            this.activeGuide.launchMethod = 'badge';
            this.activeGuide.steps[0].elementPathRule = 'body';
        },
        resetActiveItems () {
            this.isEditing = false;
            this.isColorPickerHidden = false;
            this.isDisabled = false;
            this.activeGuide = cloneDeep(this.activeHomeView);
            this.setActiveValues();
        },
        setActiveValues () {
            if (this.hasCorruptedBadge) this.setDefaultBadgeOptions();
            this.badgeName = getBadgeIconName(this.activeGuide) || 'logo';
            this.badgeColor = getBadgeColor(this.activeGuide);
            this.badgeImage = getBadgeImage(this.activeGuide);
            this.badgePosition = getBadgePosition(this.activeGuide);
            this.isCustomBadge = this.badgeName === 'custom';
        },
        selectLauncher ({ id }) {
            this.activeGuide.launchMethod = id;
            if (this.isLauncherType) {
                const newGuide = updateBadgePosition(this.activeGuide, 'top-right');
                this.activeGuide = newGuide;
            }
        },
        async handleChange (type, value) {
            let newGuide = this.activeGuide;

            switch (type) {
                case this.BADGE_OPTIONS.POSITION:
                    newGuide = updateBadgePosition(this.activeGuide, value);
                    break;
                case this.BADGE_OPTIONS.COLOR:
                    if (!/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/gim.test(value)) return;
                    if (this.isCustomBadge) return;
                    newGuide = updateBadgeColor(this.activeGuide, value);
                    newGuide = updateBadgeImage(
                        this.activeGuide,
                        this.icons.find(({ name }) => {
                            return this.badgeName === name;
                        }).src
                    );
                    break;
                case this.BADGE_OPTIONS.ICON:
                    this.isCustomBadge = value.name === 'custom';
                    newGuide = updateBadgeIconName(this.activeGuide, value.name);

                    this.badgeImageFiles = [];
                    if (!this.isCustomBadge) {
                        newGuide = updateBadgeImage(this.activeGuide, value.src);
                    } else if (this.savedBadgeImage && !this.badgeImageFiles.length) {
                        this.badgeImage = this.savedBadgeImage;
                        this.badgeImageFiles = [{ url: this.savedBadgeImage }];
                    }
                    break;
            }

            this.activeGuide = newGuide;
            this.setActiveValues();
        },
        async updateActivation () {
            if (this.isDisabled) return;
            if (this.isLauncherType || this.isBadgeType) {
                this.removeTargetElementProperties();
            }
            this.isDisabled = true;
            this.isColorPickerHidden = true;
            this.activeGuide = updateFrameColor(this.activeGuide, this.badgeColor);
            const updatedModules = this.guide.modules.map((module) => updateFrameColor(module, this.badgeColor));
            updatedModules.unshift(this.activeGuide);
            await this.updateModules({ updatedModules });
            this.resetActiveItems();

            if (this.error) {
                this.showErrorToast();
            }
        },
        removeTargetElementProperties () {
            if (has(this, 'activeGuide.attributes')) {
                delete this.activeGuide.attributes.dom;
                delete this.activeGuide.attributes.elementSelectionType;
                delete this.activeGuide.attributes.activation;
            }
            const isJSON = typeof this.activeGuide.steps[0].buildingBlocks === 'string';
            const buildingBlocks = isJSON
                ? JSON.parse(this.activeGuide.steps[0].buildingBlocks)
                : this.activeGuide.steps[0].buildingBlocks;
            const clonedBuildingBlocks = cloneDeep(buildingBlocks);
            if (has(clonedBuildingBlocks, 'views[0].web')) {
                delete clonedBuildingBlocks.views[0].web.verticalAlignment;
                delete clonedBuildingBlocks.views[0].web.relativeAlignment;
            }
            this.activeGuide.steps[0].buildingBlocks = clonedBuildingBlocks;
        },
        showErrorToast (message = 'Something went wrong!') {
            PendoNotification({
                type: 'error',
                title: message,
                message: 'Please try again or contact support if the issue persists.',
                duration: 7000
            });
        },
        handleFilesChanged (event) {
            const { files } = event;
            this.badgeImageFiles = files;
            if (!files.length) {
                this.badgeImage = '';
            }
        },
        handleUploadError (error) {
            this.showErrorToast(error.message);
            this.badgeImageFiles = [];
        },
        handleUploadLoading (loading) {
            this.imageLoading = loading;
            if (loading) {
                this.badgeImage = '';
            }
        },
        onImageLoad () {
            this.badgeImageFiles = [{ url: this.badgeImage }];
        },
        onImageError () {
            this.badgeImage = '';
            this.badgeImageFiles = [];
        },
        replaceFile () {
            this.$refs.badgeImageUploader.replaceFile();
        },
        optionName (badgeName) {
            return badgeName === 'custom' ? 'Custom Image' : badgeName;
        }
    }
};
</script>

<style lang="scss">
.multiselect-option {
    display: flex;
    align-items: center;

    > :first-child {
        padding-right: 10px;
    }
}

.label-color-picker.updating {
    height: 0px;
    overflow: hidden;
}

.guide-details--activation {
    min-height: 450px;

    .pendo-card__body {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    .launch-method {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;

        &--item {
            display: flex;
            flex-flow: column nowrap;
            height: 200px;
            justify-content: center;
            align-items: center;

            &.disabled {
                opacity: 0.4;
            }

            &--text {
                text-transform: uppercase;
                font-size: 0.875em;
                margin-top: 1em;
            }

            .badge-icon-preview-img {
                height: 50%;
            }
        }

        &--item.with-alert {
            justify-content: flex-start;
            gap: 10px;
        }
    }

    .launcher-selection-dropdown {
        width: 100%;
    }

    .badge-settings {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        > div:first-child {
            flex: 1 90%;
        }

        > div {
            flex: 0 45%;
            margin-top: 14px;
        }
    }

    .footer-badge-settings {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            flex: 0 45%;
            margin-top: 14px;
        }
    }

    .activation-text,
    .badge-icon,
    .badge-color,
    .badge-position,
    .badge-image {
        color: $gray-lighter-2;
        font-weight: 400;
        line-height: 1.5em;
        padding-bottom: 0.5em;

        &--label {
            font-weight: 700;
            color: $gray-primary;
            padding-bottom: 0.25em;
        }
    }

    .custom-badge-image {
        .image-upload.is-previewing {
            height: 0px;
            visibility: hidden;
        }

        .image-upload.is-loading {
            visibility: hidden;
        }

        .image-preview {
            margin: 5px 0px;
            height: 140px;
        }

        .edit-image-button {
            display: block;
        }
    }
}
</style>
