import store from '@/state/store';
import get from 'lodash/get';
import AnalyticsView from '@/views/Analytics';
import GuideDetailsView from '@/views/GuideDetails';
import SegmentListView from '@/views/SegmentList';
import GuideListView from '@/views/GuideList';
import ResourceCenter from '@/views/ResourceCenter';
import EditModule from '@/components/resource-center/edit-module/EditModule';
import SettingsView from '@/views/Settings';
import WorkflowsDetailsView from '@/views/WorkflowsDetails';
import WorkflowsListView from '@/views/WorkflowsList';
import PublicView from '@/views/Public';
import NotFound from '@/views/404';
import InvitationErrorView from '@/views/InvitationErrorView';
import PathDetailsView from '@/views/PathDetails';
import VisitorDetailsView from '@/views/VisitorDetails';
import PoweredBy from '@/components/PoweredBy';
import UserProfile from '@/components/settings/UserProfile';
import ManageUsers from '@/components/settings/ManageUsers';
import Integrations from '@/components/settings/Integrations';
import GuideSettings from '@/components/guides/settings/GuideDetailsSettings';
import GuideMetrics from '@/components/guides/metrics/GuideDetailsMetrics';
import GuidePollResponses from '@/components/guides/polls/GuideDetailsPollResponses';
import AnalyticsDashboard from '@/components/analytics/AnalyticsDashboard';
import AnalyticsLicensesDashboard from '@/components/analytics/licenses/LicensesDashboard';
import AnalyticsPortfolioDashboard from '@/components/analytics/portfolio/PortfolioDashboard';
import AnalyticsPagesDashboard from '@/components/analytics/pages/PagesDashboard';
import AnalyticsFeaturesDashboard from '@/components/analytics/features/FeaturesDashboard';
import AnalyticsVisitorsDashboard from '@/components/analytics/visitors/VisitorsDashboard';
import AnalyticsTrackEventsDashboard from '@/components/analytics/trackEvents/TrackEventsDashboard';
import AnalyticsPathsDashboard from '@/components/paths/PathsDashboard';
import JourneysPage from '@/components/workflows/journeys/JourneysPage';
import WorkflowsDetailsHome from '@/components/workflows/details/WorkflowsDetailsHome';
import SuperSubscriptionList from '@/components/SuperSubscriptionList';
import TrackEventDetails from '@/components/analytics/trackEvents/trackEventDetails.vue';
import { canViewResourceCenter } from '@/utils/rc-permissions';

const routes = [
    {
        path: '/',
        name: 'root',
        beforeEnter: (to, from, next) => {
            if (!store.getters['subscriptions/listAll'].length) {
                return next({ name: 'inactive', query: to.query });
            }

            if (store.getters['subscriptions/usesMultiApp']) {
                return next({ name: 'analyticsPortfolio', query: to.query });
            }

            return next({ name: 'guideList', query: to.query });
        },
        meta: {
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: PublicView,
        beforeEnter (to, from, next) {
            if (!store.getters['auth/isAuthenticated']) {
                return next();
            }

            return next({ name: 'root', query: to.query });
        },
        meta: {
            title: 'Login',
            component: 'LoginForm',
            hideNavbar: true
        }
    },
    {
        // Used as part of OIDC login flow
        path: '/initiate-login',
        name: 'initiateLogin',
        component: PublicView,
        beforeEnter (to, from, next) {
            if (!store.getters['auth/isAuthenticated']) {
                return next();
            }

            return next({ name: 'root', query: to.query });
        },
        meta: {
            title: 'InitiateLogin',
            component: 'InitiateLogin',
            hideNavbar: true
        }
    },
    {
        path: '/forgot',
        name: 'forgotPassword',
        component: PublicView,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/isAuthenticated']) {
                return next();
            }

            return next({ name: 'root', query: to.query });
        },
        meta: {
            component: 'ForgotPassword',
            title: 'Password Reset',
            hideNavbar: true
        }
    },
    {
        path: '/register/complete/:email/:token',
        name: 'completeRegistration',
        components: {
            default: PublicView
        },
        async beforeEnter (to, from, next) {
            if (store.getters['auth/isAuthenticated']) {
                await store.dispatch('auth/logout');
            }

            return next();
        },
        meta: {
            component: 'CompleteUser',
            title: 'Create Account',
            hideNavbar: true
        }
    },
    {
        path: '/register/resetpassword/:email/:token',
        name: 'setPassword',
        components: {
            default: PublicView
        },
        async beforeEnter (to, from, next) {
            if (store.getters['auth/isAuthenticated']) {
                await store.dispatch('auth/logout');
            }

            return next();
        },
        meta: {
            component: 'ResetPassword',
            title: 'Create Password',
            hideNavbar: true
        }
    },
    {
        path: '/resetpassword/:email/expiredlink',
        name: 'expiredLinkPage',
        component: PublicView,
        beforeEnter: (to, from, next) => {
            if (!store.getters['auth/isAuthenticated']) {
                return next();
            }

            return next({ name: 'root', query: to.query });
        },
        meta: {
            component: 'ExpiredLinkPage',
            title: 'Expired Link Page',
            hideNavbar: true
        }
    },
    {
        path: '/invalid-invitation-data',
        alias: ['/invalid-invitation-key', '/invitation-expired'],
        name: 'invitationError',
        components: {
            default: InvitationErrorView,
            footer: PoweredBy
        },
        meta: {
            title: 'Invitation Error',
            hideNavbar: true
        }
    },
    {
        path: '/invitation/confirm/:email/:invitationToken',
        name: 'confirmation',
        components: {
            default: PublicView
        },
        meta: {
            component: 'Confirmation',
            title: 'confirmation',
            hideNavbar: true
        }
    },
    {
        path: '/inactive',
        name: 'inactive',
        component: PublicView,
        meta: {
            authRequired: true,
            hideNavbar: true,
            title: 'Inactive',
            component: 'NoActiveSubscriptions'
        }
    },
    {
        path: '/analytics',
        name: 'analytics',
        components: {
            default: AnalyticsView,
            footer: PoweredBy
        },
        beforeEnter (to, from, next) {
            const hasAnalytics = store.getters['subscriptions/activeHasAnalytics'];

            if (!hasAnalytics) {
                return next({
                    name: 'guideList',
                    query: to.query
                });
            }

            return next();
        },
        meta: {
            authRequired: true,
            title: 'Analytics'
        },
        children: [
            {
                name: 'analyticsPortfolio',
                path: 'portfolio',
                component: AnalyticsPortfolioDashboard,
                meta: {
                    authRequired: true,
                    title: 'Portfolio Overview'
                }
            },
            {
                name: 'licensesDashboard',
                path: 'licenses',
                component: AnalyticsLicensesDashboard,
                meta: {
                    authRequired: true,
                    title: 'License Utilization'
                },
                beforeEnter (to, from, next) {
                    const hasLicenseUtilization = store.getters['subscriptions/activeIsDigitalAdoption'];
                    if (!hasLicenseUtilization) {
                        return next({ name: 'analyticsDashboard' });
                    }

                    return next();
                }
            },
            {
                name: 'analyticsDashboard',
                path: 'dashboard',
                component: AnalyticsDashboard,
                meta: {
                    authRequired: true,
                    title: 'Application Usage'
                }
            },
            {
                name: 'pagesDashboard',
                path: 'pages',
                component: AnalyticsPagesDashboard,
                meta: {
                    authRequired: true,
                    title: 'Pages'
                }
            },
            {
                name: 'featuresDashboard',
                path: 'features',
                component: AnalyticsFeaturesDashboard,
                meta: {
                    authRequired: true,
                    title: 'Features'
                }
            },
            {
                name: 'trackEventsDashboard',
                path: 'trackevents',
                component: AnalyticsTrackEventsDashboard,
                meta: {
                    authRequired: true,
                    title: 'Track Events'
                }
            },
            {
                name: 'visitorsDashboard',
                path: 'visitors',
                component: AnalyticsVisitorsDashboard,
                meta: {
                    authRequired: true,
                    title: 'Visitors'
                }
            }
        ]
    },
    {
        name: 'pathsDashboard',
        path: '/paths',
        components: {
            default: AnalyticsPathsDashboard,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Paths'
        },
        beforeEnter (to, from, next) {
            const hasAnalytics = store.getters['subscriptions/activeHasAnalytics'];

            if (!hasAnalytics) return next({ name: 'analyticsDashboard' });

            return next();
        }
    },
    {
        name: 'path',
        path: '/paths/:id',
        components: {
            default: PathDetailsView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Path'
        },
        props: {
            default: true
        },
        beforeEnter (to, from, next) {
            const hasAnalytics = store.getters['subscriptions/activeHasAnalytics'];

            if (!hasAnalytics) return next({ name: 'analyticsDashboard' });

            return next();
        }
    },
    {
        path: '/analytics/visitors/:visitorId',
        name: 'visitorDetails',
        components: {
            default: VisitorDetailsView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Visitor'
        },
        props: {
            default: true
        }
    },
    {
        path: '/analytics/trackevents/:trackEventId',
        name: 'trackEventDetails',
        components: {
            default: TrackEventDetails,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Track event details'
        },
        props: {
            default: true
        }
    },
    {
        path: '/segments',
        name: 'segmentList',
        components: {
            default: SegmentListView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Segments'
        },
        beforeEnter (to, from, next) {
            if (!store.getters['subscriptions/usesMultiApp'] && store.state.workflows.isFetching) {
                // temp fix for non multiapp subs
                // remove as part of work in https://pendo-io.atlassian.net/browse/APP-77562
                store.watch(() => !store.state.workflows.isFetching, next());
            }

            return next();
        }
    },
    {
        path: '/guides',
        name: 'guideList',
        components: {
            default: GuideListView,
            footer: PoweredBy
        },
        beforeEnter: (to, from, next) => {
            if (!store.getters['subscriptions/listAll'].length) {
                return next({ name: 'inactive', query: to.query });
            }

            return next();
        },
        meta: {
            authRequired: true,
            title: 'Guides'
        },
        children: [
            {
                path: 'alerts',
                name: 'guideAlerts',
                meta: {
                    authRequired: true,
                    title: 'Guide Alerts'
                }
            }
        ]
    },
    {
        path: '/guides/:id',
        name: 'guideDetails',
        components: {
            default: GuideDetailsView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Guide'
        },
        children: [
            {
                path: 'settings',
                name: 'guideSettings',
                component: GuideSettings,
                meta: {
                    title: 'Settings'
                }
            },
            {
                path: 'metrics',
                name: 'guideMetrics',
                component: GuideMetrics,
                meta: {
                    title: 'Metrics'
                }
            },
            {
                path: 'polls',
                name: 'guidePolls',
                component: GuidePollResponses,
                meta: {
                    title: 'Poll Responses'
                }
            }
        ]
    },
    {
        path: '/resource-center/:originId?/:activeState?',
        name: 'resourceCenter',
        components: {
            default: ResourceCenter,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Resource Center'
        },
        props: {
            default: true
        },
        beforeEnter (to, from, next) {
            const activeHasResourceCenter = store.getters['subscriptions/activeHasResourceCenter'];
            const activeIsTrainingSub = store.getters['subscriptions/activeIsTrainingSubscription'];
            const activeApps = activeIsTrainingSub
                ? [store.getters['apps/active']]
                : store.getters['apps/listAllWithRCAccessForActiveSubscription'];
            const canViewRC = canViewResourceCenter(activeApps);

            if (!activeHasResourceCenter || !canViewRC) {
                return next({ name: 'root' });
            }

            return next();
        }
    },
    {
        path: '/resource-center/:originId/:moduleRouteParam/:moduleId',
        name: 'EditModule',
        components: {
            default: EditModule,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Resource Center',
            parentPath: '/resource-center/:originId?/:publishedState?'
        },
        props: true,
        async beforeEnter (to, from, next) {
            if (!store.getters['subscriptions/activeHasResourceCenter']) {
                next({ name: 'root' });

                return;
            }

            if (store.getters['subscriptions/usesMultiApp']) {
                next();

                return;
            }

            let activeResourceCenterId = get(store, 'state.resourceCenter.active.id', null);

            if (!activeResourceCenterId) {
                await store.dispatch('resourceCenter/get');
                activeResourceCenterId = get(store, 'state.resourceCenter.active.id', null);
            }

            const originId = get(to, 'params.originId', null);
            if (activeResourceCenterId === originId) {
                next();

                return;
            }

            next({
                name: 'resourceCenter',
                params: {
                    activeState: 'draft',
                    originId: activeResourceCenterId
                }
            });
        }
    },
    {
        path: '/settings',
        name: 'settings',
        components: {
            default: SettingsView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Settings'
        },
        children: [
            {
                path: 'profile',
                name: 'userProfile',
                component: UserProfile,
                meta: {
                    authRequired: true,
                    title: 'Profile'
                }
            },
            {
                path: 'manage-users',
                name: 'manageUsers',
                component: ManageUsers,
                beforeEnter (to, from, next) {
                    const isAdmin = store.getters['auth/isAdmin'];

                    if (!isAdmin && !store.getters['auth/isImpersonating']) {
                        return next({ name: 'userProfile' });
                    }

                    return next();
                },
                meta: {
                    authRequired: true,
                    title: 'Users'
                }
            },
            {
                path: 'integrations',
                name: 'integrations',
                component: Integrations,
                beforeEnter (to, from, next) {
                    const isAdmin = store.getters['auth/isAdmin'];
                    if (!isAdmin && !store.getters['auth/isImpersonating']) return next({ name: 'userProfile' });

                    const allowIntegrationKey = store.getters['subscriptions/activeSubHasFlag']('allowIntegrationKey');

                    if (!allowIntegrationKey && !store.getters['auth/isImpersonating']) {
                        return next({ name: 'userProfile' });
                    }

                    return next();
                },
                meta: {
                    authRequired: true,
                    title: 'Integrations'
                }
            }
        ]
    },
    {
        path: '/workflows',
        name: 'workflowsList',
        components: {
            default: WorkflowsListView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Workflows'
        },
        beforeEnter (to, from, next) {
            const activeIsDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
            const activeHasAnalytics = store.getters['subscriptions/activeHasAnalytics'];
            const showWorkflows = activeIsDigitalAdoption || activeHasAnalytics;

            if (!showWorkflows) {
                return next({ name: 'notFound' });
            }

            return next();
        }
    },
    {
        path: '/workflows/:id',
        name: 'workflowsDetails',
        components: {
            default: WorkflowsDetailsView,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Workflows'
        },
        props: {
            default: true
        },
        beforeEnter (to, from, next) {
            const activeIsDigitalAdoption = store.getters['subscriptions/activeIsDigitalAdoption'];
            const activeHasAnalytics = store.getters['subscriptions/activeHasAnalytics'];
            const showWorkflows = activeIsDigitalAdoption || activeHasAnalytics;

            if (!showWorkflows) {
                return next({ name: 'notFound' });
            }

            return next();
        },
        children: [
            {
                path: 'home',
                name: 'workflowsDetailsHome',
                component: WorkflowsDetailsHome,
                meta: {
                    authRequired: true,
                    title: 'Home'
                }
            },
            {
                path: 'journeys',
                name: 'journeys',
                component: JourneysPage,
                meta: {
                    authRequired: true,
                    title: 'Journeys'
                }
            }
        ]
    },
    {
        path: '/pendozer/subs',
        name: 'SubscriptionList',
        components: {
            default: SuperSubscriptionList,
            footer: PoweredBy
        },
        meta: {
            authRequired: true,
            title: 'Subscriptions'
        },
        beforeEnter (to, from, next) {
            const superOrOps = store.getters['auth/isSuper'] || store.getters['auth/isOps'];

            if (!store.getters['auth/isPendoUser'] || !superOrOps) {
                return next({ name: 'analyticsDashboard' });
            }

            return next();
        }
    },
    {
        path: '/404',
        name: 'notFound',
        components: {
            default: NotFound,
            footer: PoweredBy
        },
        meta: {
            title: 'Not Found'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
];

export default routes;
