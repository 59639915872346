import get from 'lodash/get';
import flatMap from 'lodash/flatMap';
import invoke from 'lodash/invoke';
import keyBy from 'lodash/keyBy';
import { v4 as uuid } from 'uuid';
import { http } from '@pendo/http';
import { generateAppUid } from './apps';
import { isValidUrl } from '@pendo/services/Formatters';
import { MOBILE_IDS, MOBILE_PLATFORMS } from '../constants/mobile';

export const LEGACY_APP_ID = -323232;

export function flattenTrainingAttrs (entityList, additionalProps = {}) {
    return entityList.map((entity) => {
        let flatApps;
        const { trainingAttributes, featureFlags, applications } = entity;
        const newEntity = { ...entity, ...trainingAttributes, ...additionalProps };

        if (featureFlags) {
            newEntity.featureFlags = featureFlags;
        }
        if (applications) {
            flatApps = flattenTrainingAttrs(applications);
            newEntity.applications = flatApps;
        }

        return newEntity;
    });
}

export function hydrateSubscriptionAccounts (subscriptions = []) {
    return subscriptions.map((sub) => {
        if (!sub.accounts) {
            return { ...sub };
        }

        const accounts = Object.entries(sub.accounts).reduce((map, [id, acct]) => {
            map[id] = {
                ...acct,
                id,
                uid: `${sub.id}:${id}`
            };

            return map;
        }, {});

        return { ...sub, accounts };
    });
}

export function createAccountMap (subscriptions, subscriptionId) {
    const sub = subscriptions[subscriptionId];
    const displayName = get(sub, 'displayName');
    const map = displayName
        ? {
            [displayName]: { ...sub, id: displayName }
        }
        : {};

    return map;
}

export function setUniqueAppIds (applications = []) {
    return applications.map((app) => {
        app.uid = generateAppUid(app.subscriptionId, app.id);

        return app;
    });
}

export function isValidLookasideHost (url) {
    const urlRegex = new RegExp('^(?!https://|http://).+([a-zA-Z0-9].[a-zA-Z]{2,})(?<!/)$', 'i');

    return !url || (typeof url === 'string' && !!url.match(urlRegex)); // empty/null lookasideHost is valid
}

export function getUrlValidator (validator) {
    return (rule, value, callback) => {
        if (!validator(value)) {
            callback(new Error('URL is invalid.'));

            return;
        }
        callback();
    };
}

export function isValidLaunchDesignerForm ({ url, lookasideHost }) {
    return isValidLookasideHost(lookasideHost) && isValidUrl(url);
}

export const lookasideHostValidationRules = [
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        trigger: 'change'
    },
    {
        required: false,
        message: 'Please input a valid url excluding https:// and trailing slash',
        validator: getUrlValidator(isValidLookasideHost),
        trigger: 'blur'
    }
];

export async function normalizeUrl (url) {
    // if the URL already contains encoded characters, we need to decode them first so they
    // don't get encoded again below.
    try {
        url = decodeURIComponent(url);
    } catch (err) {
        return url;
    }

    return http.post('/api/s/_SID_/helper/urlnorm', { url: encodeURI(url) }).then((res) => res.data);
}

export function inDevEnvironment () {
    const env = get(window, '__via_info__.env', '');
    if (!env.includes('prod')) {
        return true;
    }

    return false;
}

// use kebab case
// add existing/new Pendo track event names to use and create adopt versions with `sendTrackEvent`
export const TRACK_EVENT_NAMES = {
    guideLanguageImport: 'guide-language-import',
    resourceCenterLanguageImport: 'resource-center-language-import',
    workflowsListTableUserSettingMigrated: 'workflows-list-table-user-setting-migrated'
};

export function sendPendoTrackEvent ({ trackEventKey, properties = {}, adoptSpecific = true }) {
    let trackEventName = TRACK_EVENT_NAMES[trackEventKey];
    if (!trackEventName) {
        throw new Error(`Invalid trackEventKey: ${trackEventKey}`);
    }
    if (adoptSpecific) {
        trackEventName = `adopt-${trackEventName}`;
    }

    invoke(window, 'pendo.track', trackEventName, properties);
}

// periods cause google appengine's magic routing to break
export function encodeIdForUri (id) {
    const periodRegex = /\./g;

    return encodeURIComponent(id).replace(periodRegex, '%2E');
}

export function addHrefToVisitors ({ visitors = [], visitorUrl = '/analytics/visitors' }) {
    return {
        visitors: visitors
            ? visitors.map(({ id }) => ({ id, href: `${visitorUrl}/${encodeURIComponent(encodeIdForUri(id))}` }))
            : []
    };
}

export function propName (obj, type) {
    return Object.keys(obj).find((key) => obj[key] === type);
}

export function getDefaultValueForSchema (schema) {
    let defaultValue;

    if (isMobileDataSchema(schema)) {
        return '';
    }

    switch (schema.schema) {
        case 'integer':
        case 'float':
            defaultValue = 0;
            break;
        default:
            defaultValue = null;
    }

    return defaultValue;
}

export function getDefaultOperatorForSchema (operators, { schema }) {
    const schemaOperators = operators[schema];

    return get(schemaOperators, '[0]', null);
}

export function getEntitiesByAppSelection (app, entityList, config) {
    if (!entityList) {
        return [];
    }

    const appId = config.usesMultiApp ? get(app, 'id', null) : config.appId;

    if (appId === null) {
        return entityList;
    }

    return entityList.filter((entity) => entity.appId === appId);
}

export function getPollsForGuide (guide) {
    if (!guide.polls?.length) {
        return [];
    }

    const polls = keyBy(guide.polls, 'id');

    return flatMap(guide.steps, (step) => step.pollIds)
        .map((id) => polls[id])
        .filter((poll) => poll);
}

export function generateRule (operators, schema) {
    return {
        schema,
        id: uuid(),
        operator: getDefaultOperatorForSchema(operators, schema),
        value: getDefaultValueForSchema(schema)
    };
}

export function addCommasToNumber (num) {
    const str = String(num);
    const parts = str.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}

export function getNoResourcesFoundText ({ rule, isMultiApp, resourceType }) {
    const resourcesLabel = {
        page: 'pages',
        feature: 'features',
        trackType: 'track events',
        guide: 'guides',
        guideElement: 'guide elements',
        resourceCenter: 'resource centers',
        poll: 'polls'
    }[resourceType || rule.id];

    if (isMultiApp && rule.appId !== '*') {
        return `No ${resourcesLabel} found. Please select a different app.`;
    }

    return `No ${resourcesLabel} found.`;
}

export function isCsvUploadOperator (operator) {
    return ['listContains', '!listContains'].includes(operator);
}

export function generateEngageUrlByEnv (_env) {
    if (!_env) {
        return '';
    }

    const env = _env.toLowerCase();

    if (env === 'prod') {
        return 'https://app.pendo.io';
    }

    if (env === 'prod-eu') {
        return 'https://app.eu.pendo.io';
    }

    if (env === 'prod-us1') {
        return 'https://app.us1.app.pendo.io';
    }

    if (env === 'jp-prod') {
        return 'https://app.jpn.pendo.io';
    }

    return `https://app.pendo-${env}.pendo-dev.com`;
}

// Copy of fucntion used in segments.js in engage
// Let's be clear, this entire function is a big guess. Segments in engage have evolved a ton over the past 5 years,
// so there might be odd edges that we don't catch here (yet). If you find a new scenario where
// we incorrectly identify a segment as adopt/engage, slide it in here!
export function filterRuleMightHaveBeenMadeInAdopt (filterRule, segmentLastUpdatedDate) {
    // adopt filter rules attach an icon object to each rule, but engage segments do not
    const hasIcon = filterRule.icon;
    if (hasIcon) {
        return true;
    }

    // $valid and key were added as part of a large refactor in 2020 - ancient engage segments won't have these keys
    const timestampOfValidInCodebase = 1587441600000; // 04/21/2020
    const segmentCanHaveValidOrKey = segmentLastUpdatedDate >= timestampOfValidInCodebase;
    const ruleDoesNotHaveValidOrKey = !filterRule.$valid && !filterRule.key;

    if (segmentCanHaveValidOrKey && ruleDoesNotHaveValidOrKey) {
        return true;
    }

    return false;
}

export function isEngageSegment (segment) {
    const adoptSegmentsFirstCreatedAt = 1553558400000; // 03/26/2019
    if (segment.createdAt < adoptSegmentsFirstCreatedAt) {
        return true;
    }

    // as long as default segments haven't been edited, they work fine in both places
    const isDefaultSegment = segment.id.includes('_PENDO_DEFAULT_');
    const lastUpdatedBySystemUser = segment.lastUpdatedByUser.username === '--system-user--';
    if (isDefaultSegment && lastUpdatedBySystemUser) {
        return false;
    }

    const filters = segment.definition?.filters || [];

    // if any filter rule might have been made in adopt, then it's an adopt segment and not an engage segment
    return !filters.some((rule) => {
        if (rule.or) {
            return rule.or.some((subrule) => filterRuleMightHaveBeenMadeInAdopt(subrule, segment.lastUpdatedAt));
        }

        return filterRuleMightHaveBeenMadeInAdopt(rule, segment.lastUpdatedAt);
    });
}

export function isMobileDataSchema (schema = {}) {
    return MOBILE_IDS.includes(schema.id);
}

export function getMobileDataFieldName (schema) {
    if (isMobileDataSchema(schema)) {
        return `last${schema.id.toLowerCase()}`;
    }

    return '';
}

export function isMobilePlatform (platform) {
    return MOBILE_PLATFORMS.includes(platform);
}
