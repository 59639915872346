<template>
    <pendo-multiselect
        :disabled="true"
        :preselect-first="true"
        :options="[option]"
        :value="option"
        min-trigger-width="100%" />
</template>

<script>
import { PendoMultiselect } from '@pendo/components';

/**
 * Used for purely cosmetic purposes for certain rule types.
 */
export default {
    name: 'DisabledSingleOptionSelect',
    components: { PendoMultiselect },
    props: {
        option: {
            type: String,
            required: true
        }
    }
};
</script>
