<template>
    <div class="guide-details-settings">
        <div class="guide-details--row">
            <guide-details-content-preview
                v-pendo-loading="loadingConfig"
                class="guide-details--content-preview"
                :is-localization-enabled="localizationEnabled"
                @handleManageGuide="handleManageGuide"
                @openLaunchPreviewModal="$emit('openLaunchPreviewModal', $event)" />
        </div>
        <div class="guide-details--row">
            <guide-details-activation
                v-pendo-loading="loadingConfig"
                :guide="guide"
                :page-location="pageLocation"
                :feature-name="featureName"
                :is-editing="isEditingActivation"
                :active-is-digital-adoption="activeIsDigitalAdoption"
                :can-edit="canEditGuideActivation"
                :is-mobile-guide="isMobileGuide"
                :is-mobile-tooltip="isMobileTooltip"
                :has-mobile-tooltip-activation="hasMobileTooltipActivation"
                :track-event-name="trackEventName"
                :track-event-property-message="trackEventPropertyMessage"
                class="guide-details--activation"
                @handleManageGuide="handleManageGuide"
                @updateIsEditing="isEditingActivation = $event"
                @updateLaunchMethod="updateLaunchMethod" />
            <guide-details-segment
                v-pendo-loading="loadingConfig"
                class="guide-details--segment" />
            <guide-details-schedule
                v-pendo-loading="loadingConfig"
                :active-timezone="getActiveTimezone"
                :guide="guide"
                :updating="updatingSchedule"
                :can-edit="canEditGuideSchedule"
                class="guide-details--schedule"
                @submit="updateGuideSchedule" />
            <guide-details-localization
                v-if="localizationEnabled && !isCrossAppGuide"
                v-pendo-loading="loadingConfig"
                :guide="guide"
                class="guide-details--localization" />
        </div>
        <launch-designer-modal
            :visible="showLaunchDesignerModal"
            v-bind="modalProps"
            @close="closeLaunchDesignerModal" />
    </div>
</template>

<script>
import { PendoLoading, PendoNotification } from '@pendo/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import get from 'lodash/get';
import values from 'lodash/values';
import { isCrossApp } from '@pendo/services/CrossAppGuides';
import { isMobile } from '@/stateless-components/utils/apps';
import { canEditGuide, canPublishGuide } from '@/utils/guide-permissions';
import { isInactiveCountable } from '@pendo/tagging';
import GuideDetailsActivation from '@/stateless-components/guides/settings/GuideDetailsActivation';
import GuideDetailsSchedule from '@/stateless-components/guides/settings/GuideDetailsSchedule';
import GuideDetailsContentPreview from '@/components/guides/settings/GuideDetailsContentPreview';
import GuideDetailsSegment from '@/components/guides/settings/GuideDetailsSegment';
import GuideDetailsLocalization from '@/components/guides/settings/GuideDetailsLocalization';
import {
    isConfirmationGuide,
    isMobileTooltipGuide,
    constructLaunchMethodString,
    getTrackEventPropertyRule,
    getRulsetFullObjects,
    getTrackEventPropertyMessage
} from '@/stateless-components/utils/guides';
import { manageMobileDesignerLaunch } from '@/stateless-components/utils/designer';
import { isTranslated } from '@/utils/localization.js';
import LaunchDesignerModal from '@/components/LaunchDesignerModal';

export default {
    name: 'GuideDetailsSettings',
    components: {
        GuideDetailsActivation,
        GuideDetailsContentPreview,
        GuideDetailsSchedule,
        GuideDetailsSegment,
        GuideDetailsLocalization,
        LaunchDesignerModal
    },
    directives: {
        PendoLoading
    },
    emits: ['openLaunchPreviewModal'],
    data () {
        return {
            isEditingActivation: false,
            updatingSchedule: false,
            rulsetFullObjects: {},
            showLaunchDesignerModal: false,
            modalProps: {}
        };
    },
    computed: {
        ...mapGetters({
            appFromGuide: 'apps/appFromGuide',
            guide: 'guides/active',
            pageById: 'pages/pageById',
            activeIsDigitalAdoption: 'subscriptions/activeIsDigitalAdoption',
            getFeatureById: 'features/featureById',
            getActiveTimezone: 'subscriptions/getTimezone',
            trackEventById: 'trackEvents/trackEventById'
        }),
        ...mapState({
            isUpdating: (state) => state.guides.isUpdating
        }),
        localizationEnabled () {
            const guideApp = this.appFromGuide(this.guide);

            return get(guideApp, 'applicationFlags.guideLocalization', false);
        },
        loadingConfig () {
            return {
                loading: this.isUpdating,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        },
        pageLocation () {
            const guidePageId = get(this.guide, 'steps[0].pageId');

            if (this.guide.launchMethod === 'api' && this.isMobileGuide) {
                return '---';
            }

            if (this.isMobileTooltip && !this.hasMobileTooltipActivation) {
                return '---';
            }

            if (!guidePageId) {
                return 'Sitewide';
            }

            const page = this.pageById(guidePageId);
            const nameKey = isInactiveCountable(page) ? 'name' : 'displayName';

            return get(page, nameKey, 'Location not found');
        },
        featureName () {
            const id = get(this.guide, 'steps[0].featureId', null);
            if (!id) return;

            const feature = this.getFeatureById(id);

            return get(feature, 'name', null);
        },
        trackEventName () {
            const id = get(this.guide, 'steps[0].trackTypeIds[0]', null);
            if (!id) return;

            const trackEvent = this.trackEventById(id);

            return get(trackEvent, 'displayName', null);
        },
        trackEventPropertyRule () {
            return getTrackEventPropertyRule(this.guide);
        },
        trackEventPropertyMessage () {
            if (this.hasTrackEventPropertyRule) {
                return getTrackEventPropertyMessage(this.rulsetFullObjects);
            }

            return '';
        },
        canEditGuideActivation () {
            return canEditGuide({ guide: this.guide, field: 'activation' }) && !isConfirmationGuide(this.guide);
        },
        canEditGuideSchedule () {
            return canEditGuide({ guide: this.guide, field: 'schedule' }) || canPublishGuide(this.guide);
        },
        isCrossAppGuide () {
            return isCrossApp(this.guide);
        },
        isMobileGuide () {
            return isMobile(this.appFromGuide(this.guide));
        },
        hasTranslations () {
            return values(this.guide.translationStates).some(({ state }) => isTranslated(state));
        },
        isMobileTooltip () {
            if (!this.isMobileGuide) return;

            const buildingBlocks = get(this.guide, 'steps[0].buildingBlocks');

            return isMobileTooltipGuide(buildingBlocks);
        },
        hasMobileTooltipActivation () {
            return this.guide.launchMethod === 'page-auto' || this.guide.launchMethod === 'api';
        },
        hasTrackEventPropertyRule () {
            return Object.keys(this.trackEventPropertyRule).length;
        }
    },
    async created () {
        await this.loadAllFeatures();
        await this.loadAllTrackEvents();

        // If the guide has a property rule activation - fetch the data to show
        if (this.hasTrackEventPropertyRule) {
            this.rulsetFullObjects = await getRulsetFullObjects(this.trackEventPropertyRule);
        }
    },
    methods: {
        ...mapActions({
            loadAllFeatures: 'features/fetch',
            loadAllTrackEvents: 'trackEvents/fetch',
            patch: 'guides/patch'
        }),
        stepDomainPatterns (step) {
            if (!step.app) return null;

            return [].concat(step.app.extensionDomainPatterns);
        },
        handleManageGuide ({ type, step }) {
            if (this.isMobileGuide) {
                return this.manageMobileGuide(step);
            }

            return this.manageGuideStep(type, step);
        },
        manageMobileGuide (step = this.guide.steps[0]) {
            const whiteLabelToken = {
                ...this.appWhiteLabelSettings,
                productName: this.productName
            };

            manageMobileDesignerLaunch(step.id, this.guide, whiteLabelToken);
        },
        manageGuideStep (type, step) {
            const title = type === 'guide:activation' ? 'Edit Guide Activation' : 'Edit Guide Step';
            const componentProps = {
                guideId: this.guide.id,
                type,
                translationWarning: this.hasTranslations,
                isCrossApp: this.isCrossAppGuide
            };

            if (step) {
                componentProps.suggestedDomains = this.stepDomainPatterns(step);
                componentProps.stepId = step.id;
                componentProps.stepAppId = get(step, 'appIds[0]', undefined);
            }

            this.modalProps = {
                ...componentProps,
                title,
                width: '465'
            };

            this.showLaunchDesignerModal = true;
        },
        closeLaunchDesignerModal () {
            this.showLaunchDesignerModal = false;

            this.$nextTick(() => {
                this.modalProps = {};
            });
        },
        async updateLaunchMethod (automaticActivation) {
            const launchMethod = constructLaunchMethodString(this.guide.launchMethod, automaticActivation);

            try {
                await this.patch({ guideId: this.guide.id, props: { launchMethod } });
            } catch (error) {
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Action not available. Please try again later.',
                    duration: 5000
                });
            }

            this.isEditingActivation = false;
        },
        async updateGuideSchedule (updateParams) {
            this.updatingSchedule = true;
            await this.patch(updateParams);
            this.updatingSchedule = false;
        }
    }
};
</script>

<style lang="scss">
.guide-details-settings {
    display: grid;
    grid-gap: 32px;

    .guide-details {
        &--row {
            display: grid;
            grid-gap: 32px;
            grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        }

        &--content-preview,
        &--activation,
        &--segment,
        &--schedule {
            .pendo-card__body {
                display: grid;
            }
        }
    }
}
</style>
