<template>
    <div class="workflows-query-advanced-options">
        <pendo-collapse>
            <pendo-collapse-item
                title="Advanced options"
                data-cy="workflows-advanced-options"
                type="card">
                <div
                    class="workflows-query-advanced-options-max-time"
                    data-cy="workflows-max-time-multiselect">
                    <pendo-form-item>
                        <pendo-multiselect
                            class="workflows-query-advanced-options-max-time--dropdown"
                            :disabled="disabled || isLoading"
                            full-width
                            :options="maxDurationOptions"
                            :value="selectedMaxDurationOption"
                            @select="$emit('durationSelected', $event)">
                            <template #option="{ option }">
                                <pendo-icon-option :option="option" />
                            </template>

                            <template #selectedLabel="{ option }">
                                <pendo-icon-option :option="option" />
                            </template>
                            <template #topLabel>
                                Workflow timeout
                                <pendo-icon
                                    v-pendo-tooltip="{
                                        trigger: 'hover',
                                        arrow: true,
                                        content: maxDurationTooltipText,
                                        multiline: true
                                    }"
                                    size="14"
                                    display="inline"
                                    type="info" />
                            </template>
                        </pendo-multiselect>
                    </pendo-form-item>
                </div>
            </pendo-collapse-item>
        </pendo-collapse>
    </div>
</template>
<script>
import {
    PendoCollapse,
    PendoCollapseItem,
    PendoFormItem,
    PendoIconOption,
    PendoIcon,
    PendoMultiselect,
    PendoTooltip
} from '@pendo/components';

export default {
    name: 'QueryAdavancedOptions',
    components: {
        PendoCollapse,
        PendoCollapseItem,
        PendoFormItem,
        PendoIconOption,
        PendoIcon,
        PendoMultiselect
    },
    directives: {
        PendoTooltip
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        maxDurationOptions: {
            type: Array,
            required: true
        },
        maxDurationTooltipText: {
            type: String,
            required: true
        },
        selectedMaxDurationOption: {
            type: Object,
            required: true
        }
    },
    emits: ['durationSelected']
};
</script>

<style lang="scss" scoped>
.workflows-query-advanced-options {
    min-width: 200px;
    width: 47%;
    max-width: 324px;

    :deep(.pendo-collapse) {
        border: none;

        .pendo-collapse-item {
            border: none;

            &__content {
                margin: 15px 0;
            }

            &__header {
                padding: 0;
                min-height: unset;
                background-color: $gray-lighter-8;
            }

            &__title {
                grid-template-columns: min-content min-content;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 400;
            }

            &__wrap {
                background-color: $gray-lighter-8;
            }
        }
    }

    &-max-time {
        &--dropdown {
            :deep(.pendo-multiselect__label--top) {
                display: flex;
            }

            .pendo-icon {
                margin-left: 4px;
            }
        }
    }
}
</style>
