import { readOnlyKey } from '@/components/segment-builder/utils/keys';

export default {
    inject: {
        readOnlyInjected: { from: readOnlyKey }
    },
    computed: {
        readOnly () {
            return this.readOnlyInjected.value;
        }
    }
};
