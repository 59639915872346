<template>
    <div class="activity-segment-summary">
        <div class="activity-segment-summary__header">
            {{ metricLabel }}
        </div>
        <div class="activity-segment-summary__header">
            Guide Targeting
        </div>
        <div class="activity-segment-summary__body">
            <div>
                <div class="activity-segment-summary__metric">
                    {{ firstTimeMetric }}
                </div>
                <div class="activity-segment-summary__label">
                    First Time {{ metricLabel }}
                </div>
            </div>
            <div>
                <div class="activity-segment-summary__metric">
                    {{ totalMetric }}
                </div>
                <div class="activity-segment-summary__label">
                    <pendo-icon
                        v-if="isViewsMetric"
                        v-pendo-tooltip="totalViewsTooltipOptions"
                        type="alert-circle"
                        size="14" />
                    Total {{ metricLabel }}
                </div>
            </div>
        </div>
        <div class="activity-segment-summary__audience">
            <div>
                <div
                    v-pendo-loading:feather="isFetchingTargeted"
                    class="activity-segment-summary__metric">
                    {{ targetedCount }}
                </div>
                <div class="activity-segment-summary__label">
                    <pendo-icon
                        v-pendo-tooltip="targetedTooltipOptions"
                        type="alert-circle"
                        size="14" />
                    Targeted
                </div>
            </div>
            <div class="activity-segment-summary__separator">
                of
            </div>
            <div>
                <div class="activity-segment-summary__metric">
                    {{ everyoneCount }}
                </div>
                <div class="activity-segment-summary__label">
                    Everyone
                </div>
            </div>
        </div>
        <div
            v-pendo-loading:feather="isFetchingTargeted || isFetchingUniqueVisitorCount"
            class="activity-segment-summary__percent-seen">
            <div class="activity-segment-summary__metric">
                {{ uniqueVisitorCount }} / {{ targetedCount }}
            </div>
            <div class="activity-segment-summary__label">
                <b>{{ percentSeen }}</b> of targeted visitors have seen this guide.
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getVisitorTotal } from '@/aggregations/visitor-counts';
import { getGuideTotalViewsByPeriod } from '@/aggregations/guide-views';
import { PendoIcon, PendoLoading, PendoTooltip } from '@pendo/components';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import { isCrossApp } from '@pendo/services/CrossAppGuides';

export default {
    name: 'GuideActivitySegmentSummary',
    components: {
        PendoIcon
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    data () {
        return {
            everyoneCount: '...',
            targetedCount: '...',
            uniqueVisitorCount: '...',
            isFetchingTargeted: false,
            isFetchingUniqueVisitorCount: false,
            unsubscribeFilterBarListener: null
        };
    },
    computed: {
        ...mapState({
            guideId: (state) => state.guides.activeId,
            metric: (state) => state.guideAnalytics.metric,
            viewsByPeriod: (state) => state.guideAnalytics.viewsByPeriod
        }),
        ...mapGetters({
            activeTimeSeries: 'filters/activeTimeSeries',
            getGuideById: 'guides/getGuideById',
            appFromGuide: 'apps/appFromGuide',
            guide: 'guides/active'
        }),
        firstTimeMetric () {
            return this.viewsByPeriod.reduce((sum, period) => {
                return sum + period.firstTime;
            }, 0);
        },
        totalMetric () {
            return this.viewsByPeriod.reduce((sum, period) => {
                return sum + period.total;
            }, 0);
        },
        totalViewsTooltipOptions () {
            const tooltipText = `A guide will continue to show until it is dismissed.
                Every page reload or consecutive site visit where a visitor does not
                dismiss the guide is considered a separate view and counted towards
                the total number of guide views`;

            return {
                arrow: true,
                multiline: true,
                placement: 'left',
                content: tooltipText
            };
        },
        everyoneTooltipOptions () {
            const tooltipText =
                '<b>Selected Segment</b> This number is filtered by the segment selection at the top of the page.';

            return {
                arrow: true,
                multiline: true,
                html: true,
                placement: 'bottom',
                container: '.activity-segment-summary',
                content: tooltipText
            };
        },
        targetedTooltipOptions () {
            const tooltipText =
                '<b>Targeted Segment</b> This number reflects the Visitors who currently meet the target rules in your guide.';

            return {
                arrow: true,
                multiline: true,
                html: true,
                placement: 'left',
                container: '.activity-segment-summary',
                content: tooltipText
            };
        },
        percentSeen () {
            if (this.targetedCount === 0) return '0%';
            if (this.isFetchingTargeted || this.isFetchingUniqueVisitorCount) return '...';

            const percentSeen = (this.uniqueVisitorCount / this.targetedCount) * 100;

            return `${percentSeen.toFixed(1)}%`;
        },
        metricLabel () {
            return capitalize(this.metric);
        },
        segmentId () {
            return get(this.guide, 'audienceUiHint.filters[0].segmentId', null);
        },
        isViewsMetric () {
            return this.metric.includes('views');
        }
    },
    watch: {
        async metric () {
            await this.updateUniqueVisitors();
        }
    },
    async created () {
        await Promise.all([this.update(), this.updateEveryoneCount()]);
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.updateUniqueVisitors();
        });
    },
    unmounted () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        async updateTargetedCount () {
            this.isFetchingTargeted = true;
            this.targetedCount = await getVisitorTotal({ segmentId: this.segmentId });
            this.isFetchingTargeted = false;
        },
        async updateUniqueVisitors () {
            this.isFetchingUniqueVisitorCount = true;
            const { segmentId, activeTimeSeries } = this;
            const guide = this.getGuideById(this.guideId);
            const appIds = isCrossApp(guide) ? get(guide, 'appIds', []) : [this.appFromGuide(guide).id];

            const dayRangeTimeSeries = {
                ...activeTimeSeries,
                period: 'dayRange'
            };
            const uniqueVisitorCountResult = await getGuideTotalViewsByPeriod({
                appIds,
                guideId: this.guideId,
                segmentId,
                metric: 'visitor',
                timeSeries: dayRangeTimeSeries
            });
            this.uniqueVisitorCount = get(uniqueVisitorCountResult, 'messages[0].rows[0].count', 0);
            this.isFetchingUniqueVisitorCount = false;
        },
        async updateEveryoneCount () {
            this.everyoneCount = await getVisitorTotal();
        },
        async update () {
            await Promise.all([this.updateTargetedCount(), this.updateUniqueVisitors()]);
        }
    }
};
</script>

<style lang="scss" scoped>
.activity-segment-summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 40px 2fr 1fr;
    grid-gap: 1px;
    background: $gray-lighter-5;
    text-align: center;
    height: 100%;
    overflow: hidden;

    > div {
        display: grid;
        align-items: center;
        background: $gray-lighter-8;

        &.activity-segment-summary__header {
            background: $gray-lighter-7;
        }
    }

    &__header {
        font-weight: bold;
        border-bottom: 1px solid $gray-lighter-5;
    }

    &__views,
    &__audience {
        align-content: space-evenly;

        a {
            text-decoration: underline dashed;
            text-underline-position: under;
        }
    }

    &__audience {
        position: relative;
    }

    &__separator {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        color: $gray-lighter-3;
        transform: translateY(-50%);
    }

    &__percent-seen {
        grid-column: span 2;
        align-content: center;
    }

    &__label {
        color: $gray-lighter-3;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        grid-gap: 5px;

        a {
            cursor: pointer;
        }
    }

    &__metric {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.6rem;
    }
}
</style>
