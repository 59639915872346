<template>
    <main class="portfolio-app-list">
        <pendo-table
            v-pendo-loading:feather="isFetchingList"
            :max-height="448"
            row-key="id"
            class="portfolio-app-list--table"
            :columns="columns"
            csv-download
            :data="sortedUsageList"
            :default-sort="sort"
            :filters="filters"
            :resizable="true"
            :tree-config="{ children: 'children', sortChildren: true }"
            title=""
            @column-resize="onColumnResized"
            @sort-change="onSortChanged">
            <template #headerActions>
                <div class="portfolio-app-usage-list--table-search">
                    <search v-model="searchInput" />
                </div>
            </template>
            <template #headerLeft>
                <div class="portfolio-app-usage-list--table-header-left">
                    <div class="portfolio-app-usage-list--table-title">
                        Usage by Application
                    </div>
                    <pendo-multiselect
                        class="portfolio-app-usage-list--table-period-select"
                        :allow-empty="false"
                        :options="periodOptions"
                        :value="period"
                        @input="onPeriodChanged">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                    <pendo-multiselect
                        v-model="selectedField"
                        class="portfolio-app-usage-list--table-field-select"
                        :allow-empty="false"
                        :options="selectedFieldOptions">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
            </template>
            <template #empty>
                <div class="portfolio-app-usage-list--empty">
                    <pendo-icon
                        type="alert-circle"
                        class="empty-icon"
                        stroke="#9a9ca5"
                        size="24" />
                    <span class="empty-text">
                        {{ emptyText }}
                    </span>
                </div>
            </template>
            <template #name="{ row }">
                <pendo-app-display
                    :show-app-icons="!!row.platform"
                    :apps="row" />
            </template>
        </pendo-table>
    </main>
</template>

<script>
import Search from '@/components/Search.vue';
import { rowFormatter } from '@/utils/table-formatters';
import { validPeriodsForCount } from '@/utils/time-series';
import {
    PendoDataSourceTrigger,
    PendoLoading,
    PendoMultiselect,
    PendoTable,
    PendoIcon,
    PendoAppDisplay
} from '@pendo/components';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { buildBreakdownDateMap } from '@/utils/portfolio-app-usage';

export default {
    name: 'PortfolioAppUsageList',
    components: {
        PendoDataSourceTrigger,
        PendoMultiselect,
        PendoTable,
        PendoIcon,
        Search,
        PendoAppDisplay
    },
    directives: {
        PendoLoading
    },
    props: {
        isFetchingList: {
            type: Boolean,
            default: false
        },
        isViewingApplicationsByMetadata: {
            type: Boolean,
            default: false
        },
        dateRange: {
            type: Object,
            default: () => ({ count: 'weekly' })
        },
        viewByMetadataField: {
            type: Object,
            default: () => null
        },
        appUsageListUserSettings: {
            type: Object,
            default: () => null
        },
        appMap: {
            type: Object,
            default: () => ({})
        },
        appUsagePeriodBreakdowns: {
            type: Array,
            default: () => []
        },
        appUsagePeriod: {
            type: String,
            default: 'monthly'
        },
        activeTimezone: {
            type: String,
            required: true
        }
    },
    emits: ['refreshAppUsageData', 'updateAppUsageListUserSettings'],
    data () {
        return {
            defaultSort: {
                prop: 'visitors',
                order: 'ascending'
            },
            searchInput: '',
            selectedField: {
                id: 'summedMinutes',
                label: 'Time Spent on Application (minutes)'
            },
            selectedFieldOptions: [
                {
                    id: 'summedMinutes',
                    label: 'Time Spent on Application (minutes)'
                },
                {
                    id: 'visitors',
                    label: 'Visitors'
                }
            ],
            emptyText: 'No data found. Try changing filters or selecting different applications.'
        };
    },
    computed: {
        sortedUsageList () {
            if (this.isViewingApplicationsByMetadata) {
                return this.appUsageSortedBreakdownsList;
            }

            return this.metadataFieldValuesUsageSortedBreakdownsList;
        },
        appUsageSortedBreakdownsList () {
            const sortedChildrensList = Object.values(this.appUsageBreakdownsMap).map((app) => {
                return {
                    ...app,
                    children: Object.values(app.children).sort((a, b) => {
                        return a.group.toLowerCase() < b.group.toLowerCase() ? -1 : 1;
                    })
                };
            });

            const sortedAppUsageBreakdownsList = sortedChildrensList.sort((a, b) => {
                return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
            });

            return sortedAppUsageBreakdownsList;
        },
        appUsageBreakdownsMap () {
            const appUsageBreakdownsMap = this.appUsagePeriodBreakdowns.reduce((map, period) => {
                const { time, rows } = period;
                rows.forEach((row) => {
                    const { id, name, groupValue, visitors, summedMinutes } = row;

                    const { color, displayName, faviconB64, platform, trainingAttributes, applicationFlags } = get(
                        this.appMap,
                        row.id,
                        {}
                    );

                    map[id] = map[id] || {
                        id,
                        name, // name of application
                        color,
                        displayName,
                        faviconB64,
                        platform,
                        trainingAttributes,
                        applicationFlags,
                        group: 'All Groups',
                        breakdown: {
                            // 1635739200000: { visitors: 10 summedMinutes: 9 }
                            // 1638334800000: { visitors: 3, summedMinutes: 19 }
                        },
                        children: {
                            // product: { group: 'product', breakdown: { 1635739200000: { visitors: 6, summedMinutes: 3 }, 1638334800000: { visitors: 1, summedMinutes: 3 } } }
                            // design: { group: 'design', breakdown: { 1635739200000: { visitors: 4, summedMinutes: 6 }, 1638334800000: { visitors: 2, summedMinutes: 16 } } }
                        }
                    };

                    // capture period breakdowns for each metadata field value
                    map[id].children[groupValue] = map[id].children[groupValue] || {
                        id,
                        group: groupValue,
                        name: ' '
                    };
                    map[id].children[groupValue].breakdown = map[id].children[groupValue].breakdown || {};
                    map[id].children[groupValue].breakdown[time] = map[id].children[groupValue].breakdown[time] || {};
                    map[id].children[groupValue].breakdown[time] = {
                        ...row,
                        group: row.groupValue
                    };

                    // aggregate the children for 'All Groups'
                    map[id].breakdown[time] = map[id].breakdown[time] || { visitors: 0, summedMinutes: 0 };
                    map[id].breakdown[time].visitors += visitors;
                    map[id].breakdown[time].summedMinutes += summedMinutes;
                });

                return map;
            }, {});

            return appUsageBreakdownsMap;
        },
        metadataFieldValuesUsageSortedBreakdownsList () {
            const sortedChildrensList = Object.values(this.metadataFieldValuesUsageBreakdownsMap).map(
                (metadataField) => {
                    return {
                        ...metadataField,
                        children: Object.values(metadataField.children).sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        })
                    };
                }
            );

            const sortedMetadataFieldValuesUsageBreakdownsList = sortedChildrensList.sort((a, b) => {
                return a.group.toLowerCase() < b.group.toLowerCase() ? -1 : 1;
            });

            return sortedMetadataFieldValuesUsageBreakdownsList;
        },
        metadataFieldValuesUsageBreakdownsMap () {
            const metadataFieldValuesUsageAppBreakdownsMap = this.appUsagePeriodBreakdowns.reduce((map, period) => {
                const { time, rows } = period;
                rows.forEach((row) => {
                    const { id, name, groupValue, visitors, summedMinutes } = row;

                    const { color, displayName, faviconB64, platform, trainingAttributes, applicationFlags } = get(
                        this.appMap,
                        row.id,
                        {}
                    );

                    map[groupValue] = map[groupValue] || {
                        id: groupValue,
                        name: 'All Apps',
                        group: groupValue, // name of metadata field value
                        breakdown: {
                            // 1635739200000: { visitors: 10 summedMinutes: 9 }
                            // 1638334800000: { visitors: 3, summedMinutes: 19 }
                        },
                        children: {
                            // amazonAppId: { group: ' ', name: 'Amazon', breakdown: { 1635739200000: { visitors: 6, summedMinutes: 3 }, 1638334800000: { visitors: 1, summedMinutes: 3 } } }
                            // gmailAppId: { group: ' ', name: 'Gmail', breakdown: { 1635739200000: { visitors: 4, summedMinutes: 6 }, 1638334800000: { visitors: 2, summedMinutes: 16 } } }
                        }
                    };
                    map[groupValue].children[id] = map[groupValue].children[id] || {
                        id,
                        group: ' ',
                        name,
                        color,
                        displayName,
                        faviconB64,
                        platform,
                        trainingAttributes,
                        applicationFlags
                    };
                    map[groupValue].children[id].breakdown = map[groupValue].children[id].breakdown || {};
                    map[groupValue].children[id].breakdown[time] = map[groupValue].children[id].breakdown[time] || {};
                    map[groupValue].children[id].breakdown[time] = {
                        ...row,
                        group: row.id
                    };

                    // aggregate the children for 'All Apps'
                    map[groupValue].breakdown[time] = map[groupValue].breakdown[time] || {
                        visitors: 0,
                        summedMinutes: 0
                    };
                    map[groupValue].breakdown[time].visitors += visitors;
                    map[groupValue].breakdown[time].summedMinutes += summedMinutes;
                });

                return map;
            }, {});

            return metadataFieldValuesUsageAppBreakdownsMap;
        },
        usageBreakdownDate () {
            return Object.values(this.usageBreakdownDateMap).sort((a, b) => (a.value < b.value ? -1 : 1));
        },
        usageBreakdownDateMap () {
            return buildBreakdownDateMap(this.appUsagePeriodBreakdowns, this.appUsagePeriod);
        },
        columns () {
            const columns = [
                ...this.usageBreakdownDate.map((month) => {
                    return {
                        allowResize: false,
                        label: month.name,
                        prop: `breakdown.${month.id}.${this.selectedField.id}`,
                        schema: 'integer',
                        sortable: true
                    };
                })
            ];

            const appColumn = {
                fixed: true,
                label: 'Application',
                minWidth: 200,
                prop: 'name',
                schema: 'string',
                sortable: true,
                width: get(this.appUsageListUserSettings, 'sizes.name')
            };

            const groupColumn = {
                fixed: true,
                label: 'Group',
                minWidth: 200,
                prop: 'group',
                schema: 'string',
                sortable: true,
                width: get(this.appUsageListUserSettings, 'sizes.group')
            };

            if (this.isViewingApplicationsByMetadata) {
                columns.unshift(appColumn, groupColumn);
            } else {
                columns.unshift(groupColumn, appColumn);
            }

            const hasMultipleGroupsPerApp = this.isViewingApplicationsByMetadata && this.viewByMetadataField;

            if (hasMultipleGroupsPerApp || !this.isViewingApplicationsByMetadata) {
                columns.unshift({
                    type: 'tree',
                    fixed: true,
                    allowReorder: false
                });
            }

            const data = columns.map((col) => {
                if (col.type === 'tree') {
                    return {
                        ...col
                    };
                }

                return {
                    ...col,
                    formatter: (row) => rowFormatter(row, col, this.activeTimezone)
                };
            });

            return data;
        },
        filters () {
            return [
                {
                    prop: ['name', 'group'],
                    value: this.searchInput
                }
            ];
        },
        period () {
            return this.periodOptions.find((period) => period.id === this.appUsagePeriod);
        },
        periodOptions () {
            const { dateRange } = this;

            return validPeriodsForCount(dateRange.count).map((period) => ({
                id: period,
                label: capitalize(period)
            }));
        },
        sort () {
            const sortUserSettings = get(this.appUsageListUserSettings, 'sort', null);
            if (!sortUserSettings) return this.defaultSort;

            const sortedColumnDoesNotExist = !this.columns.find((column) => column.prop === sortUserSettings.prop);
            if (sortedColumnDoesNotExist) return this.defaultSort;

            return sortUserSettings;
        }
    },
    methods: {
        onColumnResized ({ column, width }) {
            const appUsageListUserSettings = cloneDeep(this.appUsageListUserSettings) || {};
            if (!appUsageListUserSettings.sizes) {
                appUsageListUserSettings.sizes = {};
            }

            appUsageListUserSettings.sizes[column] = width;
            this.$emit('updateAppUsageListUserSettings', {
                appUsageListUserSettings
            });
        },
        onPeriodChanged (period) {
            this.$emit('refreshAppUsageData', { appUsagePeriod: period.id });
        },
        onSortChanged ({ order, prop }) {
            if (this.isFetchingList) {
                return;
            }

            const appUsageListUserSettings = cloneDeep(this.appUsageListUserSettings) || {};
            appUsageListUserSettings.sort = {
                order,
                prop
            };
            this.$emit('updateAppUsageListUserSettings', {
                appUsageListUserSettings
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-app-usage-list {
    &--table-header-left {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;

        .pendo-icon {
            margin-right: 0.5em;
            display: flex;
        }

        .empty-text {
            color: $gray-primary;
        }
    }
}

:deep(td.pendo-table__column:first-of-type) {
    .pendo-table__cell:empty::after {
        content: '';
    }
}
</style>
