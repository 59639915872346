<template>
    <pendo-empty-state background-color="inherit">
        <template #icon>
            <pendo-loading-indicator
                v-if="loading"
                :loading="loading"
                size="large" />
            <tooltip-guides-image v-if="showContent" />
        </template>
        <template
            v-if="showContent"
            #title>
            <h3>
                It’s time to create your first guide!
            </h3>
        </template>
        <template
            v-if="showContent"
            #actions>
            <pendo-button
                theme="app"
                type="secondary"
                class="create-guide"
                data-cy="guide-list-empty--create-guide"
                prefix-icon="plus"
                label="Create Guide"
                @click="$emit('showGuideCreateChange', true)" />
        </template>
    </pendo-empty-state>
</template>

<script>
import { mapState } from 'vuex';
import { PendoButton, PendoLoadingIndicator, PendoEmptyState } from '@pendo/components';
import TooltipGuidesImage from '@/components/svgs/TooltipGuidesImage';

export default {
    name: 'GuideListEmpty',
    components: {
        PendoButton,
        PendoLoadingIndicator,
        PendoEmptyState,
        TooltipGuidesImage
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ['showGuideCreateChange'],
    computed: {
        ...mapState({
            updatingActiveApp: (state) => state.apps.updatingActive
        }),
        // used to prevent flash of content when switching subs/apps
        showContent () {
            return !this.loading && !this.updatingActiveApp;
        }
    }
};
</script>
