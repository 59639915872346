import { createStore as _createStore } from 'vuex';
import { pendolytics } from './plugins/pendo.js';
import { theme } from './plugins/theme.js';
import router from '@/router';

import modules from './modules';

function getStore () {
    return new _createStore({
        modules,
        state: {
            get route () {
                return router.currentRoute.value;
            }
        },
        plugins: [pendolytics, theme],
        strict: false
    });
}

const store = getStore();

export default store;

// Used in Cypress Component Testing
export { getStore };
