<template>
    <pendo-popover
        :open="insightsShowing"
        popover-class="insights-popover"
        container=".journeys-dashboard"
        arrow
        :placement="placement"
        :offset="offset"
        trigger="manual"
        :auto-hide="false">
        <template #default>
            <slot />
        </template>
        <template #popover>
            <div class="insight">
                <div class="header">
                    <insights-icon />
                    <span class="title">{{ insight.title }}</span>
                    <pendo-button
                        icon="x"
                        class="close-button"
                        type="tertiary"
                        icon-size="20"
                        @click="hideInsights" />
                </div>
                <insight-body
                    :steps="steps"
                    :insight="insight"
                    :journey-list="journeyList"
                    :avg-times="avgTimes" />
                <div class="footer">
                    <pendo-icon-button
                        icon="chevron-left"
                        :disabled="!hasPrev"
                        @click="$emit('prev')" />
                    <span>Insight {{ meta.current }} of {{ meta.count }} </span>
                    <pendo-icon-button
                        icon="chevron-right"
                        :disabled="!hasNext"
                        @click="$emit('next')" />
                </div>
            </div>
        </template>
    </pendo-popover>
</template>

<script>
import { PendoPopover, PendoButton, PendoIconButton } from '@pendo/components';
import InsightsIcon from '@/components/icons/Insights';
import InsightBody from './InsightBody';

export default {
    name: 'InsightPopover',
    components: {
        PendoPopover,
        PendoButton,
        PendoIconButton,
        InsightsIcon,
        InsightBody
    },
    props: {
        insight: {
            type: Object,
            required: true
        },
        meta: {
            type: Object,
            required: true
        },
        journeyList: {
            type: Array,
            default: () => []
        },
        steps: {
            type: Array,
            default: () => []
        },
        avgTimes: {
            type: Object,
            default: () => ({})
        },
        placement: {
            type: String,
            default: 'right'
        },
        insightsShowing: {
            type: Boolean,
            default: false
        }
    },
    emits: ['prev', 'next', 'hideInsights'],
    computed: {
        hasPrev () {
            return this.meta.current > 1;
        },
        hasNext () {
            return this.meta.current < this.meta.count;
        },
        offset () {
            return ['top', 'bottom'].includes(this.placement) ? 0 : 20;
        }
    },
    methods: {
        hideInsights () {
            this.$emit('hideInsights');
        }
    }
};
</script>
<style lang="scss">
.insights-popover {
    .pendo-popover__inner {
        padding: 0;
    }
}
</style>

<style lang="scss" scoped>
.insight {
    width: 500px;
}

.header {
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    justify-content: left;
    padding: 16px 24px;
    border-bottom: solid 1px $color-gray-40;
    line-height: 24px;

    .title {
        margin-left: 8px;
    }

    .close-button {
        position: absolute;
        right: 24px;

        :deep(.pendo-icon) {
            svg {
                stroke: $color-gray-70;
            }
        }
    }
}

.body {
    padding: 24px;
    font-size: 13px;
    line-height: 19px;
}

.footer {
    padding: 12px 0;
    font-size: 12px;
    background-color: $color-gray-20;
    display: flex;
    justify-content: center;
    align-items: center;

    .pendo-icon-button {
        background-color: $color-purple-70;
        color: $color-white;
        border-radius: 50%;
        margin: 0 10px;
        height: 20px;
        width: 20px;

        &:hover {
            background-color: $color-purple-90;
            color: $color-white;
        }

        &.is-disabled {
            background-color: $color-gray-60;
            color: $color-white;

            &:hover {
                background-color: $color-gray-60;
                color: $color-white;
            }
        }

        &.is-icon-only {
            min-width: 20px;
        }
    }
}
</style>
