<template>
    <div class="time-to-complete-chart">
        <summary-chart-card
            :is-fetching="loading"
            :summary-width="318">
            <template #header>
                <div>
                    Time to complete
                </div>
                <div>
                    <pendo-multiselect
                        :allow-empty="false"
                        :min-menu-width="180"
                        :options="dataTypeOptions"
                        :value="dataTypeOption"
                        class="data-type-dropdown"
                        @select="onUpdateDataType($event.id)">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
                by length in
                <div>
                    <pendo-multiselect
                        :allow-empty="false"
                        :min-menu-width="80"
                        :options="timePeriodOptions"
                        :value="timePeriodOption"
                        class="time-period-dropdown"
                        @select="onUpdateTimePeriod($event.id)">
                        <template #trigger>
                            <pendo-data-source-trigger />
                        </template>
                    </pendo-multiselect>
                </div>
                <slot name="addToDashboard" />
            </template>
            <template #chart>
                <div
                    v-if="noData"
                    class="empty-state">
                    <p class="empty-state-text">
                        No data found
                    </p>
                </div>
                <div ref="time-to-complete-chart" />
            </template>
            <template #summary>
                <div
                    v-if="noData"
                    class="empty-state">
                    <p class="empty-state-text">
                        No data found
                    </p>
                </div>
                <div
                    v-else
                    class="time-to-complete-summary">
                    <div class="median-summary">
                        <div class="summary-metric">
                            {{ medianTimeToComplete }}
                        </div>
                        <div class="summary-label">
                            Median time to complete
                        </div>
                    </div>
                    <div class="average-summary">
                        <div class="summary-metric">
                            {{ averageTimeToComplete }}
                        </div>
                        <div class="summary-label">
                            Average time to complete
                        </div>
                    </div>
                </div>
            </template>
        </summary-chart-card>
    </div>
</template>

<script>
import get from 'lodash/get';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import { PendoDataSourceTrigger, PendoMultiselect } from '@pendo/components';

import { reusableTooltipFormatter, positionTooltipRightOfColumn, CHART_BASE } from '@/utils/highcharts';
import { pluralize } from '@/utils/formatters';
import { longPrettyTime } from '@/utils/durations';

import SummaryChartCard from '@/components/common/SummaryChartCard';

export default {
    name: 'WorkflowTimeToCompleteChart',
    components: {
        PendoDataSourceTrigger,
        PendoMultiselect,
        SummaryChartCard
    },
    props: {
        savedWorkflowSettings: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        isRecurring: {
            type: Boolean,
            default: false
        },
        timeToCompleteAggData: {
            type: Array,
            default: null
        },
        timeToCompleteSummaryAggData: {
            type: Object,
            default: null
        }
    },
    emits: ['updateDataType', 'updateTimePeriod'],
    data () {
        return {
            chart: null,
            dataTypeOptions: [
                {
                    id: 'distribution',
                    label: 'distribution'
                },
                {
                    id: 'cumulative',
                    label: 'cumulative'
                }
            ],
            minColumnWidth: 25
        };
    },
    computed: {
        noData () {
            if (this.loading) return false;
            if (!this.timeToCompleteAggData) return true;

            return this.timeToCompleteAggData.length === 0;
        },
        completionUnits () {
            return this.isRecurring ? 'Completed attempts' : 'Visitor completions';
        },
        dataTypeOption () {
            const setting = get(this.savedWorkflowSettings, 'timeToCompleteChartDataType', null);

            return this.dataTypeOptions.find((option) => option.id === setting) || this.dataTypeOptions[0];
        },
        dataTypeIsCumulative () {
            return this.dataTypeOption.id === 'cumulative';
        },
        timePeriodOptions () {
            const timePeriodOptions = [];
            const maxPeriodsToComplete = this.timeToCompleteAggData
                ? get(this.timeToCompleteAggData[this.timeToCompleteAggData.length - 1], 'periodsToComplete', 0)
                : 0;

            // Highcharts maximum number of x-axis values appears to be 1000
            if (maxPeriodsToComplete < 1000) {
                timePeriodOptions.push({
                    id: 'minutes',
                    label: 'minutes'
                });
            }

            if (maxPeriodsToComplete / 4 < 1000) {
                timePeriodOptions.push({
                    id: 'hourly',
                    label: 'hours'
                });
            }

            timePeriodOptions.push(
                ...[
                    {
                        id: 'daily',
                        label: 'days'
                    },
                    {
                        id: 'weekly',
                        label: 'weeks'
                    }
                ]
            );

            return timePeriodOptions;
        },
        timePeriodOption () {
            const setting = get(this.savedWorkflowSettings, 'timeToCompleteChartTimePeriod', null);

            const matchingOption = this.timePeriodOptions.find((option) => option.id === setting);

            if (matchingOption) return matchingOption;

            return this.timePeriodOptions[0];
        },
        chartScrollablePlotArea () {
            return get(this.chartData, '[0].data.length', 4) * (this.minColumnWidth + 15);
        },
        chartConfig () {
            const vm = this;
            const chartConfig = {
                ...CHART_BASE,
                chart: {
                    type: 'column',
                    height: 515
                },
                series: this.chartData,
                xAxis: {
                    min: 0,
                    crosshair: {
                        color: 'rgba(0,0,0,0)'
                    },
                    labels: {
                        formatter: this.formatXAxisValue
                    },
                    maxPadding: 0
                },
                yAxis: {
                    labels: {
                        align: 'right',
                        style: {
                            color: '#6A6C75',
                            fontSize: '12px'
                        },
                        x: -6,
                        y: 4,
                        format: this.yAxisFormat
                    },
                    maxPadding: 0,
                    min: 0,
                    tickInterval: this.dataTypeIsCumulative ? 25 : null,
                    title: {
                        style: {
                            color: '#1A1C25',
                            fontSize: '12px'
                        },
                        text: this.yAxisLabel
                    }
                },
                legend: {
                    enabled: false
                }
            };

            set(chartConfig, 'plotOptions.series.pointWidth', this.minColumnWidth);
            set(chartConfig, 'chart.scrollablePlotArea.minWidth', this.chartScrollablePlotArea);
            set(chartConfig, 'chart.marginBottom', 120);

            chartConfig.tooltip = {
                outside: false,
                useHTML: true,
                borderColor: '#DADCE5',
                padding: 0,
                shadow: {
                    color: 'rgba(0, 0, 0, 0.17)',
                    width: 8
                },
                style: {
                    color: '#FFFFFF'
                },
                formatter () {
                    const { x, tooltipValue, cumulativeTotal, color } = this.point;
                    const {
                        completionUnits,
                        dataTypeIsCumulative,
                        totalCompletions,
                        timePeriodOption,
                        prettyPercent
                    } = vm;

                    let timeRange = [x, x + 1];
                    if (timePeriodOption.id === 'minutes') timeRange = timeRange.map((value) => value * 15);

                    const periodUnits = pluralize(timePeriodOption.label, timeRange[1]);

                    const cumulativeTime = `${timeRange[1]} ${periodUnits} or less`;
                    const distributionTime =
                        x === 0 ? cumulativeTime : `Between ${timeRange[0]} to ${timeRange[1]} ${periodUnits}`;

                    const headerDescription = dataTypeIsCumulative ? cumulativeTime : distributionTime;

                    const bodyMetric = dataTypeIsCumulative
                        ? `${tooltipValue}%<span class="small"> of ${completionUnits}</span>`
                        : `${tooltipValue}<span class="small"> ${pluralize(completionUnits, tooltipValue)} </span>`;
                    const bodyDescription = dataTypeIsCumulative
                        ? `${cumulativeTotal}<span class="small"> of </span>${totalCompletions}<span class="small"> Total</span>`
                        : `${prettyPercent(tooltipValue, totalCompletions)}%<span class="small"> of total</span>`;

                    const options = {
                        classes: ['time-to-complete-chart--tooltip'],
                        color,
                        headerTitle: 'Completed',
                        headerDescription,
                        bodyMetric,
                        bodyDescription
                    };

                    return reusableTooltipFormatter(options);
                },
                positioner (boxWidth, boxHeight, point) {
                    return positionTooltipRightOfColumn(this.chart, { boxWidth, boxHeight }, point);
                }
            };

            return chartConfig;
        },
        chartData () {
            const data = this.getChartData(this.dataTypeOption.id);

            return [
                {
                    timePeriodOption: this.timePeriodOption,
                    dataTypeOption: this.dataTypeOption,
                    color: '#FF4994',
                    data,
                    legendIndex: 0
                }
            ];
        },
        yAxisLabel () {
            return this.dataTypeIsCumulative ? `${this.completionUnits} (% of total)` : `${this.completionUnits} (#)`;
        },
        medianTimeToComplete () {
            return longPrettyTime(get(this.timeToCompleteSummaryAggData, 'medianTimeToComplete', 0));
        },
        averageTimeToComplete () {
            return longPrettyTime(get(this.timeToCompleteSummaryAggData, 'avgTimeToComplete', 0));
        },
        totalCompletions () {
            return get(this.timeToCompleteSummaryAggData, 'totalCompletions', 0);
        }
    },
    watch: {
        chartData (newData, oldData) {
            if (!isEqual(newData, oldData)) {
                this.drawChart();
            }
        }
    },
    mounted () {
        this.drawChart();
    },
    methods: {
        drawChart () {
            if (this.loading || this.noData) return;

            this.chart = this.$pendo.highcharts.chart(this.$refs['time-to-complete-chart'], this.chartConfig);
            const series = this.chart.series[0];

            if (series && series.barW < this.minColumnWidth) {
                this.chart = this.$pendo.highcharts.chart(this.$refs['time-to-complete-chart'], this.chartConfig);
            }
        },
        formatXAxisValue (axisInfo) {
            let { value: previousValue } = axisInfo;
            let value = previousValue + 1;

            if (this.timePeriodOption.id === 'minutes') {
                previousValue *= 15;
                value *= 15;
            }

            if (this.dataTypeIsCumulative) return `< ${value} ${pluralize(this.timePeriodOption.label, value)}`;

            return `${previousValue} to ${value} ${pluralize(this.timePeriodOption.label, value)}`;
        },
        formatYAxisValue (axisInfo) {
            const { value } = axisInfo;

            return this.dataTypeIsCumulative ? `${value}%` : `${value}`;
        },
        getChartData (dataTypeOption) {
            if (!this.timeToCompleteAggData || !this.timeToCompleteAggData.length) return [];

            const dataBy15MinuteBlock = keyBy(this.timeToCompleteAggData, 'periodsToComplete');
            const maxBlockCount = this.timeToCompleteAggData[this.timeToCompleteAggData.length - 1].periodsToComplete;
            const flatDataWithZeroValues = [];
            for (let i = 1; i <= maxBlockCount; i++) {
                flatDataWithZeroValues.push(get(dataBy15MinuteBlock, `${i}.count`, 0));
            }
            let periodAdjustedData = flatDataWithZeroValues;

            if (this.timePeriodOption.id !== 'minutes') {
                let blocksPerPeriod;

                switch (this.timePeriodOption.id) {
                    case 'hourly':
                        blocksPerPeriod = 4;
                        break;
                    case 'weekly':
                        blocksPerPeriod = 4 * 24 * 7;
                        break;
                    case 'daily':
                    default:
                        blocksPerPeriod = 4 * 24;
                        break;
                }

                periodAdjustedData = flatDataWithZeroValues.reduce((periodData, count, blockIndex) => {
                    const periodCount = Math.floor(blockIndex / blocksPerPeriod);
                    const lastPeriodWithData = periodData.length - 1;
                    if (lastPeriodWithData !== periodCount) return [...periodData, count];

                    periodData[periodData.length - 1] += count;

                    return periodData;
                }, []);
            }

            for (let i = periodAdjustedData.length; i < 4; i++) {
                periodAdjustedData.push(0);
            }
            if (dataTypeOption === 'distribution') {
                // If one value is less than 1% of the largest value, highcharts might render it with 0 pixels
                // To avoid that, we fake the value to a slightly larger size and display the real value in the tooltip
                const minValueForDisplay = Math.floor(0.01 * Math.max(...periodAdjustedData));

                return periodAdjustedData.map((value) => ({
                    y: value === 0 ? 0 : Math.max(value, minValueForDisplay),
                    tooltipValue: value
                }));
            }

            if (dataTypeOption === 'cumulative') {
                let total = 0;
                const cumulativeCount = periodAdjustedData.map((count) => {
                    total += count;

                    return total;
                });

                return cumulativeCount.map((count) => {
                    const y = this.prettyPercent(count, total);

                    return {
                        y,
                        tooltipValue: y,
                        cumulativeTotal: count
                    };
                });
            }

            return [];
        },
        prettyPercent (count, total) {
            let percent = Math.round((count / total) * 100);
            if (percent === 100 && count !== total) percent = 99;
            if (percent === 0 && count > 0) percent = ((count / total) * 100).toFixed(1);

            return percent;
        },
        onUpdateDataType (newDataType) {
            this.$emit('updateDataType', newDataType);
        },
        onUpdateTimePeriod (newTimePeriod) {
            this.$emit('updateTimePeriod', newTimePeriod);
        }
    }
};
</script>

<style lang="scss">
.time-to-complete-chart {
    &--tooltip {
        min-width: 200px;
        font-size: 14px;
        color: $gray-primary;

        .chart-tooltip {
            &-header {
                border-bottom: 1px solid $gray-lighter-5;
                display: flex;
                flex-direction: column;
                padding: 16px 35px 16px 0;

                &--title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 20px;

                    & .header-title-text {
                        font-weight: 600;
                        margin-left: 5px;
                        overflow: hidden;
                        max-width: 20ch;
                        text-overflow: ellipsis;
                    }
                }

                &--description {
                    display: block;
                    font-size: 12px;
                    overflow: hidden;
                    max-width: 30ch;
                    padding-left: 35px;
                    text-align: left;
                    text-overflow: ellipsis;
                }
            }

            &-body {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                font-weight: bold;
                padding: 16px 35px;

                .small {
                    font-size: 14px;
                    font-weight: normal;
                }

                &--metric {
                    font-size: 22px;
                    padding-bottom: 14px;
                }
            }
        }
    }

    .summary-chart-card__chart {
        height: 547px;
    }

    .pendo-card__header {
        display: flex;
        gap: 0.4em;
        font-size: 18px;
        justify-content: flex-start;
    }

    .empty-state {
        align-items: center;
        display: flex;
        height: 515px;
        width: 100%;

        &-text {
            margin: 0;
            text-align: center;
            width: 100%;
        }
    }

    // This is because the highcharts element needs to be in the DOM to render the chart.
    .chart--hidden {
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
}

.time-to-complete-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;

    summary-metric-block {
        display: flex;
    }

    .summary-metric {
        font-size: 22px;
        font-weight: 600;
        line-height: 1.5;
    }

    .summary-label {
        color: $gray-lighter-2;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }
}
</style>
