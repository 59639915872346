<template>
    <pendo-card
        data-cy="guide-creation-with-ai-panel"
        body-min-height="450px"
        class="generate-guide-card"
        title="Generate guide">
        <template #body>
            <div class="guide-creation-with-ai-panel--body">
                <pendo-alert
                    inline
                    title="Disclaimer:"
                    type="warning"
                    description="Responses generated by Pendo AI are not guaranteed to be accurate or complete. Please carefully review and validate the results before publishing guides." />
                <div class="guide-creation-with-ai-panel--body-prompt-form">
                    <prompt-form
                        :theme="theme"
                        :application="application"
                        :is-loading="isLoading || isGeneratingSteps"
                        :prompt="prompt"
                        @updatePrompt="$emit('updatePrompt', $event)"
                        @generateSteps="$emit('generateSteps', $event)" />
                </div>
                <div class="guide-creation-with-ai-panel--body-table">
                    <suggested-steps-table
                        v-if="stepsAreGenerated"
                        :is-loading="isLoading"
                        :is-regenerating-step="isRegeneratingStep"
                        :steps="generatedSteps"
                        @updateSteps="$emit('updateSteps', $event)"
                        @regenerateStep="$emit('regenerateStep', $event)" />
                    <pendo-empty-state
                        v-if="isGeneratingSteps || !generatedSteps.length"
                        class="guide-creation-with-ai-panel--empty-state"
                        :description="
                            isGeneratingSteps
                                ? 'Generating guide content...'
                                : 'Enter prompt above and click Auto Generate Guide'
                        ">
                        <template #icon>
                            <guide-a-i-generation-illustration :loading="isGeneratingSteps" />
                        </template>
                    </pendo-empty-state>
                </div>
                <div class="guide-creation-with-ai-panel--step-preview">
                    <step-preview-carousel
                        v-if="stepsAreGenerated"
                        :steps="generatedSteps"
                        :guide="guide"
                        :show-app="false" />
                </div>
            </div>
        </template>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoAlert, PendoEmptyState } from '@pendo/components';
import PromptForm from '@/stateless-components/guides/ai/PromptForm.vue';
import SuggestedStepsTable from '@/stateless-components/guides/ai/SuggestedStepsTable.vue';
import GuideAIGenerationIllustration from '@/stateless-components/guides/ai/GuideAIGenerationIllustration';
import StepPreviewCarousel from '@/stateless-components/guides/common/StepPreviewCarousel';

export default {
    name: 'GuideCreationWithAIPanel',
    components: {
        PendoCard,
        PendoAlert,
        PromptForm,
        SuggestedStepsTable,
        StepPreviewCarousel,
        PendoEmptyState,
        GuideAIGenerationIllustration
    },
    props: {
        application: {
            type: Object,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isGeneratingSteps: {
            type: Boolean,
            default: false
        },
        isRegeneratingStep: {
            type: Boolean,
            default: false
        },
        generatedSteps: {
            type: Array,
            default: () => []
        },
        prompt: {
            type: Object,
            default: null
        },
        theme: {
            type: String,
            default: null
        }
    },
    emits: ['generateSteps', 'updatePrompt', 'updateSteps', 'regenerateStep'],
    data () {
        return {
            guide: { id: 'fakeGuideId' },
            disclaimerText:
                'Responses generated by Pendo AI are not guaranteed to be accurate or complete. Please carefully review and validate the results before publishing guides.'
        };
    },
    computed: {
        stepsAreGenerated () {
            return !this.isGeneratingSteps && this.generatedSteps.length > 0;
        }
    }
};
</script>

<style lang="scss" scoped>
.generate-guide-card {
    grid-row: span 2;
}

.guide-creation-with-ai-panel {
    :deep(.VueCarousel) {
        @include carousel;
    }

    &--empty-state {
        position: relative;
    }

    &--body {
        display: flex;
        gap: 15px;
        align-content: flex-start;
        flex-flow: wrap;
        min-height: inherit;

        &-alert,
        &-table {
            flex: 1 0 100%;
        }

        &-prompt-form {
            flex: 1 0 40%;
        }

        .pendo-card {
            &__body {
                min-height: 300px;
            }
        }
    }

    &--step-preview {
        position: relative;
        width: 100%;
        overflow: hidden;
        padding: 0 20px;
    }
}
</style>
