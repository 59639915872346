<template>
    <pendo-table
        class="path-visitor-table"
        :data="rows"
        :columns="columns"
        :height="700"
        :default-sort="{ prop: 'visitorId', order: 'ascending' }"
        csv-download
        :csv-filename="csvFilename"
        row-key="id">
        <template #empty>
            <div class="path-visitor-table__empty-text">
                <div class="empty-text__header">
                    No Visitors Found
                </div>
                <div>There are no visitors found in the current selection.</div>
                <div>
                    Try modifying selection to view data.
                </div>
            </div>
        </template>
        <template #headerLeft>
            <div class="path-visitor-table__header">
                <pendo-icon
                    size="18"
                    :type="titleIcon" />
                <span
                    :title="stepNames"
                    class="path-visitor-table__title-name">
                    {{ stepNames }}
                </span>
            </div>
        </template>
        <template #nextStepAppName="{ row }">
            <pendo-app-display :apps="row.nextStepApp" />
        </template>
    </pendo-table>
</template>
<script>
import { v4 as uuid } from 'uuid';
import map from 'lodash/map';
import head from 'lodash/head';
import last from 'lodash/last';
import snakeCase from 'lodash/snakeCase';
import truncate from 'lodash/truncate';
import get from 'lodash/get';
import { PendoTable, PendoIcon, PendoAppDisplay } from '@pendo/components';
import { isUntaggedNode, isOtherNode, isVisitorPathInStep, getNextMatchKeys } from '@pendo/services/Paths';
import { prettyTime } from '@pendo/services/Formatters';
import { convertToSubscriptionTimezone, DATE_FORMAT } from '@/utils/moment';

export default {
    name: 'PathVisitorTable',
    components: {
        PendoTable,
        PendoIcon,
        PendoAppDisplay
    },
    props: {
        pathResource: {
            type: Object,
            required: true
        },
        pathState: {
            type: Object,
            required: true
        },
        labeler: {
            type: Object,
            required: true
        },
        activeTimezone: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            columns: this.buildColumns(this.pathResource)
        };
    },
    computed: {
        titleIcon () {
            const icons = {
                Page: 'file',
                Feature: 'feature',
                TrackType: 'zap',
                Guide: 'guide'
            };

            const match = head(this.pathState.selectedStep.matches);
            const resourceKind = this.labeler.type(this.pathState.frozenPathData, match);

            return icons[resourceKind];
        },
        stepNames () {
            const { frozenPathData, selectedStep } = this.pathState;

            return this.getNamesFromMatches(frozenPathData, selectedStep.matches);
        },
        csvFilename () {
            const { predecessors } = this.pathResource.definition.config;
            const stepNames = truncate(snakeCase(this.stepNames), {
                length: 30,
                omission: ''
            });

            return `paths_${predecessors ? 'to' : 'from'}_${stepNames}`;
        },
        rows () {
            const { selectedStep, frozenVisitors, frozenPathData } = this.pathState;

            return frozenVisitors
                .filter((visitor) => isVisitorPathInStep(visitor, selectedStep))
                .map((visitor) => {
                    const { visitorId } = visitor;
                    const date = this.getStepDate(visitor, selectedStep);
                    const nextMatchKeys = getNextMatchKeys(visitor, selectedStep);
                    const nextStepNames = this.getNamesFromMatches(frozenPathData, nextMatchKeys);
                    const firstMatch = head(nextMatchKeys);
                    const nextStepApp = this.getNextStepApp(frozenPathData, firstMatch);

                    return {
                        visitorId,
                        date,
                        timeToNextStep: this.getStepTime(visitor, selectedStep),
                        id: date ? `${visitorId}${date}` : `${visitorId}${uuid()}`,
                        url: this.getStepUnknownUrl(visitor),
                        nextStepNames,
                        nextStepApp
                    };
                });
        },
        isOtherNode () {
            return isOtherNode(this.pathState.selectedStep);
        }
    },
    watch: {
        'pathResource.definition.config.predecessors': function () {
            this.columns = this.buildColumns(this.pathResource);
        },
        'pathResource.definition.config.acrossApps': function () {
            this.columns = this.buildColumns(this.pathResource);
        },
        'pathState.selectedStep': function (selectedStep, prevSelectedStep) {
            if (isUntaggedNode(this.pathState.selectedStep) || isUntaggedNode(prevSelectedStep)) {
                this.columns = this.buildColumns(this.pathResource);
            }
        }
    },
    methods: {
        getNamesFromMatches (pathData, matches) {
            const names = map(matches, (match) => {
                return this.labeler.name(pathData, match);
            });

            return names.join(', ');
        },
        getNextStepApp (pathData, matches) {
            return this.labeler.getApp(pathData, matches) || { id: '--', appDisplayName: '--' };
        },
        getStepTime (visitor, selectedStep) {
            const { depth } = selectedStep;
            const pathLength = get(visitor.path, 'length', 0);
            if (depth < pathLength - 1) {
                return Math.abs(visitor.path[depth + 1].time - visitor.path[depth].time);
            }

            return -1;
        },
        getStepDate (visitor, selectedStep) {
            const path = visitor.path[selectedStep.depth];

            return path ? path.time : undefined;
        },
        getStepUnknownUrl (visitor) {
            const lastPath = last(visitor.path);

            return lastPath.unknown && lastPath.unknown.url;
        },
        buildColumns (pathResource) {
            const { predecessors, acrossApps } = pathResource.definition.config;

            return [
                {
                    prop: 'date',
                    label: 'Date',
                    sortable: true,
                    formatter: (row) =>
                        (row.date
                            ? convertToSubscriptionTimezone(this.activeTimezone, row.date).format(DATE_FORMAT.full)
                            : '(none)')
                },
                {
                    prop: 'visitorId',
                    label: 'Visitor ID',
                    sortable: true
                },
                {
                    prop: 'nextStepNames',
                    label: predecessors ? 'Previous Step' : 'Next Step',
                    sortable: true,
                    formatter: ({ nextStepNames }) => nextStepNames || '(none)'
                },
                acrossApps && {
                    prop: 'nextStepAppName',
                    label: predecessors ? 'Previous Step App' : 'Next Step App',
                    sortable: true,
                    sortBy: (row) => row.nextStepApp.displayName
                },
                {
                    prop: 'timeToNextStep',
                    label: predecessors ? 'Time from Previous Step' : 'Time to Next Step',
                    sortable: true,
                    formatter: (row) => (row.timeToNextStep === -1 ? '--' : prettyTime(row.timeToNextStep))
                },
                isUntaggedNode(this.pathState.selectedStep) && {
                    prop: 'url',
                    label: 'Untagged',
                    sortable: true
                }
            ].filter(Boolean);
        }
    }
};
</script>

<style scoped lang="scss">
.path-visitor-table__header {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;

    .pendo-icon {
        padding: 0 8px 0 0;
    }
}

.path-visitor-table__title-name {
    line-height: 2rem;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    max-width: 100%;
    font-size: 14px;
}

.path-visitor-table__empty-text {
    .empty-text__header {
        font-size: 24px;
        line-height: 24px;
        margin: 0.5rem 0;
    }
}
</style>
