<template>
    <pendo-page-content tabs>
        <pendo-tabs
            v-model="activeTab"
            :tabs="tabs"
            theme="app"
            class="settings-tabs">
            <template #userProfile>
                <user-profile />
            </template>
            <template #manageUsers>
                <manage-users />
            </template>
            <template #integrations>
                <integrations @error="displayErrorToast" />
            </template>
        </pendo-tabs>
    </pendo-page-content>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { PendoPageContent, PendoTabs, PendoNotification } from '@pendo/components';
import Integrations from '@/components/settings/Integrations';
import UserProfile from '@/components/settings/UserProfile';
import ManageUsers from '@/components/settings/ManageUsers';
import startCase from 'lodash/startCase';

export default {
    name: 'SettingsContent',
    components: {
        UserProfile,
        ManageUsers,
        Integrations,
        PendoPageContent,
        PendoTabs
    },
    directives: {
        PendoNotification
    },
    computed: {
        ...mapState({
            route: (state) => state.route
        }),
        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            activeSubHasFlag: 'subscriptions/activeSubHasFlag'
        }),
        activeTab: {
            get () {
                return this.route.name || 'userProfile';
            },
            set (tab) {
                this.$router.push({ name: tab }).catch(() => {});
            }
        },
        tabs () {
            const tabs = [
                {
                    prop: 'userProfile',
                    label: 'Profile',
                    lazy: true
                }
            ];

            if (this.isAdmin) {
                tabs.push({
                    prop: 'manageUsers',
                    label: 'Users',
                    lazy: true
                });

                if (this.activeSubHasFlag('allowIntegrationKey')) {
                    tabs.push({
                        prop: 'integrations',
                        label: 'Integrations',
                        lazy: true
                    });
                }
            }

            return tabs;
        }
    },
    methods: {
        displayErrorToast (actionCausingError) {
            PendoNotification({
                type: 'error',
                title: `Error ${startCase(actionCausingError)}`,
                message: 'Pendo encountered an error. Please refresh your page and try again.',
                duration: 7000
            });
        }
    }
};
</script>
