<template>
    <pendo-card
        :title="title"
        :style="cssProps"
        :class="{ 'show-summary': showSummary }"
        class="summary-chart-card">
        <template
            v-if="$slots.header"
            #header>
            <slot name="header" />
        </template>
        <template #filters>
            <slot name="filters" />
        </template>
        <div class="summary-chart-card__chart">
            <pendo-loading-indicator
                v-if="isFetching"
                class="summary-chart-card__chart-loading"
                :type="spinnerType"
                :spinner-props="{
                    background: '#ffffff'
                }" />
            <slot name="chart" />
        </div>
        <div
            v-if="showSummary"
            class="summary-chart-card__summary-container">
            <div class="summary-chart-card__summary">
                <pendo-loading-indicator
                    v-if="isFetching"
                    class="summary-chart-card__summary-loading"
                    :type="spinnerType" />
                <slot
                    v-else
                    name="summary" />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoLoadingIndicator } from '@pendo/components';

export default {
    name: 'SummaryChartCard',
    components: {
        PendoCard,
        PendoLoadingIndicator
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        isFetching: {
            type: Boolean,
            default: false
        },
        showSummary: {
            type: Boolean,
            default: true
        },
        spinnerType: {
            type: String,
            default: 'material'
        },
        summaryWidth: {
            type: Number,
            default: 400
        }
    },
    computed: {
        cssProps () {
            return {
                '--summary-width': `${this.summaryWidth}px`
            };
        }
    }
};
</script>

<style lang="scss">
.show-summary {
    .pendo-card__body {
        display: grid;
        grid-template-areas:
            'filter summary'
            'chart  summary';
        grid-template-columns: calc(100% - var(--summary-width)) var(--summary-width);
    }
}

.summary-chart-card {
    .pendo-loading-overlay {
        position: absolute !important; /* stylelint-disable-line */
    }

    .pendo-card__body {
        position: relative;
        padding: 0;
    }

    .pendo-card__filters {
        grid-area: filter;
        padding: 12px;
        margin-bottom: 0;

        .pendo-tag + .pendo-tag {
            margin-left: 4px;
        }

        > * {
            margin: 4px;
        }
    }

    &__chart {
        grid-area: chart;
        padding: 16px;
        position: relative;
        height: 340px;

        .pendo-highcharts-container {
            height: 100%;

            &.empty {
                &:after {
                    content: 'No Data Available';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-weight: bold;
                }

                .highcharts-yaxis-labels,
                .highcharts-yaxis-grid {
                    display: none;
                }
            }
        }
    }

    .highcharts-tooltip {
        text-align: center;
    }

    &__summary-container {
        grid-area: summary;
        grid-row: span 2;
        border-left: 1px solid $gray-lighter-5;
        padding: 16px;
        position: relative;
    }

    &__summary {
        display: grid;
        background: $gray-lighter-8;
        border: 1px solid $gray-lighter-5;
        border-radius: 3px;
        text-align: center;
        height: 100%;
        overflow: hidden;
    }

    &__label {
        color: $gray-lighter-3;
    }

    &__metric {
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.6rem;
    }

    &__chart-loading,
    &__summary-loading {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }
}
</style>
