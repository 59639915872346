<template>
    <svg
        width="53"
        height="31"
        xmlns="http://www.w3.org/2000/svg">
        <g
            fill="none"
            fill-rule="evenodd">
            <path
                stroke="#000"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M37.167 30l14.5-14.5L37.167 1M15.5 1L1 15.5 15.5 30" />
            <path d="M0 0h52.7v31H0z" />
        </g>
    </svg>
</template>
