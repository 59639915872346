<template>
    <div>
        <div
            v-if="!error"
            class="launch-preview-modal--message">
            {{ bodyText }}
        </div>
        <pendo-form-item
            v-if="!launched"
            :rules="urlValidationRule"
            prop="url">
            <pendo-input
                :value="url"
                placeholder="Enter your app's URL"
                @input="(val) => $emit('updateUrl', val)" />
        </pendo-form-item>
        <div v-if="launched && error">
            We were unable to launch the preview. Please check that Pendo is installed at the location you entered.
        </div>
        <pendo-alert
            v-if="isResourceCenter"
            type="info">
            <slot>
                At least one guide must be set to Public and be present on a page a user is actively on for the
                onboarding or guides list module to be visible.
                <a
                    href="https://adoptsupport.pendo.io/hc/en-us/articles/4405441318683"
                    target="_blank">Learn more</a>.
            </slot>
        </pendo-alert>
        <pendo-launch-preview-pane
            v-if="showAdvancedSettings && !launched"
            :selected-window-option="selectedWindowOption"
            :selected-screen-size="selectedScreenSize"
            :custom-height="customHeight"
            :custom-width="customWidth"
            :hide-same-tab="true"
            class="preview-advance-settings"
            @update="update" />
    </div>
</template>

<script>
import { PendoFormItem, PendoInput, PendoAlert, PendoLaunchPreviewPane } from '@pendo/components';
import { urlValidationRule } from '@pendo/services/Formatters';

export default {
    name: 'LaunchPreviewModalBody',
    components: {
        PendoAlert,
        PendoFormItem,
        PendoInput,
        PendoLaunchPreviewPane
    },
    props: {
        url: {
            type: String,
            default: ''
        },
        isResourceCenter: {
            type: Boolean,
            default: false
        },
        launched: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        showAdvancedSettings: {
            type: Boolean,
            default: false
        },
        bodyContent: {
            type: String,
            default: ''
        }
    },
    emits: ['updateUrl', 'updatePreviewOptions'],
    data () {
        return {
            urlValidationRule,
            selectedWindowOption: 'newTab',
            selectedScreenSize: 'mobile',
            customWidth: 350,
            customHeight: 800
        };
    },
    computed: {
        rcOrGuideText () {
            return this.isResourceCenter ? 'Resource Center' : 'guide';
        },
        bodyText () {
            if (this.bodyContent) return this.bodyContent;

            const notLaunched = `Enter the URL where you’d like to see your ${this.rcOrGuideText}. This will open a new tab and launch the ${this.rcOrGuideText} preview.`;
            const launched = `You should see a new tab with the ${this.rcOrGuideText} preview and this ${this.rcOrGuideText} overlaying the URL you specified.`;

            return this.launched ? launched : notLaunched;
        }
    },
    methods: {
        update (event) {
            const { selectedWindowOption, selectedScreenSize, customHeight, customWidth, height, width } = event;
            this.selectedWindowOption = selectedWindowOption;
            this.selectedScreenSize = selectedScreenSize;
            this.customHeight = customHeight;
            this.customWidth = customWidth;

            this.$emit('updatePreviewOptions', {
                selectedWindowOption,
                height,
                width
            });
        }
    }
};
</script>

<style scoped lang="scss">
.launch-preview-modal--message {
    margin-bottom: 16px;
}
</style>
