<template>
    <div class="schedule-status-modal">
        <div class="schedule-status-modal--row">
            <span>
                This guide will go public on the following date and time and stop showing at the expiration
            </span>
        </div>
        <div class="schedule-status-modal--row">
            <label class="schedule-status-modal--label">
                Start Date / Time
            </label>
            <span class="schedule-status-modal--datetime">
                {{ showsAfter }}
            </span>
        </div>
        <div class="schedule-status-modal--row">
            <label class="schedule-status-modal--label">
                Expiration Date / Time
            </label>
            <span class="schedule-status-modal--datetime">
                {{ expiresAfter || '---' }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ScheduledStatusModalBody',
    props: {
        showsAfter: {
            type: String,
            default: ''
        },
        expiresAfter: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss">
.schedule-status-modal {
    &--row {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &--label {
        font-weight: bold;
        font-size: 0.875em;
    }
}
</style>
