<template>
    <nav
        id="navbar"
        class="navbar">
        <div class="top-half">
            <div
                v-if="!activeIsTrainingSub || !brand.logoUrl"
                class="navbar-brand">
                <router-link
                    :to="brandLink"
                    class="brand--link"
                    data-cy="nav-logo-link">
                    <chevron />
                </router-link>
                <div class="brand--product-name">
                    adopt
                </div>
            </div>
            <div
                v-if="activeIsTrainingSub && brand.logoUrl"
                class="navbar-brand">
                <router-link
                    :to="brandLink"
                    class="brand--link"
                    data-cy="nav-whitelabel-logo-link">
                    <img
                        v-pendo-tooltip.right="
                            brand.brandName && {
                                content: `${brand.brandName}`
                            }
                        "
                        :src="brand.logoUrl"
                        :alt="`${brand.brandName} logo`"
                        class="whitelabel-brand--logo">
                </router-link>
            </div>
            <div
                v-if="showNavbarLinks"
                class="navbar-actions">
                <router-link
                    v-for="(link, index) in routerLinks"
                    :key="index"
                    :data-cy="link.cy"
                    :to="link.to"
                    class="navbar-link"
                    :class="{ 'related-route-active': checkIfRelatedRouteActive(link.basePath) }">
                    <pendo-icon
                        :type="link.icon"
                        :size="link.size"
                        :stroke-width="link.strokeWidth || 0" />
                    <div class="subtext">
                        {{ link.label }}
                        <pendo-notification-count
                            v-if="showNotificationCount(link.label)"
                            :count="suggestedPageRules.length"
                            :size="8"
                            :show-count="false"
                            class="notification" />
                    </div>
                </router-link>
            </div>
        </div>
        <div class="bottom-half">
            <router-link
                v-if="showPendoTools"
                :to="{ name: 'SubscriptionList' }"
                class="navbar-link pendo-tools-link">
                <pendo-tools-icon
                    :animation="isImpersonating"
                    :stroke="isImpersonating ? '#FF4876' : 'currentColor'" />
                <div class="subtext">
                    Pendo Tools
                </div>
            </router-link>
            <a
                v-if="usesMultiApp"
                tabindex="0"
                class="navbar-link subscriptions"
                @click="showSubscriptionPanel">
                <pendo-icon
                    label="Subscriptions"
                    type="adopt-subscriptions"
                    size="20" />
                <div class="subtext">
                    Subscriptions
                </div>
            </a>
            <router-link
                :to="{ name: 'userProfile' }"
                :class="{ 'related-route-active': checkIfRelatedRouteActive('/settings') }"
                class="navbar-link settings-link">
                <pendo-icon
                    label="Settings"
                    type="settings"
                    size="20" />
                <div class="subtext">
                    Settings
                </div>
            </router-link>
            <div
                class="navbar-link logout"
                @click="onLogout">
                <pendo-icon
                    label="Logout"
                    type="log-out"
                    size="20" />
                <div class="subtext">
                    Logout
                </div>
            </div>
        </div>
        <subscription-panel
            v-if="isShowingSubscriptionPanel"
            ref="subscriptionPanel"
            v-pendo-on-click-away="onBlur"
            :style="subscriptionPanelPositioning"
            @close-subscription-panel="() => (isShowingSubscriptionPanel = false)" />
    </nav>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { PendoIcon, PendoTooltip, PendoOnClickAway, PendoNotificationCount } from '@pendo/components';
import Chevron from '@/components/icons/Chevron';
import PendoToolsIcon from '@/components/icons/PendoToolsIcon';
import get from 'lodash/get';
import SubscriptionPanel from '@/components/SubscriptionPanel';
import { canViewResourceCenter } from '@/utils/rc-permissions';

export default {
    name: 'Navbar',
    components: {
        Chevron,
        PendoToolsIcon,
        PendoIcon,
        PendoNotificationCount,
        SubscriptionPanel
    },
    directives: {
        PendoTooltip,
        PendoOnClickAway
    },
    data () {
        return {
            isShowingSubscriptionPanel: false,
            menuBreakpoint: null,
            resizeObserver: null,
            mutationObserver: null,
            dropdownProps: {
                placement: 'bottom-end',
                dropdownMenuStyles: {
                    border: '1px solid #eaecf1'
                }
            }
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            suggestedPageRules: (state) => state.pages.suggestedPageRules,
            userPreferences: (state) => state.userSettings.userPreferences
        }),
        ...mapGetters({
            isSuperUser: 'auth/isSuper',
            isOpsUser: 'auth/isOps',
            isPendoUser: 'auth/isPendoUser',
            isImpersonating: 'auth/isImpersonating',
            isAuthenticated: 'auth/isAuthenticated',
            isEmptyStateDigitalAdoption: 'subscriptions/isEmptyStateDigitalAdoption',
            usesMultiApp: 'subscriptions/usesMultiApp',
            activeSub: 'subscriptions/active',
            activeSubHasAnalytics: 'subscriptions/activeHasAnalytics',
            activeHasResourceCenter: 'subscriptions/activeHasResourceCenter',
            activeApp: 'apps/active',
            activeIsTrainingSub: 'subscriptions/activeIsTrainingSubscription',
            listAllWithRCAccessForActiveSubscription: 'apps/listAllWithRCAccessForActiveSubscription'
        }),
        brandLink () {
            return { name: 'root', query: this.route.query };
        },
        brand () {
            const logoUrl = get(this.activeSub, 'trainingAttributes.whiteLabelSettings.logoUrl');
            const productName = get(this.activeSub, 'trainingAttributes.productName');
            const brandName = get(this.activeSub, 'trainingAttributes.whiteLabelSettings.brandName') || productName;

            return {
                logoUrl,
                brandName
            };
        },
        showNavbarLinks () {
            return !this.isEmptyStateDigitalAdoption && this.isAuthenticated;
        },
        analyticsRouteObject () {
            return {
                visible: this.activeSubHasAnalytics,
                label: 'Analytics',
                icon: 'adopt-analytics',
                size: 24,
                basePath: '/analytics',
                to: {
                    name: 'analyticsPortfolio',
                    query: this.route.query
                }
            };
        },
        pathsRouteObject () {
            return {
                visible: this.activeSubHasAnalytics,
                label: 'Paths',
                size: 20,
                icon: 'adopt-paths',
                basePath: '/paths',
                to: {
                    name: 'pathsDashboard',
                    query: this.route.query
                }
            };
        },
        workflowsRouteObject () {
            return {
                visible: this.activeSubHasAnalytics,
                label: 'Workflows',
                icon: 'adopt-workflows',
                cy: 'nav-workflows-link',
                size: 24,
                basePath: '/workflows',
                to: {
                    name: 'workflowsList',
                    query: this.route.query
                }
            };
        },
        segmentListRouteObject () {
            return {
                visible: true,
                label: 'Segments',
                size: 20,
                icon: 'adopt-segments',
                strokeWidth: 1,
                to: {
                    name: 'segmentList',
                    query: this.route.query
                }
            };
        },
        canViewRC () {
            if (this.activeIsTrainingSub) {
                return canViewResourceCenter([this.activeApp]);
            }

            return canViewResourceCenter([...this.listAllWithRCAccessForActiveSubscription]);
        },
        resourceCenterRouteObject () {
            return {
                visible: this.activeHasResourceCenter && this.canViewRC,
                label: 'Resource Center',
                size: 20,
                icon: 'adopt-resource-center',
                to: {
                    name: 'resourceCenter',
                    query: this.route.query
                }
            };
        },
        guidesRouteObject () {
            return {
                visible: true,
                label: 'Guides',
                size: 20,
                icon: 'adopt-guides',
                to: {
                    name: 'guideList',
                    query: this.route.query
                }
            };
        },
        showSuggestedPagesTableNotifications () {
            return get(this.userPreferences, 'suggestedPagesTable.subSettings.notifications.value', false);
        },
        routerLinks () {
            const links = [
                this.analyticsRouteObject,
                this.pathsRouteObject,
                this.workflowsRouteObject,
                this.segmentListRouteObject,
                this.resourceCenterRouteObject,
                this.guidesRouteObject
            ];

            return links.filter((link) => link.visible);
        },
        subscriptionPanelPositioning () {
            if (this.isShowingSubscriptionPanel) {
                const navbarLeftOffset = document.getElementById('navbar').getBoundingClientRect().width;
                const subscriptionsIconHeight = document
                    .getElementsByClassName('navbar-link subscriptions')[0]
                    .getBoundingClientRect().bottom;
                const windowHeight = window.innerHeight;
                const bottomMargin = windowHeight - subscriptionsIconHeight - 30;
                const style = { bottom: `${bottomMargin}px`, left: `${navbarLeftOffset}px` };

                return style;
            }

            return {};
        },
        showPendoTools () {
            return (this.isSuperUser || this.isOpsUser) && this.isPendoUser;
        },
        isPendoToolsActive () {
            return this.$route.path.includes('/pendozer/subs');
        }
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        }),
        showNotificationCount (linkLabel) {
            return (
                linkLabel === 'Analytics' &&
                this.suggestedPageRules.length > 0 &&
                this.showSuggestedPagesTableNotifications
            );
        },
        showSubscriptionPanel (event) {
            event.preventDefault();
            this.isShowingSubscriptionPanel = !this.isShowingSubscriptionPanel;
            this.$nextTick(() => {
                if (!this.$refs.subscriptionPanel) return;
                this.$refs.subscriptionPanel.$el.focus();
            });
        },
        onBlur () {
            if (!document.activeElement.classList.contains('subscription-panel')) {
                this.isShowingSubscriptionPanel = false;
            }
        },
        // analytics routing in particular is split across several views that are not necessarily parent/child related
        // we need a more generic way than what vue-router provides to determine if routes are related or not
        checkIfRelatedRouteActive (basePath) {
            if (!basePath) return false;

            // Temporary hack to highlight paths specifically, even though its technically part
            // of analytics right now
            if (basePath.includes('analytics') && this.$route.fullPath.includes('paths')) {
                return false;
            }

            return this.$route.fullPath.indexOf(basePath) === 0;
        },
        async onLogout () {
            try {
                await this.logout();
                this.$router.push({ name: 'login', query: this.route.query }).catch(() => {});
            } catch (err) {
                window.location.reload(true);
            }
        },
        handleMenuItemSelect ({ to }) {
            return this.$router.push(to);
        }
    }
};
</script>

<style lang="scss" scoped>
.navbar {
    z-index: 2;
    background-color: $gray-lighter-7;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 82px;
    box-shadow: $navbar-box-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid $gray-lighter-5;
    overflow-x: auto;

    .navbar-brand {
        max-height: 50px;
        margin: 12px;
    }

    .navbar-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $gray-lighter-3;
        font-size: 12px;
        padding: 12px 0px;
        font-weight: 400;
        cursor: pointer;

        &.router-link-active,
        &.related-route-active {
            color: $gray-primary;
            font-weight: 600;
        }

        &:hover {
            color: $gray-primary;
            font-weight: 600;
            background-color: $gray-lighter-5;
        }
    }

    .subtext {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        text-align: center;
        line-height: 14px;
        font-size: 12px;
    }

    .notification {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        margin-left: 3px;
    }

    &-brand {
        margin-top: 10px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .brand--logo {
            max-width: 148px;
            max-height: 28px;
            vertical-align: middle;
        }

        .brand--product-name {
            font-size: 16px;
            font-weight: bold;
            line-height: 0.9rem;
            color: $gray-primary;
            cursor: default;
            margin-top: 3px;
            text-align: center;
        }

        .whitelabel-brand--logo {
            max-width: 48px;
            max-height: 50px;
            vertical-align: middle;
        }
    }
}
</style>
