<template>
    <div class="resource-center-list">
        <global-filters
            :show-segment-picker="false"
            :show-app-picker="false"
            :show-date-picker="false">
            <template #right>
                <search
                    v-model="searchInput"
                    placeholder="Search" />
            </template>
        </global-filters>
        <pendo-table
            :auto-height="true"
            :loading="loading"
            :data="tableData"
            :filters="filters"
            :columns="columns"
            row-key="id"
            @link-click="goToRcDraft">
            <template #app="{ row }">
                <pendo-app-display :apps="row.app" />
            </template>
            <template #isProductionOn="{ row }">
                <div class="production_status_cell">
                    <pendo-icon
                        type="circle"
                        size="8"
                        :fill="row.isProductionOn ? '#00aa62' : '#ffffff'"
                        :stroke="row.isProductionOn ? '#00aa62' : '#DADCE5'" />
                    <span>
                        {{ row.isProductionOn ? 'On' : 'Off' }}
                    </span>
                </div>
            </template>
        </pendo-table>
    </div>
</template>

<script>
import { PendoTable, PendoAppDisplay, PendoIcon } from '@pendo/components';
import GlobalFilters from '@/components/filters/GlobalFilters';
import Search from '@/components/Search';
import moment from 'moment-timezone';

export default {
    name: 'ResourceCenterMultiAppList',
    components: {
        PendoTable,
        PendoAppDisplay,
        PendoIcon,
        GlobalFilters,
        Search
    },
    props: {
        appOptions: {
            type: Object,
            required: true
        },
        furthestPromotedRcPerApp: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['selectExistingResourceCenter'],
    data () {
        return {
            columns: [
                {
                    prop: 'name',
                    type: 'link',
                    label: 'Name'
                },
                {
                    prop: 'app',
                    label: 'Application'
                },
                {
                    prop: 'lastUpdatedAt',
                    label: 'Last Updated',
                    formatter: (row) => moment(row.lastUpdatedAt).format('MMM DD, Y h:mm:ss A')
                },
                {
                    prop: 'state',
                    label: 'State',
                    formatter: (row) => {
                        if (row.state === 'public') return 'Public';

                        return 'Draft';
                    }
                },
                {
                    prop: 'isProductionOn',
                    label: 'Production Status'
                }
            ],
            searchInput: ''
        };
    },
    computed: {
        tableData () {
            return this.furthestPromotedRcPerApp.map((rc) => {
                const { id, appId, lastUpdatedAt, name, attributes, state } = rc.homeView;
                const rcAppInfo = this.appOptions[appId];

                return {
                    id,
                    appId,
                    app: rcAppInfo,
                    lastUpdatedAt,
                    name,
                    originId: attributes.resourceCenter.originId,
                    state,
                    isProductionOn: state === 'public' && !rc.isDisabled
                };
            });
        },
        filters () {
            return [
                {
                    prop: ['name', 'app.displayName'],
                    type: 'includes',
                    value: this.searchInput
                }
            ];
        }
    },
    methods: {
        goToRcDraft ({ row }) {
            this.$emit('selectExistingResourceCenter', row);
        }
    }
};
</script>
<style scoped lang="scss">
.production_status_cell {
    display: flex;
    align-items: center;

    .pendo-icon {
        margin-right: 8px;
    }
}
</style>
