import { operators as o } from '@pendo/aggregations';
import { PRODUCT_AREAS, SUB_PRODUCT_AREAS } from '@/stateless-components/utils/aggregations';

export default {
    name: 'SegmentEligibleVisitorCount',
    productArea: PRODUCT_AREAS.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS.SEGMENTS,
    responseFormat: 'number',
    build: ({ pipeline }) => {
        return o.pipeline(
            o.sources.visitors(),
            o.segment(pipeline),
            o.reduce({
                total: o.count(null)
            })
        );
    }
};
