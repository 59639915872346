<template>
    <div
        class="super-subs"
        :class="{ 'is-impersonating': isImpersonating }">
        <page-header title="Subscriptions" />
        <pendo-page-content>
            <div>
                <pendo-filter-bar class="filter-bar">
                    <div class="filter-bar__filters">
                        <pendo-tag-group>
                            <template v-for="filter in filters">
                                <pendo-tag
                                    v-if="filter.type === 'tag'"
                                    :key="filter.key"
                                    :class="{
                                        'is-active': appliedFilters.includes(filter.key),
                                        'disabled': filter.disabled
                                    }"
                                    :data-cy="filter.label"
                                    :label="filter.label"
                                    type="filter"
                                    @click.native="toggleAppliedFilter(filter)" />
                            </template>
                        </pendo-tag-group>
                    </div>
                    <search
                        v-model="filters[0].value"
                        class="filter-bar__search"
                        placeholder="Search Subscriptions" />
                </pendo-filter-bar>
                <pendo-table
                    ref="table"
                    v-bind="heightProps"
                    :data="subsList"
                    row-key="name"
                    title="Subscription List"
                    resizable
                    :default-sort="{ prop: 'name', order: 'asc' }"
                    :filters="filters"
                    :status="status"
                    :loading="loading"
                    :columns="columns">
                    <template #status>
                        <pendo-status-overlay :state="status" />
                    </template>
                    <template #name="{ row }">
                        <a
                            v-if="isSuperUser"
                            rel="noopener"
                            :href="`${engageURL()}/super/subs/${row.id}`"
                            target="_blank">
                            {{ row.name }}
                        </a>
                        <span v-else>{{ row.name }}</span>
                    </template>
                    <template #partnerSubName="{ row }">
                        <a
                            v-if="isSuperUser && row.partnerSubName !== '--'"
                            rel="noopener"
                            :href="`${engageURL()}/super/subs/${row.partnerSubId}`"
                            target="_blank">
                            {{ row.partnerSubName }}
                        </a>
                        <span v-else-if="isSuperUser && row.partnerSubName === '--'">{{ row.partnerSubName }}</span>
                        <span v-else>{{ row.name }}</span>
                    </template>
                    <template #actions="{ row }">
                        <pendo-button
                            v-if="isOpsUser && showAction(row)"
                            size="mini"
                            theme="app"
                            class="impersonate-button"
                            v-bind="action(row)"
                            @click="handleRowAction(row, action(row).action)" />
                    </template>
                    <template #headerActions>
                        <div
                            v-if="lastUpdatedAt"
                            class="info-text">
                            Last updated at {{ lastUpdatedAt }}
                        </div>
                    </template>
                </pendo-table>
            </div>
        </pendo-page-content>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import Search from '@/components/Search';
import PageHeader from '@/components/PageHeader';
import {
    PendoFilterBar,
    PendoTable,
    PendoTag,
    PendoButton,
    PendoStatusOverlay,
    PendoPageContent,
    PendoTagGroup,
    PendoNotification
} from '@pendo/components';
import { fetchFullSubscriptionApplications } from '@/utils/apps';
import { LOADING_STATES } from '@pendo/services/Constants';
import moment from 'moment-timezone';
import { fetchAllSubscriptions, impersonateSub } from '@/utils/subscriptions';
import get from 'lodash/get';
import { engageURL } from '@/stateless-components/utils/environment';

export default {
    name: 'SuperSubscriptionList',
    components: {
        PendoFilterBar,
        PendoButton,
        PendoTable,
        PendoTag,
        PendoStatusOverlay,
        PageHeader,
        PendoPageContent,
        PendoTagGroup,
        Search
    },
    data () {
        return {
            searchInput: '',
            isUpdatingSubById: null,
            appliedFilters: [],
            filters: [
                {
                    type: 'search',
                    prop: ['name', 'displayName', 'id'],
                    value: ''
                },
                {
                    type: 'tag',
                    key: 'extensionSub',
                    label: 'Extension Subs',
                    filterFn: (sub) => {
                        if (this.appliedFilters.includes('extensionSub')) {
                            return this.isExtension(sub);
                        }

                        return sub;
                    }
                },
                {
                    type: 'tag',
                    key: 'trainingSub',
                    label: 'Training Subs',
                    filterFn: (sub) => {
                        if (this.appliedFilters.includes('trainingSub')) {
                            return this.isTraining(sub);
                        }

                        return sub;
                    }
                },
                {
                    type: 'tag',
                    key: 'adoptOnCore',
                    label: 'Adopt On Core',
                    filterFn: (sub) => {
                        if (this.appliedFilters.includes('adoptOnCore')) {
                            return this.isAdoptOnCore(sub);
                        }

                        return sub;
                    }
                },
                {
                    type: 'tag',
                    key: 'digitalAdoptionOnly',
                    label: 'Extension Subs On Adopt',
                    filterFn: (sub) => {
                        if (this.deletedFilterOn) {
                            return true;
                        }

                        if (this.appliedFilters.includes('digitalAdoptionOnly')) {
                            return this.isDigitalAdoptionOnly(sub);
                        }

                        return sub;
                    }
                }
            ],
            columns: [
                {
                    prop: 'name',
                    label: 'Subscription',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'displayName',
                    label: 'Name',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'timeZone',
                    label: 'Time Zone',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'eventCount',
                    label: 'Total Events',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'id',
                    label: 'Subscription Id',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'partnerSubName',
                    label: 'Partner Name',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'subType',
                    label: 'Subscription Type',
                    sortable: true,
                    visible: true
                },
                {
                    type: 'actions',
                    width: 140,
                    visible: true
                }
            ],
            status: LOADING_STATES.RESOLVED,
            subsList: [],
            lastUpdatedAt: null,
            subIdNameMap: {},
            engageURL
        };
    },
    computed: {
        ...mapState({
            activeSubId: (state) => state.subscriptions.activeId,
            subMap: (state) => state.subscriptions.map,
            user: (state) => state.auth.user
        }),
        ...mapGetters({
            isOpsUser: 'auth/isOps',
            isSuperUser: 'auth/isSuper',
            isImpersonating: 'auth/isImpersonating',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        loading () {
            return this.status === LOADING_STATES.LOADING;
        },
        heightProps () {
            // Normal pagination/scrolling behavior for filled table, but fixed height for status messages
            return this.status === LOADING_STATES.RESOLVED ? { autoHeight: true } : { height: 270 };
        }
    },
    async created () {
        this.status = LOADING_STATES.LOADING;
        try {
            const { subscriptionList, lastUpdatedAt } = await fetchAllSubscriptions();

            subscriptionList.forEach((sub) => {
                this.subIdNameMap[sub.id] = sub.name;
            });

            this.subsList = subscriptionList.filter(this.filterByAdoptSubs).map((sub) => {
                const partnerSubName = this.subIdNameMap[sub.partnerSubId] || '--';
                const subType =
                    sub.productLines.find((productLine) => productLine.purchased).type === 'adopt'
                        ? 'Extension'
                        : 'Adopt End User';

                return { ...sub, partnerSubName, subType };
            });

            this.lastUpdatedAt = moment(lastUpdatedAt).format('MMM D, YYYY h:mma z');
            this.status = LOADING_STATES.RESOLVED;
        } catch (error) {
            this.status = LOADING_STATES.REJECTED;
        }
    },
    methods: {
        ...mapActions({
            switchSubscription: 'subscriptions/updateActive'
        }),
        hasSub (sub) {
            const subListHasSub = Object.keys(this.subMap).includes(sub.id);
            if (this.isImpersonating) return subListHasSub && this.activeSubId !== sub.id;

            return subListHasSub;
        },
        filterByAdoptSubs (sub) {
            return sub && get(sub, 'productLines', false) && (this.isExtension(sub) || this.isTraining(sub));
        },
        isExtension (sub) {
            const adoptProductLine = sub.productLines.find((product) => product.type === 'adopt');

            return get(adoptProductLine, 'purchased', false);
        },
        isTraining (sub) {
            const endUserProductLine = sub.productLines.find((product) => product.type === 'adoptEndUser');

            return get(endUserProductLine, 'purchased', false);
        },
        isDigitalAdoptionOnly (sub) {
            const adoptProductLine = sub.productLines.find((product) => product.type === 'adopt');

            return adoptProductLine.purchased && adoptProductLine.metadata.userInterface === 'adopt';
        },
        isAdoptOnCore (sub) {
            const endUserProductLine = sub.productLines.find((product) => product.type === 'adoptEndUser');

            return endUserProductLine.purchased && endUserProductLine.metadata.userInterface === 'engage';
        },
        showAction (sub) {
            return !sub.markedForDeletionAt && (this.isSuperUser || this.isMemberOfSubscription(sub.id));
        },
        action (sub) {
            if (sub.id === this.activeSubId.toString()) {
                return {
                    label: 'Current Sub',
                    disabled: true
                };
            }
            if (this.hasSub(sub)) {
                return {
                    label: 'Switch Sub',
                    action: 'switchSub'
                };
            }

            return {
                label: 'Impersonate',
                action: 'impersonate'
            };
        },
        handleRowAction (sub, action) {
            if (!action) {
                return;
            }

            return this[action](sub);
        },
        async impersonate (subscription) {
            if (subscription.subType === 'Adopt End User') {
                await this.impersonateAEU(subscription);

                return;
            }

            if (subscription.subType === 'Extension') {
                await this.impersonateExtensionSub(subscription);
            }
        },
        async impersonateAEU (subscription) {
            const subHasValidApp = await fetchFullSubscriptionApplications(subscription.id).then((applications) => {
                return applications.some((application) => application.disabledAt === 0 && application.id !== -323232);
            });
            if (subHasValidApp) {
                await impersonateSub(subscription.id);
            } else {
                PendoNotification({
                    type: 'error',
                    title: 'Unable to impersonate sub',
                    message: 'You cannot impersonate an AEU sub with no apps',
                    duration: 5000
                });
            }
        },
        async impersonateExtensionSub (subscription) {
            const adoptProductLine = subscription.productLines.find((product) => product.type === 'adopt');
            const hasAdoptUi = get(adoptProductLine, 'metadata.userInterface', '') === 'adopt';
            if (hasAdoptUi) {
                await impersonateSub(subscription.id);
            } else {
                PendoNotification({
                    type: 'error',
                    title: 'Unable to impersonate sub',
                    message: 'You cannot impersonate an Extension sub with the Engage user interface',
                    duration: 5000
                });
            }
        },
        async switchSub (sub) {
            await this.switchSubscription({ subscriptionId: sub.id });
        },
        async toggleAppliedFilter (filter) {
            if (filter.disabled) return;
            const index = this.appliedFilters.indexOf(filter.key);
            if (index === -1) {
                this.appliedFilters.push(filter.key);
            } else {
                this.appliedFilters.splice(index, 1);
            }
            this.$refs.table.updateActiveTableData();
        }
    }
};
</script>
<style lang="scss" scoped>
$info-text-color: #5f6368;

.filter-bar {
    .pendo-tag {
        cursor: pointer;
        user-select: none;

        &.disabled {
            cursor: not-allowed;
            background-color: rgba(186, 188, 197, 0.15);
            color: $gray-lighter-4;
        }

        &.is-active {
            background-color: $teal-primary;
            border-color: $teal-primary;
            color: $white;
        }
    }
}

.filter-bar__filters {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
    align-items: center;

    .filter-bar__label {
        font-weight: bold;
    }
}

.filter-bar__search {
    margin-left: auto;
}

.impersonate-button {
    font-size: 12px;
}

.info-text {
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    padding-left: 8px;
    margin-left: auto;
    color: $info-text-color;
}
</style>
