<template>
    <pendo-modal
        :title="title"
        height="auto"
        :visible="visible"
        @close="emitClose">
        <template #body>
            <span v-if="!impactedItems.length">
                You won't be able to recover it later. Are you sure you want to delete this segment?
            </span>
            <div v-if="impactedItems.length">
                <span> We have detected the following {{ impactedKind }}(s) rely on this segment. </span>
                <ul class="item-delete-warning--item-list">
                    <li
                        v-for="item in impactedItems"
                        :key="item.id">
                        {{ item.name }}
                    </li>
                </ul>
                <p>
                    If you wish to delete this segment, please remove the segment from the listed
                    {{ impactedKind }}(s) first.
                </p>
            </div>
        </template>
        <template #footer>
            <pendo-button
                type="secondary"
                theme="app"
                label="Cancel"
                @click.stop="emitClose" />
            <pendo-button
                :label="`Delete segment`"
                :class="`item-delete--segment`"
                :disabled="!!impactedItems.length"
                :loading="deletingSegment"
                type="danger"
                theme="app"
                @click.stop="handleDelete" />
        </template>
    </pendo-modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSegmentsUsingSegment } from '@/utils/segments';
import { getGuidesUsingSegment } from '@/utils/guides';
import { PendoModal, PendoButton } from '@pendo/components';

export default {
    name: 'DeleteSegmentModal',
    components: {
        PendoModal,
        PendoButton
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        segmentId: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            deletingSegment: false
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            guideList: 'guides/list'
        }),
        guidesUsingSegment () {
            return getGuidesUsingSegment(this.segmentId, this.guideList);
        },
        segmentsUsingSegment () {
            return getSegmentsUsingSegment(this.segmentId, this.segmentsListByGroup.customList);
        },
        impactedItems () {
            let impactedItems = [];

            if (this.guidesUsingSegment.length) {
                impactedItems = this.guidesUsingSegment;
            } else if (this.segmentsUsingSegment.length) {
                impactedItems = this.segmentsUsingSegment;
            }

            return impactedItems;
        },
        impactedKind () {
            if (this.guidesUsingSegment?.length) {
                return 'guide';
            }
            if (this.segmentsUsingSegment?.length) {
                return 'segment';
            }

            return '';
        },
        title () {
            let title = 'Delete Segment?';

            if (this.guidesUsingSegment.length) {
                title = 'Guide Dependency Detected';
            } else if (this.segmentsUsingSegment.length) {
                title = 'Segment Dependency Detected';
            }

            return title;
        }
    },
    watch: {
        visible (val) {
            if (val) {
                if (this.$parent.$refs?.segmentChooser) {
                    this.$parent.$refs.segmentChooser.toggleMenu();
                }

                this.fetchSegments();
                this.fetchGuides();
            }
        }
    },
    methods: {
        ...mapActions({
            fetchSegments: 'filters/fetchSegments',
            fetchGuides: 'guides/fetch',
            deleteSegment: 'filters/deleteSegment',
            updateActiveSegmentId: 'filters/updateActiveSegmentId'
        }),
        async handleDelete () {
            this.deletingSegment = true;
            await this.deleteSegment({ id: this.segmentId });
            // If the active segment is deleted, reset it to everyone
            if (this.activeSegmentId === this.segmentId) {
                this.updateActiveSegmentId({ id: 'everyone' });
            }
            this.deletingSegment = false;
            this.emitClose();
        },
        emitClose () {
            this.$emit('close');
        }
    }
};
</script>
