<template>
    <div
        class="VueCarousel-slide"
        tabindex="-1"
        :aria-hidden="!isActive"
        role="tabpanel"
        :class="{
            'VueCarousel-slide-active': isActive,
            'VueCarousel-slide-center': isCenter,
            'VueCarousel-slide-adjustableHeight': isAdjustableHeight,
            'VueCarousel-slide-alignVertically': isVerticallyAligned
        }">
        <slot />
    </div>
</template>

<script>
import get from 'lodash/get';

export default {
    name: 'Slide',
    inject: ['carousel'],
    emits: ['slideclick', 'slide-click'],
    computed: {
        activeSlides () {
            const { currentPage, breakpointSlidesPerPage, $children } = this.carousel;
            const activeSlides = [];
            const children = $children
                .filter((child) => {
                    const className = get(child, '$el.className', '');

                    return className.indexOf('VueCarousel-slide') >= 0;
                })
                .map((child) => child._uid);

            let i = 0;
            while (i < breakpointSlidesPerPage) {
                const child = children[currentPage * breakpointSlidesPerPage + i];
                activeSlides.push(child);
                i++;
            }

            return activeSlides;
        },
        /**
         * `isActive` describes whether a slide is visible
         * @return {Boolean}
         */
        isActive () {
            return this.activeSlides.indexOf(this._uid) >= 0;
        },
        /**
         * `isCenter` describes whether a slide is in the center of all visible slides
         * if perPage is an even number, we quit
         * @return {Boolean}
         */
        isCenter () {
            const { breakpointSlidesPerPage } = this.carousel;
            if (breakpointSlidesPerPage % 2 === 0 || !this.isActive) {
                return false;
            }

            return this.activeSlides.indexOf(this._uid) === Math.floor(breakpointSlidesPerPage / 2);
        },
        /**
         * `isAdjustableHeight` describes if the carousel adjusts its height to the active slide(s)
         * @return {Boolean}
         */
        isAdjustableHeight () {
            const { adjustableHeight } = this.carousel;

            return adjustableHeight;
        },
        /**
         * `isVerticallyAligned` describes if the carousel vertically centers the slide(s)
         * @return {Boolean}
         */
        isVerticallyAligned () {
            const { verticalAlign } = this.carousel;

            return verticalAlign;
        }
    },
    mounted () {
        if (!this.$isServer) {
            this.$el.addEventListener('dragstart', (event) => event.preventDefault());
        }

        this.$el.addEventListener(this.carousel.isTouch ? 'touchend' : 'mouseup', this.onTouchEnd);
    },
    methods: {
        onTouchEnd (event) {
            /**
             * @event slideclick
             * @event slide-click
             * @type {Object}
             */
            const eventPosX =
                this.carousel.isTouch && event.changedTouches && event.changedTouches.length > 0
                    ? event.changedTouches[0].clientX
                    : event.clientX;
            const deltaX = this.carousel.dragStartX - eventPosX;

            if (this.carousel.minSwipeDistance === 0 || Math.abs(deltaX) < this.carousel.minSwipeDistance) {
                this.$emit('slideclick', Object.assign({}, event.currentTarget.dataset));
                this.$emit('slide-click', Object.assign({}, event.currentTarget.dataset));
            }
        }
    }
};
</script>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.VueCarousel-slide {
    flex-basis: inherit;
    flex-grow: 0;
    flex-shrink: 0;
    user-select: none;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
}

.VueCarousel-slide-adjustableHeight {
    display: table;
    flex-basis: auto;
    width: 100%;
}

.VueCarousel-slide-alignVertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
</style>
