<template>
    <div class="segment-flag-input">
        <pendo-form
            :model="{
                value
            }">
            <pendo-form-item
                id="segmentFlagName"
                name="segmentFlagName"
                prop="value"
                :rules="rules.segmentFlagName">
                <pendo-input
                    id="segmentFlagName"
                    name="segmentFlagName"
                    :disabled="disabled"
                    :value="value"
                    @input="onInput" />
            </pendo-form-item>
        </pendo-form>
    </div>
</template>

<script>
import { PendoInput, PendoForm, PendoFormItem } from '@pendo/components';

export default {
    name: 'SegmentFlagInput',
    components: {
        PendoInput,
        PendoForm,
        PendoFormItem
    },
    props: {
        value: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            rules: {
                segmentFlagName: [
                    {
                        validator: this.validateSegmentFlagInput,
                        trigger: ['blur', 'change']
                    }
                ]
            }
        };
    },
    methods: {
        validateSegmentFlagInput (rule, val, cb) {
            const alphanumericSpaceRegex = /^[\p{L}0-9]*$/u;
            if (alphanumericSpaceRegex.test(val)) {
                this.$emit('invalid-segment-flag', false);
                cb();
            } else {
                this.$emit('invalid-segment-flag', true);
                cb(new Error('Only letters or numbers without spaces are allowed'));
            }
        },
        onInput (val) {
            this.$emit('input', val);
        }
    }
};
</script>
