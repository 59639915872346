/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import { identifiedState } from '@/utils/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    parseAppIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { requestAppEngagementVisitorsCSV, buildGroupValueGrouping } from '@/aggregations/utils';
const { operators: o } = Agg2;

export function getAppEngagement ({
    appId,
    segmentId,
    timeSeries,
    lowEngagementCap,
    medEngagementCap,
    metadataField,
    signal
}) {
    let spec;
    if (metadataField) {
        spec = buildAppEngagementByMetadataSpec({
            appId,
            segmentId,
            timeSeries,
            lowEngagementCap,
            medEngagementCap,
            metadataField
        });
    } else spec = buildAppEngagementSpec({ appId, segmentId, timeSeries, lowEngagementCap, medEngagementCap });

    return request(spec, { signal });
}

export function getAppEngagementVisitors ({ appId, timeSeries, segmentId, filter, metadataFields, limit, signal }) {
    const spec = buildAppEngagementVisitorsSpec({
        appId,
        timeSeries,
        segmentId,
        filter,
        metadataFields,
        limit
    });

    return request(spec, { signal });
}

export function getAppEngagementVisitorsCSV ({ appId, timeSeries, segmentId, filter, metadataFields, columns }) {
    const spec = buildAppEngagementVisitorsSpec({ appId, timeSeries, segmentId, filter, metadataFields });

    return requestAppEngagementVisitorsCSV(spec, columns);
}

export function buildAppEngagementSpec ({ appId, segmentId, timeSeries, lowEngagementCap, medEngagementCap }) {
    return o.aggregation(
        {
            name: 'license-engagement-adopt',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.LICENSE_UTILIZATION
        },
        o.pipeline(
            o.sources.apps({ appId }),
            o.evalExpression({ appId: 'id' }),
            o.merge(
                ['appId'],
                o.mappings({
                    appId: 'appId',
                    groupValue: 'groupValue',
                    highEngagementVisitors: 'highEngagementVisitors',
                    medEngagementVisitors: 'medEngagementVisitors',
                    lowEngagementVisitors: 'lowEngagementVisitors',
                    lowEngagementSummedMinutes: 'lowEngagementSummedMinutes',
                    medEngagementSummedMinutes: 'medEngagementSummedMinutes',
                    highEngagementSummedMinutes: 'highEngagementSummedMinutes',
                    summedMinutes: 'summedMinutes',
                    visitors: 'visitors'
                }),
                o.pipeline(
                    ...buildEngagementVisitorsPipeline({
                        appId,
                        segmentId,
                        timeSeries,
                        lowEngagementCap,
                        medEngagementCap
                    })
                )
            ),
            ...buildEngagementPercentPipeline()
        )
    );
}

export function buildAppEngagementByMetadataSpec ({
    appId,
    segmentId,
    timeSeries,
    lowEngagementCap,
    medEngagementCap,
    metadataField
}) {
    return o.aggregation(
        {
            name: 'license-engagement-by-metadata-adopt',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.LICENSE_UTILIZATION
        },
        o.pipeline(
            ...buildEngagementVisitorsPipeline({
                appId,
                segmentId,
                timeSeries,
                lowEngagementCap,
                medEngagementCap,
                metadataField
            }),
            ...buildEngagementPercentPipeline()
        )
    );
}

export function buildAppEngagementVisitorsSpec ({ appId, timeSeries, segmentId, filter, metadataFields, limit }) {
    return o.aggregation(
        {
            name: 'license-engagement-visitors-with-filter-adopt',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.LICENSE_UTILIZATION
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group(
                ['visitorId'],
                o.groupField('summedMinutes', o.sum('numMinutes')),
                o.groupField('daysActive', o.count('day'))
            ),
            o.evalExpression({
                avgTime: 'summedMinutes / daysActive'
            }),
            o.bulkExpand('visitor'),
            o.evalExpression({
                lastVisit: `visitor.auto_${parseAppIdForAggregation(appId)}.lastvisit`
            }),
            ...buildMetadataPipeline(metadataFields),
            filter && buildMetadataFilter(filter),
            limit && o.limit(limit)
        )
    );
}

function buildMetadataFilter (filter) {
    if (filter.value === '(Not Specified)') {
        return o.filter(`isNull(visitor.${filter.group}.${filter.field})`);
    }

    return o.filter(`${filter.field} == "${filter.value.toString()}"`);
}

function buildMetadataPipeline (fields) {
    const pipeline = [];
    for (const metaDataField of fields) {
        const { group, field } = metaDataField;
        const visitorMetadataString = `visitor.${group}.${field}`;

        const fieldDefinition = {};
        fieldDefinition[`${metaDataField.field}`] = visitorMetadataString;

        const fieldNotSpecified = {};
        fieldNotSpecified[
            `${metaDataField.field}`
        ] = `if(isNull(${metaDataField.field}), "(Not Specified)", ${metaDataField.field})`;

        const fieldNumber = {};
        fieldNumber[
            `${metaDataField.field}`
        ] = `if(isNumber(${metaDataField.field}), toString(${metaDataField.field}), ${metaDataField.field})`;

        const fieldTrueFalse = {};
        fieldTrueFalse[
            `${metaDataField.field}`
        ] = `if(${metaDataField.field} == true, "true", if(${metaDataField.field} == false, "false", ${metaDataField.field}))`;

        pipeline.push(
            o.evalExpression(fieldDefinition),
            o.evalExpression(fieldNotSpecified),
            o.evalExpression(fieldNumber),
            o.evalExpression(fieldTrueFalse)
        );
    }

    return pipeline;
}

function buildEngagementVisitorsPipeline ({
    appId,
    segmentId,
    timeSeries,
    lowEngagementCap,
    medEngagementCap,
    metadataField
}) {
    let visitorMetadataString;
    if (metadataField) {
        const { group, field } = metadataField;
        visitorMetadataString = `visitor.${group}.${field}`;
    }

    return [
        o.sources.events({
            appId,
            timeSeries
        }),
        o.identified(identifiedState(segmentId)),
        o.segment(parseSegmentIdForAggregation(segmentId)),
        o.group(
            ['appId', 'visitorId'],
            o.groupField('summedMinutes', o.sum('numMinutes')),
            o.groupField('numDays', o.count('day'))
        ),
        ...(metadataField ? buildGroupValuePipeline(visitorMetadataString) : []),
        o.fork(
            o.pipeline(
                o.filter(`numDays >= ${medEngagementCap}`),
                ...buildGroupValueGrouping({
                    columns: ['appId', ...(metadataField ? ['groupValue'] : [])],
                    fields: [
                        {
                            highEngagementVisitors: {
                                count: 'visitorId'
                            }
                        },
                        {
                            highEngagementSummedMinutes: {
                                sum: 'summedMinutes'
                            }
                        }
                    ],
                    useLimit: !!metadataField
                })
            ),
            o.pipeline(
                o.filter(`numDays > ${lowEngagementCap} && numDays < ${medEngagementCap}`),
                ...buildGroupValueGrouping({
                    columns: ['appId', ...(metadataField ? ['groupValue'] : [])],
                    fields: [
                        {
                            medEngagementVisitors: {
                                count: 'visitorId'
                            }
                        },
                        {
                            medEngagementSummedMinutes: {
                                sum: 'summedMinutes'
                            }
                        }
                    ],
                    useLimit: !!metadataField
                })
            ),
            o.pipeline(
                o.filter(`numDays <= ${lowEngagementCap}`),
                ...buildGroupValueGrouping({
                    columns: ['appId', ...(metadataField ? ['groupValue'] : [])],
                    fields: [
                        {
                            lowEngagementVisitors: {
                                count: 'visitorId'
                            }
                        },
                        {
                            lowEngagementSummedMinutes: {
                                sum: 'summedMinutes'
                            }
                        }
                    ],
                    useLimit: !!metadataField
                })
            )
        ),
        o.join(['appId', ...(metadataField ? ['groupValue'] : [])])
    ];
}

function buildGroupValuePipeline (metadataString) {
    return [
        o.bulkExpand('visitor'),
        o.evalExpression({
            groupValue: metadataString
        }),
        o.evalExpression({
            groupValue: 'if(isNull(groupValue), "(Not Specified)", groupValue)'
        }),
        o.evalExpression({
            groupValue: 'if(isNumber(groupValue), toString(groupValue), groupValue)'
        }),
        o.evalExpression({
            groupValue: 'if(groupValue == true, "true", if(groupValue == false, "false", groupValue))'
        })
    ];
}

function buildEngagementPercentPipeline () {
    return [
        o.evalExpression({
            visitors: 'if(isNull(visitors), 0, visitors)',
            highEngagementVisitors: 'if(isNull(highEngagementVisitors), 0, highEngagementVisitors)',
            medEngagementVisitors: 'if(isNull(medEngagementVisitors), 0, medEngagementVisitors)',
            lowEngagementVisitors: 'if(isNull(lowEngagementVisitors), 0, lowEngagementVisitors)',
            summedMinutes:
                'ifNull(highEngagementSummedMinutes, 0) + ifNull(medEngagementSummedMinutes, 0) + ifNull(lowEngagementSummedMinutes, 0)'
        }),
        o.evalExpression({
            visitors: 'highEngagementVisitors + medEngagementVisitors + lowEngagementVisitors',
            summedMinutes: 'if(summedMinutes > 0, summedMinutes, "--")'
        }),
        o.evalExpression({
            highEngagementPercent:
                'if(isNull(highEngagementVisitors), null, if(visitors == 0, 0, highEngagementVisitors / visitors))',
            medEngagementPercent:
                'if(isNull(medEngagementVisitors), null, if(visitors == 0, 0, medEngagementVisitors / visitors))',
            lowEngagementPercent:
                'if(isNull(lowEngagementVisitors), null, if(visitors == 0, 0, lowEngagementVisitors / visitors))'
        })
    ];
}

export async function getLicenseTimeSeries ({ appId, segmentId, timeSeries, signal }) {
    const spec = buildLicenseTimeSeriesSpec({ appId, segmentId, timeSeries });

    let { messages } = await request(spec, { rowsOnly: false, signal });
    messages = messages
        .map(({ time, rows }) => ({
            timestamp: time,
            rows
        }))
        .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));

    return messages;
}

export function buildLicenseTimeSeriesSpec ({ appId, segmentId, timeSeries }) {
    const { operators: o } = Agg2;

    return o.aggregation(
        {
            name: 'license-time-series-adopt',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.LICENSE_UTILIZATION
        },
        o.pipeline(
            o.sources.events({
                appId,
                timeSeries
            }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.filter('appId != -323232'),
            o.group(['appId'], o.groupField('numVisitors', o.count('visitorId')))
        )
    );
}
