import { formatTimeValue } from '../moment';
import { convertAppIdToAggSafeId } from '../apps';
import get from 'lodash/get';

export function parseApplicationUsageFilterPipeline ({ operator, selectedApp, time, value }) {
    let filterString;
    const appId = convertAppIdToAggSafeId(get(selectedApp, 'id', ''));

    switch (operator) {
        case 'used':
            if (time === 'withinlast') {
                const { count, granularity } = value;

                return {
                    filter: `!isNil(metadata.auto_${appId}.lastvisit)&&metadata.auto_${appId}.lastvisit>=dateAdd(now(),${-1 *
                        count},"${granularity}")`
                };
            }

            if (time === 'since') {
                return {
                    filter: `!isNil(metadata.auto_${appId}.lastvisit)&&metadata.auto_${appId}.lastvisit>=date("${formatTimeValue(
                        value
                    )}")`
                };
            }

            return { filter: `!isNil(metadata.auto_${appId}.lastvisit)` };
        case 'notused':
            if (time === 'withinlast') {
                const { count, granularity } = value;

                return {
                    filter: `(isNil(metadata.auto_${appId}.lastvisit))||(metadata.auto_${appId}.lastvisit<dateAdd(now(),${-1 *
                        count},"${granularity}"))`
                };
            }

            if (time === 'since') {
                return {
                    filter: `(isNil(metadata.auto_${appId}.lastvisit))||(metadata.auto_${appId}.lastvisit<date("${formatTimeValue(
                        value
                    )}"))`
                };
            }

            return { filter: `isNil(metadata.auto_${appId}.lastvisit)` };
    }

    return { filter: filterString };
}
