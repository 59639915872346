export function parseMobieDataFilterPipeline ({ operator, value, id, field, selectedApp }) {
    let filterString;

    const selectedAppString = selectedApp?.id ? `_${selectedApp.id}` : '';
    const userAgentField =
        id === 'deviceModel'
            ? `toLowerCase(userAgent${selectedAppString}.${field})`
            : `userAgent${selectedAppString}.${field}`;
    switch (operator) {
        case '==':
        case '!=':
            filterString = `${userAgentField}${operator}${value}`;
            break;
        case 'startsWith':
        case '!startsWith':
        case 'contains':
            filterString = `${operator}(${userAgentField},${value})`;
            break;
        case '!contains':
            filterString = `(${operator}(${userAgentField},${value}))`;
            break;
    }

    return { filter: filterString };
}
