import get from 'lodash/get';
import isObject from 'lodash/isObject';

export function onSortChange ($event, currentUserSettings) {
    const { order, prop } = $event;

    const newSortSetting = {
        defaultSort: { order, prop }
    };

    return generateSaveableUserSetting(newSortSetting, currentUserSettings);
}

export function onColumnResize ($event, currentUserSettings) {
    const { column: prop, width } = $event;

    const columnSizes = get(currentUserSettings, 'columnSizes', {});

    const newColumnSizes = {
        ...columnSizes,
        [prop]: width
    };

    const partialSetting = { columnSizes: newColumnSizes };

    return generateSaveableUserSetting(partialSetting, currentUserSettings);
}

export function onColumnChange ($event, currentUserSettings) {
    const { columns } = $event;
    // Some settings save *visibleColumns* – an array of columns props
    // Others save *columns* – an array of column objects (particularly when product usage columns are included)
    const key = isObject(columns[0]) ? 'columns' : 'visibleColumns';
    const partialSetting = { [key]: columns };

    return generateSaveableUserSetting(partialSetting, currentUserSettings);
}

export function generateSaveableUserSetting (partialSetting, currentUserSettings) {
    const newUserSetting = {
        ...currentUserSettings,
        ...partialSetting
    };

    return newUserSetting;
}
