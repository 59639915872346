<template>
    <span class="rule-warning">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'RuleWarning'
};
</script>

<style lang="scss" scoped>
.rule-warning {
    color: $red-error;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 0.875rem;
    margin-top: 0.5em;
}
</style>
