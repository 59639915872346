<template>
    <pendo-multiselect
        multiple
        :show-selected-values="false"
        class="app-filter"
        label-key="displayName"
        value-key="id"
        stateless
        placeholder="Select Applications"
        :value="individualSelectedApps"
        :close-on-select="false"
        :full-width="true"
        :options="groupedAppOptions"
        group-options-key="options"
        group-label-key="label"
        :max-menu-height="300"
        :max-menu-width="400"
        :min-menu-width="200"
        :max="5"
        :searchable="false"
        :popper-options="popperOptions"
        @select="addAppToFilter"
        @remove="removeAppFromFilter">
        <template #selectedLabel="{ option, selectedLabel }">
            {{ createSelectedAppsLabel(option, selectedLabel) }}
        </template>
        <template #option="{ option }">
            <pendo-checkbox-option :option="option">
                <pendo-app-display :apps="option" />
            </pendo-checkbox-option>
        </template>
        <template #optionGroup="{ option }">
            <div
                v-if="option.id === 'app-groups'"
                class="group-header-label">
                {{ option.label }}
            </div>
            <div
                v-else
                class="group-header-label">
                {{ option.label }} ({{ option.options.length }})
            </div>
        </template>
        <template #beforeList>
            <div
                v-if="individualSelectedApps.length"
                class="user-selected-apps">
                <div class="user-selected-apps-header">
                    <div class="group-header-label pendo-multiselect__group">
                        Selected ({{ individualSelectedApps.length }})
                    </div>
                    <pendo-button
                        type="link"
                        label="Clear"
                        class="clear-selected-apps-button"
                        @click="clearSelection" />
                </div>
                <div class="pendo-multiselect__option">
                    <pendo-checkbox-option
                        v-for="(selectedOption, index) of individualSelectedApps"
                        :key="index"
                        :option="selectedOption"
                        @change="removeAppFromFilter(selectedOption)">
                        <pendo-app-display :apps="selectedOption" />
                    </pendo-checkbox-option>
                </div>
                <pendo-divider
                    stroke="#dadce5"
                    height="16px" />
            </div>
        </template>
        <template #header>
            <div class="application-search-container">
                <pendo-multiselect-input
                    class="application-search"
                    :input-value="searchTerm"
                    :is-search-icon-visible="true"
                    computed-placeholder="Search Applications..."
                    :update-input-value="updateSearchTerm" />
            </div>
        </template>
        <template #noData>
            <div class="no-data-message">
                No applications found for search text "<strong>{{ searchTerm }}</strong>"
            </div>
        </template>
    </pendo-multiselect>
</template>
<script>
import {
    PendoMultiselect,
    PendoCheckboxOption,
    PendoMultiselectInput,
    PendoButton,
    PendoDivider,
    PendoAppDisplay
} from '@pendo/components';

export default {
    name: 'JourneysAppFilter',
    components: {
        PendoMultiselect,
        PendoMultiselectInput,
        PendoCheckboxOption,
        PendoButton,
        PendoDivider,
        PendoAppDisplay
    },
    props: {
        appIds: {
            type: Array,
            default: () => []
        },
        appOptions: {
            type: Array,
            default: () => []
        }
    },
    emits: ['change'],
    data () {
        return {
            searchTerm: ''
        };
    },
    computed: {
        groupedAppOptions () {
            const allAppOptions = {
                id: 'individual-apps',
                label: 'All Applications',
                options: this.filterAppListBySearchTerm(this.appOptions)
            };

            const options = [];

            if (allAppOptions.options.length) {
                options.push(allAppOptions);
            }

            return options;
        },
        individualSelectedApps () {
            return this.appOptions.filter((app) => this.appIds.includes(app.id));
        },
        popperOptions () {
            return {
                modifiers: {
                    preventOverflow: {
                        boundariesElement: 'window'
                    }
                }
            };
        }
    },
    methods: {
        createSelectedAppsLabel (options, placeholder) {
            if (options.length === 0) {
                return placeholder;
            }
            if (options.length <= 3) {
                return options.map((opt) => opt.displayName).join(', ');
            }

            return `Applications (${options.length})`;
        },
        updateSearchTerm (newVal) {
            this.searchTerm = newVal;
        },
        filterAppListBySearchTerm (appList) {
            if (!this.searchTerm) return appList;

            return appList.filter((opt) => {
                return opt.displayName.toLowerCase().includes(this.searchTerm.toLowerCase());
            });
        },
        addAppToFilter (app) {
            if (this.appIds.includes(app.id)) return;

            this.$emit('change', this.appIds.concat(app.id));
        },
        removeAppFromFilter (app) {
            const indexOfApp = this.appIds.indexOf(app.id);

            if (indexOfApp === -1) return;

            this.$emit(
                'change',
                this.appIds.filter((id) => id !== app.id)
            );
        },
        clearSelection () {
            this.$emit('change', []);
        }
    }
};
</script>
<style lang="scss" scoped>
.app-filter {
    position: relative;
    min-width: 365px;
}

.pendo-multiselect__trigger {
    height: 36px;
    min-width: 200px;
    max-width: 400px;
    padding: 0px 8px;
    display: flex;
    justify-content: space-between;
}

.selected-apps-trigger-text {
    max-width: 380px;
    display: block;
    line-height: 20px;
    box-sizing: border-box;
    padding-right: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.all-apps-option {
    display: flex;
    align-items: center;
}

.user-selected-apps-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    box-sizing: border-box;
    height: 26px;

    .clear-selected-apps-button {
        height: 26px;
        font-size: 12px;
    }
}

.application-search {
    padding: 0 8px;
    height: 34px;
    border-bottom: 1px solid $gray-lighter-6;
    border-radius: 3px;
    justify-content: start;

    :deep(.pendo-multiselect__search-icon-wrapper) {
        color: $gray-lighter-4;
    }
}

.group-header-label {
    font-size: 10px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}
</style>
