<template>
    <div class="segment-stats-panel">
        <segment-visitor-count v-bind="{ pipeline, showCsvUploadWarning, isValid }" />
        <pendo-collapse-transition>
            <segment-metric-panel
                v-if="showSegmentsUsed"
                title="Segments Used"
                wrapper-class="segments-used-count"
                :tooltip="segmentsUsedTooltip"
                :value="usedSegmentsStat" />
        </pendo-collapse-transition>
    </div>
</template>

<script>
import { PendoCollapseTransition } from '@pendo/components';
import SegmentMetricPanel from './SegmentMetricPanel.vue';
import SegmentVisitorCount from './SegmentVisitorCount.vue';
import { SEGMENT_RULE_LIMIT } from '@/components/segment-builder/constants/segments';

export default {
    name: 'SegmentStatsPanel',
    components: {
        SegmentVisitorCount,
        PendoCollapseTransition,
        SegmentMetricPanel
    },
    props: {
        pipeline: {
            type: [Array, Object],
            default: undefined
        },
        showCsvUploadWarning: {
            type: Boolean,
            default: false
        },
        isValid: {
            type: Boolean,
            default: true
        },
        nestedSegmentCount: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            segmentsUsedTooltip: {
                content: `The number of segments you can use is limited to ${SEGMENT_RULE_LIMIT}.`,
                arrow: true,
                multiline: true
            }
        };
    },
    computed: {
        showSegmentsUsed () {
            return this.nestedSegmentCount > 0;
        },
        usedSegmentsStat () {
            return `${this.nestedSegmentCount} of ${SEGMENT_RULE_LIMIT}`;
        }
    }
};
</script>
