import { http } from '@pendo/http';
import store from '@/state/store';
import { getIdentifiedState } from '@/stateless-components/utils/aggregations';

export const MAX_RETRIES = 5;

export const MAX_CSV_RETRIES = 600;

export const aggregationsEndpoint = '/api/aggregation/s/_SID_/?all=true';

export async function getLongRunningAggregationStatus (jobId) {
    return http.get(`/api/aggregation/s/_SID_/${jobId}/status`);
}

export function identifiedState (segmentId) {
    const { canShowAnonOptions } = store.state.filters;

    return getIdentifiedState(segmentId, canShowAnonOptions);
}
