<template>
    <pendo-modal
        :visible="visible"
        class="add-custom-page-modal"
        height="auto"
        title="Add Custom Pages"
        @close="closeModal">
        <template #body>
            <p class="add-custom-page-modal--description">
                The page list is a great way to keep track of visitor usage. If a page is not represented in your
                current list, input the URL of the page you’d like to add.
            </p>
            <p
                v-if="customTaggingDescription"
                class="add-custom-page-modal--description--custom">
                {{ customTaggingDescription }}
            </p>
            <pendo-divider stroke="#dadce5" />
            <pendo-form
                :call-validate="callValidate"
                :model="model"
                @formValidated="handleValidForm"
                @invalidForm="handleInvalidForm">
                <pendo-form-item
                    class="app-selector-form-item"
                    :rules="[
                        {
                            required: true,
                            message: 'No selection made',
                            trigger: 'blur'
                        }
                    ]"
                    prop="selectedApp">
                    <pendo-multiselect
                        :value="model.selectedApp"
                        :allow-empty="false"
                        :labels="{ left: 'Application' }"
                        :options="formattedAppOptions"
                        label-key="name"
                        placeholder="- None Selected -"
                        @select="onAppSelection" />
                </pendo-form-item>
                <pendo-form-item
                    :rules="rules.customUrl"
                    label="Custom URL*"
                    prop="customUrl">
                    <pendo-input
                        v-model="model.customUrl"
                        data-cy="customurl"
                        maxlength="1000"
                        placeholder="https://" />
                </pendo-form-item>
            </pendo-form>
            <div class="add-custom-page-modal--body">
                <svg
                    class="url-bracket"
                    width="9"
                    height="40"
                    viewBox="0 0 9 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1 0H0V39V40H9V39H1V0Z"
                        fill="#DADCE5" />
                </svg>
                <pendo-input
                    v-model="model.name"
                    data-cy="displayname"
                    maxlength="1000"
                    placeholder="Page Name"
                    label="Page Display Name*" />
            </div>
            <pendo-alert
                v-if="addCustomPageError"
                class="add-custom-page-error"
                type="error">
                Something went wrong. Try again - if the problem persists contact Pendo support.
            </pendo-alert>
        </template>
        <template #footer>
            <pendo-button
                theme="app"
                type="secondary"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :disabled="disableAddPageButton"
                theme="app"
                type="primary"
                label="Add Page"
                @click="validateForm" />
        </template>
    </pendo-modal>
</template>

<script>
import {
    PendoAlert,
    PendoButton,
    PendoDivider,
    PendoForm,
    PendoFormItem,
    PendoInput,
    PendoModal,
    PendoMultiselect
} from '@pendo/components';
import { mapActions, mapGetters, mapState } from 'vuex';
import { normalizeUrl } from '@/utils/utils';
import get from 'lodash/get';
import { isValidUrl } from '@pendo/services/Formatters';

export default {
    name: 'AddCustomPagesModal',
    components: {
        PendoAlert,
        PendoButton,
        PendoDivider,
        PendoForm,
        PendoFormItem,
        PendoInput,
        PendoModal,
        PendoMultiselect
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    emits: ['close'],
    data () {
        return {
            model: {
                customUrl: '',
                name: '',
                selectedApp: {}
            },
            rules: {
                customUrl: [
                    {
                        validator: this.validateUrl,
                        trigger: ['blur', 'change']
                    }
                ]
            },
            parsedPageId: null,
            parsedParameter: null,
            callValidate: false,
            addCustomPageError: false,
            isInvalidURL: true
        };
    },
    computed: {
        ...mapState({
            _pageError: (state) => state.pages.error
        }),
        ...mapGetters({
            _activeSub: 'subscriptions/active',
            excludeMobileForActiveSubscription: 'apps/excludeMobileForActiveSubscription'
        }),
        customTaggingDescription () {
            return get(this._activeSub, 'trainingAttributes.customPageInstructions', '');
        },
        disableAddPageButton () {
            return (
                !this.model.name ||
                !this.model.customUrl ||
                this.isInvalidURL ||
                !Object.keys(this.model.selectedApp).length
            );
        },
        formattedAppOptions () {
            return this.excludeMobileForActiveSubscription.map((app) => {
                return {
                    id: app.id,
                    name: app.displayName
                };
            });
        }
    },
    methods: {
        ...mapActions({
            _fetchSinglePageWithAnalytics: 'pages/fetchSinglePageWithAnalytics',
            _createCustomPage: 'pages/createCustomPage'
        }),
        closeModal () {
            this.$emit('close');
            this.model.name = '';
            this.model.selectedApp = {};
            this.addCustomPageError = false;
        },
        validateForm () {
            this.callValidate = true;
        },
        handleValidForm () {
            this.callValidate = false;

            this.onAddCustomPage();
        },
        handleInvalidForm () {
            this.callValidate = false;
        },
        validateUrl (rule, value, callback) {
            this.addCustomPageError = false;

            if (!isValidUrl(value)) {
                callback(new Error('URL is invalid.'));
                this.isInvalidURL = true;

                return;
            }

            this.isInvalidURL = false;
            callback();
        },
        async onAddCustomPage () {
            this.addCustomPageError = false;
            try {
                const { name, customUrl, selectedApp } = this.model;
                const normURL = await normalizeUrl(customUrl);
                const urlObj = new URL(normURL);
                let rule;
                if (urlObj.hash) {
                    rule = `//*/**${urlObj.hash}`;
                } else {
                    rule = `//*${urlObj.pathname}`;
                }
                const rules = [{ rule }];
                await this._createCustomPage({
                    customPage: { name, rules, appId: selectedApp.id }
                });

                this.closeModal();
            } catch (err) {
                this.addCustomPageError = true;
            }
        },
        onAppSelection (app) {
            this.model.selectedApp = app;
        }
    }
};
</script>

<style lang="scss">
.add-custom-page-modal {
    &--description {
        margin-top: 0;

        &--custom {
            white-space: pre-line;
        }
    }

    &--body {
        display: flex;
        margin-top: 12px;

        .url-bracket {
            margin-right: 8px;
        }
    }

    .add-custom-page-error {
        margin-top: 24px;
    }
}
</style>
