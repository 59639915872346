<template>
    <div class="guide-elements-table">
        <pendo-guide-elements-table
            :guide-step-names="guideStepNames"
            :apps="guideApps"
            :elements-activity-agg-status="elementsActivityAggStatus"
            :elements-activity="elementsActivity"
            :elements-table-columns="elementsTableColumns"
            :csv-download-config="csvDownloadConfig"
            :loading-options="loadingOptions"
            :is-cross-app="isCrossApp"
            :grouped="grouped"
            :show-deleted="showDeleted"
            resizable
            @toggle-grouped="toggleGrouped"
            @toggle-show-deleted="toggleShowDeleted"
            @on-metric-click="openDrawer" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { getGuideElementsActivity, getGuideElementsActivityClicks } from '@/aggregations/guide-elements-activity';
import { getStepsWithBuildingBlocks } from '@/stateless-components/utils/guides';
import { getCurrentElementData, getElementActionColumnWidth } from '@/stateless-components/utils/guide-metrics';
import { addHrefToVisitors, VISITOR_URL } from '@/utils/utils';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';
import { getElementsTableColumns, processGuideElementsAggResponse } from '@/utils/guide-elements-activity';
import {
    PendoGuideElementsDrawerHeader,
    PendoGuideMetricsDrawerContent,
    PendoGuideElementsTable
} from '@pendo/components';
import { isCrossApp, getAppGroups } from '@pendo/services/CrossAppGuides';

export default {
    name: 'GuideElementsActivityTable',
    components: {
        PendoGuideElementsTable
    },
    props: {
        csvDownloadAggId: {
            type: String,
            default: () => 'guide-elements'
        }
    },
    emits: ['open-side-panel', 'update-ui-element', 'update-guide-elements-clicks'],
    data () {
        return {
            currentIds: null,
            currentElementData: null,
            elementsActivityAggStatus: '',
            elementsActivity: [],
            unsubscribeFilterBarListener: null,
            steps: [],
            grouped: true,
            showDeleted: false,
            lastResolvedAggValue: null
        };
    },
    computed: {
        ...mapState({
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange
        }),
        ...mapGetters({
            guide: 'guides/active',
            guideList: 'guides/list',
            activeSegment: 'filters/activeSegment',
            activeTimeSeries: 'filters/activeTimeSeries',
            appsFromGuide: 'apps/appsFromGuide'
        }),
        appIds () {
            return get(this.guide, 'appIds', '');
        },
        loadingOptions () {
            return {
                loadingType: 'material',
                loadingSpinnerSize: 24
            };
        },
        elementsTableColumns () {
            const { grouped, isCrossApp } = this;
            const elementActionColumnWidth = getElementActionColumnWidth(this.elementsActivity);

            return getElementsTableColumns({
                grouped,
                elementActionColumnWidth,
                isCrossApp
            });
        },
        csvDownloadConfig () {
            const segment = get(this.activeSegment, 'name');
            const dateRange = get(this.dateRange, 'label');

            return {
                columns: getElementsTableColumns({
                    grouped: false,
                    csv: true,
                    isCrossApp: this.isCrossApp
                }),
                filename: `Guide Elements - ${dateRange} - ${segment}`
            };
        },
        guideStepNames () {
            return this.guide.steps.map(({ id, name }) => ({ id, name }));
        },
        isCrossApp () {
            return isCrossApp(this.guide);
        },
        guideApps () {
            if (!this.isCrossApp) return [];

            return this.appsFromGuide(this.guide);
        },
        getStepsWithAppIdForGuide () {
            return getAppGroups(this.guide).flatMap((appgroup, index) =>
                appgroup.map((step) => {
                    const appId = get(step, 'appIds[0]', '');

                    return {
                        ...step,
                        appId: `${appId}-${index}`
                    };
                })
            );
        }
    },
    async created () {
        await this.fetchGuides();
        await this.setGuideElements();
        await this.refreshTable();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, async () => {
            await this.refreshTable();
        });
    },
    unmounted () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapActions({
            fetchGuides: 'guides/fetch'
        }),
        async setGuideElements () {
            const guide = cloneDeep(this.guide);
            guide.steps = await getStepsWithBuildingBlocks({ steps: this.guide.steps });
            this.currentElementData = guide.steps.reduce((acc, step, index) => {
                const stepElementData = getCurrentElementData(JSON.parse(step.dom)).map((element) => {
                    return {
                        ...element,
                        guideId: this.guide.id,
                        guideStepId: step.id,
                        guideStepName: step.name,
                        guideStepIndex: index
                    };
                });
                acc.push(...stepElementData);

                return acc;
            }, []);
            this.currentIds = this.currentElementData.map((element) => element.uiElementId);
        },
        async refreshTable () {
            if (this.isCrossApp && !this.steps.length) {
                this.steps = this.getStepsWithAppIdForGuide;
            }
            this.elementsActivityAggStatus = 'loading';
            const elements = await getGuideElementsActivity({
                appIds: this.appIds,
                guideId: this.guide.id,
                currentIds: this.currentIds,
                segmentId: this.activeSegmentId,
                timeSeries: { ...this.activeTimeSeries, period: 'dayRange' }
            });
            const elementsActivity = processGuideElementsAggResponse(
                elements,
                this.guide,
                this.currentElementData,
                this.isCrossApp,
                this.steps,
                this.guideList
            );
            this.elementsActivityAggStatus = elementsActivity.length ? 'resolved' : 'empty';
            if (this.elementsActivityAggStatus === 'resolved') {
                this.lastResolvedAggValue = elements;
            }
            this.elementsActivity = elementsActivity;
        },
        toggleGrouped (newVal) {
            this.grouped = newVal;
        },
        async toggleShowDeleted (newVal) {
            this.showDeleted = newVal;
            this.elementsActivity = processGuideElementsAggResponse(
                this.lastResolvedAggValue,
                this.guide,
                this.currentElementData,
                this.isCrossApp,
                this.steps,
                this.guideList,
                newVal
            );
        },
        async getGuideElementsActivityClicksWithHref (aggParams) {
            const response = await getGuideElementsActivityClicks(aggParams);
            const visitorsWithHref = addHrefToVisitors(response[0]);

            return visitorsWithHref;
        },
        async openDrawer (row) {
            const timeSeries = {
                ...this.activeTimeSeries,
                period: 'dayRange'
            };
            this.$emit('update-ui-element', row);

            this.setSidePanelState({
                row,
                loading: true
            });
            const visitors = await this.getGuideElementsActivityClicksWithHref({
                appIds: this.appIds,
                guideId: this.guide.id,
                timeSeries,
                segmentId: this.activeSegmentId,
                uiElementId: row.uiElementId
            });

            this.$emit('update-guide-elements-clicks', visitors);

            this.setSidePanelState({
                row,
                data: visitors,
                loading: false
            });
        },
        setSidePanelState ({ row, data, loading, csvModalStatus = 'loading', csvUrl = '' }) {
            const state = {
                visible: true,
                header: {
                    component: PendoGuideElementsDrawerHeader,
                    props: {
                        uiElement: row
                    }
                },
                body: {
                    component: PendoGuideMetricsDrawerContent,
                    props: {
                        csvDownloadAggId: this.csvDownloadAggId,
                        data,
                        loading,
                        csvModalStatus,
                        csvUrl,
                        visitorUrl: VISITOR_URL,
                        showAccountsTab: false,
                        showCreateSegment: false,
                        showClicksColumn: true,
                        theme: 'app'
                    }
                }
            };
            this.$emit('open-side-panel', { ...state, visible: true });
        }
    }
};
</script>
