<template>
    <div class="track-type-rule">
        <!-- track-type list picker -->
        <div :class="{ 'track-type-undefined': rule.trackTypeDeleted }">
            <pendo-multiselect
                :value="rule.trackType"
                :allow-empty="false"
                :options="trackTypeListOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="displayName"
                value-key="id"
                full-width
                placeholder="Select Track Event"
                class="track-type-rule--track-type-select"
                @select="onTrackTypeSelect" />
            <rule-warning v-if="rule.trackTypeDeleted">
                The original track event for this rule has been deleted
            </rule-warning>
            <rule-warning v-else-if="isTrackTypeOrphaned">
                The track event used by this rule is no longer being shared.
            </rule-warning>
        </div>

        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            @select="handleTrackTypeOperatorSelection" />
        <pendo-multiselect
            :value="trackTypeTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="trackTypeTimeRangeOptions"
            :disabled="readOnly"
            class="track-type-rule--track-type-time-operator"
            @select="handleTrackTypeTimeSelection" />
        <component
            :is="timeComponent.component"
            v-if="showTimeComponent"
            v-bind="timeComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import RuleWarning from '../rule-components/RuleWarning';
import SingleDate from '../rule-components/SingleDate.vue';
import {
    SEGMENT_OPERATORS,
    TRACK_TYPE_TIME_RANGE_USED_OPTIONS,
    TRACK_TYPE_TIME_RANGE_NOT_USED_OPTIONS,
    TRACK_TYPE_TIME_RANGE_LAST_USED_OPTIONS
} from '@/components/segment-builder/constants/segments';
import { PendoMultiselect } from '@pendo/components';
import { getEntitiesByAppSelection } from '@/components/segment-builder/utils/utils';
import {
    getAbsoluteDateRangeConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import get from 'lodash/get';

export default {
    name: 'TrackTypeRule',
    components: {
        AbsoluteDateRange,
        PendoMultiselect,
        RelativeDateRange,
        RuleWarning,
        SingleDate
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        trackTypeListOptions () {
            return getEntitiesByAppSelection(this.rule.app, this.computedData.trackTypes, this.config);
        },
        isTrackTypeOrphaned () {
            const { rule } = this;

            return rule.trackType && !rule.isTrackTypeShared;
        },
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        },
        trackTypeTimeRangeOptions () {
            switch (this.rule.operator.value) {
                case 'notused':
                    return TRACK_TYPE_TIME_RANGE_NOT_USED_OPTIONS;
                case 'lastused':
                    return TRACK_TYPE_TIME_RANGE_LAST_USED_OPTIONS;
                case 'used':
                default:
                    return TRACK_TYPE_TIME_RANGE_USED_OPTIONS;
            }
        },
        showTimeComponent () {
            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(rule.trackType && operatorValue && rule.time && rule.time !== 'ever');
        },
        timeComponent () {
            if (['withinlast', '!withinlast'].includes(this.rule.time)) {
                return getRelativeDateRangeConfig(this.rule, 'value');
            }

            if (['between'].includes(this.rule.time)) {
                return getAbsoluteDateRangeConfig(this.rule);
            }

            if (['before', 'since'].includes(this.rule.time)) {
                return getSingleDateConfig(this.rule, 'value');
            }

            return;
        }
    },
    methods: {
        trackTypeTimeLabelObject (timeString) {
            return this.trackTypeTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleTrackTypeOperatorSelection ($event) {
            this.$emit('input', [{ field: 'operator', value: $event }]);
        },
        handleTrackTypeTimeSelection ($event) {
            const { value } = $event;

            if (value === 'withinlast' || value === '!withinlast') {
                this.$emit('input', { field: 'value', value: { count: 30, granularity: 'days' } });
            } else if (value !== 'before' && value !== 'since') {
                this.$emit('input', { field: 'value', value: 1 });
            }
            this.$emit('input', { field: 'time', value });
        },
        onTrackTypeSelect (trackType) {
            this.$emit('input', [
                { field: 'trackType', value: trackType },
                { field: 'isTrackTypeShared', value: true },
                { field: 'trackTypeDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.track-type-rule {
    display: contents;

    .track-type-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
