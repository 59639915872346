/* eslint-disable id-length */
import * as Agg2 from '@pendo/aggregations';
import {
    request,
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { identifiedState } from '@/utils/aggregations';

export function getPageList ({ appId }) {
    const spec = buildPageListSpec({ appId });

    return request(spec);
}

export function buildPageListSpec ({ appId }) {
    const { operators: o, generators: g } = Agg2;

    return o.aggregation(
        {
            name: 'page-list',
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.PAGE_LIST_DETAILS
        },
        o.pipeline(
            o.sources.pages({ appId }),
            g.appColumn({ primaryKeys: ['appId'] }),
            o.evalExpression({ pageId: 'id' })
        )
    );
}

export function getPageActivity ({ appId, segmentId, timeSeries, signal }) {
    const spec = buildPageActivitySpec({ appId, timeSeries, segmentId });

    return request(spec, { signal });
}

export function buildPageActivitySpec (listParams) {
    const { pageId, timeSeries, appId, segmentId } = listParams;
    const { operators: o, common: c } = Agg2;

    const pageSpecificFilter = pageId ? o.filter(`id == "${pageId}"`) : null;
    const filterForPagesWithNoData = pageId ? null : o.filter('numVisitors > 0');
    const aggregationName = pageId ? 'single-page-activity' : 'page-activity';

    return o.aggregation(
        {
            name: aggregationName,
            productArea: PRODUCT_AREAS.ANALYTICS,
            subProductArea: SUB_PRODUCT_AREAS.PAGE_LIST_DETAILS
        },
        o.pipeline(
            buildPageListSpec(listParams).pipeline,
            pageSpecificFilter,
            o.merge(
                ['pageId'],
                o.mappings({
                    numVisitors: 'numVisitors',
                    pageLoads: 'pageLoads',
                    avgTime: '(totalTime/dailyVisitors) * 60 * 1000'
                }),
                o.pipeline(
                    o.spawn(
                        o.pipeline(
                            o.sources.pageEvents({
                                timeSeries,
                                appId
                            }),
                            o.identified(identifiedState(segmentId)),
                            o.segment(parseSegmentIdForAggregation(segmentId)),
                            o.group(
                                'pageId',
                                ...c.groupFields({
                                    numVisitors: o.count('visitorId'),
                                    pageLoads: o.sum('numEvents'),
                                    dailyVisitors: o.count(['visitorId', 'day']),
                                    totalTime: o.sum('numMinutes')
                                })
                            )
                        )
                    ),
                    o.join('pageId')
                )
            ),
            o.evalExpression({
                numVisitors: 'if(isNil(numVisitors), 0, numVisitors)',
                numAccounts: 'if(isNil(numAccounts), 0, numAccounts)',
                pageLoads: 'if(isNil(pageLoads), 0, pageLoads)',
                avgTime: 'if(isNil(avgTime), 0, avgTime)'
            }),
            filterForPagesWithNoData
        )
    );
}

export function getSinglePageActivity ({ pageId, appId, segmentId, timeSeries }) {
    const spec = buildPageActivitySpec({ pageId, appId, timeSeries, segmentId });

    return request(spec);
}
