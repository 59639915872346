<template>
    <div class="view--visitor-details">
        <visitor-details-header :visitor-id="decodedVisitorId" />
        <visitor-details-content :visitor-id="decodedVisitorId" />
    </div>
</template>

<script>
import VisitorDetailsHeader from '@/components/analytics/visitors/VisitorDetailsHeader';
import VisitorDetailsContent from '@/components/analytics/visitors/VisitorDetailsContent';

export default {
    name: 'VisitorDetailsView',
    components: {
        VisitorDetailsHeader,
        VisitorDetailsContent
    },
    props: {
        visitorId: {
            type: String,
            required: true
        }
    },
    computed: {
        decodedVisitorId () {
            return decodeURIComponent(this.visitorId);
        }
    }
};
</script>
