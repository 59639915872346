<template>
    <div class="guide-element-rule">
        <!-- guide -->
        <div :class="{ 'guide-undefined': rule.guideDeleted }">
            <pendo-multiselect
                :value="rule.guide"
                :allow-empty="false"
                :options="guidePickerOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                placeholder="Select Guide"
                class="guide-element-rule--guide-select"
                @select="onGuideSelect">
                <template #option="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
                <template #noData>
                    <div class="no-data-message">
                        {{ noGuideDataText }}
                    </div>
                </template>
            </pendo-multiselect>
            <rule-warning v-if="rule.guideDeleted">
                The original guide for this rule has been deleted.
            </rule-warning>
        </div>
        <template v-if="rule.guide">
            <!-- guide step -->
            <pendo-multiselect
                v-if="guideStepOptions.length > 1"
                :value="selectedGuideStep"
                full-width
                :allow-empty="false"
                :options="guideStepOptions"
                :disabled="readOnly"
                label-key="name"
                value-key="guideStepId"
                placeholder="Select Guide Step"
                class="guide-element-rule--guide-step-select"
                @select="onGuideStepSelect" />
            <!-- guide element -->
            <!-- conditionally show when guideStepId is set or show empty when there are no steps -->
            <pendo-multiselect
                v-if="rule.guideStepId || !rule.guide.steps.length"
                :value="selectedGuideElement"
                full-width
                :extra-label-width="90"
                :allow-empty="false"
                :options="guideElementOptions"
                :loading="isGuideElementsLoading"
                :disabled="readOnly"
                label-key="name"
                value-key="guideElementId"
                placeholder="Select Guide Element"
                class="guide-element-rule--guide-element-select"
                @select="onGuideElementSelect">
                <template #noData>
                    <div class="no-data-message">
                        No guide elements found. Please select a different step or guide.
                    </div>
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
                <template #option="{ option }">
                    <pendo-icon-option :option="option" />
                </template>
            </pendo-multiselect>
        </template>
        <template v-if="rule.guideElementId">
            <pendo-multiselect
                :value="rule.operator"
                :allow-empty="false"
                preselect-first
                full-width
                value-key="value"
                :options="operatorOptions"
                :disabled="!operatorOptions || readOnly"
                class="guide-element-rule--operator"
                @select="handleOperatorSelection" />
            <pendo-multiselect
                :value="timeLabelObject(rule.time)"
                value-key="value"
                label-key="label"
                min-trigger-width="100%"
                :allow-empty="false"
                :preselect-first="true"
                :options="timeRangeOptions"
                :disabled="readOnly"
                class="guide-element-rule--time-operator"
                @select="handleTimeSelection" />
            <component
                :is="valueComponent.component"
                v-if="showValueComponent"
                v-bind="valueComponent.props"
                @input="$emit('input', $event)" />
        </template>
    </div>
</template>

<script>
import { PendoMultiselect, PendoGuideStatusOption, PendoIconOption } from '@pendo/components';
import RuleWarning from '../rule-components/RuleWarning';
import { getEntitiesByAppSelection, getNoResourcesFoundText } from '@/components/segment-builder/utils/utils';
import guideElementsActivityAgg from '@/components/segment-builder/aggregations/guide-elements-activity';
import { connectAggregationToState, withComponent, LOADING_STATES } from '@pendo/agg-connect';
import get from 'lodash/get';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import NumberInput from '../rule-components/NumberInput.vue';
import {
    SEGMENT_OPERATORS,
    GUIDE_ELEMENT_TIME_RANGE_CLICKED_OPTIONS,
    GUIDE_ELEMENT_TIME_RANGE_NOT_CLICKED_OPTIONS
} from '@/components/segment-builder/constants/segments';
import {
    getContent,
    processGuideElementsAggDropdown,
    getCurrentElementData
} from '@/components/segment-builder/utils/guideElements';
import {
    getNumberConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'GuideElementRule',
    components: {
        PendoGuideStatusOption,
        PendoIconOption,
        PendoMultiselect,
        RuleWarning,
        RelativeDateRange,
        SingleDate,
        NumberInput
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        // eslint-disable-next-line vue/no-unused-properties
        filters: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            guideElementsActivityAggStatus: null,
            guideElements: [],
            guideWithContent: undefined
        };
    },
    computed: {
        currentElementData () {
            return this.guideWithContent?.steps.reduce((acc, step) => {
                if (!step.dom) {
                    return acc;
                }

                const stepElementData = getCurrentElementData(JSON.parse(step.dom)).map((element) => {
                    return {
                        ...element,
                        guideStepId: step.id
                    };
                });
                acc.push(...stepElementData);

                return acc;
            }, []);
        },
        currentIds () {
            return this.currentElementData?.map((element) => element.uiElementId);
        },
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        },
        timeRangeOptions () {
            return this.rule.operator?.value === 'clicked'
                ? GUIDE_ELEMENT_TIME_RANGE_CLICKED_OPTIONS
                : GUIDE_ELEMENT_TIME_RANGE_NOT_CLICKED_OPTIONS;
        },
        isGuideElementsLoading () {
            return this.guideElementsActivityAggStatus === LOADING_STATES.LOADING;
        },
        guidePickerOptions () {
            const guideOptions = getEntitiesByAppSelection(this.rule.app, this.computedData.guides, this.config).filter(
                (guide) => {
                    const isResourceCenter = get(guide, 'attributes.resourceCenter') && guide.state !== 'public';
                    const isP2Guide = get(guide, 'attributes.type') === 'building-block';

                    return !isResourceCenter && isP2Guide;
                }
            );

            if (
                this.rule.guideId &&
                this.rule.guideElementId &&
                !guideOptions.find((guide) => guide.guideId === this.rule.guideId)
            ) {
                guideOptions.push({
                    id: this.rule.guideId,
                    name: `Deleted Guide (${this.rule.guideId})`,
                    state: 'deleted'
                });
            }

            return guideOptions;
        },
        guideStepOptions () {
            const { steps } = this.rule.guide;
            const options = steps.map((step, index) => {
                return {
                    guideStepId: step.id,
                    name: step.name ? `${step.name} (Step ${index + 1})` : `Step ${index + 1}`
                };
            });
            if (this.rule.guideStepId && !options.find((option) => option.guideStepId === this.rule.guideStepId)) {
                options.push({
                    guideStepId: this.rule.guideStepId,
                    name: `Deleted Step (${this.rule.guideStepId})`
                });
            }

            return options;
        },
        guideElementOptions () {
            return this.filterElementsByStep(this.rule.guideStepId);
        },
        selectedGuideStep () {
            return this.guideStepOptions.find(
                (guideStepOption) => guideStepOption.guideStepId === this.rule.guideStepId
            );
        },
        selectedGuideElement () {
            return this.guideElements.find((guideElement) => guideElement.guideElementId === this.rule.guideElementId);
        },
        noGuideDataText () {
            return getNoResourcesFoundText({
                rule: this.rule,
                isMultiApp: this.config.usesMultiApp,
                resourceType: 'guide'
            });
        },
        showValueComponent () {
            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(operatorValue && rule.time && rule.time !== 'ever');
        },
        valueComponent () {
            if (this.rule.time === 'since') {
                return getSingleDateConfig(this.rule, 'value');
            }

            if (this.rule.time === 'withinlast') {
                return getRelativeDateRangeConfig(this.rule, 'value');
            }

            if (
                this.rule.operator.value === 'clicked' &&
                (this.rule.time === 'atmost' || this.rule.time === 'atleast')
            ) {
                const numberConfig = getNumberConfig(this.rule);

                return { ...numberConfig, props: { ...numberConfig.props, min: 1, labels: { suffix: 'times' } } };
            }

            return;
        },
        guide () {
            return this.rule.guide;
        }
    },
    watch: {
        async guide (newGuide) {
            await this.getGuideWithContent(newGuide);
        }
    },
    created () {
        this.setupGuideElementsActivityAgg();
        this.getGuideWithContent(this.rule.guide);
    },
    methods: {
        async getGuideWithContent (guide) {
            this.guideWithContent = guide ? await getContent(guide) : undefined;
        },
        timeLabelObject (timeString) {
            return this.timeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleOperatorSelection ($event) {
            const fields = [{ field: 'operator', value: $event }];

            // handle switching to `not clicked` where `at least / at most` not supported
            if ($event.value === 'notclicked' && (this.rule.time === 'atleast' || this.rule.time === 'atmost')) {
                fields.push({ field: 'time', value: 'ever' });
            }

            this.$emit('input', fields);
        },
        handleTimeSelection ($event) {
            const { value } = $event;
            const fields = [{ field: 'time', value }];
            if (value === 'withinlast') {
                fields.push({ field: 'value', value: { count: 30, granularity: 'days' } });
            } else {
                fields.push({ field: 'value', value: 1 });
            }
            this.$emit('input', fields);
        },
        setupGuideElementsActivityAgg () {
            this.guideElementsActivityAggStatus = LOADING_STATES.LOADING;
            const agg$ = connectAggregationToState(
                guideElementsActivityAgg,
                withComponent({
                    guideId: 'rule.guide.id',
                    currentIds: 'currentIds',
                    filters: 'filters'
                })
            )({ component: this }, { debounceRequests: true });
            agg$.subscribe((response) => {
                const { status, value } = response;
                switch (status) {
                    case LOADING_STATES.LOADING: {
                        this.guideElementsActivityAggStatus = status;
                        this.guideElements = [];
                        break;
                    }
                    case LOADING_STATES.REJECTED:
                    case LOADING_STATES.RESOLVED: {
                        const guideElementsActivity = processGuideElementsAggDropdown(value, this.currentElementData);

                        if (
                            this.rule.guideElementId &&
                            !guideElementsActivity.find(
                                (element) => element.guideElementId === this.rule.guideElementId
                            )
                        ) {
                            guideElementsActivity.push({
                                guideElementId: this.rule.guideElementId,
                                guideStepId: this.rule.guideStepId,
                                name: `Deleted Element (${this.rule.guideElementId})`
                            });
                        }

                        this.guideElementsActivityAggStatus = guideElementsActivity.length
                            ? LOADING_STATES.RESOLVED
                            : LOADING_STATES.EMPTY;
                        this.guideElements = guideElementsActivity;
                        break;
                    }
                }
            });
        },
        async onGuideSelect (guide) {
            const fields = [
                { field: 'guide', value: guide },
                { field: 'guideElementId', value: null },
                { field: 'guideElementName', value: null },
                { field: 'guideDeleted', value: false }
            ];

            // Set the guide step for guides with only 1 step
            if (guide.steps.length === 1 && this.rule.guideStepId !== guide.steps[0].id) {
                fields.push({ field: 'guideStepId', value: guide.steps[0].id });
            } else if (guide.steps.length !== 1) {
                fields.push({ field: 'guideStepId', value: null });
            }

            this.$emit('input', fields);
        },
        onGuideStepSelect ({ guideStepId }) {
            const fields = [
                { field: 'guideStepId', value: guideStepId },
                { field: 'guideElementId', value: null },
                { field: 'guideElementName', value: null }
            ];
            this.$emit('input', fields);
        },
        onGuideElementSelect ({ guideElementId, name }) {
            const fields = [
                { field: 'guideElementId', value: guideElementId },
                { field: 'guideElementName', value: name }
            ];
            this.$emit('input', fields);
        },
        filterElementsByStep (stepId) {
            if (!stepId || stepId === '*') {
                return this.guideElements;
            }

            return this.guideElements.filter((element) => element.guideStepId === stepId);
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-element-rule {
    display: contents;

    .guide-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
