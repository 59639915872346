import { formatTimeValue } from '../moment';

export function parseFeatureFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'clicked':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildFeatureClickedWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atleast') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '<=');

                break;
            }

            filterString = buildFeatureClickedEverFilterString(filterIndex, '>');

            break;
        case 'lastclicked':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildFeatureClickedWithinLastFilterString(filterIndex, count, granularity, '>=');
                break;
            }

            if (time === '!withinlast') {
                const { count, granularity } = value;
                filterString = buildFeatureClickedNotWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'before') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '<=');

                break;
            }

            if (time === 'between') {
                filterString = buildFeatureClickedBetweenFilterString(filterIndex, value.first, value.last);

                break;
            }

            if (time === 'atleast') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'atmost') {
                filterString = buildFeatureClickedAtLeastOrMostFilterString(filterIndex, value, '<=');

                break;
            }

            filterString = buildFeatureClickedEverFilterString(filterIndex, '>');

            break;
        case 'notclicked':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildFeatureClickedWithinLastFilterString(filterIndex, count, granularity, '<');

                break;
            }

            if (time === 'since') {
                filterString = buildFeatureClickedSinceFilterString(filterIndex, value, '<');

                break;
            }

            filterString = buildFeatureClickedEverFilterString(filterIndex, '<=');

            break;
    }

    return { filter: filterString };
}

export function buildFeatureClickedEverFilterString (filterIndex, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildFeatureClickedWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 * countValue},"${timeUnit}")`;
}

export function buildFeatureClickedNotWithinLastFilterString (filterIndex, countValue, timeUnit) {
    return `(feature${filterIndex}_itemSeen.lastUsed>=now()||feature${filterIndex}_itemSeen.lastUsed<=dateAdd(now(),-${countValue},"${timeUnit}"))`;
}

export function buildFeatureClickedSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.lastUsed${comparisonSymbol}date("${formatTimeValue(timeValue)}")`;
}

export function buildFeatureClickedBetweenFilterString (filterIndex, first, last) {
    return `(feature${filterIndex}_itemSeen.lastUsed>=date("${formatTimeValue(
        first
    )}")&&feature${filterIndex}_itemSeen.lastUsed<=date("${formatTimeValue(last)}"))`;
}

export function buildFeatureClickedAtLeastOrMostFilterString (filterIndex, countValue, comparisonSymbol) {
    return `feature${filterIndex}_itemSeen.count${comparisonSymbol}${countValue}`;
}
