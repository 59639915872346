import { formatTimeValue } from '../moment';

export function parseTrackTypeFilterPipeline ({ operator, time, value, filterIndex }) {
    let filterString;

    switch (operator) {
        case 'used':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildTrackTypeSeenWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === 'since') {
                filterString = buildTrackTypeSeenSinceFilterString(filterIndex, value, '>=');

                break;
            }
            // time === 'ever'
            filterString = buildTrackTypeSeenEverFilterString(filterIndex, '>');

            break;
        case 'notused':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildTrackTypeSeenWithinLastFilterString(filterIndex, count, granularity, '<');

                break;
            }

            if (time === 'since') {
                filterString = buildTrackTypeSeenSinceFilterString(filterIndex, value, '<');

                break;
            }

            // time === 'ever'
            filterString = buildTrackTypeSeenEverFilterString(filterIndex, '<=');

            break;
        case 'lastused':
            if (time === 'withinlast') {
                const { count, granularity } = value;
                filterString = buildTrackTypeSeenWithinLastFilterString(filterIndex, count, granularity, '>=');

                break;
            }

            if (time === '!withinlast') {
                const { count, granularity } = value;
                filterString = buildTrackTypeSeenNotWithinLastFilterString(filterIndex, count, granularity);

                break;
            }

            if (time === 'since') {
                filterString = buildTrackTypeSeenSinceFilterString(filterIndex, value, '>=');

                break;
            }

            if (time === 'before') {
                filterString = buildTrackTypeSeenSinceFilterString(filterIndex, value, '<=');

                break;
            }

            // §time === 'between'
            filterString = buildTrackTypeSeenBetweenFilterString(filterIndex, value.first, value.last);

            break;
    }

    return { filter: filterString };
}

export function buildTrackTypeSeenEverFilterString (filterIndex, comparisonSymbol) {
    return `trackType${filterIndex}_itemSeen.count${comparisonSymbol}0`;
}

export function buildTrackTypeSeenWithinLastFilterString (filterIndex, countValue, timeUnit, comparisonSymbol) {
    return `trackType${filterIndex}_itemSeen.lastUsed${comparisonSymbol}dateAdd(now(),${-1 *
        countValue},"${timeUnit}")`;
}

export function buildTrackTypeSeenNotWithinLastFilterString (filterIndex, countValue, timeUnit) {
    return `(trackType${filterIndex}_itemSeen.lastUsed>=now()||trackType${filterIndex}_itemSeen.lastUsed<=dateAdd(now(),-${countValue},"${timeUnit}"))`;
}

export function buildTrackTypeSeenSinceFilterString (filterIndex, timeValue, comparisonSymbol) {
    return `trackType${filterIndex}_itemSeen.lastUsed${comparisonSymbol}date("${formatTimeValue(timeValue)}")`;
}

export function buildTrackTypeSeenBetweenFilterString (filterIndex, first, last) {
    return `(trackType${filterIndex}_itemSeen.lastUsed>=date("${formatTimeValue(
        first
    )}")&&trackType${filterIndex}_itemSeen.lastUsed<=date("${formatTimeValue(last)}"))`;
}
