<template>
    <div class="resource-center-details">
        <div class="resource-center-details--row">
            <resource-center-details-content
                v-pendo-loading:feather="loading"
                class="resource-center-details--content"
                :resource-center="resourceCenter"
                :can-edit-r-c-content="canEditRCContent"
                :active-state="activeState"
                :localization-enabled="localizationEnabled"
                @showModuleChooser="$emit('onShowModuleChooser')" />
        </div>
    </div>
</template>

<script>
import { PendoLoading } from '@pendo/components';
import ResourceCenterDetailsContent from '@/components/resource-center/ResourceCenterDetailsContent';
import { mapGetters } from 'vuex';
import get from 'lodash/get';
import { canEditRCContent } from '@/utils/rc-permissions';

export default {
    name: 'ResourceCenterDetails',
    components: {
        ResourceCenterDetailsContent
    },
    directives: {
        PendoLoading
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        resourceCenter: {
            type: Object,
            required: true
        },
        activeState: {
            type: String,
            required: true
        }
    },
    emits: ['onShowModuleChooser'],
    computed: {
        ...mapGetters({
            appFromGuide: 'apps/appFromGuide' // how this value is determined and populated for training subscription apps is TBD
        }),
        rcApp () {
            return this.appFromGuide(this.resourceCenter.draft.homeView);
        },
        localizationEnabled () {
            return get(this.rcApp, 'applicationFlags.guideLocalization', false);
        },
        canEditRCContent () {
            return canEditRCContent(this.rcApp.id);
        }
    }
};
</script>

<style lang="scss">
.resource-center-details {
    display: grid;
    grid-gap: 32px;

    .resource-center-details {
        &--row {
            display: grid;
            grid-gap: 32px;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }
    }
}
</style>
