<template>
    <div
        v-if="isVisible"
        class="segment-editor">
        <div
            v-if="config.guideTargeting && usesLastVisitSchema"
            class="segment-editor--illegal-rule-warning">
            <pendo-alert type="error">
                Segments containing the “Last Visit” rule cannot be used to target guides. Please remove this rule
                before saving the segment.
                <a
                    target="_blank"
                    href="https://adoptpartners.pendo.io/hc/en-us/articles/360046163511-Segments">Learn More</a>.
            </pendo-alert>
        </div>

        <span
            v-for="(rule, index) in rules"
            :key="`segment-rule-${index}`"
            class="rules-card">
            <pendo-card
                :title="`Rule ${index + 1}`"
                body-min-height="0"
                class="segment-rule">
                <template #headerRight>
                    <pendo-button
                        v-if="rules.length > 1 && !readOnly"
                        icon="trash-2"
                        size="mini"
                        type="tertiary"
                        class="segment-rule--remove"
                        @click="removeRule(index)" />
                </template>
                <div
                    v-for="(orRule, orIndex) in rule"
                    :key="orRule.id"
                    class="segment-rule--row">
                    <rule-editor
                        :config="config"
                        :computed-data="sharedData"
                        :index="orIndex"
                        :removable="rule.length > 1"
                        :rule="orRule"
                        :operators="operators"
                        :nested-segment-count="nestedSegmentCount"
                        :has-parent-segments="hasParentSegments"
                        :segment="segment"
                        :has-mobile-apps="hasMobileApps"
                        @remove="removeRule(index, orIndex)"
                        @update:showCsvUploadWarning="$emit('showCsvUploadWarning', $event)"
                        @input="updateOrRule($event, index, orIndex)" />
                </div>
                <pendo-button
                    v-if="!readOnly"
                    theme="app"
                    type="link"
                    prefix-icon="plus-circle"
                    label="OR"
                    class="segment-builder--create-or-rule"
                    @click="addOrRule(index)" />
            </pendo-card>
            <pendo-divider
                v-if="rules.length > 1 && rules.length !== index + 1"
                height="56px"
                stroke="#DADCE5">
                <template #start>
                    <div class="segment-editor--rule-seperator">
                        AND
                    </div>
                </template>
            </pendo-divider>
        </span>
        <div
            v-if="rules.length"
            class="segment-editor--and-separator">
            <pendo-button
                v-if="!readOnly"
                theme="app"
                type="link"
                prefix-icon="plus-circle"
                label="AND"
                class="segment-editor--create-and-rule"
                @click="addRule" />
        </div>
    </div>
</template>

<script>
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import { PendoAlert, PendoCard, PendoDivider, PendoButton } from '@pendo/components';
import RuleEditor from './RuleEditor.vue';
import { SEGMENT_OPERATORS, SEGMENT_RULE_LIMIT } from '@/components/segment-builder/constants/segments';
import {
    generateRule,
    getPollsForGuide,
    isMobileDataSchema,
    isMobilePlatform
} from '@/components/segment-builder/utils/utils';
import { getRulesFromSegment, isNestedSegmentRule } from '@/components/segment-builder/utils/segments';
import { readOnlyKey } from '@/components/segment-builder/utils/keys';

export default {
    name: 'SegmentEditor',
    components: {
        PendoAlert,
        PendoCard,
        PendoDivider,
        PendoButton,
        RuleEditor
    },
    provide () {
        // define the property to make the injected value reactive
        const readOnly = {};
        Object.defineProperty(readOnly, 'value', {
            enumerable: true,
            get: () => this.readOnly
        });

        return {
            [readOnlyKey]: readOnly
        };
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        segment: {
            type: Object,
            required: false,
            default: () => null
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            operators: SEGMENT_OPERATORS,
            rules: []
        };
    },
    computed: {
        apps () {
            return this.config?.data?.apps || [];
        },
        schemas () {
            return this.config?.data?.schemas || [];
        },
        guidesMap () {
            return this.config?.data?.guides?.map || {};
        },
        guides () {
            return this.config?.data?.guides?.filteredList?.(this.guidesMap) || Object.values(this.guidesMap);
        },
        guidesWithPolls () {
            return this.guides.filter((guide) => getPollsForGuide(guide).length);
        },
        rcModulesMap () {
            return this.config?.data?.rcModules?.map || {};
        },
        rcModules () {
            return this.config?.data?.rcModules?.filteredList?.(this.rcModulesMap) || Object.values(this.rcModulesMap);
        },
        features () {
            return this.config?.data?.features?.filteredList?.(this.featuresMap) || Object.values(this.featuresMap);
        },
        featuresMap () {
            return this.config?.data?.features?.map || {};
        },
        workflowsMap () {
            return this.config?.data?.workflows?.map || {};
        },
        workflows () {
            return this.config?.data?.workflows?.filteredList?.(this.workflowsMap) || Object.values(this.workflowsMap);
        },
        draftResourceCenters () {
            return (
                this.config?.data?.draftResourceCenters?.filteredList?.(this.draftResourceCentersMap) ||
                Object.values(this.draftResourceCentersMap)
            );
        },
        draftResourceCentersMap () {
            return this.config?.data?.draftResourceCenters?.map || {};
        },
        parentSegments () {
            if (this.segment?.id) {
                return this.segments.filter((segment) => segment.compound?.includes(this.segment.id)) || [];
            }

            return [];
        },
        hasParentSegments () {
            return this.parentSegments.length > 0;
        },
        nestedSegmentCount () {
            return reduce(
                this.rules,
                (sum, rule) => {
                    const innerSum = reduce(
                        rule,
                        (orSum, orRule) => {
                            if (isNestedSegmentRule(orRule)) {
                                return orSum + 1;
                            }

                            return orSum;
                        },
                        0
                    );

                    return sum + innerSum;
                },
                0
            );
        },
        segmentsMap () {
            return this.config?.data?.segments?.map || {};
        },
        segments () {
            return this.config?.data?.segments?.filteredList?.(this.segmentsMap) || Object.values(this.segmentsMap);
        },
        pagesMap () {
            return this.config?.data?.pages?.map || {};
        },
        pages () {
            return this.config?.data?.pages?.filteredList?.(this.pagesMap) || Object.values(this.pagesMap);
        },
        appsMap () {
            return keyBy(this.appOptions, 'id');
        },
        appOptions () {
            const defaultOption = [
                {
                    displayName: 'All Applications',
                    id: null,
                    resourceCenterId: null
                }
            ];

            const appOptions = this.apps.map((app) => {
                return {
                    ...app,
                    resourceCenterId: get(this.draftResourceCentersMap, `${app.id}.homeView.id`)
                };
            });

            return defaultOption.concat(appOptions);
        },
        sharedData () {
            return pick(this, [
                'appsMap',
                'appOptions',
                'features',
                'featuresMap',
                'filteredSchemaList',
                'segments',
                'segmentsMap',
                'pages',
                'pagesMap',
                'guides',
                'guidesMap',
                'guidesWithPolls',
                'draftResourceCenters',
                'draftResourceCentersMap',
                'rcModules',
                'rcModulesMap',
                'workflows',
                'workflowsMap',
                'trackTypes',
                'trackTypesMap'
            ]);
        },
        filteredSchemaList () {
            return this.schemas
                .filter((schema) => {
                    const isPendoGroup = schema.group === 'pendo';
                    const excludePendoGroupMetadataList = [
                        'visitor_pendo_designerenabled',
                        'visitor_pendo_donotprocess'
                    ];

                    if (!this.config.usesMultiApp && schema.id === 'application') {
                        return false;
                    }

                    if (schema.id === 'workflow' && (!this.config.hasWorkflow || this.workflows.length === 0)) {
                        return false;
                    }

                    if (schema.id === 'resourceCenter' && !this.config.hasResourceCenter) {
                        return false;
                    }

                    if (schema.schema === 'poll' && this.guidesWithPolls.length <= 0) {
                        return false;
                    }

                    if (
                        schema.schema === 'segment' &&
                        (this.segments.length === 0 ||
                            (this.segments.length === 1 && this.segment && this.segment.id === this.segments[0].id))
                    ) {
                        return false;
                    }

                    if (isPendoGroup && excludePendoGroupMetadataList.includes(schema.id)) {
                        return false;
                    }

                    if (schema.schema === 'trackType' && !this.config.useTrackTypes) {
                        return false;
                    }

                    if ((!this.config.useTrackTypes || !this.hasMobileApps) && isMobileDataSchema(schema)) {
                        return false;
                    }

                    return true;
                })
                .map((option) => {
                    return {
                        ...option,
                        icon: {
                            type: this.getSchemaIcon(option.schema)
                        }
                    };
                });
        },
        usesLastVisitSchema () {
            return this.rules.some((orRules) =>
                orRules.some((orRule) => {
                    return orRule.schema.type === 'metadata.auto.lastvisit';
                })
            );
        },
        trackTypes () {
            return (
                this.config?.data?.trackTypes?.filteredList?.(this.trackTypesMap) || Object.values(this.trackTypesMap)
            );
        },
        trackTypesMap () {
            return this.config?.data?.trackTypes?.map || {};
        },
        hasMobileApps () {
            return this.apps.some((app) => isMobilePlatform(app.platform));
        }
    },
    watch: {
        rules (newRules) {
            this.$emit('update-rules', newRules);
        },
        nestedSegmentCount (newCount) {
            this.$emit('update-nested-segment-count', newCount);
        }
    },
    mounted () {
        this.initRules();
    },
    methods: {
        initRules () {
            if (this.segment) {
                this.rules = getRulesFromSegment(this.segment).map(this.mapIncomingGuideRules);
            } else {
                const schema = get(this, 'filteredSchemaList[0]');

                this.rules = [[generateRule(this.operators, schema)]];
            }
        },
        removeRule (index, orIndex) {
            if (orIndex == null) {
                this.rules.splice(index, 1);
            } else {
                this.rules[index].splice(orIndex, 1);
            }
        },
        updateOrRule (updates, ruleIndex, orIndex) {
            const rule = this.rules[ruleIndex][orIndex];

            if (updates.id) {
                // if update includes an id, the user is selecting a
                // different "schema" -> metadata, page, feature, guide, etc.
                // reset existing rule and start fresh
                this.$set(this.rules[ruleIndex], orIndex, { key: rule.key, ...updates });
            } else {
                // update individual keys on the existing rule
                Object.entries(updates).forEach(([key, value]) => {
                    if (value === undefined) {
                        this.$delete(rule, key);
                    } else {
                        this.$set(rule, key, value);
                    }
                });
            }
        },
        addOrRule (index) {
            const currentRuleSet = this.rules[index];
            const previousRule = currentRuleSet[currentRuleSet.length - 1];
            if (this.nestedSegmentCount >= SEGMENT_RULE_LIMIT && previousRule.schema.type === 'segment') {
                currentRuleSet.push(this.generateRule());
            } else {
                const newRule = this.duplicateRule(previousRule);
                if (newRule.tagId) {
                    delete newRule.tagId;
                    delete newRule.value;
                }
                currentRuleSet.push(newRule);
            }
        },
        addRule () {
            this.rules.push([this.generateRule()]);
        },
        generateRule (schema = get(this, 'filteredSchemaList[0]')) {
            return generateRule(this.operators, schema);
        },
        duplicateRule (rule) {
            return { ...cloneDeep(rule), id: uuid() };
        },
        getSchemaIcon (schema) {
            const icon = {
                poll: 'list',
                time: 'calendar',
                guide: 'map',
                guideElement: 'guideElement',
                aggregation: 'user',
                page: 'file',
                feature: 'feature',
                application: 'activity',
                workflow: 'adopt-workflows',
                segment: 'pie-chart',
                trackType: 'zap'
            }[schema];

            return icon || 'user';
        },
        mapIncomingGuideRules (rules) {
            // Note: See `migration` util to manage further mappings for legacy rules.
            return rules.map((rule) => {
                if (rule.guideId) {
                    rule.guide = this.guidesMap[rule.guideId];
                    rule.guideDeleted = !rule.guide;
                    delete rule.guideId;
                }

                if (rule.pollId) {
                    rule.poll = getPollsForGuide(rule.guide).find((poll) => poll.id === rule.pollId);
                    delete rule.pollId;
                }

                if (rule.segmentId) {
                    rule.segment = this.segmentsMap[rule.segmentId];
                    rule.segmentDeleted = !rule.segment;
                    rule.isSegmentShared = get(rule.segment, 'shared', false);
                    delete rule.segmentId;
                }

                if (rule.pageId) {
                    rule.page = this.pagesMap[rule.pageId];
                    rule.pageDeleted = !rule.page;
                    rule.isPageShared = get(rule.page, 'trainingSettings.enabled', false);
                    rule.isCustomPage = get(rule.page, 'rootVersionId', null);
                    delete rule.pageId;
                }

                if (rule.featureId) {
                    rule.feature = this.featuresMap[rule.featureId];
                    rule.featureDeleted = !rule.feature;
                    delete rule.featureId;
                }

                if (rule.workflowId) {
                    rule.workflow = this.workflowsMap[rule.workflowId];
                    rule.workflowDeleted = !rule.workflow;
                    delete rule.workflowId;
                }

                if (get(rule, 'schema.icon') && get(rule, 'schema.schema')) {
                    rule.schema.icon = {
                        type: this.getSchemaIcon(rule.schema.schema)
                    };
                }

                if (rule.trackTypeId) {
                    rule.trackType = this.trackTypesMap[rule.trackTypeId];
                    rule.trackTypeDeleted = !rule.trackType;
                    rule.isTrackTypeShared = get(rule.trackType, 'trainingSettings.enabled', false);
                    delete rule.trackTypeId;
                }

                // Careful, `selectedApp: { id: null }` is VALID as "all applications"
                // So check for the existence of "selectedApp" instead
                if (get(rule, 'schema.selectedApp')) {
                    const app = this.appOptions.find((opt) => opt.id === rule.schema.selectedApp.id);
                    rule.app = app;
                    rule.appDeleted = !rule.app;
                    delete rule.schema.selectedApp;
                }

                return rule;
            });
        }
    }
};
</script>

<style lang="scss">
.segment-editor {
    overflow-y: auto;
    max-width: 1140px;

    &--illegal-rule-warning {
        margin-bottom: 2em;
    }

    .segment-rule {
        &--remove {
            & svg {
                stroke: $gray-lighter-2;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &--selected-label {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            .pendo-icon {
                margin-right: 0.5em;
            }
        }
    }

    &--rule-seperator {
        color: $gray-lighter-2;
        font-weight: 600;
        font-size: 14px;
    }

    &--and-separator {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 1em;

        // full width button for the line around "+ AND"
        button {
            width: 100%;
            overflow: hidden;
        }

        button:after {
            background-color: $gray-lighter-5;
            content: '';
            display: inline-block;
            height: 1px;
            position: relative;
            vertical-align: middle;
            width: 100%;
        }

        button:after {
            left: 1em;
        }
    }
}
</style>
