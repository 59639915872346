<template>
    <pendo-modal
        height="auto"
        :visible="visible"
        :title="title"
        @close="closeModal">
        <div class="item-delete-warning">
            <span v-if="!impactedItems.length">
                You won't be able to recover it later. Are you sure you want to delete this {{ kind }}?
            </span>
            <div v-if="impactedItems.length">
                <span> We have detected the following {{ impactedKind }}(s) rely on this {{ kind }}. </span>
                <ul class="item-delete-warning--item-list">
                    <li
                        v-for="item in impactedItems"
                        :key="item.id">
                        {{ item.name }}
                    </li>
                </ul>
                <p v-if="preventDeleteWithImpactedItems">
                    If you wish to delete this {{ kind }}, please remove the {{ kind }} from the listed
                    {{ impactedKind }}(s) first.
                </p>
            </div>
        </div>
        <template #footer>
            <pendo-button
                type="secondary"
                theme="app"
                label="Cancel"
                @click="closeModal" />
            <pendo-button
                :label="`Delete ${kind}`"
                :class="`item-delete--${kind}`"
                :disabled="preventDeleteWithImpactedItems && !!impactedItems.length"
                type="danger"
                theme="app"
                @click="deleteGuide(impactedItems.length)" />
        </template>
    </pendo-modal>
</template>

<script>
import { PendoButton, PendoNotification, PendoModal } from '@pendo/components';
import { mapActions, mapState } from 'vuex';
import get from 'lodash/get';

export default {
    name: 'DeleteGuideModal',
    components: {
        PendoButton,
        PendoModal
    },
    props: {
        impactedItems: {
            type: Array,
            default: () => []
        },
        preventDeleteWithImpactedItems: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: false
        },
        guide: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['close'],
    data () {
        return {
            kind: 'guide',
            impactedKind: 'segment'
        };
    },
    computed: {
        ...mapState({
            guideError: (state) => state.guides.error
        }),
        title () {
            if (this.impactedItems.length) {
                return 'Segment Dependency Detected';
            }

            return 'Delete Guide?';
        },
        errorMessages () {
            const name = get(this.guide, 'name', '');

            return {
                GENERIC: `Failed to remove guide, "${name}".\nPlease try again. If the problem persists, please contact <a href="mailto:help@pendo.io">help@pendo.io.</a>`,
                RC_ATTACHED: `The guide "${name}" is associated with one or more modules in the Resource Center. To delete it, you first need to remove it from these modules.`
            };
        }
    },
    methods: {
        ...mapActions({
            _deleteGuide: 'guides/delete',
            updateActiveSegmentId: 'filters/updateActiveSegmentId'
        }),
        closeModal () {
            this.$emit('close');
        },
        getRCLink () {
            const errData = get(this, 'guideError.response.data', {});
            const originId = get(errData, '[0].originId', '');
            const guideIds = (originId || '').split('@');

            return guideIds.length > 1
                ? `<p class="toast-padding-delete-guide-rc-dep"><a href="/resource-center/${guideIds[1]}/draft">Go to the Resource Center</a></p>`
                : '';
        },
        async deleteGuide (deleteBreaksSegment) {
            let keys;
            let errData;
            let errStatus;
            let rcKeyCheck;

            await this._deleteGuide({ guide: this.guide });

            this.closeModal();

            if (this.guideError) {
                keys = ['moduleId', 'originId', 'name'];
                errData = get(this, 'guideError.response.data', undefined);
                errStatus = get(this, 'guideError.response.status', undefined);
                rcKeyCheck = Array.isArray(errData)
                    ? errData.some((obj) => keys.some((key) => Object.keys(obj).includes(key)))
                    : false;

                const rcAttachedLink = this.getRCLink();

                PendoNotification({
                    type: 'error',
                    title: 'Unable to delete guide',
                    duration: 0,
                    dangerouslyUseHTMLString: true,
                    message:
                        errStatus === 409 && rcKeyCheck
                            ? this.errorMessages.RC_ATTACHED + rcAttachedLink
                            : this.errorMessages.GENERIC
                });
            }

            if (deleteBreaksSegment) {
                this.updateActiveSegmentId({ id: 'everyone' });
            }

            if (!this.guideError) {
                await this.$router.push('/guides').catch(() => {});
            }
        }
    }
};
</script>

<style lang="scss">
.item-delete-warning {
    &--item-list {
        padding: 0;

        li {
            list-style-type: none;
            font-weight: bold;
        }
    }
}
</style>
