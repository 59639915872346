<template>
    <pendo-input
        :class="{
            'search-input--full-width': fullWidth
        }"
        :placeholder="placeholder"
        :value="value"
        v-bind="$attrs"
        data-cy="search"
        class="search-input"
        @input="$emit('input', $event)">
        <template #prefix>
            <pendo-icon
                type="search"
                stroke="#2a2c35"
                size="16" />
        </template>
        <template #suffix>
            <slot name="suffix" />
        </template>
    </pendo-input>
</template>

<script>
import { PendoInput, PendoIcon } from '@pendo/components';

export default {
    name: 'Search',
    components: {
        PendoInput,
        PendoIcon
    },
    props: {
        value: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: 'Search'
        },
        fullWidth: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    data () {
        return {
            focused: false
        };
    }
};
</script>

<style lang="scss" scoped>
.search-input {
    max-width: 300px;
    min-width: 300px;

    &.search-input--full-width {
        max-width: 100%;
    }
}
</style>
