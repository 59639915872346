<template>
    <div>
        <pendo-multiselect
            ref="segmentChooser"
            :value="activeSegment"
            :options="segmentsList"
            :disabled="disabled"
            :full-width="fullWidth"
            :max-menu-height="370"
            class="segment-filter"
            label-key="name"
            @select="onSegmentChange">
            <template #selectedLabel="{ selectedLabel }">
                <div class="segment-filter--label">
                    <pendo-icon
                        type="user"
                        size="14" />
                    <span>
                        {{ selectedLabel }}
                    </span>
                </div>
            </template>
            <template #optionGroup="{ option }">
                <span class="segment-filter--group-label">
                    {{ option.label }}
                </span>
            </template>
            <template #option="{ option }">
                <div
                    v-pendo-tooltip="{
                        arrow: true,
                        placement: 'top',
                        hideOnTargetClick: false,
                        content: getTooltipContent(option)
                    }"
                    :class="{
                        'segment-filter--broken-segment': option.broken,
                        'segment-filter--disabled-segment': option.disabledWithReason,
                        'custom-segment': !immutableSegmentIdsMap[option.id]
                    }"
                    class="segment-filter--option">
                    <span class="segment-filter--option--name">
                        {{ option.name }}
                    </span>
                    <span
                        v-if="!immutableSegmentIdsMap[option.id]"
                        class="segment-filter--option--actions">
                        <pendo-icon-button
                            icon="edit-2"
                            :disabled="!!option.createdByApi"
                            :tooltip="option.createdByApi ? 'You must use the API to edit API-created segments' : ''"
                            class="segment-actions--edit"
                            @click.stop="editSegment({ id: option.id })" />
                        <pendo-button
                            :class="{ 'active-segment--delete': activeSegment.id === option.id }"
                            icon="trash-2"
                            size="mini"
                            type="tertiary"
                            theme="app"
                            class="segment-actions--delete"
                            @click.stop="confirmDeleteSegment({ id: option.id })" />
                    </span>
                </div>
            </template>
            <template #footer>
                <footer
                    class="segment-filter--footer"
                    data-cy="segment-filter--toggle"
                    @click="openSegmentBuilder">
                    <pendo-icon
                        :inline="true"
                        size="14"
                        type="plus" />
                    <span class="segment-filter--footer--toggle-text">
                        Custom Segment
                    </span>
                </footer>
            </template>
        </pendo-multiselect>
        <app-segment-builder
            v-if="showSegmentBuilder"
            :editing-segment="editingSegment"
            :limit-for-guide-usage="guideTargeting"
            @close="closeSegmentBuilder"
            @save="onSegmentChange" />
        <delete-segment-modal
            :visible="showDeleteSegment"
            :segment-id="segmentIdToDelete"
            @close="hideDeleteSegmentModal" />
    </div>
</template>

<script>
import isString from 'lodash/isString';
import { mapActions, mapGetters, mapState } from 'vuex';
import { PendoIcon, PendoButton, PendoMultiselect, PendoTooltip, PendoIconButton } from '@pendo/components';
import { isSegmentBlacklisted, segmentUsesDeletedGuide, isValidSegmentInContext } from '@/utils/segments';
import { DEFAULT_SEGMENT_IDS_MAP } from '@/constants/segments';
import AppSegmentBuilder from '@/components/segments/builder/AppSegmentBuilder.vue';
import DeleteSegmentModal from '@/components/segments/DeleteSegmentModal.vue';

// <pendo-multiselect> doesn't play nicely with tooltips when `option.disabled`
// `segment.disabledWithReason` is set in computed#segmentsList
export default {
    name: 'SegmentChooser',
    components: {
        AppSegmentBuilder,
        PendoIcon,
        PendoButton,
        PendoMultiselect,
        PendoIconButton,
        DeleteSegmentModal
    },
    directives: {
        PendoTooltip
    },
    props: {
        value: {
            required: true,
            validator: (prop) => isString(prop) || !prop
        },
        disabled: {
            type: Boolean,
            required: true
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        guideTargeting: {
            type: Boolean,
            required: true
        },
        blacklistTypes: {
            type: Array,
            default: () => []
        }
    },
    emits: ['input'],
    data () {
        return {
            isSavingSegment: false,
            segmentHasGuideUnsupportedRule: false,
            immutableSegmentIdsMap: {
                ...DEFAULT_SEGMENT_IDS_MAP,
                everyone: true,
                everyoneExcludingAnonymous: true,
                anonymousVisitorsOnly: true
            },
            isBuilderOpen: false,
            editingSegment: null,
            segmentToDelete: null,
            showDeleteSegment: false
        };
    },
    computed: {
        ...mapState({
            segmentsMap: (state) => state.filters.segmentsMap,
            guideMap: (state) => state.guides.map
        }),
        ...mapGetters({
            segmentsListByGroup: 'filters/segmentsListByGroup',
            schemaList: 'filters/schemaList'
        }),
        segmentsList () {
            const { customList } = this.segmentsListByGroup;

            return [
                {
                    id: 'default',
                    label: 'Default',
                    options: this.defaultSegmentOptions
                },
                {
                    id: 'custom',
                    label: 'Custom',
                    options: customList.map((segment) => {
                        segment.broken = segmentUsesDeletedGuide(segment, this.guideMap);
                        segment.disabled = isSegmentBlacklisted(segment, this.blacklistTypes);
                        segment.disabledWithReason = !isValidSegmentInContext(segment, {
                            guideMap: this.guideMap,
                            usedForGuideTargeting: this.guideTargeting
                        });

                        return segment;
                    })
                }
            ];
        },
        showSegmentBuilder () {
            return Boolean(this.isBuilderOpen && this.schemaList.length);
        },
        activeSegment () {
            return this.segmentsMap[this.value] || { id: 'everyone', name: 'Everyone' };
        },
        defaultSegmentOptions () {
            const {
                everyone,
                everyoneExcludingAnonymous,
                anonymousVisitorsOnly,
                defaultList
            } = this.segmentsListByGroup;
            if (!this.guideTargeting) {
                const defaultEveryoneOptions = [everyone, everyoneExcludingAnonymous, anonymousVisitorsOnly].filter(
                    (option) => !!option
                );

                return defaultEveryoneOptions.concat(defaultList);
            }

            return [everyone].concat(defaultList);
        }
    },
    created () {
        this.fetchVisitorSchema({ forceRefresh: false });
    },
    methods: {
        ...mapActions({
            fetchVisitorSchema: 'filters/fetchVisitorSchema'
        }),
        onSegmentChange ({ id, broken, ...segment }) {
            if (broken) return;

            const segmentValid = isValidSegmentInContext(segment, {
                guideMap: this.guideMap,
                usedForGuideTargeting: this.guideTargeting
            });
            if (!segmentValid) return;

            this.$emit('input', { id });
        },
        getTooltipContent (segment) {
            if (segment.broken) {
                return 'This segment contains a guide rule for a guide that no longer exists.';
            }

            if (segment.disabledWithReason) {
                return 'This segment contains a rule incompatible with guide targeting.';
            }

            return null;
        },
        openSegmentBuilder () {
            this.$refs.segmentChooser.toggleMenu();
            this.isBuilderOpen = true;
        },
        closeSegmentBuilder () {
            this.editingSegment = null;
            this.isBuilderOpen = false;
        },
        editSegment ({ id }) {
            this.editingSegment = this.segmentsMap[id];
            this.openSegmentBuilder();
        },
        confirmDeleteSegment ({ id }) {
            this.segmentIdToDelete = id;
            this.showDeleteSegment = true;
        },
        hideDeleteSegmentModal () {
            this.segmentIdToDelete = '';
            this.showDeleteSegment = false;
        }
    }
};
</script>

<style lang="scss">
.segment-filter {
    &--label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .pendo-icon {
            margin-right: 0.5em;
        }
    }

    &--option {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        &--name {
            margin-right: 0.5em;
        }

        .active-segment--delete,
        &--actions {
            visibility: hidden;
            opacity: 0;

            .pendo-icon svg {
                stroke: $gray-lighter-3;
            }
        }

        &:hover {
            .segment-filter--option--actions {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &--broken-segment {
        background-color: $red-error-bg;
        color: $red-error;
        margin-right: -24px;
        padding-right: 24px;
        margin-left: -16px;
        padding-left: 16px;
    }

    &--disabled-segment {
        color: $gray-lighter-2;
    }

    &--group-label {
        color: $gray-lighter-4;
    }

    &--footer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $gray-lighter-5;
        padding-top: 0.75em;
        padding-bottom: 0.75em;
        cursor: pointer;

        &:hover {
            background-color: $gray-lighter-7;
        }

        &--toggle-text {
            margin-left: 0.5em;
        }
    }
}
</style>
