<template>
    <div class="path-error-banner">
        <pendo-alert type="error">
            {{ message }}
            <span v-if="retriable">
                Please <a @click="$emit('retry')">try again</a>. If the problem persists, please
                <a href="mailto:help@pendo.io">contact us</a>.
            </span>
        </pendo-alert>
    </div>
</template>
<script>
import { PendoAlert } from '@pendo/components';
import { PathError } from '@/utils/paths';
import get from 'lodash/get';

export default {
    name: 'PathErrorBanner',
    components: {
        PendoAlert
    },
    props: {
        error: {
            type: PathError,
            default: undefined
        }
    },
    emits: ['retry'],
    computed: {
        message () {
            return get(this.error, 'message');
        },
        retriable () {
            return get(this.error, 'retriable');
        }
    }
};
</script>
<style lang="scss">
.path-error-banner {
    margin-bottom: 16px;
}
</style>
