<template>
    <div class="guide-rule">
        <!-- guide list picker -->
        <div :class="{ 'guide-undefined': rule.guideDeleted }">
            <pendo-multiselect
                :value="rule.guide"
                :allow-empty="false"
                :options="guidePickerOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="name"
                value-key="id"
                full-width
                placeholder="Select Guide"
                class="guide-rule--guide-select"
                @select="onGuideSelect">
                <template #option="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
                <template #selectedLabel="{ option }">
                    <pendo-guide-status-option
                        tooltip
                        :option="option" />
                </template>
            </pendo-multiselect>
            <rule-warning v-if="rule.guideDeleted">
                The original guide for this rule has been deleted
            </rule-warning>
        </div>
        <operator-picker
            :rule="rule"
            @input="handleGuideOperatorSelection" />
        <pendo-multiselect
            v-if="showTimeSelector"
            :value="guideTimeLabelObject"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="guideTimeRangeOptions"
            class="guide-rule--time-operator"
            :disabled="readOnly"
            @select="handleGuideTimeSelection" />
        <number-input
            v-if="showNumberInputComponent"
            class="rule-editor--item complex-rule number-input--max-height"
            :labels="{ suffix: 'times' }"
            :value="rule.value"
            :min="1"
            @input="$emit('input', $event)" />
        <component
            :is="timeComponent.component"
            v-if="showTimeComponent"
            v-bind="timeComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import get from 'lodash/get';
import { PendoMultiselect, PendoGuideStatusOption } from '@pendo/components';
import OperatorPicker from '../rule-components/OperatorPicker';
import RuleWarning from '../rule-components/RuleWarning';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import DisabledSingleOptionSelect from '../rule-components/DisabledSingleOptionSelect';
import NumberInput from '../rule-components/NumberInput.vue';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import {
    getAbsoluteDateRangeConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import { getEntitiesByAppSelection } from '@/components/segment-builder/utils/utils';
import {
    GUIDE_TIME_RANGE_SEEN_OPTIONS,
    GUIDE_TIME_RANGE_LAST_SEEN_OPTIONS,
    GUIDE_TIME_RANGE_NOT_SEEN_OPTIONS
} from '@/components/segment-builder/constants/segments';

export default {
    name: 'GuideRule',
    components: {
        DisabledSingleOptionSelect,
        OperatorPicker,
        PendoGuideStatusOption,
        PendoMultiselect,
        RuleWarning,
        RelativeDateRange,
        AbsoluteDateRange,
        SingleDate,
        NumberInput
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        guidePickerOptions () {
            return getEntitiesByAppSelection(this.rule.app, this.computedData.guides, this.config);
        },
        guideTimeRangeOptions () {
            const operatorValue = get(this.rule, 'operator.value');

            switch (operatorValue) {
                case 'lastviewed':
                    return GUIDE_TIME_RANGE_LAST_SEEN_OPTIONS;
                case 'notviewed':
                    return GUIDE_TIME_RANGE_NOT_SEEN_OPTIONS;
                case 'viewed':
                default:
                    return GUIDE_TIME_RANGE_SEEN_OPTIONS;
            }
        },
        guideTimeLabelObject () {
            return this.guideTimeRangeOptions.find((opt) => opt.value === this.rule.time);
        },
        showNumberInputComponent () {
            return (
                this.rule.operator.value === 'viewed' && (this.rule.time === 'atmost' || this.rule.time === 'atleast')
            );
        },
        showTimeComponent () {
            if (this.showNumberInputComponent) {
                return false;
            }

            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(rule.guide && operatorValue && rule.time && rule.time !== 'ever');
        },
        timeComponent () {
            if (['withinlast', '!withinlast', 'withinnext', '!withinnext'].includes(this.rule.time)) {
                return getRelativeDateRangeConfig(this.rule);
            }

            if (['between'].includes(this.rule.time)) {
                return getAbsoluteDateRangeConfig(this.rule);
            }

            if (['>=', '<='].includes(this.rule.time)) {
                return getSingleDateConfig(this.rule, 'first');
            }

            return;
        },
        showTimeSelector () {
            const { rule } = this;

            return !!rule.guide;
        }
    },
    methods: {
        handleGuideOperatorSelection ({ value }) {
            const updates = [{ field: 'operator', value }];

            // handle switching to where `at least / at most` not supported
            if (this.rule.time === 'atleast' || this.rule.time === 'atmost') {
                if (value.value === 'lastviewed') {
                    updates.push({ field: 'time', value: '<=' });
                } else {
                    updates.push({ field: 'time', value: 'ever' });
                }
            }

            // handle switching to `seen / not seen` where `between` not supported
            if (value.value !== 'lastviewed' && this.rule.time === 'between') {
                updates.push({ field: 'time', value: 'ever' });
            }

            this.$emit('input', updates);
        },
        handleGuideTimeSelection ($event) {
            const { value } = $event;
            // Clean up fields
            if (value !== 'between') {
                this.$emit('input', { field: 'last', value: undefined });
                if (value !== '>=' && value !== '<=') {
                    this.$emit('input', { field: 'first', value: undefined });
                }
            }

            if (value !== 'withinlast' && value !== '!withinlast') {
                const fields = [
                    { field: 'count', value: undefined },
                    { field: 'granularity', value: undefined }
                ];
                this.$emit('input', fields);
            }

            this.$emit('input', { field: 'time', value });
        },
        onGuideSelect (guide) {
            this.$emit('input', [
                { field: 'guide', value: guide },
                { field: 'guideDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.guide-rule {
    display: contents;

    .guide-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
