import uniq from 'lodash/uniq';
import { operators as o } from '@pendo/aggregations';
import { formatStartAndEndStepsForAgg } from './utils';
import {
    parseSegmentIdForAggregation,
    PRODUCT_AREAS,
    SUB_PRODUCT_AREAS
} from '@/stateless-components/utils/aggregations';
import { identifiedState } from '@/utils/aggregations';

export default {
    name: 'WorkflowTotalMetrics',
    productArea: PRODUCT_AREAS?.ANALYTICS,
    subProductArea: SUB_PRODUCT_AREAS?.WORKFLOWS_JOURNEYS,
    responseFormat: 'rows',
    build: ({ workflow, segmentId, dateRange }) => {
        const { classification, maxDuration, workflowSteps } = workflow;
        const isRecurring = classification === 'recurring';
        const aggTimeSeries = {
            period: 'dayRange',
            first: `date("${dateRange.value.start}")`,
            last: `date("${dateRange.value.end}")`
        };

        const steps = formatStartAndEndStepsForAgg(workflowSteps);

        const getMaxSteps = [
            o.unwind('funnel', { keepEmpty: true }),
            o.group('visitorId', o.groupField('maxStep', { max: 'funnel.steps' }))
        ];

        const appIds = uniq([steps.start.appId, steps.end.appId]);

        return o.pipeline(
            o.sources.singleEvents({ appId: appIds, timeSeries: aggTimeSeries }),
            o.identified(identifiedState(segmentId)),
            o.segment(parseSegmentIdForAggregation(segmentId)),
            o.group('visitorId', o.groupField('funnel', o.funnel([steps.start, steps.end], maxDuration, maxDuration))),
            ...(isRecurring
                ? [
                    o.fork(o.pipeline(...getMaxSteps), o.pipeline(o.cat())),
                    o.join('visitorId'),
                    o.unwind('funnel', { keepEmpty: true })
                ]
                : getMaxSteps),
            o.reduce({
                ...(isRecurring
                    ? {
                        totalAttempts: o.countIf(null, 'funnel.steps != null'),
                        incompleteAttempts: o.countIf(null, 'funnel.steps == 1'),
                        completedAttempts: o.countIf(null, 'funnel.steps == 2')
                    }
                    : {}),
                totalVisitors: o.count('visitorId'),
                neverStartedCount: o.countIf('visitorId', 'maxStep == 0'),
                incompleteCount: o.countIf('visitorId', 'maxStep == 1'),
                completedCount: o.countIf('visitorId', 'maxStep == 2')
            })
        );
    }
};
