import { v4 as uuid } from 'uuid';

export const SEGMENT_RULE_LIMIT = 5;

export const operatorRequiresUnitCountFormat = {
    'withinLast': true,
    '!withinLast': true
};

// keys match `/api/s/_SID_/metadata/:kind/schema` schema obj.schema
const numberOperators = [
    {
        id: uuid(),
        value: '==',
        label: 'equals'
    },
    {
        id: uuid(),
        value: '!=',
        label: 'does not equal'
    },
    {
        id: uuid(),
        value: '>=',
        label: 'greater than or equal to'
    },
    {
        id: uuid(),
        value: '<=',
        label: 'less than or equal to'
    }
];

export const SEGMENT_OPERATORS = {
    guideElement: [
        {
            id: uuid(),
            value: 'clicked',
            label: 'clicked'
        },
        {
            id: uuid(),
            value: 'notclicked',
            label: 'not clicked'
        }
    ],
    integer: numberOperators,
    float: numberOperators,
    time: [
        {
            id: uuid(),
            value: '<=',
            label: 'before',
            multivalue: false
        },
        {
            id: uuid(),
            value: '>=',
            label: 'since',
            multivalue: false
        },
        {
            id: uuid(),
            value: 'between',
            label: 'between',
            multivalue: true
        },
        {
            id: uuid(),
            value: 'withinLast',
            label: 'within last',
            multivalue: true
        },
        {
            id: uuid(),
            value: '!withinLast',
            label: 'not within last',
            multivalue: true
        }
    ],
    string: [
        {
            id: uuid(),
            value: 'contains',
            label: 'contains'
        },
        {
            id: uuid(),
            value: '!contains',
            label: 'does not contain'
        },
        {
            id: uuid(),
            value: '==',
            label: 'equals'
        },
        {
            id: uuid(),
            value: '!=',
            label: 'does not equal'
        },
        {
            id: uuid(),
            value: 'startsWith',
            label: 'starts with'
        },
        {
            id: uuid(),
            value: '!startsWith',
            label: 'does not start with'
        },
        {
            id: uuid(),
            value: 'listContains',
            label: 'csv contains'
        },
        {
            id: uuid(),
            value: '!listContains',
            label: 'csv does not contain'
        }
    ],
    boolean: [
        {
            id: uuid(),
            value: '==',
            unary: true,
            label: 'is true'
        },
        {
            id: uuid(),
            value: '!=',
            unary: true,
            label: 'is false'
        }
    ],
    list: [
        {
            id: uuid(),
            value: 'contains',
            label: 'contains'
        },
        {
            id: uuid(),
            value: '!contains',
            label: 'does not contain'
        }
    ],
    guide: [
        {
            id: uuid(),
            value: 'viewed',
            label: 'seen'
        },
        {
            id: uuid(),
            value: 'lastviewed',
            label: 'last seen'
        },
        {
            id: uuid(),
            value: 'notviewed',
            label: 'not seen'
        }
    ],
    page: [
        {
            id: uuid(),
            value: 'viewed',
            label: 'seen'
        },
        {
            id: uuid(),
            value: 'lastviewed',
            label: 'last seen'
        },
        {
            id: uuid(),
            value: 'notviewed',
            label: 'not seen'
        }
    ],
    segment: [
        {
            id: uuid(),
            value: '==',
            label: 'equals'
        },
        {
            id: uuid(),
            value: '!=',
            label: 'does not equal'
        }
    ],
    feature: [
        {
            id: uuid(),
            value: 'clicked',
            label: 'clicked'
        },
        {
            id: uuid(),
            value: 'lastclicked',
            label: 'last clicked'
        },
        {
            id: uuid(),
            value: 'notclicked',
            label: 'not clicked'
        }
    ],
    poll: [
        {
            id: uuid(),
            value: '==',
            label: 'equals'
        },
        {
            id: uuid(),
            value: '!=',
            label: 'does not equal'
        },
        {
            id: uuid(),
            value: 'contains',
            label: 'contains'
        },
        {
            id: uuid(),
            value: '!contains',
            label: 'does not contain'
        },
        {
            id: uuid(),
            value: 'responded',
            label: 'responded'
        },
        {
            id: uuid(),
            value: 'notresponded',
            label: 'not responded'
        }
    ],
    application: [
        {
            id: uuid(),
            value: 'used',
            label: 'used'
        },
        {
            id: uuid(),
            value: 'notused',
            label: 'not used'
        }
    ],
    workflow: [
        {
            id: uuid(),
            value: 'completed',
            label: 'Completed'
        },
        {
            id: uuid(),
            value: 'incomplete',
            label: 'Incomplete'
        },
        {
            id: uuid(),
            value: 'notStarted',
            label: 'Not Started'
        }
    ],
    workflowNonRS: [
        {
            id: uuid(),
            value: 'completed',
            label: 'Completed'
        },
        {
            id: uuid(),
            value: 'notcompleted',
            label: 'Incomplete or Not Started'
        }
    ],
    trackType: [
        {
            id: uuid(),
            value: 'used',
            label: 'used'
        },
        {
            id: uuid(),
            value: 'notused',
            label: 'not used'
        },
        {
            id: uuid(),
            value: 'lastused',
            label: 'last used'
        }
    ]
};

export const GUIDE_TIME_RANGE_SEEN_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'before', value: '<=' },
    { label: 'since', value: '>=' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'at least', value: 'atleast' },
    { label: 'at most', value: 'atmost' }
];

export const GUIDE_TIME_RANGE_LAST_SEEN_OPTIONS = [
    { label: 'before', value: '<=' },
    { label: 'since', value: '>=' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'between', value: 'between' }
];

export const GUIDE_TIME_RANGE_NOT_SEEN_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'before', value: '<=' },
    { label: 'since', value: '>=' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' }
];

export const PAGE_TIME_RANGE_VIEWED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' },
    { label: 'at least', value: 'atleast' },
    { label: 'at most', value: 'atmost' }
];

export const PAGE_TIME_RANGE_LAST_VIEWED_OPTIONS = [
    { label: 'before', value: 'before' },
    { label: 'since', value: 'since' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'between', value: 'between' }
];

export const PAGE_TIME_RANGE_NOT_VIEWED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const FEATURE_TIME_RANGE_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' },
    { label: 'at least', value: 'atleast' },
    { label: 'at most', value: 'atmost' }
];

export const FEATURE_TIME_RANGE_LAST_CLICKED_OPTIONS = [
    { label: 'before', value: 'before' },
    { label: 'since', value: 'since' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'between', value: 'between' }
];

export const FEATURE_TIME_RANGE_NOT_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const GUIDE_ELEMENT_TIME_RANGE_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' },
    { label: 'at least', value: 'atleast' },
    { label: 'at most', value: 'atmost' }
];

export const GUIDE_ELEMENT_TIME_RANGE_NOT_CLICKED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const APP_USAGE_TIME_RANGE_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const POLL_TIME_RANGE_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'before', value: '<=' },
    { label: 'since', value: '>=' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'between', value: 'between' }
];

export const INPUT_OPTIONS_MAP = {
    PICK_LIST: 'PickList',
    FREE_FORM: 'FreeForm',
    NUMBER_SCALE: 'NumberScale',
    POSITIVE_NEGATIVE: 'PositiveNegative'
};

export const WORKFLOW_TIME_RANGE_COMPLETED_INCOMPLETE_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
    // { label: 'at least', value: 'atleast' },
    // { label: 'at most', value: 'atmost' }
];

export const WORKFLOW_TIME_RANGE_NOT_STARTED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const TRACK_TYPE_TIME_RANGE_USED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const TRACK_TYPE_TIME_RANGE_NOT_USED_OPTIONS = [
    { label: 'ever', value: 'ever' },
    { label: 'within last', value: 'withinlast' },
    { label: 'since', value: 'since' }
];

export const TRACK_TYPE_TIME_RANGE_LAST_USED_OPTIONS = [
    { label: 'before', value: 'before' },
    { label: 'since', value: 'since' },
    { label: 'within last', value: 'withinlast' },
    { label: 'not within last', value: '!withinlast' },
    { label: 'between', value: 'between' }
];

export const DEFAULT_SEGMENT_IDS_MAP = {
    _VIA_DEFAULT_FV0to30: true,
    _VIA_DEFAULT_FV30to60: true,
    _VIA_DEFAULT_FV60to90: true,
    _VIA_DEFAULT_FVover30: true,
    _PENDO_DEFAULT_FV0to30: true,
    _PENDO_DEFAULT_FVover30: true,
    _PENDO_DEFAULT_FV30to60: true,
    _PENDO_DEFAULT_FV60to90: true,
    _PENDO_DEFAULT_BrChrome: true,
    _PENDO_DEFAULT_BrSafari: true,
    _PENDO_DEFAULT_BrMSIE: true
};
