<template>
    <div class="application-rule">
        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            placeholder="Select Usage"
            @select="handleAppUsageOperatorSelection" />
        <pendo-multiselect
            :value="appUsageTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            preselect-first
            :options="appUsageTimeRangeOptions"
            :disabled="readOnly"
            class="application-rule--time-operator"
            placeholder="Select Time"
            @select="handleAppUsageTimeSelection" />
        <single-date
            v-if="rule.time === 'since'"
            class="rule-editor--item"
            :value="rule.value"
            @input="$emit('input', $event)" />
        <relative-date-range
            v-if="rule.time === 'withinlast'"
            class="rule-editor--item complex-rule"
            :value="{ count: rule.value.count, granularity: rule.value.granularity }"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import { SEGMENT_OPERATORS, APP_USAGE_TIME_RANGE_OPTIONS } from '@/components/segment-builder/constants/segments';
import { PendoMultiselect } from '@pendo/components';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import SingleDate from '../rule-components/SingleDate.vue';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';

export default {
    name: 'ApplicationRule',
    components: {
        PendoMultiselect,
        SingleDate,
        RelativeDateRange
    },
    mixins: [ReadOnlyMixin],
    props: {
        rule: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            appUsageTimeRangeOptions: APP_USAGE_TIME_RANGE_OPTIONS
        };
    },
    computed: {
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        }
    },
    methods: {
        appUsageTimeLabelObject (timeString) {
            return this.appUsageTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handleAppUsageOperatorSelection ($event) {
            this.$emit('input', { field: 'operator', value: $event });
        },
        handleAppUsageTimeSelection ($event) {
            const { value } = $event;

            if (value === 'withinlast') {
                this.$emit('input', { field: 'value', value: { count: 30, granularity: 'days' } });
            } else {
                this.$emit('input', { field: 'value', value: 1 });
            }

            this.$emit('input', { field: 'time', value });
        }
    }
};
</script>

<style lang="scss" scoped>
.application-rule {
    display: contents;
}
</style>
