export function engageURL () {
    if (window.__via_info__) {
        const { env } = window.__via_info__;
        switch (env) {
            case 'prod':
                return 'https://app.pendo.io';
            case 'prod-eu':
                return 'https://app.eu.pendo.io';
            case 'prod-us1':
                return 'https://us1.app.pendo.io';
            case 'prod-jp':
                return 'https://app.jpn.pendo.io';
            case 'prod-hsbc':
                return 'https://app.hsbc.pendo.io';
            case 'prod-au':
                return 'https://app.au.pendo.io';
            default:
                return `https://app.pendo-${env}.pendo-dev.com`;
        }
    }

    return `https://${window.location.host}`;
}
