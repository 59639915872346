<template>
    <div class="portfolio-dashboard">
        <global-filters>
            <template #right>
                <div class="filters">
                    <pendo-button
                        :loading="isFetching || pivoting"
                        type="link"
                        prefix-icon="repeat"
                        label="Pivot Data"
                        @click="debouncedUpdatePivotSetting" />
                    <view-by
                        :loading="isFetchingMetadata"
                        :view-by-metadata-field="viewByMetadataField"
                        :visitor-metadata-options="visitorMetadataOptions"
                        @metadata-select="updateViewByMetadataField" />
                </div>
            </template>
        </global-filters>
        <div class="portfolio-dashboard--grid">
            <div class="portfolio-dashboard--row">
                <portfolio-app-usage-chart
                    class="portfolio-dashboard--app-usage-chart"
                    :is-fetching-chart="isFetchingChart"
                    :app-map="appMap"
                    :date-range="dateRange"
                    :view-by-metadata-field="viewByMetadataField"
                    :is-viewing-applications-by-metadata="isViewingApplicationsByMetadata"
                    :app-usage-list="usageList"
                    @updateAppFilter="updateAppFilter"
                    @fetchAppUsageChart="fetchAppUsageChart"
                    @viewApplicationUsage="routeToApplicationUsage" />
            </div>
            <div class="portfolio-dashboard--row">
                <portfolio-app-usage-list
                    class="portfolio-dashboard--apps-list"
                    :is-fetching-list="isFetchingList"
                    :active-timezone="getActiveTimezone"
                    :is-viewing-applications-by-metadata="isViewingApplicationsByMetadata"
                    :date-range="dateRange"
                    :view-by-metadata-field="viewByMetadataField"
                    :app-usage-list-user-settings="appUsageListUserSettings"
                    :app-map="appMap"
                    :app-usage-period="appUsagePeriod"
                    :app-usage-period-breakdowns="appUsagePeriodBreakdowns"
                    @refreshAppUsageData="refreshAppUsagePeriodBreakdownsData"
                    @updateAppUsageListUserSettings="updateAppUsageListUserSettings" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { PendoButton } from '@pendo/components';
import GlobalFilters from '@/components/filters/GlobalFilters';
import PortfolioAppUsageChart from '@/stateless-components/analytics/portfolio-overview/PortfolioAppUsageChart.vue';
import PortfolioAppUsageList from '@/stateless-components/analytics/portfolio-overview/PortfolioAppUsageList.vue';
import ViewBy from '@/stateless-components/analytics/portfolio-overview/ViewBy';
import { getTimeSeriesByPeriod, validPeriodsForCount } from '@/utils/time-series';
import { getAppUsageAggSpec, getAppUsageBreakdownAggSpec } from '@/aggregations/portfolio-app-usage';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { isCancel } from 'axios';
import { request } from '@/stateless-components/utils/aggregations';
import { filterBarChangeSubscriber } from '@/state/modules/filters.module';

export default {
    name: 'PortfolioDashboard',
    components: {
        GlobalFilters,
        PortfolioAppUsageChart,
        PortfolioAppUsageList,
        ViewBy,
        PendoButton
    },
    data () {
        return {
            pivoting: false,
            appUsagePeriodBreakdowns: [],
            appUsagePeriod: null,
            usageList: [],
            isFetchingMetadata: false
        };
    },
    computed: {
        ...mapState({
            appUsageListUserSettings: (state) => state.portfolio.appUsageListUserSettings,
            isFetchingChart: (state) => state.portfolio.isFetchingChart,
            isFetchingList: (state) => state.portfolio.isFetchingList,
            metadataFieldTotal: (state) => state.portfolio.metadataFieldTotal,
            isViewingApplicationsByMetadata: (state) => state.filters.isViewingApplicationsByMetadata,
            activeSegmentId: (state) => state.filters.activeSegmentId,
            dateRange: (state) => state.filters.dateRange,
            appIdsFilter: (state) => state.filters.appIdsFilter,
            viewByMetadataField: (state) => state.filters.viewByMetadataField
        }),
        ...mapGetters({
            appMap: 'apps/appMapForActiveSubscription',
            getActiveTimezone: 'subscriptions/getTimezone',
            activeTimeSeries: 'filters/activeTimeSeries',
            visitorMetadataOptions: 'metadata/visitorMetadataOptions'
        }),
        isFetching () {
            return this.isFetchingChart || this.isFetchingList;
        }
    },
    async created () {
        await Promise.all([this.refreshAppUsagePeriodBreakdownsData(), this.fetchAppUsageChart()]);
        await this.fetchMetadata();
        this.unsubscribeFilterBarListener = filterBarChangeSubscriber(this.$store, () => {
            this.refreshAppUsagePeriodBreakdownsData();
            this.fetchAppUsageChart();
        });
    },
    unmounted () {
        if (this.unsubscribeFilterBarListener) this.unsubscribeFilterBarListener();
    },
    methods: {
        ...mapMutations({
            setFetchingList: 'portfolio/setFetchingList',
            setFetchingChart: 'portfolio/setFetchingChart'
        }),
        ...mapActions({
            updateAppFilter: 'filters/updateAppFilter',
            updatePivotDataToggle: 'filters/updatePivotDataToggle',
            loadMetadata: 'metadata/loadAll',
            updateViewByMetadataField: 'filters/updateViewByMetadataField',
            updateAppUsageListUserSettings: 'portfolio/updateAppUsageListUserSettings'
        }),
        async fetchMetadata () {
            this.isFetchingMetadata = true;
            await this.loadMetadata();
            this.isFetchingMetadata = false;
        },
        debouncedUpdatePivotSetting: debounce(async function () {
            this.pivoting = true;
            await this.updatePivotDataToggle({
                isViewingApplicationsByMetadata: !this.isViewingApplicationsByMetadata
            });
            this.pivoting = false;
        }, 500),
        async refreshAppUsagePeriodBreakdownsData ({ appUsagePeriod } = {}) {
            this.appUsagePeriod = appUsagePeriod;
            this.setFetchingList({ isFetchingList: true });
            if (this.aggCancel) {
                this.aggCancel.abort();
            }

            this.aggCancel = new AbortController();

            try {
                const {
                    activeSegmentId: segmentId,
                    appIdsFilter: appId,
                    dateRange,
                    viewByMetadataField: metadataField
                } = this;

                const periods = validPeriodsForCount(dateRange.count);
                let period = this.appUsagePeriod;
                if (!periods.includes(period)) {
                    period = periods[periods.length - 1];
                    this.appUsagePeriod = period;
                }

                const timeSeries = getTimeSeriesByPeriod(period, dateRange);

                const aggSpec = getAppUsageBreakdownAggSpec({
                    appId,
                    metadataField,
                    defaultMetadataField: this.metadataFieldTotal,
                    timeSeries,
                    segmentId,
                    signal: this.aggCancel.signal
                });

                const usagePeriodBreakdowns = (
                    await request(aggSpec, { rowsOnly: false, signal: this.aggCancel.signal })
                ).messages;

                this.appUsagePeriodBreakdowns = usagePeriodBreakdowns.map((period) => {
                    return {
                        ...period,
                        rows: period.rows.map((app) => ({
                            ...app,
                            name: get(this.appMap, `${app.id}.displayName`, ''),
                            color: get(this.appMap, `${app.id}.color`, null),
                            faviconB64: get(this.appMap, `${app.id}.faviconB64`, null),
                            platform: get(this.appMap, `${app.id}.platform`, 'extension'),
                            displayName: get(this.appMap, `${app.id}.displayName`, ''),
                            trainingAttributes: get(this.appMap, `${app.id}.trainingAttributes`, null),
                            applicationFlags: get(this.appMap, `${app.id}.applicationFlags`, [])
                        }))
                    };
                });

                this.setFetchingList({ isFetchingList: false });
            } catch (err) {
                if (!isCancel(err)) {
                    this.setFetchingList({ isFetchingList: false });
                }
            }
        },
        routeToApplicationUsage () {
            this.$router.push({ name: 'analyticsDashboard' }).catch(() => {});
        },
        async fetchAppUsageChart () {
            this.setFetchingChart({ isFetchingChart: true });

            if (this.aggCancelChart) {
                this.aggCancelChart.abort();
            }

            this.aggCancelChart = new AbortController();

            try {
                const { activeSegmentId: segmentId, appIdsFilter: appId, viewByMetadataField: metadataField } = this;

                const aggSpec = getAppUsageAggSpec({
                    appId,
                    metadataField,
                    defaultMetadataField: this.metadataFieldTotal,
                    timeSeries: {
                        ...this.activeTimeSeries,
                        period: 'dayRange'
                    },
                    segmentId
                });

                this.usageList = await request(aggSpec, { signal: this.aggCancelChart.signal });
                this.setFetchingChart({ isFetchingChart: false });
            } catch (err) {
                if (!isCancel(err)) {
                    this.setFetchingChart({ isFetchingChart: false });
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.portfolio-dashboard {
    .portfolio-dashboard--grid {
        display: grid;
        grid-gap: 32px;
        position: relative;
        z-index: 1; // ensure nothing goes above global filters
    }

    .portfolio-dashboard--row {
        display: grid;
        grid-gap: 32px;
        grid-template-columns: repeat(auto-fit, minmax(372px, 1fr));
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 10px;

    &--label,
    &--item {
        margin-right: 1em;
        font-size: 0.875em;
        line-height: 1.25em;
    }

    &--item {
        &:last-of-type {
            margin-right: unset;
        }
    }
}

.filters {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 15px;
    margin-top: 0px;

    &--label,
    &--item {
        margin-right: 1em;
        font-size: 0.875em;
        line-height: 1.25em;
    }

    &--item {
        &:last-of-type {
            margin-right: unset;
        }
    }
}
</style>
