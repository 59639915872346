import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import uniqWith from 'lodash/uniqWith';
import { convertAppIdToAggSafeId } from './apps';
import { migrateRule } from './migration';
import {
    parseGuideFilterPipeline,
    parseGuideElementFilterPipeline,
    parseApplicationUsageFilterPipeline,
    parseWorkflowUsageFilterPipeline,
    parsePageFilterPipeline,
    parsePollFilterPipeline,
    parseFeatureFilterPipeline,
    parseTrackTypeFilterPipeline,
    parseMobieDataFilterPipeline
} from './pipeline-filters';
import { SEGMENT_OPERATORS, operatorRequiresUnitCountFormat } from '../constants/segments';
import moment, { formatTimeValue, DATE_FORMAT } from './moment';
import { isCsvUploadOperator, isMobileDataSchema, getMobileDataFieldName } from './utils';
import { operators as o } from '@pendo/aggregations';

// rules -> segment

export function validateAndBuildSegment ({ rules, ...params }) {
    const isValid = validateSegmentRules(rules);
    if (!isValid) {
        return null;
    }

    return buildSegment({ rules, ...params });
}

export function validateSegmentRules (rules = []) {
    if (!rules.length) {
        return false;
    }

    return rules.every((orRules) => {
        return orRules.every((rule) => isRuleValid(rule));
    });
}

export function isRuleValid (rule) {
    const {
        schema,
        operator,
        value,
        time,
        guide,
        guideElementId,
        poll,
        page,
        feature,
        app,
        segment,
        workflow,
        first,
        last,
        granularity,
        count,
        trackType
    } = rule;
    const noSourceEntryProvided =
        value == null &&
        guide == null &&
        page == null &&
        feature == null &&
        app == null &&
        workflow == null &&
        segment == null &&
        trackType == null;

    if (isEmpty(schema) || isEmpty(operator) || noSourceEntryProvided) {
        return false;
    }

    if (schema.schema === 'poll') {
        if (!guide) {
            return false;
        }
        if (!poll) {
            return false;
        }

        if (time === 'withinlast' && (!count || !granularity)) {
            return false;
        }
        if (time === '!withinlast' && (!count || !granularity)) {
            return false;
        }
        if (time === 'since' && !first) {
            return false;
        }
        if (time === 'before' && !first) {
            return false;
        }
        if (time === 'between' && (!first || !last)) {
            return false;
        }

        if (!RegExp(/^(not)?responded$/).test(operator.value)) {
            return value !== null && value !== undefined;
        }
    }

    if (schema.schema === 'guide') {
        if (!guide) {
            return false;
        }

        if (time === 'withinlast' && (!count || !granularity)) {
            return false;
        }
        if (time === '!withinlast' && (!count || !granularity)) {
            return false;
        }
        if (time === 'since' && !first) {
            return false;
        }
        if (time === 'before' && !first) {
            return false;
        }
        if (time === 'between' && (!first || !last)) {
            return false;
        }
    }

    if (schema.schema === 'segment') {
        if (!segment) {
            return false;
        }

        if (rule.segmentInvalid) {
            return false;
        }
    }

    if (schema.schema === 'guideElement') {
        if (!guide || !guideElementId) {
            return false;
        }
        if (time === 'withinlast' && !value) {
            return false;
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
        if (time === 'atleast' && !value) {
            return false;
        }
        if (time === 'atmost' && !value) {
            return false;
        }
    }

    if (schema.schema === 'page') {
        if (!page) {
            return false;
        }
        if (time === 'withinlast' && !value) {
            return false;
        }
        if (time === '!withinlast' && !value) {
            return false;
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === 'before' && !value) {
            return false;
        }
        if (time === 'between' && (!value?.first || !value?.last)) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
        if (time === 'atleast' && !value) {
            return false;
        }
        if (time === 'atmost' && !value) {
            return false;
        }
    }

    // duplicating this logic since we're adding at least/at most in the future
    if (schema.schema === 'feature') {
        if (!feature) {
            return false;
        }
        if (time === 'withinlast' && !value) {
            return false;
        }
        if (time === '!withinlast' && !value) {
            return false;
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === 'before' && !value) {
            return false;
        }
        if (time === 'between' && (!value?.first || !value?.last)) {
            return false;
        }
        if (time === 'atleast' && !value) {
            return false;
        }
        if (time === 'atmost' && !value) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
    }

    if (schema.schema === 'application') {
        if (!rule.app) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
        if (time === 'withinlast' || time === '!withinlast') {
            if (!value || !value.granularity || isUndefined(value.count)) {
                return false;
            }
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === null) {
            return false;
        }
    }

    if (schema.schema === 'workflow') {
        if (!workflow) {
            return false;
        }
        if (time === 'withinlast' && !value) {
            return false;
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === 'atleast' && !value) {
            return false;
        }
        if (time === 'atmost' && !value) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
    }

    if (schema.schema === 'trackType') {
        if (!trackType) {
            return false;
        }
        if (time === 'withinlast' && !value) {
            return false;
        }
        if (time === '!withinlast' && !value) {
            return false;
        }
        if (time === 'since' && !value) {
            return false;
        }
        if (time === 'before' && !value) {
            return false;
        }
        if (time === 'between' && (!value?.first || !value?.last)) {
            return false;
        }
        if (time === 'ever') {
            return true;
        }
    }

    if (!operator.multivalue) {
        return true;
    }

    if (operator.value === 'between') {
        return !!value.first && !!value.last;
    }

    // rule is csv upload related, but does not have a reference to an entity tag
    if (isCsvUploadOperator(operator.value) && !rule.tagId) {
        return false;
    }

    if (!value) {
        return false;
    }

    return Object.keys(value).every((valKey) => value[valKey] != null);
}

// appId is legacy from before multi-app was introduced, and will be removed
export function buildSegment ({ rules, name, flagName, appId, selectedVisitorType = 'visitorId' }) {
    const isNestedSegment = containsNestedSegment(rules);
    const filters = parseRulesAsFilters(rules, appId);
    const segment = {
        name,
        flagName,
        shared: true,
        definition: {
            filters,
            advanced: false,
            advancedOptions: { selectedVisitorType }
        }
    };

    if (isNestedSegment) {
        segment.compound = buildCompoundOperator({ rules });
        segment.pipelines = buildCompoundSegmentPipelines({ filters, rules, appId, selectedVisitorType });
    } else {
        segment.pipeline = buildSegmentPipeline({ filters, selectedVisitorType });
    }

    return segment;
}

export function buildSegmentPipeline ({ filters, selectedVisitorType = 'visitorId' }) {
    return [
        { source: { visitors: null } },
        ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
        ...generateMergeBlocks(filters, selectedVisitorType),
        ...generateUserAgentParsedBlock(filters),
        ...filters.map(getPipelineFiltersFromFilter),
        { select: { visitorId: 'visitorId' } }
    ];
}

export function generateUserAgentParsedBlock (filters = []) {
    let flatFilters = filters.flatMap((filter) => filter.or || filter);
    flatFilters = uniqWith(flatFilters, (filter1, filter2) => filter1.selectedApp?.id === filter2.selectedApp?.id);
    const userAgentFilters = flatFilters.reduce((acc, filter) => {
        if (!isMobileDataSchema(filter)) {
            return acc;
        }

        const selectedApp = filter.selectedApp?.id ? `_${filter.selectedApp.id}` : '';
        acc.push(o.userAgent(`userAgent${selectedApp}`, `metadata.auto${selectedApp}.lastuseragent`));

        return acc;
    }, []);

    return userAgentFilters;
}

export function buildCompoundOperator ({ rules, inlineCount = 0, logicalOperator = '&&' } = {}) {
    if (!Array.isArray(rules)) {
        const { segment, operator } = rules;
        if (operator.value === '!=') {
            return `!${segment.id}`;
        }

        return segment.id;
    }

    let compoundOperator = '';

    // Extract rules containing nested segment rules
    const nestedSegmentRules = rules.filter(isNestedSegmentRule);
    const otherRules = rules.filter((rule) => !isNestedSegmentRule(rule));

    // Build "regular" pipeline if other rules exist
    if (otherRules.length) {
        compoundOperator += `inline${inlineCount}`;
        inlineCount++;
    }

    if (nestedSegmentRules.length) {
        nestedSegmentRules.forEach((orRules, index) => {
            inlineCount += index;
            if (otherRules.length || index > 0) {
                compoundOperator += ` ${logicalOperator} `;
            }
            compoundOperator += `${buildCompoundOperator({ rules: orRules, inlineCount, logicalOperator: '||' })}`;
        });
    }

    if (inlineCount > 0 && logicalOperator === '||') {
        compoundOperator = `(${compoundOperator})`;
    }

    return compoundOperator;
}

export function buildCompoundSegmentPipelines ({
    rules,
    appId,
    inlineCount = 0,
    selectedVisitorType = 'visitorId'
} = {}) {
    if (!Array.isArray(rules)) {
        return;
    }
    let pipelines = {};

    // Extract rules containing nested segment rules
    const nestedSegmentRules = rules.filter(isNestedSegmentRule);
    const otherRules = rules.filter((rule) => !isNestedSegmentRule(rule));

    // Build "regular" compound rules if other rules exist
    if (otherRules.length) {
        const pipeline = buildSegmentPipeline({ filters: parseRulesAsFilters(otherRules, appId), selectedVisitorType });
        pipelines[`inline${inlineCount}`] = pipeline;
        inlineCount++;
    }

    if (nestedSegmentRules.length) {
        nestedSegmentRules.forEach((orRules, index) => {
            inlineCount += index;
            const more = buildCompoundSegmentPipelines({ rules: orRules, appId, inlineCount, selectedVisitorType });
            pipelines = { ...pipelines, ...more };
        });
    }

    return pipelines;
}

export function parseRulesAsFilters (rules, overrideAppId) {
    // mapRulesToFilters needs to rely on the object reference to keep track of index
    // this MUST match the index in `merge` for `lastState<index>` which is calculated
    // indepedently in `getMergeFromFilter`
    const options = { filterIndex: 0, overrideAppId };

    return rules.map((orRules) => {
        let filter = {};
        if (!Array.isArray(orRules)) {
            orRules = [orRules];
        }

        if (orRules.length > 1) {
            filter.or = orRules.map(mapRulesToFilters(options));
        } else {
            [filter] = orRules.map(mapRulesToFilters(options));
        }

        return filter;
    });
}

export function mapRulesToFilters (options) {
    return ({ operator, schema, value, ...rule }) => {
        const appId = options.overrideAppId || get(rule, 'app.id', null);

        if (schema.schema === 'segment') {
            const { segment } = rule;

            return {
                ...schema,
                type: 'segment',
                kind: 'segment',
                segmentId: get(segment, 'id', null),
                operator: get(operator, 'value', null),
                selectedApp: { id: appId }, // temp override for subs without usesMultiApp
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'guide') {
            const { time, guide } = rule;

            return {
                ...schema,
                type: 'guide',
                guideId: get(guide, 'id', null), // rule may reference deleted guide
                guideStepId: get(guide, 'steps[0].id', null), // guaranteed entry point for guide for at least/at most count
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                ...getConditionalKeyValues(rule),
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'guideElement') {
            const { time, guideElementId, guide, guideStepId, guideElementName } = rule;

            return {
                ...schema,
                type: 'guideElement',
                guideElementId,
                guideStepId,
                guideElementName,
                guideId: get(guide, 'id', null),
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'page') {
            const { time, page } = rule;

            return {
                ...schema,
                type: 'page',
                pageId: get(page, 'id', null), // rule may reference deleted page
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'feature') {
            const { time, feature } = rule;

            return {
                ...schema,
                type: 'feature',
                featureId: get(feature, 'id', null), // rule may reference deleted feature
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        if (schema.schema === 'poll') {
            const { time, guide, poll } = rule;

            const noFormatPollTypes = ['PositiveNegative', 'NumberScale'];
            if (!noFormatPollTypes.includes(get(poll, 'attributes.type'))) {
                value = formatValueFromSchema(value, 'string');
            }

            return {
                ...schema,
                type: 'poll',
                guideId: get(guide, 'id', null), // rule may reference deleted guide
                pollId: get(poll, 'id', null),
                operator: get(operator, 'value', null),
                time: time || 'ever',
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                value,
                ...getConditionalKeyValues(rule)
            };
        }

        if (schema.schema === 'application') {
            const { time } = rule;
            const aggFormattedAppId = convertAppIdToAggSafeId(appId);

            return {
                ...schema,
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                field: `visitor.auto_${aggFormattedAppId}.lastVisit`
            };
        }

        if (schema.schema === 'workflow') {
            const { workflow, time } = rule;

            return {
                ...schema,
                type: 'workflow',
                workflowId: get(workflow, 'id', null),
                operator: get(operator, 'value', null),
                filterIndex: options.filterIndex++,
                time: time || 'ever',
                value
            };
        }

        // CSV
        if (isCsvUploadOperator(operator.value)) {
            return {
                ...schema,
                type: 'tag',
                tagId: get(rule, 'tagId', null),
                operator: get(operator, 'value', null),
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++,
                value: value != null ? formatValueFromSchema(value, 'string') : value
            };
        }

        if (schema.schema === 'trackType') {
            const { time, trackType } = rule;

            return {
                ...schema,
                type: 'trackType',
                trackTypeId: get(trackType, 'id', null), // rule may reference deleted trackType
                operator: get(operator, 'value', null),
                time: time || 'ever',
                value,
                selectedApp: { id: appId },
                filterIndex: options.filterIndex++
            };
        }

        return {
            ...schema,
            // when we remove single app aka usesMultiApp becomes the default, remove selectedApp
            selectedApp: { id: appId },
            value: formatValueFromSchema(value, schema.schema, operator.value),
            operator: get(operator, 'value', null)
        };
    };
}

function getConditionalKeyValues (rule) {
    const conditionalKeys = ['first', 'last', 'granularity', 'count'];
    const keysToInclude = {};
    conditionalKeys.forEach((key) => {
        if (!isUndefined(rule[key])) {
            keysToInclude[key] = rule[key];
        }
    });

    return keysToInclude;
}

export function formatValueFromSchema (value, schema, operator = null) {
    if (operatorRequiresUnitCountFormat[operator]) {
        schema = 'unitCount'; // eslint-disable-line no-param-reassign
    }

    let formattedValue = null;

    switch (schema) {
        case 'time': {
            formattedValue = formatTimeValue(value);
            break;
        }
        case 'string':
        case 'list': {
            formattedValue = `"${value}"`;
            break;
        }
        case 'unitCount': {
            const { count, granularity } = value;
            formattedValue = { count: Number(count) * -1, granularity: formatValueFromSchema(granularity, 'string') };
            break;
        }
        case 'integer': {
            formattedValue = parseInt(value, 10);
            break;
        }
        case 'float': {
            formattedValue = parseFloat(value);
            break;
        }
        case 'boolean': {
            formattedValue = value;
            break;
        }
        default:
            throw new Error(`Invalid schema ${schema} for value ${value}`);
    }

    return formattedValue;
}

export function generateMergeBlocks (filters, selectedVisitorType = 'visitorId') {
    return filters
        .map((filter) => getMergeFromFilter(filter, selectedVisitorType))
        .reduce((list, merge) => {
            if (Array.isArray(merge)) {
                list.push(...merge);

                return list;
            }

            list.push(merge);

            return list;
        }, [])
        .filter(Boolean);
}

export function getMergeFromFilter (
    {
        or,
        type,
        guideId,
        guideStepId,
        guideElementId,
        pollId,
        pageId,
        featureId,
        tagId,
        workflowId,
        filterIndex,
        trackTypeId
    },
    selectedVisitorType = 'visitorId'
) {
    if (or) {
        return or.map((filter) => getMergeFromFilter(filter, selectedVisitorType));
    }

    let merge;

    switch (type) {
        case 'guide': {
            const lastStateId = `lastState${filterIndex}`;
            const guideLastTimeId = `guideLastTime${filterIndex}`;
            const guideFirstTimeId = `guideFirstTime${filterIndex}`;
            const guideCountId = `guideSeenCount${filterIndex}`;

            merge = {
                fields: ['guideId', 'visitorId'],
                mappings: {
                    [lastStateId]: 'lastState',
                    [guideLastTimeId]: 'lastSeen',
                    [guideFirstTimeId]: 'firstSeen',
                    [guideCountId]: 'seenCount'
                },
                pipeline: [
                    { source: { guidesSeenEver: { guideId, guideStepId } } },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    {
                        group: {
                            group: ['visitorId'],
                            fields: [
                                { lastState: { first: 'lastState' } },
                                { lastSeen: { first: 'lastSeenAt' } },
                                { firstSeen: { first: 'firstSeenAt' } },
                                { seenCount: { first: 'seenCount' } }
                            ]
                        }
                    }
                ]
            };
            break;
        }
        case 'guideElement': {
            const itemSeenKey = `guideElement${filterIndex}_itemSeen`;

            merge = {
                fields: ['guideId', 'visitorId'],
                mappings: {
                    [`${itemSeenKey}.count`]: 'clickCount',
                    [`${itemSeenKey}.lastUsed`]: 'lastClickAt'
                },
                pipeline: [
                    { source: { guidesElementClickEver: { guideId, guideStepId, uiElementId: guideElementId } } },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    {
                        group: {
                            group: ['visitorId'],
                            fields: [{ lastClickAt: { first: 'lastClickAt' } }, { clickCount: { first: 'clickCount' } }]
                        }
                    }
                ]
            };
            break;
        }
        case 'page': {
            const itemSeenKey = `page${filterIndex}_itemSeen`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitors: null } },
                    {
                        itemSeen: {
                            [itemSeenKey]: { pageId }
                        }
                    },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    { select: { visitorId: 'visitorId', [itemSeenKey]: itemSeenKey } }
                ]
            };
            break;
        }
        case 'feature': {
            const itemSeenKey = `feature${filterIndex}_itemSeen`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitors: null } },
                    {
                        itemSeen: {
                            [itemSeenKey]: { featureId }
                        }
                    },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    { select: { visitorId: 'visitorId', [itemSeenKey]: itemSeenKey } }
                ]
            };
            break;
        }
        case 'poll': {
            const pollResponseId = `pollResponse${filterIndex}`;
            const pollTimeId = `pollTime${filterIndex}`;

            merge = {
                fields: ['visitorId'],
                mappings: {
                    [pollResponseId]: pollResponseId,
                    [pollTimeId]: pollTimeId
                },
                pipeline: [
                    { source: { pollsSeenEver: { guideId, pollId } } },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    { select: { visitorId: 'visitorId', [pollResponseId]: 'response', [pollTimeId]: 'time' } }
                ]
            };
            break;
        }
        case 'tag': {
            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitorTags: { visitorTagId: tagId } } },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    { select: { visitorId: 'visitorId', [`tag${filterIndex}`]: 'true' } }
                ]
            };
            break;
        }
        case 'workflow': {
            const lastCompletedAtId = `lastCompletedAt${filterIndex}`;
            const startedAtId = `startedAt${filterIndex}`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { workflowCompletionEver: { workflowId } } },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    {
                        select: {
                            visitorId: 'visitorId',
                            [lastCompletedAtId]: 'lastCompletedAt',
                            [startedAtId]: 'startedAt',
                            incompleteCount: 'incompleteCount',
                            completeCount: 'completeCount'
                        }
                    }
                ]
            };
            break;
        }
        case 'trackType': {
            const itemSeenKey = `trackType${filterIndex}_itemSeen`;

            merge = {
                fields: ['visitorId'],
                pipeline: [
                    { source: { visitors: null } },
                    {
                        itemSeen: {
                            [itemSeenKey]: { trackTypeId }
                        }
                    },
                    ...(o.identified(selectedVisitorType) ? [o.identified(selectedVisitorType)] : []),
                    { select: { visitorId: 'visitorId', [itemSeenKey]: itemSeenKey } }
                ]
            };
            break;
        }
        default:
            return null;
    }

    return { merge };
}

export function getPipelineFiltersFromFilter ({ operator, type, value, or, ...rawFilter }) {
    if (or) {
        return parseOrFilterForPipeline(or);
    }

    if (type === 'guide') {
        return parseGuideFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'guideElement') {
        return parseGuideElementFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'page') {
        return parsePageFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'feature') {
        return parseFeatureFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'poll') {
        return parsePollFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'tag') {
        return parseListContainsFilterPipeline({ operator, ...rawFilter });
    }

    if (type === 'application') {
        return parseApplicationUsageFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'workflow') {
        return parseWorkflowUsageFilterPipeline({ operator, value, ...rawFilter });
    }

    if (type === 'trackType') {
        return parseTrackTypeFilterPipeline({ operator, value, ...rawFilter });
    }

    if (isMobileDataSchema(rawFilter)) {
        return parseMobieDataFilterPipeline({ operator, value, ...rawFilter });
    }

    let filter = '';

    switch (operator) {
        case 'contains': {
            filter = { filter: `contains(${type},${value})` };
            break;
        }
        case '!contains': {
            filter = { filter: `!contains(${type},${value})` };
            break;
        }
        case 'between': {
            const { first, last } = value;
            filter = { filter: `${type}>=date("${first}")&&${type}<=date("${last}")` };
            break;
        }
        case 'withinLast': {
            const { count, granularity } = value;
            filter = { filter: `${type}<=now()&&${type}>=dateAdd(now(),${count},${granularity})` };
            break;
        }
        case '!withinLast': {
            const { count, granularity } = value;
            filter = { filter: `${type}>=now()||${type}<=dateAdd(now(),${count},${granularity})` };
            break;
        }
        default:
            if (rawFilter.schema === 'time') {
                filter = { filter: `${type}${operator}date("${value}")` };
            } else {
                filter = { filter: `${type}${operator}${value}` };
            }
    }

    return filter;
}

export function parseListContainsFilterPipeline ({ operator, filterIndex }) {
    let filter;

    switch (operator) {
        case 'listContains': {
            filter = { filter: `!isNil(tag${filterIndex})` };
            break;
        }
        case '!listContains': {
            filter = { filter: `isNil(tag${filterIndex})` };
            break;
        }
    }

    return filter;
}

export function parseOrFilterForPipeline (filters) {
    return filters.map(getPipelineFiltersFromFilter).reduce(
        (acc, rule) => {
            if (!acc.filter) {
                acc.filter = `(${rule.filter})`;
            } else {
                acc.filter += `||(${rule.filter})`;
            }

            return acc;
        },
        { filter: '' }
    );
}

export function containsNestedSegment (rules) {
    return rules.some(isNestedSegmentRule);
}

export function isNestedSegmentRule (rule) {
    // Outer "and" rule
    if (Array.isArray(rule)) {
        return rule.some(isNestedSegmentRule);
    }

    // Inner "or" rule
    return !!(rule && rule.schema?.schema === 'segment');
}

// segment -> rules

export function getRulesFromSegment (segment) {
    const { filters } = segment.definition;

    return filters.reduce((rules, filter) => {
        if (filter.or) {
            rules.push(filter.or.map(parseOrRuleFromFilter));
        } else {
            rules.push([parseOrRuleFromFilter(filter)]);
        }

        return rules;
    }, []);
}

export function parseOrRuleFromFilter (rule) {
    const { operator, value, ...schema } = migrateRule(rule);
    const { id, type, filterIndex, kind, selectedApp, name, ...rest } = schema;

    if (schema.schema === 'segment') {
        const { segmentId } = schema;

        return {
            schema,
            segmentId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'guide') {
        return {
            ...rest,
            schema,
            value,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'poll') {
        return {
            ...rest,
            schema,
            value: parseValueFromDefinition(value, 'string'),
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'page') {
        const { time, pageId } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            pageId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'feature') {
        const { time, featureId } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            featureId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'application') {
        const { time } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.type === 'tag') {
        const { tagId } = schema;

        return {
            schema,
            tagId,
            value: parseValueFromDefinition(value, schema.schema, operator),
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'workflow') {
        const { workflowId, time } = schema;
        const isNonRSWorkflow = operator === 'notcompleted';

        return {
            schema,
            workflowId,
            time,
            value: get(value, 'length') ? value.slice() : value,
            operator: findSchemaOperator(operator, isNonRSWorkflow ? 'workflowNonRS' : schema.schema)
        };
    }

    if (schema.schema === 'guideElement') {
        const { time, guideId, guideStepId, guideElementId, guideElementName } = schema;

        return {
            schema,
            guideId,
            guideStepId,
            guideElementId,
            guideElementName,
            time,
            value: get(value, 'length') ? value.slice() : value,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    if (schema.schema === 'trackType') {
        const { time, trackTypeId } = schema;

        return {
            schema,
            time,
            value: get(value, 'length') ? value.slice() : value,
            trackTypeId,
            operator: findSchemaOperator(operator, schema.schema)
        };
    }

    // when we remove single app aka usesMultiApp becomes the default, comment this back in
    // Before you could view multiple apps in adopt, `selectedApp` was added to all rules,
    // even rules that are not technically app-specific
    // for cases where rules are actually subscription-wide, such as visitor comparisons,
    // remove selectedApp to avoid confusion
    // delete schema.selectedApp;

    return {
        schema,
        value: parseValueFromDefinition(value, schema.schema, operator),
        operator: findSchemaOperator(operator, schema.schema)
    };
}

export function parseValueFromDefinition (rawValue, schema, operator) {
    if (operatorRequiresUnitCountFormat[operator]) {
        schema = 'unitCount'; // eslint-disable-line no-param-reassign
    }

    let value;

    switch (schema) {
        case 'string':
        case 'list': {
            value = (rawValue || '').replace(/"/g, '');
            break;
        }
        case 'time': {
            const parseTimeValue = (dateVal) => {
                // legacy value (in ms)
                if (typeof dateVal === 'number') {
                    return moment.tz(dateVal, 'UTC').format(DATE_FORMAT.iso);
                }

                return dateVal;
            };

            if (isPlainObject(rawValue)) {
                value = Object.entries(rawValue).reduce((acc, [key, val]) => {
                    acc[key] = parseTimeValue(val);

                    return acc;
                }, {});
            } else {
                value = parseTimeValue(rawValue);
            }
            break;
        }
        case 'unitCount': {
            const { count, granularity } = rawValue;
            value = { count: count * -1, granularity: parseValueFromDefinition(granularity, 'string') };
            break;
        }
        case 'integer':
        case 'float':
        default:
            value = rawValue;
    }

    return value;
}

export function findSchemaOperator (operator, schema) {
    const list = SEGMENT_OPERATORS[schema];

    return list.find((opt) => opt.value === operator);
}

// segment builder ui utilities

export function getAbsoluteDateRangeConfig (rule) {
    return {
        component: 'absolute-date-range',
        props: {
            value: {
                first: rule.first || rule.value?.first,
                last: rule.last || rule.value?.last
            }
        }
    };
}

export function getRelativeDateRangeConfig (rule, field) {
    return {
        component: 'relative-date-range',
        props: {
            value: {
                count: field ? rule[field].count : rule.count,
                granularity: field ? rule[field].granularity : rule.granularity
            }
        }
    };
}

export function getSingleDateConfig (rule, field = 'value') {
    return {
        component: 'single-date',
        props: {
            field,
            value: rule[field]
        }
    };
}

export function getSegmentCsvConfig (rule) {
    return {
        component: 'segment-csv-upload',
        props: {
            value: {
                tagId: rule.tagId,
                filename: rule.value
            }
        }
    };
}

export function getNumberConfig (rule, field = 'value') {
    return {
        component: 'number-input',
        props: {
            field,
            value: rule[field],
            min: 0
        }
    };
}

export function getStringConfig (rule, field = 'value') {
    const supportedAutoFields = {
        id: true,
        lastservername: true,
        accountid: true
    };

    const [kind, group, fieldName] = rule.schema.field?.split('.');

    if (isMobileDataSchema(rule.schema)) {
        return {
            component: 'metadata-value-select',
            props: {
                field,
                kind: rule.schema.kind,
                group: 'auto',
                fieldName: getMobileDataFieldName(rule.schema),
                value: rule[field]
            }
        };
    }

    if (
        rule.schema.schema !== 'string' ||
        (rule.operator.value !== '==' && rule.operator.value !== '!=') ||
        rule.schema.neverIndex === undefined ||
        ((rule.schema.neverIndex || rule.schema.group === 'auto') && !supportedAutoFields[fieldName])
    ) {
        return {
            component: 'string-input',
            props: {
                field,
                value: rule[field]
            }
        };
    }

    return {
        component: 'metadata-value-select',
        props: {
            field,
            kind,
            group,
            fieldName,
            value: rule[field]
        }
    };
}

export function getSegmentVisitorType (segment) {
    const segmentVisitorType = get(segment, 'definition.advancedOptions.selectedVisitorType', null);

    if (segmentVisitorType) {
        return segmentVisitorType;
    }

    return segment?.pipeline?.some((pipelineItem) => pipelineItem.identified === 'visitorId') ? 'visitorId' : null;
}
