<template>
    <pendo-table
        title="Suggested Steps"
        class=""
        :data="localSteps"
        draggable
        :columns="columns"
        :max-height="600"
        :loading="isLoading || isRegeneratingStep"
        @table-row-drag-stop="reorder">
        <template #stepType="{ rowIndex, row }">
            <div
                class="column-wrap"
                pendo-draggable-ignore>
                <pendo-multiselect
                    class="suggested-steps-table--step-type-value"
                    :value="layoutOptions.find((option) => option.id == row.stepType)"
                    :options="layoutOptions"
                    @select="
                        (value) => {
                            updateStepLayout(rowIndex, value);
                        }
                    ">
                    <template #selectedLabel="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                    <template #option="{ option }">
                        <pendo-icon-option :option="option" />
                    </template>
                </pendo-multiselect>
            </div>
        </template>
        <template #stepTextContent="{ rowIndex, row }">
            <div
                class="column-wrap"
                pendo-draggable-ignore>
                <pendo-editable-content
                    auto-width
                    :value="row.stepTextContent"
                    exit-event="blur"
                    @input="debouncedUpdateStepContent(rowIndex, $event)" />
            </div>
        </template>
        <template #actions="{ row }">
            <div
                class="column-wrap"
                pendo-draggable-ignore>
                <pendo-actions-cell
                    pendo-draggable-ignore
                    :row="row"
                    :actions="[
                        {
                            type: 'regenerate',
                            icon: 'wand',
                            tooltip: 'Regenerate with currently selected tone'
                        },
                        {
                            type: 'delete',
                            icon: 'trash-2',
                            tooltip: 'Delete'
                        }
                    ]"
                    @regenerate="regenerateStep"
                    @delete="deleteStep" />
            </div>
        </template>
        <template #append>
            <tbody>
                <tr>
                    <td
                        :style="{ width: `${stepNumberColumnWidth}px` }"
                        class="suggested-steps-table--row-entry-index pendo-table__cell pendo-table__column">
                        {{ localSteps.length + 1 }}
                    </td>
                    <td />
                    <td class="pendo-table__cell pendo-table__column">
                        <pendo-editable-content
                            v-model="newStepTextContent"
                            class="suggested-steps-table--new-step-content"
                            pendo-draggable-ignore
                            auto-width
                            exit-event="blur"
                            empty-text="Enter text to add another step"
                            @confirm="addNewStep" />
                    </td>
                </tr>
            </tbody>
        </template>
    </pendo-table>
</template>

<script>
import {
    PendoTable,
    PendoMultiselect,
    PendoEditableContent,
    PendoActionsCell,
    PendoIconOption
} from '@pendo/components';
import { isGeneratedStepsValid } from '@/stateless-components/utils/ai';
import { BUILDING_BLOCK_LAYOUT_TYPES } from '@pendo/services/Constants';
const { TOOLTIP, LIGHTBOX } = BUILDING_BLOCK_LAYOUT_TYPES;
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';

const STEP_NUMBER_COLUMN_WIDTH = 60;

export default {
    name: 'SuggestedStepsTable',
    components: {
        PendoTable,
        PendoMultiselect,
        PendoEditableContent,
        PendoActionsCell,
        PendoIconOption
    },
    props: {
        steps: {
            type: Array,
            default () {
                return [];
            },
            validator (values) {
                return isGeneratedStepsValid(values);
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isRegeneratingStep: {
            type: Boolean,
            default: false
        }
    },
    emits: ['inputStepContent', 'updateSteps', 'regenerateStep'],
    data () {
        return {
            layoutOptions: [TOOLTIP, LIGHTBOX].map((option) => ({
                id: option,
                label: option,
                icon: { type: option.includes('tooltip') ? 'guide' : 'target-element' }
            })),
            newStepTextContent: '',
            indexToShow: {},
            stepNumberColumnWidth: STEP_NUMBER_COLUMN_WIDTH,
            columns: [
                {
                    type: 'index',
                    label: '#',
                    sortable: false,
                    width: STEP_NUMBER_COLUMN_WIDTH
                },
                {
                    prop: 'stepType',
                    label: 'Layout',
                    sortable: false,
                    visible: true,
                    width: 250
                },
                {
                    prop: 'stepTextContent',
                    label: 'Content',
                    sortable: false,
                    visible: true
                },
                {
                    type: 'actions',
                    width: 60,
                    allowResize: false,
                    visible: true
                }
            ],
            localSteps: this.cloneSteps()
        };
    },
    watch: {
        isRegeneratingStep (value) {
            if (!value) {
                this.localSteps = this.cloneSteps();
            }
        }
    },
    methods: {
        cloneSteps () {
            return cloneDeep(this.steps).map((step) => {
                const id = step.id || uuid();

                return { ...step, id };
            });
        },
        updateSteps () {
            this.$emit('updateSteps', this.localSteps);
        },
        updateStepLayout (index, value) {
            this.localSteps[index].stepType = value.id;
            this.updateSteps();
        },
        inputStepContent (index, value) {
            this.$emit('inputStepContent', index, value);
        },
        updateStepContent (index, value) {
            this.localSteps[index].stepTextContent = value;
            this.updateSteps();
        },
        debouncedUpdateStepContent: debounce(function (rowIndex, $event) {
            this.updateStepContent(rowIndex, $event);
        }, 250),
        reorder ({ from, to }) {
            if (to == null || from === null || to === from) return;
            this.localSteps.splice(to, 0, ...this.localSteps.splice(from, 1));
            this.updateSteps();
        },
        addNewStep (stepTextContent) {
            if (!stepTextContent.trim().length) return;
            this.localSteps.push({
                stepTextContent,
                stepType: 'lightbox',
                id: uuid()
            });
            this.newStepTextContent = '';
            this.updateSteps();
        },
        regenerateStep ({ id }) {
            const index = this.localSteps.findIndex((step) => step.id === id);
            this.$emit('regenerateStep', { index });
        },
        deleteStep ({ id }) {
            const index = this.localSteps.findIndex((step) => step.id === id);
            this.localSteps.splice(index, 1);
            this.updateSteps();
        }
    }
};
</script>

<style lang="scss" scoped>
.column-wrap {
    display: contents;
}

:deep(.pendo-editable-content__form-item) {
    overflow: hidden;
}

:deep(.pendo-editable-content__input) {
    /* stylelint-disable declaration-no-important */
    min-width: 200px !important;
    max-width: 100% !important;
}

.suggested-steps-table--row-entry-index {
    text-align: center;
}

.pendo-editable-content.is-editing,
.pendo-editable-content.is-editing :deep(.pendo-editable-content__input .pendo-input__control) {
    width: 100%;
}
</style>
