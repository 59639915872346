<template>
    <pendo-modal
        :show-close="true"
        :visible="isVisible"
        :confirm-button-config="CONFIG"
        :esc-to-close="true"
        :click-to-close="true"
        class="delete-rc-modal"
        data-cy="delete-rc-modal"
        width="400px"
        :title="MESSAGE_CONTENT.TITLE"
        :message="MESSAGE_CONTENT.MESSAGE"
        type="confirmation"
        @close="$emit('onCancel')"
        @cancel="$emit('onCancel')"
        @confirm="!isUpdating && $emit('onDelete')" />
</template>

<script>
import { PendoModal } from '@pendo/components';

const CONFIG = Object.freeze({
    type: 'danger',
    label: 'Delete Resource Center',
    theme: 'app'
});

const MESSAGE_CONTENT = Object.freeze({
    TITLE: 'Delete Resource Center?',
    MESSAGE:
        'By confirming this action, your Resource Center will no longer be visible to all eligible end users and all modules and content will be removed.'
});

export default {
    name: 'ResourceCenterDeleteModal',
    components: {
        PendoModal
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false
        },
        isUpdating: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onCancel', 'onDelete'],
    data () {
        return {
            MESSAGE_CONTENT,
            CONFIG
        };
    }
};
</script>
