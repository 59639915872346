<template>
    <pendo-card
        class="query-builder"
        collapsible
        :title="isNewPath ? 'Create a Path' : 'Query'">
        <div class="query-builder__body">
            <div class="query-builder__section query-builder__top">
                <div class="query-builder__root-config">
                    <div class="query-builder__direction">
                        <div class="query-builder__secondary-text">
                            Show paths...
                        </div>
                        <pendo-multiselect
                            :value="selectedDirection"
                            :options="directionOptions"
                            :disabled="globallyDisabled"
                            @select="onDirectionSelect">
                            <template #trigger>
                                <pendo-data-source-trigger />
                            </template>
                            <template #option="{ option }">
                                <pendo-icon-option :option="option" />
                            </template>
                        </pendo-multiselect>
                    </div>
                    <resource-chooser
                        class="query-builder__root-resource"
                        :kind-id-container="pathResource.definition.config"
                        :disabled="globallyDisabled"
                        :app-list="appListForActiveSub"
                        @select="onRootResourceSelect" />
                    <div class="unshared-resource-alert">
                        <pendo-alert
                            v-if="!isResourceShared"
                            data-cy="resource-unshared-warning"
                            type="warning">
                            {{ alertMessage }}
                            <a
                                :href="HELP_ARTICLE"
                                target="_blank">Learn more</a>.
                        </pendo-alert>
                    </div>
                </div>
                <div
                    v-if="isMultiApp"
                    class="query-builder__across-apps">
                    <pendo-toggle
                        :value="acrossApps"
                        :disabled="globallyDisabled"
                        @change="onAcrossAppsChange" />
                    <label class="query-builder__across-apps-label">Follow paths across apps</label>
                </div>
            </div>
            <div class="query-builder__section">
                <div class="query-builder__title">
                    Date Range
                </div>
                <date-range-picker
                    v-if="timeSeries"
                    :first-visit="appFirstVisit"
                    :value="timeSeries"
                    :range-limiter="90"
                    :disabled="globallyDisabled"
                    @change="onTimeSeriesChange" />
            </div>
            <transition name="query-builder-section">
                <div class="query-builder__section query-builder__segment">
                    <div>
                        <div class="query-builder__title">
                            Segment
                        </div>
                        <segment-chooser
                            :value="segment"
                            :disabled="globallyDisabled"
                            :guide-targeting="false"
                            @input="onSegmentChange" />
                    </div>
                </div>
            </transition>
            <transition name="query-builder-section">
                <div class="query-builder__section query-builder__advanced">
                    <pendo-collapse>
                        <pendo-collapse-item
                            title="Advanced Options"
                            type="card">
                            <pendo-multiselect
                                v-if="!hasGuidesInPathSegmentFlag"
                                :value="includedResources"
                                :options="includedEventsOptions"
                                :disabled="globallyDisabled"
                                @select="onIncludedResourcesChange" />
                            <div v-if="hasGuidesInPathSegmentFlag">
                                <div class="query-builder__subtitle">
                                    Event types included
                                </div>
                                <pendo-multiselect
                                    :options="includedEventsOptions"
                                    :value="selectedEventTypeOptions"
                                    :popper-options="{ class: 'event-type-chooser' }"
                                    :close-on-select="false"
                                    :disabled="globallyDisabled"
                                    @remove="onIncludedEventsChange($event, false)"
                                    @select="onIncludedEventsChange($event, true)">
                                    <template #selectedLabel>
                                        {{ includedEventsLabel }}
                                    </template>
                                    <template #header>
                                        <pendo-button
                                            class="select-all-checkbox-options"
                                            theme="app"
                                            type="link"
                                            @click="onSelectAllOrClearAllEvents">
                                            {{ selectedEventTypeOptions.length ? 'Clear' : 'Select all' }}
                                        </pendo-button>
                                    </template>
                                    <template #option="{ option }">
                                        <pendo-checkbox-option :option="option" />
                                    </template>
                                </pendo-multiselect>
                            </div>
                            <div>
                                <pendo-checkbox
                                    label="Collapse Repeating Steps"
                                    :value="pathResource.definition.config.collapseDups"
                                    :disabled="globallyDisabled"
                                    @change="onCollapseDupsChange" />
                            </div>
                            <div>
                                <pendo-checkbox
                                    label="Remove Duplicate Visitor Paths"
                                    :value="pathResource.definition.config.uniqueVisitors"
                                    :disabled="globallyDisabled"
                                    @change="onRemoveVisitorDups" />
                            </div>
                            <pendo-input-number
                                :min="2"
                                :max="20"
                                :disabled="globallyDisabled"
                                size="small"
                                class="query-builder__advanced-length"
                                :labels="{ right: 'Maximum Path Length' }"
                                :value="pathResource.definition.config.maxLength"
                                @change="onPathLengthChange" />
                        </pendo-collapse-item>
                    </pendo-collapse>
                </div>
            </transition>
            <div class="query-builder__section query-builder__footer">
                <span
                    v-if="lastRunAt"
                    class="query-builder__secondary-text">Last run on {{ lastRunAt }}</span>
                <path-save-action
                    v-if="isNewPath"
                    :can-share="canShare"
                    :disabled="!runnable"
                    button-prefix-icon="play"
                    button-label="Save & Run"
                    @save="onSave" />
                <pendo-button
                    v-if="!isNewPath"
                    theme="app"
                    type="primary"
                    prefix-icon="play"
                    :label="!canEdit || !changed ? 'Run' : 'Save & Run'"
                    class="query-builder__save"
                    :loading="pathState.status === 'loading'"
                    :disabled="!runnable || isNewPath"
                    @click="runnable && $emit('run')" />
            </div>
        </div>
    </pendo-card>
</template>
<script>
import {
    PendoCard,
    PendoButton,
    PendoToggle,
    PendoTooltip,
    PendoCollapse,
    PendoCollapseItem,
    PendoCheckbox,
    PendoCheckboxOption,
    PendoInputNumber,
    PendoMultiselect,
    PendoDataSourceTrigger,
    PendoIconOption,
    PendoAlert
} from '@pendo/components';
import { mapGetters } from 'vuex';
import SegmentChooser from '@/components/segments/SegmentChooser';
import DateRangePicker from '@/stateless-components/common/DateRangePicker';
import ResourceChooser from '@/components/common/ResourceChooser';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import get from 'lodash/get';
import moment, { DATE_FORMAT } from '@/utils/moment.js';
import { convertValueToDateRangeConfig } from '@/utils/time-series';
import { generatePathTimeSeries, convertEngageTimeSeriesTypeToId } from '@/utils/paths';
import PathSaveAction from '@/components/paths/PathSaveAction';

const HELP_ARTICLE = 'https://adoptpartners.pendo.io/hc/en-us/articles/360062120292/';

export default {
    name: 'PathQueryBuilder',
    components: {
        PendoCard,
        PendoButton,
        PendoToggle,
        DateRangePicker,
        SegmentChooser,
        PendoCollapse,
        PendoCollapseItem,
        PendoCheckbox,
        PendoCheckboxOption,
        PendoInputNumber,
        ResourceChooser,
        PendoMultiselect,
        PendoDataSourceTrigger,
        PendoIconOption,
        PathSaveAction,
        PendoAlert
    },
    directives: {
        PendoTooltip
    },
    props: {
        appFirstVisit: {
            type: Number,
            required: true
        },
        pathResource: {
            type: Object,
            required: true
        },
        pathState: {
            type: Object,
            required: true
        },
        runnable: {
            type: Boolean,
            required: true
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canShare: {
            type: Boolean,
            default: false
        },
        globallyDisabled: {
            type: Boolean,
            default: false
        },
        changed: {
            type: Boolean,
            default: false
        },
        isMultiApp: {
            type: Boolean,
            default: false
        }
    },
    emits: ['run', 'save', 'change'],
    data () {
        return {
            resourceOptions: [
                { value: 'pages', label: 'Pages' },
                { value: 'features', label: 'Features' }
            ],
            directionOptions: [
                { id: 'startingFrom', label: 'Starting from', icon: { type: 'log-out' } },
                { id: 'leadingTo', label: 'Leading to', icon: { type: 'log-in' } }
            ],
            HELP_ARTICLE
        };
    },
    computed: {
        ...mapGetters({
            appListForActiveSub: 'apps/listAllForActiveSubscription',
            getPageById: 'pages/pageById',
            getFeatureById: 'features/featureById',
            getTrackEventById: 'trackEvents/trackEventById',
            getGuideById: 'guides/getGuideById',
            hasSegmentFlag: 'auth/hasSegmentFlag'
        }),
        hasGuidesInPathSegmentFlag () {
            return this.hasSegmentFlag('guidesInPaths');
        },
        hasAdoptTrackEventSegmentFlag () {
            return this.hasSegmentFlag('adoptTrackEvent');
        },
        isNewPath () {
            return !this.pathResource.id;
        },
        lastRunAt () {
            const { lastRunAt } = this.pathResource;

            return lastRunAt ? moment(lastRunAt).format(DATE_FORMAT.full) : '';
        },
        segment () {
            return this.pathResource.definition.config.segmentId;
        },
        selectedEventTypeOptions () {
            return this.includedEventsOptions.filter((option) => {
                const isPage = option.id === 'pages';
                const id = isPage ? 'omitPages' : option.id;
                const checked = isPage
                    ? !this.pathResource.definition.config[id]
                    : this.pathResource.definition.config[id];

                return checked;
            });
        },
        includedEventsOptions () {
            if (this.hasGuidesInPathSegmentFlag) {
                return [
                    { id: 'pages', label: 'Pages', icon: { type: 'file' } },
                    { id: 'features', label: 'Features', icon: { type: 'feature' } },
                    this.hasAdoptTrackEventSegmentFlag && {
                        id: 'trackEvents',
                        label: 'Track Events',
                        icon: { type: 'zap' }
                    },
                    { id: 'guideEvents', label: 'Guides', icon: { type: 'guide' } }
                ].filter(Boolean);
            }

            if (this.hasAdoptTrackEventSegmentFlag) {
                return [
                    {
                        id: 'pages,features,trackEvents',
                        label: 'Show Pages & Features & Track Events'
                    },
                    { id: 'pages,features', label: 'Show Pages & Features' },
                    { id: 'pages,trackEvents', label: 'Show Pages & Track Events' },
                    {
                        id: 'features,trackEvents',
                        label: 'Show Features & Track Events'
                    },
                    { id: 'pages', label: 'Show Pages Only' },
                    { id: 'features', label: 'Show Features Only' },
                    { id: 'trackEvents', label: 'Show Track Events Only' }
                ];
            }

            return [
                { id: 'pages,features', label: 'Show Pages & Features' },
                { id: 'pages', label: 'Show Pages Only' },
                { id: 'features', label: 'Show Features Only' }
            ];
        },
        includedEventsLabel () {
            if (this.selectedEventTypeOptions.length === 1) {
                return this.selectedEventTypeOptions[0].label;
            }

            return `Event types (${this.selectedEventTypeOptions.length})`;
        },
        includedResources () {
            const { config } = this.pathResource.definition;
            const { features, omitPages, trackEvents, guides } = config;
            const resourceTypes = [
                !omitPages && 'pages',
                features && 'features',
                trackEvents && 'trackEvents',
                guides && 'guides'
            ].filter(Boolean);

            const id = resourceTypes.toString();

            return this.includedEventsOptions.find((option) => option.id === id);
        },
        acrossApps () {
            return this.pathResource.definition.config.acrossApps;
        },
        selectedDirection () {
            return this.pathResource.definition.config.predecessors
                ? this.directionOptions[1]
                : this.directionOptions[0];
        },
        timeSeries () {
            const value = convertEngageTimeSeriesTypeToId(this.pathResource.definition.timeSeries);

            return convertValueToDateRangeConfig({ value }, this.appFirstVisit);
        },
        isResourceShared () {
            const pageId = get(this, 'pathResource.definition.config.pageId', null);
            const featureId = get(this, 'pathResource.definition.config.featureId', null);
            const trackTypeId = get(this, 'pathResource.definition.config.trackTypeId', null);
            const guideId = get(this, 'pathResource.definition.config.guideId', null);
            if (pageId === null && featureId === null && trackTypeId === null && guideId === null) return true;

            let resource;
            if (pageId) {
                resource = this.getPageById(pageId);
            } else if (featureId) {
                resource = this.getFeatureById(featureId);
            } else if (trackTypeId) {
                resource = this.getTrackEventById(trackTypeId);
            } else {
                resource = this.getGuideById(guideId);
            }

            if (resource === null) return true;

            const isCustomResource = get(resource, 'rootVersionId', null) !== null;
            if (isCustomResource && !trackTypeId) return true;

            return get(resource, 'trainingSettings.enabled', false);
        },
        resourceKind () {
            if (get(this, 'pathResource.definition.config.pageId', false)) {
                return 'Page';
            }
            if (get(this, 'pathResource.definition.config.featureId', false)) {
                return 'Feature';
            }
            if (get(this, 'pathResource.definition.config.trackEventId', false)) {
                return 'Track Event';
            }

            return 'Guide';
        },
        alertMessage () {
            return `This ${this.resourceKind} is no longer being shared with this application.`;
        }
    },
    methods: {
        onSave (config) {
            this.$emit('save', config);
        },
        onRootResourceSelect (resource = {}) {
            const { kind, id, appId } = resource;
            const clonedPathResource = cloneDeep(this.pathResource);
            const { config } = clonedPathResource.definition;
            delete config.pageId;
            delete config.featureId;
            delete config.trackTypeId;
            delete config.guideId;

            config.appId = appId;
            switch (kind) {
                case 'Page':
                    config.pageId = id;
                    break;
                case 'Feature':
                    config.featureId = id;
                    break;
                case 'TrackType':
                    config.trackTypeId = id;
                    break;
                case 'Guide':
                    config.guideId = id;
                    config.guideEvents = true;
                    config.guideEventTypes = resource.resourceGuideEvent.guideEventTypes;
                    config.guideStepId = resource.resourceGuideEvent.guideStepId;
                    break;
            }
            this.$emit('change', clonedPathResource);
        },
        onDirectionSelect (option) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.predecessors = option.id === 'leadingTo';
            this.$emit('change', clonedPathResource);
        },
        onAcrossAppsChange (acrossApps) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.acrossApps = acrossApps;
            this.$emit('change', clonedPathResource);
        },
        onTimeSeriesChange ({ dateRange }) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.timeSeries = generatePathTimeSeries(dateRange);
            this.$emit('change', clonedPathResource);
        },
        onSegmentChange (segment) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.segmentId = segment.id;
            this.$emit('change', clonedPathResource);
        },
        onIncludedEventsChange (option, isAdding) {
            // notice `omitPages` vs `features` and `trackEvents`
            const isPage = option.id === 'pages';
            const id = isPage ? 'omitPages' : option.id;
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config[id] = isPage ? !isAdding : isAdding;
            this.$emit('change', clonedPathResource);
        },
        onSelectAllOrClearAllEvents () {
            const clonedPathResource = cloneDeep(this.pathResource);
            if (this.selectedEventTypeOptions.length) {
                if (this.hasAdoptTrackEventSegmentFlag) {
                    clonedPathResource.definition.config.trackEvents = false;
                }
                clonedPathResource.definition.config.features = false;
                clonedPathResource.definition.config.guideEvents = false;
                clonedPathResource.definition.config.omitPages = true;
            } else {
                if (this.hasAdoptTrackEventSegmentFlag) {
                    clonedPathResource.definition.config.trackEvents = true;
                }

                clonedPathResource.definition.config.features = true;
                clonedPathResource.definition.config.guideEvents = true;
                clonedPathResource.definition.config.omitPages = false;
            }

            this.$emit('change', clonedPathResource);
        },
        onIncludedResourcesChange (option) {
            const { id } = option;
            if (!isString(id)) return;

            const resources = id.split(',');
            const clonedPathResource = cloneDeep(this.pathResource);
            const includedResources = new Set(resources);

            clonedPathResource.definition.config.omitPages = !includedResources.has('pages');
            clonedPathResource.definition.config.features = includedResources.has('features');
            clonedPathResource.definition.config.trackEvents = includedResources.has('trackEvents');
            clonedPathResource.definition.config.guides = includedResources.has('guides');

            this.$emit('change', clonedPathResource);
        },
        onCollapseDupsChange (collapseDups) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.collapseDups = collapseDups;
            this.$emit('change', clonedPathResource);
        },
        onRemoveVisitorDups (uniqueVisitors) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.uniqueVisitors = uniqueVisitors;
            this.$emit('change', clonedPathResource);
        },
        onPathLengthChange (maxLength) {
            const clonedPathResource = cloneDeep(this.pathResource);
            clonedPathResource.definition.config.maxLength = maxLength;
            this.$emit('change', clonedPathResource);
        }
    }
};
</script>

<style lang="scss">
.event-type-chooser {
    .select-all-checkbox-options {
        margin: 0 16px;
    }

    .pendo-multiselect__content-wrapper {
        padding-top: 0;
    }
}

.query-builder {
    .pendo-card {
        overflow-x: hidden;

        &__content {
            overflow-x: auto;
        }

        &__body {
            min-width: 650px;
            width: 100%;
        }
    }
}

.query-builder__top {
    display: grid;
    grid-template-rows: auto min-content;
    align-items: end;
    gap: 2rem 2rem;
}

.query-builder__root-config {
    display: flex;
}

.query-builder__root-resource {
    margin-left: 2rem;
}

.query-builder__direction {
    min-width: 176px;
    padding-right: 2rem;
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid $gray-lighter-5;

    .query-builder__secondary-text {
        display: inline-block;
        padding-bottom: 0.5rem;
    }
}

.resource-selector {
    padding-bottom: 6px;
}

.query-builder__across-apps {
    border-top: 1px dashed $gray-lighter-5;
    padding-top: 2rem;
    display: flex;
    align-items: center;
    grid-column-start: 1;
    grid-column-end: 3;
}

.query-builder__across-apps-label {
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    margin: 0 0 0 0.5rem;
}

.query-builder__advanced-length {
    overflow-x: auto;

    :deep(.pendo-input-number__label) {
        font-weight: normal;
        color: $gray-lighter-1;
    }
}

.query-builder-section-enter,
.query-builder-section-leave-to {
    height: 0 !important; /* stylelint-disable-line */
    padding-top: 0 !important; /* stylelint-disable-line */
    padding-bottom: 0 !important; /* stylelint-disable-line */
    margin-top: 0 !important; /* stylelint-disable-line */
    margin-bottom: 0 !important; /* stylelint-disable-line */
}

.query-builder-section-enter-to,
.query-builder-section-leave {
    height: 110px;
}

.query-builder {
    .pendo-card__body {
        padding: 0;
    }
}

.query-builder__section {
    padding: 24px;

    &:not(:first-child) {
        border-top: 0.5px solid $gray-lighter-5;
    }
}

.query-builder__card-header {
    margin-right: 1rem;
}

.query-builder__footer {
    display: flex;
    grid-template-columns: 1fr auto auto;
    column-gap: 1rem;
    align-items: center;
    justify-content: flex-end;
}

.query-builder__segment {
    display: flex;
    justify-content: space-between;
}

.query-builder__title {
    padding: 0;
    margin: 0;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 14px;
}

.query-builder__subtitle {
    padding: 0;
    margin: 0;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
}

.query-builder__secondary-text {
    font-size: 1.5rem;
    font-size: 15px;
    color: $gray-lighter-2;
}

.query-builder__close-segment {
    margin-right: 0.5rem;
}

.query-builder__save {
    margin-left: 1rem;
}

.query-builder__advanced {
    .pendo-collapse {
        border: none;

        .pendo-collapse-item {
            border: none;

            &__header {
                padding: 0;
                min-height: unset;
            }

            &__title {
                grid-template-columns: min-content min-content;
                white-space: nowrap;
            }

            &__content {
                margin: 1rem 0;

                > *:not(:last-child) {
                    margin-bottom: 1rem;
                }

                .pendo-checkbox,
                .pendo-checkbox__label {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.unshared-resource-alert {
    margin-left: 2rem;
}
</style>
