import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';

/* This segment builder started out as two unique segment builders in Adopt and Engage
 * with unique solutions on how segment filter options get saved to the BE. This section
 * is intended to provide a clear-cut way to carefully migrate segment filters from both apps
 * to conform to a consistent pattern. This does NOT impact saved aggs; only how the segment
 * builder represents the agg in user-selection form so we can correctly render the builder
 * UI across both platforms. */

export function migrateRule (rule) {
    // NOTE: Keep these in order in case we change decisions in the future!
    rule = migrateStartEndKeys(rule);
    rule = migrateGranularityCountArrayToKeys(rule);

    return rule;
}

/* Context: Adopt segments use start/end values while Engage segments use first/last
 * Migration: Use first/last to match with timeSeries key fields:
 *
 * `{ value: { start: 123, end: 456 } }`
 *  becomes
 * `{ value: { first: 123, last: 456 } }` */
function migrateStartEndKeys (rule) {
    if (!isUndefined(rule.value?.start)) {
        rule.value.first = rule.value.start;
        delete rule.value.start;
    }

    if (!isUndefined(rule.value?.end)) {
        rule.value.last = rule.value.end;
        delete rule.value.end;
    }

    return rule;
}

/* Context: Adopt segments overload `value` with `count`/`granularity` array
 * while Engage segments use unique fields.
 * Migration: Use unique fields under `value` Object instead of Array for metadata:
 *
 * `{ value: [5, "weeks"] }`
 *  becomes
 * `{ value: { count: 5, granularity: "weeks" } }` */
function migrateGranularityCountArrayToKeys (rule) {
    if (isArray(rule.value)) {
        const [count, granularity] = rule.value;
        rule.value = { count, granularity };
    }

    return rule;
}
