<template>
    <pendo-multiselect
        :allow-empty="false"
        append-to-body
        :loading="isLoading"
        :disable-while-loading="false"
        :disabled="readOnly"
        :extra-label-width="60"
        :internal-search="!limitExceeded"
        :max-menu-height="maxMenuHeight"
        min-trigger-width="80px"
        :options="options"
        placeholder="Type value here"
        searchable
        taggable
        tag-placeholder="(new)"
        :value="value"
        @input-value-change="onInputValueChanged"
        @select="onOptionSelected"
        @tag="onOptionSelected">
        <template #header>
            <div
                v-if="showSearchHeader"
                class="metadata-value-select--search-notice"
                data-cy="metadata-value-select--search-notice">
                <pendo-alert :show-icon="false">
                    Begin your search with the <strong>first case-sensitive letters</strong> of a value (e.g. “Pen” for
                    “Pendo”)
                </pendo-alert>
            </div>
            <p
                v-if="limitExceeded"
                class="metadata-value-select--limit-notice"
                data-cy="metadata-value-select--limit-notice">
                Showing first 400 results only
            </p>
        </template>
        <template #noData>
            <p class="metadata-value-select--empty-state">
                No options available
            </p>
        </template>
    </pendo-multiselect>
</template>

<script>
import { PendoAlert, PendoMultiselect, PendoNotification } from '@pendo/components';
import { queryMetadataValues } from '@/components/segment-builder/utils/metadata';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';

export default {
    name: 'MetadataValueSelect',
    components: {
        PendoAlert,
        PendoMultiselect
    },
    mixins: [ReadOnlyMixin],
    props: {
        field: {
            type: String,
            default: 'value'
        },
        kind: {
            type: String,
            default: ''
        },
        group: {
            type: String,
            default: ''
        },
        fieldName: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            isLoading: true,
            initialLimitExceeded: false,
            initialOptions: [],
            limitExceeded: false,
            options: [],
            searchValue: ''
        };
    },
    computed: {
        showSearchHeader () {
            return this.searchValue === '' && this.initialLimitExceeded;
        },
        maxMenuHeight () {
            const defaultHeight = 302;
            const searchNoticeHeight = 100;
            const limitNoticeHeight = 30;

            if (this.showSearchHeader) {
                return defaultHeight + searchNoticeHeight + limitNoticeHeight;
            }

            return this.limitExceeded ? defaultHeight + limitNoticeHeight : defaultHeight;
        }
    },
    async created () {
        this.getOptionsDebounce = debounce(this.getOptions, 500);
        await this.getOptions('');
        this.initialLimitExceeded = this.limitExceeded;
        if (this.initialLimitExceeded) {
            this.initialOptions = clone(this.options);
        }

        const value = isString(this.value) ? this.value : '';
        this.onOptionSelected(value);
    },
    methods: {
        async getOptions (query) {
            if (query === '' && this.initialOptions.length !== 0) {
                this.limitExceeded = this.initialLimitExceeded;
                this.options = clone(this.initialOptions);
                this.isLoading = false;

                return;
            }

            this.isLoading = true;

            try {
                const { data } = await queryMetadataValues(this.kind, this.group, this.fieldName, query);

                this.limitExceeded = data.limitExceeded;
                this.options = data.values;
            } catch {
                PendoNotification({
                    type: 'error',
                    message: 'Unable to fetch metadata values',
                    duration: 5000
                });
            }

            this.isLoading = false;
        },
        onInputValueChanged (inputValue) {
            if (this.initialLimitExceeded) {
                this.searchValue = inputValue;
                this.isLoading = true;
                this.getOptionsDebounce(inputValue);
            }
        },
        onOptionSelected (value) {
            if (this.fieldName === 'lastdevicemodel') {
                value = value.toLowerCase();
            }
            this.$emit('input', { field: this.field, value });
        }
    }
};
</script>

<style lang="scss" scoped>
.metadata-value-select {
    &--empty-state {
        color: $gray-lighter-2;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        text-align: center;
    }

    &--limit-notice {
        font-weight: bold;
        margin: 0;
        padding: 8px 16px;
        text-align: center;
    }

    &--search-notice {
        padding: 16px 16px 8px;
    }
}
</style>
