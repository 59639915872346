<template>
    <div class="workflow-baseline-config">
        <pendo-button
            v-if="!savedDateRange"
            data-cy="no-baseline-btn"
            type="link"
            label="Want to measure impact against a baseline?"
            :disabled="disabled"
            @click="showModal = true" />
        <div
            v-else
            data-cy="saved-baseline-label"
            class="date-selected">
            <span>Comparing to baseline of </span>
            <span class="date-range">{{ savedDateRangeLabel }} </span>
            <pendo-button
                theme="app"
                data-cy="edit-baseline-btn"
                type="link"
                suffix-icon="edit-3"
                label="Edit"
                :disabled="disabled"
                @click="showModal = true" />
        </div>
        <pendo-modal
            title="Set baseline"
            data-cy="baseline-config-modal"
            height="339px"
            width="520px"
            :visible.sync="showModal"
            @close="resetRange">
            <div class="baseline-config-modal-content">
                <p class="description">
                    Measure process improvements over time. Choose a date range as your baseline to compare previous
                    metrics against your current metrics.
                </p>
                <span class="date-range-label">Baseline date range</span>
                <div class="picker">
                    <pendo-date-picker
                        v-model="range"
                        data-cy="baseline-date-picker"
                        :class="range === null ? 'show-placeholder' : null"
                        :shortcuts="false"
                        placeholder="Select a date range"
                        type="range"
                        :min-date="minDate"
                        :max-date="new Date()"
                        :columns="2" />
                    <pendo-button
                        v-if="range"
                        data-cy="clear-baseline-btn"
                        class="clear"
                        theme="app"
                        type="link"
                        label="Clear"
                        @click="clearRange" />
                </div>
                <span
                    v-if="invalidDateRange"
                    class="invalid-daterange">
                    Date range cannot exceed 90 days
                </span>
            </div>
            <template #footer>
                <pendo-button
                    type="secondary"
                    label="Cancel"
                    data-cy="cancel-btn"
                    @click="cancelSave" />
                <pendo-button
                    type="primary"
                    theme="app"
                    label="Save"
                    data-cy="save-btn"
                    :disabled="disableSave"
                    @click="handleSave" />
            </template>
        </pendo-modal>
    </div>
</template>

<script>
import { PendoButton, PendoModal, PendoDatePicker } from '@pendo/components';
import get from 'lodash/get';

export default {
    name: 'WorkflowsBaselineModal',
    components: {
        PendoButton,
        PendoModal,
        PendoDatePicker
    },
    props: {
        savedDateRange: {
            type: Object,
            default: null
        },
        earliestAppFirstVisit: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['save'],
    data () {
        return {
            showModal: false,
            range: null
        };
    },
    computed: {
        minDate () {
            let { earliestAppFirstVisit } = this;

            if (!earliestAppFirstVisit) {
                const today = new Date();
                const defaultFirstVisit = today.setFullYear(today.getFullYear() - 1);

                earliestAppFirstVisit = defaultFirstVisit;
            }

            return this.$pendo
                .moment(earliestAppFirstVisit)
                .startOf('day')
                .toDate();
        },
        disableSave () {
            if (this.range === null) return false;
            const dateRangeStart = get(this.savedDateRange, 'start', null);
            const dateRangeEnd = get(this.savedDateRange, 'end', null);
            const startDateUnchanged = this.$pendo.moment(this.range.start).isSame(dateRangeStart);
            const endDateUnchanged = this.$pendo.moment(this.range.end).isSame(dateRangeEnd);

            return (startDateUnchanged && endDateUnchanged) || this.invalidDateRange;
        },
        invalidDateRange () {
            if (this.range === null) return false;
            const daysBetweenSelection = this.$pendo.moment(this.range.end).diff(this.range.start, 'days');

            return daysBetweenSelection > 90;
        },
        savedDateRangeLabel () {
            if (!this.savedDateRange) return '';

            const startLabel = this.$pendo.moment(this.savedDateRange.start).format('ll');
            const endLabel = this.$pendo.moment(this.savedDateRange.end).format('ll');

            return `${startLabel} - ${endLabel}`;
        }
    },
    created () {
        this.resetRange();
    },
    methods: {
        resetRange () {
            if (!this.savedDateRange) {
                this.range = null;
            } else {
                this.range = {
                    start: new Date(this.savedDateRange.start),
                    end: new Date(this.savedDateRange.end)
                };
            }
        },
        clearRange () {
            this.range = null;
        },
        cancelSave () {
            this.resetRange();
            this.showModal = false;
        },
        handleSave () {
            let setting;
            if (this.range === null) {
                setting = null;
            } else {
                setting = {
                    start: this.range.start,
                    end: this.range.end
                };
            }
            this.$emit('save', setting);
            this.showModal = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.show-placeholder {
    :deep(.pendo-date-picker__input-value) {
        color: $gray-lighter-2;
    }
}

.date-selected {
    font-size: 13px;

    .date-range {
        font-weight: 600;
    }

    .pendo-button {
        font-size: 13px;
        margin-left: 8px;

        :deep(.icon-suffix) {
            padding-left: 6px;
        }
    }
}

.baseline-config-modal-content {
    .description {
        margin-top: 15px;
        margin-bottom: 24px;
    }

    .date-range-label {
        font-weight: 600;
    }

    .picker {
        display: flex;

        .clear {
            font-size: 13px;
            margin-left: 8px;
        }
    }

    .invalid-daterange {
        float: left;
        margin-top: 5px;
        padding: 8px;
        background: $red-error-bg;
        color: $red-error;
    }
}
</style>
