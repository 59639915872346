<template>
    <div class="poll-localizer-input">
        <pendo-card
            class="translation-unit-card"
            body-min-height="34px">
            <div class="translation-unit-card__prepend">
                <pendo-icon v-bind="pollType.icon" />
            </div>
            <div class="translation-unit-card__body">
                <div class="poll-header">
                    {{ pollType.name }}
                </div>
                <component
                    :is="getTranslationInput(pollBlock)"
                    v-for="pollBlock in transUnitGroup.blocks"
                    :key="pollBlock.id"
                    class="translation-unit"
                    :trans-unit-group="pollBlock"
                    :trans-unit-map="transUnitMap"
                    :trans-unit-heights="transUnitHeights"
                    :initial-trans-unit-values="initialTransUnitValues"
                    :value-key="valueKey"
                    :disabled="disabled"
                    :warnings="warnings"
                    @change="$emit('change', $event)"
                    @changeHeight="onChangeHeight" />
            </div>
        </pendo-card>
    </div>
</template>

<script>
import { Polls } from '@pendo/services/BuildingBlocks';
import { PendoCard, PendoInput, PendoIcon } from '@pendo/components';
import ElementLocalizer from './ElementLocalizer';
import TextLocalizer from './TextLocalizer';

export default {
    name: 'PollLocalizer',
    components: {
        ElementLocalizer,
        TextLocalizer,
        PendoCard,
        PendoInput,
        PendoIcon
    },
    props: {
        transUnitGroup: {
            type: Object,
            required: true
        },
        transUnitMap: {
            type: Object,
            required: true
        },
        transUnitHeights: {
            type: Object,
            default: () => ({})
        },
        initialTransUnitValues: {
            type: Array,
            default: () => []
        },
        valueKey: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        warnings: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['change', 'changeHeight'],
    computed: {
        pollType () {
            const { pollType } = this.transUnitGroup;
            const icon = (props) => ({
                stroke: '#2A2C35',
                size: 16,
                ...props
            });

            switch (pollType) {
                case Polls.pollTypes.freeForm:
                    return {
                        icon: icon({ type: 'open-text' }),
                        name: 'Open text poll'
                    };
                case Polls.pollTypes.positiveNegative:
                    return {
                        icon: icon({
                            type: 'check-x',
                            strokeWidth: 3,
                            size: 22
                        }),
                        name: 'Yes / No poll'
                    };
                case Polls.pollTypes.numberScale:
                    return {
                        icon: icon({ type: 'hash' }),
                        name: 'Number scale poll'
                    };
                case Polls.pollTypes.pickList:
                    return {
                        icon: icon({ type: 'list' }),
                        name: 'Multi choice poll'
                    };
                default:
                    return {
                        icon: icon({ type: 'list' }),
                        name: 'Poll'
                    };
            }
        }
    },
    methods: {
        onChangeHeight (...args) {
            this.$emit('changeHeight', ...args);
        },
        getTranslationInput ({ type: transUnitType }) {
            if (transUnitType === 'TextView') {
                return 'TextLocalizer';
            }

            return 'ElementLocalizer';
        }
    }
};
</script>

<style lang="scss" scoped>
.translation-unit-card {
    :deep(.pendo-card__body) {
        display: flex;
    }

    &__prepend {
        border-right: 1px solid $color-gray-40;
        padding: 8px 0;
        width: 33px;
        display: flex;
        justify-content: center;
        background-color: $color-gray-10;
    }

    &__body {
        padding: 8px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .poll-header {
            font-weight: 600;
        }
    }
}
</style>
