<template>
    <div class="page-rule">
        <!-- page list picker -->
        <div :class="{ 'page-undefined': rule.pageDeleted }">
            <pendo-multiselect
                :value="rule.page"
                :allow-empty="false"
                :options="pageListPickerOptions"
                :searchable="true"
                :disabled="readOnly"
                label-key="displayName"
                value-key="id"
                full-width
                placeholder="Select Page"
                class="page-rule--page-select"
                @select="onPageSelect" />
            <rule-warning v-if="rule.pageDeleted">
                The original page for this rule has been deleted
            </rule-warning>
            <rule-warning v-else-if="isPageOrphaned">
                The page used by this rule is no longer being shared.
            </rule-warning>
        </div>

        <pendo-multiselect
            :value="rule.operator"
            :allow-empty="false"
            preselect-first
            full-width
            value-key="value"
            :options="operatorOptions"
            :disabled="!operatorOptions || readOnly"
            class="rule-editor--operator"
            @select="handlePageOperatorSelection" />
        <pendo-multiselect
            :value="pageTimeLabelObject(rule.time)"
            value-key="value"
            label-key="label"
            min-trigger-width="100%"
            :allow-empty="false"
            :preselect-first="true"
            :options="pageTimeRangeOptions"
            :disabled="readOnly"
            class="page-rule--page-time-operator"
            @select="handlePageTimeSelection" />
        <number-input
            v-if="showNumberInputComponent"
            class="rule-editor--item complex-rule number-input--max-height"
            :labels="{ suffix: 'times' }"
            :value="rule.value"
            :min="1"
            @input="$emit('input', $event)" />
        <component
            :is="timeComponent.component"
            v-if="showTimeComponent"
            v-bind="timeComponent.props"
            @input="$emit('input', $event)" />
    </div>
</template>

<script>
import AbsoluteDateRange from '../rule-components/AbsoluteDateRange.vue';
import NumberInput from '../rule-components/NumberInput.vue';
import RelativeDateRange from '../rule-components/RelativeDateRange.vue';
import RuleWarning from '../rule-components/RuleWarning';
import SingleDate from '../rule-components/SingleDate.vue';
import {
    SEGMENT_OPERATORS,
    PAGE_TIME_RANGE_VIEWED_OPTIONS,
    PAGE_TIME_RANGE_LAST_VIEWED_OPTIONS,
    PAGE_TIME_RANGE_NOT_VIEWED_OPTIONS
} from '@/components/segment-builder/constants/segments';
import { PendoMultiselect } from '@pendo/components';
import { getEntitiesByAppSelection } from '@/components/segment-builder/utils/utils';
import {
    getAbsoluteDateRangeConfig,
    getRelativeDateRangeConfig,
    getSingleDateConfig
} from '@/components/segment-builder/utils/segments';
import ReadOnlyMixin from '../rule-mixins/ReadOnly.mixin';
import get from 'lodash/get';

export default {
    name: 'PageRule',
    components: {
        AbsoluteDateRange,
        NumberInput,
        PendoMultiselect,
        RelativeDateRange,
        RuleWarning,
        SingleDate
    },
    mixins: [ReadOnlyMixin],
    props: {
        computedData: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },
    computed: {
        filteredPageList () {
            const { pages } = this.computedData;

            if (this.config.hasAdoptCustomPageSegments) {
                return pages;
            }

            return pages.filter((page) => !page.isChildPage);
        },
        isPageOrphaned () {
            const { rule } = this;

            return rule.page && !rule.isPageShared && !rule.isCustomPage;
        },
        pageListPickerOptions () {
            return getEntitiesByAppSelection(this.rule.app, this.filteredPageList, this.config);
        },
        operatorOptions () {
            return [...SEGMENT_OPERATORS[this.rule.schema.schema]];
        },
        pageTimeRangeOptions () {
            switch (this.rule.operator.value) {
                case 'lastviewed':
                    return PAGE_TIME_RANGE_LAST_VIEWED_OPTIONS;
                case 'notviewed':
                    return PAGE_TIME_RANGE_NOT_VIEWED_OPTIONS;
                case 'viewed':
                default:
                    return PAGE_TIME_RANGE_VIEWED_OPTIONS;
            }
        },
        showNumberInputComponent () {
            return (
                this.rule.operator.value === 'viewed' && (this.rule.time === 'atmost' || this.rule.time === 'atleast')
            );
        },
        showTimeComponent () {
            if (this.showNumberInputComponent) {
                return false;
            }

            const { rule } = this;
            const operatorValue = get(rule, 'operator.value');

            return !!(rule.page && operatorValue && rule.time && rule.time !== 'ever');
        },
        timeComponent () {
            if (['withinlast', '!withinlast'].includes(this.rule.time)) {
                return getRelativeDateRangeConfig(this.rule, 'value');
            }

            if (['between'].includes(this.rule.time)) {
                return getAbsoluteDateRangeConfig(this.rule);
            }

            if (['before', 'since'].includes(this.rule.time)) {
                return getSingleDateConfig(this.rule, 'value');
            }

            return;
        },
        showTimeSelector () {
            const { rule } = this;

            return !!rule.page;
        }
    },
    methods: {
        pageTimeLabelObject (timeString) {
            return this.pageTimeRangeOptions.find((opt) => opt.value === timeString);
        },
        handlePageOperatorSelection ($event) {
            const updates = [{ field: 'operator', value: $event }];
            // handle switching to operator where `at least / at most` not supported
            if (this.rule.time === 'atleast' || this.rule.time === 'atmost') {
                if ($event.value === 'notviewed') {
                    updates.push({ field: 'time', value: 'ever' });
                }

                if ($event.value === 'lastviewed') {
                    updates.push({ field: 'time', value: 'before' });
                }
            }
            this.$emit('input', updates);
        },
        handlePageTimeSelection ($event) {
            const { value } = $event;

            if (value === 'withinlast' || value === '!withinlast') {
                this.$emit('input', { field: 'value', value: { count: 30, granularity: 'days' } });
            } else if (value !== 'before' && value !== 'since') {
                this.$emit('input', { field: 'value', value: 1 });
            }
            this.$emit('input', { field: 'time', value });
        },
        onPageSelect (page) {
            this.$emit('input', [
                { field: 'page', value: page },
                { field: 'isPageShared', value: true },
                { field: 'pageDeleted', value: false }
            ]);
        }
    }
};
</script>

<style lang="scss" scoped>
.page-rule {
    display: contents;

    .page-undefined {
        :deep(.pendo-multiselect__trigger) {
            border: 1px solid $red-error;
        }
    }
}
</style>
