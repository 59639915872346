<template>
    <journeys-empty-state
        v-if="showEmptyState"
        :has-saved-workflow="hasSavedWorkflow"
        :is-fetching="isFetching"
        :is-saving="isSaving"
        @showBlade="$emit('showBlade')" />
    <div
        v-else
        class="journeys-dashboard">
        <journeys-filter-bar
            :app-ids="appIds"
            :loading="isLoading"
            :terminal-nodes="[savedWorkflowStart, savedWorkflowEnd]"
            :filtered-percentage="filteredJourneyPercentage"
            :has-journey-data="completedJourneys ? completedJourneys.length === 0 : false" />
        <journeys-content
            :loading="isLoading"
            :journey-data="formattedCompletedJourneys"
            :included-steps="includedSteps"
            :insights-showing="insightsShowing"
            @hideInsights="hideInsights"
            @toggleInsights="toggleInsights"
            @toggleIncludedStep="toggleIncludedStep"
            @updateFilteredPercentage="updateFilteredPercentage" />
    </div>
</template>

<script>
import { PendoNotification } from '@pendo/components';
import JourneysFilterBar from './JourneysFilterBar';
import JourneysContent from '@/stateless-components/workflows/journeys/JourneysContent';
import JourneysEmptyState from '@/stateless-components/workflows/journeys/JourneysEmptyState';
import cloneDeep from 'lodash/cloneDeep';
import { connectAggregationToState, withStore, withComponent } from '@pendo/agg-connect';
import completedJourneysAgg from '@/aggregations/completed-journeys';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { hasInvalidWorkflowSteps } from '@/utils/workflows';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import { v4 as uuid } from 'uuid';

export default {
    name: 'JourneysPage',
    components: {
        JourneysFilterBar,
        JourneysContent,
        JourneysEmptyState
    },
    directives: {
        PendoNotification
    },
    emits: ['showBlade'],
    data () {
        return {
            completedJourneysAggStatus: 'loading',
            completedJourneys: [],
            filteredJourneyPercentage: ''
        };
    },
    computed: {
        ...mapState({
            includedSteps: (state) => state.workflows.includedSteps,
            insightsShowing: (state) => state.workflows.insightsShowing,
            isFetching: (state) => state.workflows.isFetching,
            isSaving: (state) => state.workflows.isSaving,
            // It's used but eslint doesn't recognize it use in the connect to state function
            // eslint-disable-next-line vue/no-unused-properties
            activeSegmentId: (state) => state.filters.activeSegmentId
        }),
        ...mapGetters({
            pageById: 'pages/pageById',
            featureById: 'features/featureById',
            appById: 'apps/appById',
            pageList: 'pages/list',
            featureList: 'features/list',
            savedWorkflow: 'workflows/savedWorkflow',
            // It's used but eslint doesn't recognize it use in the connect to state function
            // eslint-disable-next-line vue/no-unused-properties
            activeTimeSeries: 'filters/activeTimeSeries'
        }),
        hasInvalidSavedWorkflowSteps () {
            if (this.savedWorkflow) {
                const pagesMap = keyBy(this.pageList, 'id');
                const featuresMap = keyBy(this.featureList, 'id');

                return hasInvalidWorkflowSteps(this.savedWorkflow.workflowSteps, { featuresMap, pagesMap });
            }

            return false;
        },
        savedJourney () {
            return this.savedWorkflow ? cloneDeep(this.savedWorkflow.journey) : null;
        },
        showEmptyState () {
            return !this.hasSavedWorkflow || !this.hasSavedJourney || this.hasInvalidSavedWorkflowSteps;
        },
        hasSavedWorkflow () {
            return this.savedWorkflow && !this.hasInvalidSavedWorkflowSteps;
        },
        hasSavedJourney () {
            if (this.savedJourney) {
                return this.savedJourney.pageIds.length || this.savedJourney.featureIds.length;
            }

            return false;
        },
        appIds () {
            if (!this.savedWorkflowStart || !this.savedWorkflowEnd) return;

            const startAppId = this.getAppIdOfCountable(
                this.savedWorkflowStart.countableId,
                this.savedWorkflowStart.countableKind
            );
            const endAppId = this.getAppIdOfCountable(
                this.savedWorkflowEnd.countableId,
                this.savedWorkflowEnd.countableKind
            );

            const pageAppIds = this.pageIds.map((pageId) => {
                return get(this.pageById(pageId), 'appId');
            });

            const featureAppIds = this.featureIds.map((featureId) => {
                return get(this.featureById(featureId), 'appId');
            });

            return uniq([startAppId, endAppId, ...pageAppIds, ...featureAppIds]);
        },
        maxDuration () {
            return get(this, 'savedWorkflow.maxDuration');
        },
        savedWorkflowSteps () {
            return get(this.savedWorkflow, 'workflowSteps', []).reduce(
                (result, step) => {
                    result[step.type] = step;

                    return result;
                },
                {
                    start: undefined,
                    end: undefined
                }
            );
        },
        savedWorkflowStart () {
            return get(this, 'savedWorkflowSteps.start');
        },
        savedWorkflowEnd () {
            return get(this, 'savedWorkflowSteps.end');
        },
        pageIds () {
            const pageIds = get(this, 'savedJourney.pageIds', []);

            return pageIds.filter((pageId) => get(this.pageById(pageId), 'appId'));
        },
        featureIds () {
            const featureIds = get(this, 'savedJourney.featureIds', []);

            return featureIds.filter((featureId) => get(this.featureById(featureId), 'appId'));
        },
        formattedCompletedJourneys () {
            if (!this.completedJourneys) return [];

            const formattedJourneys = this.completedJourneys.map((completedJourney) => {
                const avgStepTimestamps = get(completedJourney, 'averageStepTimestamps').map((time) => {
                    return Math.round(time * 10) / 10;
                });
                const journeySteps = get(completedJourney, 'journey');

                const avgTimeTaken = avgStepTimestamps[avgStepTimestamps.length - 1] - avgStepTimestamps[0];

                const journey = [];
                let lastTraversedStep;

                journeySteps.forEach((step, index) => {
                    let avgTimeSinceLastStep = null;

                    if (index > 0) {
                        avgTimeSinceLastStep = avgStepTimestamps[index] - avgStepTimestamps[index - 1];
                    }

                    const countableDetails = this.getCountableDetails(step);

                    const app = this.appById(countableDetails.appId);
                    const journeyStep = {
                        ...step,
                        stepId: step.pageId || step.featureId,
                        nodeId: uuid(),
                        appName: app.displayName,
                        favicon: app.faviconB64,
                        color: app.color,
                        platform: app.platform,
                        name: countableDetails.displayName,
                        avgTimeSinceLastStep
                    };

                    if (lastTraversedStep && avgTimeSinceLastStep === 0) {
                        const duplicateTags = lastTraversedStep.duplicateTags
                            ? lastTraversedStep.duplicateTags
                            : [lastTraversedStep];

                        if (!duplicateTags.find((step) => step.stepId === journeyStep.stepId)) {
                            duplicateTags.push(journeyStep);
                        }

                        lastTraversedStep.duplicateTags = duplicateTags;
                    } else {
                        journey.push(journeyStep);
                        lastTraversedStep = journeyStep;
                    }
                });

                const numSteps = journey.length;

                return {
                    id: uuid(),
                    avgTimeTaken,
                    numSteps,
                    numAttempts: completedJourney.numAttempts,
                    journey
                };
            });

            return formattedJourneys;
        },
        isLoading () {
            return this.completedJourneysAggStatus === 'loading';
        }
    },
    watch: {
        completedJourneys () {
            this.setIncludedSteps({ updatedSteps: {} });
            this.updateFilteredPercentage('');
        }
    },
    created () {
        if (this.hasInvalidSavedWorkflowSteps) {
            this.$router.push({
                name: 'workflowsDetailsHome',
                params: {
                    id: this.savedWorkflow.id
                }
            });

            return;
        }
        this.createCompletedJourneysAgg$();
    },
    methods: {
        ...mapActions({
            toggleIncludedStep: 'workflows/toggleIncludedStep'
        }),
        ...mapMutations({
            setIncludedSteps: 'workflows/setIncludedSteps',
            toggleInsights: 'workflows/toggleInsights',
            hideInsights: 'workflows/hideInsights'
        }),
        createCompletedJourneysAgg$ () {
            const completedJourneysAgg$ = connectAggregationToState(
                completedJourneysAgg,
                withComponent({
                    appIds: 'appIds',
                    featureIds: 'featureIds',
                    pageIds: 'pageIds',
                    segmentId: 'activeSegmentId',
                    timeSeries: 'activeTimeSeries',
                    workflowStart: 'savedWorkflowStart',
                    workflowEnd: 'savedWorkflowEnd',
                    maxDuration: 'maxDuration'
                }),
                withStore({})
            )({ component: this, store: this.$store });

            completedJourneysAgg$.subscribe((response) => {
                const { status, value } = response;
                this.completedJourneysAggStatus = status;
                switch (status) {
                    case 'loading':
                        this.completedJourneys = null;
                        break;
                    case 'resolved': {
                        this.completedJourneys = value;
                        break;
                    }
                    case 'rejected': {
                        // eslint-disable-next-line no-console
                        console.warn(response.error);
                        PendoNotification({
                            type: 'error',
                            message:
                                'Something went wrong when trying to retrieve updated journeys data. Please try again.'
                        });
                        break;
                    }
                }
            });
        },
        getAppIdOfCountable (countableId, countableKind) {
            if (countableKind === 'Page') return get(this.pageById(countableId), 'appId');
            if (countableKind === 'Feature') return get(this.featureById(countableId), 'appId');

            return '';
        },
        getCountableDetails (countable) {
            if (countable.pageId) {
                return this.pageById(countable.pageId);
            }

            if (countable.featureId) {
                return this.featureById(countable.featureId);
            }

            return null;
        },
        updateFilteredPercentage (newFilteredDialogue) {
            this.filteredJourneyPercentage = newFilteredDialogue;
        }
    }
};
</script>

<style lang="scss" scoped>
.journeys-dashboard {
    display: grid;
    grid-template-columns: minmax(372px, 1fr);
    grid-gap: 12px;

    :deep(.insights-popover) {
        z-index: 3;
    }
}
</style>
