<template>
    <div
        v-show="carousel.pageCount > 1"
        class="VueCarousel-pagination"
        :class="{ [`VueCarousel-pagination--${paginationPositionModifierName}`]: paginationPositionModifierName }">
        <div
            class="VueCarousel-dot-container"
            role="tablist"
            :style="dotContainerStyle">
            <button
                v-for="(page, index) in paginationCount"
                :key="`${page}_${index}`"
                class="VueCarousel-dot"
                aria-hidden="false"
                role="tab"
                :title="getDotTitle(index)"
                :value="getDotTitle(index)"
                :aria-label="getDotTitle(index)"
                :aria-selected="isCurrentDot(index) ? 'true' : 'false'"
                :class="{ 'VueCarousel-dot--active': isCurrentDot(index) }"
                :style="dotStyle(index)"
                @click="goToPage(index)" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'Pagination',
    inject: ['carousel'],
    emits: ['paginationclick'],
    computed: {
        paginationPositionModifierName () {
            const { paginationPosition } = this.carousel;
            // guard to add only required class modifiers
            if (paginationPosition.indexOf('overlay') < 0) {
                return;
            }

            return paginationPosition;
        },
        paginationCount () {
            return this.carousel && this.carousel.scrollPerPage
                ? this.carousel.pageCount
                : this.carousel.slideCount || 0;
        },
        dotContainerStyle () {
            const { carousel } = this;
            if (carousel.maxPaginationDotCount === -1) {
                return {};
            }
            const doublePadding = carousel.paginationPadding * 2;
            const containerWidth = carousel.maxPaginationDotCount * (carousel.paginationSize + doublePadding);

            return {
                'overflow': 'hidden',
                'width': `${containerWidth}px`,
                'margin': '0 auto',
                'white-space': 'nowrap'
            };
        }
    },
    methods: {
        /**
         * Change page by index
         * @param {number} index
         * return {void}
         */
        goToPage (index) {
            /**
             * @event paginationclick
             * @type {number}
             */
            this.$emit('paginationclick', index);
        },

        /**
         * Check on current dot
         * @param {number} index - dot index
         * @return {boolean}
         */
        isCurrentDot (index) {
            return index === this.carousel.currentPage;
        },

        /**
         * Generate dot title
         * @param {number} index - dot index
         * @return {string}
         */
        getDotTitle (index) {
            return this.carousel.$children[index].title ? this.carousel.$children[index].title : `Item ${index}`;
        },
        /**
         * Control dots appear and disappear
         * @param {number} index - dot index
         * @return {Object} - dot(buttn) style
         */
        dotStyle () {
            const { carousel } = this;
            const basicBtnStyle = {};

            if (carousel.maxPaginationDotCount === -1) {
                return basicBtnStyle;
            }

            const eachDotsWidth = carousel.paginationSize + carousel.paginationPadding * 2;
            const maxReverse = carousel.pageCount - carousel.maxPaginationDotCount;
            let translateAmount;
            if (carousel.currentPage > maxReverse) {
                translateAmount = maxReverse;
            } else if (carousel.currentPage <= carousel.maxPaginationDotCount / 2) {
                translateAmount = 0;
            } else {
                translateAmount = carousel.currentPage - Math.ceil(carousel.maxPaginationDotCount / 2) + 1;
            }

            const transformWidth = 0 - eachDotsWidth * translateAmount;

            return Object.assign(basicBtnStyle, {
                '-webkit-transform': `translate3d(${transformWidth}px,0,0)`,
                'transform': `translate3d(${transformWidth}px,0,0)`,
                '-webkit-transition': `-webkit-transform ${carousel.speed / 1000}s`,
                'transition': `transform ${carousel.speed / 1000}s`
            });
        }
    }
};
</script>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.VueCarousel-pagination {
    width: 100%;
    text-align: center;
}

.VueCarousel-pagination--top-overlay {
    position: absolute;
    top: 0;
}

.VueCarousel-pagination--bottom-overlay {
    position: absolute;
    bottom: 0;
}

.VueCarousel-dot-container {
    display: inline-block;
    margin-top: 5px;
    padding: 0 4px;
}

.VueCarousel-dot {
    display: inline-block;
    cursor: pointer;
    appearance: none;
    background-clip: padding-box;
    box-sizing: content-box;
    padding: 0;
    border-radius: 100%;
    outline: none;
    border-color: $gray-primary;
    border-style: solid;
    width: 8px;
    height: 8px;
    background-color: #fff;
    margin: 5px;
    padding: 0;

    &.VueCarousel-dot--active {
        background-color: $gray-primary;
    }
}

.VueCarousel-dot:focus {
    outline: 1px solid lightblue;
}
</style>
