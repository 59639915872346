<template>
    <div
        class="workflows-highlightsAggData-card"
        data-cy="workflows-highlightsAggData-card">
        <pendo-card body-min-height="108px">
            <template #header>
                <div class="card-title">
                    Highlights
                </div>
                <workflow-baseline-config
                    :saved-date-range="baselineDateRange"
                    :earliest-app-first-visit="earliestAppFirstVisit"
                    :disabled="!allowBaselineChange"
                    @save="handleBaselineDateRangeChange" />
            </template>
            <div class="stats">
                <div
                    class="stat"
                    data-cy="completion-rate">
                    <div
                        v-pendo-loading="{
                            loading: loadingHighlights,
                            skeletonProps: { height: '36', width: '128' }
                        }"
                        class="number">
                        {{ completionRate }}
                    </div>
                    <div class="description">
                        {{ isRecurring ? 'Workflow completion rate' : 'Visitor completion rate' }}
                    </div>
                </div>
                <div
                    class="stat"
                    data-cy="median-time-to-complete">
                    <div
                        v-pendo-loading="{
                            loading: loadingHighlights,
                            skeletonProps: { height: '36', width: '128' }
                        }"
                        class="number">
                        {{ medianTimeToComplete }}
                    </div>
                    <div class="description">
                        Median time to complete
                    </div>
                </div>
                <div
                    class="stat"
                    data-cy="visitor-completions">
                    <div
                        v-pendo-loading="{
                            loading: loadingHighlights,
                            skeletonProps: { height: '36', width: '128' }
                        }"
                        class="number">
                        {{ visitorCompletions }}
                    </div>
                    <div class="description">
                        {{ isRecurring ? 'Completed attempts' : 'Visitor completions' }}
                    </div>
                </div>
            </div>
            <div
                v-if="baselineDateRange"
                data-cy="baseline-comparison"
                class="comparison">
                <metric-comparison
                    :recurring="isRecurring"
                    :loading="loadingHighlights || loadingBaseline"
                    :baseline="baselineAggData"
                    :current="highlightsAggData" />
            </div>
        </pendo-card>
    </div>
</template>

<script>
import get from 'lodash/get';

import { PendoCard, PendoLoading } from '@pendo/components';

import WorkflowBaselineConfig from '@/stateless-components/workflows/details/WorkflowBaselineConfig';
import MetricComparison from '@/components/workflows/details/baseline/MetricComparison';
import { longPrettyTime } from '@/utils/durations';
import inRange from 'lodash/inRange';

export default {
    name: 'WorkflowsHighlightsCard',
    components: {
        PendoCard,
        WorkflowBaselineConfig,
        MetricComparison
    },
    directives: {
        PendoLoading
    },
    props: {
        isRecurring: {
            type: Boolean,
            default: false
        },
        loadingHighlights: {
            type: Boolean,
            default: false
        },
        loadingBaseline: {
            type: Boolean,
            default: false
        },
        savedWorkflowSettings: {
            type: Object,
            default: null
        },
        earliestAppFirstVisit: {
            type: Number,
            default: null
        },
        highlightsAggData: {
            type: Object,
            default: () => {}
        },
        baselineAggData: {
            type: Object,
            default: () => {}
        }
    },
    emits: ['updateBaselineDateRange'],
    computed: {
        baselineDateRange () {
            if (!this.savedWorkflowSettings) return null;

            return this.savedWorkflowSettings.baselineDateRange;
        },
        baselineDateRangeFormatted () {
            if (!this.baselineDateRange) return null;

            return {
                value: {
                    start: this.$pendo.moment(this.baselineDateRange.start).format('YYYY-MM-DD'),
                    end: this.$pendo.moment(this.baselineDateRange.end).format('YYYY-MM-DD')
                }
            };
        },
        allowBaselineChange () {
            return !this.loadingHighlights && !this.loadingBaseline;
        },
        completionsKey () {
            return this.isRecurring ? 'completedAttempts' : 'completedCount';
        },
        completionRate () {
            const totalsKey = this.isRecurring ? 'totalAttempts' : 'totalVisitors';
            const numerator = get(this.highlightsAggData, this.completionsKey, 0);
            const denominator = get(this.highlightsAggData, totalsKey, 0);

            if (denominator === 0) return '0%';

            const completionRate = (numerator / denominator) * 100;

            if (inRange(completionRate, 0.01, 0.499)) {
                return `${completionRate.toFixed(2)}%`;
            }

            if (inRange(completionRate, 0.5, 9.5)) {
                return `${completionRate.toFixed(1)}%`;
            }

            return `${Math.round(completionRate)}%`;
        },
        visitorCompletions () {
            const completions = get(this.highlightsAggData, this.completionsKey, 0);

            return completions.toLocaleString();
        },
        medianTimeToComplete () {
            const timeMs = get(this.highlightsAggData, 'medianTimeToComplete', 0);

            return longPrettyTime(timeMs);
        }
    },
    methods: {
        handleBaselineDateRangeChange (value) {
            this.$emit('updateBaselineDateRange', value);
        }
    }
};
</script>

<style lang="scss" scoped>
.card-title {
    font-size: 18px;
}

.stats {
    display: flex;
    justify-content: space-around;
    height: 108px;

    .stat {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 33%;

        &:nth-of-type(2) {
            border-left: 1px solid #dadce5;
            border-right: 1px solid #dadce5;
        }

        :deep(.pendo-loading-overlay) {
            display: flex;
            justify-content: center;
        }
    }

    .number {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
    }

    .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
    }
}

:deep(.pendo-card__body) {
    padding: 0;
}
</style>
