<template>
    <pendo-card
        class="path-chart-card"
        :title="pathChartTitle">
        <template #headerRight>
            <pendo-loading-indicator
                v-if="isLoading"
                size="small" />
        </template>
        <div class="path-chart-card__body">
            <div
                v-if="isLoading && !isRunning"
                class="path-chart-card__status">
                <pendo-loading-indicator size="large" />
            </div>
            <div
                v-else-if="isLoading && isRunning"
                class="path-chart-card__status">
                <pendo-loading-indicator size="large" />
                <span class="path-chart-card__status-text">Data for this report is running. This could take a few minutes...</span>
            </div>
            <div
                v-else-if="isRejected"
                class="path-chart-card__status path-chart-card__status--rejected">
                <pendo-icon
                    size="48"
                    type="alert-triangle"
                    stroke="#ffffff"
                    fill="#db1111" />
                <span>
                    {{ errorMessage }}
                </span>
            </div>
            <div
                v-else-if="isResolved && !isEmpty"
                class="path-chart-card__resolved">
                <path-chart
                    :frozen-path-data="pathState.frozenPathData"
                    :path-resource="pathResource"
                    :labeler="labeler"
                    :color-by="colorBy"
                    @select="onPathChartStepSelect" />
            </div>
            <div
                v-else-if="isResolved && isEmpty"
                class="path-chart-card__status">
                <path-details-empty-state />
            </div>
        </div>
    </pendo-card>
</template>
<script>
import { PendoCard, PendoLoadingIndicator, PendoIcon } from '@pendo/components';
import PathChart from '@/components/paths/PathChart';
import PathDetailsEmptyState from '@/components/paths/PathDetailsEmptyState.vue';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { LOADING_STATES } from '@pendo/services/Constants';
import { progressCircleColors } from '@/utils/paths';

export default {
    name: 'PathChartCard',
    components: {
        PendoCard,
        PendoLoadingIndicator,
        PathChart,
        PathDetailsEmptyState,
        PendoIcon
    },
    props: {
        pathResource: {
            type: Object,
            required: true
        },
        pathState: {
            type: Object,
            required: true
        },
        labeler: {
            type: Object,
            required: true
        }
    },
    emits: ['select'],
    data () {
        return {
            progressCircleColors
        };
    },
    computed: {
        ...mapGetters({
            pageById: 'pages/pageById',
            featureById: 'features/featureById',
            trackEventById: 'trackEvents/getTrackEventById',
            guideById: 'guides/getGuideById'
        }),
        isLoading () {
            return this.pathState.status === LOADING_STATES.LOADING;
        },
        isRunning () {
            return Number.isFinite(this.pathState.percentComplete);
        },
        isRejected () {
            return this.pathState.status === LOADING_STATES.REJECTED;
        },
        errorMessage () {
            return get(this.pathState.error, 'message', '');
        },
        isResolved () {
            return this.pathState.status === LOADING_STATES.RESOLVED;
        },
        isEmpty () {
            const visitors = this.pathState.frozenVisitors || [];

            return this.isResolved && visitors.length === 0;
        },
        colorBy () {
            return this.pathResource.definition.config.acrossApps ? 'byApp' : 'byGroup';
        },
        rootResource () {
            if (!this.pathResource) {
                return;
            }

            const { definition = {} } = this.pathResource;
            const { config = {} } = definition;
            const { pageId, featureId, trackTypeId, guideId } = config;

            if (pageId) {
                return this.pageById(pageId);
            }
            if (featureId) {
                return this.featureById(featureId);
            }
            if (trackTypeId) {
                return this.trackEventById(trackTypeId);
            }
            if (guideId) {
                return this.guideById(guideId);
            }

            return null;
        },
        pathChartTitle () {
            const predecessors = this.pathResource.definition.config.predecessors || false;

            return this.rootResource ? `Paths ${predecessors ? 'to' : 'from'} ${this.rootResource.name}` : '';
        }
    },
    methods: {
        onPathChartStepSelect (selectedStep) {
            this.$emit('select', selectedStep);
        }
    }
};
</script>
<style scoped lang="scss">
.path-chart-card {
    :deep(.pendo-card) {
        .pendo-card__header--left {
            white-space: nowrap;
            text-align: center;
            text-overflow: ellipsis;
            overflow: hidden;
            min-width: 0;
            max-width: 100%;
            line-height: 2rem;
        }
    }
}

.path-chart-card__body {
    min-height: 600px;
}

.path-chart-card__status {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $gray-lighter-2;
    font-size: 1.2rem;
}

.path-chart-card__status--rejected {
    line-height: 2rem;
}

.path-chart-card__status-text {
    line-height: 2rem;
    margin: 2rem 0;
}

.path-chart-card__legend {
    display: flex;
}

.path-chart-card__resolved {
    > *:not(:last-child) {
        margin-bottom: 1.5rem;
    }
}

.path-chart-card__app-legend {
    margin-left: 4px;
}
</style>
