<template>
    <div>
        <pendo-button
            :type="launched ? 'primary' : 'tertiary'"
            theme="app"
            :label="launched ? 'Close' : 'Cancel'"
            @click="closeModal" />
        <pendo-button
            v-if="!launched && snippetInstalled"
            :disabled="!isValidUrl"
            label="Launch Preview"
            theme="app"
            type="primary"
            @click="launchPreview" />
        <pendo-button
            v-if="!snippetInstalled"
            theme="app"
            type="primary"
            label="Install Pendo"
            @click="$emit('goToSetup')" />
    </div>
</template>

<script>
import { PendoButton } from '@pendo/components';
import { isValidUrl } from '@pendo/services/Formatters';

export default {
    name: 'LaunchPreviewModalFooter',
    components: {
        PendoButton
    },
    props: {
        launched: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: ''
        },
        snippetInstalled: {
            type: Boolean,
            default: true
        }
    },
    emits: ['goToSetup', 'close', 'launchPreview'],
    computed: {
        isValidUrl () {
            return isValidUrl(this.url);
        }
    },
    methods: {
        closeModal () {
            this.$emit('close');
        },
        launchPreview () {
            this.$emit('launchPreview');
        }
    }
};
</script>
