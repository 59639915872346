import { http } from '@pendo/http';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import get from 'lodash/get';

export async function getContent (guide) {
    const guideWithContent = cloneDeep(guide);
    const stepsWithContent = await getGuideStepsContent(guideWithContent.steps);
    guideWithContent.steps = stepsWithContent;

    return guideWithContent;
}

export async function getGuideStepsContent (steps) {
    const promises = steps.map(async (step) => {
        const stepContent = {};
        if (step.buildingBlocksUrl) {
            const response = await http.get(step.buildingBlocksUrl, {
                withCredentials: false,
                transformResponse: null
            });
            stepContent.buildingBlocks = response.data;
        }
        if (step.domUrl) {
            const response = await http.get(step.domUrl, { withCredentials: false, transformResponse: null });
            stepContent.dom = response.data;
        }

        return Object.assign(step, stepContent);
    });

    return Promise.all(promises);
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function processGuideElementsAggDropdown (rows, currentElementData) {
    const mergedData = merge(keyBy(rows, 'uiElementId'), keyBy(currentElementData, 'uiElementId'));

    return Object.values(mergedData).map((row) => {
        const elementText = formatUiElementTextForDisplay(row.uiElementText, row.uiElementId);

        return {
            guideElementId: row.uiElementId,
            guideStepId: row.guideStepId,
            name: row.isDeleted ? `Deleted Element (${elementText})` : elementText,
            icon: { type: getUiElementTypeIcon(row.uiElementType) }
        };
    });
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function formatUiElementTextForDisplay (uiElementText, uiElementId) {
    if (!uiElementText) {
        return uiElementId || 'Unknown';
    }

    if (uiElementText === '×') {
        return 'Close Button';
    }

    return uiElementText;
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function getUiElementTypeIcon (elementType) {
    return {
        'Button': 'rectangle',
        'Close Button': 'x',
        'Dismiss Button': 'x',
        'Link': 'link'
    }[elementType];
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function getCurrentElementData (dom) {
    let elements = [];
    const isCorrectType = dom.type && (dom.type === 'button' || dom.type === 'a');
    const uiElementId = get(dom, 'props.id', false);
    if (isCorrectType && uiElementId) {
        const uiElementText = getUiElementText(dom);
        const uiElementType = getUiElementType(uiElementId, dom.type);
        let uiElementActions = get(dom, 'actions');
        if (dom.type === 'a' && !uiElementActions) {
            uiElementActions = [{ action: 'openLink' }];
        }

        elements.push({ uiElementId, uiElementText, uiElementType, uiElementActions });
    }

    if (dom.children) {
        dom.children.forEach((child) => {
            elements = elements.concat(getCurrentElementData(child));
        });
    }

    return elements;
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function getUiElementText (dom) {
    const uiElementText = get(dom, 'content', false);
    if (uiElementText) {
        return uiElementText;
    }

    let concatenatedUiText = '';
    if (dom.children) {
        dom.children.forEach((child) => {
            const uiText = getUiElementText(child);
            if (uiText) {
                concatenatedUiText = concatenatedUiText.concat(uiText);
            }
        });
    }

    return concatenatedUiText;
}

// this function is duplicated from pendo-appengine -> guide-elements-table/utils.js
export function getUiElementType (uiElementId, type) {
    if (uiElementId.includes('pendo-close-guide-')) {
        return 'Close Button';
    }

    return {
        button: 'Button',
        a: 'Link'
    }[type];
}
