<template>
    <pendo-card class="journey-card-container">
        <div
            v-if="calculatedInsights.length"
            class="insights-banner">
            <insights-icon />
            <span class="insights-banner__text"><b>Insights:</b> We found {{ insightsPhrase }} about top journeys.</span>
            <pendo-button
                size="mini"
                class="toggle-insights-button"
                :label="insightsShowing ? 'Hide insights' : 'Show me'"
                @click="$emit('toggleInsights')" />
        </div>
        <div class="journey-canvas">
            <div class="journey-list-container">
                <journey-list
                    v-for="journey in topJourneys"
                    :key="journey.heading"
                    class="journey-nodes"
                    :num-of-attempts="journey.data.numAttempts"
                    :avg-time-taken="journey.data.avgTimeTaken"
                    :steps="journey.data.journey"
                    :heading="journey.heading"
                    :step-count="journey.data.numSteps"
                    :total-num-of-attempts="totalAttempts"
                    :current-insight="currentInsight"
                    :insight-meta="insightMeta"
                    :avg-times="avgTimes"
                    :included-steps="includedSteps"
                    :insights-showing="insightsShowing"
                    :show-default-icon="showDefaultIcon"
                    :is-engage="isEngage"
                    @prevInsight="prevInsight"
                    @nextInsight="nextInsight"
                    @resetInsights="resetInsights"
                    @toggleIncludedStep="toggleIncludedStep"
                    @hideInsights="$emit('hideInsights')" />
            </div>
        </div>
    </pendo-card>
</template>

<script>
import { PendoCard, PendoButton } from '@pendo/components';
import JourneyList from '@/stateless-components/workflows/journeys/JourneyList';
import InsightsIcon from '@/components/icons/Insights';
import isEqual from 'lodash/isEqual';
import {
    longestTimeBetweenSteps,
    repeatedSequences,
    quickestVsMostCommon
} from '@/stateless-components/workflows/journeys/insights/insights';
import { JOURNEY_TYPES } from '@/stateless-components/workflows/journeys/utils/constants';
import { pluralize } from '@/utils/formatters';

export default {
    name: 'TopJourneys',
    components: {
        PendoCard,
        JourneyList,
        PendoButton,
        InsightsIcon
    },
    props: {
        journeyData: {
            type: Array,
            default: () => []
        },
        includedSteps: {
            type: Object,
            default: () => ({})
        },
        insightsShowing: {
            type: Boolean,
            default: false
        },
        showDefaultIcon: {
            type: Boolean,
            default: true
        },
        isEngage: {
            type: Boolean,
            default: false
        }
    },
    emits: ['toggleInsights', 'hideInsights', 'toggleIncludedStep'],
    data () {
        return {
            currentInsight: null,
            calculatedInsights: []
        };
    },
    computed: {
        mostCommonJourney () {
            const mostCommon = this.journeyData.reduce((prev, current) => {
                return prev.numAttempts > current.numAttempts ? prev : current;
            }, {});

            return {
                heading: JOURNEY_TYPES.MOST_COMMON,
                types: [JOURNEY_TYPES.MOST_COMMON],
                data: mostCommon
            };
        },
        fewestStepsJourney () {
            const fewestSteps = this.journeyData.reduce((prev, current) => {
                return prev.numSteps < current.numSteps ? prev : current;
            }, {});

            return {
                heading: JOURNEY_TYPES.FEWEST_STEPS,
                types: [JOURNEY_TYPES.FEWEST_STEPS],
                data: fewestSteps
            };
        },
        quickestJourney () {
            const quickest = this.journeyData.reduce((prev, current) => {
                return prev.avgTimeTaken < current.avgTimeTaken ? prev : current;
            }, {});

            return {
                heading: JOURNEY_TYPES.QUICKEST,
                types: [JOURNEY_TYPES.QUICKEST],
                data: quickest
            };
        },
        avgTimes () {
            return {
                mostCommon: this.mostCommonJourney.data.avgTimeTaken,
                quickest: this.quickestJourney.data.avgTimeTaken
            };
        },
        showQuickestInsight () {
            return this.avgTimes.mostCommon !== this.avgTimes.quickest;
        },
        totalAttempts () {
            return this.journeyData.reduce((totalAttempts, curRow) => totalAttempts + curRow.numAttempts, 0);
        },
        topJourneys () {
            const topJourneys = [this.mostCommonJourney, this.fewestStepsJourney, this.quickestJourney];
            const dedupedTopJourneys = [];

            for (const journeyToAdd of topJourneys) {
                let duplicateFound = false;
                for (const journeyToMatch of dedupedTopJourneys) {
                    if (isEqual(journeyToMatch.data, journeyToAdd.data)) {
                        duplicateFound = true;
                        journeyToMatch.heading = [journeyToMatch.heading, journeyToAdd.heading].join(' & ');
                        journeyToMatch.types = [...journeyToMatch.types, ...journeyToAdd.types];
                        break;
                    }
                }

                if (!duplicateFound) dedupedTopJourneys.push(journeyToAdd);
            }

            return dedupedTopJourneys;
        },
        insightMeta () {
            return {
                current: this.calculatedInsights.indexOf(this.currentInsight) + 1,
                count: this.calculatedInsights.length
            };
        },
        currentInsightIndex () {
            return this.calculatedInsights.indexOf(this.currentInsight);
        },
        insightsPhrase () {
            const count = this.calculatedInsights.length;

            return `${count} ${pluralize('insight', count)}`;
        }
    },
    watch: {
        includedSteps () {
            this.resetInsights();
        }
    },
    created () {
        this.resetInsights();
    },
    methods: {
        runInsights () {
            const { journey: mostCommonSteps } = this.mostCommonJourney.data;

            this.calculatedInsights.push(longestTimeBetweenSteps(mostCommonSteps));
            this.calculatedInsights.push(...repeatedSequences(mostCommonSteps));

            if (this.showQuickestInsight) {
                this.calculatedInsights.push(quickestVsMostCommon());
            }
        },
        prevInsight () {
            this.currentInsight = this.calculatedInsights[this.currentInsightIndex - 1];
        },
        nextInsight () {
            this.currentInsight = this.calculatedInsights[this.currentInsightIndex + 1];
        },
        resetInsights () {
            this.$emit('hideInsights');
            this.calculatedInsights = [];
            this.runInsights();
            this.currentInsight = this.calculatedInsights[0];
        },
        toggleIncludedStep (step) {
            this.$emit('toggleIncludedStep', step);
        }
    }
};
</script>
<style lang="scss" scoped>
.journey-canvas {
    overflow: auto;
    width: inherit;
    padding-bottom: 25px;

    .journey-list-container {
        display: flex;
        justify-content: flex-start;
        margin: 20px auto 0 auto;
        width: fit-content;
    }

    .journey-nodes {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.insights-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    background-color: $color-purple-10;
    color: $color-purple-70;

    &__text {
        margin: 0 16px 0 8px;
    }

    :deep(.pendo-button) {
        background-color: $color-purple-70;
    }

    :deep(.pendo-button:hover) {
        background-color: $color-purple-90;
    }
}
</style>
